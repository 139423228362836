export const environment = {
  production: false,
  serviceWorker: true,
  name: 'Nimbus',
  region: 'ap-southeast-2',
  identityPoolId: 'ap-southeast-2:fad6b8f9-9837-44f7-bd05-67c1cd0be9c7',
  userPoolId: 'ap-southeast-2_sD9ks6IKC',
  clientId: '4ptcqbi5giiml7aqrq10d7v5f',
  atlasCDNDomain: 'https://atlas.nimbus.unleashlive.com',
  jobCDNDomain: 'https://job.nimbus.unleashlive.com',
  ioTPolicyName: 'remote-control-policy',
  iotRestURL: 'ajuy0fzllclgq-ats.iot.ap-southeast-2.amazonaws.com',
  API_URL: 'https://api.nimbus.unleashlive.com/v1/',
  STREAM_URL: 'https://chunks-cdn.nimbus.unleashlive.com',
  RTMP_STREAM_URL: 'rtmp://stream.nimbus.unleashlive.com',
  WEBRTC_SERVER_URL: 'https://54.79.254.168:8889',
  STREAM_DASHBOARD_URL: 'https://watch.nimbus.unleashlive.com/',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_CNKC1ajQ8NhfsKTn9unMGbig',
  NO_IMAGE_PATH: 'assets/images/placeholder.png',
  LIBRARY_BUCKET: 'library-syd-nimbus',
  ACCOUNT_BUCKET: 'account-syd-nimbus',
  ADDON_BUCKET: 'addon-syd-nimbus',
  DEVICE_BUCKET: 'device-syd-nimbus',
  LIBRARY_BUCKET_UPLOAD: 'library-upload-syd-nimbus',
  ATLAS_ASSETS_BUCKET: 'atlas-assets-syd-nimbus',
  JOB_BUCKET: 'job-syd-nimbus',
  PWA_STREAMING_APP_ID: 'f8458db0bcdf455faca9cacded4d3a1a',
  SEGMENT_IO_API_KEY: 'X0w2GaxDfV9cPJl7tXKs5QGYheCgJSIy',
  APPSYNC_LIBRARY_URI: 'https://mediadrive-api.nimbus.unleashlive.com/graphql',
  APPSYNC_NOTIFICATIONS_URI: 'https://notifications-api.nimbus.unleashlive.com/graphql',
  CDN_CLI_BUCKET: 'https://cli-syd-nimbus.s3-ap-southeast-2.amazonaws.com',
  CLI_CATEGORY: 'latest',
  THUMBLER_API_CDN: 'https://thumbnails.nimbus.unleashlive.com',
  REPORT_API_CDN: 'https://reports.nimbus.unleashlive.com',
  LIBRARY_CF_CDN: 'https://library.nimbus.unleashlive.com',
  MODELS_CF_CDN: 'https://models.nimbus.unleashlive.com',
  UNLEASH_LIVE_USER_ID: 'ap-southeast-2:25d55d74-8797-4b80-94c8-c0c285a74ac0',
  FLIGHT_LOG_FILE_BUCKET: 'flight-log-file-syd-nimbus',
  FLIGHT_LOGS_CF_CDN: 'https://flights.nimbus.unleashlive.com',
  CESIUM_ACCESS_TOKEN: '',
  GMAPS_KEY: 'AIzaSyCpoL2A5vBDgWb0iOKmuZdyrMDRdTlhpUk',
  OAUTH_DOMAIN: 'unleashlive-nimbus.auth.ap-southeast-2.amazoncognito.com',
  OAUTH_REDIRECT_SIGN_IN: 'https://nimbus.unleashlive.com/auth/sign-in',
  OAUTH_REDIRECT_SIGN_OUT: 'https://nimbus.unleashlive.com/auth/logout'
};

<div class="container">
  <div class="section-name">{{ 'profile.rolesManagement.header' | translate }}</div>
  <div class="content mat-elevation-z5" [class.has-unsaved-changes]="hasUnsavedChanges$ | async">
    <div class="header">
      <button mat-raised-button color="primary" [disabled]="!(hasUnsavedChanges$ | async)" (click)="saveChanges()">
        {{ 'common.save' | translate }}
      </button>
      <button mat-raised-button color="primary" (click)="openAddRoleDialog()">
        <mat-icon>add</mat-icon>
        {{ 'profile.rolesManagement.actions.createRole' | translate }}
      </button>
    </div>

    <div class="permissions-table">
      <table mat-table [dataSource]="permissionGroups$ | async" class="mat-elevation-z1">
        <!-- Permission Name Column -->
        <ng-container matColumnDef="permissionName">
          <th mat-header-cell *matHeaderCellDef>{{ 'profile.rolesManagement.permissionName' | translate }}</th>
          <td mat-cell *matCellDef="let group">
            <div class="permission-group">
              <div class="group-name">{{ group.groupName }}</div>
              <div class="permissions-list">
                <div class="permission-name" *ngFor="let permission of group.permissions">
                  {{ permission.name }}
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Dynamic Role Columns -->
        <ng-container *ngFor="let role of roles$ | async">
          <ng-container [matColumnDef]="role.id">
            <th mat-header-cell *matHeaderCellDef>
              {{ role.name }}
              <button
                *ngIf="role.companyId !== 'default'"
                mat-icon-button
                color="warn"
                [matTooltip]="'common.delete' | translate"
                (click)="removeRole(role)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let group">
              <div class="permission-group">
                <div class="group-name"></div>
                <div class="permissions-list">
                  <div class="permission-row" *ngFor="let permission of group.permissions">
                    <mat-checkbox
                      [checked]="hasPermission(role, permission)"
                      (change)="togglePermission(role, permission)"
                      [matTooltip]="
                        role.companyId === 'default'
                          ? ('profile.rolesManagement.defaultRoleTooltip' | translate)
                          : permission.description
                      "
                      [disabled]="role.companyId === 'default'"
                      [class.default-role]="role.companyId === 'default'"
                    >
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>

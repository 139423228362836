import {Directive, HostListener, Input} from '@angular/core';
import {Map} from 'leaflet';
import {LayersVisibilityService} from '../services/layers-visibility.service';

@Directive({
  selector: '[ulShowOnlyThisGroup]',
  standalone: true
})
export class ShowOnlyThisGroupDirective {
  @Input('ulShowOnlyThisGroup') public groupName: string;
  @Input() public map: Map;

  constructor(private layersVisibilityService: LayersVisibilityService) {}

  @HostListener('click', ['$event'])
  public showOnlyThisGroup(): void {
    this.layersVisibilityService.showOnlyThisGroup(this.groupName, this.map);
  }
}

import {Mission} from '@app/atlas/services/mission-settings.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';

export interface Job {
  companyId: string;
  completedTasks: number;
  createdAt: number;
  description: string;
  id: string;
  ownerId: string;
  pk: string;
  sk: string;
  status: JOB_STATUS;
  teamId: string;
  title: string;
  totalTasks: number;
  updatedAt: number;
  userIds?: string[];
  tasks?: Task[];
  assetId?: string;
  s3Path?: string;
}

export enum JOB_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export interface Task {
  sk: string;
  pk: string;
  ownerId: string;
  teamId: string;
  companyId: string;
  title: string;
  status: UserTaskStatus;
  description: string;
  jobId?: string;
  type: TaskType;
  libraryId: string;
  assets: string[];
  context: TaskContext;
  userId: string;
  assignedId: string;
  assignedAt: number;
  createdAt: number;
  updatedAt: number;
  id: string;
  libraryItemId?: string;
}

export enum UserTaskStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export enum TaskType {
  FLIGHT_REQUEST = 'FLIGHT_REQUEST',
  FLIGHT_REQUEST_MANUAL = 'FLIGHT_REQUEST_MANUAL'
}

export interface TaskContext {
  mission?: Partial<Mission>;
  asset?: Partial<AtlasAssetModel>;
}

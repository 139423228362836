<div class="header">
  <div class="header__links">
    <span class="header__link header__link--back" (click)="goBack()">← {{'common.back' | translate}}</span>
    <button
      *ngIf="!isUserInvitation && (isChangePasswordFlow$ | async) === false"
      mat-icon-button
      [matMenuTriggerFor]="menu"
      class="header__link header__link--more"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="goToPreviousAuthStep()" class="header__menu-item">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'common.signOut' | translate}}</span>
      </button>
    </mat-menu>
  </div>
</div>

<div class="spacer"></div>

<unleash-alert
  class="unleash-alert"
  *ngIf="errorMessage$ | async as errorMessage"
  type="error"
  [label]="errorMessage"
  iconName="close"
></unleash-alert>
<div class="logo">
  <img class="logo__img" src="/assets/images/unleash-logo--dark.svg" />
</div>

<div *ngIf="isUserInvitation; else changePasswordTemplate" class="title">
  <div class="title__text">{{'Join '+ organizationName +' on Unleash live'}}</div>
</div>
<ng-template #changePasswordTemplate>
  <div class="title">
    <div class="title__text">{{'auth.forgot.changePassword' | translate}}</div>
    <div class="title__sub-text">{{'auth.forgot.changePasswordHelp' | translate}}</div>
  </div>
</ng-template>

<unleash-forgot-password-step-two
  class="form form--fixed"
  (submitForm)="submit($event)"
  (resendCode)="resendCode()"
  [forgotPasswordForm]="forgotPasswordForm$ | async"
  [inProgress]="isInProgress$ | async"
  [isUserInvitation]="isUserInvitation"
  [isCodeExpired]="isCodeExpired$ | async"
  [codeGeneratedSuccessfully]="codeGeneratedSuccessfully | async"
>
</unleash-forgot-password-step-two>
<div class="spacer"></div>

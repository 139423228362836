import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeZonesService {
  private userLocaleLanguage = navigator.language || 'en-US';
  public timeZoneList: TimeZone[] = [];

  constructor() {
    this.timeZoneList = this.getTimeZoneList();
  }

  public getTimeZoneList(): TimeZone[] {
    const timeZones = (Intl as any).supportedValuesOf('timeZone');
    const finalTimeZoneList = [];
    for (const timeZone of timeZones) {
      const formatter = new (Intl as any).DateTimeFormat(this.userLocaleLanguage, {
        timeZone,
        timeZoneName: 'short'
      });

      const timeZoneValue = formatter.formatToParts().find(part => part.type === 'timeZoneName').value;

      if (timeZoneValue) {
        finalTimeZoneList.push({
          name: timeZone,
          fullTimeZoneName: `${timeZone} ${timeZoneValue}`,
          timeZoneValue
        });
      }
    }
    return finalTimeZoneList;
  }

  public getCurrentTimeZoneIndex(): number {
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.timeZoneList.findIndex(timeZone => timeZone.name === currentTimeZone);
  }

  public getCurrentTimeZoneValue(timezone: string): string {
    return this.timeZoneList.find(timeZone => timeZone.name === timezone)?.timeZoneValue;
  }
}

export interface TimeZone {
  name: string;
  fullTimeZoneName: string;
  timeZoneValue: string;
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {UserService} from '../../core/services/api/user.service';

@Injectable({
  providedIn: 'root'
})
export class AtlasAnnotationService {
  private annotationAssetTypesUrl$: Observable<string>;

  constructor(private userService: UserService, private http: HttpClient) {
    this.annotationAssetTypesUrl$ = this.userService.myCompany$.pipe(
      filter(company => !!company),
      map(company => company.atlas?.annotationAssetTypesUrl)
    );
  }

  getAssetTypesData(assetTypesDataUrl: string) {
    return this.http
      .get(assetTypesDataUrl, {
        responseType: 'json' as any,
        withCredentials: true
      })
      .toPromise()
      .then(response => {
        return Promise.resolve(response);
      })
      .catch(err => {
        console.error('err', err);
      });
  }

  /**
   * Checks if user has defined private annotation data
   *  @return true if annotation will be described using user's annotation data
   *          false if annotation will be described using public descriptors
   */
  getAnnotationAssetDataUrl(): Observable<string> {
    return this.annotationAssetTypesUrl$;
  }
}

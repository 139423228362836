import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AddonZones} from '@app/shared/annotation-shared/models/annotations.model';
import * as actions from '@app/store/user/user.actions';
import * as selectors from '@app/store/user/user.selectors';
import {differenceInDays} from 'date-fns';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {CompanyModel, UserCompanyModel} from '../models/api/company-model';
import {AnalyticsConfig, StreamPlayer, UserDeviceJoined} from '../models/api/user-device.model';
import {UserModel} from '../models/api/user-model';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {Team, TeamRole} from '@app/profile/models/team.model';
import {cloneDeep} from 'lodash';
import {selectSelectedTeamId} from '@app/profile/store/profile.selectors';
import {CalibrationType} from '@app/shared/calibration/calibration.component';
import {Stream} from '../models/api/stream.model';

@Injectable({
  providedIn: 'root'
})
export class UserStoreFacadeService {
  public currentUser$: Observable<UserModel> = this.store
    .select(selectors.selectUser)
    .pipe(filter((user: UserModel) => !!user));
  public currentUserId$: Observable<UserModel['id']> = this.store.select(selectors.selectUserId);
  public isDeveloperMode$: Observable<boolean> = this.store.select(selectors.selectIsDeveloperMode);
  public isAutoflyEnabled$: Observable<boolean> = this.store
    .select(selectors.selectIsAutoflyEnabled)
    .pipe(filter(value => value !== null && value !== undefined));
  public currentUserCompany$: Observable<CompanyModel> = this.store
    .select(selectors.selectCompany)
    .pipe(filter((company: CompanyModel) => !!company));
  public currentUserDevices$: Observable<UserDeviceJoined[]> = this.store
    .select(selectors.selectDevices)
    .pipe(filter((devices: UserDeviceJoined[]) => !!devices && devices.length > 0));
  public currentUserDevicesObject$: Observable<{[key: string]: UserDeviceJoined}> = this.store.select(
    selectors.selectDevicesObject
  );

  public currentPlan$: Observable<UserModel['currentPlan']> = this.store.select(selectors.selectCurrentPlan);
  public companies$: Observable<CompanyModel[]> = this.store
    .select(selectors.selectCompanies)
    .pipe(filter((companies: CompanyModel[]) => !!companies && companies.length > 0));
  public devicesSorted$: Observable<UserDeviceJoined[]> = this.store.select(selectors.selectDevicesSorted);
  public defaultDevice$: Observable<UserDeviceJoined | null> = this.store.select(selectors.selectDefaultDevice);
  public trialPeriodDaysLeft$: Observable<number> = this.store.pipe(
    select(selectors.selectUser),
    map((user: UserModel) => Math.round(differenceInDays(user.trialPeriodEnd, new Date())) * -1)
  );
  public selectUserTeamId$: Observable<string> = this.store.select(selectors.selectUserTeamId);
  public userTeams$ = this.store.pipe(select(selectors.selectUserTeams));
  public currentTeamId$ = this.store.pipe(select(selectors.selectCurrentTeamId));
  public companyId$: Observable<string> = this.store.select(selectors.selectCompanyId);
  public currentOrganizationName$ = this.store.pipe(select(selectors.selectCurrentOrganizationName));
  public isSwitchingTeam$: Observable<boolean> = this.store.select(selectors.selectIsSwitchingTeam);
  public selectedTeamId$ = this.store.select(selectSelectedTeamId);
  public currentTeam$: Observable<Team> = this.store.select(selectors.selectCurrentTeam);
  public currentRole$: Observable<TeamRole> = this.store.select(selectors.selectCurrentRole);
  public isMfaEnabled$: Observable<boolean> = this.store.select(selectors.selectIsMfaEnabled);
  public liveDevices$: Observable<UserDeviceJoined[]> = this.store.select(selectors.selectLiveDevices);
  public isUpdatingCognitoUser$: Observable<boolean> = this.store.select(selectors.selectIsUpdatingCognitoUser);

  constructor(private store: Store) {}

  // TODO Refactor as class properties instead of methods

  // eslint-disable-next-line rxjs/finnish
  public getDevice(deviceId: string): Observable<UserDeviceJoined> {
    return this.store.pipe(select(selectors.selectDevice, {deviceId}));
  }

  public updateDeviceZonesConfig(deviceId: string, zonesConfig: {[x: string]: string | AddonZones}): void {
    this.store.dispatch(actions.updateDeviceZonesConfig({deviceId, zonesConfig}));
  }

  public updateAnalyticsConfig(deviceId: string, analyticsConfig: AnalyticsConfig): void {
    this.store.dispatch(actions.updateAnalyticsConfig({deviceId, analyticsConfig}));
  }

  public userStoreData(user: UserModel) {
    this.store.dispatch(actions.userStoreData({user}));
  }

  public userStoreDeviceData(device: UserDeviceJoined) {
    device
      ? this.store.dispatch(actions.userStoreDeviceData({devices: {[device.id]: cloneDeep(device)}}))
      : this.store.dispatch(actions.userStoreDeviceData({devices: {}}));
  }

  public userStoreCompany(company: CompanyModel) {
    this.store.dispatch(actions.userStoreCompany({company}));
  }

  public userStoreRemoveDeviceData(deviceId: string) {
    this.store.dispatch(actions.userStoreRemoveDeviceData({deviceId}));
  }

  public setDeviceUsage() {
    this.store.dispatch(actions.setDeviceUsage());
  }

  public updateDeviceCache(device: Partial<UserDeviceJoined>) {
    this.store.dispatch(actions.updateDeviceCache({device}));
  }

  public removeDeviceFromUser(deviceId: string, userId: string) {
    this.store.dispatch(actions.removeDeviceFromUser({deviceId, userId}));
  }

  public removeDeviceFromCache(deviceId: string) {
    this.store.dispatch(actions.removeDeviceFromCache({deviceId}));
  }

  public findAllUserDevices(identityId: string, fetchUserDevicesCount: number, FETCH_DEVICES_MAX_RETRY: number) {
    this.store.dispatch(actions.findAllUserDevices({identityId, fetchUserDevicesCount, FETCH_DEVICES_MAX_RETRY}));
  }

  public getCompanyIfUserIsNotAdmin() {
    this.store.dispatch(actions.getCompanyIfUserIsNotAdmin());
  }

  public getTeam() {
    this.store.dispatch(actions.getTeam());
  }

  public updateUser(user: Partial<UserModel>, identityId: string) {
    this.store.dispatch(actions.updateUser({user, identityId}));
  }

  public updatePlanInUserDataStore(planId: string, period: PaymentPeriod) {
    this.store.dispatch(actions.updatePlanInUserDataStore({planId, period}));
  }

  public userStoreCompanies(companies: CompanyModel[]) {
    this.store.dispatch(actions.saveCompanies({companies}));
  }

  public saveUserCompanies(companies: UserCompanyModel[]) {
    this.store.dispatch(actions.saveUserCompanies({companies}));
  }

  public createCompany(company: Partial<CompanyModel>) {
    this.store.dispatch(actions.createCompany({company}));
  }

  public registerCompany(company: Partial<CompanyModel>) {
    this.store.dispatch(actions.registerCompany({company}));
  }

  public updateCompany(companyId: string, company: Partial<CompanyModel>) {
    this.store.dispatch(actions.updateCompany({companyId, company}));
  }

  public setupUserDataModel(user: UserModel) {
    this.store.dispatch(actions.setupUserDataModel({user}));
  }

  public saveSetOfDevices(devices: {[key: string]: UserDeviceJoined}) {
    this.store.dispatch(actions.saveSetOfDevices({devices}));
  }

  public getCompanyDataIfUserIsAdmin(): void {
    this.store.dispatch(actions.getCompanyDataIfUserIsAdmin());
  }

  public setUserStoreRole(roles: string[]): void {
    this.store.dispatch(actions.setUserStoreRole({payload: {roles}}));
  }

  public getUserRolesByCompany() {
    this.store.dispatch(actions.getUserRolesByCompany());
  }

  public loadUserTeams() {
    this.store.dispatch(actions.loadUserTeams());
  }

  public loadUserTeamsSuccess(teams: {[key: string]: Team}) {
    this.store.dispatch(actions.loadUserTeamsSuccess({payload: {teams}}));
  }

  public switchCurrentTeam(teamId: string) {
    this.store.dispatch(actions.switchCurrentTeam({payload: {teamId}}));
  }

  public switchCurrentTeamSuccess(companyId: string, teamId: string) {
    this.store.dispatch(actions.switchCurrentTeamSuccess({payload: {companyId, teamId}}));
  }

  public goToManageTeams(): void {
    this.store.dispatch(actions.goToManageTeams());
  }

  public deleteSceneMapping(
    deviceId: string,
    addonId: string,
    calibrationType: CalibrationType,
    analyticsConfig: AnalyticsConfig
  ): void {
    this.store.dispatch(actions.deleteSceneMapping({payload: {deviceId, addonId, calibrationType, analyticsConfig}}));
  }

  public deleteSceneMappingSuccess(payload: {deviceId: string; addonId: string}): void {
    this.store.dispatch(actions.deleteSceneMappingSuccess({payload}));
  }

  public confirmDeleteMarkerByIndex(index: number) {
    this.store.dispatch(actions.confirmDeleteMarkerByIndex({payload: {index}}));
  }

  public addLiveDevice(payload: {deviceId: UserDeviceJoined['id']; player: StreamPlayer}): void {
    this.store.dispatch(actions.addLiveDevice({payload}));
  }

  public setRunningModelsByDeviceId(deviceId: any, runningModels: string[]) {
    this.store.dispatch(actions.setRunningModelsByDeviceId({payload: {deviceId, runningModels}}));
  }

  public setCurrentModel(deviceId: string, addonId: string): void {
    this.store.dispatch(actions.setCurrentModel({payload: {deviceId, addonId}}));
  }

  public removeAddonFromWaitingModels(deviceId: string): void {
    this.store.dispatch(actions.removeAddonFromWaitingModels({payload: {deviceId}}));
  }

  public removeAddonFromWaitingModelsDueError(deviceId: string, modelId: string) {
    this.store.dispatch(actions.removeAddonFromWaitingModelsDueError({payload: {deviceId, modelId}}));
  }

  public updateRestreamsStats(restreams: {
    [key: string]: {
      isRestreamingFrom?: boolean;
      statsRestreamingFrom?: string;
      isRestreamingTo?: boolean;
      statsRestreamingTo?: string;
      className?: string;
    };
  }) {
    this.store.dispatch(actions.updateRestreamsStats({payload: restreams}));
  }

  public removeAddonFromRunningModels(deviceId: string, modelId: string): void {
    this.store.dispatch(actions.removeAddonFromRunningModels({payload: {deviceId, modelId}}));
  }

  public setDisplayedDeviceIds(displayedDeviceIds: string[]): void {
    this.store.dispatch(actions.setDisplayedDeviceIds({payload: {displayedDeviceIds}}));
  }

  public changeDestUrlSetOnSelectedDevice(destUrlSet: boolean, deviceId: string) {
    this.store.dispatch(actions.changeDestUrlSetOnSelectedDevice({payload: {destUrlSet, deviceId}}));
  }

  public setDestUrlOnSelectedDevice(destUrl: string, deviceId: string): void {
    this.store.dispatch(actions.setDestUrlOnSelectedDevice({payload: {destUrl, deviceId}}));
  }

  public updateDevicesCache(devices: {[key: string]: Partial<UserDeviceJoined>}) {
    this.store.dispatch(actions.updateDevicesCache({payload: devices}));
  }

  public setIsUpdatingCognitoUser(isUpdatingCognitoUser: boolean) {
    this.store.dispatch(actions.setIsUpdatingCognitoUser({isUpdatingCognitoUser}));
  }

  public verifyMobileNumber(phoneNumber: string) {
    this.store.dispatch(actions.verifyMobileNumber({phoneNumber}));
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {actionSaveEmail} from '@app/auth/components/forgot-password/forgot-password.actions';
import {selectRouterRedirectState} from '@app/store/app.state';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {
  actionFederatedSignIn,
  actionSignInFormError,
  actionSignInFormSubmit,
  actionSignInHideSignOutAlert
} from '@app/auth/components/sign-in/sign-in.actions';
import {SignInForm} from '@app/auth/components/sign-in/sign-in.model';
import {
  selectSignInFormError,
  selectSignInFormInProgress,
  selectSignInFormIsSignOutAlert
} from '@app/auth/components/sign-in/sign-in.selectors';
import {SignInCodeStatus} from '@app/auth/models/registration-login.types';
import {MetatagService} from '@app/shared/services/metatag.service';
import {CustomMetatags} from '@app/shared/models/custom-metatags.enum';

import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {AUTH_NOTIFICATIONS} from './sign-in.config';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss']
})
export class SignInPage implements OnInit, OnDestroy {
  public hasSignedOut$: Observable<boolean>;
  public errorMessage$: Observable<string>;
  public inProgress$: Observable<boolean>;
  public queryParamsSub: Subscription;
  public isDeveloperMode: boolean = this.route.snapshot.data.isDeveloperMode;
  public companyLogo: string | null = null;
  public companyName: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private metatagService: MetatagService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(actionSaveEmail({payload: {email: ''}}));
    
    // Get company name and logo using MetatagService
    this.metatagService.getMetatagValue(CustomMetatags.COMPANY_NAME)
      .pipe(take(1))
      .subscribe(name => this.companyName = name);

    this.metatagService.getMetatagValue(CustomMetatags.LOGIN_LOGO)
      .pipe(take(1))
      .subscribe(logo => this.companyLogo = logo);

    this.watchQueryParams();
    this.hasSignedOut$ = this.store.pipe(select(selectSignInFormIsSignOutAlert));
    this.errorMessage$ = this.store.pipe(
      select(selectSignInFormError),
      map(error => {
        if (!error) {
          return null;
        }

        if (error.code === SignInCodeStatus.USER_EXISTS) {
          return error.message;
        }

        if (error.code === SignInCodeStatus.NOT_AUTHORIZED) {
          return AUTH_NOTIFICATIONS.DEFAULT;
        }

        return AUTH_NOTIFICATIONS.DEFAULT;
      })
    );
    this.inProgress$ = this.store.pipe(select(selectSignInFormInProgress));

    this.store
      .pipe(select(selectRouterRedirectState))
      .pipe(take(1))
      .subscribe(redirectState => {
        if (redirectState.url === '/secure/atlas' && redirectState.queryParams['id']) {
          this.translateService
            .get('atlas.layerAlerts.signInToLoadLinksToFusionAtlas')
            .pipe(take(1))
            .subscribe(signInToLoadLinksToFusionAtlas => {
              this.snackBar.open(signInToLoadLinksToFusionAtlas, null, {
                duration: 5000
              });
            });
        }
      });
  }

  public ngOnDestroy(): void {
    this.store.dispatch(actionSignInHideSignOutAlert());
    this.store.dispatch(actionSignInFormError({isDeveloperMode: this.isDeveloperMode}));
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
  }

  public signIn(value: SignInForm): void {
    this.store.dispatch(
      actionSignInFormSubmit({
        form: value,
        isDeveloperMode: this.isDeveloperMode
      })
    );
  }

  public federatedSignIn(value: string): void {
    this.store.dispatch(
      actionFederatedSignIn({
        provider: value
      })
    );
  }

  public goToPasswordResetPage(): void {
    this.router.navigate([`/auth/${this.isDeveloperMode ? 'developer-' : ''}forgot-password`]);
  }

  public watchQueryParams(): void {
    this.queryParamsSub = this.route.queryParams
      .pipe(filter(params => !!params['status'] || !!params['provider']))
      .subscribe(params => {
        let ssoProvider: string = params['provider'];
        if (ssoProvider) {
          this.federatedSignIn(ssoProvider);
          return;
        }

        switch (params['status']) {
          case SignInCodeStatus.USER_EXISTS:
            this.store.dispatch(
              actionSignInFormError({
                form: {},
                error: {
                  code: params['status'],
                  message: AUTH_NOTIFICATIONS.USER_EXISTS,
                  name: 'USER_EXIST'
                }
              })
            );
            break;
          default:
            break;
        }
        this.clearQueryParams();
      });
  }

  public clearQueryParams(): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      skipLocationChange: false,
      preserveFragment: false
    });
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RenameLayersDialogComponent} from '@app/atlas/components/rename-layers-dialog/rename-layers-dialog.component';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {Map} from 'leaflet';
import {filter, take} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LocalStorageGroupLayer} from './layers-control-group.models';
import {ActivatedRoute} from '@angular/router';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {FlatNode} from '../layers-control-items.component';
import {taskLayers} from '@app/atlas/atlas.config';
import {SuperclusterService} from '@app/atlas/services/supercluster.service';
import {LayersVisibilityService} from '@app/atlas/services/layers-visibility.service';
import {AtlasHeaderStatus} from '@app/atlas/services/atlas-header.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layers-control-group',
  templateUrl: './layers-control-group.component.html',
  styleUrls: ['./layers-control-group.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: 'noop'}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersControlGroupComponent implements OnInit {
  public events = EVENTS;
  public layerGroupSavedInLocalStorage: LocalStorageGroupLayer[] = [];
  @Input() public node: any;
  @Input() public treeControl: FlatTreeControl<FlatNode>;
  @Input()
  public isExpanded: boolean;
  @Input()
  public groupName: string;
  @Input()
  public groupId: string;
  @Input()
  public children: AtlasAssetModel[];
  @Input('hasToDetectChanges')
  public set hasToDetectChanges(hasToDetectChanges: boolean | null) {
    if (hasToDetectChanges !== undefined) {
      this.refreshIsDisplaying();
      this.refreshColor();
    }
  }
  @Input() public hasToBlockMenu: boolean = false;
  @Input() public totalSelectedLayers: number;
  @Input() public isListOnly: boolean = false;
  @Input() public map: Map;
  @Input() public headerStatus: AtlasHeaderStatus;

  @Output() public openLayerControlItemMenu: EventEmitter<MouseEvent> = new EventEmitter();

  // eslint-disable-next-line rxjs/finnish
  public isDisplaying$ = new BehaviorSubject<boolean>(true);
  // eslint-disable-next-line rxjs/finnish
  public isSelected$ = new BehaviorSubject<boolean>(false);
  public aclPermissions = AclPermissions;
  public availableColors = this.atlasService.availableColors;
  public customIconColorIndex: number = -1;
  public hasAllLayersLoaded$ = this.atlasService.hasAllLayersLoaded$.asObservable();
  public atlasHeaderStatus = AtlasHeaderStatus;

  private selectedLayersSub: Subscription;

  constructor(
    private atlasService: AtlasService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private superclusterService: SuperclusterService,
    private layersVisibilityService: LayersVisibilityService
  ) {}

  public ngOnInit(): void {
    if (this.route.snapshot.queryParams.id) {
      this.isDisplaying$.next(!this.route.snapshot.queryParams.id);
    }
    this.selectedLayersSub = this.atlasService.selectedLayers$.subscribe(selectedAssets => {
      const assetIsSelected = a => !!selectedAssets.find(s => s.id === a.id);
      const allAssetsSelected = this.children.every(assetIsSelected);
      this.isSelected$.next(allAssetsSelected);
    });
    if (this.atlasService.avoidLoadLocalStorageAssets) {
      return;
    }
    this.refreshColor();
    this.hideTaskAssets();
  }

  public changeGroupColor(colorIndex: number): void {
    this.customIconColorIndex = colorIndex;
    this.children.forEach(child => {
      this.atlasService.changeLayerColor(child, colorIndex);
      this.layersVisibilityService.updateClusterColor(child, colorIndex, this.map);
      this.atlasService.updateLayerColor(child.leafletLayer, child.color);
    });
    this.atlasService.hasLayerGroupChanged$.next(true);
    this.translateService
      .get('atlas.changeColor.groupColorUpdated')
      .pipe(take(1))
      .subscribe(message => {
        this.snackBar.open(message, null, {
          panelClass: 'center',
          duration: 3000
        });
      });
  }

  public changeLayersVisibility(): void {
    if (this.isDisplaying$.value) {
      this.children.forEach((layer: AtlasAssetModel) => (layer.isDisplaying = true));
      return;
    }
    this.children.forEach((layer: AtlasAssetModel) => (layer.isDisplaying = false));
  }

  public toggleSelectGroup(groupName: string) {
    const newSelectedState = !this.isSelected$.value;
    this.children.forEach(a => {
      if (a.groupName === groupName) {
        a.isSelected = newSelectedState;
        if (newSelectedState) {
          this.atlasService.addSelectedLayer(a);
        } else {
          this.atlasService.removeSelectedLayer(a.id);
        }
      }
    });
    this.atlasService.hasLayerGroupChanged$.next(true);
  }

  public toggleIsDisplayingGroup() {
    this.isDisplaying$.next(!this.isDisplaying$.value);
    this.layersVisibilityService.toggleAssetsGroups(this.children, this.isDisplaying$.value, this.map);
    this.atlasService.hasLayerGroupChanged$.next(true);
  }

  public renameGroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel): boolean => asset.groupName === groupName
    );
    const isRenamed: (name: string) => boolean = (name: string): boolean => !!name && groupName !== name;
    this.dialog
      .open(RenameLayersDialogComponent, {
        ...STANDARD_DIALOG_CONFIG,
        data: {name: groupName}
      })
      .afterClosed()
      .pipe(untilDestroyed(this), filter(isRenamed))
      .subscribe((name: string): void => {
        this.atlasService
          .updateAssets(groupedLayers, {groupName: name})
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.translateService
              .get('atlas.control.renameSuccessName', {value: name})
              .pipe(untilDestroyed(this))
              .subscribe(message => {
                this.snackBar.open(message, null, {duration: 5000, panelClass: 'center'});
              });
          });
      });
  }

  public ungroup(groupName: string): void {
    const groupedLayers: AtlasAssetModel[] = this.atlasService.assets.filter(
      (asset: AtlasAssetModel) => asset.groupName === groupName
    );
    this.atlasService
      .updateAssets(groupedLayers, {groupName: null})
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.translateService
          .get('atlas.control.ungroupSuccess', {value: response.length})
          .pipe(untilDestroyed(this))
          .subscribe(message => {
            this.snackBar.open(message, null, {duration: 5000});
          });
      });
  }

  public bringToFrontGroup(groupName: string): void {
    this.atlasService.assets.forEach(asset => {
      if (asset.groupName === groupName) {
        const zIndex = 100;
        this.atlasService.bringToFrontPolygon(asset);
        this.superclusterService.setUpdatedMarkerZIndex(zIndex, asset.id);
        this.superclusterService.updateClusters(this.map);
      }
    });
  }

  public bringToBackGroup(groupName: string): void {
    this.atlasService.assets.forEach(asset => {
      if (asset.groupName === groupName) {
        const zIndex = -1;
        this.atlasService.bringToBackPolygon(asset);
        this.superclusterService.setUpdatedMarkerZIndex(zIndex, asset.id);
        this.superclusterService.updateClusters(this.map);
      }
    });
  }

  public refreshIsDisplaying() {
    this.isDisplaying$.next(this.children.some((layer: AtlasAssetModel) => layer.isDisplaying));
  }

  public refreshColor() {
    const colorReference = this.children[0].customColorIndex;
    this.customIconColorIndex = this.children.every(
      (layer: AtlasAssetModel) => layer?.customColorIndex === colorReference
    )
      ? colorReference
      : -1;
  }

  public emitOpenLayerControlItemMenu(event: MouseEvent): void {
    this.openLayerControlItemMenu.emit(event);
  }

  private hideTaskAssets(): void {
    if (this.groupName === taskLayers) {
      this.isDisplaying$.next(false);
    }
  }

  public hasToHideLayer(isDisplaying: boolean): boolean {
    return !(
      isDisplaying ||
      this.headerStatus === this.atlasHeaderStatus.SELECT_MARKERS ||
      this.headerStatus === this.atlasHeaderStatus.EDIT_JOB_SHAPE
    );
  }
}

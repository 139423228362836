import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {NotificationLayout, NotificationLevel, NotificationModel} from '@app/core/models/api/notifications.model';
import {HelpService} from '@app/core/services/help.service';
import {NotificationActions} from './notifications-card.model';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCardComponent {
  @Input()
  public notification: NotificationModel;
  @Input()
  public layout: NotificationLayout = NotificationLayout.simple;
  @Input()
  public hasCloseButton: boolean = false;
  @Output()
  public notificationWasSeen: EventEmitter<boolean> = new EventEmitter();
  @Output()
  public openNotificationGroup: EventEmitter<NotificationModel[]> = new EventEmitter();
  @Output()
  public deleteNotification: EventEmitter<NotificationModel> = new EventEmitter();
  @Output()
  public clickAction: EventEmitter<string> = new EventEmitter();
  @Output()
  public close: EventEmitter<boolean> = new EventEmitter();

  public isOnHoverCard: boolean = false;
  public notificationLevel = NotificationLevel;
  public notificationLayout = NotificationLayout;
  public aclPermissions = AclPermissions;
  public notificationActions = NotificationActions;

  constructor(private helpService: HelpService) {}

  @HostListener('mouseenter')
  public onMouseEnter() {
    if (this.layout === NotificationLayout.simple) {
      // TODO: We need mark as seen notifications on hover
      /* this.notificationWasSeen.emit(true); */
    }
    this.isOnHoverCard = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave() {
    this.isOnHoverCard = false;
  }

  public emitDeleteNotification() {
    this.deleteNotification.emit(this.notification);
  }

  public emitReadNotification() {
    if (!this.notification.isRead) {
      this.notificationWasSeen.emit(true);
    }
  }

  public closeSidebar(action: string) {
    this.clickAction.emit(action);
  }

  public emitClose() {
    this.close.emit(true);
  }

  public goToContactUs() {
    this.helpService.goToContactUs();
  }

  public getType(actionUrl: any) {
    console.info('actionUrl', actionUrl);
    console.info('typeof actionUrl', typeof actionUrl);
    return typeof actionUrl;
  }
}

import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';

export interface AssetProperty {
  key: string;
  displayName: string;
}

export class UserModel {
  public autoflyFullAccess?: boolean;
  public avatar?: string;
  public identityId?: string;
  public name?: string;
  public email: string;
  public password?: string;
  public username?: string;
  public fullName?: string;
  public id: string;
  public createdAt?: Date;
  public updatedAt?: Date;
  public streamKey: string;
  public streamModel: string;
  public policyAgreed?: boolean;
  public currentPlan?: string;
  public developer?: boolean;
  public trialPeriodEnd?: number;
  public provider?: string;
  public currentUsage: UsageModel;
  public activationCode?: string;
  public subscriptionStart?: number;
  public subscriptionPeriod?: PaymentPeriod;
  // TODO label config should be just string
  public labelConfigId?: number | string;
  public stripeSubscription?: string;
  public activeTeamAndCompany: activeTeamAndCompany;
  public acl: Record<string, string>;
  public roles: {[key: string]: string[]};
  public dataDashboardId?: string;
  public phone?: string;
}

export interface activeTeamAndCompany {
  teamId: string;
  companyId: string;
}

export interface UsageModel {
  devices?: number;
  devicesMax?: number;
  streamsRaw?: number;
  streamsRawMax?: number;
  streamsAi?: number;
  streamsAiMax?: number;
  jobsCv?: number;
  jobsCvMax?: number;
  jobs3d?: number;
  jobs3dMax?: number;
  jobsMedia?: number;
  jobsMediaMax?: number;
  librarySize?: number;
  librarySizeMax?: number;
}

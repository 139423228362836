import {createAction, props} from '@ngrx/store';
import {Role, Permission} from '../../models/roles-management.model';

export enum RolesManagementActionTypes {
  LoadRoles = '[Roles Management] Load Roles',
  LoadRolesSuccess = '[Roles Management] Load Roles Success',
  LoadRolesFailure = '[Roles Management] Load Roles Failure',

  LoadPermissions = '[Roles Management] Load Permissions',
  LoadPermissionsSuccess = '[Roles Management] Load Permissions Success',
  LoadPermissionsFailure = '[Roles Management] Load Permissions Failure',

  // Save changes
  SaveChanges = '[Roles Management] Save Changes',
  SaveChangesSuccess = '[Roles Management] Save Changes Success',
  SaveChangesFailure = '[Roles Management] Save Changes Failure',

  // For local-only operations (no backend)
  AddRole = '[Roles Management] Add Role',
  DeleteLocalRole = '[Roles Management] Delete Local Role',
  UpdateLocalRolePermissions = '[Roles Management] Update Local Role Permissions',

  // For backend operations
  CreateRole = '[Roles Management] Create Role',
  CreateRoleSuccess = '[Roles Management] Create Role Success',
  CreateRoleFailure = '[Roles Management] Create Role Failure',

  UpdateRole = '[Roles Management] Update Role',
  UpdateRoleSuccess = '[Roles Management] Update Role Success',
  UpdateRoleFailure = '[Roles Management] Update Role Failure',

  DeleteRole = '[Roles Management] Delete Role',
  DeleteRoleSuccess = '[Roles Management] Delete Role Success',
  DeleteRoleFailure = '[Roles Management] Delete Role Failure'
}

export const loadRoles = createAction(RolesManagementActionTypes.LoadRoles);
export const loadRolesSuccess = createAction(RolesManagementActionTypes.LoadRolesSuccess, props<{roles: Role[]}>());
export const loadRolesFailure = createAction(RolesManagementActionTypes.LoadRolesFailure, props<{error: string}>());

export const loadPermissions = createAction(RolesManagementActionTypes.LoadPermissions);
export const loadPermissionsSuccess = createAction(
  RolesManagementActionTypes.LoadPermissionsSuccess,
  props<{permissions: Permission[]}>()
);
export const loadPermissionsFailure = createAction(
  RolesManagementActionTypes.LoadPermissionsFailure,
  props<{error: string}>()
);

export const createRole = createAction(RolesManagementActionTypes.CreateRole, props<{role: Role}>());
export const createRoleSuccess = createAction(
  RolesManagementActionTypes.CreateRoleSuccess,
  props<{role: Role; tempRoleId: string}>()
);
export const createRoleFailure = createAction(RolesManagementActionTypes.CreateRoleFailure, props<{error: string}>());

export const addRole = createAction(RolesManagementActionTypes.AddRole, props<{role: {name: string}}>());

export const deleteLocalRole = createAction(RolesManagementActionTypes.DeleteLocalRole, props<{roleId: string}>());

export const updateRole = createAction(
  RolesManagementActionTypes.UpdateRole,
  props<{roleId: string; role: Partial<Role>}>()
);
export const updateRoleSuccess = createAction(
  RolesManagementActionTypes.UpdateRoleSuccess,
  props<{roleId: string; role: Role}>()
);
export const updateRoleFailure = createAction(RolesManagementActionTypes.UpdateRoleFailure, props<{error: string}>());

export const deleteRole = createAction(RolesManagementActionTypes.DeleteRole, props<{roleId: string}>());
export const deleteRoleSuccess = createAction(RolesManagementActionTypes.DeleteRoleSuccess, props<{roleId: string}>());
export const deleteRoleFailure = createAction(RolesManagementActionTypes.DeleteRoleFailure, props<{error: string}>());

export const updateLocalRolePermissions = createAction(
  RolesManagementActionTypes.UpdateLocalRolePermissions,
  props<{roleId: string; permissionKey: string; isAdd: boolean}>()
);

export const saveChanges = createAction(RolesManagementActionTypes.SaveChanges);
export const saveChangesSuccess = createAction(RolesManagementActionTypes.SaveChangesSuccess, props<{roles: Role[]}>());
export const saveChangesFailure = createAction(RolesManagementActionTypes.SaveChangesFailure, props<{error: string}>());

<div
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="space-between start"
  fxLayoutAlign.lt-sm="start"
  class="container"
>
  <div fxFlex="100%" fxFlex.lt-sm="100%">
    <div class="option email">
      <div class="option-title">{{ 'common.email' | translate }}</div>
      <div class="option-content-email">
        <div class="email">
          {{ user?.email }}
        </div>
      </div>
    </div>
    <button [disabled]="isSendingForgotCode" class="change-password-button" mat-button color="primary" (click)="emitChangePassword()">
      {{ 'profile.changePassword' | translate }}
      <unleash-spinner *ngIf="isSendingForgotCode" diameter="20" borderWidth="2.1" color="#999"></unleash-spinner>
    </button>
    <div class="option">
      <form [formGroup]="form" class="option-content-name">
        <mat-form-field class="form-field-name">
          <input
            type="text"
            matInput
            (change)="update()"
            formControlName="name"
            placeholder="{{ 'common.name' | translate }}"
            required
          />
          <mat-error *ngIf="form.get('name').hasError('required')"
            >{{ 'common.name' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
          >
        </mat-form-field>
      </form>
    </div>

    <div class="option">
      <unleash-language-picker></unleash-language-picker>
    </div>

    <section class="mobile-number">
      <h1 class="mobile-number__title">{{ 'profile.phone.mobileNumber' | translate }}</h1>
      <ng-container [ngSwitch]="phoneNumberState">
        <ng-container *ngSwitchCase="PHONE_NUMBER_STATE.EDIT">
          <section class="mobile-number__existent">
            {{ user.phone }}
            <button
              (click)="setPhoneNumberState(PHONE_NUMBER_STATE.VERIFY); clearPhoneNumberForm()"
              mat-button
              color="primary"
              class="mobile-number__edit"
            >
              {{ 'profile.phone.edit' | translate }}
            </button>
          </section>
        </ng-container>
        <ng-container *ngSwitchCase="PHONE_NUMBER_STATE.VERIFY">
          <section class="mobile-number__container" [formGroup]="phoneForm">
            <div class="mobile-number__content">
              <mat-form-field class="mobile-number__phone-input" appearance="fill">
                <mat-label>{{ 'profile.phone.countryCode' | translate }}</mat-label>
                <mat-icon *ngIf="isPhoneListOpened" class="mobile-number__search-icon" matPrefix color="primary"
                  >search</mat-icon
                >
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <input
                  type="text"
                  [placeholder]="'profile.phone.findCountryOrCode' | translate"
                  matInput
                  formControlName="countryCode"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  (closed)="setIsPhoneListOpened(false)"
                  (opened)="setIsPhoneListOpened(true)"
                  [panelWidth]="272"
                  #auto="matAutocomplete"
                >
                  <mat-option *ngFor="let country of filteredOptions | async" [value]="country.dialCode">
                    {{ country.flag + ' ' + country.name + ' ' + country.dialCode }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field appearance="fill">
                <mat-label>{{ 'profile.phone.mobileNumber' | translate }}</mat-label>
                <input numbersOnly [includeZero]="true" matInput formControlName="mobileNumber" />
              </mat-form-field>
              <button
                [disableRipple]="true"
                *ngIf="
                  phoneForm.valid &&
                  (phoneForm.value.countryCode + phoneForm.value.mobileNumber !== user.phone ||
                    !(hasPhoneNumberVerified$ | async))
                "
                [disabled]="isUpdatingCognitoUser$ | async"
                (click)="verifyMobileNumber()"
                mat-button
                color="primary"
                class="mobile-number__verify"
              >
                {{ 'profile.phone.verify' | translate }}
                <unleash-spinner
                  *ngIf="isUpdatingCognitoUser$ | async"
                  diameter="20"
                  borderWidth="2.1"
                  color="#111"
                ></unleash-spinner>
              </button>
              <button
                *ngIf="user.phone"
                [disabled]="isUpdatingCognitoUser$ | async"
                (click)="setPhoneNumberState(PHONE_NUMBER_STATE.EDIT); clearPhoneNumberForm()"
                mat-flat-button
                color="primary"
                class="mobile-number__verify"
              >
                {{ 'profile.phone.cancel' | translate }}
              </button>
            </div>
            <mat-error
              *ngIf="
                phoneForm.invalid && phoneForm.controls.countryCode.touched && phoneForm.controls.mobileNumber.touched
              "
              >**{{ 'profile.phone.invalidMobileNumber' | translate }}</mat-error
            >
          </section>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="hasPhoneNumberVerified$ | async; else notVerifiedPhoneNumberTemplate">
        <ng-container *ngIf="hasSmsMfa$ | async; else noSmsMfaTemplate">
          <section class="mobile-number__mfa-message">
            <mat-icon class="mobile-number__info-icon">info</mat-icon>
            {{ 'profile.phone.userWithMfa' | translate }}
          </section>
        </ng-container>
        <ng-template #noSmsMfaTemplate>
          <!-- TODO: Uncomment when ai app alerts is enabled -->
          <!-- <section class="mobile-number__mfa-message">
            <mat-icon class="mobile-number__info-icon">info</mat-icon>
            {{ 'profile.phone.userWithNoMfa' | translate }}
          </section> -->
        </ng-template>
      </ng-container>
      <ng-template #notVerifiedPhoneNumberTemplate>
        <span *ngIf="user.phone && phoneNumberState === PHONE_NUMBER_STATE.EDIT" class="mobile-number__no-verified">
          **{{ 'profile.phone.mobileNumberNotVerified' | translate }}
          <a
            mat-button
            [disabled]="isUpdatingCognitoUser$ | async"
            (click)="verifyExistingPhoneNumber(user.phone)"
            class="mobile-number__no-verified-action"
            >{{ 'profile.phone.verifyHere' | translate }}
            <unleash-spinner
              *ngIf="isUpdatingCognitoUser$ | async"
              diameter="20"
              borderWidth="2.1"
              color="#111"
            ></unleash-spinner>
          </a>
        </span>
      </ng-template>
    </section>
  </div>
  <!--  <div fxFlex="50%" fxFlex.lt-sm="100%" fxLayout="column" fxLayoutAlign="start center"-->
  <!--       fxLayoutAlign.lt-sm="start start">-->
  <!--    <div class="picture">-->
  <!--      <div class="picture-title">Avatar</div>-->
  <!--      <logo-upload subfolder='user' [parentId]="user?.id" [url]="user?.avatar"-->
  <!--                   (s3Url)="updateUserAvatar($event)"></logo-upload>-->
  <!--    </div>-->
  <!--  </div>-->
</div>

import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {selectAwsMarketplaceToken} from '@app/auth/state/auth.selectors';
import {UntilDestroy} from '@ngneat/until-destroy';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map, shareReplay, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {CompanyModel} from '../../core/models/api/company-model';
import {ExtensionModel} from '../../core/models/api/extension.model';
import {PlanModel} from '../../core/models/api/plan.model';
import {UserDeviceJoined} from '../../core/models/api/user-device.model';
import {UserModel} from '../../core/models/api/user-model';
import {DeviceService} from '../../core/services/api/device.service';
import {UserService} from '../../core/services/api/user.service';
import {PlansService} from '../../plans/services/plans.service';
import {DateFormat} from '../models/date-format.model';
import {ExportData, ExportDataOption, ExportDateRange, ExportType} from '../models/export-data.model';
import {DateFormatService} from '../services/date-format.service';
import {ExportDataService} from '../services/export-data.service';
import {Addon} from '@app/store/addon/models/addon';
import {TeamTileScreen} from '@app/profile/models/team-tile-screen.model';
import {TeamRole, Team, TeamTable, TeamAction, TeamTableActions} from '@app/profile/models/team.model';
import {UserTeamTileScreen} from '@app/profile/models/user-team-tile-screen.model';
import {
  UserTeamAction,
  UserTeamInvitation,
  UserTeamTable,
  UserTeamTableActions
} from '@app/profile/models/user-team.model';
import {UserTileScreen} from '@app/profile/models/user-tile-screen.model';
import {UserAction, UserTable, UserTableActions, UserTeam} from '@app/profile/models/users-table.model';
import {TeamsAccountManagementService} from '@app/profile/services/teams-account-management.service';
import {UserTeamsAccountManagementService} from '@app/profile/services/user-teams-account-management.service';
import {UsersAccountManagementService} from '@app/profile/services/users-account-management.service';
import {UserInvitation} from '@app/profile/models/user-roles.model';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {FormBuilder} from '@angular/forms';
import {AclService} from '@app/core/services/acl.service';
import {UsersManagementStoreFacadeService} from '../services/users-management-store-facade.service';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {AuthService} from '@app/auth/services/auth.service';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyProfileComponent implements OnDestroy, AfterViewInit {
  @ViewChild('bottom') public bottomPage: ElementRef;
  @ViewChild('profileComponent') public profileComponent: UserProfileComponent;

  public company: CompanyModel;
  // eslint-disable-next-line rxjs/finnish
  public myDevices$: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject([]);
  // eslint-disable-next-line rxjs/finnish
  public myPlanInfo$: BehaviorSubject<PlanModel> = new BehaviorSubject(null);
  public addons: Addon[] = [];
  public extensions: ExtensionModel[] = [];
  public storesActive: {[key: string]: boolean} = {};
  public isProduction: boolean = environment.production;
  public desktopMenuTop: number;
  public menuItems: {
    label: string;
    icon: string;
    href: string;
    class: string;
    customIcon?: CustomIcon;
    routerLink?: string;
  }[];
  // eslint-disable-next-line rxjs/finnish
  public endToLoadData$: BehaviorSubject<UserLoadingData> = new BehaviorSubject<UserLoadingData>({
    user: false,
    company: false,
    plans: false,
    userDevices: false
  });
  // eslint-disable-next-line rxjs/finnish
  public user$: Observable<UserModel> = this.userService.user$;
  public isAwsUser$: Observable<boolean>;
  // eslint-disable-next-line rxjs/finnish
  public isActivating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public classMenuObserver: MutationObserver;

  public dataRangeExportOptions: ExportDataOption<ExportDateRange>[];
  public typeExportOptions: ExportDataOption<ExportType>[];

  public dateFormatOptions: DateFormat[];

  public userPlan$ = combineLatest([this.userService.user$, this.plansService.plans]).pipe(
    filter(([user, plans]) => !!plans && !!user),
    map(([user, plans]: [UserModel, PlanModel[]]) => {
      const myPlan = plans.filter(plan => plan.id === user.currentPlan).pop();
      this.endToLoadData$.next({...this.endToLoadData$.value, plans: true});
      return myPlan;
    })
  );

  public roles$ = this.userAccountManagementService.roles$;

  private endToLoadDataSub: Subscription;
  private initPlansSub: Subscription;
  private userSub: Subscription;
  private myCompanySub: Subscription;
  private userDevicesSub: Subscription;
  private observableMediaSubscription: Subscription;
  private fragmentSubscription: Subscription;
  private userPlanSub: Subscription;

  public users$: Observable<UserTeam[]>;
  public hasMoreThanOneAdmin$: Observable<boolean>;
  public usersMenuActions$: Observable<UserTableActions[]>;
  public userScreen$: Observable<UserTileScreen>;
  public selectedUser$: Observable<UserTeam>;
  public roles: TeamRole[];

  public teams$: Observable<Team[]> = this.teamsAccountManagementService.teams$;
  public teamsTable$: Observable<TeamTable[]> = this.teamsAccountManagementService.teamsTable$;
  public teamsMenuActions: TeamTableActions[];
  public teamRoles: TeamRole[];
  public teamScreen$: Observable<TeamTileScreen>;
  public selectedTeam$: Observable<TeamTable>;

  public selectedUserTeam$: Observable<TeamTable>;
  public userTeamsScreen$: Observable<UserTeamTileScreen>;
  public userTeamsMenuActions: UserTeamTableActions[];
  public usersTable$: Observable<any[]> = this.userAccountManagementService.usersTable$;
  public currentTeamId$: Observable<string> = this.userStoreFacadeService.currentTeamId$;
  public companyId$: Observable<string> = this.userStoreFacadeService.companyId$;
  public isMfaEnabled$: Observable<boolean> = this.userStoreFacadeService.isMfaEnabled$.pipe(shareReplay(1));
  public userTeams$: Observable<UserTeamTable[]> = this.userStoreFacadeService.userTeams$.pipe(
    map(userTeams => Object.values(userTeams))
  );

  public userBySelectedTeam$: Observable<UserTable[]> = this.teamsAccountManagementService.userBySelectedTeam$;
  public isUserManagementLoading$: Observable<boolean> =
    this.usersManagementStoreFacadeService.isUserManagementActionLoading$;

  public aclPermissions = AclPermissions;
  public userSearchForm = this.fb.group({
    search: ''
  });
  public savedEmail$: Observable<string> = this.authService.savedEmail$;
  public isDeveloperMode = this.activatedRoute.snapshot.data.isDeveloperMode;
  public isSendingForgotCode$ = this.authService.isSendingForgotCode.asObservable();

  constructor(
    public router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public deviceService: DeviceService,
    public observableMedia: MediaObserver,
    private activatedRoute: ActivatedRoute,
    private plansService: PlansService,
    private elementRef: ElementRef,
    private exportDataService: ExportDataService,
    private dateFormatService: DateFormatService,
    private store: Store,
    private userAccountManagementService: UsersAccountManagementService,
    private teamsAccountManagementService: TeamsAccountManagementService,
    private userTeamsAccountManagementService: UserTeamsAccountManagementService,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService,
    private userStoreFacadeService: UserStoreFacadeService,
    private fb: FormBuilder,
    private aclService: AclService,
    private authService: AuthService
  ) {
    this.users$ = this.userAccountManagementService.users$;
    this.currentTeamId$ = this.userStoreFacadeService.currentTeamId$;
    this.hasMoreThanOneAdmin$ = this.userAccountManagementService.hasMoreThanOneAdmin$;
    this.usersMenuActions$ = this.userAccountManagementService.userMenuActions$;
    this.userScreen$ = this.userAccountManagementService.userScreen$;
    this.selectedUser$ = this.userAccountManagementService.selectedUser$;

    this.teamsMenuActions = this.teamsAccountManagementService.teamMenuActions;
    this.teamRoles = this.teamsAccountManagementService.roles;
    this.teamScreen$ = this.teamsAccountManagementService.teamScreen$;
    this.selectedTeam$ = this.teamsAccountManagementService.selectedTeamTable$;

    this.userTeamsMenuActions = this.userTeamsAccountManagementService.userTeamMenuActions;
    this.selectedUserTeam$ = this.userTeamsAccountManagementService.selectedUserTeam$;
    this.userTeamsScreen$ = this.userTeamsAccountManagementService.userTeamScreen$;

    this.dataRangeExportOptions = this.exportDataService.dataRangeExportOptions;
    this.typeExportOptions = this.exportDataService.typeExportOptions;

    this.dateFormatOptions = this.dateFormatService.dateFormatOptions;

    this.isAwsUser$ = this.store.pipe(
      select(selectAwsMarketplaceToken),
      map(token => !!token)
    );
    this.menuItems = [
      {
        label: 'Usage',
        href: 'usage',
        icon: 'data_usage',
        class: ''
      },
      {
        label: 'Plan',
        href: 'plan',
        icon: 'description',
        class: ''
      },
      {
        label: 'Devices',
        href: 'devices',
        icon: '',
        customIcon: {
          class: 'icon svg',
          alt: 'router',
          src: 'assets/icons/profile/router.svg'
        },
        class: ''
      },
      {
        label: 'Streaming',
        href: 'streaming',
        icon: 'linked_camera',
        class: ''
      },
      {
        label: 'Profile',
        href: 'profile',
        icon: 'person',
        class: ''
      },
      {
        label: 'Organisation',
        href: 'organisation',
        icon: 'business',
        class: ''
      },
      {
        label: 'Data & privacy',
        href: 'data-privacy',
        icon: 'security',
        class: ''
      },
      {
        label: 'Developer options',
        href: 'developer',
        icon: 'code',
        class: ''
      },
      {
        label: 'Sign out',
        href: '',
        icon: 'exit_to_app',
        class: 'sign-out',
        routerLink: '/auth/logout'
      }
    ];
    this.getData();
    this.userStoreFacadeService.getCompanyDataIfUserIsAdmin();
  }

  public ngAfterViewInit(): void {
    this.fragmentSubscription = this.activatedRoute.fragment
      .pipe(filter(fragment => !!fragment))
      .subscribe(fragment => {
        this.endToLoadData$
          .pipe(
            filter(loadedData => Object.values(loadedData).every(data => !!data)),
            take(1)
          )
          .subscribe(() => {
            const section = this.elementRef.nativeElement.querySelector(`#${fragment}`);
            if (section) {
              setTimeout(() => {
                section.scrollIntoView();
              }, 0);
            }
          });
      });
    this.aclService.hasSetupPermission$
      .pipe(
        filter(permission => !!permission),
        take(1)
      )
      .subscribe(() => {
        this.watchScroll();
      });
  }

  public ngOnDestroy(): void {
    if (this.classMenuObserver) {
      this.classMenuObserver.disconnect();
    }

    this.teamsAccountManagementService.hideTeamDetails();
  }

  public watchScroll(): void {
    this.classMenuObserver = new MutationObserver(mutations => {
      mutations.forEach(menuObserver => {
        if ((menuObserver.target as any).classList.contains('active')) {
          this.router.navigate(['secure/profile'], {
            skipLocationChange: true
          });
          const currentUrl = window.location.href;
          const newUrl = currentUrl.replace(/#.*$/, '') + (menuObserver.target as HTMLAnchorElement).hash;
          window.history.replaceState({}, document.title, newUrl);
        }
      });
    });

    const mobileMenuItems = this.elementRef.nativeElement.querySelectorAll('.mobile-menu-item');
    mobileMenuItems.forEach(menu =>
      this.classMenuObserver.observe(menu, {attributes: true, attributeFilter: ['class']})
    );
  }

  public requestExport(exportData: ExportData): void {
    this.userService.user$.pipe(take(1)).subscribe((user: UserModel) => {
      this.exportDataService.export(exportData);
      if (exportData.type === ExportType.media || exportData.type === ExportType.userActivity) {
        this.exportDataService.displayExportDataDialog(user.email);
      }
    });
  }

  public activateDeveloperMode(): void {
    this.isActivating$.next(true);
    this.userService.updateUser({
      developer: true
    });
    this.userService.user$.subscribe(
      resp => {
        this.isActivating$.next(false);
      },
      err => {
        console.error(err);
        this.isActivating$.next(false);
      }
    );
  }

  public turnOffDeveloperMode(): void {
    this.isActivating$.next(true);
    this.userService.updateUser({
      developer: false
    });
    this.userService.user$.subscribe(
      resp => {
        this.isActivating$.next(false);
      },
      err => {
        console.error(err);
        this.isActivating$.next(false);
      }
    );
  }

  private getData(): void {
    this.userSub = this.userService.user$.subscribe(user => {
      this.endToLoadData$.next({...this.endToLoadData$.value, user: true});
    });
    this.myCompanySub = this.userService.myCompany$.subscribe(company => {
      this.company = company;
      this.endToLoadData$.next({...this.endToLoadData$.value, company: true});
    });
    this.userDevicesSub = this.userService.userDevices$.subscribe(userDevices => {
      this.myDevices$.next(Object.values(userDevices));
      this.endToLoadData$.next({...this.endToLoadData$.value, userDevices: true});
    });
  }

  public displayAddUsersView(): void {
    this.userAccountManagementService.displayAddUsersView();
  }

  public displayUsersView(): void {
    this.userAccountManagementService.displayUsersView();
    this.userAccountManagementService.removeSelectedUserId();
    this.userTeamsAccountManagementService.displayUserTeamsView();
    this.userTeamsAccountManagementService.removeSelectedUserTeam();
  }

  public addUsers(userInvitations: UserInvitation[]): void {
    this.userAccountManagementService.addUsers(userInvitations);
    this.userAccountManagementService.displayUsersView();
  }

  public filterUsers(search: string) {
    this.userSearchForm.controls.search.setValue(search);
    this.userAccountManagementService.filterUsers(search);
  }

  public selectUser(user: UserTable): void {
    this.userAccountManagementService.selectUser(user);
  }

  public displayAddTeamsView(): void {
    this.teamsAccountManagementService.displayAddTeamsView();
  }

  public displayTeamsView(): void {
    this.teamsAccountManagementService.displayTeamsView();
  }

  public addTeam(name: Team['name']): void {
    this.teamsAccountManagementService.addTeam(name);
    this.teamsAccountManagementService.displayTeamsView();
  }

  public filterTeams(search: string) {
    this.teamsAccountManagementService.filterTeams(search);
  }

  public selectTeam(team: TeamTable) {
    this.teamsAccountManagementService.selectTeam(team);
  }

  public executeUserAction(action: UserAction): void {
    this.userAccountManagementService.executeUserAction(action);
  }

  public executeTeamAction(action: TeamAction): void {
    this.teamsAccountManagementService.executeTeamAction(action);
  }

  public displayUserTeamsView(): void {
    this.userTeamsAccountManagementService.displayUserTeamsView();
  }

  public displayAddUserToTeamsView(): void {
    this.userTeamsAccountManagementService.displayAddUserToTeamsView();
  }

  public displayUserTeamDetailsView(userTeam: UserTeamTable): void {
    this.userTeamsAccountManagementService.displayUserTeamDetailsView();
    this.userTeamsAccountManagementService.selectUserTeam(userTeam);
  }

  public addUserToTeam(userTeamInvitation: UserTeamInvitation): void {
    this.userTeamsAccountManagementService.addUserToTeam(userTeamInvitation);
    this.userTeamsAccountManagementService.displayUserTeamsView();
  }

  public filterUserTeams(search: string): void {
    this.userTeamsAccountManagementService.filterUserTeams(search);
  }

  public executeUserTeamsAction(action: UserTeamAction): void {
    this.userTeamsAccountManagementService.executeUserTeamsAction(action);
  }

  public openAddUsersToTeam(): void {
    this.teamsAccountManagementService.openAddUsersToTeam();
  }

  public openInviteUsersDialog(): void {
    this.userTeamsAccountManagementService.openInviteUsersDialog();
  }

  public changeAnchor(anchor: string): void {}

  public changePassword(): void {
    this.savedEmail$.pipe(take(1)).subscribe(email => {
      this.authService.setIsChangePasswordFlow(true);
      this.authService.triggerForgotPassword(email, this.isDeveloperMode);
    });
  }
}

export interface CustomIcon {
  class: string;
  alt: string;
  src: string;
}

interface UserLoadingData {
  user: boolean;
  company: boolean;
  plans: boolean;
  userDevices: boolean;
}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {map, mergeMap, catchError} from 'rxjs/operators';
import {RolesApiService} from '../../services/roles-api.service';
import {RolesManagementActionTypes} from './roles-management.actions';
import * as RolesManagementActions from './roles-management.actions';

@Injectable()
export class RolesManagementEffects {
  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesManagementActionTypes.LoadRoles),
      mergeMap(() =>
        this.rolesApiService.listRoles().pipe(
          map(roles => RolesManagementActions.loadRolesSuccess({roles})),
          catchError(error => of(RolesManagementActions.loadRolesFailure({error: error.message})))
        )
      )
    )
  );

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesManagementActionTypes.LoadPermissions),
      mergeMap(() =>
        this.rolesApiService.listPermissions().pipe(
          map(permissions => RolesManagementActions.loadPermissionsSuccess({permissions})),
          catchError(error => of(RolesManagementActions.loadPermissionsFailure({error: error.message})))
        )
      )
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesManagementActions.createRole),
      mergeMap(action => {
        const {role} = action;
        const tempRoleId = role.id;
        return this.rolesApiService.createRole(role).pipe(
          map(createdRole => RolesManagementActions.createRoleSuccess({role: createdRole, tempRoleId})),
          catchError(error => of(RolesManagementActions.createRoleFailure({error: error.message})))
        );
      })
    )
  );

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesManagementActions.updateRole),
      mergeMap(action => {
        const {roleId, role} = action;
        return this.rolesApiService.updateRole(roleId, role).pipe(
          map(updatedRole => RolesManagementActions.updateRoleSuccess({roleId, role: updatedRole})),
          catchError(error => of(RolesManagementActions.updateRoleFailure({error: error.message})))
        );
      })
    )
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RolesManagementActionTypes.DeleteRole),
      mergeMap(({roleId}) =>
        this.rolesApiService.deleteRole(roleId).pipe(
          map(() => RolesManagementActions.deleteRoleSuccess({roleId})),
          catchError(error => of(RolesManagementActions.deleteRoleFailure({error: error.message})))
        )
      )
    )
  );

  constructor(private actions$: Actions, private rolesApiService: RolesApiService) {}
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {
  actionForgotPasswordStepTwoFormError,
  actionForgotPasswordStepTwoFormSubmit,
  actionForgotPasswordStepTwoFormSuccess
} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.actions';
import {ForgotPasswordStepTwo} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.model';
import {
  selectForgotPasswordStepTwoFormError,
  selectForgotPasswordStepTwoFormInProgress,
  selectForgotPasswordStepTwoFormValue
} from '@app/auth/components/forgot-password-step-two/forgot-password-step-two.selectors';
import {selectForgotPasswordFormValue} from '@app/auth/components/forgot-password/forgot-password.selectors';
import {actionSignInFormSubmit} from '@app/auth/components/sign-in/sign-in.actions';
import {AuthErrorModel} from '@app/auth/models/amplify-error';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {
  actionForgotPasswordFormSubmit,
  actionForgotPasswordFormSuccess
} from '@app/auth/components/forgot-password/forgot-password.actions';
import {AuthService} from '@app/auth/services/auth.service';

@Component({
  templateUrl: './forgot-password-step-two.page.html',
  styleUrls: ['./forgot-password-step-two.page.scss']
})
export class ForgotPasswordStepTwoPage implements OnInit, OnDestroy {
  public errorMessage$: Observable<string>;
  public forgotPasswordForm$: Observable<ForgotPasswordStepTwo>;
  public isInProgress$: Observable<boolean>;
  public isDeveloperMode = this.route.snapshot.data.isDeveloperMode;
  public isUserInvitation: boolean = false;
  public organizationName: string = null;
  public isCodeExpired$: Observable<boolean> = this.store.pipe(
    select(selectForgotPasswordStepTwoFormError),
    filter(Boolean),
    map(error => error?.code === 'ExpiredCodeException')
  );
  public codeGeneratedSuccessfully: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public codeGenerationError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isChangePasswordFlow$ = this.authService.isChangePasswordFlow.asObservable();

  constructor(
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    const {email, code, organisation} = this.route.snapshot.queryParams;
    this.isUserInvitation = !!(email && code && organisation);

    if (this.isUserInvitation) {
      this.organizationName = organisation;
    }
  }

  public ngOnInit(): void {
    this.handleFormValues();
    this.initializeFormValues();
  }

  public ngOnDestroy(): void {
    this.authService.setIsChangePasswordFlow(false);
  }

  public handleFormValues(): void {
    const email = decodeURIComponent(this.route.snapshot.queryParams['email']);
    const emailIsEmpty = 'undefined';

    if (email !== emailIsEmpty) {
      this.store.dispatch(actionForgotPasswordFormSubmit({form: {email}}));
    }

    this.store.pipe(select(selectForgotPasswordFormValue), take(1)).subscribe(form => {
      if (!form.email) {
        this.goToPreviousAuthStep();
      }
    });

    this.forgotPasswordForm$ = this.store.pipe(
      select(selectForgotPasswordStepTwoFormValue),
      map((item: ForgotPasswordStepTwo) => {
        const code = this.route.snapshot.queryParams['code'];
        const newForm = {...item};
        if (code) {
          newForm.code = code;
        }

        return newForm;
      })
    );
  }

  public initializeFormValues(): void {
    //reset form values
    this.store.dispatch(actionForgotPasswordStepTwoFormSuccess());

    this.translateService.get('auth.forgot.invalidVerification').subscribe(codeMismatchTranslation => {
      this.errorMessage$ = this.store.pipe(
        select(selectForgotPasswordStepTwoFormError),
        filter(Boolean),
        map(error => (error?.code === 'CodeMismatchException' ? codeMismatchTranslation : error.message))
      );
    });

    this.isInProgress$ = this.store.pipe(select(selectForgotPasswordStepTwoFormInProgress));
  }

  public goToPreviousAuthStep(): void {
    const isInitialLoad = !!this.route.snapshot.queryParams['code'];
    if (isInitialLoad) {
      this.router.navigate([`/auth/sign-in`]);
    } else {
      this.router.navigate([`/auth/${this.isDeveloperMode ? 'developer-' : ''}forgot-password`]);
    }
  }

  public goBack() {
    history.back();
  }

  public submit(event: ForgotPasswordStepTwo) {
    this.store.dispatch(actionForgotPasswordStepTwoFormSubmit({form: event}));
    this.store.select(selectForgotPasswordFormValue).subscribe(form => {
      Auth.forgotPasswordSubmit(form.email, event.code, event.password)
        .then(() => {
          this.store.dispatch(actionSignInFormSubmit({form: {email: form.email, password: event.password}}));
          // clear email and in progress state from first page
          this.store.dispatch(actionForgotPasswordFormSuccess());
        })
        .catch((error: AuthErrorModel) => {
          console.info('error', error);
          this.store.dispatch(actionForgotPasswordStepTwoFormError({error}));
        });
    });
  }
  public resendCode() {
    this.store
      .select(selectForgotPasswordFormValue)
      .pipe(
        filter(form => !!form),
        take(1)
      )
      .subscribe(form => {
        Auth.forgotPassword(form.email)
          .then(() => {
            this.codeGeneratedSuccessfully.next(true);
          })
          .catch(error => {
            console.info('error', error);
            this.store.dispatch(actionForgotPasswordStepTwoFormError({error}));
          });
      });
  }
}

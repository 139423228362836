import {icon} from 'leaflet';
import {StatusMarkerColors} from '../atlas.config';

export const createInProgressMarkerIcon = size => {
  const color = StatusMarkerColors.IN_PROGRESS;
  const customSvg = `
  <svg width="${size[0]}" height="${size[1]}" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3" y="3" width="11" height="11" fill="white"/>
<path d="M8.16667 0C3.61667 0 0 3.61667 0 8.16667C0 14.2333 8.16667 23.3333 8.16667 23.3333C8.16667 23.3333 16.3333 14.2333 16.3333 8.16667C16.3333 3.61667 12.7167 0 8.16667 0ZM11.0833 12.8333L8.16667 11.0833L5.25 12.8333L6.06667 9.56667L3.5 7.35L6.88333 7.11667L8.16667 3.96667L9.45 7L12.8333 7.35L10.2667 9.56667L11.0833 12.8333Z" fill="${color}"/>
</svg>
`;
  const svgAsURI = 'data:image/svg+xml;base64,' + btoa(customSvg).replace('#', '%23');

  return icon({
    iconUrl: svgAsURI,
    attribution: color,
    iconAnchor: [size[0] / 2, size[1]]
  });
};

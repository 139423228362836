import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingSpinnerModule} from '@app/shared/loading-spinner/loading-spinner';
import {PlansSharedModule} from '@app/shared/plans/plans.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {AddonDetailsComponent} from './components/addon-details/addon-details.component';
import {CompanyDetailsComponent} from './components/company-details/company-details.component';
import {DataPrivacyComponent} from './components/data-privacy/data-privacy.component';
import {DeleteAllMediaDialogComponent} from './components/delete-all-media-dialog/delete-all-media-dialog.component';
import {DeleteAllMediaInfoDialogComponent} from './components/delete-all-media-info-dialog/delete-all-media-info-dialog.component';
import {DeleteDeviceDialogComponent} from './components/delete-device-dialog/delete-device-dialog.component';
import {DeveloperComponent} from './components/developer/developer.component';
import {AdditionalDeviceDialogComponent} from './components/device/additional-device-dialog/additional-device-dialog.component';
import {DeviceComponent} from './components/device/device.component';
import {LoadingDialogComponent} from './components/loading-dialog/loading-dialog.component';
import {MiniPlanViewerComponent} from './components/mini-plan-viewer/mini-plan-viewer.component';
import {MyProfileComponent} from './components/myprofile.component';
import {OrganisationComponent} from './components/organisation/organisation.component';
import {PlanComponent} from './components/plan/plan.component';
import {StreamingComponent} from './components/streaming/streaming.component';
import {GaugeComponent} from './components/usage/gauge/gauge.component';
import {UsageComponent} from './components/usage/usage.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {ProfileRoutingModule} from './profile-routing.module';
import {UsersTableComponent} from './components/users/users-table/users-table.component';
import {AddUserRowComponent} from './components/users/add-user-row/add-user-row.component';
import {AddUserComponent} from './components/users/add-user/add-user.component';
import {UsersComponent} from './components/users/users/users.component';
import {SearchInputModule} from '@app/shared/search-input/search-input.module';
import {StoreModule} from '@ngrx/store';
import {profileFeatureKey, reducer as profileReducer} from './store/profile.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ProfileEffects} from './store/profile.effects';
import {UsersTileComponent} from './components/users/users-tile/users-tile.component';
import {UserDetailComponent} from './components/users/user-detail/user-detail.component';
import {AddTeamRowComponent} from './components/teams/add-team-row/add-team-row.component';
import {AddTeamComponent} from './components/teams/add-team/add-team.component';
import {TeamsTableComponent} from './components/teams/teams-table/teams-table.component';
import {TeamsTileComponent} from './components/teams/teams-tile/teams-tile.component';
import {TeamsComponent} from './components/teams/teams/teams.component';
import {TeamDetailComponent} from './components/teams/team-detail/team-detail.component';
import {AddUserToTeamRowComponent} from './components/users/add-user-to-team-row/add-user-to-team-row.component';
import {AddUserToTeamComponent} from './components/users/add-user-to-team/add-user-to-team.component';
import {UserTeamsTableComponent} from './components/users/user-teams-table/user-teams-table.component';
import {UserTeamsComponent} from './components/users/user-teams/user-teams.component';
import {UserTeamsTileComponent} from './components/users/user-teams-tile/user-teams-tile.component';
import {ExportDataComponent} from './components/data-privacy/export-data/export-data.component';
import {ExportDataDialogComponent} from './components/data-privacy/export-data-dialog/export-data-dialog.component';
import {UserRolePickerComponent} from './components/users/user-role-picker/user-role-picker.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {SharedPipesModule} from '../shared/pipes/shared-pipes.module';
import {UsersManagementEffects} from '@app/profile/store/users-management/users-management.effects';
import {DeleteUserTeamDialogComponent} from '@app/profile/components/delete-user-team-dialog/delete-user-team-dialog.component';
import {AclDirective} from '@app/core/directives/acl.directive';
import {RoleNamePipe} from '@app/profile/pipes/role-name.pipe';
import {AclDisabledFormControlDirective} from '@app/core/directives/acl-disable-form-control.directive';
import {TeamsManagementEffects} from '@app/profile/store/teams-management/team-management.effects';
import {EditTeamDialogComponent} from './components/teams/edit-team-dialog/edit-team-dialog.component';
import {AddUsersToTeamComponent} from './components/teams/add-users-to-team/add-users-to-team.component';
import {DeleteTeamDialogComponent} from './components/teams/delete-team-dialog/delete-team-dialog.component';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {EnableAuthorizationToggleChangeDirective} from './directives/enable-autorization-toggle.directive';
import {StrftimeToDateFormatPipe} from './pipes/strftime-to-date-format.pipe';
import {ChangeCurrentAnchorDirective} from './directives/change-current-anchor.directive';
import {VerifyMobileNumberDialogComponent} from './components/verify-mobile-number-dialog/verify-mobile-number-dialog.component';
import {NumbersOnlyDirectiveModule} from '@app/shared/numbers-only-directive/numbers-only.module';
import {LeavePageDialogComponent} from './components/leave-page-dialog/leave-page-dialog.component';
import {InfoDialogComponent} from './shared/info-dialog/info-dialog.component';

@NgModule({
  declarations: [
    MyProfileComponent,
    CompanyDetailsComponent,
    LoadingDialogComponent,
    UsageComponent,
    GaugeComponent,
    PlanComponent,
    DeviceComponent,
    StreamingComponent,
    UserProfileComponent,
    DeveloperComponent,
    DeleteDeviceDialogComponent,
    AdditionalDeviceDialogComponent,
    DataPrivacyComponent,
    DeleteAllMediaDialogComponent,
    DeleteAllMediaInfoDialogComponent,
    MiniPlanViewerComponent,
    UsersTableComponent,
    AddUserRowComponent,
    AddUserComponent,
    UsersComponent,
    UsersTileComponent,
    UserDetailComponent,
    AddUserToTeamRowComponent,
    AddUserToTeamComponent,
    TeamsTableComponent,
    AddTeamRowComponent,
    AddTeamComponent,
    TeamsComponent,
    TeamsTileComponent,
    TeamDetailComponent,
    UserTeamsTableComponent,
    UserTeamsComponent,
    UserTeamsTileComponent,
    ExportDataComponent,
    ExportDataDialogComponent,
    UserRolePickerComponent,
    DeleteUserTeamDialogComponent,
    AddUsersToTeamComponent,
    DeleteTeamDialogComponent,
    VerifyMobileNumberDialogComponent,
    LeavePageDialogComponent,
    InfoDialogComponent
  ],
  imports: [
    CommonModule,
    UnleashMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileRoutingModule,
    HttpClientModule,
    LoadingSpinnerModule,
    PlansSharedModule,
    UnleashLoaderModule,
    SearchInputModule,
    MatExpansionModule,
    StoreModule.forFeature(profileFeatureKey, profileReducer),
    EffectsModule.forFeature([ProfileEffects, UsersManagementEffects, TeamsManagementEffects]),
    SharedPipesModule,
    AclDirective,
    RoleNamePipe,
    AclDisabledFormControlDirective,
    SpinnerModule,
    EnableAuthorizationToggleChangeDirective,
    StrftimeToDateFormatPipe,
    AddonDetailsComponent,
    OrganisationComponent,
    EditTeamDialogComponent,
    ChangeCurrentAnchorDirective,
    NumbersOnlyDirectiveModule
  ]
})
export class ProfileModule {}

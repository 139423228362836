import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {Addon} from '@app/store/addon/models/addon';
import {filter, map, Observable, shareReplay, Subscription, take, tap, withLatestFrom} from 'rxjs';
import {StreamScheduleFacadeService} from '@app/live/services/stream-schedule-facade.service';
import {ScheduleModel} from '@app/core/models/api/schedule.model';
import {HeaderState, SCHEDULE_PAGE_STATE, StreamScheduleService} from '@app/live/services/stream-schedule.service';
import {TranslateService} from '@ngx-translate/core';
import {ScheduleMode, ScheduleType} from '@app/live/models/schedule.model';
import {TeamRole} from '@app/profile/models/team.model';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-stream-scheduler-page',
  templateUrl: './stream-scheduler-page.component.html',
  styleUrls: ['./stream-scheduler-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamSchedulerPageComponent implements OnInit, OnDestroy {
  public headerState$: Observable<HeaderState> = this.streamScheduleService.headerState.pipe(shareReplay(1));
  public scheduleList$: Observable<ScheduleModel[]> = this.scheduleFacadeService.selectSchedules$.pipe(shareReplay(1));
  public devices$: Observable<UserDeviceJoined[]> = this.userStoreFacadeService.currentUserDevices$.pipe(
    map(devices => devices?.filter(device => device?.sourceUrl))
  );
  public addons$: Observable<Addon[]> = this.addonStoreFacadeService.getAllActiveAddons();
  public addonsByKey$: Observable<{[key: string]: Addon}> = this.addonStoreFacadeService.addons$;
  public userStreamKey$ = this.userStoreFacadeService.currentUser$.pipe(map(user => user?.streamKey));
  public currentUserTimezone$ = this.userStoreFacadeService.currentUserCompany$.pipe(map(company => company?.timezone));
  public isLoadingSchedules$: Observable<boolean> = this.scheduleFacadeService.isLoadingSchedules$.pipe(shareReplay(1));
  public schedulePageState$: Observable<SCHEDULE_PAGE_STATE> = this.streamScheduleService.schedulePageState.pipe(
    tap(() => this.generateHeaderList()),
    shareReplay(1)
  );
  public schedulePageStaus: typeof SCHEDULE_PAGE_STATE = SCHEDULE_PAGE_STATE;
  public isAdmin$: Observable<boolean> = this.userStoreFacadeService.currentRole$.pipe(
    filter(role => !!role),
    map(role => role === TeamRole.admin),
    shareReplay(1)
  );
  public generateHeaderSub: Subscription;

  constructor(
    private router: Router,
    private userStoreFacadeService: UserStoreFacadeService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private scheduleFacadeService: StreamScheduleFacadeService,
    private streamScheduleService: StreamScheduleService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.scheduleFacadeService.loadSchedules();
    this.generateHeaderList();
  }

  public ngOnDestroy(): void {
    this.streamScheduleService.setSchedulePageState(SCHEDULE_PAGE_STATE.LIST);
  }

  public generateHeaderList(): void {
    this.generateHeaderSub = this.translateService
      .get('live.scheduler.scheduleStreams')
      .pipe(take(1))
      .subscribe(scheduleStreams => {
        this.streamScheduleService.setHeaderState({
          title: scheduleStreams,
          items: []
        });
      });
  }

  public goBack(): void {
    this.schedulePageState$.pipe(take(1)).subscribe(state => {
      switch (state) {
        case SCHEDULE_PAGE_STATE.LIST:
          this.router.navigate(['secure/live']);
          break;
        case SCHEDULE_PAGE_STATE.NEW_SCHEDULE:
          this.streamScheduleService.setSchedulePageState(SCHEDULE_PAGE_STATE.LIST);
      }
    });
  }

  public newSchedule(): void {
    this.streamScheduleService.setSchedulePageState(SCHEDULE_PAGE_STATE.NEW_SCHEDULE);
    this.translateService
      .get(['live.scheduler.emptySchedule', 'live.scheduler.createNewSchedule'])
      .pipe(take(1))
      .subscribe(translations => {
        const {'live.scheduler.emptySchedule': emptySchedule, 'live.scheduler.createNewSchedule': createNewSchedule} =
          translations;

        this.streamScheduleService.setHeaderState({
          title: createNewSchedule,
          items: Object.values(emptySchedule)
        });
      });
  }

  public editSchedule(scheduleId: string): void {
    this.router.navigate([`secure/live/scheduler/${scheduleId}`]);
  }

  public viewSchedule(scheduleId: string): void {
    this.router.navigate([`secure/live/scheduler/${scheduleId}`], {queryParams: {viewMode: true}});
  }

  public goToPrincipalPage(): void {
    this.streamScheduleService.setSchedulePageState(SCHEDULE_PAGE_STATE.LIST);
  }

  public batchCreateSchedule(form: any, streamKey: string): void {
    const scheduleData = {
      deviceIds: form.devices,
      schedules: form.schedules,
      streamKey: streamKey,
      name: form.name,
      scheduleExpressionTimezone: form.scheduleExpressionTimezone,
      addons: form.addons?.map(addonId => ({id: addonId})),
      recurring: form?.type === ScheduleType.RECURRING,
      mode: form.mode
    };
    if (form.type === ScheduleType.ONEOFF) {
      scheduleData['oneOffStartDate'] = form.oneOffStartDate;
    }
    if (form.mode === ScheduleMode.SNAPSHOT) {
      scheduleData['imageSnapshotFrecuency'] = form.imageSnapshotFrecuency;
      scheduleData['imageSnapshotFrecuencyUnits'] = form.imageSnapshotFrecuencyUnits;
    }
    this.scheduleFacadeService.batchCreateSchedule(scheduleData);
  }

  public deleteSchedule({scheduleName, scheduleId}): void {
    this.scheduleFacadeService.deleteSchedules(scheduleName, scheduleId);
  }
}

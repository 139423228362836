import {
  featureGroup,
  FeatureGroup,
  latLng,
  LatLng,
  LatLngBounds,
  Layer,
  LeafletMouseEvent,
  Map,
  marker,
  Marker,
  MarkerOptions,
  polyline
} from 'leaflet';
import 'leaflet-draw';
import 'leaflet-rotatedmarker';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable} from 'rxjs';
import {share, take, map as rxjsMap} from 'rxjs/operators';
import {
  Mission,
  MissionRoutePoint,
  MissionSettingsService,
  SELECTED_WAYPOINT
} from '../../services/mission-settings.service';
import {get} from 'lodash';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MissionType} from '@app/atlas/model/mission.model';
import {DrawOnMapService} from '../draw-on-map.service';
import {markerOverlapZIndex} from '@app/atlas/atlas.config';

declare var L; // leaflet global

export class RouteCreator {
  get totalDistance$(): Observable<number> {
    return this._totalDistance$.asObservable();
  }

  get markers$() {
    return this._markers$.pipe(share());
  }
  private drawnItems: FeatureGroup = featureGroup();
  private markers: Marker[] = [];
  private _markers$: BehaviorSubject<Marker[]> = new BehaviorSubject([]);
  private routeMarkers: FeatureGroup = featureGroup();
  private routeLineOptions = {
    color: '#f3e401',
    weight: 2,
    opacity: 1
  };
  private polylineDrawerControl: any; // new polyline handler
  private editControl: any; // edit route handler
  private tempLatLng: LatLng; // needed for total distance
  private _totalDistance$: BehaviorSubject<number> = new BehaviorSubject<number>(0.0);
  private markerLayer: FeatureGroup = L.featureGroup().addTo(this.map);
  private selectVertexOnEdit: BehaviorSubject<number> = new BehaviorSubject<number>(
    SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED
  );
  private isNewVertex: boolean = false;
  private isNewVertexCreated: Promise<any>;
  private editLayers: Layer[];
  private currentEvent: any;

  constructor(
    private map: Map,
    private missionSettingsService: MissionSettingsService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.map = map;
    this.disableDrawHandlers(map);
    this.createDrawHandlers(map);
    map.addLayer(this.drawnItems);
    map.addLayer(this.routeMarkers);
    DrawOnMapService.addCustomPolyVerticesEdit();
  }

  public get getRouteMarkers() {
    return this.routeMarkers;
  }

  public get getDrawnItems() {
    return this.drawnItems;
  }

  public clearMission(missionId: string): void {
    this.drawnItems.eachLayer(layer => {
      if ((layer.options as any).missionId === missionId) {
        layer.removeFrom(this.map);
      }
    });
    this.routeMarkers.eachLayer(layer => {
      if ((layer.options as any).missionId === missionId) {
        layer.removeFrom(this.map);
      }
    });
    this.markers.forEach(layer => {
      if ((layer.options as any).missionId === missionId) {
        layer.removeFrom(this.map);
      }
    });
  }

  /** Display the mission details on the map */
  public loadMission({
    mission,
    hasToClearLayers,
    routeColor,
    hasToZoomIn
  }: {
    mission: Mission;
    hasToClearLayers?: boolean;
    routeColor?: string;
    hasToZoomIn?: boolean;
  }) {
    if (hasToClearLayers) {
      // clear previous mission
      this.clear();
      this.markerLayer.clearLayers();
    }
    // add route points
    if (mission.type === MissionType.MAPPING_2D) {
      this.drawSurveyMissionPolygon(mission, routeColor, hasToZoomIn);
      this.drawSurveyMissionRoute(mission);
      return;
    }
    const latLngArr = mission.route
      .map((m, i) => {
        const isLastIndex = mission.route.length - 1 === i;
        const addMarkerParams = {
          point: m,
          index: i,
          isLastIndex,
          disableClick: null,
          missionId: mission.id
        };
        if (routeColor) {
          addMarkerParams['routeColor'] = routeColor;
        }
        return this.addMarker(addMarkerParams);
      })
      .filter(latLng => !!latLng);
    if (latLngArr.length === 0) {
      return;
    }
    // draw polyline
    this.routeLineOptions.color = routeColor ? routeColor : '#f3e401';
    const route = polyline(latLngArr, {...this.routeLineOptions, missionId: mission.id} as any);
    this.drawnItems.addLayer(route);
    this._markers$.next(this.markers);

    // center map
    this.fitBounds(route.getBounds(), hasToZoomIn);
  }

  private drawSurveyMissionRoute(mission: Mission) {
    const route = mission.waylineFolders[0].route;
    const latLngArr = route
      .map((marker, index) => {
        const isLastIndex = route.length - 1 === index;
        const addMarkerParams = {
          point: marker as any,
          index: index,
          isLastIndex,
          disableClick: true
        };
        return this.addMarker(addMarkerParams);
      })
      .filter(latLng => !!latLng);
    if (latLngArr.length === 0) {
      return;
    }
    // draw polyline
    const routeDraw = polyline(latLngArr, this.routeLineOptions);
    this.drawnItems.addLayer(routeDraw);
    this._markers$.next(this.markers);
  }

  private drawSurveyMissionPolygon(mission: Mission, routeColor: string, hasToZoomIn: boolean) {
    const surveyCoordinates =
      mission?.surveySettings?.polygon?.coordinates.map(element => [element.latitude, element.longitude]) || [];
    if (surveyCoordinates.length > 0) {
      const polygon = L.polygon(surveyCoordinates, {
        color: routeColor || '#11ACA4',
        weight: 2,
        fill: false
      });

      polygon.addTo(this.map);
      this.fitBounds(L.latLngBounds(surveyCoordinates), hasToZoomIn);
    }
  }

  public fitBounds(coordinates: LatLngBounds, hasToZoomIn: boolean) {
    setTimeout(() => {
      this.map.invalidateSize();
      this.map.fitBounds(coordinates, hasToZoomIn ? {} : {maxZoom: this.map.getZoom()});
    }, 200);
  }

  public clear() {
    this.onRouteDeletedEvent();
    this.drawnItems.eachLayer(l => l.removeFrom(this.map));
  }

  public newRoute() {
    this.polylineDrawerControl.enable();
  }
  public editRoute() {
    this.drawnItems.eachLayer((polyline: any) => {
      // fix https://github.com/Leaflet/Leaflet.draw/issues/804
      polyline.options.editing || (polyline.options.editing = {});
      polyline.editing.enable();
    });
    this.map.fireEvent(L.Draw.Event.EDITSTART);
  }

  public editSettings() {
    this.onRouteEditedEvent({layers: this.drawnItems});
  }

  public editSave() {
    this.polylineDrawerControl.disable();

    if (this.markers.length === 0) {
      return false;
    }
    this.drawnItems.eachLayer((polyline: any) => {
      polyline.editing.disable();
    });
    this.map.fireEvent(L.Draw.Event.EDITSTOP);

    return true;
  }

  public editStop() {
    this.map.fireEvent(L.Draw.Event.EDITSTOP);
  }

  public stopDrawing() {
    this.polylineDrawerControl.disable();
  }

  public selectRoutePoint(waypointIndex: number): void {
    this.missionSettingsService.setSelectedWaypointIndex(waypointIndex);
    if (this.missionSettingsService.isEditing.value) {
      this.selectVertexOnEdit.next(waypointIndex);
      return;
    }
    this.addSelectedWaypointClass(this.markerLayer.getLayers(), 'options.routeIndex', waypointIndex);
  }

  public deselectRouteEditPoint(waypointIndex: number): void {
    const allMarkers = this.editLayers;
    allMarkers.forEach((marker: Marker) => {
      if ((marker as any)?._index === waypointIndex) {
        const iconDivClassList = (marker as any)._icon.firstChild.nextSibling.classList;
        iconDivClassList.remove('selected-waypoint');
      }
    });
  }
  public deselectRoutePoint(waypointIndex: number): void {
    const allMarkers = this.markerLayer.getLayers();
    allMarkers.forEach((marker: Marker) => {
      if ((marker.options as any).routeIndex === waypointIndex) {
        const iconDivClassList = (marker as any)._icon.firstChild.nextSibling.classList;
        iconDivClassList.remove('selected-waypoint');
      }
    });
  }

  private addSelectedWaypointClass(allMarkers: Layer[], markerIndex: string, waypointIndex: number): void {
    allMarkers.forEach((marker: Marker) => {
      const iconDivClassList = (marker as any)._icon?.firstChild?.nextElementSibling.classList;
      if (!iconDivClassList) {
        return;
      }
      if (get(marker, markerIndex) === waypointIndex) {
        iconDivClassList.add('selected-waypoint');
        return;
      }
      iconDivClassList.remove('selected-waypoint');
    });
  }

  private generateWaypointIcons(marker: Element, isLastIndex: boolean, index: number, heading: number) {
    marker.innerHTML =
      isLastIndex || index === 0
        ? this.generateGreenIconLimits(isLastIndex, heading)
        : this.generateGreenIconInternWaypoints(index, heading);
  }

  private listenSelectedVertexOnEdit() {
    //add selected vertex style in the map
    this.selectVertexOnEdit.subscribe(async index => {
      if (index === SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED) {
        return;
      }
      if (this.isNewVertex) {
        //await until new vertex is drawn
        await this.isNewVertexCreated;
      }
      if (index >= 0) {
        const allMarkers: Layer[] = this.editLayers.filter((layer: L.Marker) =>
          layer.options.icon.options.className?.includes('custom-route-waypoint')
        ) as Layer[];
        this.addSelectedWaypointClass(allMarkers, '_index', index);
      }
    });
  }

  private generateVertexIconsOnEdit() {
    //generate new custom icons for vertex
    this.missionSettingsService.editingMission.pipe(rxjsMap(editMission => editMission.mission)).subscribe(mission => {
      //remove vertex from edit UI
      if (this.missionSettingsService.indexToRemove !== SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED) {
        const indexLayer = this.editLayers.findIndex(
          (layer: any) => layer?._index === this.missionSettingsService.indexToRemove && !!layer._icon
        );
        if (indexLayer >= 0) {
          (this.editLayers[indexLayer] as any)._icon.click();
          this.currentEvent.object._removeVertex(this.currentEvent.data);
        }
      }
      //redraw waypoints if lat or lng where updated from UI settings
      if (this.missionSettingsService.hasToRedraw) {
        this.redrawWaypoints(this.editLayers, mission);
        this.missionSettingsService.sethasToRedraw(false);
      }
      //generate icons with refresh heading position
      if (this.missionSettingsService.hasToRegenerateVertex) {
        const markers = this.editLayers.filter(
          (layer: any) => layer.options.icon?.options.className?.includes('custom-route-waypoint') && layer?._icon
        ) as any;
        markers.forEach(marker => {
          marker.setZIndexOffset(markerOverlapZIndex);
          const isLastIndex = markers.length - 1 === marker._index;
          this.generateWaypointIcons(marker._icon, isLastIndex, marker._index, mission?.route[marker._index].heading);
          if (marker._index === this.missionSettingsService.selectedWaypointIndex) {
            const iconDivClassList = (marker._icon.firstChild.nextSibling as any).classList;
            iconDivClassList.add('selected-waypoint');
          }
        });
        this.missionSettingsService.setHasToRegenerateVertex(false);
      }
    });
  }

  private redrawWaypoints(layers, mission) {
    const allMarkers = Object.values(layers).filter(layers => layers instanceof L.Marker);
    const selectedWaypointIndex = this.missionSettingsService.selectedWaypointIndex;
    const marker = allMarkers.find((marker: any) => marker._index === selectedWaypointIndex) as any;
    const route = mission.route[selectedWaypointIndex];
    const newLatLng = L.latLng(route.lat, route.lng);
    marker.setLatLng(newLatLng);
    this.drawnItems.eachLayer((drawnLayer: any) => {
      const vertex = drawnLayer.getLatLngs()[selectedWaypointIndex];
      vertex.lat = route.lat;
      vertex.lng = route.lng;
      this.setNewMiddleMarkersPosition(marker, allMarkers, vertex);
      drawnLayer.redraw();
    });
  }

  private setNewMiddleMarkersPosition(marker, allMarkers, vertex) {
    if (marker._middleRight) {
      const rightMarker = allMarkers.find(
        (marker: any) => marker._index === this.missionSettingsService.selectedWaypointIndex + 1
      ) as any;
      const startPoint = L.latLng((rightMarker as any)._latlng.lat, (rightMarker as any)._latlng.lng);
      const endPoint = L.latLng(vertex.lat, vertex.lng);
      marker._middleRight.setLatLng(this.calculateMiddleLatLng(startPoint, endPoint));
    }
    if (marker._middleLeft) {
      const leftMarker = allMarkers.find(
        (marker: any) => marker._index === this.missionSettingsService.selectedWaypointIndex - 1
      ) as any;
      const startPoint = L.latLng((leftMarker as any)._latlng.lat, (leftMarker as any)._latlng.lng);
      const endPoint = L.latLng(vertex.lat, vertex.lng);
      marker._middleLeft.setLatLng(this.calculateMiddleLatLng(startPoint, endPoint));
    }
  }

  private calculateMiddleLatLng(startLatLng: LatLng, endLatLng: LatLng) {
    // eslint-disable-next-line no-magic-numbers
    const lat = (startLatLng.lat + endLatLng.lat) / 2;
    // eslint-disable-next-line no-magic-numbers
    const lng = (startLatLng.lng + endLatLng.lng) / 2;
    return L.latLng(lat, lng);
  }

  private createNewVertex(customRouteWaypoints: Layer[], newLayer: any) {
    return combineLatest([
      this.missionSettingsService.settings$.pipe(take(1)),
      this.missionSettingsService.editingMission.pipe(take(1))
    ]).pipe(
      rxjsMap(([defaultSettings, editMission]) => {
        const newIndex = newLayer?._index;
        if (!newIndex) {
          return;
        }
        const mission = editMission.mission;
        mission.route.splice(newIndex, 0, {...defaultSettings, ...newLayer._latlng});
        this.missionSettingsService.setEditingMission({
          mission,
          selectedWaypointIndex: newIndex
        });
        customRouteWaypoints.forEach((element: any, _: number, layersArray: any[]) => {
          const isLastIndex = layersArray.length - 1 === element._index;
          this.generateWaypointIcons(
            element._icon,
            isLastIndex,
            element._index,
            mission.route[element._index]?.heading
          );
        });
        //Important to get the next created vertex
        newLayer.options.icon.options.newIndex = null;
        return '';
      })
    );
  }

  public disableDrawHandlers(map): void {
    map.off(L.Draw.Event.DRAWVERTEX);
    map.off(L.Draw.Event.EDITSTART);
    map.off(L.Draw.Event.EDITSTOP);
    map.off(L.Draw.Event.CREATED);
    map.off(L.Draw.Event.EDITREMOVEVERTEX);
    map.off(L.Draw.Event.EDITCLICKVERTEX);
    map.off(L.Draw.Event.EDITVERTEX);
    map.off(L.Draw.Event.EDITED);
    map.off(L.Draw.Event.DELETED);
  }

  private createDrawHandlers(map: Map) {
    // todo - options not being applied
    this.polylineDrawerControl = new L.Draw.Polyline(this.map, this.routeLineOptions);
    this.editControl = new L.EditToolbar.Edit(this.map, {
      featureGroup: this.drawnItems
      // selectedPathOptions: this.options.edit.selectedPathOptions,
      // poly: this.options.poly
    });
    map.on(L.Draw.Event.EDITSTART, async (event: any) => {
      this.editLayers = Object.values(event.target._targets).filter(layers => layers instanceof L.Marker) as Layer[];
      this.missionSettingsService.setIsEditingMission(true);
      this.missionSettingsService.setHasToRegenerateVertex(true);
      this.listenSelectedVertexOnEdit();
      this.generateVertexIconsOnEdit();
      this.toggleRouteMarkers(false); // hide drone markers
    });
    map.on(L.Draw.Event.EDITSTOP, () => {
      this.toggleRouteMarkers(true); // show drone markers
      this.missionSettingsService.editStop();
      this.editControl.save();
      this.missionSettingsService.setIsEditingMission(false);
      this.selectVertexOnEdit.next(SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED);
    });
    map.on(L.Draw.Event.CREATED, this.onRouteCreatedEvent.bind(this));
    map.on(L.Draw.Event.EDITREMOVEVERTEX, (event: any) => {
      this.removeVertex(event.layer);
    });
    map.on(L.Draw.Event.EDITCLICKVERTEX, (event: any) => {
      this.currentEvent = event;
      this.missionSettingsService.indexToRemove = SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED;
      this.selectWaypointOnEdit(event.data.target._index);
    });
    map.on(L.Draw.Event.EDITVERTEX, (event: any) => {
      this.currentEvent = event;
      const layers = Object.values(event.target._targets).filter(layers => layers instanceof L.Marker) as Layer[];
      this.editLayers = layers;
      const newLayer = this.getNewLayer(layers);
      if (!newLayer) {
        //only drag
        this.missionSettingsService.setSelectedWaypointIndex(event.data.target._index);
        this.updateEditedVertexSettings(layers);
        return;
      }
      newLayer.off('mouseover');
      this.editVertex(newLayer, layers);
      this.missionSettingsService.redoStack.next([]);
    });
    map.on(L.Draw.Event.EDITED, this.onRouteEditedEvent.bind(this));
    map.on(L.Draw.Event.DELETED, this.onRouteDeletedEvent.bind(this));
    map.on(L.Draw.Event.DRAWVERTEX, (e: any) => {
      this.missionSettingsService.settings$.pipe(take(1)).subscribe(routePoint => {
        const layers = Object.values(e.layers._layers);
        const drawedLayer = layers[layers.length - 1];
        const position = (drawedLayer as any).getLatLng();
        const lat = position.lat;
        const lng = position.lng;
        const latLong = latLng({lat, lng, alt: routePoint.altitude});
        this.calculateTotalDistance(latLong);
        this.totalDistance$.pipe(take(1)).subscribe(distance => {
          const time = this.calculateTime(routePoint.altitude, routePoint.speed, distance);
          // eslint-disable-next-line no-magic-numbers
          const distanceInKilometers = distance / 1000;
          this.missionSettingsService.newVertexDrawed.next({
            waypoint: {...routePoint, lat, lng},
            distance: distanceInKilometers,
            time
          });
        });
      });
    });
  }

  private calculateTime(altitude: number, speed: number, distance: number): number {
    // eslint-disable-next-line no-magic-numbers
    const goingUpAndDownTime = (altitude / 2) * 2;
    const takeoffAndLandingTime = 15;
    const flyingPathTime = distance / speed;
    return Math.round(flyingPathTime + goingUpAndDownTime + takeoffAndLandingTime);
  }

  private selectWaypointOnEdit(selectedIndex: number) {
    this.selectRoutePoint(selectedIndex);
    this.missionSettingsService.editingMission.pipe(take(1)).subscribe(editMission => {
      this.missionSettingsService.setEditingMission({
        mission: editMission.mission,
        selectedWaypointIndex: selectedIndex
      });
    });
  }

  private removeVertex(layer: any) {
    this.missionSettingsService.editingMission.pipe(take(1)).subscribe(currentMission => {
      currentMission.mission.route.splice(layer._index, 1);
      this.missionSettingsService.setHasToRegenerateVertex(true);
      this.missionSettingsService.indexToRemove = SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED;
      const mission = currentMission.mission;
      const maxIndex = mission.route.length - 1;
      const newSelectedIndex =
        maxIndex < this.missionSettingsService.selectedWaypointIndex
          ? maxIndex
          : this.missionSettingsService.selectedWaypointIndex;
      this.missionSettingsService.setEditingMission({
        mission: mission,
        selectedWaypointIndex: newSelectedIndex
      });
      const indexLayer = this.editLayers.findIndex((layer: any) => layer?._index === layer._index);
      if (indexLayer !== -1) {
        this.editLayers.splice(indexLayer, 1);
      }
      this.translate
        .get('atlas.mission.waypointRemoved')
        .pipe(take(1))
        .subscribe(translations => {
          this.snackBar.open('', null); //empty snackbar added to fix its location
          this.snackBar.open(translations, null, {panelClass: 'mission-uploaded', duration: 3000});
        });
      setTimeout(() => {
        this.map.invalidateSize();
      }, 200);
    });
  }

  private editVertex(newLayer: Layer, layers: Layer[]) {
    this.isNewVertex = true;
    this.isNewVertexCreated = lastValueFrom(this.createNewVertex(this.getCustomRouteWaypoints(layers), newLayer));
    this.isNewVertex = false;
    this.translate
      .get('atlas.mission.waypointAdded')
      .pipe(take(1))
      .subscribe(translations => {
        this.snackBar.open('', null); //empty snackbar added to fix its location
        this.snackBar.open(translations, null, {panelClass: 'mission-uploaded', duration: 3000});
      });
  }

  private getNewLayer(layers: Layer[]) {
    const customRouteWaypoints = this.getCustomRouteWaypoints(layers);
    return customRouteWaypoints.filter(layer => !!(layer as any).options.icon.options?.newIndex)[0] as any;
  }

  private getCustomRouteWaypoints(layers: Layer[]) {
    return layers.filter((layer: any) => layer.options.icon.options.className?.includes('custom-route-waypoint'));
  }

  private updateEditedVertexSettings(layers: any) {
    const allLayers = Object.values(layers);
    const selectedWaypointIndex = this.missionSettingsService.selectedWaypointIndex;
    const layer = allLayers.find((layer: any) => layer._index === selectedWaypointIndex) as any;
    const editingMission = this.missionSettingsService.editingMission.value.mission;
    if (!layer || !editingMission) {
      return;
    }
    editingMission.route[selectedWaypointIndex] = {
      ...editingMission.route[selectedWaypointIndex],
      lat: layer._latlng.lat,
      lng: layer._latlng.lng
    };
    this.missionSettingsService.setEditingMission({
      mission: editingMission,
      selectedWaypointIndex: selectedWaypointIndex
    });
  }

  private toggleRouteMarkers(isVisible: boolean) {
    if (isVisible) {
      this.routeMarkers.addTo(this.map);
    } else {
      this.routeMarkers.removeFrom(this.map);
    }
  }

  private onRouteDeletedEvent() {
    // deleting whole route

    // clear total distance
    this.resetDistance();

    // delete all route markers
    for (const marker of this.markers) {
      this.routeMarkers.removeLayer(marker);
    }
    this.markers = [];
    this._markers$.next(this.markers);
  }

  private onRouteEditedEvent(e: any) {
    this.missionSettingsService.settings$.pipe(take(1)).subscribe(settings => {
      const {layers} = e;
      // there should be only one layer for route
      layers.eachLayer((layer: Layer | any) => {
        // update route markers and create/delete if necessary
        layer._latlngs.forEach((latlng: LatLng, ind, layers) => {
          const marker: Marker = this.markers.find((marker: Marker | any) => marker._latlng === latlng);
          if (!marker) {
            // create new marker
            const addMarkerParams = {
              point: {...settings, ...latlng} as unknown as MissionRoutePoint,
              index: ind,
              isLastIndex: layers.length - 1 === ind
            };
            this.addMarker(addMarkerParams);
          } else {
            // update existing marker
            marker.setLatLng(latlng);
          }
        });

        const numberToRemove = this.markers.length - layer._latlngs.length;
        if (numberToRemove > 0) {
          const markers = this.markers.splice(-numberToRemove); // remove few last elements
          for (const m of markers) {
            try {
              this.routeMarkers.removeLayer(m);
            } catch (e) {
              console.error('e', e);
              // IGNORE exception: Cannot read property 'disable' of undefined
            }
          }
        }
      });
      this._markers$.next(this.markers);
    });
  }

  private onRouteCreatedEvent(e: any) {
    this.missionSettingsService.settings$.pipe(take(1)).subscribe(settings => {
      const {layerType, layer} = e;
      if (layerType === 'polyline') {
        // draw markers
        layer._latlngs.forEach((latLng: LatLng, index: number, array: LatLng[]) => {
          const isLastIndex = array.length - 1 === index;
          const addMarkerParams = {
            point: {...settings, ...latLng} as unknown as MissionRoutePoint,
            index: index,
            isLastIndex
          };
          this.addMarker(addMarkerParams);
        });
      }
      this.drawnItems.addLayer(layer);
      this._markers$.next(this.markers);
      this.editRoute();
      this.missionSettingsService.isNewRoute.next(false);
    });
  }

  private generateGreenIconLimits(isLastIndex: boolean, rotate: number, routeColor?: string): string {
    return `
    <div class="limit-waypoint" style="background-color:${routeColor}">
     <span>${isLastIndex ? 'E' : 'S'}</span> 
     <div class="triangle-container" style="rotate:${rotate}deg">
    <div class="triangle" style="border-bottom-color:${routeColor}"></div> 
    </div>
    </div>
  `;
  }

  private generateGreenIconInternWaypoints(index: number, rotate: number, routeColor?: string): string {
    return `
    <div class="intern-waypoint" style="border-color:${routeColor}">
    ${index + 1}
    <div class="triangle-container" style="rotate:${rotate}deg">
    <div class="triangle" style="border-bottom-color:${routeColor}"></div> 
    </div>
    </div>
  `;
  }

  private drawMarker(latLng: L.LatLngExpression, iconHtml: string) {
    const customIcon = L.divIcon({
      className: 'custom-route-icon',
      html: iconHtml,
      iconSize: [20, 20]
    });

    L.marker(latLng, {
      icon: customIcon,
      draggable: false
    }).addTo(this.map);
  }

  private addMarker({
    point,
    index,
    isLastIndex,
    disableClick,
    missionId,
    routeColor
  }: {
    point: MissionRoutePoint;
    index?: number;
    isLastIndex?: boolean;
    disableClick?: boolean;
    missionId?: string;
    routeColor?: string;
  }) {
    const {pitch, speed, heading, lat, lng, altitude} = point;
    if (!lat || !lng) {
      return null;
    }
    const iconHtml =
      isLastIndex || index === 0
        ? this.generateGreenIconLimits(isLastIndex, heading, routeColor)
        : this.generateGreenIconInternWaypoints(index, heading, routeColor);
    const customIcon = L.divIcon({
      className: 'custom-route-icon',
      html: iconHtml,
      iconSize: [20, 20]
    });
    const latLong = latLng({lat, lng, alt: altitude});
    const markerItem: any = marker(latLong, {
      icon: customIcon,
      draggable: false,
      routeIndex: index,
      missionId
    } as MarkerOptions | any)
      .on('click', (mouseEvent: LeafletMouseEvent) => {
        if (disableClick) {
          return;
        }
        this.missionSettingsService.selectMarker(markerItem);
      })
      .addTo(this.markerLayer);
    markerItem.setZIndexOffset(markerOverlapZIndex);
    this.calculateTotalDistance(latLong);
    if (index >= 0) {
      this.markers.splice(index, 0, markerItem);
    } else {
      this.markers.push(markerItem);
    }
    this.routeMarkers.addLayer(markerItem);
    this.missionSettingsService.saveMarkerSettings(markerItem, {
      altitude,
      pitch,
      speed,
      heading
    } as MissionRoutePoint);
    return latLong;
  }

  private resetDistance() {
    this._totalDistance$.next(0.0);
    this.tempLatLng = null;
  }

  private calculateTotalDistance(latLng: LatLng) {
    if (this.tempLatLng == null) {
      this.tempLatLng = latLng;
      return;
    }

    const total = this._totalDistance$.getValue();
    const distanceTo = this.tempLatLng.distanceTo(latLng);
    this._totalDistance$.next(total + distanceTo);
    this.tempLatLng = latLng;
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translateAction'
})
export class TranslateActionPipe implements PipeTransform {
  public actions = {
    TAKE_PHOTO: 'Take Photo',
    TAKE_PHOTO_FIXED_ANGLE: 'Take Photo Fixed Angle',
    START_RECORD: 'Start Record',
    STOP_RECORD: 'Stop Record',
    FOCUS: 'Focus',
    FOCUS_TYPE: 'Set Focus Type',
    ZOOM: 'Zoom',
    CUSTOM_DIR_NAME: 'Create New Folder',
    GIMBAL_ROTATE: 'Gimbal Rotate',
    GIMBAL_ROTATE_EVENLY: 'Gimbal Evenly Rotate',
    ROTATE_YAW: 'Drone Rotation',
    HOVER: 'Hover And Wait',
    START_SMART_OBLIQUE: 'Start Smart Oblique',
    STOP_SMART_OBLIQUE: 'Stop Smart Oblique',
    START_CONTINUOUS_SHOOTING: 'Start Continuous Shooting',
    STOP_CONTINUOUS_SHOOTING: 'Stop Continuous Shooting',
    START_TIMELAPSE: 'Start TimeLapse',
    STOP_TIMELAPSE: 'Stop TimeLapse',
    //Corridor actions
    WAIT: 'Wait',
    START_VIDEO: 'Start Video',
    STOP_VIDEO: 'Stop Video',
    GIMBAL_PITCH: 'Gimbal Pitch'
  };
  public transform(action: string): unknown {
    return this.actions[action] || action;
  }
}

import {HttpClient} from '@angular/common/http';
import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import * as L from 'leaflet';
import {Map} from 'leaflet';
import * as esriLeaflet from 'esri-leaflet';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';
import {AtlasService} from './atlas.service';
import {TileSessionService} from './tile-session.service';

@Injectable({
  providedIn: 'root'
})
export class ArcgisAssetLoaderService extends AbstractAssetLoaderService {
  constructor(
    protected atlasService: AtlasService,
    protected http: HttpClient,
    protected tileSessionService: TileSessionService,
    protected sanitizer: DomSanitizer,
    protected route: ActivatedRoute
  ) {
    super(atlasService, http, tileSessionService, sanitizer, route);
  }

  /**
   * Loads an ArcGIS asset onto the map
   * @param asset The asset model to load
   * @param map The Leaflet map instance
   * @returns Promise resolving to the number of layers added
   */
  public async load(asset: AtlasAssetModel, map: Map): Promise<number> {
    try {
      const assetError = await this.catchAssetsErrors(asset);
      if (assetError) {
        console.error('[ArcgisLoader] Asset validation error:', assetError);
        throw new Error(assetError);
      }

      this.atlasService.isLoading$.next(true);

      const arcgisUrl = asset.key;
      let featureLayer = await this.createLayer(arcgisUrl, asset);
      const defaultBounds = this.getDefaultBounds();

      this.addLayerToMap(map, asset, featureLayer, defaultBounds);

      if (arcgisUrl.includes('FeatureServer')) {
        await this.setupFeatureLayerEvents(featureLayer, map, asset, defaultBounds);
      }

      return 1;
    } catch (error) {
      console.error('[ArcgisLoader] Unexpected error in load method:', error);
      this.atlasService.isLoading$.next(false);
      throw error;
    }
  }

  /**
   * Creates the appropriate layer based on the ArcGIS URL type
   * @param arcgisUrl The ArcGIS service URL
   * @param asset The asset model
   * @returns The created Esri layer
   */
  private async createLayer(arcgisUrl: string, asset: AtlasAssetModel): Promise<any> {
    if (arcgisUrl.includes('FeatureServer')) {
      return this.createFeatureServerLayer(arcgisUrl, asset);
    } else if (arcgisUrl.includes('MapServer')) {
      return this.createMapServerLayer(arcgisUrl);
    } else {
      return this.createTiledLayer(arcgisUrl);
    }
  }

  /**
   * Creates a Feature Server layer with appropriate options
   * @param url The Feature Server URL
   * @param asset The asset model
   * @returns The created Feature Server layer
   */
  private createFeatureServerLayer(url: string, asset: AtlasAssetModel): any {
    try {
      const featureLayerOptions: any = {url};

      if (asset.propertyFilter && Object.keys(asset.propertyFilter).length > 0) {
        featureLayerOptions.where = this.buildWhereClause(asset.propertyFilter);
      }

      featureLayerOptions.onEachFeature = (feature, layer) => {
        this.bindPopupToFeature(feature, layer, asset);
      };

      featureLayerOptions.pointToLayer = (feature, latlng) => {
        return L.circleMarker(latlng, {
          radius: 7,
          fillColor: asset.fillColor || '#3388ff',
          color: asset.strokeColor || '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
        });
      };

      return esriLeaflet.featureLayer(featureLayerOptions);
    } catch (e) {
      console.error('[ArcgisLoader] Error creating FeatureServer layer:', e);
      throw e;
    }
  }

  /**
   * Creates a Map Server layer
   * @param url The Map Server URL
   * @returns The created Map Server layer
   */
  private createMapServerLayer(url: string): any {
    try {
      const layer = esriLeaflet.dynamicMapLayer({
        url,
        opacity: 0.7
      });
      return layer;
    } catch (e) {
      console.error('[ArcgisLoader] Error creating MapServer layer:', e);
      throw e;
    }
  }

  /**
   * Creates a Tiled layer (default for other services)
   * @param url The service URL
   * @returns The created Tiled layer
   */
  private createTiledLayer(url: string): any {
    try {
      const layer = esriLeaflet.tiledMapLayer({url});
      return layer;
    } catch (e) {
      console.error('[ArcgisLoader] Error creating tiled layer:', e);
      throw e;
    }
  }

  /**
   * Returns the default bounds for initial layer display
   * @returns Default LatLngBounds object
   */
  private getDefaultBounds(): L.LatLngBounds {
    return L.latLngBounds([
      [-90, -180],
      [90, 180]
    ]);
  }

  /**
   * Adds a layer to the map with the specified bounds
   * @param map The Leaflet map
   * @param asset The asset model
   * @param layer The layer to add
   * @param bounds The bounds for the layer
   */
  private addLayerToMap(map: Map, asset: AtlasAssetModel, layer: any, bounds: L.LatLngBounds): void {
    super.addAsset(map, {
      id: asset.id,
      layer,
      name: asset.name,
      bounds
    });

    this.atlasService.handleDetectAssetChanges();
    this.atlasService.isLoading$.next(false);
  }

  /**
   * Sets up event handlers for Feature Server layers
   * @param featureLayer The Feature Server layer
   * @param map The Leaflet map
   * @param asset The asset model
   * @param defaultBounds Default bounds to use if layer bounds cannot be determined
   * @returns Promise that resolves when the layer is loaded
   */
  private setupFeatureLayerEvents(
    featureLayer: any,
    map: Map,
    asset: AtlasAssetModel,
    defaultBounds: L.LatLngBounds
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      featureLayer.on('load', () => {
        try {
          const bounds = this.getLayerBounds(featureLayer, defaultBounds, asset);
          this.addLayerToMap(map, asset, featureLayer, bounds);
          resolve();
        } catch (error) {
          console.error('[ArcgisLoader] Error processing ArcGIS layer', error);
          reject(error);
        }
      });
    });
  }

  /**
   * Gets the bounds of a layer, with fallback to default bounds
   * @param featureLayer The Feature Server layer
   * @param defaultBounds Default bounds to use if layer bounds cannot be determined
   * @param asset The asset model for updating bounds via query
   * @returns The layer bounds
   */
  private getLayerBounds(featureLayer: any, defaultBounds: L.LatLngBounds, asset: AtlasAssetModel): L.LatLngBounds {
    try {
      if (featureLayer.getBounds) {
        return featureLayer.getBounds();
      } else if (featureLayer.query) {
        this.queryLayerBounds(featureLayer, asset);
        return defaultBounds;
      }
      return defaultBounds;
    } catch (e) {
      console.warn('[ArcgisLoader] Could not get bounds from ArcGIS layer, using default', e);
      return defaultBounds;
    }
  }

  /**
   * Queries a layer for its bounds and updates the asset when available
   * @param featureLayer The Feature Server layer
   * @param asset The asset model to update
   */
  private queryLayerBounds(featureLayer: any, asset: AtlasAssetModel): void {
    featureLayer.query().bounds(
      function (error, latLngBounds) {
        if (!error && latLngBounds && latLngBounds.isValid()) {
          const assetToUpdate = this.atlasService.getAssetById(asset.id);
          if (assetToUpdate) {
            assetToUpdate.bounds = latLngBounds;
          }
        }
      }.bind(this)
    );
  }

  protected catchAssetsErrors(asset: AtlasAssetModel): Promise<string> {
    // This method doesn't need async operations, but keeping the Promise return type for backward compatibility
    if (!asset) {
      console.error('[ArcgisLoader] Asset is empty');
      return Promise.resolve('Asset is empty');
    }

    if (!asset.key) {
      console.error('[ArcgisLoader] Asset key is empty');
      return Promise.resolve('Asset key is empty');
    }

    if (!asset.key.includes('arcgis/rest/services')) {
      console.error('[ArcgisLoader] Invalid ArcGIS services URL:', asset.key);
      return Promise.resolve('Invalid ArcGIS services URL');
    }

    if (
      !asset.key.includes('FeatureServer') &&
      !asset.key.includes('MapServer') &&
      !asset.key.includes('ImageServer')
    ) {
      console.error('[ArcgisLoader] URL must point to a FeatureServer, MapServer, or ImageServer:', asset.key);
      return Promise.resolve('URL must point to a FeatureServer, MapServer, or ImageServer');
    }

    return Promise.resolve(null);
  }

  /**
   * Binds a popup to a feature with all its properties
   * @param feature The ArcGIS feature
   * @param layer The Leaflet layer
   * @param asset The asset model
   */
  private bindPopupToFeature(feature, layer, asset: AtlasAssetModel): void {
    if (!feature || !layer) return;

    layer.on('click', () => {
      layer.unbindPopup();
      layer.closePopup();

      if (this.atlasService.hasToBlockEvents) {
        return;
      }

      const popupContent = this.generatePopupContent(feature, asset);

      layer.bindPopup(popupContent, {
        maxWidth: 300,
        className: 'arcgis-popup'
      });
      layer.openPopup();
    });
  }

  /**
   * Generates HTML content for the popup based on feature attributes
   * @param feature The ArcGIS feature
   * @param asset The asset model
   * @returns HTML string for popup content
   */
  private generatePopupContent(feature, asset: AtlasAssetModel): string {
    const properties = feature.properties || {};

    const rawTitle = properties.title || properties.name || asset.name || 'ArcGIS Feature';
    const safeTitle = this.sanitizer.sanitize(SecurityContext.HTML, rawTitle?.toString() || '');

    let content = `<div class="popup-content"><h4>${safeTitle}</h4>`;

    content += '<table class="popup-table">';

    Object.keys(properties).forEach(key => {
      if (['shape', 'Shape', 'SHAPE', 'objectid', 'OBJECTID', 'FID', 'fid'].includes(key)) {
        return;
      }

      const value = properties[key];
      if (value !== null && value !== undefined) {
        const safeKey = this.sanitizer.sanitize(SecurityContext.HTML, key?.toString() || '');
        const safeValue = this.sanitizer.sanitize(SecurityContext.HTML, value?.toString() || '');
        content += `<tr><td><strong>${safeKey}</strong></td><td>${safeValue}</td></tr>`;
      }
    });

    content += '</table></div>';
    return content;
  }

  /**
   * Builds a SQL-like WHERE clause for ArcGIS feature layer filtering
   * @param propertyFilter Object containing property names and values to filter by
   * @returns SQL-like WHERE clause string
   */
  private buildWhereClause(propertyFilter: Record<string, any>): string {
    if (!propertyFilter || Object.keys(propertyFilter).length === 0) {
      return '1=1';
    }

    const conditions = Object.entries(propertyFilter).map(([property, value]) => {
      if (typeof value === 'string') {
        return `${property} = '${value}'`;
      } else if (typeof value === 'number') {
        return `${property} = ${value}`;
      } else if (value === null) {
        return `${property} IS NULL`;
      } else if (Array.isArray(value)) {
        const values = value
          .map(v => {
            return typeof v === 'string' ? `'${v}'` : v;
          })
          .join(',');
        return `${property} IN (${values})`;
      } else {
        return `${property} = '${value}'`;
      }
    });

    return conditions.join(' AND ');
  }
}


interface Action {
  action: string;
}

interface RoutePoint {
  lat: number;
  lng: number;
  altitude: number;
  speed: number;
  heading: number;
  pitch: number;
  actions: Action[];
  altitudeWGS: number;
  altitudeEGM: number;
  gimbal: number;
}

interface InputData {
  name: string;
  description: string;
  heightMode: string;
  isSmartInspect: boolean;
  route: RoutePoint[];
}


export interface GeoJSONFeatureCollection {
  type: "FeatureCollection";
  properties: {
    name: string;
    description: string;
    heightMode: string;
    isSmartInspect: boolean;
  };
  features: GeoJSONFeature[];
}

export interface GeoJSONFeature {
  type: "Feature";
  properties: { [key: string]: any };
  geometry: GeoJSONGeometry;
}

interface GeoJSONGeometry {
  type: string;
  coordinates: number[] | number[][];
}

export enum MissionExportType {
  JSON = 'json',
  GEOJSON = 'geojson'
}

import {Injectable} from '@angular/core';
import {UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AtlasService} from '../services/atlas.service';

@Injectable({
  providedIn: 'root'
})
export class MapGuard {
  constructor(private atlasService: AtlasService) {}
  public canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.atlasService.atlasFinishDestroy.asObservable();
  }
}

import {environment} from './environment';
import {RedirectUrlService} from '@app/auth/services/redirect-url.service';

// named arbitrarily, used only for reference
export const jsApiName = 'js-api';
export const jobsApiName = 'jobs-api';
export const samApiName = 'sam-api';
export const javaApiName = 'java-api';

export const amplifyConfig = {
  Auth: {
    identityPoolId: environment.identityPoolId,
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.clientId,
    mandatorySignIn: true,
    oauth: {
      domain: environment.OAUTH_DOMAIN,
      redirectSignIn: RedirectUrlService.getRedirectSignInUrl(),
      redirectSignOut: RedirectUrlService.getRedirectSignOutUrl(),
      responseType: 'code',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin']
    }
  },
  API: {
    // aws_appsync_graphqlEndpoint: environment.APPSYNC_NOTIFICATIONS_URI,
    // aws_appsync_region: environment.region,
    // aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',

    endpoints: [
      {
        name: jsApiName,
        endpoint: environment.API_URL,
        region: environment.region,
        service: 'execute-api'
      },
      {
        name: jobsApiName,
        endpoint: environment.jobCDNDomain,
        region: environment.region,
        service: 'execute-api'
      },
      {
        name: samApiName,
        endpoint: 'https://segment-anything.unleashlive.com/',
        region: environment.region,
        service: 'execute-api'
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: environment.LIBRARY_BUCKET_UPLOAD,
      region: environment.region
    },
    customPrefix: {
      public: '',
      protected: '',
      private: ''
    }
  }
};

import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {AclPermissions} from '../api/acl.model';

export abstract class MenuItem {
  public readonly id: number;
  public label: string;
  public imagePath: string;
  public styleClass: string;
  public fragment: string;
  public queryParams: {};
  public abstract type: MenuItemTypeEnum;
  public aclPermission: AclPermissions;
  private static _id: number = 1;

  protected constructor(
    label: string,
    imagePath: string,
    styleClass?: string,
    fragment?: string,
    queryParams?: {},
    aclPermission?: AclPermissions
  ) {
    this.id = MenuItem._id++;
    this.label = label;
    this.imagePath = imagePath;
    this.styleClass = styleClass;
    this.fragment = fragment;
    this.queryParams = queryParams;
    this.aclPermission = aclPermission;
  }
}

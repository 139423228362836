import {Injectable} from '@angular/core';
import {HeatmapAssetLoaderService} from './heatmap-asset-loader.service';
import {OrthomapAssetLoaderService} from './orthomap-asset-loader.service';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {AssetType, AtlasAssetModel} from '../../core/models/api/atlas.model';
import {Map} from 'leaflet';
import {AbstractAssetLoaderService} from './abstract-asset-loader.service';
import {FlightLogAssetLoaderService} from './flight-log-asset-loader.service';
import {ArcgisAssetLoaderService} from './arcgis-asset-loader.service';

@Injectable({
  providedIn: 'root'
})
export class AssetLoaderService {
  // local cache, cleaned on map destroy
  // todo preserve laoded assets and map state on map destroy
  // todo use web worker to load assets
  loadedAssetIds: string[] = [];

  loadTimes = {};
  totalMarkerCount: number = 0;
  constructor(
    private geojsonAssetLoaderService: GeojsonAssetLoaderService,
    private heatmapAssetLoaderService: HeatmapAssetLoaderService,
    private orthomapAssetLoaderService: OrthomapAssetLoaderService,
    private flightLogAssetLoaderService: FlightLogAssetLoaderService,
    private arcgisAssetLoaderService: ArcgisAssetLoaderService
  ) {}

  clean() {
    this.loadedAssetIds = [];
  }

  async load(asset: AtlasAssetModel, map: Map) {
    if (!map) {
      console.error('Empty map instance provided. Aborting asset load!!!');
      return;
    }
    try {
      this.loadTimes[asset.name] = Date.now();

      // Get the appropriate loader for this asset type
      let loader;
      try {
        loader = this.getLoaderByType(asset.type);
      } catch (loaderError) {
        console.error('[AssetLoader] Failed to get loader for type:', asset.type, loaderError);
        throw loaderError;
      }

      // Load the asset using the loader
      const count = await loader.load(asset, map);

      if (count) {
        this.totalMarkerCount += count;
      }

      this.loadedAssetIds.push(asset.id);
      this.loadTimes[asset.name] = Date.now() - this.loadTimes[asset.name];
    } catch (e) {
      console.error('[AssetLoader] Failed to load asset. Type:', asset.type, 'Name:', asset.name, 'Error:', e);
    }
  }

  getLoaderByType(type): AbstractAssetLoaderService {
    switch (type) {
      case AssetType.GEOJSON:
        return this.geojsonAssetLoaderService;
      case AssetType.ORTHOPHOTOMAP:
        return this.orthomapAssetLoaderService;
      case AssetType.HEATMAP:
        throw new Error('TODO implement heatmap loader');
      case AssetType.FLIGHT_LOG:
        return this.flightLogAssetLoaderService;
      case AssetType.ARCGIS:
        return this.arcgisAssetLoaderService;
      default:
        console.error('[AssetLoader] Unknown asset type:', type);
        throw new Error(`unknown asset type: ${type}`);
    }
  }
}

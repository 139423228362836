import {Directive, HostListener, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ASSET_GEOMETRY_TYPE, AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {TranslateService} from '@ngx-translate/core';
import {take} from 'rxjs';

@Directive({
  selector: '[ulPreview3D]',
  standalone: true
})
export class Preview3DDirective {
  @Input() public asset: AtlasAssetModel;

  constructor(private router: Router, private snackbar: MatSnackBar, private translateService: TranslateService) {}

  @HostListener('click')
  public preview3D(): void {
    if (!this.hasPolygons()) {
      this.translateService
        .get('atlas.layerAlerts.noPolygonsFound')
        .pipe(take(1))
        .subscribe(translation => {
          this.snackbar.open(translation, null, {duration: 3000, panelClass: 'center'});
        });
      return;
    }
    if (this.asset?.type === AssetType.GEOJSON && this.asset?.bounds) {
      this.router.navigate(['/secure/atlas/3d-preview'], {
        queryParams: {
          assetId: this.asset.id
        }
      });
    }
  }

  public hasPolygons(): boolean {
    return !!(this.asset?.geojson as any)?.features?.some(
      feature =>
        feature.geometry.type === ASSET_GEOMETRY_TYPE.POLYGON ||
        feature.geometry.type === ASSET_GEOMETRY_TYPE.MULTI_POLYGON
    );
  }
}

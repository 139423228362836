import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {amplifyConfig, javaApiName, jobsApiName, jsApiName} from 'environments/amplify.config';
import {API} from '@aws-amplify/api';
import {getCognitoIdentityValue} from '@app/core/services/api/cognito-identity-value';

@Injectable({providedIn: 'root'})
export class ApiGateway {
  constructor() {}

  // I perform a GET request to the API, appending the given params
  // as URL search parameters. Returns a stream.
  get(path: string, params?: any, opts?: any, headers?: any): Observable<any> {
    path = this.sanitizePath(path, jsApiName);
    const myInit: any = {
      headers: {
        ...headers,
        ...this.getCognitoAuthHeaders()
      },
      queryStringParameters: params,
      ...opts
    };
    const promise = API.get(jsApiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        // console.warn('API request failed', path, err);
        return Promise.reject(error);
      });

    return from(promise);
  }

  postToLegacyApi(path: string, params: any, data: any) {
    path = this.sanitizePath(path, jsApiName);
    const myInit = {
      headers: {},
      body: data
    };

    const promise = API.post(javaApiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        // console.log(error.response)
      });

    return from(promise);
  }

  public post(
    path: string,
    params: any,
    data: any,
    responseType = 'json',
    headers?: any,
    apiName = jsApiName
  ): Observable<any> {
    path = this.sanitizePath(path, apiName);
    const myInit = {
      headers: {
        ...headers,
        ...this.getCognitoAuthHeaders()
      },
      body: data,
      responseType
    };
    
    if (params) {
      myInit['queryStringParameters'] = params;
    }

    const promise = API.post(apiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        return Promise.reject((error && error.response && error.response.data) || error);
      });

    return from(promise);
  }

  put(path: string, params: any, data: any, headers?: any): Observable<any> {
    path = this.sanitizePath(path, jsApiName);
    const myInit = {
      headers: {
        ...headers,
        ...this.getCognitoAuthHeaders()
      },
      body: data
    };

    const promise = API.put(jsApiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        return Promise.reject((error && error.response && error.response.data) || error);
      });

    return from(promise);
  }

  patch(path: string, params: any, data: any, headers?: any): Observable<any> {
    path = this.sanitizePath(path, jsApiName);
    const myInit = {
      headers: {
        ...headers,
        ...this.getCognitoAuthHeaders()
      },
      body: data
    };

    const promise = API.patch(jsApiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        return Promise.reject((error && error.response && error.response.data) || error);
      });

    return from(promise);
  }

  delete(path: string, params: any, data?: any, headers?: any): Observable<any> {
    path = this.sanitizePath(path, jsApiName);
    const myInit = {
      headers: {
        ...headers,
        ...this.getCognitoAuthHeaders()
      },
      body: data
    };

    const promise = API.del(jsApiName, path, myInit)
      .then(this.unwrapDynamoDBResult)
      .catch(error => {
        return Promise.reject((error && error.response && error.response.data) || error);
      });

    return from(promise);
  }

  private sanitizePath(path: string, apiName: string) {
    const endpoint = amplifyConfig.API.endpoints.find(endpoint => endpoint.name === apiName);
    if (!endpoint) {
      console.error('No endpoint configured: ', apiName);
    }
    const apiUrl = endpoint.endpoint;
    if (path.startsWith('/') && !apiUrl.endsWith('/')) {
      return path;
    }
    if (!path.startsWith('/') && apiUrl.endsWith('/')) {
      return path;
    }
    if (!path.startsWith('/') && !apiUrl.endsWith('/')) {
      return `/${path}`;
    }
    return path;
  }

  public getCognitoAuthHeaders(): Record<string, string> {
    return {
      'X-Amz-Cognito-Security-Token': getCognitoIdentityValue('idToken')
    };
  }

  /** Remove dynamodb wrapper object and return pure data */
  private unwrapDynamoDBResult(value: any): any {
    return value.Items
      ? value.Items
      : value.Item
      ? value.Item
      : value.Attributes
      ? value.Attributes // unwrap patch request
      : value;
  }
}

<ng-container *ngIf="isGpsDevice; else defaultTemplate">
  <div class="device-list-container" *ngFor="let asset of gpsDevices | async">
    <app-layers-control-item
      class="full-width"
      [asset]="asset"
      [hasToBlockMenu]="hasToBlockMenu"
      [hasToDetectChangesSidebar]="hasToDetectChangesSidebar | async"
      [hasToDetectChanges]="hasToDetectChanges$ | async"
      [isGpsDevice]="true"
      (refreshView)="emitRefreshView()"
      (openLayerControlItemMenu)="openLayerControlItemMenu($event, asset)"
      [isListOnly]="isListOnly"
      [map]="map"
      [headerStatus]="headerStatus$ | async"
    ></app-layers-control-item>
  </div>
</ng-container>
<ng-template #defaultTemplate>
  <cdk-virtual-scroll-viewport class="virtual-scroll-container" itemSize="48">
    <ng-container *cdkVirtualFor="let node of dataSource; trackBy: trackByFn" cdkVirtualForTemplateCacheSize="100">
      <app-layers-control-item
        *ngIf="!node?.expandable"
        class="full-width"
        [asset]="node.asset"
        [hasToBlockMenu]="hasToBlockMenu"
        [hasToDetectChangesSidebar]="hasToDetectChangesSidebar | async"
        [hasToDetectChanges]="hasToDetectChanges$ | async"
        (refreshView)="emitRefreshView()"
        (openLayerControlItemMenu)="openLayerControlItemMenu($event, node.asset)"
        (openSearchResults)="emitOpenSearchResults($event)"
        [isListOnly]="isListOnly"
        [map]="map"
        [headerStatus]="headerStatus$ | async"
      >
      </app-layers-control-item>
      <div class="node" [style.padding-left]="node?.level * 24 + 'px'">
        <ng-container *ngIf="node?.expandable">
          <app-layers-control-group
            [node]="node"
            [treeControl]="treeControl"
            [id]="node?.id"
            class="full-width"
            [isExpanded]="treeControl.isExpanded(node)"
            [groupName]="node.name"
            [groupId]="node.children[0].id"
            [children]="node.children"
            [hasToBlockMenu]="hasToBlockMenu"
            [totalSelectedLayers]="totalSelectedLayers"
            [hasToDetectChanges]="hasToDetectChanges$ | async"
            [isListOnly]="isListOnly"
            [map]="map"
            [headerStatus]="headerStatus$ | async"
          ></app-layers-control-group>
        </ng-container>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>

<unleash-context-menu #contextMenuControlItems [matMenu]="moreOptionsMenu">
  <mat-menu
    class="layer__group"
    #moreOptionsMenu="matMenu"
    [class]="selectedControlItemAsset?.type === assetType.DEVICE ? 'atlas-device-menu' : 'atlas-layer-menu'"
  >
    <ng-container *ngIf="isListOnly; else defaultMenuTemplate">
      <ng-container *ngIf="isGpsDevice; else layerMenuListTemplate">
        <button
          class="layer__button"
          mat-menu-item
          [isGpsDevice]="isGpsDevice"
          [map]="map"
          [ulShowLayer]="selectedControlItemAsset"
          data-test-id="view-location-menu-item"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">gps_fixed</mat-icon>
            {{ 'atlas.control.viewLocation' | translate }}
          </span>
        </button>
      </ng-container>
      <ng-template #layerMenuListTemplate>
        <button
          class="layer__button"
          mat-menu-item
          [isGpsDevice]="isGpsDevice"
          [map]="map"
          [ulShowLayer]="selectedControlItemAsset"
          data-test-id="view-location-menu-item"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">gps_fixed</mat-icon>
            {{ 'atlas.control.viewLocation' | translate }}
          </span>
        </button>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          [disabled]="!(hasAllLayersLoaded$ | async)"
          class="layer__button"
          mat-menu-item
          [ulShowOnlyThisLayer]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="visible_layer"></mat-icon>
            {{ 'atlas.control.showOnlyThisLayer' | translate }}
          </span>
        </button>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          class="layer__button"
          mat-menu-item
          [ulBringToFrontLayer]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">flip_to_front</mat-icon>
            {{ 'atlas.control.front' | translate }}
          </span>
        </button>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          class="layer__button"
          mat-menu-item
          [ulBringToBackLayer]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">flip_to_back</mat-icon>
            {{ 'atlas.control.back' | translate }}
          </span>
        </button>
      </ng-template>
    </ng-container>
    <ng-template #defaultMenuTemplate>
      <button
        class="layer__button"
        mat-menu-item
        [isGpsDevice]="isGpsDevice"
        [map]="map"
        [ulShowLayer]="selectedControlItemAsset"
        data-test-id="view-location-menu-item"
      >
        <span class="layer__button-content">
          <mat-icon inline="true" class="layer__icon">gps_fixed</mat-icon>
          {{ 'atlas.control.viewLocation' | translate }}
        </span>
      </button>
      <ng-container
        *ngIf="
          selectedControlItemAsset?.type === assetType.ORTHOPHOTOMAP &&
          selectedControlItemAsset?.parentId &&
          selectedControlItemAsset?.modelId
        "
      >
        <button
          class="layer__button"
          mat-menu-item
          [ulGoToModel]="selectedControlItemAsset"
          [analyticsTrackerType]="events.ATLAS_LAYER_ACTION"
          [analyticsProperties]="{type: 'Go to model'}"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">3d_rotation</mat-icon>
            {{ 'atlas.control.goToModels' | translate }}
          </span>
        </button>
        <button class="layer__button" mat-menu-item [ulGoToPhoto]="selectedControlItemAsset">
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">image</mat-icon>
            {{ 'atlas.control.goToPhotos' | translate }}
          </span>
        </button>
      </ng-container>
      <ng-container *ngIf="selectedControlItemAsset?.type === assetType.DEVICE">
        <app-device-context-menu
          [device]="selectedControlItemAsset?.device"
          (toggleStream)="toggleStream.emit($event)"
          (deviceUpdated)="deviceUpdated($event)"
        ></app-device-context-menu>
      </ng-container>
      <ng-container
        *ngIf="selectedControlItemAsset?.owner !== 'samples' && selectedControlItemAsset?.type !== assetType.DEVICE"
      >
        <button
          class="layer__button"
          data-test-id="copy-asset-link-menu-item"
          mat-menu-item
          [ulCopyLinkToAsset]="selectedControlItemAsset"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon">link</mat-icon>

            {{ 'atlas.control.copyLink' | translate }}
          </span>
        </button>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          class="layer__button"
          mat-menu-item
          [ulGroupAsset]="selectedControlItemAsset"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="add-group"></mat-icon>
            {{ 'atlas.control.addToGroup' | translate }}
          </span>
        </button>
        <button
          *ngIf="selectedControlItemAsset?.type !== assetType.FLIGHT_LOG"
          class="layer__button"
          mat-menu-item
          [ulCompareLayer]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="compare"></mat-icon>
            {{ 'atlas.control.compareLayers' | translate }}
          </span>
        </button>
        <ng-container *uaHasPermission="aclPermissions.JobsApiListByTeamId">
          <button
            *ngIf="selectedControlItemAsset?.geojson?.features[0].properties.jobId && (isJobsEnabled$ | async)"
            [routerLink]="'/secure/jobs/' + selectedControlItemAsset.geojson.features[0].properties.jobId"
            class="layer__button"
            mat-menu-item
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="jobs"></mat-icon>
              {{ 'jobs.goToJob' | translate }}
            </span>
          </button>
          <button
            *ngIf="
              (isJobsEnabled$ | async) &&
              isJobAvailable &&
              baseAsset &&
              (headerStatus$ | async) === atlasHeaderStatus.DEFAULT
            "
            [ulEditJobSelection]="selectedFeature?.jobName"
            [baseAsset]="baseAsset"
            [polygonAsset]="selectedControlItemAsset"
            [map]="map"
            class="layer__button"
            mat-menu-item
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">edit</mat-icon>
              {{ 'jobs.editJobSelection' | translate }}
            </span>
          </button>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
          <button
            *ngIf="selectedControlItemAsset?.type === assetType.GEOJSON"
            class="layer__button"
            [map]="map"
            [ulSelectMarkers]="selectedControlItemAsset"
            mat-menu-item
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="select-markers"></mat-icon>
              {{ 'atlas.control.selectMarkers' | translate }}
            </span>
          </button>
        </ng-container>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          data-test-id="rename-menu-item"
          class="layer__button"
          mat-menu-item
          [ulRenameAsset]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="rename"></mat-icon>
            {{ 'atlas.control.rename' | translate }}
          </span>
        </button>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
          <button
            *ngIf="selectedControlItemAsset?.type === assetType.GEOJSON"
            data-test-id="convert-to-mission-menu-item"
            class="layer__button"
            mat-menu-item
            [ulConvertToMissionAsset]="selectedControlItemAsset"
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="u_convert_layer_to_mission"></mat-icon>
              Convert to mission
            </span>
          </button>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
          <ng-container
            *ngIf="
              !(selectedControlItemAsset?.state || selectedControlItemAsset?.hasState) &&
              selectedControlItemAsset?.type === assetType.GEOJSON
            "
          >
            <button class="layer__button" mat-menu-item [matMenuTriggerFor]="colorMenu">
              <span class="layer__button-content">
                <mat-icon inline="true" class="layer__icon">color_lens</mat-icon>
                {{ 'atlas.control.color' | translate }}
              </span>
            </button>
            <mat-menu class="color-list" #colorMenu="matMenu">
              <div
                *ngFor="let color of availableColors; let i = index"
                class="color-list__item"
                [style.backgroundColor]="color"
                [ulChangeLayerColor]="selectedControlItemAsset"
                [map]="map"
                [colorIndex]="i"
              >
                <mat-icon class="color-list__check" *ngIf="i === selectedControlItemAsset?.customColorIndex"
                  >check</mat-icon
                >
              </div>
            </mat-menu>
          </ng-container>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiGeojson2shp">
          <ng-container *ngIf="selectedControlItemAsset?.type === assetType.GEOJSON">
            <button class="layer__button" mat-menu-item [ulExportShape]="selectedControlItemAsset">
              <span class="layer__button-content">
                <mat-icon inline="true" class="layer__custom-icon" svgIcon="export-layer"></mat-icon>
                {{ 'atlas.control.exportToShapefile' | translate }}
              </span>
            </button>
            <button
              data-test-id="export-geojson-menu-item"
              class="layer__button"
              mat-menu-item
              [ulExportToGeojson]="selectedControlItemAsset"
            >
              <span class="layer__button-content">
                <mat-icon inline="true" class="layer__custom-icon" svgIcon="export-layer"></mat-icon>
                {{ 'atlas.control.exportGeojson' | translate }}
              </span>
            </button>
          </ng-container>
          <ng-container *ngIf="selectedControlItemAsset?.type === assetType.ORTHOPHOTOMAP">
            <button
              data-test-id="export-geotiff-menu-item"
              class="layer__button"
              mat-menu-item
              [ulExportToGeotiff]="selectedControlItemAsset"
            >
              <span class="layer__button-content">
                <mat-icon inline="true" class="layer__custom-icon" svgIcon="export-layer"></mat-icon>
                {{ 'atlas.control.exportGeotiff' | translate }}
              </span>
            </button>
          </ng-container>
        </ng-container>
        <mat-divider *uaHasPermission="aclPermissions.AtlasApiUpdate" class="layer__divider"></mat-divider>
        <button
          *uaHasPermission="aclPermissions.AtlasApiUpdate"
          [disabled]="!(hasAllLayersLoaded$ | async)"
          class="layer__button"
          mat-menu-item
          [ulShowOnlyThisLayer]="selectedControlItemAsset"
          [map]="map"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="visible_layer"></mat-icon>
            {{ 'atlas.control.showOnlyThisLayer' | translate }}
          </span>
        </button>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
          <button
            *ngIf="selectedControlItemAsset?.type !== assetType.FLIGHT_LOG"
            class="layer__button"
            mat-menu-item
            [ulBringToFrontLayer]="selectedControlItemAsset"
            [map]="map"
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_front</mat-icon>
              {{ 'atlas.control.front' | translate }}
            </span>
          </button>
        </ng-container>
        <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
          <button
            *ngIf="selectedControlItemAsset?.type !== assetType.FLIGHT_LOG"
            class="layer__button"
            mat-menu-item
            [ulBringToBackLayer]="selectedControlItemAsset"
            [map]="map"
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_back</mat-icon>
              {{ 'atlas.control.back' | translate }}
            </span>
          </button>
        </ng-container>
        <button
          class="layer__button"
          *ngIf="selectedControlItemAsset?.dashboard"
          mat-menu-item
          [ulShowDashboard]="selectedControlItemAsset?.dashboard"
          [layerName]="selectedControlItemAsset?.name"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__custom-icon" svgIcon="bar_chart"></mat-icon>
            {{ 'atlas.control.viewAnalysis' | translate }}
          </span>
        </button>
        <mat-divider *uaHasPermission="aclPermissions.AtlasApiDelete" class="layer__divider"></mat-divider>
        <button
          *uaHasPermission="aclPermissions.AtlasApiDelete"
          data-test-id="delete-menu-item"
          class="layer__button"
          mat-menu-item
          [map]="map"
          [ulRemoveLayer]="selectedControlItemAsset"
          class="delete"
        >
          <span class="layer__button-content">
            <mat-icon inline="true" class="layer__icon delete">delete</mat-icon>
            {{ 'common.delete' | translate }}
          </span>
        </button>
      </ng-container>
    </ng-template>
  </mat-menu>
</unleash-context-menu>

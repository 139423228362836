import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, share, switchMap, take, tap} from 'rxjs/operators';
import {ApiGateway} from '../../core/services/api/api-gateway.service';
import {Mission} from './mission-settings.service';

@Injectable({
  providedIn: 'root'
})
export class MissionApiService {
  private endpoint: string = 'mission';

  constructor(private apiGateway: ApiGateway) {}

  public createMission(mission: Mission) {
    return this.apiGateway.post(this.endpoint, {}, mission).pipe(share());
  }

  public listMissions(): Observable<Mission[]> {
    const missions: BehaviorSubject<Mission[]> = new BehaviorSubject([]);
    const isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    const limit = 100;
    const getMissions = (nextToken?: string) =>
      this.apiGateway
        .get(this.endpoint + `?limit=${limit}${nextToken ? `&nextToken=${nextToken}` : ''}`, {}, undefined)
        .pipe(
          take(1),
          tap((response: {items: Mission[]; nextToken: string}) => {
            missions.next([...missions.value, ...response.items]);
            if (!response.nextToken) {
              isLoading.next(false);
              return;
            }
            getMissions(response.nextToken);
          })
        )
        .subscribe();
    getMissions();
    return isLoading.pipe(
      filter(isLoading => !isLoading),
      take(1),
      switchMap(() =>
        missions.pipe(
          take(1),
          map(missions => {
            const newestFirst = (a, b) => b.updatedAt - a.updatedAt;
            missions.sort(newestFirst);
            return this.clearMissionActions(missions);
          })
        )
      )
    );
  }

  public updateMission(missionId: string, body: Partial<Mission>) {
    return this.apiGateway.patch(this.endpoint + '/' + missionId, {}, body).pipe(share());
  }

  public removeMission(missionId: string) {
    return this.apiGateway.delete(this.endpoint + '/' + missionId, {}, undefined).pipe(share());
  }

  public importMission(mission: string) {
    return this.apiGateway.post(`${this.endpoint}/import`, {}, mission).pipe(share());
  }

  public importMissionKMZ(mission: string) {
    const blob = new Blob([mission], {type: 'application/octet-stream'});
    const formData = new FormData();
    formData.append('file', blob, 'mision.kmz');
    return this.apiGateway
      .post(`${this.endpoint}/import`, {}, formData, 'json', {
        'Content-Type': 'multipart/form-data'
      })
      .pipe(share());
  }

  public clearMissionActions(missions: Mission[]) {
    if (!missions) return [];
    return missions.map(mission => {
      mission?.route
        ?.filter(route => !!route)
        .forEach(route => {
          if (Array.isArray(route.actions) && route.actions?.length > 0) {
            route.actions = route.actions.filter(action => action?.action);
            return;
          }
          route.actions = [];
        });
      return mission;
    });
  }
}

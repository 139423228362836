import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {Observable, Subscription, map, shareReplay, take} from 'rxjs';
import {Role, Permission} from '../../models/roles-management.model';
import {RoleManagementStoreFacadeService} from '../../services/role-management-store-facade.service';
import {AddRoleDialogComponent} from './add-role-dialog/add-role-dialog.component';
import {UntilDestroy} from '@ngneat/until-destroy';

interface MenuAction {
  name: string;
  type: 'edit' | 'delete';
}

interface PermissionGroup {
  groupName: string;
  permissions: Permission[];
}

@UntilDestroy()
@Component({
  selector: 'app-roles-manager',
  templateUrl: './roles-manager.component.html',
  styleUrls: ['./roles-manager.component.scss']
})
export class RolesManagerComponent implements OnInit {
  dataSource = new MatTableDataSource<PermissionGroup>([]);
  displayedColumns: string[] = ['permissionName'];

  roles$ = this.roleManagementStoreFacade.roles$;
  permissions$ = this.roleManagementStoreFacade.permissions$;
  permissionGroups$: Observable<PermissionGroup[]>;
  hasUnsavedChanges$: Observable<boolean>;
  permissionGroupsSubs: Subscription;
  rolesSubs: Subscription;

  constructor(
    private roleManagementStoreFacade: RoleManagementStoreFacadeService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.permissionGroups$ = this.permissions$.pipe(
      map(permissions => {
        const groups = new Map<string, Permission[]>();
        permissions.forEach(permission => {
          const group = groups.get(permission.groupName) || [];
          group.push(permission);
          groups.set(permission.groupName, group);
        });
        return Array.from(groups.entries()).map(([groupName, permissions]) => ({
          groupName,
          permissions
        }));
      }),
      shareReplay(1)
    );

    this.hasUnsavedChanges$ = this.roleManagementStoreFacade.updatedRoleIds$.pipe(map(ids => ids.length > 0));

    this.permissionGroupsSubs = this.permissionGroups$.subscribe(groups => {
      this.dataSource.data = groups;
    });
  }

  ngOnInit(): void {
    this.roleManagementStoreFacade.loadRoles();
    this.roleManagementStoreFacade.loadPermissions();

    this.rolesSubs = this.roles$.subscribe(roles => {
      this.displayedColumns = ['permissionName', ...roles.map(role => role.id)];
    });
  }

  hasPermission(role: Role, permission: Permission): boolean {
    return role.acl.some(p => p === [permission.apiName, permission.action].join(':'));
  }

  togglePermission(role: Role, permission: Permission) {
    if (role.companyId === 'default') {
      return;
    }

    const permissionKey = [permission.apiName, permission.action].join(':');
    const currentPermissions = role.acl || [];
    const hasPermission = this.hasPermission(role, permission);

    this.roleManagementStoreFacade.updateLocalRolePermissions(
      role.id,
      permissionKey,
      !hasPermission // if permission exists, we want to remove it, and vice versa
    );
  }

  openAddRoleDialog() {
    const dialogRef = this.dialog.open(AddRoleDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.roleManagementStoreFacade.addRole(result);
        }
      });
  }

  removeRole(role: Role) {
    if (!role.pk && !role.sk) {
      // For new roles (created in this session), just remove from store
      this.roleManagementStoreFacade.deleteLocalRole(role.id);
    } else {
      // For existing roles, dispatch delete action
      this.roleManagementStoreFacade.deleteRole(role.id);
    }
  }

  saveChanges() {
    this.roleManagementStoreFacade.saveChanges();
  }
}

<mat-drawer-container class="container">
  <mat-drawer class="mat-elevation-z2" mode="side" opened>
    <mat-nav-list style="width: 200px">
      <a class="item" mat-list-item [routerLink]="['/secure/profile/your-profile']" routerLinkActive="active">
        <mat-icon class="icon">person</mat-icon>
        {{ 'profile.headers.profile' | translate }}</a
      >
      <ng-container *uaHasPermission="aclPermissions.OrganizationApiGetAdminOrganizationData">
        <a class="item" mat-list-item [routerLink]="['/secure/profile/organization']" routerLinkActive="active">
          <mat-icon class="icon">business</mat-icon>
          {{ 'profile.headers.organization' | translate }}</a
        >
        <a class="item" mat-list-item [routerLink]="['/secure/profile/users']" routerLinkActive="active">
          <mat-icon class="icon">people</mat-icon>
          {{ 'profile.headers.users' | translate }}</a
        >
        <a class="item" mat-list-item [routerLink]="['/secure/profile/teams']" routerLinkActive="active">
          <mat-icon class="icon">groups</mat-icon>
          {{ 'profile.headers.teams' | translate }}</a
        >
      </ng-container>
      <a
        *uaHasPermission="aclPermissions.MainApiReadDevice"
        class="item"
        mat-list-item
        [routerLink]="['/secure/profile/streaming-devices']"
        routerLinkActive="active"
      >
        <mat-icon class="icon" svgIcon="stream-menu"></mat-icon>
        {{ 'profile.headers.streamingAndDevices' | translate }}</a
      >
      <ng-container *uaHasPermission="aclPermissions.OrganizationApiUpdateCompany">
        <a class="item" mat-list-item [routerLink]="['/secure/profile/data-privacy']" routerLinkActive="active">
          <mat-icon class="icon">security</mat-icon>
          {{ 'profile.headers.dataPrivacy' | translate }}</a
        >
      </ng-container>
      <ng-container *uaHasPermission="aclPermissions.AddonApiCliAccess">
        <a class="item" mat-list-item [routerLink]="['/secure/profile/developers']" routerLinkActive="active">
          <mat-icon class="icon" svgIcon="coco-json"></mat-icon>
          {{ 'profile.headers.developers' | translate }}</a
        >
      </ng-container>
    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content class="content">
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {GeoJSON} from 'geojson';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';

@Injectable({
  providedIn: 'root'
})
export class AtlasApiService {
  constructor(private apiGateway: ApiGateway) {}

  public listAtlasItems() {
    return this.apiGateway.get('atlas/asset');
  }

  public getById(id: string) {
    return this.apiGateway.get(`atlas/asset/${id}`);
  }

  public getAssignAssetsPreview(libraryItemId: string) {
    return this.apiGateway.get(`atlas/assign-assets/${libraryItemId}`);
  }

  public removeById(id: string) {
    return this.apiGateway.delete(`atlas/asset/${id}`, null, undefined);
  }

  public update(id: string, payload: Partial<AtlasAssetModel>) {
    return this.apiGateway.patch(`atlas/asset/${id}`, null, {...payload});
  }

  public changeMarkerState(assetId: string, features: {featureId: string; state: string}[]) {
    return this.apiGateway.patch(`atlas/asset/update`, null, {assetId, features});
  }

  public moveMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.apiGateway.patch(`atlas/asset/move`, null, {featureIds: indexes, from: assetIdFrom, to: assetIdTo});
  }

  public copyMarkers(assetIdFrom: string, assetIdTo: string, indexes: number[]) {
    return this.apiGateway.patch(`atlas/asset/copy`, null, {featureIds: indexes, from: assetIdFrom, to: assetIdTo});
  }

  public convertToShapeFile(assetId: string, name: string) {
    return this.apiGateway.post(`atlas/convert/shapefile`, null, {assetId, name}, 'text');
  }

  public assignAssets(libraryItemId: string, atlasAssetId: string, groupNameKey: string) {
    const payload = !!atlasAssetId ? {atlasAssetId, groupNameKey} : {groupNameKey};
    return this.apiGateway.post(`atlas/assign-assets/${libraryItemId}`, null, payload);
  }

  public create(payload: {name: string; type: AssetType; key: string; groupName: string; jobId?: string}) {
    return this.apiGateway.post(`atlas/asset`, null, payload);
  }

  public convertAssetToMission(assetId: string, si: string) {
    return this.apiGateway.post('/mission/import', null, {assetId, si});
  }
}

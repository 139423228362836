import {Injectable} from '@angular/core';
import {JobsFacadeService} from './jobs-facade.service';
import {catchError, EMPTY, filter, firstValueFrom, Observable, of, switchMap, take, tap} from 'rxjs';
import {JOB_STATUS, Job, Task} from '../models/jobs.models';
import {TeamRole} from '@app/profile/models/team.model';
import {AtlasService} from '@app/atlas/services/atlas.service';

@Injectable({
  providedIn: 'root'
})
export class JobDetailService {
  public isLoadingSingleJob$: Observable<boolean> = this.jobsFacadeService.isLoadingSingleJob$;
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> =
    this.jobsFacadeService.teamMembers$;
  public isLoadingTasks$: Observable<boolean> = this.jobsFacadeService.isLoadingTasks$;
  public isLoadingMarkers$: Observable<boolean> = this.jobsFacadeService.isLoadingMarkers$;
  public isUpdatingJob$: Observable<boolean> = this.jobsFacadeService.isUpdatingJob$;
  public updatedTask: {taskId: string; userIds: string[]} = null;

  constructor(private jobsFacadeService: JobsFacadeService, private atlasService: AtlasService) {}

  public getAssetById(assetId: string) {
    const storedAsset = this.atlasService.getAssetById(assetId);
    if (storedAsset) {
      return storedAsset;
    }
    return firstValueFrom(
      this.atlasService.fetchAssetById(assetId).pipe(
        catchError(() => {
          return of(null);
        })
      )
    );
  }

  public loadJob(jobId: string) {
    this.jobsFacadeService.loadJob(jobId);
  }

  public loadMissions() {
    this.jobsFacadeService.loadMissions();
  }

  public loadJobMarkers(jobId: string) {
    this.jobsFacadeService.loadJobMarkers(jobId);
  }

  public storeSingleJob(jobId: string): void {
    this.jobsFacadeService.storeSingleJob(jobId);
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJobMarkers(jobId: string): Observable<Job> {
    return this.jobsFacadeService.selectJobMarkers(jobId);
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJob(jobId: string): Observable<Job> {
    return this.jobsFacadeService.selectJob(jobId);
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJobTasks(jobId: string): Observable<Task[]> {
    return this.jobsFacadeService.selectJobTasks(jobId);
  }

  public updateIsLoadingSingleJob(isLoadingSingleJob: boolean): void {
    this.jobsFacadeService.updateIsLoadingSingleJob(isLoadingSingleJob);
  }

  public openAddUsers(jobId: string): void {
    this.jobsFacadeService.openAddUsers(jobId);
  }

  public storeJobTasks(jobId: string): void {
    this.jobsFacadeService.storeJobTasks(jobId);
  }

  public updateJob(jobId: string, params: Partial<Job>): void {
    this.jobsFacadeService.updateJob(jobId, params);
  }

  public openDeleteJob(
    jobId: string,
    name: string,
    jobStatus: JOB_STATUS,
    assetId: string,
    hasToRedirect: boolean
  ): void {
    this.jobsFacadeService.openDeleteJob(jobId, name, jobStatus, assetId, hasToRedirect);
  }

  public openRenameJob(jobId: string, name: string): void {
    this.jobsFacadeService.openRenameJob(jobId, name);
  }

  public openDeleteTask(taskId: string, name: string) {
    this.jobsFacadeService.openDeleteTask(taskId, name);
  }

  public assignUserId(taskId: string, jobId: string, assignedId: string): void {
    this.jobsFacadeService.updateTask(taskId, jobId, {assignedId});
  }

  public openMoreDetails(task: Task, jobName: string): void {
    this.jobsFacadeService.openMoreDetails(task, jobName);
  }

  public clearTaskList(jobId: string) {
    this.jobsFacadeService.clearTaskList(jobId);
  }

  public assignAllTasksToMe(jobId: string) {
    this.jobsFacadeService.assignAllTasksToMe(jobId);
  }
}

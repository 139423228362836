import {ChangeDetectionStrategy, Component, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MissionExportType} from '@app/atlas/model/mission-geojson.model';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AclService} from '@app/core/services/acl.service';
import {Task, UserTaskStatus} from '@app/jobs/models/jobs.models';
import {JobTasksService} from '@app/jobs/services/job-tasks.service';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {JobsPageService} from '@app/jobs/services/jobs-page.service';
import {TeamRole} from '@app/profile/models/team.model';
import {ConfirmCloseDialogComponent} from '@app/shared/confirm-close-dialog/confirm-close-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  EMPTY,
  filter,
  fromEvent,
  map,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  take,
  tap
} from 'rxjs';
import {ExportTaskComponent} from '@app/jobs/components/export-task/export-task.component';
import {ExportTaskDialogForm} from '@app/jobs/models/export-task.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-edit-task-dialog',
  templateUrl: './edit-task-dialog.component.html',
  styleUrls: ['./edit-task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTaskDialog {
  public form: FormGroup = this.fb.group({
    title: [this.data.task.title, Validators.required],
    status: this.data.task.status,
    assignedId: this.data.task.assignedId,
    description: this.data.task.description
  });
  public statusMapping = {
    [UserTaskStatus.COMPLETED]: {name: 'Complete', class: 'completed'},
    [UserTaskStatus.PUBLISHED]: {name: 'Ready', class: 'ready'},
    [UserTaskStatus.FAILED]: {name: 'Failed', class: 'failed'},
    [UserTaskStatus.IN_PROGRESS]: {name: 'In progress', class: 'in-progress'}
  };
  public taskStatuses = Object.keys(this.statusMapping);
  public isLoading: boolean = false;
  public isEditTitleEnabled: boolean = false;
  public isEditDescriptionEnabled: boolean = true;
  public isFullScreenEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userTaskStatus = UserTaskStatus;
  public aclPermissions = AclPermissions;
  public hasCreateJobPermission$: Observable<boolean> = this.aclService.hasSetupPermission$.pipe(
    filter(hasSetupPermission => !!hasSetupPermission),
    take(1),
    map(() => this.aclService.hasPermission(this.aclPermissions.JobsApiCreateJob)),
    shareReplay(1)
  );
  public currentUserId$: Observable<string> = this.jobsPageService.currentUserId$;

  protected readonly MissionExportType = MissionExportType;

  private fullScreenSubs: Subscription;

  @ViewChild('map') public mapElement;

  constructor(
    public dialogRef: MatDialogRef<EditTaskDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task;
      jobUserIds: string[];
      teamMembers: {
        [key: string]: {
          id: string;
          name: string;
          role: TeamRole;
        };
      };
    },
    private fb: FormBuilder,
    private jobsApiService: JobsApiService,
    private matDialog: MatDialog,
    private jobTasksService: JobTasksService,
    private aclService: AclService,
    private jobsPageService: JobsPageService
  ) {
    this.fullScreenSubs = fromEvent(document, 'fullscreenchange').subscribe(() => {
      const isInFullScreen =
        (document as any).webkitIsFullScreen ||
        (document as any).mozFullScreen ||
        (document as any).msFullscreenElement;
      this.isFullScreenEnabled.next(isInFullScreen);
    });
    this.handleCloseDialog();
  }

  private openConfirmCloseDialog() {
    const dialog = this.matDialog.open(ConfirmCloseDialogComponent, {
      ...STANDARD_DIALOG_CONFIG,
      width: '800px',
      minWidth: '280px',
      autoFocus: false,
      disableClose: true
    });
    return dialog.afterClosed();
  }

  public exportTask(task: Task) {
    const dialog = this.matDialog.open(ExportTaskComponent, {
      ...STANDARD_DIALOG_CONFIG,
      width: '800px',
      minWidth: '280px',
      maxWidth: '90vw',
      autoFocus: false,
      disableClose: true
    });
    return dialog.beforeClosed().subscribe((form: ExportTaskDialogForm) => {
      if (!form) {
        return;
      }
      this.jobsApiService
        .exportTask({
          taskIds: [task.id],
          ...form
        })
        .pipe(take(1));
    });
  }

  private handleCloseDialog() {
    this.dialogRef
      .backdropClick()
      .pipe(
        switchMap(() => {
          if (this.form.dirty) {
            return this.openConfirmCloseDialog();
          }
          this.dialogRef.close();
          return EMPTY;
        }),
        tap(closeConfirmed => {
          if (closeConfirmed) {
            this.dialogRef.close();
          }
        })
      )
      .subscribe();
  }

  public cancelDialog(): void {
    if (this.form.dirty) {
      this.openConfirmCloseDialog()
        .pipe(
          take(1),
          tap(closeConfirmed => {
            if (closeConfirmed) {
              this.dialogRef.close();
            }
          })
        )
        .subscribe();
      return;
    }
    this.dialogRef.close();
  }

  public toggleEditTitleEnabled(): void {
    this.isEditTitleEnabled = !this.isEditTitleEnabled;
  }

  public toggleEditDescription(): void {
    this.isEditDescriptionEnabled = !this.isEditDescriptionEnabled;
  }

  public setStatus(status: UserTaskStatus): void {
    this.form.controls.status.setValue(status);
    this.form.controls.status.markAsDirty();
  }

  public setUserId(assignedId: string): void {
    this.form.controls.assignedId.setValue(assignedId);
    this.form.controls.status.markAsDirty();
  }

  public finishEdit(): void {
    if (this.form.valid) {
      const changes = {};
      Object.keys(this.form.value).forEach(property => {
        const formValue = this.form.value[property];
        const taskValue = this.data.task[property];
        if (this.areJobValuesDifferent(formValue, taskValue)) {
          changes[property] = formValue;
        }
      });
      if (Object.keys(changes).length > 0) {
        this.isLoading = true;
        this.jobsApiService
          .updateTask(this.data.task.id, changes)
          .pipe(take(1))
          .subscribe(() => {
            this.dialogRef.close({changes, jobId: this.data.task.jobId});
          });
        return;
      }
      this.dialogRef.close();
    }
  }

  public viewReport(libraryItemId: string): void {
    this.jobTasksService.viewReport(libraryItemId);
    this.dialogRef.close();
  }

  public viewMedia(libraryItemId: string): void {
    this.jobTasksService.viewMedia(libraryItemId);
    this.dialogRef.close();
  }

  public setEnableFullScreen(enableFullScreen: boolean) {
    enableFullScreen ? this.fullScreen() : this.exitFullScreen();
  }

  private fullScreen() {
    const element = this.mapElement.map.getContainer();
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).webkitEnterFullscreen) {
      // IOS
      (element as any).webkitEnterFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
      // Safari
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      // IE11
      (element as any).msRequestFullscreen();
    }
  }

  private exitFullScreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      /* Safari */
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      /* IE11 */
      (document as any).msExitFullscreen();
    }
  }

  private areJobValuesDifferent(val1, val2) {
    if (val1 === null && val2 === undefined) return false;
    if (val1 === undefined && val2 === null) return false;
    return val1 !== val2;
  }
}

import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AtlasMapComponent} from '@app/atlas/components/atlas-map/atlas-map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {DataModule} from '@app/data/data.module';
import {AnnotationSharedModule} from '@app/shared/annotation-shared/annotation-shared.module';
import {ConfirmDeleteDialogModule} from '@app/shared/confirm-delete-dialog/confirm-delete-dialog.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {NgxFileDropModule} from 'ngx-file-drop';
import {VideogularModule} from '../core/modules/videogular/videogular.module';
import {ContextMenuModule} from '../shared/context-menu/context-menu.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {AtlasRoutingModule} from './atlas-routing.module';
import {AnnotationControlComponent} from './components/annotation-control/annotation-control.component';
import {AtlasDeviceComponent} from './components/atlas-device/atlas-device.component';
import {AtlasVideoComponent} from './components/atlas-video/atlas-video.component';
import {AtlasFlightLogComponent} from './components/atlas-flight-log/atlas-flight-log.component';
import {AtlasUploadComponent} from './components/atlas-upload/atlas-upload.component';
import {CreateGroupLayersDialogComponent} from './components/create-group-layers-dialog/create-group-layers-dialog.component';
import {GenericSettingsComponent} from './components/create-mission/option-sidemenu/generic-settings/generic-settings.component';
import {OptionSidemenuComponent} from './components/create-mission/option-sidemenu/option-sidemenu.component';
import {LayerNotFoundDialogComponent} from './components/layer-not-found-dialog/layer-not-found-dialog.component';
import {DeviceNotFoundDialogComponent} from './components/device-not-found-dialog/device-not-found-dialog.component';
import {LayersControlGroupComponent} from './components/layers-control/layers-control-items/layers-control-group/layers-control-group.component';
import {LayersControlItemComponent} from './components/layers-control/layers-control-items/layers-control-item/layers-control-item.component';
import {LayersControlItemsComponent} from './components/layers-control/layers-control-items/layers-control-items.component';
import {LayersControlComponent} from './components/layers-control/layers-control.component';
import {MoveNewGroupComponent} from './components/move-new-group/move-new-group.component';
import {PointMapComponent} from './components/point-map/point-map.component';
import {RenameLayersDialogComponent} from './components/rename-layers-dialog/rename-layers-dialog.component';
import {SearchLayerComponent} from './components/search-layer/search-layer.component';
import {SetLayerNameDialogComponent} from './components/set-layer-name-dialog/set-layer-name-dialog.component';
import {ToggleBaseLayerComponent} from './components/toggle-base-layer/toggle-base-layer.component';
import {ZoomButtonsComponent} from './components/zoom-buttons/zoom-buttons.component';
import {FileSizePipe} from './pipes/file-size.pipe';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {FlightLogDetailDialogModule} from '@app/shared/dialogs/flight-log-detail-dialog/flight-log-detail-dialog.module';
import {FormatRelativePipe} from '@app/shared/pipes/formatRelative.pipe';
import {DurationPipe} from '@app/shared/pipes/duration.pipe';
import {AclDirective} from '@app/core/directives/acl.directive';
import {LiveVideoPlayerModule} from '@app/shared/live-video-player/live-video-player.module';
import {WebrtcPlayerComponent, WhepPlayerComponent} from 'unleash-components/dist/unleash-components';
import {DeviceContextMenuComponent} from './components/device-context-menu/device-context-menu.component';
import {AtlasSnackbarMessageComponent} from './components/atlas-snackbar-message/atlas-snackbar-message.component';
import {AclDisabledFormControlDirective} from '@app/core/directives/acl-disable-form-control.directive';
import {AppMissionControlComponent} from './components/app-mission-control/app-mission-control.component';
import {NewRouteDirective} from './directives/new-route.directive';
import {CustomTooltipDirective} from '@app/shared/custom-tooltip.directive';
import {MissionViewerControlComponent} from './components/mission-viewer-control/mission-viewer-control.component';
import {MissionsHeaderComponent} from './components/missions-header/missions-header.component';
import {ExportMissionDirective} from './directives/export-mission.directive';
import {RenameMissionComponent} from './components/rename-mission/rename-mission.component';
import {RenameMissionDirective} from './directives/rename-mission.directive';
import {UploadMissionDirective} from './directives/upload-mission.directive';
import {UploadMissionsComponent} from './components/upload-missions/upload-missions.component';
import {DeleteMissionDialogComponent} from './components/delete-mission-dialog/delete-mission-dialog.component';
import {DeleteMissionDirective} from './directives/delete-mission.directive';
import {CompleteRouteDirective} from './directives/complete-route.directive';
import {ClearRouteDirective} from './directives/clear-route.directive';
import {DisableDrawerDirective} from './directives/disable-drawer.directive';
import {EditMissionSettingsComponent} from './components/edit-mission-settings/edit-mission-settings.component';
import {DefaultSettingsDirective} from './directives/default-settings.directive';
import {EditMissionDirective} from './directives/edit-mission.directive';
import {EditWaypointSettingsComponent} from './components/edit-waypoint-settings/edit-waypoint-settings.component';
import {WaypointSettingsDirective} from './directives/waypoint-settings.directive';
import {SaveRouteDirective} from './directives/save-route.directive';
import {CloseEditDirective} from './directives/close-edit.directive';
import {UndoMissionEditDirective} from './directives/undo-mission-edit.directive';
import {RedoMissionEditDirective} from './directives/redo-mission-edit.directive';
import {RevertMissionChangesDirective} from './directives/revert-mission-changes.directive';
import {NumbersOnlyDirectiveModule} from '@app/shared/numbers-only-directive/numbers-only.module';
import {DeleteWaypointDirective} from './directives/delete-waypoint.directive';
import {LeaveEditModeComponent} from './components/leave-edit-mode/leave-edit-mode.component';
import {SmartInspectDirective} from './directives/smart-inspect.directive';
import {OpenPreviewIn3dDirective} from './directives/open-preview-in-3d.directive';
import {SelectMarkersHeaderComponent} from './components/select-markers/select-markers.component';
import {MoveMarkersDialogComponent} from './components/move-markers-dialog/move-markers-dialog.component';
import {MoveMarkersDirective} from './directives/move-markers.directive';
import {CopyMarkersDirective} from './directives/copy-markers.directive';
import {AddToGroupComponent} from './components/add-to-group/add-to-group.component';
import {ShowLayerDirective} from './directives/show-layer.directive';
import {GoToModelDirective} from './directives/go-to-model.directive';
import {GroupAssetDirective} from './directives/group-asset.directive';
import {CopyLinkToAssetDirective} from './directives/copy-link-to-asset.directive';
import {GoToPhotoDirective} from './directives/go-to-photo.directive';
import {BringToBackLayerDirective} from './directives/bring-to-back-layer.directive';
import {BringToFrontLayerDirective} from './directives/bring-to-front-layer.directive';
import {ChangeLayerColorDirective} from './directives/change-layer-color.directive';
import {CompareLayerDirective} from './directives/compare-layer.directive';
import {ExportShapeDirective} from './directives/export-shape.directive';
import {ExportToGeojsonDirective} from './directives/export-to-geojson.directive';
import {ExportToGeotiffDirective} from './directives/export-to-geotiff.directive';
import {RemoveLayertDirective} from './directives/remove-layer.directive';
import {RenameAssetDirective} from './directives/rename-asset.directive';
import {SelectMarkersDirective} from './directives/select-markers.directive';
import {ShowDashboardDirective} from './directives/show-dashboard.directive';
import {ShowOnlyThisLayerDirective} from './directives/show-only-this-layer.directive';
import {MarkerStateMenuComponent} from './components/marker-state-menu/marker-state-menu.component';
import {MarkerStatusInfoComponent} from './components/marker-status-info/marker-status-info.component';
import {UnleashMaterialSharedModule} from '@app/shared/unleash-material/unleash-material-shared.module';
import {SaveLayerVisibilityDirective} from './directives/save-layer-visibility.directive';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {SurveyMissionInfoComponent} from './components/survey-mission-info/survey-mission-info.component';
import {ShowOnlyThisGroupDirective} from './directives/show-only-this-group.directive';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {jobsFeatureKey, jobsReducer} from '@app/jobs/store/jobs.reducer';
import {JobsEffects} from '@app/jobs/store/jobs.effects';
import {ConvertToMissionAssetDirective} from './directives/convert-to-mission-asset.directive';
import {CreateJobDirective} from './directives/create-job.directive';
import {CreateJobDialog} from './components/create-job-dialog/create-job-dialog.component';
import {JobPolygonMenuComponent} from './components/job-polygon-menu/job-polygon-menu.component';
import {atlasFeatureKey, atlasReducer} from './store/atlas.reducer';
import {AtlasEffects} from '@app/atlas/store/atlas.effects';
import {PropertiesSearchResultsComponent} from './components/properties-search-results/properties-search-results.component';
import {HighlightTextPipe} from '@app/shared/pipes/highlight-text.pipe';
import {ScrollingModule as ExperimentalScrollingModule} from '@angular/cdk-experimental/scrolling';
import {EditJobSelectionDirective} from './directives/edit-job-selection.directive';
import {TranslateActionPipe} from './pipes/translate-action.pipe';
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip';

// override leaflet default icon settings
declare let L; // leaflet global
delete L.Icon.Default.prototype._getIconUrl;
const defaultIconSize = 24;
const centerDivider = 2;
L.Icon.Default.mergeOptions({
  iconSize: [defaultIconSize, defaultIconSize],
  iconAnchor: new L.Point(defaultIconSize / centerDivider, defaultIconSize),
  iconRetinaUrl: 'assets/icons/pin.png',
  iconUrl: 'assets/icons/pin.png',
  shadowUrl: null
});

@NgModule({
  declarations: [
    GenericSettingsComponent,
    OptionSidemenuComponent,
    FileSizePipe,
    AtlasMapComponent,
    AtlasDeviceComponent,
    AtlasVideoComponent,
    AtlasFlightLogComponent,
    AtlasUploadComponent,
    LayersControlComponent,
    ToggleBaseLayerComponent,
    ZoomButtonsComponent,
    PointMapComponent,
    AnnotationControlComponent,
    SetLayerNameDialogComponent,
    SearchLayerComponent,
    CreateGroupLayersDialogComponent,
    RenameLayersDialogComponent,
    LayersControlItemsComponent,
    LayersControlItemComponent,
    LayersControlGroupComponent,
    MoveNewGroupComponent,
    LayerNotFoundDialogComponent,
    DeviceNotFoundDialogComponent,
    DeviceContextMenuComponent,
    AtlasSnackbarMessageComponent,
    AppMissionControlComponent,
    NewRouteDirective,
    MissionViewerControlComponent,
    MissionsHeaderComponent,
    ExportMissionDirective,
    RenameMissionComponent,
    RenameMissionDirective,
    UploadMissionsComponent,
    UploadMissionDirective,
    DeleteMissionDialogComponent,
    DeleteMissionDirective,
    CompleteRouteDirective,
    ClearRouteDirective,
    DisableDrawerDirective,
    EditMissionSettingsComponent,
    DefaultSettingsDirective,
    EditMissionDirective,
    EditWaypointSettingsComponent,
    WaypointSettingsDirective,
    SaveRouteDirective,
    CloseEditDirective,
    UndoMissionEditDirective,
    RedoMissionEditDirective,
    RevertMissionChangesDirective,
    DeleteWaypointDirective,
    LeaveEditModeComponent,
    SmartInspectDirective,
    SelectMarkersHeaderComponent,
    MoveMarkersDialogComponent,
    MoveMarkersDirective,
    CopyMarkersDirective,
    AddToGroupComponent,
    MarkerStateMenuComponent,
    MarkerStatusInfoComponent,
    SurveyMissionInfoComponent,
    CreateJobDialog,
    JobPolygonMenuComponent,
    PropertiesSearchResultsComponent,
    EditJobSelectionDirective,
    TranslateActionPipe
  ],
  imports: [
    CommonModule,
    UnleashMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxFileDropModule,
    VideogularModule,
    AtlasRoutingModule,
    LeafletModule,
    AnnotationSharedModule,
    UnleashLoaderModule,
    ContextMenuModule,
    ConfirmDeleteDialogModule,
    DataModule,
    SpinnerModule,
    FlightLogDetailDialogModule,
    FormatRelativePipe,
    DurationPipe,
    AclDirective,
    AclDisabledFormControlDirective,
    LiveVideoPlayerModule,
    WebrtcPlayerComponent,
    CustomTooltipDirective,
    NumbersOnlyDirectiveModule,
    OpenPreviewIn3dDirective,
    ShowLayerDirective,
    GoToModelDirective,
    GoToPhotoDirective,
    CopyLinkToAssetDirective,
    GroupAssetDirective,
    BringToBackLayerDirective,
    BringToFrontLayerDirective,
    ChangeLayerColorDirective,
    CompareLayerDirective,
    ExportShapeDirective,
    ExportToGeojsonDirective,
    ExportToGeotiffDirective,
    RemoveLayertDirective,
    RenameAssetDirective,
    SelectMarkersDirective,
    ShowDashboardDirective,
    ShowOnlyThisLayerDirective,
    ShowOnlyThisGroupDirective,
    UnleashMaterialSharedModule,
    SaveLayerVisibilityDirective,
    CreateJobDirective,
    ScrollingModule,
    ExperimentalScrollingModule,
    StoreModule.forFeature(atlasFeatureKey, atlasReducer),
    StoreModule.forFeature(jobsFeatureKey, jobsReducer),
    EffectsModule.forFeature([JobsEffects, AtlasEffects]),
    ConvertToMissionAssetDirective,
    HighlightTextPipe,
    MtxTooltipModule,
    WhepPlayerComponent
  ],
  exports: [
    LayersControlComponent,
    AtlasDeviceComponent,
    ToggleBaseLayerComponent,
    ZoomButtonsComponent,
    SelectMarkersDirective,
    SelectMarkersHeaderComponent,
    ExportMissionDirective
  ]
})
export class AtlasModule {}

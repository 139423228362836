import {Injectable} from '@angular/core';
import {CompanyModel} from '@app/core/models/api/company-model';
import {UserModel} from '@app/core/models/api/user-model';
import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';
import {SideNavMenuService} from '@app/core/services/side-navbar-menu.service';
import {SidenavMenuFacadeService} from '@app/core/services/sidenav-menu-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {Team, TeamRole} from '@app/profile/models/team.model';
import {userStoreCompany, userStoreData} from '@app/store/user/user.actions';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {combineLatest} from 'rxjs';
import {filter, map, take, tap, withLatestFrom} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SidenavMenuEffects {
  public sidebarMenu$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(
          ofType(userStoreData),
          filter((action: {user: UserModel}) => !!action.user),
          map((action: {user: UserModel}) => action.user),
          take(1)
        ),
        this.sideNavMenuService.isMobileView$,
        this.userStoreFacadeService.currentUserCompany$
      ]).pipe(
        tap(([user, isMobileView, company]: [UserModel, boolean, CompanyModel]) => {
          let menu = [];
          menu = this.sideNavMenuService.MENU_ITEMS;

          if (user.developer) {
            menu = [...menu, ...this.sideNavMenuService.DEVELOPER_DASHBOARD_MENU_ITEM];
          }
          if (company?.useUserTask) {
            menu = [...menu, this.sideNavMenuService.MENU_ITEMS_JOBS];
          }

          menu = [...menu, this.sideNavMenuService.AI_APPS_STORE_MENU_ITEMS];

          menu = [...menu, ...this.sideNavMenuService.MENU_ITEMS_END];

          const index = isMobileView
            ? menu.length
            : menu.findIndex(menuItem => menuItem.label === 'core.sidebar.menu.notifications');

          this.sidenavMenuFacadeService.setMenuItems(menu);
          this.sidenavMenuFacadeService.setLastTopIndex(index - 1);
        })
      ),
    {dispatch: false}
  );

  public sidebarProfileMenuItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userStoreCompany),
        withLatestFrom(this.sidenavMenuFacadeService.profileMenuItem$),
        tap(([action, menuItem]: [{company: CompanyModel | null}, LinkMenuItem]) => {
          this.sidenavMenuFacadeService.setProfileMenuItem(
            new LinkMenuItem({
              label: menuItem.label,
              imagePath: action?.company?.logo || null,
              routerLink: menuItem.routerLink
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private sidenavMenuFacadeService: SidenavMenuFacadeService,
    private sideNavMenuService: SideNavMenuService,
    private userStoreFacadeService: UserStoreFacadeService
  ) {}
}

<div [ngClass]="sideNavbarStateService.expanded ? 'open' : 'close'" class="side-navbar-wrapper">
  <mat-nav-list class="side-navbar">
    <div class="side-navbar__close-button">
      <mat-icon class="side-navbar__close-icon" (click)="closeMobileNav()">close</mat-icon>
    </div>
    <ng-container *ngIf="sideNavbarStateService.indexLastTopMenuItems$ | async as indexLastTopMenuItems">
      <ng-container *ngFor="let menuItem of menuItems$ | async; trackBy: trackByFn; let i = index; let last = last">
        <ng-container *ngIf="menuItem.aclPermission; else noAclTemplate">
          <ng-container *uaHasPermission="menuItem.aclPermission">
            <ng-container
              [ngTemplateOutlet]="menuItemTemplate"
              [ngTemplateOutletContext]="{
                menuItem: menuItem,
                pushToBottom: i === indexLastTopMenuItems,
                isExpanded: sideNavbarStateService.expanded,
                closeMobileNav: closeMobileNav
              }"
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noAclTemplate>
          <ng-container
            [ngTemplateOutlet]="menuItemTemplate"
            [ngTemplateOutletContext]="{
              menuItem: menuItem,
              pushToBottom: i === indexLastTopMenuItems,
              isExpanded: sideNavbarStateService.expanded,
              closeMobileNav: closeMobileNav
            }"
          ></ng-container>
        </ng-template>
      </ng-container>
      <div class="side-navbar__fixed-items" *ngIf="canLoadMenu | async">
        <mat-divider class="divider"></mat-divider>
        <unleash-sidebar-menu-item-team
          [teams]="userTeams$ | async"
          [totalUserTeams]="totalUserTeams$ | async"
          [selectedTeamId]="currentTeamId$ | async"
          [isExpanded]="sideNavbarStateService.expanded"
          [userRole]="userRole$ | async"
          [isMobileView]="isMobileView | async"
          (selectedTeam)="switchCurrentTeam($event)"
          (goToManageTeams)="goToManageTeams()"
          (closeMobileNav)="closeMobileNav()"
          [class.teams-collapsed]="!sideNavbarStateService.expanded"
        ></unleash-sidebar-menu-item-team>
        <mat-divider class="divider"></mat-divider>
        <unleash-sidebar-menu-item-account
          [userName]="userName$ | async"
          [companyName]="companyName$ | async"
          [avatar]="avatar$ | async"
          [isExpanded]="sideNavbarStateService.expanded"
          [menuItem]="sideNavbarStateService.profileMenuItem$ | async"
          (logOut)="logOut()"
          (click)="closeMobileNav()"
        ></unleash-sidebar-menu-item-account>
      </div>
    </ng-container>
  </mat-nav-list>

  <div
    class="toggle-button-wrapper"
    [matTooltip]="
      sideNavbarStateService.expanded
        ? ('core.sidebar.collapseNavigation' | translate)
        : ('core.sidebar.expandNavigation' | translate)
    "
    matTooltipPosition="after"
    (click)="toggleSidebar()"
  >
    <button class="toggle-button" mat-icon-button>
      <mat-icon>
        {{ sideNavbarStateService.expanded ? 'chevron_left' : 'chevron_right' }}
      </mat-icon>
    </button>
  </div>
</div>

<ng-template
  #menuItemTemplate
  let-menuItem="menuItem"
  let-pushToBottom="pushToBottom"
  let-isExpanded="isExpanded"
  let-closeMobileNav="closeMobileNav"
>
  <app-sidebar-menu-item-link
    *ngIf="menuItem.type === menuItemTypes.link"
    [class.push-other-items-to-the-bottom]="pushToBottom"
    [menuItem]="menuItem"
    [isExpanded]="isExpanded"
    class="menu-item"
    (click)="closeMobileNav()"
  ></app-sidebar-menu-item-link>
  <app-sidebar-menu-item-newtab
    *ngIf="menuItem.type === menuItemTypes.newTab"
    [class.push-other-items-to-the-bottom]="pushToBottom"
    [menuItem]="menuItem"
    class="menu-item"
  ></app-sidebar-menu-item-newtab>
</ng-template>

import {Addon} from '@app/store/addon/models/addon';
import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ScheduleModel} from '@app/core/models/api/schedule.model';

@Component({
  selector: 'unleash-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleListComponent implements AfterViewInit {
  @Input('scheduleList') public set setScheduleList(scheduleList: ScheduleModel[]) {
    this.dataSource.data = [...scheduleList].sort((scheduleA, scheduleB) =>
      scheduleA.title.localeCompare(scheduleB.title)
    );

    this.dataSource.paginator = this.paginator;
  }
  @Input() public addons: Addon[] = [];
  @Input() public isAdmin: boolean = false;

  @Output() public edit: EventEmitter<string> = new EventEmitter();
  @Output() public view: EventEmitter<string> = new EventEmitter();
  @Output() public delete: EventEmitter<{scheduleName: string; scheduleId: string}> = new EventEmitter();
  @Output() public newSchedule: EventEmitter<void> = new EventEmitter();

  @ViewChild('paginator') public paginator: MatPaginator;

  public dataSource: MatTableDataSource<ScheduleModel> = new MatTableDataSource<ScheduleModel>();
  public displayedColumns = ['name', 'devices', 'type', 'scheduleDays', 'aiApps', 'actions'];

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public sortSchedules() {
    this.dataSource.data = this.dataSource.data.reverse();
  }

  public emitEdit(scheduleId: string): void {
    this.edit.emit(scheduleId);
  }

  public emitView(scheduleId: string): void {
    this.view.emit(scheduleId);
  }

  public emitDelete(scheduleName: string, scheduleId: string): void {
    this.delete.emit({scheduleName, scheduleId});
  }

  public emitNewSchedule(): void {
    this.newSchedule.emit();
  }
}

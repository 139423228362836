export const ALLOWED_FILE_TYPES = {
  LIBRARY: {
    MIME_TYPES: ['image/*', 'video/*', 'application/vnd.las', 'application/vnd.laz', 'application/json', 'text/plain'],
    EXTENSIONS: [
      '.jpg',
      '.jpeg',
      '.png',
      '.gif',
      '.tiff',
      '.bmp',
      '.iiq',
      '.mp4',
      '.mov',
      '.avi',
      '.mpeg',
      '.las',
      '.laz',
      '.json',
      '.360',
      '.txt'
    ]
  }
};

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
import {NotificationLayout, NotificationModel} from '@app/core/models/api/notifications.model';
import {actionHideNotification} from '@app/core/notifications/notifications.actions';
import {selectNewNotification} from '@app/core/notifications/notifications.selectors';
import {LayoutCheckerService} from '@app/core/services/layout-checker.service';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, Observable, Subscription, filter, map, of, switchMap, take} from 'rxjs';
import {NotificationSidebarComponent} from '../notification-sidebar/notification-sidebar.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SideNavMenuService} from '@app/core/services/side-navbar-menu.service';
import {TranslateService} from '@ngx-translate/core';
import {SidebarStateService} from '@app/core/services/sidebar-state.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'ua-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecureHomeComponent {
  public isDeveloperMode$: Observable<boolean> = this.userStoreFacadeService.isDeveloperMode$;
  public notification$: Observable<NotificationModel> = this.store.pipe(select(selectNewNotification));
  public notificationLayout: typeof NotificationLayout = NotificationLayout;
  public isSwitchingTeam$: Observable<boolean> = this.userStoreFacadeService.isSwitchingTeam$;
  public mobileHeaderLabel: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private notificationSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(
    public router: Router,
    public layoutCheckerService: LayoutCheckerService,
    public notificationService: NotificationService,
    private userStoreFacadeService: UserStoreFacadeService,
    public store: Store,
    public dialog: MatDialog,
    private sideNavMenuService: SideNavMenuService,
    private translateService: TranslateService,
    public sideNavbarStateService: SidebarStateService
  ) {
    this.notificationSubscription = this.notificationService.isOpenNotificationsSidebar$
      .pipe(filter(isOpened => !!isOpened))
      .subscribe(() => {
        this.openDialog();
      });
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() =>
          this.sideNavMenuService.MENU_ITEMS_MOBILE_LIST.filter(item => this.router.url.includes(item?.routerLink)).map(
            menuItem => menuItem.label
          )
        ),
        switchMap(menuLabel => (menuLabel.length ? this.translateService.get(menuLabel[0]) : of('')))
      )
      .subscribe(mobileHeaderLabel => {
        this.mobileHeaderLabel.next(mobileHeaderLabel);
        const notIncludedMobileSidenavUrls = ['/summary'];
        const hasToHideMobileSidenav = notIncludedMobileSidenavUrls.some(string => this.router.url.includes(string));
        !!mobileHeaderLabel || hasToHideMobileSidenav
          ? this.layoutCheckerService.isShowFooterNav$.next(false)
          : this.layoutCheckerService.isShowFooterNav$.next(true);
      });
  }

  public openDialog(): void {
    const notificationsDialog = this.dialog.open(NotificationSidebarComponent, {
      minWidth: '375px',
      height: '100vh',
      maxWidth: '100vw',
      panelClass: 'notifications-dialog',
      autoFocus: false
    });
    notificationsDialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.notificationService.closeNotificationSidebar();
      });
  }

  public closeNotificationSidebar(): void {
    this.notificationService.closeNotificationSidebar();
  }

  public openChange(hasOpenSidebar: boolean): void {
    if (!hasOpenSidebar) {
      this.closeNotificationSidebar();
    }
  }

  public hideNotification(): void {
    this.store.dispatch(actionHideNotification());
  }

  public openMobileMenu(): void {
    this.sideNavbarStateService.expanded = true;
    this.sideNavbarStateService.toggleMobileMenu();
    this.layoutCheckerService.isShowFooterNav$.next(true);
  }
}

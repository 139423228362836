import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {UserModel} from '@app/core/models/api/user-model';
import {UserService} from '@app/core/services/api/user.service';
import {setupMobilePhone, verifyMobileNumber} from '@app/store/user/user.actions';
import {Store} from '@ngrx/store';
import {countries} from 'country-codes-flags-phone-codes';
import {Observable, Subscription, debounceTime, from, map, shareReplay, startWith, switchMap, take} from 'rxjs';
import {Auth} from '@aws-amplify/auth';
import {AuthService} from '@app/auth/services/auth.service';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent {
  @Input('user')
  public set fillCompany(user: UserModel) {
    if (user && Object.keys(user).length > 0) {
      this.user = user;
      this.form.controls.name.setValue(user.fullName);
      this.form.controls.avatar.setValue(user.avatar);
      if (this.user.phone) {
        this.setPhoneNumberState(phoneNumberStatus.EDIT);
      }
    }
  }
  @Input() public isSendingForgotCode: boolean = false;

  @Output() public changePassword: EventEmitter<void> = new EventEmitter();

  public user: UserModel;
  public form = new UntypedFormGroup({
    name: new UntypedFormControl(['', {updateOn: 'blur', validators: [Validators.required]}]),
    avatar: new UntypedFormControl([''])
  });
  public phoneForm = new UntypedFormGroup({
    countryCode: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/^\+\d+$/),
      Validators.maxLength(5),
      Validators.minLength(3)
    ]),
    mobileNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(15)])
  });
  public countries = countries.sort((a, b) => a.name.localeCompare(b.name));
  public isPhoneListOpened: boolean = false;
  public filteredOptions = null;
  public isUpdatingCognitoUser$: Observable<boolean> = this.userService.isUpdatingCognitoUser$.pipe(shareReplay(1));
  public phoneNumberState: phoneNumberStatus = phoneNumberStatus.VERIFY;
  public PHONE_NUMBER_STATE = phoneNumberStatus;
  public hasSmsMfa$ = from(Auth.currentAuthenticatedUser()).pipe(
    map(cognitoUser => cognitoUser.preferredMFA === 'SMS_MFA'),
    shareReplay(1)
  );
  public hasPhoneNumberVerified$ = this.userService.updateCognitoUserFinished$.pipe(
    switchMap(() => from(Auth.currentAuthenticatedUser({bypassCache: true})).pipe(take(1))),
    map(cognitoUser => !!cognitoUser?.attributes?.phone_number_verified),
    shareReplay(1)
  );
  private formChangeSubscription: Subscription;

  constructor(private userService: UserService, private authService: AuthService, private store: Store) {
    this.userService.updateCognitoUserFinished();
  }

  public ngOnInit() {
    this.filteredOptions = this.phoneForm.controls.countryCode.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      map(value => this.filterCountries(value))
    );

    this.formChangeSubscription = this.phoneForm.valueChanges.subscribe(() => {
      const canLogOut = !(
        this.phoneForm.touched &&
        this.phoneForm.value.countryCode + this.phoneForm.value.mobileNumber !== this.user.phone
      );
      this.authService.setCanLogOutOnSetMobilePhone(canLogOut);
    });
  }

  private filterCountries(value: string) {
    const filterValue = value.toLowerCase();
    return this.countries.filter(
      country =>
        country.name.toLowerCase().includes(filterValue) || country.dialCode.toLowerCase().includes(filterValue)
    );
  }

  public update() {
    if (this.form.valid) {
      this.userService.updateUser({
        fullName: this.form.value.name,
        avatar: this.form.value.avatar
      });
    } else {
      this.form.controls.name.markAsTouched();
    }
  }

  public updateUserAvatar(s3Url: string): void {
    this.form.patchValue({avatar: s3Url});
    this.update();
  }

  public setIsPhoneListOpened(isPhoneListOpened: boolean): void {
    this.isPhoneListOpened = isPhoneListOpened;
  }

  public verifyMobileNumber() {
    const phoneNumber = this.phoneForm.value.countryCode + this.phoneForm.value.mobileNumber;
    this.store.dispatch(
      setupMobilePhone({
        phoneNumber
      })
    );
  }

  public verifyExistingPhoneNumber(phoneNumber: string) {
    this.store.dispatch(
      verifyMobileNumber({
        phoneNumber
      })
    );
  }

  public setPhoneNumberState(state: phoneNumberStatus): void {
    this.phoneNumberState = state;
  }

  public clearPhoneNumberForm(): void {
    this.phoneForm.reset();
  }

  public emitChangePassword(): void {
    this.changePassword.emit();
  }
}

enum phoneNumberStatus {
  EDIT,
  VERIFY
}

<ng-container *ngIf="selectedMission; else planMissionTemplate">
  <ng-container *ngIf="isEditMode; else viewMissionHeaderTemplate">
    <nav class="global-process-bar">
      <section class="global-process-bar__container">
        <button
          class="global-process-bar__button"
          mat-icon-button
          [ulCloseEdit]="selectedWaypointIndex"
          (updateHeader)="setEditMode(false)"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="global-process-bar__info">
          <h1 class="global-process-bar__title">
            {{ 'atlas.mission.editMission' | translate }}
          </h1>
          <ng-container *ngTemplateOutlet="missionInfoTemplate; context: {$implicit: selectedMission}"></ng-container>
        </div>
      </section>
    </nav>
  </ng-container>
  <ng-template #viewMissionHeaderTemplate>
    <nav class="global-process-bar">
      <section class="global-process-bar__container">
        <button class="global-process-bar__button" mat-icon-button ulDisableDrawer (click)="emitCloseMissionViewer()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="global-process-bar__info">
          <h1 class="global-process-bar__title" data-test-id="view-mission-title">
            {{ isNewMission ? ('atlas.mission.newMission' | translate) : ('atlas.mission.viewMission' | translate) }}
          </h1>
          <ng-container *ngTemplateOutlet="missionInfoTemplate; context: {$implicit: selectedMission}"></ng-container>
        </div>
      </section>
    </nav>
  </ng-template>

  <ng-template #missionInfoTemplate let-mission>
    <section class="global-process-bar__title--subtitle">
      {{ mission?.name }}
      <ng-container *ngIf="isNewMission; else routesTemplate">
        <div class="point"></div>
        <span class="plan-mission-header__mission-type">
          {{ missionTypeDisplay[mission.type] }}
        </span>
      </ng-container>
      <ng-template #routesTemplate>
        <ng-container *ngIf="selectedMission.type !== missionType.MAPPING_2D; else surveyMissionRouteTemplate">
          <div class="point"></div>
          {{ mission?.route?.length }} {{ 'atlas.mission.waypoints' | translate }}
        </ng-container>
        <ng-template #surveyMissionRouteTemplate>
          <div class="point"></div>
          {{ mission?.waylineFolders[0]?.route?.length }} {{ 'atlas.mission.waypoints' | translate }}
        </ng-template>
      </ng-template>
      <ng-container *ngIf="!isNewMission">
        <div class="point"></div>
        <span class="plan-mission-header__mission-type">
          {{ missionTypeDisplay[mission.type] }}
        </span>
      </ng-container>
      <ng-container *ngIf="selectedMission.type !== missionType.MAPPING_2D; else surveyMissionTimeTemplate">
        <div class="point"></div>
        {{ mission?.distance | number: '1.2-2' }} {{ 'atlas.mission.kilometers' | translate }}
        <div class="point"></div>
        {{ mission?.time | uDuration: 'shortFormatFlight' }}
      </ng-container>
      <ng-template #surveyMissionTimeTemplate>
        <div class="point"></div>
        {{ mission?.waylineFolders[0]?.distance / 1000 | number: '1.2-2' }} {{ 'atlas.mission.kilometers' | translate }}
        <div class="point"></div>
        {{ mission?.waylineFolders[0]?.duration | uDuration: 'shortFormatFlight' }}
      </ng-template>
    </section>
  </ng-template>
  <nav *ngIf="selectedMission.type !== missionType.MAPPING_2D" class="global-action-bar">
    <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
      <ng-container *ngIf="isNewMission; else viewMissionTemplate">
        <button
          [ulRenameMission]="selectedMission"
          [isNewMission]="true"
          (updatedMission)="emitUpdatedMission($event)"
          mat-button
          class="global-action-bar__button"
          [disabled]="isCreatingMission"
        >
          <mat-icon class="global-action-bar__icon-svg" svgIcon="rename-mission"></mat-icon>
          {{ 'atlas.mission.rename' | translate }}
        </button>
        <button
          ulClearRoute
          (click)="clearRoutes()"
          [disabled]="!selectedMission.route.length || isCreatingMission"
          mat-button
          class="global-action-bar__button"
        >
          <mat-icon class="global-action-bar__icon">location_off</mat-icon>
          {{ 'flights.createMission.clearRoute' | translate }}
        </button>
        <button
          [disabled]="selectedMission.route.length < 2 || isCreatingMission"
          [ulCompleteRoute]="selectedMission"
          (click)="setIsCreatingMission(true)"
          (routeSaved)="setIsCreatingMission(false); setNewMission(false)"
          mat-button
          class="global-action-bar__button"
        >
          <ng-container *ngIf="isCreatingMission; else completeIconTemplate">
            <unleash-spinner class="global-action-bar__icon-inline" diameter="20" color="#fff"></unleash-spinner>
          </ng-container>
          <ng-template #completeIconTemplate>
            <mat-icon class="global-action-bar__icon">beenhere</mat-icon>
          </ng-template>
          {{ 'atlas.mission.completeRoute' | translate }}
        </button>
      </ng-container>
      <ng-template #viewMissionTemplate>
        <ng-container *ngIf="isEditMode; else missionTemplate">
          <button
            [ulRenameMission]="selectedMission"
            [isNewMission]="false"
            (updatedMission)="emitUpdatedMission($event)"
            mat-button
            class="global-action-bar__button"
            [disabled]="isUpdatingMission"
          >
            <mat-icon class="global-action-bar__icon-svg" svgIcon="rename-mission"></mat-icon>
            {{ 'atlas.mission.rename' | translate }}
          </button>
          <button
            [ulDeleteMission]="selectedMission"
            (updateSelectedMission)="emitUpdatedMission(null); setEditMode(false)"
            mat-button
            class="global-action-bar__button"
            [disabled]="isUpdatingMission"
          >
            <mat-icon class="global-action-bar__icon">delete</mat-icon> {{ 'common.delete' | translate }}
          </button>
          <button ulRevertMissionChanges mat-button class="global-action-bar__button" [disabled]="isUpdatingMission">
            <mat-icon class="global-action-bar__icon-svg" svgIcon="restore"></mat-icon>
            {{ 'atlas.mission.revertAllChanges' | translate }}
          </button>
          <button ulUndoMissionEdit mat-button class="global-action-bar__button" [disabled]="isUpdatingMission">
            <mat-icon class="global-action-bar__icon-svg" svgIcon="undo"></mat-icon>
            {{ 'atlas.mission.undo' | translate }}
          </button>
          <button ulRedoMissionEdit mat-button class="global-action-bar__button" [disabled]="isUpdatingMission">
            <mat-icon class="global-action-bar__icon-svg" svgIcon="redo"></mat-icon>
            {{ 'atlas.mission.redo' | translate }}
          </button>
          <button mat-button ulOpenPreviewIn3d [mission]="selectedMission" class="global-action-bar__button">
            <mat-icon class="global-action-bar__icon-svg" svgIcon="u-preview-3d"></mat-icon>
            <span>{{ 'atlas.mission.previewIn3D' | translate }}</span>
          </button>
          <button
            mat-button
            class="global-action-bar__button"
            [ulSaveRoute]="selectedWaypointIndex"
            (click)="setIsUpdatingMission(true)"
            (missionUpdated)="setIsUpdatingMission(false); setEditMode(false)"
            [disabled]="isUpdatingMission"
          >
            <ng-container *ngIf="isUpdatingMission; else iconTemplate">
              <unleash-spinner class="global-action-bar__icon-inline" diameter="20" color="#fff"></unleash-spinner>
            </ng-container>
            <ng-template #iconTemplate>
              <mat-icon class="global-action-bar__icon-svg" svgIcon="save-changes"></mat-icon>
            </ng-template>
            {{ 'atlas.mission.saveChanges' | translate }}
          </button>
        </ng-container>
        <ng-template #missionTemplate>
          <ng-container *ngIf="selectedMission.type === missionType.MAPPING_2D; else defaultMissionActions">
            <button mat-button class="global-action-bar__button">
              <mat-icon class="global-action-bar__icon-svg" svgIcon="export-white"></mat-icon>
              {{ 'atlas.mission.export' | translate }}
            </button>
          </ng-container>
          <ng-template #defaultMissionActions>
            <button
              mat-button
              *uaHasPermission="aclPermissions.MissionsApiUpdate"
              ulEditMission
              class="global-action-bar__button"
              (click)="setEditMode(true)"
            >
              <mat-icon class="global-action-bar__icon">edit</mat-icon> {{ 'atlas.mission.editMission' | translate }}
            </button>
            <button mat-button ulOpenPreviewIn3d [mission]="selectedMission" class="global-action-bar__button">
              <mat-icon class="global-action-bar__icon-svg" svgIcon="u-preview-3d"></mat-icon>
              <span>{{ 'atlas.mission.previewIn3D' | translate }}</span>
            </button>
            <button [matMenuTriggerFor]="menu" mat-button class="global-action-bar__button">
              <mat-icon class="global-action-bar__icon-svg" svgIcon="export-white"></mat-icon>
              {{ 'atlas.mission.export' | translate }}
              <mat-icon class="global-action-bar__icon"> arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button class="global-action-bar__menu-item" [ulExportMission]="selectedMission" [exportType]="MissionExportType.JSON" mat-menu-item>
                <mat-icon class="global-action-bar__icon-svg" svgIcon="coco-json"></mat-icon>
                <span>{{ 'atlas.mission.downloadJson' | translate }}</span>
              </button>
              <button class="global-action-bar__menu-item"  [ulExportMission]="selectedMission" [exportType]="MissionExportType.GEOJSON" mat-menu-item>
                <mat-icon class="global-action-bar__icon-svg" svgIcon="coco-json"></mat-icon>
                <span>{{ 'atlas.mission.exportGeojson' | translate }}</span>
              </button>
              <button
                class="global-action-bar__menu-item"
                [cdkCopyToClipboard]="
                  {
                    name: selectedMission?.name,
                    description: selectedMission?.description,
                    heightMode: selectedMission?.heightMode,
                    isSmartInspect: selectedMission?.isSmartInspect || false,
                    route: selectedMission?.route
                  } | json
                "
                (click)="showCopyToClipboard()"
                mat-menu-item
              >
                <mat-icon class="global-action-bar__icon">content_copy</mat-icon>
                <span>{{ 'atlas.mission.copyJson' | translate }}</span>
              </button>
            </mat-menu>
          </ng-template>
        </ng-template>
      </ng-template>
    </section>
  </nav>
</ng-container>
<ng-template #planMissionTemplate>
  <nav class="global-process-bar global-process-bar--single-line">
    <div class="global-process-bar__container">
      <button class="global-process-bar__button" mat-icon-button routerLink="/secure/atlas">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="global-process-bar__info">
        <h1 class="global-process-bar__title">
          {{ 'atlas.mission.planMissions' | translate }}
        </h1>
      </div>
    </div>
  </nav>
  <nav class="global-action-bar mission-actions">
    <section class="global-action-bar__options global-action-bar__options--mobile-menu-hidden">
      <button
        *uaHasPermission="aclPermissions?.MissionsApiCreate"
        ulUploadMission
        mat-button
        class="global-action-bar__button"
        data-test-id="upload-mission"
      >
        <mat-icon class="global-action-bar__icon">upload</mat-icon>
        <span> {{ 'common.upload' | translate }} </span>
      </button>
      <button
        *uaHasPermission="aclPermissions?.MissionsApiCreate"
        mat-button
        class="global-action-bar__button"
        ulNewRoute
        (click)="emitNewMission()"
      >
        <mat-icon class="global-action-bar__icon-svg" svgIcon="add"></mat-icon>
        <span> {{ 'atlas.mission.newMission' | translate }} </span>
      </button>
      <button *uaHasPermission="aclPermissions?.FlightsApiRead" mat-button class="global-action-bar__button" routerLink="/secure/flights/logs">
        <mat-icon class="global-action-bar__icon">list</mat-icon>
        <span> {{ 'atlas.mission.flightLogs' | translate }} </span>
      </button>
    </section>
  </nav>
</ng-template>

export interface acl {
  [key: string]: boolean;
}

export enum AclPermissions {
  AccountApiUlAdmin = 'account-api:ulAdmin',
  AccountApiUploadImage = 'account-api:uploadImage',

  AddonApiCliAccess = 'addon-api:cliAccess',
  AddonApiActivate = 'addon-api:activate',
  AddonApiDeactivate = 'addon-api:deactivate',
  AddonApiRead = 'addon-api:read',
  AddonApiListPublic = 'addon-api:listPublic',
  AddonApiCreate = 'addon-api:create',
  AddonApiPublish = 'addon-api:publish',
  AddonApiDelete = 'addon-api:delete',
  AddonApiReview = 'addon-api:review',
  AddonApiSubmitToReview = 'addon-api:submitToReview',
  AddonApiUnpublish = 'addon-api:unpublish',
  AddonApiUpdate = 'addon-api:update',
  AddonApiWithdrawFromReview = 'addon-api:withdrawFromReview',

  AiStreamApiKillAI = 'ai-stream-api:killAI',
  AiStreamApiRunAI = 'ai-stream-api:runAI',

  AnalyticsApiCreateUserApiKey = 'analytics-api:createUserApiKey',
  AnalyticsApiDeleteUserApiKey = 'analytics-api:deleteUserApiKey',

  AnalyticsApiListDevices = 'analytics-api:listDevices',
  AnalyticsApiGetSessions = 'analytics-api:getSessions',
  AnalyticsApiGetTemporaryDashboardLink = 'analytics-api:getTemporaryDashboardLink',

  AtlasApiRead = 'atlas-api:read',
  AtlasApiCreate = 'atlas-api:create',
  AtlasApiDelete = 'atlas-api:delete',
  AtlasApiUpdate = 'atlas-api:update',
  AtlasApiGeojson2shp = 'atlas-api:geojson2shp',
  AtlasApiKml2geojson = 'atlas-api:kml2geojson',
  AtlasApiGenerate = 'atlas-api:generate',
  AtlasApiAssignAssets = 'atlas-api:assignAssets',

  BatchJobsApiComputerVisionJob = 'batch-jobs-api:computerVisionJob',
  BatchJobsApiModelExport = 'batch-jobs-api:modelExport', // not on frontend
  BatchJobsApiOpenDroneMapJob = 'batch-jobs-api:openDroneMapJob',

  CortexPluginApiCreate = 'cortex-plugin-api:create', // not on frontend
  CortexPluginApiGet = 'cortex-plugin-api:get', // not on frontend
  CortexPluginApiList = 'cortex-plugin-api:list', // not on frontend
  CortexPluginApiUpdate = 'cortex-plugin-api:update', // not on frontend

  LibraryApiRename = 'library-api:rename',
  LibraryApiAnnotationSummary = 'library-api:annotationSummary',
  LibraryApiBatchDownload = 'library-api:batchDownload',
  LibraryApiCreateFolder = 'library-api:createFolder',
  LibraryApiExportAnalytics = 'library-api:exportAnalytics',
  LibraryApiGraphQlDeleteItems = 'library-api:graphQlDeleteItems',
  LibraryApiRead = 'library-api:read',
  LibraryApiGraphQlMoveItems = 'library-api:graphQlMoveItems',
  LibraryApiGraphQlUpdateTags = 'library-api:graphQlUpdateTags',
  LibraryApiSaveAnnotations = 'library-api:saveAnnotations',
  LibraryApiUploadToLibraryItems = 'library-api:uploadToLibraryItems',

  LibraryApiStartGDriveImport = 'library-integration-api:startGDriveImport',
  LibraryApiStartS3Import = 'library-integration-api:startS3Import',
  LibraryApiStartSkydioImport = 'library-integration-api:startSkydioImport',

  LivestreamApiStreamRead = 'livestream-api:streamRead',
  LivestreamApiStreamPublish = 'livestream-api:streamPublish',

  MainApiCheckPromoCode = 'main-api:checkPromoCode',
  MainApiCreateDevice = 'main-api:createDevice',
  MainApiCreateUser = 'main-api:createUser',
  MainApiDeleteDevice = 'main-api:deleteDevice',
  MainApiDeleteUser = 'main-api:deleteUser',
  MainApiGetDevice = 'main-api:getDevice',
  MainApiGetUser = 'main-api:getUser',
  MainApiReadDevice = 'main-api:readDevice',
  MainApiReadPlan = 'main-api:readPlan',
  MainApiPublicPlansList = 'main-api:publicPlansList',
  MainApiSubscribeToPlan = 'main-api:subscribeToPlan',
  MainApiUnsubscribeUser = 'main-api:unsubscribeUser',
  MainApiUpdateDevice = 'main-api:updateDevice',
  MainApiUpdateUser = 'main-api:updateUser',

  MissionsApiCreate = 'missions-api:create',
  MissionsApiDelete = 'missions-api:delete',
  MissionsApiRead = 'missions-api:read',
  MissionsApiUpdate = 'missions-api:update',

  OrganizationApiAddAiApp = 'organization-api:addAiApp',
  OrganizationApiAddUser = 'organization-api:addUser',
  OrganizationApiCreate = 'organization-api:create',
  OrganizationApiGetCompany = 'organization-api:getCompany',
  OrganizationApiGetTeam = 'organization-api:getTeam',
  OrganizationApiRemove = 'organization-api:remove',
  OrganizationApiRemoveAiApp = 'organization-api:removeAiApp',
  OrganizationApiRemoveUser = 'organization-api:removeUser',
  OrganizationApiUpdateCompany = 'organization-api:updateCompany',
  OrganizationApiGetAdminOrganizationData = 'organization-api:getAdminOrganizationData',

  ReportApiDelete = 'report-api:delete',
  ReportApiRead = 'report-api:read',
  ReportApiExportData = 'report-api:exportData',
  ResellersApiRequestActivationLink = 'resellers-api:requestActivationLink',

  TagApiCreate = 'tag-api:create',
  TagApiDelete = 'tag-api:delete',
  TagApiRead = 'tag-api:read',
  TagApiUpdate = 'tag-api:update',

  FlightsApiCreate = 'flights-api:create',
  FlightsApiDelete = 'flights-api:delete',

  JobsApiListByTeamId = 'user-task-api:listJobsByTeamId',
  JobsApiCreateJob = 'user-task-api:createJob',
  JobsApiDeleteJob = 'user-task-api:deleteJob',
  JobsApiUpdateJob = 'user-task-api:updateJob',
  JobsApiCreateTask = 'user-task-api:createTask',
  JobsApiUpdateTask = 'user-task-api:updateTask',
  JobsassignUserToAllTasks= 'user-task-api:assignUserToAllTasksInJob'
}

import {createAction, props} from '@ngrx/store';
import {ListScheduleResponse} from '@app/live/models/schedule.model';
import {
  BatchCreateSchedule,
  BatchDeleteSchedulePayload,
  BatchUpdateSchedule,
  ScheduleModel
} from '@app/core/models/api/schedule.model';

export enum LiveSchedulesActionTypes {
  loadSchedules = '[Live Schedules] Load schedules',
  loadSingleSchedule = '[Live Schedules] Load single schedule',
  loadSchedulesSuccess = '[Live Schedules] Load schedules success',
  loadSchedulesFail = '[Live Schedules] Load schedules fail',
  updateIsLoadingSchedules = '[Live Schedules] Update is loading schedules',
  updateIsExecutingAction = '[Live Schedules] Update is executing action',

  batchCreateSchedule = '[Live Schedules] Batch create schedule',
  batchCreateScheduleSuccess = '[Live Schedules] Batch create schedule success',

  batchUpdateSchedule = '[Live Schedules] Batch update schedule',
  batchUpdateScheduleSuccess = '[Live Schedules] Batch update schedule success',

  batchDeleteSchedule = '[Live Schedules] Batch delete schedules',
  batchDeleteScheduleSuccess = '[Live Schedules] Batch delete schedule success'
}
export const loadSchedules = createAction(LiveSchedulesActionTypes.loadSchedules);
export const loadSchedulesSuccess = createAction(
  LiveSchedulesActionTypes.loadSchedulesSuccess,
  props<ListScheduleResponse>()
);
export const loadSchedulesFail = createAction(LiveSchedulesActionTypes.loadSchedulesFail, props<any>());

export const batchCreateSchedule = createAction(
  LiveSchedulesActionTypes.batchCreateSchedule,
  props<{payload: BatchCreateSchedule}>()
);
export const batchCreateScheduleSuccess = createAction(
  LiveSchedulesActionTypes.batchCreateScheduleSuccess,
  props<ScheduleModel>()
);
export const batchUpdateSchedule = createAction(
  LiveSchedulesActionTypes.batchUpdateSchedule,
  props<{scheduleId: string; payload: BatchUpdateSchedule}>()
);

export const batchUpdateScheduleSuccess = createAction(
  LiveSchedulesActionTypes.batchUpdateScheduleSuccess,
  props<ScheduleModel>()
);

export const batchDeleteSchedule = createAction(
  LiveSchedulesActionTypes.batchDeleteSchedule,
  props<BatchDeleteSchedulePayload>()
);

export const batchDeleteScheduleSuccess = createAction(
  LiveSchedulesActionTypes.batchDeleteScheduleSuccess,
  props<ScheduleModel>()
);

export const updateIsLoadingSchedules = createAction(
  LiveSchedulesActionTypes.updateIsLoadingSchedules,
  props<{isLoadingSchedules: boolean}>()
);

export const loadSingleSchedule = createAction(
  LiveSchedulesActionTypes.loadSingleSchedule,
  props<{scheduleId: string}>()
);

export const updateIsExecutingAction = createAction(
  LiveSchedulesActionTypes.updateIsExecutingAction,
  props<{isExecutingAction: boolean}>()
);

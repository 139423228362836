import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as jobsActions from '../store/jobs.actions';
import * as jobsSelectors from '../store/jobs.selectors';
import {Observable} from 'rxjs';
import {JOB_STATUS, Job, Task} from '../models/jobs.models';
import {TeamRole} from '@app/profile/models/team.model';

@Injectable({
  providedIn: 'root'
})
export class JobsFacadeService {
  public inProgressJobs$: Observable<Job[]> = this.store.select(jobsSelectors.selectInProgressJobs);
  public completeJobs$: Observable<Job[]> = this.store.select(jobsSelectors.selectCompleteJobs);
  public failedJobs$: Observable<Job[]> = this.store.select(jobsSelectors.selectFailedJobs);
  public draftJobs$: Observable<Job[]> = this.store.select(jobsSelectors.selectDraftJobs);
  public publishedJobs$: Observable<Job[]> = this.store.select(jobsSelectors.selectPublishedJobs);
  public nextToken$: Observable<string> = this.store.select(jobsSelectors.selectNextToken);
  public taskNextToken$: Observable<string> = this.store.select(jobsSelectors.selectTasksNextToken);

  public isLoadingJobs$: Observable<boolean> = this.store.select(jobsSelectors.selectIsLoadingJobs);
  public isLoadingSingleJob$: Observable<boolean> = this.store.select(jobsSelectors.selectIsLoadingSingleJob);
  public hasJobs$: Observable<boolean> = this.store.select(jobsSelectors.selectHasJobs);
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> = this.store.select(
    jobsSelectors.selectTeamMembers
  );
  public isLoadingTasks$: Observable<boolean> = this.store.select(jobsSelectors.selectIsLoadingTasks);
  public isLoadingMarkers$: Observable<boolean> = this.store.select(jobsSelectors.selectIsLoadingMarkers);
  public allJobsLoaded$: Observable<boolean> = this.store.select(jobsSelectors.selectAllJobsLoaded);
  public isAddingTasks$: Observable<boolean> = this.store.select(jobsSelectors.selectIsAddingTasks);
  public isUpdatingJob$: Observable<boolean> = this.store.select(jobsSelectors.selectIsUpdatingJob);

  constructor(public store: Store) {}

  public listJobs(): void {
    this.store.dispatch(jobsActions.storeJobs());
  }

  public storeJobsSuccess(items: Job[], nextToken: string): void {
    this.store.dispatch(jobsActions.storeJobsSuccess({items, nextToken}));
  }

  public createNewJob(): void {
    this.store.dispatch(jobsActions.createNewJob());
  }

  public createNewJobSuccess(job: Job): void {
    this.store.dispatch(jobsActions.createNewJobSuccess({job}));
  }

  public storeSingleJob(jobId: string): void {
    this.store.dispatch(jobsActions.storeSingleJob({jobId}));
  }

  public storeSingleJobSuccess(job: Job): void {
    this.store.dispatch(jobsActions.storeSingleJobSuccess({job}));
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJob(jobId: string): Observable<Job> {
    return this.store.select(jobsSelectors.selectJob(jobId));
  }

  public updateIsLoadingSingleJob(isLoadingSingleJob: boolean): void {
    this.store.dispatch(jobsActions.updateIsLoadingSingleJob({isLoadingSingleJob}));
  }

  public openAddUsers(jobId: string): void {
    this.store.dispatch(jobsActions.openAddUsers({jobId}));
  }

  public storeTeamMembersSuccess(teamMembers: any[]): void {
    this.store.dispatch(jobsActions.storeTeamMembersSuccess({teamMembers}));
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJobUsers(jobId: string): Observable<string[]> {
    return this.store.select(jobsSelectors.selectJobUsers(jobId));
  }

  // eslint-disable-next-line rxjs/finnish
  public selectJobTasks(jobId: string): Observable<Task[]> {
    return this.store.select(jobsSelectors.selectJobTasks(jobId));
  }

  public updateJobSuccess(jobId: string, params: Partial<Job>): void {
    this.store.dispatch(jobsActions.updateJobSuccess({jobId, params}));
  }

  public storeJobTasks(jobId: string): void {
    this.store.dispatch(jobsActions.storeJobTasks({jobId}));
  }

  public storeJobTasksSuccess(tasks: any[], jobId: string, nextToken: string): void {
    this.store.dispatch(jobsActions.storeJobTasksSuccess({tasks, jobId, nextToken}));
  }

  public updateIsLoadingTasks(isLoadingTasks: boolean): void {
    this.store.dispatch(jobsActions.updateIsLoadingTasks({isLoadingTasks}));
  }

  public updateIsLoadingJobs(isLoadingJobs: boolean): void {
    this.store.dispatch(jobsActions.updateIsLoadingJobs({isLoadingJobs}));
  }

  public updateIsLoadingMarkers(isLoadingMarkers: boolean): void {
    this.store.dispatch(jobsActions.updateIsLoadingMarkers({isLoadingMarkers}));
  }

  public addTasks(jobId: string, tasks: any[], selectedAssetId: string): void {
    this.store.dispatch(jobsActions.addTasks({jobId, tasks, selectedAssetId}));
  }

  public addMissionTasks(jobId: string, tasks: any[]): void {
    this.store.dispatch(jobsActions.addMissionTasks({jobId, tasks}));
  }

  public addTasksSuccess(task: Task, jobId: string): void {
    this.store.dispatch(jobsActions.addTasksSuccess({task, jobId}));
  }

  public updateIsAddingTasks(isAddingTasks: boolean): void {
    this.store.dispatch(jobsActions.updateIsAddingTasks({isAddingTasks}));
  }

  public updateJob(jobId: string, params: Partial<Job>): void {
    this.store.dispatch(jobsActions.updateJob({jobId, params}));
  }

  public updateIsUpdatingJob(isUpdatingJob: boolean): void {
    this.store.dispatch(jobsActions.updateIsUpdatingJob({isUpdatingJob}));
  }

  public openDeleteJob(
    jobId: string,
    name: string,
    jobStatus: JOB_STATUS,
    assetId: string,
    hasToRedirect: boolean
  ): void {
    this.store.dispatch(jobsActions.openDeleteJob({jobId, name, jobStatus, assetId, hasToRedirect}));
  }

  public deleteJobSuccess(jobId: string, jobStatus: JOB_STATUS): void {
    this.store.dispatch(jobsActions.deleteJobSuccess({jobId, jobStatus}));
  }

  public openRenameJob(jobId: string, name: string): void {
    this.store.dispatch(jobsActions.openRenameJob({jobId, name}));
  }

  public openDeleteTask(taskId: string, name: string): void {
    this.store.dispatch(jobsActions.openDeleteTask({taskId, name}));
  }

  public deleteTaskSuccess(taskId: string, jobId: string): void {
    this.store.dispatch(jobsActions.deleteTaskSuccess({taskId, jobId}));
  }

  public resetTaskNextToken(): void {
    this.store.dispatch(jobsActions.resetTaskNextToken());
  }

  public updateTask(taskId: string, jobId: string, params: Partial<Task>): void {
    this.store.dispatch(jobsActions.updateTask({taskId, jobId, params}));
  }

  public updateTaskSuccess(taskId: string, jobId: string, params: Partial<Task>): void {
    this.store.dispatch(jobsActions.updateTaskSuccess({taskId, jobId, params}));
  }

  public openMoreDetails(task: Task, jobName: string): void {
    this.store.dispatch(jobsActions.openMoreDetails({task, jobName}));
  }

  public clearTaskList(jobId: string): void {
    this.store.dispatch(jobsActions.clearTaskList({jobId}));
  }

  public createFlightPathTasks(payload: {jobId: string; flightPathTasks: any[]}): void {
    this.store.dispatch(jobsActions.createFlightPathTasks({payload}));
  }

  public selectJobMarkers(jobId: string) {
    return this.store.select(jobsSelectors.selectJobMarkers(jobId));
  }

  public loadJobMarkers(job: Job) {
    this.store.dispatch(jobsActions.loadJobMarkers({payload: {job}}));
  }

  public loadJobMarkersSuccess(jobId: string, jobMarkers: any) {
    this.store.dispatch(jobsActions.loadJobMarkersSuccess({payload: {jobId, jobMarkers}}));
  }

  public storeTeamMembers(): void {
    this.store.dispatch(jobsActions.storeTeamMembers());
  }

  public assignAllTasksToMe(jobId: string): void {
    this.store.dispatch(jobsActions.assignAllTasksToMe({jobId}));
  }
}

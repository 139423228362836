<form class="form" [formGroup]="form" (submit)="clickForgotPasswordButton()" autocomplete="off">
  <mat-form-field class="code" appearance="fill">
    <mat-label>{{ 'auth.forgot.verificationCode' | translate }}</mat-label>
    <input
      matInput
      formControlName="code"
      autofocus="true"
      autocomplete="off"
      type="tel"
      pattern="[0-9]*"
      data-test-id="codeInput"
    />
  </mat-form-field>
  <div class="code-messages">
    <mat-error
      *ngIf="
        form.controls['code'].hasError('required') &&
        (form.controls.code.touched || form.controls.code.dirty) &&
        !isCodeExpired
      "
      >{{ 'auth.forgot.verificationCode' | translate }} {{ 'common.errors.isRequired' | translate }}**</mat-error
    >
    <mat-error
      *ngIf="
        (form.controls['code'].hasError('minlength') || form.controls['code'].hasError('maxlength')) &&
        (form.controls.code.touched || form.controls.code.dirty) &&
        !isCodeExpired
      "
      >{{ 'auth.forgot.codeLength' | translate }}**</mat-error
    >
    <mat-error
      *ngIf="isCodeExpired && !codeGeneratedSuccessfully"
      class="code-messages__error"
      [class.disabled]="newCodeRequired"
    >
      {{ 'auth.password.codeExpired' | translate }}
      <button
        [class.disabled]="newCodeRequired"
        [disabled]="newCodeRequired"
        class="code-messages__send"
        (click)="emitResendCode()"
      >
        {{ 'auth.password.sendNewCode' | translate }}
      </button>
      <unleash-spinner *ngIf="newCodeRequired" diameter="18" borderWidth="2.1" color="#111"></unleash-spinner>
    </mat-error>
    <span *ngIf="codeGeneratedSuccessfully && !form.controls.code.dirty">{{
      'auth.password.newCodeSent' | translate
    }}</span>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>{{
      isUserInvitation ? ('common.password' | translate) : ('auth.forgot.newPassword' | translate)
    }}</mat-label>
    <input
      matInput
      [type]="hidePassword ? 'password' : 'text'"
      formControlName="password"
      data-test-id="newPasswordInput"
      autocomplete="off"
    />
    <button
      class="visibility"
      mat-icon-button
      matSuffix
      type="button"
      aria-label="Toggle password visibility"
      (click)="hidePassword = !hidePassword"
    >
      <ng-container *ngIf="hidePassword; else hideTemplate">
        <div class="visibility__item">
          <mat-icon color="primary">visibility</mat-icon>
          {{ 'common.show' | translate }}
        </div>
      </ng-container>
      <ng-template #hideTemplate>
        <div class="visibility__item">
          <mat-icon color="primary">visibility_off</mat-icon>
          {{ 'common.hide' | translate }}
        </div>
      </ng-template>
    </button>
  </mat-form-field>

  <section [ngClass]="{dirty: form.controls.password.dirty, valid: isPasswordValid}" class="form__password-validation">
    <span> {{ 'auth.password.mustInclude' | translate }}</span>
    <div class="form__validation-list">
      <span *ngFor="let validation of passwordValidations" class="form__validation-item">
        <mat-icon [class.invalid]="!validation.isValid" class="form__validation-icon" color="primary">{{
          validation.isValid ? 'check' : form.controls.password.dirty ? 'info' : 'info_outline'
        }}</mat-icon>
        {{ 'auth.password.' + validation.translationKey | translate }}</span
      >
    </div>
  </section>

  <unleash-checkbox
    *ngIf="isUserInvitation"
    class="form__terms"
    formControlName="terms"
    [onToggle]="cleanTermsAndConditionError"
  >
    <div [innerHTML]="'auth.signUp.terms' | translate"></div>
    <mat-error
      *ngIf="
        (form.controls.terms.invalid && form.controls.terms.touched) ||
        (form.controls.terms.dirty && form.controls.terms.invalid)
      "
    >
      {{ 'auth.signUp.termsError' | translate }}
    </mat-error>
  </unleash-checkbox>

  <button
    class="form__item submit-button"
    mat-raised-button
    color="primary"
    [appLadda]="inProgress"
    [disabled]="inProgress"
    data-test-id="updatePasswordButton"
  >
    {{ isUserInvitation ? ('auth.forgot.acceptInvite' | translate) : ('auth.forgot.updatePassword' | translate) }}
  </button>
</form>

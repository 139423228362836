import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LibraryNavigationService {
  constructor(private router: Router) {}

  public goToReport(libraryItemId: string): void {
    this.router.navigate([`secure/library/${libraryItemId}/summary`]);
  }

  public goToLibraryItem(libraryItemId: string): void {
    this.router.navigate([`secure/library/${libraryItemId}`]);
  }

  public goToLibraryRoot(): void {
    this.router.navigate([`secure/library`]);
  }
}

import {Injectable, NgZone} from '@angular/core';
import {CompanyModel, CompanyRegisterResponse, UserCompanyModel} from '@app/core/models/api/company-model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserModel} from '@app/core/models/api/user-model';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {PaymentPeriod} from '@app/shared/stripe-elements/payment.model';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  EMPTY,
  filter,
  from,
  map,
  Observable,
  of,
  share,
  switchMap,
  take,
  tap,
  throwError,
  withLatestFrom,
  zip
} from 'rxjs';
import * as actions from './user.actions';
import {DeviceService} from '@app/core/services/api/device.service';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {AclService} from '@app/core/services/acl.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {UsersManagementStoreFacadeService} from '@app/profile/services/users-management-store-facade.service';
import {Team} from '@app/profile/models/team.model';
import {UserTeamsApiService} from '@app/profile/services/user-teams-api.service';
import {AuthService} from '@app/auth/services/auth.service';
import {LibraryAwsAppAsyncClientService} from '@app/library/services/library-aws-appsync-client.service';
import {NotificationsService} from '@app/core/services/appsync-notifications/notifications.service';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {Router} from '@angular/router';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {getCognitoIdentityValue} from '@app/core/services/api/cognito-identity-value';
import {actionYourProfileError, actionYourProfileSuccess} from '@app/auth/components/your-profile/your-profile.actions';
import {Store} from '@ngrx/store';
import {PlansService} from '@app/plans/services/plans.service';
import {authSignedIn} from '@app/auth/state/auth.actions';
import {MatDialog} from '@angular/material/dialog';
import {CalibrationConfirmDeleteDialogComponent} from '@app/shared/calibration/components/calibration-confirm-delete-dialog/calibration-confirm-delete-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {CalibrationService} from '@app/shared/calibration/services/calibration.service';
import {CalibrationConfirmMarkerDeleteDialogComponent} from '@app/shared/calibration/components/calibration-confirm-marker-delete-dialog/calibration-confirm-marker-delete-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CalibrationType} from '@app/shared/calibration/calibration.component';
import {Auth} from '@aws-amplify/auth';
import {VerifyMobileNumberDialogComponent} from '@app/profile/components/verify-mobile-number-dialog/verify-mobile-number-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class UserEffects {
  public setDeviceUsage$: Observable<[UserModel, UserDeviceJoined[]]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setDeviceUsage),
        switchMap(() =>
          zip(
            this.userStoreFacadeService.currentUser$.pipe(take(1)),
            this.userStoreFacadeService.currentUserDevices$.pipe(take(1))
          )
        ),
        tap(([user, devices]: [UserModel, UserDeviceJoined[]]) => {
          if (!user.currentUsage) {
            user.currentUsage = {};
          }
          const userCopy = {...user};
          userCopy.currentUsage = {...user.currentUsage, devices: devices.length};
          this.userStoreFacadeService.userStoreData({...userCopy});
        })
      ),
    {dispatch: false}
  );

  public updateDeviceCache$: Observable<[UserDeviceJoined, {[key: string]: UserDeviceJoined}]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateDeviceCache),
        map((action: {device: Partial<UserDeviceJoined>}) => action.device),
        withLatestFrom(this.userStoreFacadeService.currentUserDevicesObject$),
        tap(([device, userDevices]: [UserDeviceJoined, {[key: string]: UserDeviceJoined}]) => {
          const deviceInStore = userDevices[device.id];
          if (deviceInStore) {
            const deviceCopy = {...deviceInStore, ...device};
            deviceCopy['updatedAt'] = new Date().getTime();
            this.userStoreFacadeService.userStoreDeviceData(deviceCopy);
          }
        })
      ),
    {dispatch: false}
  );

  public removeDeviceFromUser$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.removeDeviceFromUser),
        withLatestFrom(this.userStoreFacadeService.currentUserDevices$),
        filter(([actions, userDevices]: [{deviceId: string; userId: string}, UserDeviceJoined[]]) => {
          return userDevices.length > 1;
        }),
        switchMap(([actions, userDevices]: [{deviceId: string; userId: string}, UserDeviceJoined[]]) => {
          return zip(
            this.apiGateway
              .delete(`user/${encodeURIComponent(actions.userId)}/device`, {}, {deviceId: actions.deviceId})
              .pipe(share()),
            of(userDevices),
            of(actions.deviceId)
          );
        }),
        tap(([data, userDevices, deviceId]: [unknown, UserDeviceJoined[], string]) => {
          const deviceInd = userDevices.findIndex((d: UserDeviceJoined) => d.id === deviceId);
          if (deviceInd > -1) {
            this.userStoreFacadeService.userStoreRemoveDeviceData(deviceId);
            this.userStoreFacadeService.setDeviceUsage();
          }
        }),
        catchError(error => {
          console.error('error removing device', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public removeDeviceFromCache$: Observable<[string, UserDeviceJoined[]]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.removeDeviceFromCache),
        map((actions: {deviceId: string}) => actions.deviceId),
        withLatestFrom(this.userStoreFacadeService.currentUserDevices$),
        tap(([deviceId, userDevices]: [string, UserDeviceJoined[]]) => {
          const deviceInd = userDevices.findIndex((d: UserDeviceJoined) => d.id === deviceId);
          if (deviceInd > -1) {
            this.userStoreFacadeService.userStoreRemoveDeviceData(deviceId);
            this.userStoreFacadeService.setDeviceUsage();
          }
        })
      ),
    {dispatch: false}
  );

  public findAllUserDevices$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.findAllUserDevices),
        switchMap((actions: {identityId: string; fetchUserDevicesCount: number; FETCH_DEVICES_MAX_RETRY: number}) =>
          zip(this.deviceService.listTeamDevices(), of(actions))
        ),
        tap(
          ([userDevices, {identityId, fetchUserDevicesCount, FETCH_DEVICES_MAX_RETRY}]: [
            UserDeviceJoined[],
            {identityId: string; fetchUserDevicesCount: number; FETCH_DEVICES_MAX_RETRY: number}
          ]) => {
            if (!!userDevices && userDevices.length === 0 && fetchUserDevicesCount++ <= FETCH_DEVICES_MAX_RETRY) {
              const DELAY_RETRY_FETCH_DEVICES = 3000;
              setTimeout(
                () =>
                  this.userStoreFacadeService.findAllUserDevices(
                    identityId,
                    fetchUserDevicesCount,
                    FETCH_DEVICES_MAX_RETRY
                  ),
                DELAY_RETRY_FETCH_DEVICES
              );
            }
            const devicesFormated: {[key: string]: UserDeviceJoined} = {};
            userDevices.forEach((device: UserDeviceJoined) => {
              devicesFormated[device.id] = {...device};
            });
            this.userStoreFacadeService.saveSetOfDevices(devicesFormated);
            this.userStoreFacadeService.setDeviceUsage();
          }
        ),
        catchError(error => {
          console.error('error finding devices', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public getCompanyDataIfUserIsAdmin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.getCompanyDataIfUserIsAdmin),
        switchMap(() =>
          this.aclService.hasSetupPermission$.pipe(
            filter(hasPermission => !!hasPermission),
            take(1)
          )
        ),
        filter(() => this.aclService.hasPermission(AclPermissions.OrganizationApiGetAdminOrganizationData)),
        switchMap(() =>
          zip(
            this.apiGateway.get(`organization`, {}, undefined).pipe(share()),
            this.userStoreFacadeService.currentTeamId$.pipe(filter(teamId => !!teamId))
          )
        ),
        tap(([data, teamId]) => {
          this.addonStoreFacadeService.loadListSuccess(data.company.aiAppsPartial);
          this.userStoreFacadeService.userStoreCompanies([data.company]);
          this.userStoreFacadeService.userStoreCompany({...data.company});

          let users = data.users;

          const teamsCounter = {};

          for (const user of users) {
            teamsCounter[user.teamId] = teamsCounter[user.teamId] + 1 || 1;
          }

          // get current user role or the first role for each user
          users = users.map(user => {
            let role = '';
            if (Object.values(user.roles).length > 0) {
              if (teamId in data.roles) {
                role = data.roles[teamId][0];
              } else {
                role = Object.values(user.roles)[0] as string;
              }
            }
            return {...user, role};
          });

          const teams = data.teams.map(team => ({...team, members: teamsCounter[team.id] || 0}));
          this.usersManagementStoreFacadeService.setRoles(data.roles);
          this.teamsManagementStoreFacadeService.setTeams(teams);
          this.usersManagementStoreFacadeService.setUsers(users);
        })
      ),
    {dispatch: false}
  );

  public getUserRolesByCompany$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.getUserRolesByCompany),
        switchMap(action =>
          zip(
            this.apiGateway.get(`organization/user`, {}, undefined).pipe(share()),
            this.userStoreFacadeService.currentTeamId$.pipe(filter(teamId => !!teamId))
          )
        ),
        tap(([data, teamId]: any) => {
          let currentRoles = [];
          if (Object.values(data.roles).length > 0) {
            if (teamId in data.roles) {
              currentRoles = data.roles[teamId];
            } else {
              const role = Object.values(data.roles)[0];
              currentRoles = [role];
            }
          }
          this.userStoreFacadeService.setUserStoreRole(currentRoles);
        }),
        catchError(e => {
          if (e.response.status === 404 || e.response.status === 403) {
            return this.router.navigate(['/auth/team-selector/']);
          } else {
            return throwError(() => e);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public getTeam$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.getTeam),
        switchMap(() =>
          zip(
            this.userStoreFacadeService.currentUser$.pipe(
              map(user => user.activeTeamAndCompany.teamId),
              take(1)
            ),
            this.apiGateway.get(`organization/team`, {}, undefined)
          )
        ),
        switchMap(([currentTeamId, team]) => {
          if (currentTeamId === team.id) {
            this.teamsManagementStoreFacadeService.setTeams([team]);
            return EMPTY;
          }
          return from(this.authService.refreshCredentials(true)).pipe(
            take(1),
            switchMap(() => this.apiGateway.get(`organization/team`, {}, undefined)),
            tap(team => this.teamsManagementStoreFacadeService.setTeams([team]))
          );
        }),
        catchError(error => {
          console.error('error to ge team', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public getCompanyIfUserIsNotAdmin$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.getCompanyIfUserIsNotAdmin),
        switchMap(() =>
          zip(
            this.apiGateway.get(`organization/company`, {}, undefined).pipe(share()),
            this.aclService.hasSetupPermission$.pipe(
              filter(hasPermission => !!hasPermission),
              take(1)
            )
          )
        ),
        tap(([data]: [CompanyModel, boolean]) => {
          if (!data) {
            console.info('User has no company assigned');
          }
        }),
        tap(([company]: [CompanyModel, boolean]) => {
          this.addonStoreFacadeService.loadListSuccess(company.aiAppsPartial);
          this.userStoreFacadeService.userStoreCompanies([company]);
          this.userStoreFacadeService.userStoreCompany({...company});
        }),
        catchError(error => {
          console.error('error finding companies', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public updateUser$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateUser),
        switchMap((actions: {user: Partial<UserModel>; identityId: string}) =>
          zip(
            this.apiGateway
              .patch(
                `user/${encodeURIComponent(actions.identityId)}`,
                {},
                {currentPlan: actions.user.currentPlan, ...actions.user}
              )
              .pipe(share()),
            of(actions.user)
          )
        ),
        tap(([data, user]: [UserModel, UserModel]) => {
          this.userStoreFacadeService.userStoreData({...user, ...data});
        }),
        catchError(error => {
          console.error('error updating user', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public updatePlanInUserDataStore$: Observable<[UserModel, PaymentPeriod, string]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updatePlanInUserDataStore),
        switchMap((actions: {planId: string; period: PaymentPeriod}) =>
          zip(this.userStoreFacadeService.currentUser$, of(actions.period), of(actions.planId))
        ),
        tap(([user, period, planId]: [UserModel, PaymentPeriod, string]) => {
          const currentUser = {...user};
          currentUser.currentPlan = planId;
          currentUser.subscriptionPeriod = period;
          this.userStoreFacadeService.userStoreData({...currentUser});
        })
      ),
    {dispatch: false}
  );

  public loadUserTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.loadUserTeams),
        switchMap(() => this.apiGateway.get('organization/switch')),
        map(response => {
          return response.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.teamId]: {
                id: curr.teamId,
                name: curr.teamName,
                role: curr.roles[0],
                companyId: curr.companyId,
                companyName: curr.companyName
              }
            }),
            {}
          );
        }),
        tap((teams: {[key: string]: Team}) => {
          this.userStoreFacadeService.loadUserTeamsSuccess(teams);
        })
      ),
    {
      dispatch: false
    }
  );

  public switchCurrentTeam$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.switchCurrentTeam),
        switchMap(({payload}) => {
          const accessToken = getCognitoIdentityValue('accessToken');
          const refreshToken = getCognitoIdentityValue('refreshToken');
          return this.apiGateway.post('organization/switch', null, {
            teamId: payload.teamId,
            accessToken,
            refreshToken
          });
        }),
        tap(async response => {
          const newTeamId = response.updatedUser.activeTeamAndCompany.teamId;
          const newCompanyId = response.updatedUser.activeTeamAndCompany.companyId;
          this.localStorageService.updateCognitoCredentials(
            newTeamId,
            response.credentials.accessToken,
            response.credentials.idToken
          );
          this.libraryAwsAppAsyncClientService.generateAppSyncClient();
          this.notificationsService.generateNotificationsAwsAppsyncClient();
          this.userStoreFacadeService.switchCurrentTeamSuccess(newCompanyId, newTeamId);
          this.localStorageService.setItem('team-switched', true);
          if (this.router.url === '/auth/team-selector') {
            await this.router.navigate(['/secure/profile']);
          }

          window.location.reload();
        })
      ),
    {
      dispatch: false
    }
  );

  public registerCompany$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.registerCompany),
        switchMap(payload => {
          const accessToken = getCognitoIdentityValue('accessToken');
          const refreshToken = getCognitoIdentityValue('refreshToken');
          const idToken = getCognitoIdentityValue('idToken');
          return this.apiGateway
            .post('organization/register/company', {}, {accessToken, refreshToken, idToken, company: payload.company})
            .pipe(share());
        }),
        tap((response: CompanyRegisterResponse) => {
          const teamId = response.team.id;
          // if (userData.developer) {
          //   this.store.dispatch(actionYourDeveloperProfileSuccess());
          //   return;
          // }
          // this.hasMarketPlaceToken
          //   ? this.store.dispatch(actionWelcomeAwsTokenSuccess())
          //   : this.store.dispatch(actionYourProfileSuccess());
          this.userStoreFacadeService.userStoreCompanies([response.company]);
          this.userStoreFacadeService.userStoreCompany({...response.company});
          this.userStoreFacadeService.userStoreData({...response.user});
          // TODO: Sir Luis to double check -> This part was taken from switch team response. Probably we can wrap that over onSwitchTeamSuccess (or not)
          this.localStorageService.updateCognitoCredentials(
            teamId,
            response.newCredentials.credentials.IdToken,
            response.newCredentials.credentials.AccessToke
          );
          this.libraryAwsAppAsyncClientService.generateAppSyncClient();
          this.notificationsService.generateNotificationsAwsAppsyncClient();
          this.authService.setupIdentityIdAfterSignIn();
          this.store.dispatch(authSignedIn({payload: {userId: response.user.id}}));
          const companyPhone = response.company.phone;
          if (companyPhone) {
            this.authService.updateUser({phone: companyPhone});
          }
        }),
        switchMap(() => {
          return this.plansService.getPlans();
        }),
        tap(() => {
          this.store.dispatch(actionYourProfileSuccess());
        }),
        catchError(e => {
          this.store.dispatch(actionYourProfileError({error: e}));
          return of(e);
        })
      ),
    {dispatch: false}
  );
  public createCompany$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.createCompany),
        tap(() => {
          console.debug('deprecated');
        }),
        // switchMap((actions: {company: Partial<CompanyModel>}) =>
        //   // this.apiGateway.post('company', {}, actions.company).pipe(share())
        // ),
        // tap((data: CompanyModel) => {
        //   this.userStoreFacadeService.userStoreCompanies([data]);
        //   this.userStoreFacadeService.userStoreCompany({...data});
        // }),
        catchError(error => {
          console.error('error creating company', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public updateCompany$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.updateCompany),
        switchMap((actions: {companyId: string; company: Partial<CompanyModel>}) =>
          zip(
            this.apiGateway.patch(`organization/company/${actions.companyId}`, {}, actions.company).pipe(share()),
            of(actions.companyId)
          )
        ),
        filter(([data, companyId]: [CompanyModel, string]) => !!data),
        switchMap(([data, companyId]: [CompanyModel, string]) =>
          zip(
            of(data),
            of(companyId),
            this.userStoreFacadeService.companies$.pipe(take(1)),
            this.userStoreFacadeService.currentUserCompany$.pipe(take(1))
          )
        ),
        tap(([data, companyId, companies, myCompany]: [CompanyModel, string, CompanyModel[], CompanyModel]) => {
          if (!data.id) {
            data.id = companyId;
          }
          const index = companies.findIndex((it: CompanyModel) => it.id === companyId);
          this.userStoreFacadeService.userStoreCompanies([{...companies[index], ...data}]);
          this.userStoreFacadeService.userStoreCompany({...myCompany, ...data});
        }),
        catchError(error => {
          console.error('error updating company', error);
          return error;
        })
      ),
    {dispatch: false}
  );

  public setupUserDataModel$: Observable<UserModel> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setupUserDataModel),
        switchMap(() => this.userStoreFacadeService.currentUser$.pipe(take(1))),
        tap((user: UserModel) => {
          this.userStoreFacadeService.userStoreData({...user});
        })
      ),
    {dispatch: false}
  );

  public goToManageTeams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.goToManageTeams),
        tap(() => this.router.navigate(['secure/profile'], {fragment: 'teams'}))
      ),
    {dispatch: false}
  );

  public deleteSceneMapping$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteSceneMapping),
        switchMap(({payload}) => {
          return this.dialog
            .open(CalibrationConfirmDeleteDialogComponent, {
              ...STANDARD_DIALOG_CONFIG,
              autoFocus: false,
              width: '80vw',
              maxWidth: '800px',
              data: payload
            })
            .afterClosed()
            .pipe(
              tap((response: {hasBeenDeleted: boolean; calibrationType: CalibrationType}) => {
                if (response.hasBeenDeleted) {
                  this.calibrationService.clearAllReferences();
                  this.calibrationService.setCalibrationLabel(false);
                  const title =
                    response.calibrationType === CalibrationType.perspective ? 'Perspective' : 'Scene mapping';
                  this.snackbar.open(`${title} deleted`, null, {
                    duration: 3000,
                    panelClass: 'confirm-scene-mapping-snackbar__marker-delete'
                  });
                }
              })
            );
        })
      ),
    {dispatch: false}
  );

  public confirmDeleteMarkerByIndex$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.confirmDeleteMarkerByIndex),
        switchMap(({payload}) =>
          this.zone.run(() => {
            return this.dialog
              .open(CalibrationConfirmMarkerDeleteDialogComponent, {
                ...STANDARD_DIALOG_CONFIG,
                autoFocus: false,
                width: '80vw',
                maxWidth: '800px',
                data: {
                  index: payload.index
                }
              })
              .afterClosed();
          })
        ),
        filter((payload: {hasBeenDeleted: boolean; index: number}) => !!payload?.hasBeenDeleted),
        tap((payload: {hasBeenDeleted: boolean; index: number}) => {
          const index = payload.index;
          this.calibrationService.deletePointLatLngByIndex(index);
          this.calibrationService.emitDeletePointByIndex(index);
          this.calibrationService.removePointOnScreen(index);
          this.snackbar.open('Marker deleted', null, {
            duration: 3000,
            panelClass: 'confirm-scene-mapping-snackbar__marker-delete'
          });
        })
      ),

    {dispatch: false}
  );

  public actionSetupMobilePhone = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setupMobilePhone),
        switchMap(action => zip(of(action), from(Auth.currentAuthenticatedUser()))),
        switchMap(([{phoneNumber}, cognitoUser]) => {
          this.userStoreFacadeService.setIsUpdatingCognitoUser(true);
          this.authService.updateUser({
            phone: phoneNumber
          });
          return from(
            Auth.updateUserAttributes(cognitoUser, {
              phone_number: phoneNumber
            })
          ).pipe(
            tap(() => {
              this.userStoreFacadeService.verifyMobileNumber(phoneNumber);
            })
          );
        }),
        catchError(() => {
          this.userStoreFacadeService.setIsUpdatingCognitoUser(false);
          return this.translate.get('profile.phone.phoneNotRegistered').pipe(
            tap(translation => {
              this.snackbar.open(translation, null, {
                panelClass: 'center',
                duration: 3000
              });
            })
          );
        })
      ),
    {dispatch: false}
  );

  public verifyMobileNumber = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.verifyMobileNumber),
        switchMap(({phoneNumber}) => {
          return from(Auth.verifyCurrentUserAttribute('phone_number')).pipe(
            tap(() => {
              this.userStoreFacadeService.setIsUpdatingCognitoUser(false);
              this.authService.updateCognitoUserFinished();
              this.dialog.open(VerifyMobileNumberDialogComponent, {
                ...STANDARD_DIALOG_CONFIG,
                data: {phoneNumber},
                disableClose: true,
                width: '50vw',
                minWidth: '200px',
                maxWidth: '800px'
              });
            }),
            catchError(() => {
              this.userStoreFacadeService.setIsUpdatingCognitoUser(false);
              return this.translate.get('profile.phone.phoneNotConfirmed').pipe(
                tap(translation => {
                  this.snackbar.open(translation, null, {
                    panelClass: 'center',
                    duration: 3000
                  });
                })
              );
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private userStoreFacadeService: UserStoreFacadeService,
    private deviceService: DeviceService,
    private apiGateway: ApiGateway,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private aclService: AclService,
    private usersManagementStoreFacadeService: UsersManagementStoreFacadeService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService,
    private authService: AuthService,
    private libraryAwsAppAsyncClientService: LibraryAwsAppAsyncClientService,
    private notificationsService: NotificationsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store,
    private plansService: PlansService,
    private dialog: MatDialog,
    private calibrationService: CalibrationService,
    private snackbar: MatSnackBar,
    private zone: NgZone,
    private translate: TranslateService
  ) {}
}
<section>
  <div class="title">
    <h2 mat-dialog-title class="title__text">{{ 'jobs.exportTaskComponent.title' | translate }}</h2>
  </div>
  <form [formGroup]="form">
    <mat-radio-group color="accent" formControlName="format" class="list">
      <mat-label class="header-text">{{ 'jobs.exportTaskComponent.format.label' | translate }}</mat-label>
      <mat-radio-button [value]="EXPORT_FORMAT.geojson">{{
        'jobs.exportTaskComponent.format.geojson' | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="EXPORT_FORMAT.kml">{{
        'jobs.exportTaskComponent.format.kml' | translate
      }}</mat-radio-button>
      <mat-radio-button [value]="EXPORT_FORMAT.kmz">{{
        'jobs.exportTaskComponent.format.kmz' | translate
      }}</mat-radio-button>
    </mat-radio-group>

    <div formGroupName="content" class="list">
      <mat-label class="header-text">{{ 'jobs.exportTaskComponent.content.label' | translate }}</mat-label>
      <mat-checkbox color="accent" formControlName="lines">{{
        'jobs.exportTaskComponent.content.options.lines' | translate
      }}</mat-checkbox>
      <mat-checkbox color="accent" formControlName="markers">{{
        'jobs.exportTaskComponent.content.options.markers' | translate
      }}</mat-checkbox>
      <mat-checkbox color="accent" formControlName="polygons">{{
        'jobs.exportTaskComponent.content.options.polygons' | translate
      }}</mat-checkbox>
    </div>
  </form>
</section>

<div class="actions" mat-dialog-actions>
  <button mat-button mat-dialog-close color="primary" class="export-task__cancel-action">
    {{ 'jobs.exportTaskComponent.actions.cancel' | translate }}
  </button>
  <button
    [disabled]="
      !form.get('format').value ||
      (!form.get('content').get('lines').value &&
        !form.get('content').get('markers').value &&
        !form.get('content').get('polygons').value)
    "
    mat-flat-button
    class="export-task__action"
    color="primary"
    (click)="onSubmit(form)"
  >
    {{ 'jobs.exportTaskComponent.actions.export' | translate }}
  </button>
</div>

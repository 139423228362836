import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {NotificationService} from '@app/core/services/notifications/notification.service';
import {Auth} from '@aws-amplify/auth';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {actionSignInShowSignOutAlert} from '../components/sign-in/sign-in.actions';
import {AUTH_KEY, AWS_KEY, REGISTRATION_STAGE} from '../models/auth.models';
import {AuthService} from '../services/auth.service';
import {
  authIoTInitRequired,
  authLogin,
  authLogout,
  authPlanExpired,
  authPlanPaid,
  authPlanSelectionRequired,
  authRegistrationFinished,
  authSignedIn,
  authSignedOut,
  authSignIn,
  authPostRegistrationSignIn,
  authTokensRefreshed
} from './auth.actions';
import {RegistrationStage} from './auth.state';
import {AclStoreFacadeService} from '@app/core/services/acl-store-facade.service';
import {PlansService} from '@app/plans/services/plans.service';
import {UserService} from '@app/core/services/api/user.service';
import {EMPTY} from 'rxjs';

@Injectable()
export class AuthEffects {
  public login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogin),
        tap(action => {
          this.router.navigate(['/secure']);
          // clear search in library2
          // this.searchService.clearFilterProperties();
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true
          });
        })
      ),
    {dispatch: false}
  );
  public logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        switchMap(({isDeveloperMode}) => {
          if (!this.authService.canLogOutOnSetMobilePhone) {
            this.router.navigate(['auth/sign-in']);
            return EMPTY;
          }
          this.localStorageService.setItem(AUTH_KEY, {isAuthenticated: false});
          this.localStorageService.removeItem(REGISTRATION_STAGE);
          return this.authService.signOut(isDeveloperMode).pipe(map(() => actionSignInShowSignOutAlert()));
        })
      ),
    {dispatch: true}
  );

  public signIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSignIn),
        tap(action => Auth.signIn(action.form.email.toLowerCase(), action.form.password))
      ),
    {dispatch: false}
  );
  public authPostRegistrationSignIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authPostRegistrationSignIn),
        switchMap(action => Auth.signIn(action.form.email.toLowerCase(), action.form.password)),
        tap(user => {
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: true,
            userId: undefined
          });
          this.router.navigate(['auth/your-profile']);
        })
      ),
    {dispatch: false}
  );

  public signedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSignedIn),
      tap(action => {
        this.userService.findAllUserDevices();
        this.plansService.getPlans();
        this.aclStoreFacadeService.requestAclConfig();
        this.userService.getCompanyForNonAdmin();
        this.userService.getUserRolesByCompany();
        this.userService.loadUserTeams();

        this.unleashAnalyticsService.initAnalytics();
        this.localStorageService.setItem(AUTH_KEY, {
          isAuthenticated: true,
          userId: action.payload.userId
        });
      }),
      map(action => authIoTInitRequired())
    )
  );

  public signedOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSignedOut),
        tap(action => {
          this.unleashAnalyticsService.resetAnalytics();
          this.localStorageService.removeItem(AWS_KEY);
          this.localStorageService.removeItem(AUTH_KEY);
          this.authService.offAuthEvents();
          this.atlasService.clearAtlasLocalStorage();
          location.reload();
        })
      ),
    {dispatch: false}
  );

  public refreshed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authTokensRefreshed),
      map(action => authIoTInitRequired())
    )
  );

  public planExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authPlanExpired),
        tap(action => {
          this.router.navigate(['auth/reason']);
          this.notificationService.warn(
            'Your trial run out and payment was declined. Go through the plan selection process'
          );
          this.unleashAnalyticsService.logEvent(EVENTS.AUTH_PLAN_EXPIRED);
        })
      ),
    {dispatch: false}
  );

  public planSelectionRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authPlanSelectionRequired),
        tap(({isDeveloperMode}) => {
          switch (this.localStorageService.getItem(REGISTRATION_STAGE)) {
            case RegistrationStage.developerDetails:
              this.router.navigate(['auth/developer-profile']);
              return;
            case RegistrationStage.details:
              this.router.navigate(['auth/your-profile']);
              return;
            case RegistrationStage.confirmation:
              this.router.navigate(['auth/confirm-registration']);
              return;
            case RegistrationStage.developerPayment:
              this.router.navigate(['/auth/developer-payment']);
              return;
            case RegistrationStage.payment:
              this.router.navigate(['/auth/reason']);
              return;
            case RegistrationStage.done:
              this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.payment);
              return;
            default:
              // eslint-disable-next-line no-case-declarations
              const url = isDeveloperMode ? 'auth/developer-payment' : 'auth/reason';
              this.router.navigate([url]);
              // this.notificationService.warn('Please complete sign up to begin your trial');
              break;
          }
        })
      ),
    {dispatch: false}
  );

  public registrationFinished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authRegistrationFinished),
        switchMap(action => {
          this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.done);
          return this.router.navigate(['/secure/dashboard']);
        })
      ),
    {dispatch: false}
  );

  public planPaid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authPlanPaid),
        tap(action => {
          // this.localStorageService.setItem(REGISTRATION_STAGE, RegistrationStage.done);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private notificationService: NotificationService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private authService: AuthService,
    private atlasService: AtlasService,
    private userService: UserService,
    private plansService: PlansService,
    private aclStoreFacadeService: AclStoreFacadeService
  ) {}
}

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {Task} from '@app/jobs/models/jobs.models';
import {JobTasksService} from '@app/jobs/services/job-tasks.service';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {take, tap, catchError, EMPTY, switchMap, Observable, of, map} from 'rxjs';

@Component({
  selector: 'unleash-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteDialog {
  public isLoading: boolean = false;
  public deleteJobsOptions: typeof DELETE_JOBS_OPTIONS = DELETE_JOBS_OPTIONS;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      deleteOption: DELETE_JOBS_OPTIONS;
      id: string;
      name: string;
      assetId: string;
    },
    public jobsApiService: JobsApiService,
    public atlasService: AtlasService,
    public jobTasksService: JobTasksService
  ) {}

  public deleteAction(): void {
    switch (this.data.deleteOption) {
      case DELETE_JOBS_OPTIONS.JOB:
        this.deleteJob();
        break;
      case DELETE_JOBS_OPTIONS.TASK:
        this.deleteTask();
        break;
    }
  }

  public deleteJob() {
    this.isLoading = true;
    this.jobsApiService
      .deleteJob(this.data.id)
      .pipe(
        take(1),
        switchMap(response => {
          if (this.data.assetId) {
            const removeAssetParams = {
              assetId: this.data.assetId,
              avoidUpdateCache: false
            };
            return this.removeJobAsset(removeAssetParams, response);
          }
          this.closeDialogWithResponse(response);
          return EMPTY;
        }),
        catchError(error => {
          this.dialogRef.close({error});
          return EMPTY;
        })
      )
      .subscribe();
  }

  private removeJobAsset(removeAssetParams, response) {
    const assetLayer = this.atlasService.getAssetById(removeAssetParams.assetId)?.leafletLayer;
    return this.atlasService.removeAsset(removeAssetParams).pipe(
      tap(() => {
        assetLayer?.remove();
        this.closeDialogWithResponse(response);
      }),
      catchError(() => {
        this.closeDialogWithResponse(response);
        return EMPTY;
      })
    );
  }

  private closeDialogWithResponse(response) {
    this.dialogRef.close({jobId: this.data.id, data: response});
  }

  public deleteTask() {
    this.isLoading = true;
    this.jobsApiService
      .deleteTask(this.data.id)
      .pipe(
        take(1),
        switchMap((task: Task) => {
          this.jobTasksService.removeTask(task.id);
          const assetId = task?.context?.asset?.id;
          const assetLayer = task?.context?.asset?.leafletLayer;
          return assetId
            ? this.deleteLayer(assetId).pipe(
                map(() => {
                  assetLayer?.remove();
                  return task;
                }),
                catchError(() => {
                  this.dialogRef.close({removeAtlasAssetError: true, data: task});
                  return EMPTY;
                })
              )
            : of(task);
        }),
        tap(response => {
          this.dialogRef.close({data: response});
        }),
        catchError(() => {
          this.dialogRef.close({error: true});
          return EMPTY;
        })
      )
      .subscribe();
  }

  // eslint-disable-next-line rxjs/finnish
  private deleteLayer(assetId: string): Observable<Task> {
    const removeAssetParams = {
      assetId,
      avoidUpdateCache: false
    };
    return this.atlasService.removeAsset(removeAssetParams);
  }
}

export enum DELETE_JOBS_OPTIONS {
  JOB,
  TASK
}

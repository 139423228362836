import {select, Store} from '@ngrx/store';
import {Injectable} from '@angular/core';
import {
  batchCreateSchedule,
  batchCreateScheduleSuccess,
  loadSchedules,
  batchUpdateSchedule,
  batchUpdateScheduleSuccess,
  batchDeleteSchedule,
  updateIsLoadingSchedules,
  batchDeleteScheduleSuccess,
  loadSingleSchedule,
  updateIsExecutingAction
} from '@app/live/store/schedules/live-schedule.actions';
import {
  selectIsExecutingAction,
  selectIsLoadingSchedules,
  selectScheduleById,
  selectSchedules
} from '@app/live/store/schedules/live-schedule.selectors';
import {Observable} from 'rxjs';
import {BatchCreateSchedule, BatchUpdateSchedule, ScheduleModel} from '@app/core/models/api/schedule.model';

@Injectable({
  providedIn: 'root'
})
export class StreamScheduleFacadeService {
  constructor(private store: Store) {}

  public selectSchedules$: Observable<ScheduleModel[]> = this.store.pipe(select(selectSchedules));
  public isLoadingSchedules$: Observable<boolean> = this.store.pipe(select(selectIsLoadingSchedules));
  public isExecutingAction$: Observable<boolean> = this.store.pipe(select(selectIsExecutingAction));

  // eslint-disable-next-line rxjs/finnish
  public selectScheduleById(scheduleId: string): Observable<ScheduleModel> {
    return this.store.pipe(select(selectScheduleById(scheduleId)));
  }

  public loadSchedules(): void {
    this.store.dispatch(loadSchedules());
  }

  public batchCreateSchedule(payload: BatchCreateSchedule): void {
    this.store.dispatch(batchCreateSchedule({payload}));
  }

  public createScheduleSuccess(schedule: ScheduleModel): void {
    return this.store.dispatch(batchCreateScheduleSuccess(schedule));
  }

  public updateSchedule(scheduleId: string, payload: BatchUpdateSchedule): void {
    this.store.dispatch(batchUpdateSchedule({scheduleId, payload}));
  }

  public updateScheduleSuccess(schedule: ScheduleModel): void {
    this.store.dispatch(batchUpdateScheduleSuccess(schedule));
  }

  public deleteSchedules(scheduleName: string, scheduleId: string): void {
    this.store.dispatch(batchDeleteSchedule({scheduleName, scheduleId}));
  }

  public deleteSchedulesSuccess(schedule: ScheduleModel): void {
    this.store.dispatch(batchDeleteScheduleSuccess(schedule));
  }

  public updateIsLoadingSchedules(isLoadingSchedules: boolean): void {
    this.store.dispatch(updateIsLoadingSchedules({isLoadingSchedules}));
  }

  public loadSingleSchedule(scheduleId: string): void {
    this.store.dispatch(loadSingleSchedule({scheduleId: scheduleId}));
  }

  public updateIsExecutingAction(isExecutingAction: boolean): void {
    this.store.dispatch(updateIsExecutingAction({isExecutingAction}));
  }
}

export enum EXPORT_FORMAT {
  geojson = 'geojson',
  kml = 'kml',
  kmz = 'kmz'
}

export interface ExportTaskDialogForm {
  format: EXPORT_FORMAT,
  content: {
    lines: boolean
    markers: boolean
    polygons: boolean
  }
}

export interface ExportTaskPayload extends ExportTaskDialogForm{
  taskIds: string[],
}

<div class="scheduler">
  <unleash-static-header
    (goBack)="goBack()"
    [title]="headerState?.title"
    [items]="headerState?.items"
  ></unleash-static-header>
  <mat-progress-bar *ngIf="isExecutingAction$ | async" mode="indeterminate"></mat-progress-bar>
  <ng-container *ngIf="scheduleAttributes$ | async as scheduleAttributes">
    <section *ngIf="isAdmin$ | async" class="scheduler-actions">
      <button
        (click)="deleteSchedule(scheduleAttributes?.name, scheduleAttributes?.id)"
        class="scheduler-actions__button"
        mat-button
      >
        <mat-icon class="scheduler-actions__icon">delete</mat-icon>
        <span class="scheduler-actions__text"> {{ 'live.scheduler.delete' | translate }}</span>
      </button>
    </section>
    <div class="scheduler__new">
      <unleash-new-schedule
        *ngIf="userStreamKey$ | async as streamKey"
        [devices]="devices$ | async"
        [addons]="addons$ | async"
        [scheduleAttributes]="scheduleAttributes"
        [isEditMode]="true"
        [isAdmin]="isAdmin$ | async"
        [currentUserDevicesObject]="currentUserDevicesObject$ | async"
        [isExecutingAction]="isExecutingAction$ | async"
        (editSchedule)="editSchedule($event, streamKey)"
        (goToPrincipalPage)="goBack()"
      ></unleash-new-schedule>
    </div>
  </ng-container>
</div>

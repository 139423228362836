import {ChangeDetectorRef, Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {AtlasHeaderService, AtlasHeaderStatus} from '../services/atlas-header.service';
import {LatLngBounds, Map} from 'leaflet';
import {AtlasAnnotationsSettigsService} from '../services/atlas-annotations-settings.service';
import {AtlasSelectMarkersService} from '../services/atlas-select-markers.service';
import {AtlasSelectMarkersSettingsService} from '../services/atlas-select-markers-settings.service';
import {LayersVisibilityService} from '../services/layers-visibility.service';

@Directive({
  selector: '[ulSelectMarkers]',
  standalone: true
})
export class SelectMarkersDirective {
  @Input('ulSelectMarkers') public asset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(
    private atlasService: AtlasService,
    private atlasHeaderService: AtlasHeaderService,
    private atlasAnnotationsSettigsService: AtlasAnnotationsSettigsService,
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private atlasSelectMarkersSettingsService: AtlasSelectMarkersSettingsService,
    private cd: ChangeDetectorRef,
    private layersVisibilityService: LayersVisibilityService
  ) {}

  @HostListener('click', ['$event'])
  public selectMarkers(): void {
    const asset = this.asset;
    this.atlasAnnotationsSettigsService.closeAnnotationsOptions();
    this.atlasService.sethasToSkipLoadAssets(true);
    this.layersVisibilityService.displaySelectMarkersView(asset, this.map);
    this.cd.detectChanges();
    this.atlasSelectMarkersService.setCurrentLayer(asset);
    const headerStatus = AtlasHeaderStatus.SELECT_MARKERS;
    this.atlasHeaderService.setHeaderStatus(headerStatus);
    this.atlasSelectMarkersSettingsService.showSelectOptions();
    this.map.invalidateSize();
    const mapBounds = this.map.getBounds();
    const isLayerInTheView = mapBounds.intersects(asset.bounds);
    if (!isLayerInTheView) {
      this.map.fitBounds(asset.bounds as LatLngBounds);
    }
    this.atlasService.setHasToBlockEvents(true);
    this.atlasService.setHasToRemoveAssetsHiglight(true);
    this.atlasSelectMarkersService.setOpenSelectMarkersTime(new Date());
  }
}

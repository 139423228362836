<div *ngIf="(hasToShowAddons | async) === false; else addonsListTpl" class="empty">
  <img class="empty__img" src="assets/images/developer-dashboard/ai-apps-empty-placeholder.svg" />
  <span class="empty__principal">{{ 'live.scheduler.noAiApps' | translate }}</span>
  <ng-container *ngIf="isAdmin; else viewerTpl">
    <span class="empty__secondary">{{ 'live.scheduler.addAiApps' | translate }}</span>
    <button (click)="setHasToShowAddons(true)" class="empty__button" mat-button>
      {{ 'live.scheduler.addAiApp' | translate }}
    </button>
  </ng-container>
  <ng-template #viewerTpl>
    <span class="empty__secondary">{{ 'live.scheduler.noAiAppsViewer' | translate }}</span>
    <span class="empty__secondary">{{ 'live.scheduler.noAiAppsViewerMessage' | translate }}</span>
  </ng-template>
</div>
<ng-template #addonsListTpl>
  <span class="addons-title">{{
    isAdmin ? ('live.scheduler.aiAppTitle' | translate) : ('live.scheduler.aiAppTitleViewer' | translate)
  }}</span>
  <form [formGroup]="addonForm" class="addons">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'live.scheduler.aiApp' | translate }}</mat-label>
      <mat-select formControlName="principalAddonId" (selectionChange)="selectionChange($event.value, true)">
        <mat-option>--</mat-option>
        <mat-option
          [disabled]="addon.id === addonForm.controls.additionalAddonId.value"
          *ngFor="let addon of addons"
          [value]="addon.id"
          >{{ addon.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'live.scheduler.additionalAiApp' | translate }}</mat-label>
      <mat-select formControlName="additionalAddonId" (selectionChange)="selectionChange($event.value, false)">
        <mat-option>--</mat-option>
        <mat-option
          [disabled]="addon.id === addonForm.controls.principalAddonId.value"
          *ngFor="let addon of addons"
          [value]="addon.id"
          >{{ addon.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>

import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {AtlasHeaderService, AtlasHeaderStatus} from '@app/atlas/services/atlas-header.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {UserService} from '@app/core/services/api/user.service';
import {Job} from '@app/jobs/models/jobs.models';
import {JobDetailService} from '@app/jobs/services/job-detail.service';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {TranslateService} from '@ngx-translate/core';
import {Map} from 'leaflet';
import {BehaviorSubject, catchError, map, Observable, of, shareReplay, Subject, switchMap, take, tap} from 'rxjs';

@Component({
  selector: 'unleash-job-polygon-menu',
  templateUrl: './job-polygon-menu.component.html',
  styleUrls: ['./job-polygon-menu.component.scss']
})
export class JobPolygonMenuComponent {
  @Input() public jobId: string;
  @Input() public jobName: string;
  @Input() public baseAsset: AtlasAssetModel;
  @Input() public polygonAsset: AtlasAssetModel;
  @Input() public map: Map;

  public goToJob$: Subject<void> = new Subject<void>();
  public currentJob: Job = null;
  public isLoadingJob: boolean = false;
  public hasToCloseComponent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public headerStatus$: Observable<AtlasHeaderStatus> = this.atlasHeaderService.headerStatus$.pipe(shareReplay(1));
  public atlasHeaderStatus = AtlasHeaderStatus;
  public isJobsEnabled$: Observable<boolean> = this.userService.myCompany$.pipe(map(company => !!company.useUserTask));
  public aclPermissions = AclPermissions;

  constructor(
    private router: Router,
    private jobDetailService: JobDetailService,
    private jobsApiService: JobsApiService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private atlasHeaderService: AtlasHeaderService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {}

  public goToJob(): void {
    this.router.navigate(['/secure/jobs/' + this.jobId]);
  }

  public editJobSelection(): void {
    this.hasToCloseComponent.next(true);
  }

  public deleteJob(): void {
    if (!this.currentJob) {
      this.isLoadingJob = true;
      this.cd.detectChanges();
      this.jobDetailService
        .selectJob(this.jobId)
        .pipe(
          take(1),
          switchMap(job => {
            if (job) {
              return of(job);
            }
            return this.fetchJob();
          }),
          tap(job => {
            this.isLoadingJob = false;
            this.cd.detectChanges();
            this.currentJob = job;
            this.jobDetailService.openDeleteJob(this.jobId, job.title, job.status, this.polygonAsset.id, false);
          })
        )
        .subscribe();
      return;
    }
    this.jobDetailService.openDeleteJob(
      this.jobId,
      this.currentJob.title,
      this.currentJob.status,
      this.polygonAsset.id,
      false
    );
  }

  private fetchJob(): Observable<Job> {
    return this.jobsApiService.getJob(this.jobId).pipe(
      take(1),
      catchError(() => {
        this.isLoadingJob = false;
        this.cd.detectChanges();
        return this.translateService.get('jobs.jobNotFound').pipe(
          take(1),
          tap(translation => {
            this.snackBar.open(translation, null, {
              panelClass: 'center',
              duration: 3000
            });
          })
        );
      })
    );
  }
}

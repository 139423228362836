<h1 mat-dialog-title>{{ 'profile.info.couldNotRemoveUser' | translate }}</h1>
<div mat-dialog-content class="content">
  <span> {{ 'profile.info.removeUserErrorInfo' | translate }} </span>
  <span> {{ 'profile.info.removeUserErrorInfoTwo' | translate }} </span>
</div>

<div mat-dialog-actions>
  <button color="primary" mat-button mat-dialog-close>{{ 'common.ok' | translate }}</button>
  <button color="primary" mat-raised-button [mat-dialog-close]="true">{{ 'common.contactUs' | translate }}</button>
</div>

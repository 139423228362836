import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {StatusService} from '@app/core/services/api/status.service';
import {UserService} from '@app/core/services/api/user.service';
import {NetworkStatusService} from '@app/core/services/network-status.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {PlansService} from '@app/plans/services/plans.service';
import {routerRemoveRedirect} from '@app/store/router/router.actions';
import {Auth} from '@aws-amplify/auth';
import {Hub} from '@aws-amplify/core';
import {select, Store} from '@ngrx/store';
import {UserModel} from '@app/core/models/api/user-model';
import {actionClearNotifications} from '@app/core/notifications/notifications.actions';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';
import {selectRouterRedirect} from '@app/store/router/router.selector';
import {selectHasDeveloperMode} from '@app/store/user/user.selectors';
import {BehaviorSubject, from, Observable, of, ReplaySubject, throwError, zip} from 'rxjs';
import {catchError, filter, map, switchMap, take, takeLast, tap} from 'rxjs/operators';
import {actionSignInFormError} from '../components/sign-in/sign-in.actions';
import {selectSignInFormValue} from '../components/sign-in/sign-in.selectors';
import {
  actionSignUpClearForm,
  actionSignUpFormError,
  actionSignUpFormSuccess
} from '../components/sign-up/sign-up.actions';
import {selectSignUpFormValue} from '../components/sign-up/sign-up.selectors';
import {MFARequiredStates, MFA_SETUP_STATE, REGISTRATION_STAGE} from '../models/auth.models';
import {
  actionClearAwsMarketPlaceToken,
  actionSetupAwsMarketPlaceToken,
  authInitializationFinished,
  authInitializationStarted,
  authPlanPaid,
  authPlanSelectionRequired,
  authPostRegistrationSignIn,
  authSignedIn,
  authSignedOut,
  authSignIn,
  authTokensRefreshed
} from '../state/auth.actions';
import {RegistrationStage} from '../state/auth.state';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {AnalysisPageService} from '@app/analysis/services/analysis-page.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {TeamsManagementStoreFacadeService} from '@app/profile/services/teams-management-facade.service';
import {Team} from '@app/profile/models/team.model';
import {selectSignInEmail} from '../components/forgot-password/forgot-password.selectors';
import {
  actionForgotPasswordFormError,
  actionForgotPasswordFormSubmit,
  actionForgotPasswordFormSuccess
} from '../components/forgot-password/forgot-password.actions';
import {AuthErrorModel} from '../models/amplify-error';

@Injectable({providedIn: 'root'})
export class AuthService implements OnDestroy {
  public hubListener: any;
  public cognitoUser: any;
  public totpToken: any;
  public isVerificationCodeValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isTotpCodeValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isSmsMfaCodeValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isPhoneValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public hasSetupTotpError: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public identityIdSet$: ReplaySubject<void> = this.userService.identityIdSet$;
  public canLogOutOnSetMobilePhone: boolean = true;
  public savedEmail$ = zip([this.store.pipe(select(selectSignInEmail)), from(Auth.currentUserInfo())]).pipe(
    map(([email, user]) => {
      if (email) {
        return email;
      }
      if (user) {
        return user.attributes.email;
      }
      return '';
    })
  );
  public isSendingForgotCode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isChangePasswordFlow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private userService: UserService,
    private plansService: PlansService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private statusService: StatusService,
    private unleashAnalytics: UnleashAnalyticsService,
    private router: Router,
    private networkStatusService: NetworkStatusService,
    private localStorageService: LocalStorageService,
    private atlasService: AtlasService,
    private analysisPageService: AnalysisPageService,
    private userStoreFacadeService: UserStoreFacadeService,
    private teamsManagementStoreFacadeService: TeamsManagementStoreFacadeService
  ) {
    this.offAuthEvents();
    this.store.dispatch(authInitializationStarted());
    const MFASetupState = this.localStorageService.getItem('MFASetupState');
    if (MFASetupState !== null) {
      switch (MFASetupState) {
        case MFA_SETUP_STATE.VERIFY_SMS:
          this.router.navigate(['auth/sms-verification']);
          break;
        case MFA_SETUP_STATE.VERIFY_TOTP:
          this.router.navigate(['auth/totp-verification']);
          break;
        case MFA_SETUP_STATE.SETUP:
          this.router.navigate(['auth/setup-mfa']);
          break;
        case MFA_SETUP_STATE.SETUP_SMS:
          this.router.navigate(['auth/setup-mobile-number']);
          break;
        case MFA_SETUP_STATE.CONFIRM_TOTP:
          this.router.navigate(['auth/confirm-totp-token']);
          break;
        case MFA_SETUP_STATE.CONFIRM_MOBILE_NUMBER:
          this.router.navigate(['auth/confirm-mobile-number']);
          break;
      }
    } else {
      this.finalizeLogInBasedOnCurrentSession();
    }

    this.hubListener = data => {
      console.warn('hubListener.event', data.payload.event);
      switch (data.payload.event) {
        case 'signIn':
          if (
            data.payload.data.attributes &&
            data.payload.data.attributes['custom:mfaRequired'] === MFARequiredStates.NEEDS_SETUP
          ) {
            return;
          }
          this.finalizeLogInBasedOnCurrentSession();
          break;
        case 'signedIn':
          break;
        case 'signUp':
          this.store.dispatch(actionSignUpFormSuccess());
          // console.error('user signed up');
          break;
        case 'signOut':
          // console.error('user signed out');
          this.store.dispatch(authSignedOut());
          this.store.dispatch(actionClearAwsMarketPlaceToken());
          break;
        case 'signIn_failure':
          // console.error('user sign in failed');
          break;
        case 'configured':
          // console.error('the Auth module is configured');
          break;
        case 'userConfirmed':
          this.store
            .pipe(
              select(selectSignUpFormValue),
              filter(form => !!form.email && !!form.password),
              take(1)
            )
            .subscribe(form => {
              this.store.dispatch(authPostRegistrationSignIn({form}));
              this.store.dispatch(actionSignUpClearForm());
            });

          break;
      }
    };

    this.networkStatusService.isOnline$.subscribe(async isOnline => {
      await this.refreshCredentials(isOnline);
    });
    this.networkStatusService
      .isOnlineTimer(60 * 50 * 1000, 60 * 50 * 1000 /*every 50 minutes*/)
      .subscribe(async isOnline => {
        await this.refreshCredentials(isOnline);
      });
    // this.store.pipe(select(selectAuthIoTInitRequired))
    //   .subscribe(required => {
    //     if (!!required) {
    //       this.initIoT();
    //     }
    //   });
  }

  public ngOnDestroy(): void {
    this.offAuthEvents();
  }

  public setIsChangePasswordFlow(isChangePasswordFlow: boolean): void {
    this.isChangePasswordFlow.next(isChangePasswordFlow);
  }

  public setCanLogOutOnSetMobilePhone(canLogOutOnSetMobilePhone: boolean): void {
    this.canLogOutOnSetMobilePhone = canLogOutOnSetMobilePhone;
  }

  public setTotpToken(totpToken: any): void {
    this.totpToken = totpToken;
  }

  public setCognitoUser(cognitoUser: any): void {
    this.cognitoUser = cognitoUser;
  }

  public setIsVerificationCodeValid(isVerificationCodeValid: boolean): void {
    this.isVerificationCodeValid.next(isVerificationCodeValid);
  }

  public setIsTotpCodeValid(isTotpCodeValid: boolean): void {
    this.isTotpCodeValid.next(isTotpCodeValid);
  }

  public setHasSetupTotpError(hasSetupTotpError: boolean): void {
    this.hasSetupTotpError.next(hasSetupTotpError);
  }

  public setIsSmsMfaCodeValid(isSmsMfaCodeValid: boolean): void {
    this.isSmsMfaCodeValid.next(isSmsMfaCodeValid);
  }

  public setIsPhoneValid(isPhoneValid: boolean): void {
    this.isPhoneValid.next(isPhoneValid);
  }

  public listenAuthEvents(): void {
    Hub.listen('auth', this.hubListener);
  }

  public offAuthEvents(): void {
    Hub.remove('auth', this.hubListener);
  }

  // eslint-disable-next-line rxjs/finnish
  public signOut(isDeveloperMode: boolean): Observable<any> {
    return from(Auth.signOut()).pipe(
      switchMap(() => {
        this.userService.user$
          .pipe(
            take(1),
            tap(user => {
              this.unleashAnalytics.logEvent(EVENTS.LOGOUT, {email: user.email});
              this.userService.clearDataStore();
            })
          )
          .subscribe();
        this.statusService.clearDataStore();
        this.addonStoreFacadeService.clearAddonStore();
        this.store.dispatch(actionClearNotifications());
        return this.router.navigate([`/auth/${isDeveloperMode ? 'developer-' : ''}sign-in`]);
      }),
      tap(() => {
        this.atlasService.clearAtlasLocalStorage();
        location.reload();
      }),
      catchError(err => {
        this.userService.clearDataStore();
        this.statusService.clearDataStore();
        this.addonStoreFacadeService.clearAddonStore();
        this.store.dispatch(actionClearNotifications());
        this.router.navigate([`/auth/${isDeveloperMode ? 'developer-' : ''}sign-in`]);
        return throwError(() => new Error(err));
      })
    );
  }

  // async initIoT() {
  //   // IoT Attaches the specified policy to the specified principal
  //   // Ref https://stackoverflow.com/a/
  //   const identityId = await this.store.pipe(select(selectAuthUserId), take(1)).toPromise();
  //   if (!identityId) {
  //     return;
  //   }
  //   console.info('attaching ',this.policyName, 'role to', identityId );
  //   AWS.config.update({region: environment.region});
  //   const params: AttachPolicyRequest = {
  //     policyName: this.policyName,
  //     target: identityId
  //   };
  //   const cognitoCreds = await Auth.currentUserCredentials();
  //   const _iot = new Iot({credentials: cognitoCreds});
  //   // console.info('attaching policy to IoT', params);
  //   _iot.attachPolicy(params, (err, data) => {
  //     if (err) {
  //       console.error('IOT attach pricipal policy error ', err);
  //     }
  //   });
  //   this.store.dispatch(authIoTInitFinished());
  // }

  public finalizeLogInBasedOnCurrentSession(): void {
    zip(this.setupIdentityIdAfterSignIn(), from(Auth.currentUserInfo())).subscribe(
      ([currentCredentials, cognitoUser]) => {
        if (!cognitoUser || !cognitoUser.id) {
          console.warn('No user found in the session');
          this.store.dispatch(authInitializationFinished());
          return;
        }

        // todo this flow is not used any more, should be removed from application
        if (cognitoUser.attributes?.['custom:awsMarketplaceToken']) {
          this.store.dispatch(
            actionSetupAwsMarketPlaceToken({
              awsMarketplaceToken: cognitoUser.attributes['custom:awsMarketplaceToken']
            })
          );
        }

        const id = cognitoUser.id;

        this.userService
          .findUser()
          .pipe(
            take(1),
            catchError(error => {
              if ( this.router.url !== '/auth/your-profile' && (error?.response?.status === 404 || error?.response?.status === 403)) {
                this.router.navigate(['/auth/team-selector/']);
              }
              throw new Error("User doesn't exist");
            }),
            // check if user is already created and move to invite-already-accepted in
            // organization invitation flow
            tap(userData => {
              this.userService.setupUserDataModel(userData);
            }),
            switchMap((userData: UserModel) => {
              this.userStoreFacadeService.getTeam();

              return zip(
                of(userData),
                of(this.checkUserPlanAction(userData)),
                this.store.select(selectRouterRedirect),
                this.store.select(selectHasDeveloperMode),
                this.teamsManagementStoreFacadeService.currentTeam$.pipe(
                  filter((team: Team) => !!team),
                  switchMap(() => this.analysisPageService.currentDashboard$),
                  take(1)
                )
              );
            }),
            tap(() => this.store.dispatch(authInitializationFinished())),
            filter(([userData]) => !!userData.activeTeamAndCompany),
            tap(data => {
              this.store.dispatch(authSignedIn({payload: {userId: id}}));

              const [userData, hasUserPlanAction, redirect, hasDeveloperMode, currentDashboard] = data;

              if (!this.router.routerState.snapshot.url || this.router.routerState.snapshot.url.includes('/admin/')) {
                return;
              }

              if (!/secure/.exec(this.router.routerState.snapshot.url)) {
                switch (this.localStorageService.getItem(REGISTRATION_STAGE)) {
                  case RegistrationStage.details:
                    this.router.navigate(['auth/your-profile']);
                    return;
                  case RegistrationStage.developerDetails:
                    this.router.navigate(['auth/developer-profile']);
                    return;
                  case RegistrationStage.confirmation:
                    this.router.navigate(['auth/confirm-registration']);
                    return;
                  case RegistrationStage.welcome:
                    this.router.navigate(['auth/welcome']);
                    return;
                  case RegistrationStage.developerPayment:
                    this.router.navigate(['/auth/developer-payment']);
                    return;
                  case RegistrationStage.payment:
                    this.router.navigate(['/auth/reason']);
                    return;
                  default:
                    if (!!redirect && !!redirect.url) {
                      this.store.dispatch(routerRemoveRedirect());
                      this.router.navigate([redirect.url], {
                        queryParams: redirect.queryParams
                      });
                      return;
                    }

                    if (userData.currentPlan) {
                      if (currentDashboard) {
                        const targetRoute = currentDashboard.length > 0 ? '/secure/analysis' : '/secure/library';
                        this.router.navigate([targetRoute]);
                      } else {
                        this.router.navigate(['/secure/library']);
                      }
                      return;
                    }

                    if (userData.developer || hasDeveloperMode) {
                      this.router.navigate(['/auth/developer-payment']);
                      return;
                    }

                    if (userData.developer && hasDeveloperMode) {
                      this.router.navigate(['/auth/reason']);
                      return;
                    }
                }
              }

              if (/sign\-in|forgot\-password\-2|sign\-up/.test(this.router.url) && redirect?.url) {
                this.router.navigate([redirect.url], {
                  queryParams: redirect.queryParams
                });
                return;
              }
            })
          )
          .subscribe();

        this.userService.user$.pipe(take(1)).subscribe(user => {
          this.unleashAnalytics.logEvent(EVENTS.LOGIN_SUCCESS, {
            user: user.email
          });
        });
      },
      err => {
        console.trace('err', err);
        this.store.pipe(select(selectSignInFormValue), takeLast(1)).subscribe(form => {
          this.unleashAnalytics.logEvent(EVENTS.LOGIN_FAILED, {
            email: form.email.toLowerCase()
          });
          this.store.dispatch(actionSignInFormError({form, error: err}));
        });
      }
    );
  }

  private checkUserPlanAction(user: UserModel): boolean {
    if (user.currentPlan) {
      this.store.dispatch(authPlanPaid());
      return true;
    }

    this.store.dispatch(authPlanSelectionRequired({isDeveloperMode: user.developer}));
  }

  public setupIdentityIdAfterSignIn(): Observable<any> {
    return from(Auth.currentCredentials()).pipe(
      filter(Boolean),
      tap((info: any) => this.userService.setIdentityId(info.identityId)),
      take(1),
      catchError(() => of(true))
    );
  }

  private finalizeSignUpBasedOnSession() {
    Auth.currentSession()
      .then(cognitoSession => {})
      .catch(err => {
        if (err === 'No current user') {
          this.store
            .pipe(
              select(selectSignUpFormValue),
              filter(form => !!form.email && !!form.password),
              take(1)
            )
            .subscribe(form => {
              this.store.dispatch(authSignIn({form}));
              this.store.dispatch(actionSignUpClearForm());
            });
        } else {
          console.trace(JSON.stringify(err));
          this.unleashAnalytics.logEvent(EVENTS.AUTH_FAILED);
          this.store.dispatch(actionSignUpFormError({error: err.message}));
        }
      });
  }

  public async refreshCredentials(isOnline: boolean): Promise<void> {
    if (!isOnline) {
      return;
    }

    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      await Auth.Credentials.clear();
      const refreshedSession = await this.refreshCognitoSession(cognitoUser, currentSession);
      // we need to load new session
      const credentials = await Auth.currentCredentials();
      const newCurrentSession = await Auth.currentSession();
      this.store.dispatch(authTokensRefreshed());
    } catch (e) {
      console.error('Could not refresh token:', e);
    }
  }

  public updateUser(user: Partial<UserModel>) {
    this.userService.updateUser(user);
  }

  public updateCognitoUserFinished() {
    this.userService.updateCognitoUserFinished();
  }

  public triggerForgotPassword(email: string, isDeveloperMode: boolean) {
    this.isSendingForgotCode.next(true);
    this.store.dispatch(actionForgotPasswordFormSubmit({form: {email}}));
    Auth.forgotPassword(email)
      .then(() => {
        this.store.dispatch(actionForgotPasswordFormSuccess());
        this.router.navigate([`/auth/${isDeveloperMode ? 'developer-' : ''}change-password`]);
        this.isSendingForgotCode.next(false);
      })
      .catch((error: AuthErrorModel) => {
        this.store.dispatch(actionForgotPasswordFormError({error}));
      });
  }

  private refreshCognitoSession(cognitoUser: any, currentSession: any): Promise<any> {
    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }
}

import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup, FormControl, ReactiveFormsModule, FormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {LaddaModule} from '@app/shared/ladda/ladda.module';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, Observable, shareReplay, Subscription, switchMap, take} from 'rxjs';
import {AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {TaskType} from '@app/jobs/models/jobs.models';
import {WayPointType} from '../convert-to-mission-dialog/convert-to-mission-dialog.component';
import {GeoJSON, Feature, Geometry} from 'geojson';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {JobsFacadeService} from '@app/jobs/services/jobs-facade.service';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-convert-to-task-dialog',
  templateUrl: './convert-to-task-dialog.component.html',
  styleUrls: ['./convert-to-task-dialog.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatButtonModule,
    TranslateModule,
    MatDialogModule,
    LaddaModule,
    MatSelectModule,
    MatInputModule,
    MatStepperModule,
    MatRadioModule,
    CommonModule
  ]
})
export class ConvertToTaskComponent implements OnInit {
  private isLoading = new BehaviorSubject(false);
  private jobFormSubscription: Subscription;

  public isLoading$ = this.isLoading.asObservable().pipe(shareReplay(1));
  public smartInspectProfiles = Object.values(WayPointType).map(value => ({key: value, name: `SI-${value}`}));
  public jobs$: Observable<any[]>;
  public jobSelectionType = JobSelectionType;
  public form = new FormGroup({
    smartInspectProfile: new FormControl(WayPointType.POLE_3, Validators.required),
    operationalAltitude: new FormControl(18, [Validators.required, Validators.min(0), Validators.max(200)]),
    speed: new FormControl(2, [Validators.required, Validators.min(0), Validators.max(20)]),
    missionName: new FormControl(this.data.name, Validators.required)
  });
  public jobForm = new FormGroup({
    jobId: new FormControl(''),
    jobType: new FormControl(this.jobSelectionType.CREATE_NEW, Validators.required),
    jobName: new FormControl(this.data.name, Validators.required)
  });

  constructor(
    private dialogRef: MatDialogRef<ConvertToTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AtlasGeojsonAssetModel,
    private jobsApiService: JobsApiService,
    private jobsFacadeService: JobsFacadeService
  ) {
    this.jobsFacadeService.listJobs();
    this.validateJobs();
  }

  private validateJobs() {
    this.jobFormSubscription = this.jobForm.get('jobType')!.valueChanges.subscribe(value => {
      if (value === this.jobSelectionType.CREATE_NEW) {
        this.jobForm.get('jobName')!.setValidators(Validators.required);
        this.jobForm.get('jobId')!.clearValidators();
      } else {
        this.jobForm.get('jobId')!.setValidators(Validators.required);
        this.jobForm.get('jobName')!.clearValidators();
      }

      this.jobForm.get('jobName')!.updateValueAndValidity();
      this.jobForm.get('jobId')!.updateValueAndValidity();
    });
  }

  public ngOnInit() {
    this.jobs$ = this.jobsFacadeService.allJobs$;
  }

  public convertAssetToTask() {
    this.isLoading.next(true);

    // Map GeoJSON features to route points
    const routePoints = [];

    // Check if geojson is a FeatureCollection
    const geojson = this.data.geojson as GeoJSON;
    const features = (geojson as any).features || [];

    // Sort features using travelling salesman heuristic algorithm
    const sortedFeatures = this.getSortedFeatures(features);
    if (sortedFeatures.length > 0) {
      sortedFeatures.forEach((feature: Feature<Geometry>, index) => {
        // Only process Point geometries
        if (feature.geometry && feature.geometry.type === 'Point') {
          const geometry = feature.geometry as any; // Cast to any to access coordinates
          if (!geometry.coordinates) {
            return; // Skip if no coordinates
          }

          // Point geometry: [longitude, latitude]
          const coordinates = geometry.coordinates;
          const lng = coordinates[0];
          const lat = coordinates[1];

          if (lat !== undefined && lng !== undefined) {
            // Create route point
            routePoints.push({
              pole: 'RegularPoint',
              wo: `${index}`,
              lat,
              lng,
              altitude: this.form.value.operationalAltitude,
              speed: this.form.value.speed,
              si: this.form.value.smartInspectProfile,
              actions: [
                {
                  action: 'TAKE_PHOTO'
                }
              ]
            });
          }
        }
      });
    }

    // Check if we have any route points
    if (routePoints.length === 0) {
      this.isLoading.next(false);
      this.dialogRef.close({
        taskResult: null,
        error: 'No Point geometries found in the GeoJSON. Only Point geometries are supported.'
      });
      return;
    }

    // Create task payload with the required structure for the API
    const taskPayload = {
      type: TaskType.FLIGHT_REQUEST,
      desc: `Corridor mission for ${this.data.name}`,
      name: this.form.value.missionName,
      speed: this.form.value.speed,
      route: routePoints,
      jobId: this.jobForm.value.jobId || null,
      assignedId: ''
    };

    // If no job is selected, create a new job first
    const createTask$ = this.jobForm.value.jobId
      ? this.jobsApiService.createJobTaskAndMissionCorridor(taskPayload)
      : this.jobsApiService
          .createJob({
            title: this.jobForm.value.jobName
          })
          .pipe(
            switchMap(job => {
              // Add job ID to the task payload
              taskPayload.jobId = job.id;
              return this.jobsApiService.createJobTaskAndMissionCorridor(taskPayload);
            })
          );

    createTask$.pipe(take(1)).subscribe({
      next: task => {
        this.isLoading.next(false);
        this.dialogRef.close({taskResult: task});
      },
      error: error => {
        console.error('Error creating task:', error);
        this.isLoading.next(false);
        this.dialogRef.close({taskResult: null, error});
      }
    });
  }

  public cancel() {
    this.dialogRef.close({taskResult: null});
  }

  // sort features using travelling salesman heuristic algorithm
  private getSortedFeatures(features) {
    // Haversine distance function
    function distance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Earth's radius in km
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    }

    // Nearest Neighbor algorithm
    function nearestNeighborTSP(points) {
      const n = points.length;
      const visited = new Array(n).fill(false);
      const tour = [0]; // Start with the first point
      visited[0] = true;

      for (let i = 1; i < n; i++) {
        const lastPoint = tour[tour.length - 1];
        let nearestPoint = -1;
        let minDistance = Infinity;

        for (let j = 0; j < n; j++) {
          if (!visited[j]) {
            const dist = distance(points[lastPoint][1], points[lastPoint][0], points[j][1], points[j][0]);
            if (dist < minDistance) {
              minDistance = dist;
              nearestPoint = j;
            }
          }
        }

        tour.push(nearestPoint);
        visited[nearestPoint] = true;
      }

      return tour;
    }

    const coordinates = features.map(f => f.geometry.coordinates);

    const tour = nearestNeighborTSP(coordinates);

    return tour.map(i => features[i]);
  }
}

export enum JobSelectionType {
  CREATE_NEW = 'CREATE_NEW',
  ADD_TO_EXISTING = 'ADD_TO_EXISTING'
}

<ng-container *ngIf="selectedMission; else missionListTemplate">
  <unleash-mission-viewer-control
    [mission]="selectedMission"
    [isMissionDrawerClosed]="isMissionDrawerClosed"
    [selectedWaypointIndex]="selectedWaypointIndex"
    [defaultSettings]="defaultSettings$ | async"
    [isEditing]="isEditing$ | async"
    [isNewRoute]="isNewRoute$ | async"
    (selectedWaypoint)="emitSelectedWaypoint($event)"
    (updatedMission)="updateMission($event)"
  ></unleash-mission-viewer-control>
</ng-container>
<ng-template #missionListTemplate>
  <ng-container *ngIf="!(isLoading$ | async); else loadingTemplate">
    <ng-container *ngIf="hasMissions$ | async; else noMissionsTemplate">
      <mat-tab-group class="mission__container">
        <mat-tab
          [label]="('atlas.mission.ready' | translate) + ' (' + (readyMissionsGroupedByOwner$ | async).length + ')'"
        >
          <ng-container
            [ngTemplateOutlet]="missionsTemplate"
            [ngTemplateOutletContext]="{groupedMissions: (readyMissionsGroupedByOwner$ | async)}"
          ></ng-container>
        </mat-tab>
        <mat-tab
          [label]="
            ('atlas.mission.completed' | translate) + ' (' + (completeMissionsGroupedByOwner$ | async).length + ')'
          "
        >
          <ng-container
            [ngTemplateOutlet]="missionsTemplate"
            [ngTemplateOutletContext]="{groupedMissions: (completeMissionsGroupedByOwner$ | async)}"
          ></ng-container>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </ng-container>

  <ng-template #missionsTemplate let-groupedMissions="groupedMissions">
    <ng-container *ngIf="groupedMissions as missions">
      <ng-container *ngIf="missions.length">
        <ng-container *ngFor="let mission of missions">
          <div class="mission" data-test-id="mission-container">
            <div (click)="viewMission(mission)" class="mission__item">
              <span class="mission__icon-container">
                <mat-icon
                  class="mission__icon"
                  [uaCustomTooltip]="tooltipTemplate"
                  [tooltipPosition]="tooltipPosition"
                  [svgIcon]="
                    mission.isSmartInspect
                      ? 'smart-inspect-mission'
                      : mission.type !== 'FLIGHT_REQUEST_MANUAL'
                      ? (mission.type || 'waypoint') + '-mission'
                      : 'waypoint-mission'
                  "
                ></mat-icon>
              </span>
              <div class="mission__content">
                <span class="mission__title" data-test-id="mission-title">
                  {{ mission.name }}
                </span>
                <span
                  *ngIf="mission.type !== missionType.MAPPING_2D; else missionSurveyTimeTemplate"
                  class="mission__info"
                >
                  {{ mission.route?.length || 'N/A' }} {{ 'atlas.mission.waypoints' | translate }},
                  {{ mission.time | uDuration: 'shortFormatFlight' }}, {{ mission.distance | number: '1.2-3' }}km</span
                >
                <ng-template #missionSurveyTimeTemplate>
                  <span *ngIf="mission?.waylineFolders?.length > 0" class="mission__info">
                    {{ mission?.waylineFolders[0]?.route?.length }} {{ 'atlas.mission.waypoints' | translate }}
                    {{ mission?.waylineFolders[0]?.duration | uDuration: 'shortFormatFlight' }},
                    {{ mission?.waylineFolders[0]?.distance / 1000 | number: '1.2-3' }}km
                  </span>
                </ng-template>
              </div>
            </div>
            <button mat-icon-button class="mission__menu" [matMenuTriggerFor]="menu" data-test-id="mission-menu-button">
              <mat-icon class="mission__icon-menu">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button class="mission__menu-item" (click)="viewMission(mission)" mat-menu-item>
                <mat-icon class="mission__icon-menu" svgIcon="leading"></mat-icon>
                <span class="mission__item-menu">{{ 'atlas.mission.viewMission' | translate }}</span>
              </button>
              <button class="mission__menu-item" (click)="viewLocation(mission)" mat-menu-item>
                <mat-icon class="mission__icon-menu">my_location</mat-icon>
                <span class="mission__item-menu">{{ 'atlas.mission.viewLocation' | translate }}</span>
              </button>
              <button
                *ngIf="mission.type !== missionType.MAPPING_2D"
                class="mission__menu-item mission__menu-item--group"
                (click)="openPreviewIn3D(mission)"
                mat-menu-item
              >
                <span>
                  <mat-icon class="mission__icon-menu" svgIcon="u-preview-3d"></mat-icon>
                  {{ 'atlas.mission.previewIn3D' | translate }}
                </span>
              </button>
              <button
                *uaHasPermission="aclPermissions.MissionsApiUpdate"
                [ulRenameMission]="mission"
                class="mission__menu-item"
                mat-menu-item
              >
                <mat-icon class="mission__icon-menu" svgIcon="rename-mission"></mat-icon>
                <span class="mission__item-menu">{{ 'atlas.mission.rename' | translate }}</span>
              </button>
              <button
                *ngIf="mission.type !== missionType.MAPPING_2D"
                class="mission__menu-item"
                mat-menu-item
                [matMenuTriggerFor]="matExportMenu"
              >
                <mat-icon class="mission__icon-menu" svgIcon="export"></mat-icon>
                <span class="mission__item-menu"> {{ 'atlas.mission.export' | translate }}</span>
              </button>
              <mat-menu #matExportMenu="matMenu">
                <button [ulExportMission]="mission" [exportType]="MissionExportType.JSON" mat-menu-item>
                  <mat-icon class="mission__icon-menu" svgIcon="coco-json"></mat-icon>
                  <span>{{ 'atlas.mission.downloadJson' | translate }}</span>
                </button>
                <button [ulExportMission]="mission" [exportType]="MissionExportType.GEOJSON" mat-menu-item>
                  <mat-icon class="mission__icon-menu" svgIcon="coco-json"></mat-icon>
                  <span>{{ 'atlas.mission.exportGeojson' | translate }}</span>
                </button>
                <button
                  [cdkCopyToClipboard]="
                    {
                      name: mission?.name,
                      description: mission?.description,
                      heightMode: mission?.heightMode,
                      isSmartInspect: mission?.isSmartInspect || false,
                      route: mission?.route
                    } | json
                  "
                  (click)="showCopyToClipboard()"
                  mat-menu-item
                >
                  <mat-icon class="mission__icon-menu">content_copy</mat-icon>
                  <span>{{ 'atlas.mission.copyJson' | translate }}</span>
                </button>
              </mat-menu>
              <ng-container *uaHasPermission="aclPermissions.MissionsApiDelete">
                <mat-divider class="mission__menu-divider"></mat-divider>
                <button
                  [ulDeleteMission]="mission"
                  class="mission__menu-item"
                  mat-menu-item
                  data-test-id="delete-mission-button"
                >
                  <mat-icon class="mission__icon-menu delete">delete</mat-icon>
                  <span class="mission__item-menu delete">{{ 'common.delete' | translate }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <ng-template #tooltipTemplate>
            <div class="tooltip">
              <span class="tooltip__title"> {{ 'atlas.mission.missionType' | translate }}</span>
              <span class="tooltip__mission"> {{ missionTypeDisplay[mission?.type] || missionTypeDisplay['waypoint'] }}</span>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- TEMPLATE TO USE WHEN THE DATA IS GROUPED -->
    <!-- <ng-container *ngFor="let missions of missionsGroupedByOwner$ | async | keyvalue">
      <div class="mission-owner">
        <mat-icon class="mission-owner__icon" svgIcon="user"></mat-icon>
        {{ missions.key }} ({{ missions.value.length }})
      </div>
      <div *ngFor="let mission of missions.value | keyvalue">
        <div class="mission">
          <div class="mission__content">
            <span class="mission__title">
              {{ mission.value.name }}
            </span>
            <span class="mission__info">
              {{ mission.value.route.length }} waypoints, {{ mission.value.time | uDuration: 'shortFormatFlight' }},
              {{ mission.value.distance | number: '1.2-3' }}km</span
            >
          </div>
        </div>
      </div>
    </ng-container> -->
  </ng-template>

  <ng-template #loadingTemplate>
    <div class="mission__loader">
      <unleash-spinner diameter="20"></unleash-spinner>
    </div>
  </ng-template>

  <ng-template #noMissionsTemplate>
    <div class="mission__no-missions">
      <span class="mission__no-missions-header">{{ 'atlas.mission.emptyMissions' | translate }}</span>
      <div class="mission__no-missions-container">
        <mat-icon class="mission__no-missions-icon" svgIcon="no-missions"></mat-icon>
        <span class="mission__no-missions-title">{{ 'atlas.mission.noMissions' | translate }}</span>
        <span class="mission__no-missions-description">
          {{ 'atlas.mission.noMissionsDescription' | translate }}
        </span>
      </div>
    </div>
  </ng-template>
</ng-template>

import {Action, createReducer, on} from '@ngrx/store';
import {CompanyModel, UserCompanyModel} from '@app/core/models/api/company-model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

import {UserModel} from '@app/core/models/api/user-model';
import * as actions from './user.actions';
import {Team} from '@app/profile/models/team.model';

export interface UserState {
  user: UserModel;
  devices: {[key: string]: UserDeviceJoined};
  company: CompanyModel;
  companies: CompanyModel[];
  userTeams: {[key: string]: Team};
  userCompanies: UserCompanyModel[];
  isSwitchingTeam: boolean;
  isUpdatingCognitoUser: boolean;
}

export const initialState: UserState = {
  user: null,
  devices: {},
  company: null,
  companies: [],
  userTeams: {},
  userCompanies: [],
  isSwitchingTeam: false,
  isUpdatingCognitoUser: false
};

const reducer = createReducer(
  initialState,
  on(actions.userStoreData, (state, {user}) => {
    if (!user && !state.user) {
      return {...state, user: null};
    }

    return {
      ...state,
      user: {...state.user, ...user}
    };
  }),
  on(actions.userStoreDeviceData, (state, {devices}) => {
    return {
      ...state,
      devices: {...state.devices, ...devices}
    };
  }),
  on(actions.userStoreRemoveDeviceData, (state, {deviceId}) => {
    const devices = {...state.devices};
    delete devices[deviceId];
    return {
      ...state,
      devices: devices
    };
  }),
  on(actions.userStoreCompany, (state, {company}) => {
    return {...state, company};
  }),
  on(actions.updateDeviceZonesConfig, (state, {deviceId, zonesConfig}) => {
    return {
      ...state,
      devices: {
        ...state.devices,
        [deviceId]: {...state.devices[deviceId], zonesConfig}
      }
    };
  }),
  on(actions.updateAnalyticsConfig, (state, {deviceId, analyticsConfig}) => {
    return {
      ...state,
      devices: {
        ...state.devices,
        [deviceId]: {...state.devices[deviceId], analyticsConfig}
      }
    };
  }),
  on(actions.deleteSceneMappingSuccess, (state, {payload: {deviceId, addonId}}) => {
    const deviceState = state.devices[deviceId];
    const {analyticsConfig, ...device} = deviceState;

    const user = {
      ...state,
      devices: {
        ...state.devices,
        [deviceId]: device
      }
    };

    return user;
  }),
  on(actions.saveCompanies, (state, {companies}) => {
    return {...state, companies: companies};
  }),
  on(actions.saveUserCompanies, (state, {companies}) => {
    const userCompanies = companies ? [...state.userCompanies, ...companies] : [];
    return {...state, userCompanies: userCompanies};
  }),
  on(actions.saveSetOfDevices, (state, {devices}) => {
    return {...state, devices: devices};
  }),
  on(actions.setUserStoreRole, (state, {payload}: {payload: {roles}}) => {
    return {...state, user: {...state.user, role: payload.roles[0], roles: payload.roles}};
  }),
  on(actions.loadUserTeamsSuccess, (state, {payload}: {payload: {teams: {[key: string]: Team}}}) => {
    return {...state, userTeams: payload.teams};
  }),
  on(actions.switchCurrentTeam, state => ({...state, isSwitchingTeam: true})),
  on(actions.switchCurrentTeamSuccess, (state, {payload}: {payload: {companyId: string; teamId: string}}) => {
    return {
      ...state,
      user: {...state.user, activeTeamAndCompany: {companyId: payload.companyId, teamId: payload.teamId}}
    };
  }),
  on(actions.addLiveDevice, (state, {payload: {deviceId, player}}) => ({
    ...state,
    devices: {
      ...state.devices,
      [deviceId]: {...state.devices[deviceId], runningModels: [], waitingModels: [], isLive: true, player}
    }
  })),
  on(actions.setRunningModelsByDeviceId, (state, {payload: {deviceId, runningModels}}) => {
    const deviceState = state.devices[deviceId];
    return {...state, devices: {...state.devices, [deviceId]: {...deviceState, runningModels}}};
  }),
  on(actions.setCurrentModel, (state, {payload}: {payload: {addonId: string; deviceId: string}}) => {
    return {
      ...state,
      devices: {
        ...state.devices,
        [payload.deviceId]: {...state.devices[payload.deviceId], selectedModel: payload.addonId}
      }
    };
  }),
  on(actions.removeAddonFromWaitingModels, (state, {payload}: {payload: {deviceId: string}}) => {
    const device = {...state.devices[payload.deviceId]};
    device.waitingModels = device.waitingModels?.filter(modelId => !device.runningModels.includes(modelId)) || [];

    return {...state, devices: {...state.devices, [payload.deviceId]: device}};
  }),
  on(
    actions.removeAddonFromWaitingModelsDueError,
    (state, {payload}: {payload: {deviceId: string; modelId: string}}) => {
      const device = {...state.devices[payload.deviceId]};
      device.waitingModels = device.waitingModels.filter(modelId => modelId !== payload.modelId);

      return {...state, devices: {...state.devices, [payload.deviceId]: device}};
    }
  ),
  on(actions.removeAddonFromRunningModels, (state, {payload}: {payload: {deviceId: string; modelId: string}}) => {
    const device = {...state.devices[payload.deviceId]};
    device.runningModels = device.runningModels.filter(modelId => modelId !== payload.modelId);

    if (device.selectedModel === payload.modelId) {
      device.selectedModel = 'RAW_STREAM';
    }

    return {
      ...state,
      devices: {...state.devices, [payload.deviceId]: device}
    };
  }),
  on(actions.setDisplayedDeviceIds, (state, {payload}: {payload: {displayedDeviceIds: string[]}}) => {
    const newDevices = {...state.devices};
    const devicesIds = Object.keys(state.devices);
    for (let index = 0; index < devicesIds.length; index++) {
      const deviceId = devicesIds[index];
      newDevices[deviceId] = {...newDevices[deviceId], isDisplayed: false};
    }

    for (let index = 0; index < payload.displayedDeviceIds.length; index++) {
      const deviceId = payload.displayedDeviceIds[index];
      newDevices[deviceId] = {...newDevices[deviceId], isDisplayed: true};
    }

    return {
      ...state,
      devices: newDevices
    };
  }),
  on(
    actions.updateRestreamsStats,
    (
      state,
      {
        payload
      }: {
        payload: {
          [key: string]: {
            isRestreamingFrom?: boolean;
            statsRestreamingFrom?: string;
            isRestreamingTo?: boolean;
            statsRestreamingTo?: string;
          };
        };
      }
    ) => {
      const devices = {...state.devices};
      Object.keys(payload).forEach(deviceId => {
        devices[deviceId] = {...devices[deviceId], ...payload[deviceId]};
      });
      return {...state, devices};
    }
  ),
  on(actions.changeDestUrlSetOnSelectedDevice, (state, {payload}) => ({
    ...state,
    devices: {
      ...state.devices,
      [payload.deviceId]: {...state.devices[payload.deviceId], destUrlSet: payload.destUrlSet}
    }
  })),
  on(actions.setDestUrlOnSelectedDevice, (state, {payload}: {payload: {deviceId: string; destUrl: string}}) => {
    return {
      ...state,
      devices: {
        ...state.devices,
        [payload.deviceId]: {...state.devices[payload.deviceId], destUrl: payload.destUrl}
      }
    };
  }),
  on(actions.updateDevicesCache, (state, {payload}: {payload: {[key: string]: Partial<UserDeviceJoined>}}) => {
    const newDevices = {...state.devices};
    const devicesIds = Object.keys(state.devices);
    for (let index = 0; index < devicesIds.length; index++) {
      const deviceId = devicesIds[index];
      newDevices[deviceId] = {...newDevices[deviceId], ...payload[deviceId]};
    }

    return {...state, devices: {...state.devices, ...newDevices}};
  }),
  on(actions.setIsUpdatingCognitoUser, (state, {isUpdatingCognitoUser}) => {
    return {...state, isUpdatingCognitoUser};
  })
);

export function userStateReducer(state: UserState | undefined, action: Action): UserState {
  return reducer(state, action);
}
import {DatePipe} from '@angular/common';
import {ScheduleCronExpression} from '@app/core/models/api/schedule.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, shareReplay} from 'rxjs';
import {TeamRole} from '@app/profile/models/team.model';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';

@Injectable({
  providedIn: 'root'
})
export class StreamScheduleService {
  public schedulePageState: BehaviorSubject<SCHEDULE_PAGE_STATE> = new BehaviorSubject<SCHEDULE_PAGE_STATE>(
    SCHEDULE_PAGE_STATE.LIST
  );
  public headerState: BehaviorSubject<HeaderState> = new BehaviorSubject<HeaderState>({title: null, items: []});
  public role$: Observable<TeamRole> = this.userStoreFacadeService.currentRole$.pipe(shareReplay(1));
  public currentUserDevicesObject$: Observable<{[key: string]: UserDeviceJoined}> =
    this.userStoreFacadeService.currentUserDevicesObject$.pipe(shareReplay(1));

  constructor(private datePipe: DatePipe, private userStoreFacadeService: UserStoreFacadeService) {}

  public dateToCron(schedules, timezone, recurring = true): ScheduleCronExpression[] {
    const expressions = [];
    for (const dayIndex in schedules) {
      const schedulesPerDay = schedules[dayIndex];
      if (!schedulesPerDay) {
        continue;
      }
      schedulesPerDay.forEach(schedule => {
        const dayOfTheWeek = schedule.startTime.getDay();
        const addDays = parseInt(dayIndex) - dayOfTheWeek;
        const startTime = new Date(schedule.startTime.getTime() + addDays * 24 * 60 * 60 * 1000);
        const endTime = new Date(schedule.endTime.getTime() + addDays * 24 * 60 * 60 * 1000);
        const startMinute = this.datePipe.transform(startTime, 'mm');
        const startHour = this.datePipe.transform(startTime, 'HH');
        const startDay = this.datePipe.transform(startTime, 'd');
        const startMonth = this.datePipe.transform(startTime, 'M');
        const startYear = this.datePipe.transform(startTime, 'yyyy');
        const endMinute = this.datePipe.transform(endTime, 'mm');
        const endHour = this.datePipe.transform(endTime, 'HH');
        const endDay = this.datePipe.transform(endTime, 'd');
        const endMonth = this.datePipe.transform(endTime, 'M');
        const endYear = this.datePipe.transform(endTime, 'yyyy');

        let expression = {
          startCronExpression: `cron(${startMinute} ${startHour} ? * ${schedule.startTime.getDay() + 1} *)`,
          endCronExpression: `cron(${endMinute} ${endHour} ? * ${schedule.endTime.getDay() + 1} *)`
        };

        if (!recurring) {
          expression = {
            startCronExpression: `cron(${startMinute} ${startHour} ${startDay} ${startMonth} ? ${startYear})`,
            endCronExpression: `cron(${endMinute} ${endHour} ${endDay} ${endMonth} ? ${endYear})`
          };
        }

        if (schedule?.startCronId) {
          expression['startCronId'] = schedule.startCronId;
        }

        if (schedule?.endCronId) {
          expression['endCronId'] = schedule.endCronId;
        }
        expressions.push(expression);
      });
    }
    return expressions;
  }

  public setSchedulePageState(state: SCHEDULE_PAGE_STATE) {
    this.schedulePageState.next(state);
  }

  public setHeaderState(state: HeaderState) {
    this.headerState.next(state);
  }
}

export enum SCHEDULE_PAGE_STATE {
  'LIST',
  'NEW_SCHEDULE'
}

export interface HeaderState {
  title: string;
  items: string[];
}
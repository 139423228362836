import {Device} from '@app/core/models/api/user-device.model';
import {Team} from '@app/profile/models/team.model';
import {AutoflyScope} from '@app/flights/services/iot-topic.service';
import {AtlasAssetModel} from './atlas.model';
import {LatLngTuple} from 'leaflet';

export class CompanyModel {
  id: string;
  owner: string;
  name: string;
  logo: string;
  website: string;
  industry: string;
  createdAt: number;
  updatedAt: number;
  slug?: string;
  isPublicWatchPageEnabled?: boolean;
  phone?: string;
  aiApps?: any; //TODO
  aiAppsPartial?: any; //TODO
  mfaRequired?: boolean;
  dateFormat?: string;
  timezone?: string;
  useUserTask?: boolean;
  isStreamProxyEnabled?: boolean;
  autoflyFullAccess?: any;
  autoflyScope?: AutoflyScope;
  atlas?: {
    assets: AtlasAssetModel[];
    annotationAssetTypesUrl: string;
    settings: {
      center: LatLngTuple;
      initialZoom: number;
      getLatestDays: number /* number of days to limit the recent assets */;
      weatherMapEnabled?: boolean;
      isLegendEnabled?: boolean;
      isPopupOnHoverDisabled?: boolean;
      markers?: MarkersSettings;
    };
  };
}

export interface MarkersSettings {
  style: string;
  radius: number;
  borderColor: string;
  borderSize: number;
}

export class UserCompanyModel {
  companyId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface CompanyRegisterResponse {
  newCredentials: any;
  user: any;
  team: Team;
  teamUser: any;
  company: any;
  device: Device;
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ForgotPassword} from './forgot-password.model';
import {emailRegex} from '../../regex';
import {Observable} from 'rxjs';

@Component({
  selector: 'unleash-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent implements OnInit {
  @Input() isInProgress: boolean;
  @Input() savedEmail$: Observable<string> = new Observable<string>();
  @Output() submitForm: EventEmitter<Partial<ForgotPassword>> = new EventEmitter();

  form = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(emailRegex)]]
  });
  private skipValidation: boolean;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.savedEmail$.subscribe(email => {
      if (email) {
        this.form.get('email')?.disable();
        this.form.setValue({email: email});
        this.skipValidation = true;
      }
    });
  }

  clickForgotPasswordButton() {
    if (this.form.valid || this.skipValidation) {
      this.submitForm.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BatchUpdateSchedule, ScheduleCronExpression, ScheduleModel} from '@app/core/models/api/schedule.model';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {StreamScheduleFacadeService} from '@app/live/services/stream-schedule-facade.service';
import {HeaderState} from '@app/live/services/stream-schedule.service';
import {Addon} from '@app/store/addon/models/addon';
import {TranslateService} from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  distinctUntilChanged,
  filter,
  map,
  of,
  shareReplay,
  take,
  withLatestFrom
} from 'rxjs';
import {ScheduleMode, ScheduleType} from '@app/live/models/schedule.model';
import {TeamRole} from '@app/profile/models/team.model';

@Component({
  selector: 'unleash-edit-stream-schedule-page',
  templateUrl: './edit-stream-schedule-page.component.html',
  styleUrls: ['./edit-stream-schedule-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditStreamSchedulePageComponent {
  public isAdmin$: Observable<boolean> = of(false);
  public isViewMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentUserDevicesObject$: Observable<{[key: string]: UserDeviceJoined}> =
    this.userStoreFacadeService.currentUserDevicesObject$;
  public devices$: Observable<UserDeviceJoined[]> = of([]);
  public addons$: Observable<Addon[]> = this.addonStoreFacadeService.getAllActiveAddons();
  public schedules: ScheduleModel[];
  public userStreamKey$ = this.userStoreFacadeService.currentUser$.pipe(map(user => user?.streamKey));
  public schedule$: Observable<ScheduleModel> = this.scheduleFacadeService
    .selectScheduleById(this.route.snapshot.params.scheduleId)
    .pipe(
      withLatestFrom(
        this.translateService
          .get([
            'live.scheduler.device',
            'live.scheduler.devices',
            'live.scheduler.day',
            'live.scheduler.days',
            'live.scheduler.aiApp',
            'live.scheduler.aiApps',
            'live.scheduler.editSchedule',
            'live.scheduler.viewSchedule'
          ])
          .pipe(take(1))
      ),
      map(([schedule, translations]) => {
        const {
          'live.scheduler.device': device,
          'live.scheduler.devices': devices,
          'live.scheduler.day': day,
          'live.scheduler.days': days,
          'live.scheduler.aiApp': aiApp,
          'live.scheduler.aiApps': aiApps,
          'live.scheduler.editSchedule': editSchedule,
          'live.scheduler.viewSchedule': viewSchedule
        } = translations;
        const numberOfDevices = schedule?.deviceIds?.length;
        const numberOfDays = schedule?.scheduleDays?.length;
        const numberOfAddons = schedule?.addons?.length;
        const items = [
          schedule?.title,
          !numberOfDevices
            ? null
            : numberOfDevices === 1
            ? numberOfDevices + ' ' + device
            : numberOfDevices + ' ' + devices,
          !numberOfDays ? null : numberOfDays === 1 ? numberOfDays + ' ' + day : numberOfDays + ' ' + days,
          !numberOfAddons ? null : numberOfAddons === 1 ? numberOfAddons + ' ' + aiApp : numberOfAddons + ' ' + aiApps
        ].filter(elements => !!elements);
        this.headerState = {
          title: this.route.snapshot.queryParams?.viewMode ? viewSchedule : editSchedule,
          items
        };
        return schedule;
      }),
      shareReplay(1)
    );

  public scheduleAttributes$ = this.schedule$.pipe(
    filter(schedule => !!schedule),
    map(schedule => {
      const schedules = this.parseSchedules(schedule?.expressions) || [];
      return {
        devices: schedule?.deviceIds,
        name: schedule?.title || '',
        schedules,
        scheduleExpressionTimezone: schedule?.scheduleExpressionTimezone,
        addons: schedule?.addons.map(addon => addon?.id) || [],
        recurring: schedule.isRecurring,
        oneOffStartDate: schedule.isRecurring
          ? null
          : this.getOneOffStartDate(schedule?.expressions[0]?.startCronExpression),
        isSnapshot: schedule.isSnapshot,
        snapshotConfig: schedule.snapshotConfig,
        scheduleDays: schedule.scheduleDays,
        id: schedule?.id
      };
    }),
    shareReplay(1)
  );
  public isExecutingAction$: Observable<boolean> = this.scheduleFacadeService.isExecutingAction$;
  public headerState: HeaderState = {title: null, items: []};

  constructor(
    private userStoreFacadeService: UserStoreFacadeService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private scheduleFacadeService: StreamScheduleFacadeService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.scheduleFacadeService.loadSingleSchedule(this.route.snapshot.params.scheduleId);
    this.handleViewMode();
  }

  private getOneOffStartDate(startCronExpression: string) {
    const startCronValues = startCronExpression ? this.cronToDate(this.cronToValues(startCronExpression)) : null;
    return this.getPastMonday(startCronValues ? startCronValues : new Date());
  }

  public handleViewMode() {
    if (this.route.snapshot.queryParams?.viewMode) {
      this.devices$ = this.schedule$.pipe(
        filter(schedule => !!schedule),
        withLatestFrom(this.currentUserDevicesObject$),
        map(([schedule, devicesObject]) => schedule.deviceIds.map(deviceId => devicesObject[deviceId]))
      );

      return;
    }
    this.isAdmin$ = this.userStoreFacadeService.currentRole$.pipe(
      filter(role => !!role),
      map(role => role === TeamRole.admin),
      shareReplay(1)
    );

    this.devices$ = this.userStoreFacadeService.currentUserDevices$.pipe(
      map(devices => devices?.filter(device => device?.sourceUrl)),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public cronToDate({
    minute,
    hour,
    dayOfWeek,
    dayOfMonth,
    month
  }: {
    minute: number;
    hour: number;
    dayOfWeek: number;
    dayOfMonth?: number;
    month?: number;
  }) {
    const currentDate = new Date();
    currentDate.setDate(dayOfMonth ? dayOfMonth : currentDate.getDate() + ((dayOfWeek - currentDate.getDay() + 7) % 7));
    currentDate.setHours(hour);
    currentDate.setMinutes(minute);
    if (month) {
      currentDate.setMonth(month);
    }
    return currentDate;
  }

  public parseSchedules(schedules: ScheduleCronExpression[]) {
    return schedules?.map((expression, index) => {
      const startCronValues = this.cronToValues(expression?.startCronExpression);
      return {
        [startCronValues.dayOfWeek]: {
          startTime: this.cronToDate(startCronValues),
          startCronId: expression.startCronId,
          endTime: this.cronToDate(this.cronToValues(expression.endCronExpression)),
          endCronId: expression.startCronId
        }
      };
    });
  }

  public cronToValues(cronExpression: string): {
    minute: number;
    hour: number;
    dayOfWeek: number;
    dayOfMonth?: number;
    month?: number;
  } {
    const regex = /cron\((.*?)\)/;
    const match = cronExpression.match(regex);
    let cron = null;
    const values = {
      minute: null,
      hour: null,
      dayOfWeek: null
    };

    cron = match[1].replace('cron(', '').replace(')', '').split(' ');
    if (match.length > 0 && cron[4] !== '?') {
      values['minute'] = cron[0];
      values['hour'] = cron[1];
      values['dayOfWeek'] = parseInt(cron[4]) - 1;
    } else {
      const startCron = new Date(
        parseInt(cron[5]),
        parseInt(cron[3]) - 1, //https://stackoverflow.com/questions/12254333/javascript-is-creating-date-wrong-month
        parseInt(cron[2]),
        parseInt(cron[1]),
        parseInt(cron[0])
      );
      values['dayOfMonth'] = startCron.getDate();
      values['dayOfWeek'] = startCron.getDay();
      values['minute'] = cron[0];
      values['hour'] = cron[1];
      values['month'] = startCron.getMonth();
    }
    return values;
  }

  public goBack(): void {
    this.router.navigate([`secure/live/scheduler`]);
  }

  public editSchedule(form: any, streamKey: string) {
    const updateParams: BatchUpdateSchedule = {
      deviceIds: form.devices,
      schedules: form.schedules,
      name: form.name,
      streamKey: streamKey,
      scheduleExpressionTimezone: form.scheduleExpressionTimezone,
      addons: form.addons?.map(addonId => ({id: addonId})),
      recurring: form?.type === ScheduleType.RECURRING,
      mode: form.mode
    };
    if (form.type === ScheduleType.ONEOFF) {
      updateParams['oneOffStartDate'] = form.oneOffStartDate;
    }
    if (form.mode === ScheduleMode.SNAPSHOT) {
      updateParams['imageSnapshotFrecuency'] = form.imageSnapshotFrecuency;
      updateParams['imageSnapshotFrecuencyUnits'] = form.imageSnapshotFrecuencyUnits;
    }
    this.scheduleFacadeService.updateSchedule(this.route.snapshot.params.scheduleId, updateParams);
  }

  public getPastMonday(date): Date {
    const dayOfWeek = date.getDay();
    const daysToSubtract = (dayOfWeek + 6) % 7;
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - daysToSubtract);
    return date;
  }

  public deleteSchedule(scheduleName: string, scheduleId: string): void {
    this.scheduleFacadeService.deleteSchedules(scheduleName, scheduleId);
  }
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Map} from 'leaflet';
import {LayersVisibilityService} from '../services/layers-visibility.service';

@Directive({
  selector: '[ulShowOnlyThisLayer]',
  standalone: true
})
export class ShowOnlyThisLayerDirective {
  @Input('ulShowOnlyThisLayer') public asset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(private layersVisibilityService: LayersVisibilityService) {}

  @HostListener('click', ['$event'])
  public showOnlyThisLayer(): void {
    this.layersVisibilityService.showOnlyThisLayer(this.asset, this.map);
  }
}

<button mat-menu-item class="layer">
  <span class="layer__group full-width">
    <span class="layer__label-container full-width" (click)="hasToBlockMenu ? '' : toggleSelectGroup(groupName)">
      <div class="layer__container">
        <div class="layer__container">
          <span class="layer__label">
            <mat-icon
              *ngIf="isExpanded; else folderCloseTemplate"
              class="layer__label-icon open-folder"
              [class]="customIconColorIndex >= 0 ? 'custom-icon-' + customIconColorIndex : ''"
              svgIcon="folder-open"
            ></mat-icon>
            <ng-template #folderCloseTemplate>
              <mat-icon
                [class]="customIconColorIndex >= 0 ? 'custom-icon-' + customIconColorIndex : ''"
                class="layer__label-icon folder"
                >folder</mat-icon
              >
            </ng-template>
            <div class="layer__asset-content">
              <span
                class="layer__asset-name"
                [class.short-name]="isListOnly"
                [class.layer__hidden]="!(isDisplaying$ | async)"
                data-test-id="asset-name"
              >
                {{ groupName }}
              </span>
              <mat-icon
                (click)="treeControl.toggleDescendants(node); $event.preventDefault(); $event.stopPropagation()"
                class="layer__arrow-icon"
                >{{ isExpanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon
              >
            </div></span
          >
        </div>
        <mat-checkbox
          class="layer__checkbox"
          [class.layer__hidden]="!(isDisplaying$ | async)"
          [class.short-name]="isListOnly"
          (click)="$event.preventDefault()"
          [disableRipple]="true"
          [checked]="isSelected$ | async"
        >
          <span
            class="layer__asset-name"
            [class.short-name]="isListOnly"
            [class.layer__hidden]="!(isDisplaying$ | async)"
            data-test-id="asset-name"
          >
            {{ groupName }}
          </span>
          <mat-icon
            (click)="treeControl.toggleDescendants(node); $event.preventDefault(); $event.stopPropagation()"
            class="layer__arrow-icon"
            >{{ isExpanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon
          >
        </mat-checkbox>
      </div>
      <button
        (click)="$event.stopPropagation()"
        mat-icon-button
        [disabled]="hasToBlockMenu"
        (click)="toggleIsDisplayingGroup()"
        [analyticsTrackerType]="events.ATLAS_LAYER_VISIBILITY"
        class="layer__visibility-button"
      >
        <mat-icon class="action-icon visibility" *ngIf="isDisplaying$ | async; else visibilityOffTemplate"
          >visibility</mat-icon
        >
        <ng-template #visibilityOffTemplate>
          <mat-icon class="action-icon visibility__off">visibility_off</mat-icon>
        </ng-template>
      </button>
    </span>
  </span>

  <span class="layer__group">
    <ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
      <button
        [disabled]="hasToBlockMenu || !!totalSelectedLayers"
        class="layer__more"
        mat-icon-button
        [matMenuTriggerFor]="moreOptionsMenu"
      >
        <mat-icon [class.action-icon--dark]="!hasToBlockMenu" class="action-icon__visible" inline="true">
          more_vert
        </mat-icon>
      </button>

      <mat-menu #moreOptionsMenu="matMenu">
        <ng-container *ngIf="isListOnly; else defaultTemplate">
          <button
            [disabled]="!(hasAllLayersLoaded$ | async)"
            class="layer__button"
            mat-menu-item
            [ulShowOnlyThisGroup]="groupName"
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="visible_layer"></mat-icon>
              {{ 'atlas.control.showOnlyThisGroup' | translate }}
            </span>
          </button>
          <button class="layer__button" mat-menu-item (click)="bringToFrontGroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_front</mat-icon>
              {{ 'atlas.control.front' | translate }}
            </span>
          </button>
          <button class="layer__button" mat-menu-item (click)="bringToBackGroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_back</mat-icon>
              {{ 'atlas.control.back' | translate }}
            </span>
          </button>
        </ng-container>
        <ng-template #defaultTemplate>
          <button class="layer__button" mat-menu-item (click)="ungroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">folder</mat-icon>
              {{ 'atlas.control.ungroup' | translate }}
            </span>
          </button>
          <button
            [disabled]="!(hasAllLayersLoaded$ | async)"
            class="layer__button"
            mat-menu-item
            [ulShowOnlyThisGroup]="groupName"
          >
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="visible_layer"></mat-icon>
              {{ 'atlas.control.showOnlyThisGroup' | translate }}
            </span>
          </button>
          <button class="layer__button" mat-menu-item [matMenuTriggerFor]="colorMenu">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">color_lens</mat-icon>
              {{ 'atlas.control.color' | translate }}
            </span>
          </button>
          <mat-menu class="color-list" #colorMenu="matMenu">
            <div
              *ngFor="let color of availableColors; let i = index"
              class="color-list__item"
              [style.backgroundColor]="color"
              (click)="changeGroupColor(i)"
            >
              <mat-icon class="color-list__check" *ngIf="i === customIconColorIndex">check</mat-icon>
            </div>
          </mat-menu>
          <button class="layer__button" mat-menu-item (click)="renameGroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__custom-icon" svgIcon="rename"></mat-icon>
              {{ 'atlas.control.rename' | translate }}
            </span>
          </button>
          <button class="layer__button" mat-menu-item (click)="bringToFrontGroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_front</mat-icon>
              {{ 'atlas.control.front' | translate }}
            </span>
          </button>
          <button class="layer__button" mat-menu-item (click)="bringToBackGroup(groupName)">
            <span class="layer__button-content">
              <mat-icon inline="true" class="layer__icon">flip_to_back</mat-icon>
              {{ 'atlas.control.back' | translate }}
            </span>
          </button>
        </ng-template>
      </mat-menu>
    </ng-container>
  </span>
</button>

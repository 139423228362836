import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EXPORT_FORMAT} from '@app/jobs/models/export-task.model';

@Component({
  selector: 'unleash-export-task',
  templateUrl: './export-task.component.html',
  styleUrls: ['./export-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportTaskComponent {
  protected readonly EXPORT_FORMAT = EXPORT_FORMAT;
  public form: FormGroup = this.fb.group({
    format: [false, Validators.required],
    content: this.fb.group({
      lines: [false],
      markers: [false],
      polygons: [false]
    })
  });

  constructor(public dialogRef: MatDialogRef<ExportTaskComponent>, private fb: FormBuilder) {}

  public emitClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(form: FormGroup) {
    this.dialogRef.close(form.value);
  }
}

/* eslint-disable rxjs/finnish */
import {Injectable} from '@angular/core';
import {Auth} from '@aws-amplify/auth';
import {select, Store} from '@ngrx/store';
import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync';
import gql from 'graphql-tag';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged, filter, pluck, take} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {NotificationListQuery, NotificationModel, NotificationState} from '../../models/api/notifications.model';
import {
  actionLoadMoreNotifications,
  actionLoadNotifications,
  actionLoadNotificationsFailure,
  actionLoadNotificationsSuccess,
  actionNewNotification,
  actionRemoveNotification,
  actionSetNotificationAsRead,
  actionShowNotificationProgressUpdate,
  actionUnreadCountLoaded,
  actionUpdateNotification
} from '../../notifications/notifications.actions';
import {selectNotifications, selectUnreadNotificationsCount} from '../../notifications/notifications.selectors';
import {UserService} from '../api/user.service';
import {EVENTS, UnleashAnalyticsService} from '../unleash-analytics.service';
import {
  deleteNotificationQuery,
  getUnreadCount,
  listNotificationQuery,
  newNotificationSubscription,
  notificationDeletedSubscription,
  notificationUpdatedSubscription,
  updateNotificationQuery
} from './notifications.queries';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public nextToken = null;
  public nextToken$: Observable<string>;
  public unreadNotifications$: Observable<number> = this.store.pipe(select(selectUnreadNotificationsCount));

  private _nextToken: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private newNotificationsSubscription$: ZenObservable.Subscription;
  private notificationsUpdatedSubscription$: ZenObservable.Subscription;
  private notificationsDeletedSubscription$: ZenObservable.Subscription;

  private userId: string;
  private client: AWSAppSyncClient<any>;

  constructor(
    public userService: UserService,
    private unleashAnalyticsService: UnleashAnalyticsService,
    private store: Store
  ) {
    this.generateNotificationsAwsAppsyncClient();

    this.nextToken$ = this._nextToken.asObservable();

    this.userService.user$.pipe(pluck('id'), distinctUntilChanged(), filter(Boolean)).subscribe((userId: string) => {
      this.userId = userId;
      this.store.dispatch(actionLoadNotifications());

      if (!this.newNotificationsSubscription$) {
        this.newNotificationsSubscription$ = this.client
          .subscribe({query: gql(newNotificationSubscription(userId))})
          .subscribe(
            result => {
              console.info(`Loaded New Notifications`, result.data.newNotifications);
              if (!!result && !!result && !!result.data && !!result.data.newNotifications) {
                this.store.dispatch(actionNewNotification({payload: result.data.newNotifications}));
                this.unleashAnalyticsService.logEvent(EVENTS.NEW_NOTIFICATION);
              }
            },
            error => {
              this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(error)}}));
            }
          );
      }

      if (!this.notificationsUpdatedSubscription$) {
        this.notificationsUpdatedSubscription$ = this.client
          .subscribe({query: gql(notificationUpdatedSubscription(userId))})
          .subscribe(
            result => {
              console.info(`Loaded Updated Notifications`, result.data.updatedNotifications);
              if (!!result && !!result && result.data && result.data.updatedNotifications) {
                const updatedNotification = result.data.updatedNotifications;
                this.store.dispatch(actionUpdateNotification({payload: updatedNotification}));
                if (updatedNotification.state === NotificationState.FINISH) {
                  this.store.dispatch(
                    actionShowNotificationProgressUpdate({
                      payload: updatedNotification
                    })
                  );
                }
              }
            },
            error => {
              this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(error)}}));
            }
          );
      }
      if (!this.notificationsDeletedSubscription$) {
        this.notificationsDeletedSubscription$ = this.client
          .subscribe({query: gql(notificationDeletedSubscription(userId))})
          .subscribe(
            result => {
              const deletedNotifications = result?.data?.deletedNotifications;
              if (deletedNotifications) {
                console.info(`Loaded deleted Notifications`, deletedNotifications);
                this.store.dispatch(actionRemoveNotification({payload: deletedNotifications}));
              }
            },
            error => {
              this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(error)}}));
            }
          );
      }
    });
  }

  public generateNotificationsAwsAppsyncClient() {
    this.client = new AWSAppSyncClient({
      url: environment.APPSYNC_NOTIFICATIONS_URI,
      disableOffline: true,
      region: environment.region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      }
    });
  }

  public async getNotInProgressNotifications(): Promise<void> {
    this.getNotifications(false);
  }

  public async getInProgressNotifications(): Promise<void> {
    this.getNotifications(true);
  }

  public async getNotifications(isInProgress: boolean): Promise<void> {
    try {
      const PAGE_LIMIT = 15;
      console.info('Getting new notifications ' + (!isInProgress ? 'not ' : ' ') + 'in progress');
      const statement = listNotificationQuery(this.userId, isInProgress, PAGE_LIMIT);
      const response = (await this.client.query({query: gql(statement)})) as any;
      const listResponse = response.data.list as NotificationListQuery;
      this.store.dispatch(actionLoadNotificationsSuccess({payload: listResponse.items}));
      this.nextToken = listResponse.nextToken;
      this._nextToken.next(listResponse.nextToken);
    } catch (err) {
      console.error(err);
      this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(err)}}));
    }
  }

  public async getUnreadNotificationsCounter(): Promise<void> {
    try {
      const statement = getUnreadCount(this.userId);
      const response = (await this.client.query({query: gql(statement)})) as any;
      const count = response.data.getUnreadCount.count as number;
      this.store.pipe(take(1), select(selectUnreadNotificationsCount)).subscribe(counterFromStore => {
        if (count < counterFromStore) {
          this.store.dispatch(actionUnreadCountLoaded({payload: count}));
        }
      });
    } catch (err) {
      console.error(err);
      this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(err)}}));
    }
  }

  public markAllNotificationsAsSeen(): void {
    this.store.pipe(select(selectNotifications)).subscribe(notifications => {
      notifications.forEach(n => {
        if (!n.isRead) {
          this.store.dispatch(actionSetNotificationAsRead({payload: n}));
        }
      });
    });
  }

  public async setNotificationAsRead(notification: NotificationModel): Promise<void> {
    const statement = updateNotificationQuery(notification);
    await this.client.mutate({mutation: gql(statement)});
  }

  public async setNotificationAsUnread(notification: NotificationModel): Promise<void> {
    notification.isRead = false;
    const statement = updateNotificationQuery(notification);
    await this.client.mutate({mutation: gql(statement)});
  }

  public deleteNotification(notification: NotificationModel): void {
    try {
      const statement = deleteNotificationQuery(notification);
      this.client.mutate({mutation: gql(statement)});
      this.store.dispatch(actionRemoveNotification({payload: notification}));
    } catch (e) {
      this.store.dispatch(actionLoadNotificationsFailure({payload: {error: JSON.stringify(e)}}));
    }
  }

  public showMoreNotifications(): void {
    this.store.dispatch(actionLoadMoreNotifications({inProgress: true}));
  }

  public updateIsInProgressNotification(notification: NotificationModel): void {
    this.store.dispatch(actionNewNotification({payload: notification}));
    this.unleashAnalyticsService.logEvent(EVENTS.NEW_NOTIFICATION);
  }

  public getNextToken$(): Observable<string> {
    return this.nextToken$;
  }
}

export interface CreateNotificationMutation {
  __typename: string;
  id?: string;
  createdAt?: string;
  userId: string;
  data: string;
  status: string;
  source: string;
  from: string;
}

export interface OnCreateNotificationSubscription {
  id?: string;
  createdAt?: string;
  userId: string;
  data: any;
}

import {Observable, map, tap} from 'rxjs';
import {ImagePath} from '../models/gui/image-path';
import {LinkMenuItem} from '../models/gui/link-menu-item';
import {MenuItem} from '../models/gui/menu-item';
import {NewTabMenuItem} from '../models/gui/newtab-menu-item';
import {MediaObserver} from '@angular/flex-layout';
import {Injectable} from '@angular/core';
import {AclPermissions} from '../models/api/acl.model';

@Injectable({
  providedIn: 'root'
})
export class SideNavMenuService {
  public MENU_ITEMS = [];

  public isMobileView$: Observable<boolean> = this.observableMedia.asObservable().pipe(
    map(change => {
      const indexFirstPriorityMedia = 0;
      const mediaAlias = change[indexFirstPriorityMedia].mqAlias;
      const isMobileView = mediaAlias === 'sm' || mediaAlias === 'xs';
      return isMobileView;
    }),
    tap(isMobileView => {
      if (isMobileView) {
        this.MENU_ITEMS = this.MENU_ITEMS_MOBILE_LIST;
        return;
      }
      this.MENU_ITEMS = [
        new LinkMenuItem({
          label: 'core.sidebar.menu.stream',
          imagePath: ImagePath.LIVE_STREAM_ICON_IMG_PATH,
          routerLink: '/secure/live',
          aclPermission: AclPermissions.LivestreamApiStreamRead
        }),
        new LinkMenuItem({
          label: 'core.sidebar.menu.library',
          imagePath: ImagePath.LIBRARY_ICON_IMG_PATH,
          routerLink: '/secure/library',
          aclPermission: AclPermissions.LibraryApiRead
        }),
        new LinkMenuItem({
          label: 'core.sidebar.menu.atlas',
          imagePath: ImagePath.FUSION_ATLAS_ICON_IMG_PATH,
          routerLink: '/secure/atlas',
          aclPermission: AclPermissions.AtlasApiRead
        }),
        new LinkMenuItem({
          label: 'core.sidebar.menu.analysis',
          imagePath: ImagePath.ANALYSIS_ICON_IMG_PATH,
          routerLink: '/secure/analysis',
          aclPermission: AclPermissions.ReportApiRead
        }),
        new LinkMenuItem({
          label: 'core.sidebar.menu.remoteCockpit',
          imagePath: ImagePath.MISSION_CONTROL_ICON_IMG_PATH,
          routerLink: '/secure/flights/cockpit',
          aclPermission: AclPermissions.MissionsApiRead
        })
      ];
    })
  );

  public MENU_ITEMS_MOBILE = [
    new LinkMenuItem({
      label: 'core.sidebar.menu.stream',
      imagePath: ImagePath.LIVE_STREAM_ICON_IMG_PATH,
      routerLink: '/secure/live',
      aclPermission: AclPermissions.LivestreamApiStreamRead
    }),
    new LinkMenuItem({
      label: 'core.sidebar.menu.library',
      imagePath: ImagePath.LIBRARY_ICON_IMG_PATH,
      routerLink: '/secure/library',
      aclPermission: AclPermissions.LibraryApiRead
    }),
    new LinkMenuItem({
      label: 'core.sidebar.menu.atlas',
      imagePath: ImagePath.FUSION_ATLAS_ICON_IMG_PATH,
      routerLink: '/secure/atlas',
      aclPermission: AclPermissions.AtlasApiRead
    }),
    new LinkMenuItem({
      label: 'core.sidebar.menu.account',
      imagePath: ImagePath.ACCOUNT_ICON_IMG_PATH,
      routerLink: '/secure/profile'
    })
  ];

  public MENU_ITEMS_MOBILE_LIST = [
    new LinkMenuItem({
      label: 'core.sidebar.menu.remoteCockpit',
      imagePath: ImagePath.MISSION_CONTROL_ICON_IMG_PATH,
      routerLink: '/secure/flights/cockpit',
      aclPermission: AclPermissions.MissionsApiRead
    }),
    new LinkMenuItem({
      label: 'core.sidebar.menu.analysis',
      imagePath: ImagePath.ANALYSIS_ICON_IMG_PATH,
      routerLink: '/secure/analysis',
      aclPermission: AclPermissions.ReportApiRead
    })
  ];

  public AI_APPS_STORE_MENU_ITEMS = new LinkMenuItem({
    label: 'core.sidebar.menu.aiAppStore',
    imagePath: ImagePath.AI_APPS_STORE_ICON_IMG_PATH,
    routerLink: '/secure/store',
    queryParams: {
      clearFilters: true
    },
    aclPermission: AclPermissions.AddonApiRead
  });

  public MENU_ITEMS_JOBS = new LinkMenuItem({
    label: 'core.sidebar.menu.jobs',
    imagePath: ImagePath.JOBS,
    routerLink: '/secure/jobs',
    aclPermission: AclPermissions.JobsApiListByTeamId
  });

  public MENU_ITEMS_END = [
    new NewTabMenuItem({
      label: 'core.sidebar.menu.notifications',
      imagePath: ImagePath.NOTIFICATIONS,
      url: ''
    }),
    new NewTabMenuItem({
      label: 'core.sidebar.menu.help',
      imagePath: ImagePath.HELP_ICON_IMG_PATH,
      url: 'https://knowledge.unleashlive.com/'
    })
  ];

  public DEVELOPER_DASHBOARD_MENU_ITEM: MenuItem[] = [
    new LinkMenuItem({
      label: 'core.sidebar.menu.developer-dashboard',
      imagePath: ImagePath.DEVELOPER_DASHBOARD_IMG_PATH,
      routerLink: '/secure/developer-dashboard',
      aclPermission: AclPermissions.MainApiUpdateUser
    })
  ];

  constructor(private observableMedia: MediaObserver) {}
}

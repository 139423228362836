<section class="item" *ngIf="asset$ | async as asset">
  <button
    mat-menu-item
    class="layer"
    [class.device]="asset.deviceIcon"
    [class.highlighted]="asset.isHighlighted && (hasToRemoveAssetsHiglight | async) === false"
  >
    <span class="layer__group full-width">
      <span class="layer__label-container full-width" (click)="hasToBlockMenu ? '' : toggleSelect(asset)">
        <div class="layer__container--two-rows">
          <div class="layer__container">
            <div class="layer__container">
              <mat-icon class="layer__nested-icon" *ngIf="asset.groupName" svgIcon="nested-layer"></mat-icon>
              <div class="layer__container">
                <span class="layer__label">
                  <ng-container
                    *ngIf="asset?.customColorIndex >= 0 && asset.type === assetType.GEOJSON; else defaultIconTemplate"
                  >
                    <mat-icon
                      *ngIf="!asset.deviceIcon; else deviceIconTemplate"
                      inline="true"
                      [class.layer__hidden]="hasToHideLayer(asset)"
                      class="layer__label-icon"
                      [class]="'custom-icon-' + asset.customColorIndex"
                      svgIcon="custom-layer"
                    ></mat-icon>
                  </ng-container>
                  <span
                    class="layer__asset-name"
                    [class.short-name]="isListOnly"
                    [class.layer__hidden]="hasToHideLayer(asset)"
                    data-test-id="asset-name"
                  >
                    {{ asset.name }}
                  </span>
                  <ng-template #defaultIconTemplate>
                    <mat-icon
                      *ngIf="asset.hasState; else geojsonIconTemplate"
                      inline="true"
                      [class.layer__hidden]="hasToHideLayer(asset)"
                      class="layer__label-icon"
                      svgIcon="in-progress-marker"
                    ></mat-icon>
                    <ng-template #geojsonIconTemplate>
                      <mat-icon
                        *ngIf="!asset.deviceIcon; else deviceIconTemplate"
                        inline="true"
                        [class.layer__hidden]="hasToHideLayer(asset)"
                        class="layer__label-icon"
                        [class]="'custom-icon-' + asset.customColorIndex"
                        >{{ asset.iconName }}</mat-icon
                      >
                    </ng-template>
                  </ng-template>
                  <ng-template #deviceIconTemplate>
                    <img class="layer__device-icon" [src]="asset.deviceIcon" />
                  </ng-template>
                </span>
              </div>
            </div>
            <mat-checkbox
              class="layer__checkbox"
              (click)="$event.preventDefault()"
              [disableRipple]="true"
              [checked]="asset.isSelected"
              [class.layer__hidden]="hasToHideLayer(asset)"
              [class.short-name]="isListOnly"
              >{{ asset.name }}</mat-checkbox
            >
          </div>
          <span
            class="layer__asset-created-date"
            [class.layer__hidden]="hasToHideLayer(asset)"
            data-test-id="asset-created-date"
          >
            {{ 'atlas.layerControl.created' | translate }} {{ asset.createdAt | date: "dd MMM '''yy, h:mm:ss a" }}
          </span>
        </div>
        <button
          *ngIf="headerStatus !== atlasHeaderStatus.SELECT_MARKERS && headerStatus !== atlasHeaderStatus.EDIT_JOB_SHAPE"
          mat-icon-button
          [ulSaveLayerVisibility]="asset"
          [map]="map"
          [analyticsTrackerType]="events.ATLAS_LAYER_VISIBILITY"
          [disabled]="hasToBlockMenu"
          class="layer__visibility-button"
        >
          <ng-container *ngIf="asset.isDisplaying; else visibilityOffTemplate">
            <mat-icon class="action-icon visibility">visibility</mat-icon>
          </ng-container>
          <ng-template #visibilityOffTemplate>
            <mat-icon class="action-icon visibility__off">visibility_off</mat-icon>
          </ng-template>
        </button>
      </span>
    </span>

    <button
      class="menu-icon"
      mat-icon-button
      (click)="emitOpenLayerControlItemMenu($event)"
      [attr.data-test-id]="'asset-more-options-icon-menu-' + asset.id"
    >
      <mat-icon
        *ngIf="asset.bounds; else loading"
        [class.action-icon--dark]="!hasToBlockMenu"
        class="action-icon__visible"
        inline="true"
        >more_vert</mat-icon
      >
      <ng-template #loading>
        <unleash-spinner diameter="20"></unleash-spinner>
      </ng-template>
    </button>
  </button>
  <button
    class="properties"
    *ngIf="asset?.searchResult?.properties?.length as totalProperties"
    mat-button
    color="primary"
    (click)="emitOpenSearchResults(asset)"
  >
    {{ totalProperties }}
    {{ totalProperties === 1 ? ('atlas.layerControl.match' | translate) : ('atlas.layerControl.matches' | translate) }}
    &#62;
  </button>
</section>

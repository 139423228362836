import {LinkMenuItem} from '@app/core/models/gui/link-menu-item';
import {MenuItem} from '@app/core/models/gui/menu-item';
import {createFeatureSelector, createReducer, on} from '@ngrx/store';
import * as actions from './sidenav-menu.actions';

export const rootReducerKey = 'sidenavMenu';

export interface State {
  sidebarMenu: MenuItem[];
  sidebarMenuProfileItem: LinkMenuItem;
  sidebarMenuLastTopIndex: number;
}

const initialState: State = {
  sidebarMenu: [],
  sidebarMenuProfileItem: new LinkMenuItem({
    label: 'core.sidebar.menu.account',
    imagePath: null,
    routerLink: '/secure/profile'
  }),
  sidebarMenuLastTopIndex: 5
};

export const selectState = createFeatureSelector<State>(rootReducerKey);

export const reducer = createReducer(
  initialState,
  on(actions.setMenuItems, (state: State, action: {payload: {menuItems: MenuItem[]}}) => {
    return {...state, sidebarMenu: action.payload.menuItems};
  }),
  on(actions.setLastTopIndex, (state: State, action: {payload: {index: number}}) => {
    return {...state, sidebarMenuLastTopIndex: action.payload.index};
  }),
  on(actions.setProfileMenuItem, (state: State, action: {payload: {profileMenuItem: LinkMenuItem}}) => {
    return {...state, sidebarMenuProfileItem: action.payload.profileMenuItem};
  })
);

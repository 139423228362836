import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {MoveMarkerDestination} from '../components/move-markers-dialog/move-markers-dialog.component';
import {AtlasSelectMarkersService} from '../services/atlas-select-markers.service';
import {firstValueFrom, take} from 'rxjs';
import {AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {GenericDialogComponent} from '@app/shared/generic-dialog/generic-dialog/generic-dialog.component';
import {Map} from 'leaflet';

@Directive({
  selector: '[ulMoveMarkers]'
})
export class MoveMarkersDirective {
  @Input('ulMoveMarkers') public destination: MoveMarkerDestination;
  @Input() public layerName: string;
  @Input() public currentLayer: AtlasGeojsonAssetModel;
  @Input() public existingLayer: AtlasGeojsonAssetModel;
  @Input() public map: Map;

  @Output() public showSpinner: EventEmitter<boolean> = new EventEmitter();

  private warningDialog: MatDialogRef<GenericDialogComponent>;

  @HostListener('click', ['$event'])
  public moveMarkers() {
    if (this.elementRef.nativeElement.hasAttribute('disabled')) {
      return;
    }
    this.elementRef.nativeElement.classList.add('mat-button-disabled');
    this.elementRef.nativeElement.setAttribute('disabled', 'true');
    this.showSpinner.emit(true);
    this.destination === MoveMarkerDestination.NEW_LAYER ? this.createNewLayer() : this.addToExistingLayer();
  }

  private async createNewLayer(): Promise<void> {
    const currentLayerFeatures = this.atlasSelectMarkersService.getUpdatedCurrentLayer(this.currentLayer);
    if (currentLayerFeatures.length === 0) {
      this.openWarningDialog(true);
      return;
    }
    this.atlasSelectMarkersService.avoidLoadLocalStorageAssets(true);
    const parameters = {
      layerName: this.layerName,
      currentLayer: this.currentLayer,
      groupName: null,
      isInProgress: false
    };
    await firstValueFrom(this.atlasSelectMarkersService.moveMarkers(this.currentLayer));
    await this.atlasSelectMarkersService.createNewMarkersLayer(parameters);
    this.atlasSelectMarkersService.removeSelectedMarkers(this.currentLayer, this.map);
    this.atlasSelectMarkersService.clearSelectedMarkers();
    this.atlasSelectMarkersService.setHasToUpdateAssets(true);
    this.atlasSelectMarkersService.setCurrentLayer(this.currentLayer);
    this.closeDialog();
  }

  private async addToExistingLayer(): Promise<void> {
    const currentLayerFeatures = this.atlasSelectMarkersService.getUpdatedCurrentLayer(this.currentLayer);
    if (currentLayerFeatures.length === 0) {
      this.openWarningDialog(false);
      return;
    }
    this.atlasSelectMarkersService.avoidLoadLocalStorageAssets(true);
    await firstValueFrom(this.atlasSelectMarkersService.moveMarkers(this.currentLayer, this.existingLayer));
    this.atlasSelectMarkersService.reGenerateSelectedMarkers(this.currentLayer, this.map);
    this.atlasSelectMarkersService.addMarkersToNewLayer(this.existingLayer);
    this.atlasSelectMarkersService.removeSelectedMarkers(this.currentLayer, this.map);
    this.atlasSelectMarkersService.clearSelectedMarkers();
    this.atlasSelectMarkersService.setHasToUpdateAssets(true);
    this.atlasSelectMarkersService.setCurrentLayer(this.currentLayer);
    this.closeDialog();
  }

  private openWarningDialog(hasToCreateNewLayer: boolean): void {
    this.showSpinner.emit(false);
    this.translateService
      .get('atlas.warningMoveMarkers')
      .pipe(take(1))
      .subscribe(translations => {
        this.warningDialog = this.dialog.open(GenericDialogComponent, {
          autoFocus: false,
          disableClose: true,
          width: '800px',
          data: {
            title: translations.title,
            paragraphs: [translations.content],
            actionName: translations.actionName
          }
        });
        this.warningDialog.componentInstance.hasToExecute.pipe(take(1)).subscribe(async () => {
          this.atlasSelectMarkersService.avoidLoadLocalStorageAssets(false);
          hasToCreateNewLayer
            ? await this.atlasSelectMarkersService.createLayerAndRemoveOld(this.layerName, this.currentLayer)
            : await this.atlasSelectMarkersService.updateLayerAndRemoveOld(this.existingLayer, this.currentLayer);
          this.atlasSelectMarkersService.clearSelectedMarkers();
          this.atlasSelectMarkersService.setHasToUpdateAssets(false);
          this.closeDialog();
        });
      });
  }

  private showSnackBar(snackbarMessage: string) {
    this.snackBar.open(snackbarMessage, null, {
      duration: 3000,
      panelClass: 'center'
    });
  }

  private closeDialog() {
    this.translateService
      .get(this.warningDialog ? 'atlas.moveMarkers.markersMovedAndRemove' : 'atlas.moveMarkers.markersMoved')
      .pipe(take(1))
      .subscribe(snackbarMessage => {
        if (this.warningDialog) {
          this.warningDialog.close();
          this.showSnackBar(snackbarMessage);
          return;
        }
        this.showSpinner.emit(false);
        this.showSnackBar(snackbarMessage);
      });
  }

  constructor(
    private atlasSelectMarkersService: AtlasSelectMarkersService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private elementRef: ElementRef
  ) {}
}

<div
  class="select-markers"
  [class.select-markers__disabled]="
    activeSelectedOption === selectMarkersOption.BOX ||
    activeSelectedOption === selectMarkersOption.POLYGON ||
    isEditJobMode
  "
  [style.top]="xPosition + 'px'"
>
  <span class="select-markers__title">{{ 'atlas.control.selectMarkers' | translate }}</span>
  <button
    [class.active]="activeSelectedOption === selectMarkersOption.BOX"
    class="select-markers__button"
    mat-button
    (click)="emitSelectByBox()"
  >
    <mat-icon svgIcon="atlas-box"></mat-icon>
    <span class="select-markers__text-button">{{ 'atlas.selectLayers.byBox' | translate }}</span>
  </button>
  <button
    [class.active]="activeSelectedOption === selectMarkersOption.POLYGON || isEditJobMode"
    class="select-markers__button"
    mat-button
    (click)="emitSelectByPolygon()"
  >
    <mat-icon svgIcon="atlas-polygon"></mat-icon>
    <span class="select-markers__text-button">{{ 'atlas.selectLayers.byPolygon' | translate }}</span>
  </button>
  <button
    *ngIf="layerType === AssetType.GEOJSON"
    [class.active]="activeSelectedOption === selectMarkersOption.CLICK"
    class="select-markers__button"
    mat-button
    (click)="emitSelectByClick()"
  >
    <mat-icon svgIcon="atlas-click"></mat-icon>
    <span class="select-markers__text-button">{{ 'atlas.selectLayers.byClick' | translate }}</span>
  </button>
  <ng-container *ngIf="totalSelectedMarkers">
    <mat-divider class="select-markers__divider"></mat-divider>
    <div class="select-markers__length">
      <span>{{ 'atlas.selectLayers.selected' | translate }}</span>
      {{ totalSelectedMarkers }}
    </div>
  </ng-container>
</div>

<div class="layout">
  <ng-container
    [ngSwitch]="displayedDevice.player"
    *ngFor="let displayedDevice of liveDevices$ | async; let index = index; trackBy: trackById"
  >
    <unleash-webrtc-player
      *ngIf="
        !unleashCustomWebRTCEnabled &&
        displayedDevice.player === streamPlayer.WEBRTC &&
        (!displayedDevice.selectedModel || displayedDevice?.selectedModel?.id === rawStream.id)
      "
      [autoStart]="true"
      [ngClass]="[
        (gridState$ | async)[displayedDevice.id],
        layoutState$ | async,
        (selectedDeviceId$ | async) === displayedDevice.id ? 'selected' : ''
      ]"
      (click)="setSelectedDevice(displayedDevice.id)"
      [deviceId]="displayedDevice.id"
      [agoraToken]="displayedDevice.agoraToken"
      [agoraTokenRefreshCallback]="webrtcService.getWebRTCSubscriberToken"
      [streamAppId]="streamAppId"
      [isShowingControls]="true"
      [isLiveStreaming]="true"
      (snapshot)="catchSnapshot($event)"
      class="unleash-webrtc-player streaming-player"
    ></unleash-webrtc-player>
    <unleash-whep-player
      *ngIf="
        unleashCustomWebRTCEnabled &&
        (streamKey$ | async) &&
        displayedDevice.player === streamPlayer.WEBRTC &&
        (!displayedDevice.selectedModel || displayedDevice?.selectedModel?.id === rawStream.id)
      "
      [ngClass]="[
        (gridState$ | async)[displayedDevice.id],
        layoutState$ | async,
        (selectedDeviceId$ | async) === displayedDevice.id ? 'selected' : ''
      ]"
      [streamKey]="streamKey$ | async"
      [deviceId]="displayedDevice.id"
      [webrtcServerUrl]="serverUrl"
      [isLiveStreaming]="true"
      [isShowingControls]="true"
      (snapshot)="catchSnapshot($event)"
      (click)="setSelectedDevice(displayedDevice.id)"
      class="unleash-webrtc-player streaming-player"
    ></unleash-whep-player>
    <div *ngIf="displayedDevice.player === streamPlayer.NONE">
      The playback has been turned off by sender. Livestream will be saved to your library.
    </div>
    <app-live-video-player
      class="streaming-player"
      *ngIf="
        !displayedDevice.player ||
        displayedDevice.player === streamPlayer.DEFAULT ||
        (displayedDevice.player === streamPlayer.WEBRTC &&
          displayedDevice?.selectedModel &&
          displayedDevice?.selectedModel?.id != rawStream.id)
      "
      [ngClass]="[
        (gridState$ | async)[displayedDevice.id],
        layoutState$ | async,
        (selectedDeviceId$ | async) === displayedDevice.id ? 'selected' : ''
      ]"
      (click)="setSelectedDevice(displayedDevice.id)"
      [isSelected]="(selectedDeviceId$ | async) === displayedDevice.id"
      [isFavourite]="displayedDevice.isFavourite"
      [device]="displayedDevice"
      [model]="displayedDevice.selectedModel || rawStream"
      [layoutState]="layoutState$ | async"
      [vrExperience]="displayedDevice.streamVR"
    >
    </app-live-video-player>
  </ng-container>
</div>

import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ScheduleService} from '@app/core/services/api/schedule.service';
import {EMPTY, catchError, take} from 'rxjs';

@Component({
  selector: 'unleash-delete-schedule-dialog',
  templateUrl: './delete-schedule-dialog.component.html',
  styleUrls: ['./delete-schedule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteScheduleDialogComponent {
  public isActionInProgress = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string; scheduleId: string},
    private scheduleService: ScheduleService
  ) {}

  public deleteSchedule(): void {
    this.isActionInProgress = true;
    this.scheduleService
      .delete(this.data.scheduleId)
      .pipe(
        take(1),
        catchError(error => {
          this.dialogRef.close({error: error});
          return EMPTY;
        })
      )
      .subscribe(data => this.dialogRef.close(data));
  }
}

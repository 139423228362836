import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {share} from 'rxjs/operators';
import {Role, Permission, RoleRequestParams, RoleModel, DefaultRoleId} from '../models/roles-management.model';

@Injectable({
  providedIn: 'root'
})
export class RolesApiService {
  private readonly endpoint = 'role';
  private readonly permissionEndpoint = 'permission';
  constructor(private apiGateway: ApiGateway) {}

  /**
   * List all available roles
   */
  public listRoles(): Observable<Role[]> {
    return this.apiGateway.get(this.endpoint).pipe(share());
  }

  /**
   * Create a new role
   * @param role Role data to create
   */
  public createRole(role: RoleRequestParams): Observable<Role> {
    return this.apiGateway.post(this.endpoint, null, role).pipe(share());
  }

  /**
   * Update an existing role
   * @param roleId ID of the role to update
   * @param role Updated role data
   */
  public updateRole(roleId: string, role: Partial<Role>): Observable<Role> {
    return this.apiGateway.patch(`${this.endpoint}/${roleId}`, {}, role).pipe(share());
  }

  /**
   * Delete a role
   * @param roleId ID of the role to delete
   */
  public deleteRole(roleId: string): Observable<void> {
    return this.apiGateway.delete(`${this.endpoint}/${roleId}`, {}).pipe(share());
  }

  /**
   * List all available permissions
   */
  public listPermissions(): Observable<Permission[]> {
    return this.apiGateway.get(this.permissionEndpoint).pipe(share());
  }
}

<div class="scheduler">
  <unleash-static-header
    (goBack)="goBack()"
    [title]="(headerState$ | async)?.title"
    [items]="(headerState$ | async)?.items"
  ></unleash-static-header>
  <mat-progress-bar *ngIf="isLoadingSchedules$ | async" mode="indeterminate"></mat-progress-bar>
  <div class="scheduler__load" *ngIf="isLoadingSchedules$ | async; else itemsLoadedTemplate">
    <unleash-spinner diameter="55" borderWidth="5" color="#ccc"></unleash-spinner>
  </div>
  <ng-template #itemsLoadedTemplate>
    <ng-container [ngSwitch]="schedulePageState$ | async">
      <ng-container *ngSwitchCase="schedulePageStaus.NEW_SCHEDULE">
        <div class="scheduler__new">
          <unleash-new-schedule
            *ngIf="userStreamKey$ | async as streamKey"
            [devices]="devices$ | async"
            [addons]="addons$ | async"
            [currentUserTimezone]="currentUserTimezone$ | async"
            [isAdmin]="isAdmin$ | async"
            (goToPrincipalPage)="goToPrincipalPage()"
            (createSchedule)="batchCreateSchedule($event, streamKey)"
          ></unleash-new-schedule>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="schedulePageStaus.LIST">
        <ng-container *ngIf="isAdmin$ | async">
          <section *ngIf="!isEditMode" class="scheduler-actions">
            <button (click)="newSchedule()" class="scheduler-actions__button" mat-button>
              <mat-icon class="scheduler-actions__icon">add</mat-icon>
              <span class="scheduler-actions__text">{{ 'live.scheduler.newSchedule' | translate }}</span>
            </button>
          </section>
        </ng-container>
        <section class="schedule-list">
          <div class="schedule-list__title">
            <section>
              <mat-icon class="schedule-list__icon" svgIcon="schedule"></mat-icon>
              {{ 'live.scheduler.schedules' | translate }}
            </section>
            <section>
              {{ (scheduleList$ | async)?.length }}
              {{
                (scheduleList$ | async)?.length === 1
                  ? ('live.scheduler.schedule' | translate)
                  : ('live.scheduler.schedules' | translate)
              }}
            </section>
          </div>
          <unleash-schedule-list
            [scheduleList]="(scheduleList$ | async) || []"
            [addons]="addonsByKey$ | async"
            [isAdmin]="isAdmin$ | async"
            (edit)="editSchedule($event)"
            (view)="viewSchedule($event)"
            (delete)="deleteSchedule($event)"
            (newSchedule)="newSchedule()"
          ></unleash-schedule-list>
        </section>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

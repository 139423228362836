import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {MenuItem} from './menu-item';
import {AclPermissions} from '../api/acl.model';

export class LinkMenuItem extends MenuItem {
  public routerLink: string;
  public type: MenuItemTypeEnum = MenuItemTypeEnum.link;

  constructor(menuParams: {
    label: string;
    imagePath: string;
    routerLink: string;
    aclPermission?: AclPermissions;
    fragment?: string;
    queryParams?: {};
    styleClass?: string;
  }) {
    super(
      menuParams.label,
      menuParams.imagePath,
      menuParams.styleClass,
      menuParams.fragment,
      menuParams.queryParams,
      menuParams.aclPermission
    );
    this.routerLink = menuParams.routerLink;
  }
}

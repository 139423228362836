import {ApplicationRef, Injectable} from '@angular/core';
import {AtlasAssetModel, AtlasGeojsonAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasUploadService} from '@app/shared/services/upload/atlas-upload.service';
import {Map, Marker} from 'leaflet';
import {BehaviorSubject, Observable, ReplaySubject, firstValueFrom, fromEvent, skip, take, tap} from 'rxjs';
import {AtlasService} from './atlas.service';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {SelectMarkersOption} from '../components/atlas-map/atlas-map.component';
import {GeojsonFeatureState} from '../model/marker.model';
import {defaultMissionRouteColor} from '../atlas.config';
import {v4 as uuidv4} from 'uuid';
import {JobUploadService} from '@app/shared/services/upload/job-upload.service';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';
import {DrawOnMapService} from '../components/draw-on-map.service';
import {JobDetailService} from '@app/jobs/services/job-detail.service';
import {JobsFacadeService} from '@app/jobs/services/jobs-facade.service';
declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class AtlasSelectMarkersService {
  public selectedMarkers = new BehaviorSubject([]);
  public drawControl;
  public drawnItems = new L.FeatureGroup();
  public hasToUpdateAssets = new ReplaySubject(1);
  public hasDrawnItems = new BehaviorSubject(false);
  public newShapeCreated = new BehaviorSubject(false);
  public hasToShowCreatedLayerAtTop: boolean = false;
  public selectMarkersCurrentOption = new BehaviorSubject(SelectMarkersOption.NONE);
  public createdAssets: AtlasGeojsonAssetModel[] = [];
  public currentLayer: BehaviorSubject<AtlasGeojsonAssetModel> = new BehaviorSubject<AtlasGeojsonAssetModel>(null);
  public shapeColorIndex = -1;
  public markersOnShape = [];
  public openSelectMarkersTime: Date = null;
  public editingShapes = [];
  public editingAsset = null;
  public editingShapesGeojson = [];
  public hasAddedEditChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private atlasUploadService: AtlasUploadService,
    private atlasService: AtlasService,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService,
    private jobUploadService: JobUploadService,
    private jobsApiService: JobsApiService,
    private drawOnMapService: DrawOnMapService,
    private jobDetailService: JobDetailService,
    private jobsFacadeService: JobsFacadeService,
    private appRef: ApplicationRef
  ) {}

  public setHasDrawnItems(hasDrawnItems: boolean): void {
    this.hasDrawnItems.next(hasDrawnItems);
  }

  public setHasAddedEditChanges(hasAddedEditChanges: boolean): void {
    this.hasAddedEditChanges.next(hasAddedEditChanges);
  }

  public setOpenSelectMarkersTime(openSelectMarkersTime: Date) {
    this.openSelectMarkersTime = openSelectMarkersTime;
  }

  public setCurrentLayer(currentLayer: AtlasGeojsonAssetModel) {
    this.currentLayer.next(currentLayer);
  }

  public async enableMarkerEvents() {
    if (!this.currentLayer.value) {
      return;
    }
    this.atlasService.setHasToBlockEvents(false);
  }

  public setHasToShowCreatedLayerAtTop(hasToShowCreatedLayerAtTop: boolean): void {
    this.hasToShowCreatedLayerAtTop = hasToShowCreatedLayerAtTop;
  }

  public getUpdatedCurrentLayer(layer: AtlasGeojsonAssetModel) {
    const currentLayerFeatures = (layer.geojson as any).features.map(feature => feature?.index);
    const selectedFeaturesSet = new Set(this.selectedMarkers.value.map(marker => marker.feature.index));
    const filteredFeatures = currentLayerFeatures.filter(feature => !selectedFeaturesSet.has(feature));
    return filteredFeatures;
  }

  public async createLayerAndRemoveOld(
    newLayerName: string,
    oldLayer: AtlasGeojsonAssetModel,
    map: Map
  ): Promise<void> {
    await firstValueFrom(this.removeLayer(oldLayer));
    const parameters = {
      layerName: newLayerName,
      currentLayer: oldLayer,
      groupName: null,
      isInProgress: false
    };
    await this.createNewMarkersLayer(parameters, map);
    this.removeSelectedMarkers(oldLayer);
    this.atlasService.removeOldAsset(oldLayer);
  }

  public async updateLayerAndRemoveOld(existingLayer: AtlasGeojsonAssetModel, oldLayer: AtlasGeojsonAssetModel) {
    await firstValueFrom(this.moveMarkers(this.currentLayer.value, existingLayer));
    await firstValueFrom(this.removeLayer(oldLayer));
    this.removeSelectedMarkers(oldLayer);
    this.addMarkersToNewLayer(existingLayer);
    this.atlasService.removeOldAsset(oldLayer);
  }

  public setHasToUpdateAssets(hasToUpdateAssets: boolean) {
    this.hasToUpdateAssets.next(hasToUpdateAssets);
  }

  public addMarkersToNewLayer(asset: AtlasGeojsonAssetModel) {
    this.selectedMarkers.value.map(marker => {
      this.atlasService.updateMarkerColor(asset, marker);
      return (asset.leafletLayer as any).addData(marker.toGeoJSON());
    });
  }

  public clearSelectedMarkers() {
    this.selectedMarkers.next([]);
    this.markersOnShape = [];
  }

  // eslint-disable-next-line rxjs/finnish
  public removeLayer(asset: AtlasGeojsonAssetModel): Observable<any> {
    const removeAssetParams = {
      assetId: asset.id,
      avoidUpdateCache: true
    };
    return this.atlasService.removeAsset(removeAssetParams);
  }

  // eslint-disable-next-line rxjs/finnish
  public moveMarkers(targetLayer: AtlasGeojsonAssetModel, destinationLayer?: AtlasGeojsonAssetModel): Observable<any> {
    const currentLayerFeatures = (targetLayer.geojson as any).features;
    const selectedFeaturesSet = new Set(this.selectedMarkers.value.map(marker => marker.feature?.index));
    const filteredFeatureIndexes = currentLayerFeatures
      .filter(feature => selectedFeaturesSet.has(feature?.index))
      .map(feature => feature?.index);
    return this.atlasService.moveMarkers(targetLayer.id, destinationLayer?.id, filteredFeatureIndexes).pipe(
      take(1),
      tap(response => {
        if (response.targetAsset) {
          targetLayer.key = response.targetAsset.key;
        }
        if (response.destinationAsset && destinationLayer) {
          destinationLayer.key = response.destinationAsset.key;
        }
      })
    );
  }

  public copyMarkers(targetLayer: AtlasGeojsonAssetModel, destinationLayer?: AtlasGeojsonAssetModel) {
    const currentLayerFeatures = (targetLayer.geojson as any).features;
    const selectedFeaturesSet = new Set(this.selectedMarkers.value.map(marker => marker.feature?.index));
    const filteredFeatureIndexes = currentLayerFeatures
      .filter(feature => selectedFeaturesSet.has(feature?.index))
      .map(feature => feature?.index);

    return this.atlasService.copyMarkers(targetLayer.id, destinationLayer.id, filteredFeatureIndexes).pipe(
      take(1),
      tap(response => {
        if (response.destinationAsset) {
          destinationLayer.key = response.destinationAsset.key;
        }
      })
    );
  }

  public avoidLoadLocalStorageAssets(avoidLoadLocalStorageAssets: boolean) {
    this.atlasService.setAvoidLoadLocalStorageAssets(avoidLoadLocalStorageAssets);
  }

  public reGenerateSelectedMarkers() {
    this.selectedMarkers.value.forEach(layer => {
      this.generateDefaultIcon(layer);
    });
  }

  public removeSelectedMarkers(layer: AtlasGeojsonAssetModel) {
    this.selectedMarkers.value.forEach(marker => {
      (layer.leafletLayer as any).removeLayer(marker);
    });
  }

  public async createNewMarkersLayer(
    {
      layerName,
      currentLayer,
      groupName,
      isInProgress
    }: {layerName: string; currentLayer: AtlasGeojsonAssetModel; groupName: string; isInProgress: boolean},
    map: Map
  ): Promise<void> {
    try {
      const selectedMarkers = this.selectedMarkers.value;
      if (!selectedMarkers.length) {
        throw new Error('No selected markers to process');
      }
      const features = selectedMarkers.map((marker, index) => {
        let feature;
        if (index === 0) {
          feature = {
            ...marker.feature,
            properties: {
              ...marker.feature?.properties,
              layerName: layerName,
              customGroupName: groupName
            }
          };
        }
        const properties = feature?.properties ? feature?.properties : marker.feature?.properties;
        feature = {
          ...marker.feature,
          properties: {
            ...properties,
            state: isInProgress ? GeojsonFeatureState.IN_PROGRESS : marker.feature?.properties?.state || null
          }
        };

        return feature;
      });

      const saveFeatureCollection = {
        features,
        type: 'FeatureCollection'
      };

      const file = this.createFile(JSON.stringify(saveFeatureCollection), `${layerName}.geojson`);
      await this.atlasUploadService.addToQueue([file]);

      const minimumTimeout = 5000;
      const timeOut = Math.max(minimumTimeout, file.size / 10000);

      await new Promise(resolve => setTimeout(resolve, timeOut));

      this.setHasToShowCreatedLayerAtTop(true);

      const createdAssets = await firstValueFrom(this.atlasService.getCreatedAssets());
      if (createdAssets.length === 0) {
        throw new Error('New asset not found');
      }

      const lastCreatedAsset = createdAssets[createdAssets.length - 1];
      this.updateAssetColor(currentLayer.color, isInProgress, lastCreatedAsset);
      this.addGeojsonFeatureIndexes(saveFeatureCollection);

      const leafletLayer = await this.geojsonAssetLoaderService.createLayer(
        saveFeatureCollection,
        {...lastCreatedAsset, color: currentLayer.color},
        map,
        true
      );

      const newLayer = {
        ...lastCreatedAsset,
        leafletLayer,
        bounds: leafletLayer.getBounds(),
        isDisplaying: false,
        isHighlighted: true,
        iconName: 'notes',
        geojson: leafletLayer.toGeoJSON(),
        groupName: groupName
      };

      newLayer.hasState = this.atlasService.hasAllMarkersStatusAssigned(newLayer);
      newLayer.color = newLayer.hasState ? null : currentLayer.color;
      newLayer.customColorIndex = newLayer.hasState
        ? -1
        : this.atlasService.availableColors.findIndex(color => color === currentLayer.color);

      this.createdAssets.push(newLayer);
      this.atlasService.addNewAssets(newLayer, currentLayer);
    } catch (error) {
      console.warn('Error during layer creation', error);
      throw error;
    }
  }

  public clearSelections(map: Map): void {
    this.drawnItems.eachLayer(layer => {
      map.removeLayer(layer);
      this.drawnItems.removeLayer(layer);
    });
    this.restoreMarkerIcons();
    this.clearSelectedMarkers();
    this.setHasDrawnItems(false);
  }

  public restoreMarkerIcons() {
    this.selectedMarkers.value.forEach(layer => {
      this.generateMarkerIcon(layer);
    });
  }

  public stopListenEditEvents(map: Map): void {
    map?.off(L.Draw.Event.EDITVERTEX);
  }

  public closeEditJobShapeWithoutSave(map: Map) {
    this.restoreMarkerIcons();
    this.setCurrentLayer(null);
    this.clearSelectedMarkers();
    this.restoreOriginalEditFeature();
    this.resetEditingShape();
    this.setHasAddedEditChanges(false);
    this.setHasDrawnItems(false);
    this.stopListenEditEvents(map);
    this.clearDrawnItems();
  }

  public clearDrawnItems(): void {
    this.drawnItems.clearLayers();
  }

  public discardJobsEdit(map: Map) {
    this.clearSelectedMarkers();
    this.restoreOriginalEditFeature();
    this.enableEditFeature(map);
    this.editingShapes.forEach(shape => {
      this.verifyMarkersInsideBox(shape, true);
    });
    this.setHasAddedEditChanges(false);
  }

  public closeEditJobShape(map: Map) {
    this.editingShapes.forEach(shape => {
      shape.editing.disable();
    });
    this.restoreMarkerIcons();
    this.setCurrentLayer(null);
    this.clearSelectedMarkers();
    this.resetEditingShape();
    this.setHasDrawnItems(false);
    this.stopListenEditEvents(map);
    this.clearDrawnItems();
    this.setHasAddedEditChanges(false);
  }

  public startDrawing(map: Map): void {
    this.newShapeCreated.next(false);
    this.createDrawControl(map);
    this.hideControlsBar();
    this.listenDrawEvents(map);
  }

  public selectMarkersByClick(): void {
    (this.currentLayer.value.leafletLayer as any).eachLayer((layer: any) => {
      if (layer instanceof L.Marker) {
        if (layer.options.icon?._group) {
          layer.getAllChildMarkers().forEach(child => {
            this.listenLayerOnClick(child);
          });
          return;
        }
        this.listenLayerOnClick(layer);
      }
    });
  }

  public disableSelectMarkersByClick(): void {
    if (!this.currentLayer.value) {
      return;
    }
    (this.currentLayer.value.leafletLayer as any).eachLayer((layer: any) => {
      if (layer instanceof L.Marker) {
        if (layer.options.icon?._group) {
          layer.getAllChildMarkers().forEach(child => {
            child.off('click');
          });
          return;
        }
        layer.off('click');
      }
    });
  }

  public clearControl(map: Map): void {
    if (this.drawControl) {
      map?.removeControl(this.drawControl);
      map?.off(L.Draw.Event.DRAWSTOP);
      map?.off(L.Draw.Event.CREATED);
    }
  }

  public setSelectMarkersCurrentOption(selectMarkersCurrentOption: SelectMarkersOption) {
    this.selectMarkersCurrentOption.next(selectMarkersCurrentOption);
  }

  public addCreatedAssets() {
    if (this.createdAssets.length === 0) {
      return;
    }
    this.setHasToShowCreatedLayerAtTop(true);
    this.createClickListener();
  }

  private addGeojsonFeatureIndexes(saveFeatureCollection): void {
    saveFeatureCollection.features.forEach((feature, i) => {
      feature.index = i;
    });
  }

  private updateAssetColor(color: string, isInProgressState: boolean, lastCreatedAsset: AtlasGeojsonAssetModel) {
    if (!color || isInProgressState) {
      return;
    }
    this.atlasService.updateAsset(lastCreatedAsset, {color}, true).pipe(take(1)).subscribe();
  }

  private createClickListener() {
    fromEvent(window, 'click')
      .pipe(skip(1), take(1))
      .subscribe(() => {
        this.atlasService.setHasToRemoveAssetsHiglight(true);
      });
  }

  private listenLayerOnClick(layer: L.Marker): void {
    layer.on('click', () => {
      const layerIndex = this.selectedMarkers.value.indexOf(layer);
      if (layerIndex === -1) {
        this.selectedMarkers.next([...new Set([...this.selectedMarkers.value, layer])]);
        this.generateMarkerIcon(layer, 'assets/icons/atlas/selected-marker.svg');
        return;
      }
      this.selectedMarkers.value.splice(layerIndex, 1);
      this.selectedMarkers.next(this.selectedMarkers.value);
      this.generateMarkerIcon(layer);
    });
  }

  private generateMarkerIcon(layer: Marker, icon?: string) {
    icon ? this.generateSelectedIcon(layer, icon) : this.generateDefaultIcon(layer);
  }

  private generateDefaultIcon(layer: Marker) {
    const updatedIcon = this.geojsonAssetLoaderService.generateMarker(
      layer.feature,
      layer.getLatLng(),
      this.currentLayer.value
    );
    layer.setIcon(updatedIcon.options.icon);
  }

  private generateSelectedIcon(layer, icon) {
    const iconProperties = layer.options.icon.options;
    const newCustomIcon = L.icon({
      ...iconProperties,
      iconUrl: icon,
      iconSize: [25, 41],
      type: 'selectedIcon'
    });
    layer.setIcon(newCustomIcon);
  }

  private createDrawControl(map: Map): void {
    const shapeOptions = {
      color: this.atlasService.availableColors[this.shapeColorIndex] || defaultMissionRouteColor,
      fillOpacity: 0.2,
      dashArray: null,
      weight: 1
    };
    this.drawControl = new L.Control.Draw({
      draw: {
        polyline: false,
        circle: false,
        polygon: {
          shapeOptions
        },
        rectangle: {
          shapeOptions
        },
        marker: false
      },
      edit: {
        featureGroup: this.drawnItems,
        edit: false
      }
    });

    map.addControl(this.drawControl);
    map.addLayer(this.drawnItems);
    map.on('draw:drawstart', () => {
      map.on('contextmenu', () => {
        this.clearControl(map);
      });
    });
  }

  private hideControlsBar() {
    const draw = document.querySelector('.leaflet-draw') as any;
    draw.style = 'visibility:hidden';
  }

  private listenDrawEvents(map: Map): void {
    map.on(L.Draw.Event.DRAWSTOP, () => {
      this.setSelectMarkersCurrentOption(SelectMarkersOption.NONE);
      const drawTooltip = document.querySelector('.leaflet-draw-tooltip') as any;
      if (drawTooltip) {
        drawTooltip.style = 'display:none';
      }
    });
    map.on(L.Draw.Event.CREATED, (event: any) => {
      this.newShapeCreated.next(true);
      const layer = event.layer;
      this.verifyMarkersInsideBox(layer);
      this.addDrawnItems([layer]);
    });
  }

  public listenEditEvents(map, layers): void {
    map.on(L.Draw.Event.EDITVERTEX, () => {
      this.selectedMarkers.next([]);
      layers.forEach(layer => {
        this.verifyMarkersInsideBox(layer, true);
      });
      this.setHasAddedEditChanges(true);
    });
  }

  public addDrawnItems(layers) {
    layers.forEach(layer => {
      this.drawnItems.addLayer(layer);
    });
    this.setHasDrawnItems(true);
  }

  public verifyMarkersInsideBox(drawedLayer: any, hasToRestoreIcons: boolean = false): void {
    let selectedMarkers = [];
    const layers = (this.currentLayer.value.leafletLayer as any).getLayers();
    const currentSelectedMarkers = new Set(this.selectedMarkers.value);
    for (const layer of layers) {
      if (layer instanceof L.Marker) {
        const isMarkerInsidePolygon = this.isMarkerInsidePolygon(layer, drawedLayer);
        if (isMarkerInsidePolygon) {
          selectedMarkers = [...new Set([...this.addSelectedMarkers(layer), ...selectedMarkers])];
          continue;
        }

        if (hasToRestoreIcons && !currentSelectedMarkers.has(layer)) {
          this.restoreMarkerIcon(layer);
        }
      }
    }
    this.selectedMarkers.next([...new Set([...this.selectedMarkers.value, ...selectedMarkers])]);
  }

  private restoreMarkerIcon(layer) {
    const hasSelectedIcon = layer.getIcon().options?.type === 'selectedIcon';
    if (hasSelectedIcon) {
      this.generateMarkerIcon(layer);
    }
  }

  private addSelectedMarkers(layer) {
    const currentSelectedMarkers = new Set(this.selectedMarkers.value);
    this.markersOnShape = [...new Set([...this.markersOnShape, layer])];
    if (!currentSelectedMarkers.has(layer)) {
      currentSelectedMarkers.add(layer);
      this.generateMarkerIcon(layer, 'assets/icons/atlas/selected-marker.svg');
    }
    return Array.from(currentSelectedMarkers);
  }

  private isMarkerInsidePolygon(marker, polygon) {
    //Original idea https://stackoverflow.com/questions/31790344/determine-if-a-point-reside-inside-a-leaflet-polygon
    let isInside = false;
    const x = marker.getLatLng().lat,
      y = marker.getLatLng().lng;
    for (let ii = 0; ii < polygon.getLatLngs().length; ii++) {
      const polygonPoints = polygon.getLatLngs()[ii];
      for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
        const xi = polygonPoints[i].lat,
          yi = polygonPoints[i].lng;
        const xj = polygonPoints[j].lat,
          yj = polygonPoints[j].lng;

        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) isInside = !isInside;
      }
    }
    return isInside;
  }

  public createFile(data: string, filename: string): File {
    const contentType = {type: 'text'};
    const blob = new Blob([data], contentType);
    return new File([blob], filename, {type: 'text', lastModified: Date.now()});
  }

  public enableEditFeature(map: Map) {
    this.drawOnMapService.customJobPolyVerticesEdit();
    this.editingShapes.forEach(shape => {
      shape.editing = new L.Edit.Poly(shape, map);
      shape.editing.enable();
    });
  }

  public setEditingShape(shapes: any, polygonAsset: AtlasAssetModel) {
    this.editingShapes = shapes;
    this.editingAsset = polygonAsset;
    this.editingShapesGeojson = this.editingShapes.map(editingShape => editingShape.toGeoJSON());
  }

  public restoreOriginalEditFeature() {
    this.editingShapes.forEach((shape, index) => {
      if (!shape) {
        return;
      }
      shape.editing.disable();
      shape.setLatLngs(
        this.editingShapesGeojson[index].geometry.coordinates[0].map(coord => L.latLng(coord[1], coord[0]))
      );
      delete shape.editing;
      shape.redraw();
    });
  }

  public resetEditingShape() {
    this.editingShapes = [];
    this.editingShapesGeojson = [];
  }

  public saveNewJobPolygon() {
    const assetId = uuidv4();
    this.createAsset(assetId);
    this.atlasService.removeAsset({assetId: this.editingAsset.id, avoidUpdateCache: true});
    this.editingAsset.id = assetId;
  }

  public async updateJobMarkers() {
    const features = this.selectedMarkers.value.map(marker => marker.feature);
    const featureCollection = {
      type: 'FeatureCollection',
      features: features
    };
    const jobId = this.editingAsset.geojson.features[0].properties.jobId;
    await this.uploadJobFile(featureCollection, this.editingAsset.name);
    const s3Path = this.jobUploadService.lastFilePath;
    this.jobsApiService.updateJob(jobId, {s3Path});
    this.jobsFacadeService.updateJobSuccess(jobId, {s3Path});
    this.jobDetailService.setHasToUpdateMarkers(true);
  }

  public async uploadJobFile(featureCollection, title) {
    const fileName = `${title}.geojson`;
    const lastFilePath = await this.jobUploadService.getKey(fileName);
    this.jobUploadService.setLastFilePath(lastFilePath);
    const file = this.createFile(JSON.stringify(featureCollection), fileName);
    this.jobUploadService.addToQueue([file]);
  }

  private createAsset(assetId: string) {
    const featureCollection = {
      type: 'FeatureCollection',
      features: []
    };
    this.editingShapes.forEach(shape => {
      featureCollection.features.push(shape.toGeoJSON());
    });
    this.uploadAtlasFile(featureCollection, this.editingAsset.name, assetId);
  }

  private uploadAtlasFile(featureCollection, title, assetId) {
    const file = this.createFile(JSON.stringify(featureCollection), `${title}.geojson`);
    this.atlasUploadService.addToQueue([file], {assetId});
  }
}

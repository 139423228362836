import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map, of, switchMap, take, tap, zip} from 'rxjs';
import {DefaultMissionSettings, Mission, MissionRoutePoint, MissionSettingsService} from './mission-settings.service';
import {MissionApiService} from './mission-api.service';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {LatLng, latLng} from 'leaflet';
import {MissionImportService} from '@app/flights/services/mission-import.service';
import {MissionModel, MissionType} from '../model/mission.model';
import {PermissionService} from '@app/core/services/permission.service';
import * as FileSaver from 'file-saver';
import {Router} from '@angular/router';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {GeoJSONFeature, GeoJSONFeatureCollection} from '@app/atlas/model/mission-geojson.model';

@Injectable({
  providedIn: 'root'
})
export class MissionManagerService {
  private missions: BehaviorSubject<Mission[]> = new BehaviorSubject<Mission[]>([]);
  public missions$ = this.missions.asObservable();

  public missionToRemove: BehaviorSubject<Mission> = new BehaviorSubject<Mission>(null);
  public missionName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public missionsGroupedByOwner$: Observable<Mission[]> = this.missions$.pipe(
    switchMap(missions => zip(of(missions), this.userStoreFacadeService.selectUserTeamId$)),
    map(([missions, teamId]: [Mission[], string]) => {
      const missionsByTeamId = missions.filter(m => m.teamId === teamId);
      return missionsByTeamId
        .map(mission => {
          const route = this.fillMissionDefaultSettings(mission.route);
          return {...mission, route};
        })
        .map(mission => this.calculateTimeAndDistance(mission));
      //TODO Group missions
      // .reduce((acc, curr) => {
      //   const groupKey = curr.ownerId;
      //   if (!acc[groupKey]) {
      //     acc[groupKey] = [];
      //   }
      //   acc[groupKey].push(curr);
      //   return acc;
      // }, {});
    }),
    tap(missionsGroupedByOwner => {
      const completeMissionsGroupedByOwner = [];
      const readyMissionsGroupedByOwner = [];

      missionsGroupedByOwner.forEach(mission => {
        if (mission.lastFlight) {
          completeMissionsGroupedByOwner.push(mission);
        } else {
          readyMissionsGroupedByOwner.push(mission);
        }
      });

      this.completeMissionsGroupedByOwner.next([...completeMissionsGroupedByOwner]);
      this.readyMissionsGroupedByOwner.next([...readyMissionsGroupedByOwner]);
      this.isLoading.next(false);
      this.hasMissions.next(missionsGroupedByOwner.length > 0);
    })
  );

  public completeMissionsGroupedByOwner: BehaviorSubject<Mission[]> = new BehaviorSubject([]);
  public readyMissionsGroupedByOwner: BehaviorSubject<Mission[]> = new BehaviorSubject([]);
  public hasMissions: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public missionType = MissionType;

  private totalDistance: number = 0;
  private tempLatLng: LatLng;

  constructor(
    private missionApiService: MissionApiService,
    private userStoreFacadeService: UserStoreFacadeService,
    private missionSettingsService: MissionSettingsService,
    private missionImportService: MissionImportService,
    private permissions: PermissionService,
    private router: Router,
    private unleashAnalytics: UnleashAnalyticsService
  ) {}

  public setMissions(missions: Mission[]) {
    this.missions.next(missions);
    this.missionsGroupedByOwner$.pipe(take(1)).subscribe();
  }

  public fillMissionDefaultSettings(missionRoute: MissionRoutePoint[]) {
    return missionRoute?.filter(Boolean).map(route => {
      const mergedObject: MissionRoutePoint = {} as MissionRoutePoint;
      this.missionSettingsService.settings$.pipe(take(1)).subscribe(settings => {
        for (const key in settings) {
          if (route?.[key] !== null && route?.[key] !== undefined) {
            mergedObject[key] = route[key];
          } else {
            mergedObject[key] = settings[key];
          }
        }
      });
      return mergedObject;
    });
  }

  public removeMission(missionToRemove: Mission) {
    const newMissions = this.missions.value.filter(mission => mission.id !== missionToRemove.id);
    this.missions.next([...newMissions]);
    this.missionsGroupedByOwner$.pipe(take(1)).subscribe();
  }

  public updateMission(mission: Mission, updatedMission: Partial<Mission>) {
    return this.missionApiService.updateMission(mission.id, updatedMission);
  }

  public updateMissionUi(updatedMission: Mission) {
    const newMissions = this.missions.value.map(mission => {
      if (mission.id === updatedMission.id) {
        return {...mission, ...updatedMission};
      }
      return mission;
    });

    this.missions.next([...newMissions]);
    this.missionsGroupedByOwner$.pipe(take(1)).subscribe();
  }

  public addNewMission(mission: Mission): void {
    this.missions.next([...this.missions.value, mission]);
    this.missionsGroupedByOwner$.pipe(take(1)).subscribe();
  }

  public importMission(event) {
    const files = event.target.files;
    const file = files[0];

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const mission: Mission = await this.missionImportService.importFile(file, fileReader.result as string);
      if (mission.type === MissionType.CORRIDOR) {
        console.info('Created Corridor mission', mission);
      }
      this.missionSettingsService.setMission(mission);
      this.setMissionName(mission.name);
    };
    fileReader.readAsText(file);
  }

  public exportMissionJSON(mission: Mission) {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.download(`${mission.name || 'waypoints'}-${new Date().getTime()}.json`, {
      name: mission?.name,
      description: mission?.description,
      heightMode: mission?.heightMode,
      isSmartInspect: mission?.isSmartInspect || false,
      route: mission?.route
    });
  }

  private download(name: string, obj: unknown) {
    const file = new Blob([JSON.stringify(obj)], {type: 'application/json'});
    FileSaver.saveAs(file, name);
  }

  public exportMissionGeoJSON(mission: MissionModel, customName: string): void {
    const routePoints = mission.route;
    const lineCoordinates: number[][] = routePoints.map(point => [point.lng, point.lat, point.altitude]);
    // flight path
    const lineFeature: GeoJSONFeature = {
      type: 'Feature',
      properties: {
        stroke: '#ffa500',
        'stroke-width': 2,
        'stroke-opacity': 1,
        description: `${mission.name} Flight Path`
      },
      geometry: {
        type: 'LineString',
        coordinates: lineCoordinates
      }
    };

    // waypoint features
    const pointFeatures: GeoJSONFeature[] = routePoints.map(point => ({
      type: 'Feature',
      properties: point,
      geometry: {
        type: 'Point',
        coordinates: [point.lng, point.lat, point.altitude]
      }
    }));
    const featureCollection: GeoJSONFeatureCollection = {
      type: 'FeatureCollection',
      properties: {
        name: mission.name,
        description: mission.description,
        heightMode: mission.heightMode,
        isSmartInspect: mission.isSmartInspect
      },
      features: [lineFeature, ...pointFeatures]
    };

    this.download(`${customName ? customName : mission.name}.geojson`, featureCollection);
  }

  public viewMission(mission: Mission): void {
    if (mission.type === this.missionType.MAPPING_2D) {
      this.unleashAnalytics.logEvent(EVENTS.MISSION_PLANNER_SURVEY_MISSION_VIEWED);
    }
    this.missionSettingsService.setMission(mission);
    this.setMissionName(mission.name);
  }

  public viewLocation(mission: Mission): void {
    this.missionSettingsService.viewLocation(mission);
  }

  public setMissionName(missionName: string): void {
    this.missionName.next(missionName);
  }

  public calculateTimeAndDistance(mission: Mission): Mission {
    this.totalDistance = 0;
    this.tempLatLng = null;
    let time = 0;
    mission.route?.forEach((routeItem, index) => {
      if (!routeItem || !routeItem.lat || !routeItem.lng) {
        console.warn(`route point ${index} is not defined in the mission ${mission.id}`);
        return;
      }
      this.calculateTotalDistance(
        latLng({
          lat: routeItem.lat,
          lng: routeItem.lng,
          alt: routeItem.altitude || DefaultMissionSettings.DEFAULT_ALT
        })
      );
      time = this.calculateTime(
        routeItem.altitude || DefaultMissionSettings.DEFAULT_ALT,
        routeItem.speed || DefaultMissionSettings.DEFAULT_SPEED
      );
    });
    // eslint-disable-next-line no-magic-numbers
    return {...mission, distance: this.totalDistance / 1000, time};
  }

  public openPreviewIn3D(mission: Mission) {
    this.router.navigate(['/secure/atlas/3d-preview'], {queryParams: {missionId: mission.id}});
  }

  public addMission(mission: Mission): Observable<Mission[]> {
    const missions = [...this.missions.value, mission].sort((a, b) => b.updatedAt - a.updatedAt);
    this.missions.next(missions);
    return this.missionsGroupedByOwner$.pipe(take(1));
  }

  private calculateTime(altitude: number, speed: number): number {
    // eslint-disable-next-line no-magic-numbers
    const goingUpAndDownTime = (altitude / 2) * 2;
    const takeoffAndLandingTime = 15;
    const flyingPathTime = this.totalDistance / speed;
    return Math.round(flyingPathTime + goingUpAndDownTime + takeoffAndLandingTime);
  }

  private calculateTotalDistance(latLng: LatLng) {
    if (this.tempLatLng == null) {
      this.tempLatLng = latLng;
      return;
    }
    const total = this.totalDistance;
    const distanceTo = this.tempLatLng.distanceTo(latLng);
    this.totalDistance = total + distanceTo;
    this.tempLatLng = latLng;
  }
}

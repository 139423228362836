import {Pipe, PipeTransform} from '@angular/core';
import {Breadcrumb, BREADCRUMB} from '../breadcrumb/breadcrumb.model';
import {FolderNamePipe} from './folder-name.pipe';
import {firstValueFrom} from 'rxjs';

@Pipe({
  name: 'ubreadcrumbs'
})
export class BreadcrumbsPipe implements PipeTransform {
  public breadcrumbs: Breadcrumb[] = [];
  public totalCollapsedItems = 0;
  public tokens: Breadcrumb[] = [];
  public path: string[] = [];
  public moreButtonPosition = 1;
  public previousCollapsedItems = 0;
  public sliceEnd = 0;

  private readonly INITIAL_PATH = '';
  private readonly MIN_BREADCRUMBS_LENGTH = 3;

  constructor(private folderNamePipe: FolderNamePipe) {}
  public async transform(
    value: string[],
    isOverlapping: boolean,
    font: string,
    widthValues: number[]
  ): Promise<Breadcrumb[]> {
    if (value.length < this.MIN_BREADCRUMBS_LENGTH) {
      this.setDefaultBreadcrumbs(value);
      return this.breadcrumbs;
    }
    if (this.shouldResetBreadcrumbs(value)) {
      this.setCurrentPath(value);
      return this.adjustOverlappingBreadcrumbs(value, isOverlapping);
    }
    this.setCurrentPath(value);
    if (isOverlapping) {
      return this.adjustOverlappingBreadcrumbs(value, isOverlapping);
    }
    if (this.hasTokens()) {
      return await this.adjustBreadcrumbsByContainerSize(value, font, widthValues);
    }
    return this.breadcrumbs;
  }

  public setCurrentPath(value: string[]): void {
    this.path = value;
  }

  public setDefaultBreadcrumbs(value: string[]): void {
    this.tokens = value.map(
      (id: string, index: number): Breadcrumb => ({
        id,
        class: '',
        type: BREADCRUMB.TEXT,
        index
      })
    );
    this.breadcrumbs = this.tokens;
  }

  public adjustOverlappingBreadcrumbs(value: string[], isOverlapping: boolean): Breadcrumb[] {
    if (this.breadcrumbs.length === this.MIN_BREADCRUMBS_LENGTH && this.hasTokens()) {
      return this.breadcrumbs;
    }
    this.setDefaultBreadcrumbs(value);
    if (isOverlapping) {
      this.totalCollapsedItems++;
      this.updateTruncatedItems();
    }
    return this.breadcrumbs;
  }

  public async adjustBreadcrumbsByContainerSize(
    value: string[],
    font: string,
    widthValues: number[]
  ): Promise<Breadcrumb[]> {
    const tokenIndex = this.breadcrumbs.findIndex(element => !!element.tokens?.length);
    if (tokenIndex === -1) {
      return this.breadcrumbs;
    }
    const folderName = await firstValueFrom(this.folderNamePipe.transform(this.breadcrumbs[tokenIndex]?.tokens[0]?.id));
    const textWidthPx = this.getTextWidth(folderName, font);
    const listPadding = 45;
    if (
      widthValues.length > 1 &&
      widthValues[0] + textWidthPx + listPadding < widthValues[1] &&
      this.totalCollapsedItems > 0
    ) {
      this.setDefaultBreadcrumbs(value);
      if (this.totalCollapsedItems === 1) {
        return this.breadcrumbs;
      }
      this.totalCollapsedItems--;
      this.updateTruncatedItems();
    }
    return this.breadcrumbs;
  }

  public updateTruncatedItems() {
    if (this.moreButtonPosition === 1) {
      this.moreButtonPosition = Math.ceil(this.breadcrumbs.length / 2);
    }
    let sliceStart = this.moreButtonPosition - this.totalCollapsedItems;
    const maxBreadcrumbsLength = this.isPathEven(this.breadcrumbs.length)
      ? this.breadcrumbs.length
      : this.breadcrumbs.length + 1;
    const minLenghtInLeftSide = 2;
    const totalItemsInLeft = this.moreButtonPosition - this.totalCollapsedItems;
    const leftMinPositionReached = totalItemsInLeft < minLenghtInLeftSide;
    if (leftMinPositionReached) {
      this.totalCollapsedItems > this.previousCollapsedItems ? this.sliceEnd++ : this.sliceEnd--;
      sliceStart =
        maxBreadcrumbsLength - this.totalCollapsedItems < this.MIN_BREADCRUMBS_LENGTH ? 1 : minLenghtInLeftSide;
    } else {
      this.sliceEnd = this.moreButtonPosition;
    }
    this.previousCollapsedItems = this.totalCollapsedItems;
    const maxSliceEnd = maxBreadcrumbsLength - 1;
    this.breadcrumbs.splice(sliceStart, this.totalCollapsedItems, {
      id: '•••',
      class: 'more',
      type: BREADCRUMB.MORE,
      tokens: this.tokens.slice(sliceStart, this.sliceEnd >= maxSliceEnd ? maxSliceEnd : this.sliceEnd)
    });
  }

  public getTextWidth(text: string, font: string): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;
    context.font = font;
    return context.measureText(text).width;
  }

  private shouldResetBreadcrumbs(value: string[]): boolean {
    return (
      value[0] === this.INITIAL_PATH || (this.path.length > 0 && this.path.length !== value.length) || !this.hasTokens()
    );
  }

  private hasTokens(): boolean {
    return this.breadcrumbs.some(element => !!element.tokens?.length);
  }

  private isPathEven(path: number) {
    return path % 2 === 0;
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {DateFnsAdapter} from '@angular/material-date-fns-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {TimeZonesService} from '@app/shared/time-zones/time-zones.service';
import {BehaviorSubject, Subscription, debounceTime} from 'rxjs';
import en from 'date-fns/locale/en-US';
import {Addon} from '@app/store/addon/models/addon';
import {Days, ScheduleMode, ScheduleType} from '@app/live/models/schedule.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TeamRole} from '@app/profile/models/team.model';
import {MatRadioChange} from '@angular/material/radio';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'yyyy/MM/dd',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'yyyy'
  }
};

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-new-schedule',
  templateUrl: './new-schedule.component.html',
  styleUrls: ['./new-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter
    },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {
      provide: MAT_DATE_LOCALE,
      useValue: en
    }
  ]
})
export class NewScheduleComponent {
  @Input('devices') public set setDevices(devices: UserDeviceJoined[]) {
    if (devices?.length > 0) {
      this.devicesList = devices;
      this.filteredDevices.next(devices);
    }
  }
  @Input() public currentUserDevicesObject: {[key: string]: UserDeviceJoined} = null;
  @Input() public addons: Addon[] = [];
  @Input() public isEditMode: boolean = false;
  @Input('currentUserTimezone') public set setCurrentUserTimezone(currentUserTimezone: string) {
    if (currentUserTimezone) {
      this.scheduleForm.controls.scheduleExpressionTimezone.setValue(currentUserTimezone);
    }
  }
  @Input('isAdmin') public set setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
    if (isAdmin === false) {
      for (const control in this.scheduleForm.controls) {
        this.scheduleForm.controls[control].disable();
      }
    }
  }
  @Input() public isExecutingAction: boolean = false;

  @Output() public goToPrincipalPage: EventEmitter<void> = new EventEmitter();
  @Output() public createSchedule: EventEmitter<any> = new EventEmitter();
  @Output() public editSchedule: EventEmitter<any> = new EventEmitter();

  public isAdmin: boolean = false;
  public minDate = null;
  public maxDate = null;
  public currentDate = new Date().setHours(0, 0, 0, 0);
  public teamRole: typeof TeamRole = TeamRole;
  public totalDays: number = 0;
  protected readonly ScheduleType = ScheduleType;

  public dateFilter = (date: Date | null): boolean => {
    const day = (date || new Date()).getDay();
    return day === 1;
  };
  public timeZones = this.timeZonesService.timeZoneList;
  public types = [
    {value: ScheduleType.RECURRING, name: 'Recurring'},
    {value: ScheduleType.ONEOFF, name: 'One-off'}
  ];
  public modes = [
    {value: ScheduleMode.STREAM, title: 'Video', disabled: false},
    {value: ScheduleMode.SNAPSHOT, title: 'Image Snapshot', disabled: false}
  ];
  public imageSnapshotFrecuencyUnits = [{value: 'hours'}, {value: 'minutes'}];
  public devicesList: UserDeviceJoined[] = [];
  public filteredDevices: BehaviorSubject<UserDeviceJoined[]> = new BehaviorSubject([]);
  public days = Days;
  public oneOffDays = {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null
  };
  @Input('scheduleAttributes') public set setScheduleAttributes(scheduleAttributes) {
    if (scheduleAttributes) {
      this.scheduleForm.controls.name.setValue(scheduleAttributes?.name);
      this.scheduleForm.controls.scheduleExpressionTimezone.setValue(scheduleAttributes?.scheduleExpressionTimezone);
      this.scheduleForm.controls.addons.setValue(scheduleAttributes?.addons);
      this.scheduleForm.controls.devices.setValue(scheduleAttributes.devices);
      this.scheduleForm.controls.type.setValue(
        scheduleAttributes.recurring ? ScheduleType.RECURRING : ScheduleType.ONEOFF
      );
      if (scheduleAttributes.scheduleDays) {
        this.totalDays = scheduleAttributes.scheduleDays?.length;
      }
      if (scheduleAttributes.isSnapshot) {
        this.scheduleForm.controls.mode.setValue(ScheduleMode.SNAPSHOT);
        const unit = scheduleAttributes.snapshotConfig?.unit;
        const frequency = scheduleAttributes.snapshotConfig?.frequency;
        this.scheduleForm.controls.imageSnapshotFrecuencyUnits.setValue(unit);
        this.scheduleForm.controls.imageSnapshotFrecuency.setValue(this.calculateFrequency(frequency, unit));
      }
      if (scheduleAttributes.schedules.length > 0) {
        scheduleAttributes.schedules.forEach(item => {
          const key = Object.keys(item)[0];
          if (!this.incomingSchedules[key]) {
            this.incomingSchedules[key] = [];
          }
          this.incomingSchedules[key].push(item[key]);
        });
      }
      if (scheduleAttributes.oneOffStartDate) {
        this.scheduleForm.controls.oneOffStartDate.setValue(scheduleAttributes.oneOffStartDate);
        this.minDate = scheduleAttributes.oneOffStartDate;
        this.maxDate = this.getNextFriday(this.minDate);
      }
    }
  }

  public incomingSchedules = {};
  public scheduleForm: FormGroup = this.fb.group({
    search: new FormControl(''),
    devices: new FormControl([], [Validators.required]),
    name: new FormControl('New Schedule', [Validators.required]),
    scheduleExpressionTimezone: new FormControl(this.timeZones[this.timeZonesService.getCurrentTimeZoneIndex()]?.name),
    type: new FormControl(this.types[0].value),
    mode: new FormControl(this.modes[0].value),
    imageSnapshotFrecuency: new FormControl(1, [Validators.required, Validators.min(1)]),
    imageSnapshotFrecuencyUnits: new FormControl(this.imageSnapshotFrecuencyUnits[1].value),
    oneOffStartDate: new FormControl(),
    addons: new FormControl([]),
    schedules: this.fb.group(
      {
        [this.days.Monday]: new FormControl(null),
        [this.days.Tuesday]: new FormControl(null),
        [this.days.Wednesday]: new FormControl(null),
        [this.days.Thursday]: new FormControl(null),
        [this.days.Friday]: new FormControl(null),
        [this.days.Saturday]: new FormControl(null),
        [this.days.Sunday]: new FormControl(null)
      },
      {validators: [this.noSchedulesValidator]}
    )
  });
  public searchSubscription: Subscription;
  public schedulesSubscription: Subscription;
  public startDateSubscription: Subscription;
  public timezoneExpressionSubscription: Subscription;
  public scheduleMode: typeof ScheduleMode = ScheduleMode;

  constructor(private fb: FormBuilder, private timeZonesService: TimeZonesService, private cd: ChangeDetectorRef) {
    this.searchSubscription = this.scheduleForm.controls.search.valueChanges
      // eslint-disable-next-line no-magic-numbers
      .pipe(debounceTime(100))
      .subscribe((searchText: string) => this.searchDevice(searchText));

    this.schedulesSubscription = this.scheduleForm.controls.schedules.valueChanges.subscribe(schedules => {
      const scheduleValues = Object.values(schedules);
      if (scheduleValues.every(schedule => !!schedule)) {
        this.checkInvalidRanges(schedules);
      }
    });

    this.timezoneExpressionSubscription = this.scheduleForm.controls.scheduleExpressionTimezone.valueChanges.subscribe(
      () => {
        const schedules = this.scheduleForm.controls.schedules.value;
        this.checkInvalidRanges(schedules);
      }
    );

    this.startDateSubscription = this.scheduleForm.controls.oneOffStartDate.valueChanges.subscribe(
      (startDate: Date) => {
        if (!startDate) {
          ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((day, index) => {
            this.oneOffDays[day] = null;
          });
          return;
        }
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((day, index) => {
          const dayDate = new Date(startDate);
          dayDate.setDate(startDate.getDate() + index);
          this.oneOffDays[day] = dayDate;
        });
      }
    );

    this.minDate = this.getMinDate();
  }

  public checkInvalidRanges(schedules): void {
    for (const schedule in schedules) {
      schedules[schedule]?.forEach(daySchedule => {
        if (daySchedule?.invalid) {
          const controlIndex = daySchedule.startTime.getDay();
          (this.scheduleForm.controls.schedules as any).controls[controlIndex]?.setErrors({invalidDate: true});
        }
      });
    }
    this.cd.detectChanges();
  }

  public getMinDate(): Date {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const difference = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const currentMonday = new Date(today.setDate(difference));
    currentMonday.setHours(0, 0, 0, 0);
    return currentMonday;
  }

  public getNextFriday(date: Date): Date {
    const nextFriday = new Date(date);
    const dayOfWeek = nextFriday.getDay();
    const daysUntilNextFriday = (5 - dayOfWeek + 7) % 7;
    nextFriday.setDate(nextFriday.getDate() + daysUntilNextFriday);
    return nextFriday;
  }

  public searchDevice(event) {
    this.filteredDevices.next(
      this.devicesList.filter(
        device =>
          device?.name.toLowerCase().includes(event.toLowerCase()) ||
          device?.description.toLowerCase().includes(event.toLowerCase())
      )
    );
  }

  public selectAllDevices() {
    this.scheduleForm.controls.devices.patchValue(this.devicesList.map(device => device.id));
  }

  public deSelectAllDevices() {
    this.scheduleForm.controls.devices.patchValue([]);
  }

  public addDevice(deviceId: string) {
    const devices = [...this.scheduleForm.controls.devices.value];
    const index = devices.findIndex(device => device === deviceId);
    index !== -1 ? devices.splice(index, 1) : devices.push(deviceId);
    this.scheduleForm.controls.devices.patchValue(devices);
  }

  public emitGoToPrincipalPage() {
    this.goToPrincipalPage.emit();
  }

  public emitCreateSchedule() {
    if (this.scheduleForm.valid) {
      const formattedData = this.getFormattedData();
      const schedules = formattedData?.schedules;
      this.checkInvalidRanges(schedules);
      this.isExecutingAction = true;
      this.createSchedule.emit(this.getFormattedData());
    }
  }

  public isInvalidRange(startDate: Date): boolean {
    const targetTimeZone = this.scheduleForm.controls.scheduleExpressionTimezone.value;
    let currentDate = new Date();
    if (targetTimeZone) {
      currentDate = new Date(currentDate.toLocaleString('en-US', {timeZone: targetTimeZone}));
    }
    return startDate <= currentDate;
  }

  public emitEditSchedule() {
    if (this.scheduleForm.valid) {
      const formattedData = this.getFormattedData();
      const schedules = formattedData?.schedules;
      this.checkInvalidRanges(schedules);
      this.editSchedule.emit(formattedData);
    }
  }

  public noSchedulesValidator(control: AbstractControl): ValidationErrors | null {
    const hasSchedules = Object.values(control.value).filter((value: any) => !!value && value.length > 0).length > 0;
    return !hasSchedules ? {error: 'No schedule sections set'} : null;
  }

  public typeChanged(event: any) {
    if (event.value === ScheduleType.ONEOFF) {
      this.changeMode(ScheduleMode.STREAM);
      this.scheduleForm.controls.oneOffStartDate.setValue(this.minDate);
      this.scheduleForm.controls.mode.setValue(this.modes[0].value);
      this.modes[1].disabled = true;
      return;
    }
    this.modes[1].disabled = false;
    this.scheduleForm.controls.oneOffStartDate.setValue(null);
  }

  public changeMode(mode: ScheduleMode): void {
    mode === ScheduleMode.SNAPSHOT ? this.removeValidator() : this.addValidator();
  }

  private addValidator() {
    const control = this.scheduleForm.get('schedules');
    control.setValidators([this.noSchedulesValidator]);
    control.updateValueAndValidity();
  }

  private removeValidator() {
    const control = this.scheduleForm.get('schedules');
    control.clearValidators();
    control.updateValueAndValidity();
  }

  private getFormattedData() {
    if (this.scheduleForm.controls.type.value === this.types[0].value) {
      delete this.scheduleForm.value.oneOffStartDate;
    }
    if (this.scheduleForm.controls.mode.value === this.modes[0].value) {
      delete this.scheduleForm.value.imageSnapshotFrecuency;
      delete this.scheduleForm.value.imageSnapshotFrecuencyUnits;
    }
    delete this.scheduleForm.value.search;
    return this.scheduleForm.value;
  }

  private calculateFrequency(value: number, unit: string) {
    switch (unit) {
      case 'seconds':
        return value;
      case 'minutes':
        return value / 60;
      case 'hours':
        return value / 3600;
      default:
        return value;
    }
  }
}

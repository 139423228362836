import {ChangeDetectionStrategy, Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Addon} from '@app/store/addon/models/addon';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'unleash-scheduler-ai-apps',
  templateUrl: './scheduler-ai-apps.component.html',
  styleUrls: ['./scheduler-ai-apps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchedulerAiAppsComponent),
      multi: true
    }
  ]
})
export class SchedulerAiAppsComponent implements ControlValueAccessor {
  @Input() public addons: Addon[] = [];
  @Input('isAdmin') public set setIsAdmin(isAdmin: boolean) {
    this.isAdmin = isAdmin;
    this.toggleEnabledOptions(isAdmin);
  }

  public isAdmin: boolean = false;
  public hasToShowAddons: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public addonForm: FormGroup = this.fb.group({
    principalAddonId: [''],
    additionalAddonId: ['']
  });

  constructor(private fb: FormBuilder) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (_: any) => void = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: () => void = () => {};

  public writeValue(obj: any): void {
    if (obj?.length > 0) {
      this.setHasToShowAddons(true);
      this.selectionChange(obj[0], true);
      this.selectionChange(obj[1], false);
    }
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public toggleEnabledOptions(isAdmin: boolean): void {
    for (const control in this.addonForm.controls) {
      isAdmin ? this.addonForm.controls[control].enable() : this.addonForm.controls[control].disable();
    }
  }

  public selectionChange(addonId: string, isPrincipal: boolean): void {
    isPrincipal
      ? this.addonForm.controls.principalAddonId.setValue(addonId)
      : this.addonForm.controls.additionalAddonId.setValue(addonId);

    this.onChange(
      [this.addonForm.controls.principalAddonId.value, this.addonForm.controls.additionalAddonId.value].filter(
        addon => !!addon
      )
    );
  }

  public setHasToShowAddons(hasToShowAddons: boolean) {
    this.hasToShowAddons.next(hasToShowAddons);
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, map} from 'rxjs';
import {UserStoreFacadeService} from '../services/user-store-facade.service';
import {ExpiryService} from '@app/plans/services/expiry.service';
import {UserModel} from '../models/api/user-model';
import {AuthService} from '@app/auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TrialExpiredGuard {
  constructor(
    private userStoreFacadeService: UserStoreFacadeService,
    private expiryService: ExpiryService,
    private authService: AuthService
  ) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userStoreFacadeService.currentUser$.pipe(
      map((user: UserModel) => !this.expiryService.hasTrialConstraints(user))
    );
  }
}
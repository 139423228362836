import {Role, Permission} from '../../models/roles-management.model';

export const rolesManagementFeatureKey = 'rolesManagement';

export interface RolesManagementState {
  roles: Role[];
  permissions: Permission[];
  updatedRoleIds: string[];
  deletedRoleIds: string[]; // To track roles that were saved but then deleted
  isLoading: boolean;
  error: string | null;
}

export const initialRolesManagementState: RolesManagementState = {
  roles: [],
  permissions: [],
  updatedRoleIds: [],
  deletedRoleIds: [],
  isLoading: false,
  error: null
};

import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {HttpClient} from '@angular/common/http';
import {ScheduleModel, SchedulePayload} from '@app/core/models/api/schedule.model';
import {Observable} from 'rxjs';
import {ListScheduleResponse} from '@app/live/models/schedule.model';

@Injectable({providedIn: 'root'})
export class ScheduleService {
  private endpoint = 'schedule';
  constructor(private apiGateway: ApiGateway, private http: HttpClient) {}

  public listTeamSchedules(): Observable<ListScheduleResponse> {
    return this.apiGateway.get(this.endpoint);
  }

  public getSingleSchedule(scheduleId: string): Observable<ScheduleModel> {
    return this.apiGateway.get(`${this.endpoint}/${scheduleId}`);
  }

  public create(payload: SchedulePayload): Observable<ScheduleModel> {
    return this.apiGateway.post([this.endpoint].join('/'), {}, payload);
  }

  public update(scheduleId: string, payload: SchedulePayload): Observable<ScheduleModel> {
    return this.apiGateway.patch([this.endpoint, scheduleId].join('/'), {}, payload);
  }

  public delete(scheduleId: string): Observable<ScheduleModel> {
    return this.apiGateway.delete([this.endpoint, scheduleId].join('/'), {});
  }
}

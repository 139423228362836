<ng-container *ngIf="dataSource.data?.length > 0; else blankStateTemplate">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="name"
    matSortDisableClear
    matSortDirection="desc"
    (matSortChange)="sortSchedules($event)"
    class="table"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-sort-header mat-header-cell *matHeaderCellDef disableClear>{{ 'live.scheduler.name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="devices">
      <th mat-sort-header mat-header-cell *matHeaderCellDef disableClear>{{ 'live.scheduler.devices' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.deviceIds.length }}</td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.scheduler.type' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.isRecurring ? ('live.scheduler.recurring' | translate) : ('live.scheduler.oneOff' | translate) }}
      </td>
    </ng-container>

    <!-- Schedule Column -->
    <ng-container matColumnDef="scheduleDays">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.scheduler.schedule' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="table__week">
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(2)">M</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(3)">T</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(4)">W</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(5)">T</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(6)">F</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(7)">S</div>
          <div class="table__week-day" [class.active]="row.scheduleDays?.includes(1)">S</div>
        </div>
      </td>
    </ng-container>

    <!-- Ai apps Column -->
    <ng-container matColumnDef="aiApps">
      <th mat-header-cell *matHeaderCellDef>{{ 'live.scheduler.aiApps' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="table__aiApps">
          <span *ngFor="let aiApp of row.addons">
            {{ addons[aiApp.id]?.name || '-' }}
          </span>
        </div>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button (click)="$event.preventDefault(); $event.stopPropagation()" [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon class="table__menu-icon">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="emitView(row.id)" mat-menu-item>
            <mat-icon>remove_red_eye</mat-icon>
            <span>{{ 'live.scheduler.view' | translate }}</span>
          </button>
          <button
            [matTooltip]="isAdmin ? '' : ('live.scheduler.cantDelete' | translate)"
            [disabled]="!isAdmin"
            (click)="emitEdit(row.id)"
            matTooltipClass="table__tooltip"
            matTooltipPosition="right"
            mat-menu-item
          >
            <mat-icon>edit</mat-icon>
            <span>{{ 'live.scheduler.edit' | translate }}</span>
          </button>
          <button
            [matTooltip]="isAdmin ? '' : ('live.scheduler.cantDelete' | translate)"
            [disabled]="!isAdmin"
            [class.table__delete]="isAdmin"
            (click)="emitDelete(row.title, row.id)"
            matTooltipClass="table__tooltip"
            matTooltipPosition="right"
            mat-menu-item
          >
            <mat-icon>delete</mat-icon>
            <span>{{ 'live.scheduler.delete' | translate }}</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      (click)="isAdmin ? emitEdit(row.id) : emitView(row.id)"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
  <mat-paginator *ngIf="dataSource.data?.length > 15" #paginator [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons>
  </mat-paginator>
</ng-container>
<ng-template #blankStateTemplate>
  <section class="blank-state mat-elevation-z3">
    <mat-icon class="blank-state__icon" svgIcon="schedule-blank"></mat-icon>
    <span class="blank-state__title">{{ 'live.scheduler.noSchedules' | translate }}</span>
    <span class="blank-state__info">{{ 'live.scheduler.noSchedulesInfo' | translate }}</span>
    <button *ngIf="isAdmin" (click)="emitNewSchedule()" class="blank-state__action" mat-button>
      {{ 'live.scheduler.createNewSchedule' | translate }}
    </button>
  </section>
</ng-template>

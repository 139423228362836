import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Map} from 'leaflet';
import {SuperclusterService} from '../services/supercluster.service';
import {AtlasService} from '../services/atlas.service';

@Directive({
  selector: '[ulBringToFrontLayer]',
  standalone: true
})
export class BringToFrontLayerDirective {
  @Input('ulBringToFrontLayer') public asset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(private superclusterService: SuperclusterService, private atlasService: AtlasService) {}

  @HostListener('click', ['$event'])
  public bringToFrontLayer(): void {
    const zIndex = 100;
    this.atlasService.bringToFrontPolygon(this.asset);
    this.superclusterService.setUpdatedMarkerZIndex(zIndex, this.asset.id);
    this.superclusterService.updateClusters(this.map);
  }
}

import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {AnalysisConfigurationModule} from '@app/shared/analysis-configuration/analysis-configuration.module';
import {AnnotationSharedModule} from '@app/shared/annotation-shared/annotation-shared.module';
import {LoadingSpinnerModule} from '@app/shared/loading-spinner/loading-spinner';
import {ManageZonesDialogModule} from '@app/shared/manage-zones-dialog/manage-zones-dialog.module';
import {UnleashLoaderModule} from '@app/theme/components/unleash-loader/unleash-loader.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {WebrtcPlayerComponent} from 'unleash-components/dist/webrtc-player';
import {VideogularModule} from '../core/modules/videogular/videogular.module';
import {SharedPipesModule} from '../shared/pipes/shared-pipes.module';
import {UnleashMaterialModule} from '../theme/unleash-material.module';
import {CloseDialogComponent} from './components/close-dialog/close-dialog.component';
import {DeviceTableComponent} from './components/device-table/device-table.component';
import {DeviceComponent} from './components/device/device.component';
import {EmbedCodeDialog} from './components/embed-code-dialog/embed-code.component';
import {FourGridLayoutComponent} from './components/four-grid-layout/four-grid-layout.component';
import {ModelLoadingSpinnerComponent} from './components/model-loading-spinner/model-loading-spinner.component';
import {ModelSelectorComponent} from './components/model-selector/model-selector.component';
import {OfflineDevicesComponent} from './components/offline-devices/offline-devices.component';
import {RestreamComponent} from './components/re-stream/restream.component';
import {StartStreamCardComponent} from './components/start-stream-card/start-stream-card.component';
import {StreamLimitDialog} from './components/stream-limit-dialog/stream-limit.component';
import {StreamingInfoComponent} from './components/streaming-info/streaming-info.component';
import {LiveRoutingModule} from './live-routing.module';
import {LiveStreamPage} from './pages/live-stream-page/live-stream-page.component';
import {StreamWebrtcComponent} from './pages/stream-webrtc/stream-webrtc.component';
import {LiveEffects} from './store/live.effects';
import {liveFeatureKey, liveReducer} from './store/live.reducer';
import {scheduleFeatureKey, reducer as liveScheduleReducer} from './store/schedules/live-schedule.reducer';
import {SpinnerModule} from '@app/shared/spinner/spinner.module';
import {AclDirective} from '@app/core/directives/acl.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FullscreenOverlayContainer, OverlayContainer} from '@angular/cdk/overlay';
import {LiveVideoPlayerModule} from '@app/shared/live-video-player/live-video-player.module';
import {HeaderStreamComponent} from './components/header/header-stream.component';
import {StreamSchedulerPageComponent} from './pages/stream-scheduler-page/stream-scheduler-page.component';
import {StaticHeaderComponent} from '@app/shared/static-header/static-header.component';
import {ScheduleListComponent} from './components/schedule-list/schedule-list.component';
import {DeleteScheduleDialogComponent} from './components/delete-schedule-dialog/delete-schedule-dialog.component';
import {NewScheduleComponent} from './components/new-schedule/new-schedule.component';
import {NumbersOnlyDirectiveModule} from '@app/shared/numbers-only-directive/numbers-only.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SchedulerTimeSelectorComponent} from './components/scheduler-time-selector/scheduler-time-selector.component';
import {SchedulerAiAppsComponent} from './components/scheduler-ai-apps/scheduler-ai-apps.component';
import {LiveScheduleEffects} from '@app/live/store/schedules/live-schedule.effects';
import {EditStreamSchedulePageComponent} from './pages/edit-stream-schedule-page/edit-stream-schedule-page.component';
import {LowBatteryDialog} from './components/low-battery-dialog/low-battery-dialog.component';
import {ContinueWatchingComponent} from './components/continue-watching/continue-watching.component';
import {EmptyStateStreamCardsComponent} from './components/empty-state-stream-cards/empty-state-stream-cards.component';
import {ModelDescriptionPipe} from './pipes/model-description.pipe';
import {MtxTooltipModule} from '@ng-matero/extensions/tooltip';

@NgModule({
  declarations: [
    ModelLoadingSpinnerComponent,
    ModelSelectorComponent,
    OfflineDevicesComponent,
    StreamWebrtcComponent,
    RestreamComponent,
    FourGridLayoutComponent,
    DeviceTableComponent,
    DeviceComponent,
    StreamLimitDialog,
    EmbedCodeDialog,
    LiveStreamPage,
    CloseDialogComponent,
    StreamingInfoComponent,
    HeaderStreamComponent,
    StreamSchedulerPageComponent,
    ScheduleListComponent,
    DeleteScheduleDialogComponent,
    NewScheduleComponent,
    SchedulerTimeSelectorComponent,
    SchedulerAiAppsComponent,
    EditStreamSchedulePageComponent,
    LowBatteryDialog,
    ContinueWatchingComponent
  ],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UnleashMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ClipboardModule,
    LiveRoutingModule,
    VideogularModule,
    AnnotationSharedModule,
    // NgxAgoraModule.forRoot({AppID: environment.PWA_STREAMING_APP_ID}),
    UnleashLoaderModule,
    WebrtcPlayerComponent,
    AnalysisConfigurationModule,
    ManageZonesDialogModule,
    StoreModule.forFeature(liveFeatureKey, liveReducer),
    StoreModule.forFeature(scheduleFeatureKey, liveScheduleReducer),
    EffectsModule.forFeature([LiveEffects, LiveScheduleEffects]),
    SharedPipesModule,
    SpinnerModule,
    AclDirective,
    MatTooltipModule,
    StaticHeaderComponent,
    NumbersOnlyDirectiveModule,
    DragDropModule,
    LiveVideoPlayerModule,
    EmptyStateStreamCardsComponent,
    StartStreamCardComponent,
    ModelDescriptionPipe,
    MtxTooltipModule
  ],
  exports: [],
  providers: [DatePipe, {provide: OverlayContainer, useClass: FullscreenOverlayContainer}]
})
export class LiveModule {}

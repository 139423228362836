<section class="delete-dialog">
  <span class="delete-dialog__title"> {{ 'live.scheduler.deleteScheduleTitle' | translate }} </span>
  <p class="delete-dialog__text">{{ 'live.scheduler.deleteScheduleText' | translate: {value: data.title} }}</p>
  <p class="delete-dialog__text">{{ 'live.scheduler.deleteScheduleConfirmationText' | translate }}</p>

  <section class="delete-dialog__actions">
    <a [disabled]="isActionInProgress" mat-dialog-close color="primary" mat-button>{{ 'common.cancel' | translate }}</a>
    <a
      [disabled]="isActionInProgress"
      [class.delete-dialog__disabled]="isActionInProgress"
      (click)="deleteSchedule()"
      mat-button
      class="delete-dialog__delete"
    >
      <section class="delete-dialog__delete-content">
        <mat-icon>delete</mat-icon>
        <div class="delete-dialog__delete-text">{{ 'common.delete' | translate }}</div>
      </section>
      <unleash-spinner *ngIf="isActionInProgress" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
    </a>
  </section>
</section>

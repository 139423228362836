import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {filter, map, Observable, tap} from 'rxjs';
import {AclService} from '../services/acl.service';
import {AclPermissions} from '../models/api/acl.model';

@Injectable({
  providedIn: 'root'
})
export class MissionPlannerGuard {
  private readonly aclPermission = AclPermissions.MissionsApiRead;

  constructor(private aclService: AclService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const mission = route.queryParamMap.get('mission');
    if (+mission === 1) {
      return this.aclService.hasSetupPermission$.pipe(
        filter(hasSetupPermission => !!hasSetupPermission),
        map(() => this.aclService.hasPermission(this.aclPermission)),
        tap(permission => {
          if (!permission) {
            this.router.navigate(['secure/dashboard']);
          }
        })
      );
    }
    return true;
  }
}

export enum UPLOAD_METHODS {
  FILE = 'File',
  FOLDER = 'Folder',
  DRAG_DROP = 'DragDrop'
}

export interface UPLOAD {
  files: FileList;
  uploadType: UPLOAD_METHODS;
}

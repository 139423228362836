import {ShapeTypes} from '@app/core/models/api/label-config.model';
import {DrawTool} from '@app/shared/image-annotation-shared/models/draw-tool';
import {
  Canvas,
  Draw,
  LabelOpacity,
  SingleLabel
} from '@app/shared/image-annotation-shared/models/image-annotation.model';
import {cloneDeep} from 'lodash';
import {Observable, OperatorFunction, Subscription, fromEvent, throttleTime} from 'rxjs';
import tinycolor from 'tinycolor2';

declare var SVG: any;
export class LineInOutFeature extends DrawTool {
  public fillBackgroundUrl: string = 'assets/images/arrow-green-30-50.png';
  public resizingSub: any;
  public xHeightTag: number;
  public xWidthTag: number;
  public heightTag: number;
  public inWidthTag: number;
  public outWidthTag: number;
  public verticalOffsetX: number;
  public centerXLine: any;
  public centerYLine: any;
  public lineConnectorHeight: number;
  public lineConnectorWidth: number;
  public lineInTagX: any;
  public lineInTagY: any;
  public lineOutTagX: number;
  public lineInTag: any;
  public lineOutTag: any;
  public lineOutTagY: number;
  public lineInTagCenterX: any;
  public lineOutTagCenterX: any;
  public lineInTagCenterY: any;
  public lineOutTagCenterY: any;
  public lineInCloseX: any;
  public lineInCloseY: number;
  public lineOutCloseX: any;
  public lineOutCloseY: number;
  public lineInConnectorLine: any;
  public lineOutConnectorLine: any;
  public lineInClose: any;
  public lineOutClose: any;
  public lineInEnd: any;
  public lineOutEnd: any;
  public closeIconIn: any;
  public closeIconOut: any;
  public directionIconIn: any;
  public directionIconOut: any;
  public lineInText: any;
  public lineOutText: any;
  public lineInTextContent: string = 'IN';
  public lineOutTextContent: string = 'OUT';
  public dragmoveSub: any;
  public circleDeleteMe: any;
  public circleDeleteMeTwo: any;
  public circleDeleteMeThree: any;

  public isInEnabled: boolean = true;
  public isOutEnabled: boolean = true;
  public xIconPath: string =
    'M11.0834 3.739L10.2609 2.9165L7.00008 6.17734L3.73925 2.9165L2.91675 3.739L6.17758 6.99984L2.91675 10.2607L3.73925 11.0832L7.00008 7.82234L10.2609 11.0832L11.0834 10.2607L7.82258 6.99984L11.0834 3.739Z';
  public plusIconPath: string =
    'M9.08317 5.58317H5.58317V9.08317H4.4165V5.58317H0.916504V4.4165H4.4165V0.916504H5.58317V4.4165H9.08317V5.58317Z';

  public directionIconPathUp = 'M4.36327 0.51709L0.0263667 7.51709L8.02637 7.51709L4.36327 0.51709Z';
  public directionIconPathDown = 'M4.37792 7.51709L0.0410151 0.51709L8.04102 0.51709L4.37792 7.51709Z';
  public directionIconPathRight = 'M7.04492 3.67921L0.0449218 8.01611L0.0449218 0.0161133L7.04492 3.67921Z';
  public directionIconPathLeft = 'M0.0449219 4.35302L7.04492 0.0161128L7.04492 8.01611L0.0449219 4.35302Z';
  public directionIconPath = 'M3.6631 0.5L8 7.5L0 7.5L3.6631 0.5Z';

  public referenceName = {
    group: 'group',
    lineInTag: 'line-in-tag',
    lineOutTag: 'line-out-tag',
    lineInClose: 'line-in-close',
    lineOutClose: 'line-out-close',
    lineInEnd: 'line-in-end',
    lineOutEnd: 'line-out-end',
    lineInConnectorLine: 'line-in-connector-line',
    lineOutConnectorLine: 'line-out-connector-line',
    closeIconIn: 'close-icon-in',
    closeIconOut: 'close-icon-out',
    directionIconIn: 'direction-icon-in',
    directionIconOut: 'direction-icon-out',
    lineInText: 'line-in-text',
    lineOutText: 'line-out-text',
    startCircle: 'start-circle',
    endCircle: 'end-circle'
  };
  public fill: string;
  public stroke: string;
  public group: Draw;
  public startCircle: any;
  public endCircle: any;
  public dragmoveLineInEndSub: Subscription;
  public dragmoveLineOutEndSub: Subscription;
  private angleError = 0.02;

  constructor(ref: Draw, canvas: Canvas) {
    super(canvas);
    this.ref = ref;
    this.type = ShapeTypes.line_in_out;
  }

  public generateDrawTemplate(): SingleLabel {
    throw new Error('Method not implemented.');
  }

  public exportDraw(singleLabel: SingleLabel): [SingleLabel, string] {
    throw new Error('Method not implemented.');
  }

  public importDraw(
    label: SingleLabel,
    scalingFactor: number,
    canvasSize: {width: number; height: number},
    originalSize: {width: number; height: number}
  ): SingleLabel {
    let vertices = label.shape.vertices;
    if (label.shape?.length > 0) {
      vertices = label.shape.map(vertices => ({x: vertices[0], y: vertices[1]}));
    }

    let matrix = null;
    let angle = 0;

    const widthScale = canvasSize.width / originalSize.width;
    const heightScale = canvasSize.height / originalSize.height;

    if (label.matrix) {
      matrix = new SVG.Matrix(
        this.scaleMatrix(label.matrix, {
          width_scale: widthScale,
          height_scale: heightScale
        })
      );

      const rotationAngleRad = Math.atan2(matrix.b, matrix.a);
      const rotationAngleDeg = (rotationAngleRad * 180) / Math.PI;
      angle = rotationAngleDeg < 0 ? rotationAngleDeg + 360 : rotationAngleDeg;
    }

    if (label.original_shape.length > 2) {
      label.isInEnabled = true;
      label.isOutEnabled = true;
    }

    const shapeVertices = this.scaleLineInOutShape(
      label.original_shape,
      {
        width_scale: widthScale,
        height_scale: heightScale
      },
      angle
    );

    const scaledShape = [];
    for (const point of shapeVertices) {
      const p = new SVG.Point(point[0], point[1]);
      const transformedPoint = p.transform(matrix);
      scaledShape.push([transformedPoint.x, transformedPoint.y]);
    }

    let color = label.color;
    if (typeof label.color === 'object') {
      color = (label as any).color.fill;
    }

    const colorEditable = tinycolor(color);
    colorEditable.setAlpha(LabelOpacity.full);
    color = colorEditable.toString();

    const singleLabel: SingleLabel = {
      id: label.id,
      displayName: (label as any).display_name,
      visibility: true,
      shapeType: label.shapeType,
      category: label.category,
      shape: scaledShape,
      matrix,
      severity: label.severity,
      comment: label.comment,
      color: color,
      isAI: label.isAI,
      isAccepted: label.isAccepted,
      isModified: label.isModified,
      suggestedCategory: label.suggestedCategory,
      addonId: label.addonId,
      distance: label.distance,
      metadata: JSON.stringify(label.metadata),
      isInEnabled: label.isInEnabled,
      isOutEnabled: label.isOutEnabled
    };

    this.setScalingFactorForCircleRadius(scalingFactor);

    this.ref.type = ShapeTypes.line_in_out;
    this.ref.attr('id', singleLabel.id);
    this.ref.attr('fill', singleLabel.color);
    this.ref.attr('fill-opacity', LabelOpacity.full);
    this.ref.attr('stroke', '#000');
    this.ref.attr('stroke-opacity', LabelOpacity.full);
    this.ref.attr('stroke-width', this.vertexStrokeWidth + 'px');
    this.ref.attr('metadata', JSON.stringify(label.metadata));
    this.ref.attr('isInEnabled', singleLabel.isInEnabled);
    this.ref.attr('isOutEnabled', singleLabel.isOutEnabled);
    this.ref.attr('display-name', singleLabel.displayName);
    this.ref.attr('shape-type', this.type);
    this.ref.plot(scaledShape);

    this.ref.attr({
      stroke: singleLabel.color,
      'stroke-width': '3',
      'stroke-dasharray': '4',
      fill: singleLabel.color
    });

    this.setupConstants();
    this.lineInTag = this.canvas
      .rect(this.inWidthTag, this.heightTag)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineInTag}`});
    this.lineOutTag = this.canvas
      .rect(this.outWidthTag, this.heightTag)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineOutTag}`});

    this.lineInConnectorLine = this.canvas
      .polyline([0.0])
      .stroke({color: singleLabel.color, width: 2})
      .attr({id: `${this.id}-${this.referenceName.lineInConnectorLine}`});
    this.lineOutConnectorLine = this.canvas
      .polyline([0.0])
      .stroke({color: singleLabel.color, width: 2})
      .attr({id: `${this.id}-${this.referenceName.lineOutConnectorLine}`});

    this.lineInClose = this.canvas
      .rect(this.xHeightTag, this.xWidthTag)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineInClose}`});
    this.lineOutClose = this.canvas
      .rect(this.xHeightTag, this.xWidthTag)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineOutClose}`});

    this.lineInEnd = this.canvas
      .rect(8, 8)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineInEnd}`});
    this.lineOutEnd = this.canvas
      .rect(8, 8)
      .attr({fill: singleLabel.color, id: `${this.id}-${this.referenceName.lineOutEnd}`});

    this.closeIconIn = this.canvas
      .path(this.xIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.closeIconIn}`});
    this.closeIconOut = this.canvas
      .path(this.xIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.closeIconOut}`});
    this.directionIconIn = this.canvas
      .path(this.directionIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.directionIconIn}`});
    this.directionIconOut = this.canvas
      .path(this.directionIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.directionIconOut}`});

    this.lineInText = (this.canvas as any).text(this.lineInTextContent).attr({
      fill: '#FFF',
      'font-family': 'Rubik',
      'font-weight': 500,
      style: 'user-select: none',
      'letter-spacing': '0.75px',
      'font-size': '12px',
      id: `${this.id}-${this.referenceName.lineInText}`
    });
    this.lineOutText = (this.canvas as any).text(this.lineOutTextContent).attr({
      fill: '#FFF',
      'font-family': 'Rubik',
      'font-weight': 500,
      style: 'user-select: none',
      'letter-spacing': '0.75px',
      'font-size': '12px',
      id: `${this.id}-${this.referenceName.lineOutText}`
    });

    const lineBbox = (this.ref as any).array().value;
    const x1 = lineBbox[0][0];
    const y1 = lineBbox[0][1];
    const x2 = lineBbox[1][0];
    const y2 = lineBbox[1][1];

    this.startCircle = this.canvas
      .circle(8)
      .attr({id: `${this.id}-${this.referenceName.startCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x1, y1);
    this.endCircle = this.canvas
      .circle(8)
      .attr({id: `${this.id}-${this.referenceName.endCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x2, y2);

    this.group = this.canvas.group().attr({id: `${this.id}-${this.referenceName.group}`});
    this.group.add(this.ref);
    this.group.add(this.lineInConnectorLine);
    this.group.add(this.lineOutConnectorLine);
    this.group.add(this.lineInTag);
    this.group.add(this.lineOutTag);
    this.group.add(this.lineInClose);
    this.group.add(this.lineInText);
    this.group.add(this.lineOutText);
    this.group.add(this.lineOutClose);
    this.group.add(this.closeIconIn);
    this.group.add(this.closeIconOut);
    this.group.add(this.lineInEnd);
    this.group.add(this.lineOutEnd);
    this.group.add(this.directionIconIn);
    this.group.add(this.directionIconOut);
    this.group.add(this.startCircle);
    this.group.add(this.endCircle);

    this.updateLinesPosition();
    return singleLabel;
  }

  public restoreBackup(drawBak: any) {
    super.restoreBackup(drawBak);

    if (drawBak?.points) {
      this.ref.attr('points', drawBak.points);
    }

    if (drawBak?.arrayPoints) {
      if (this.ref?._array?.value) {
        this.ref._array.value = cloneDeep(drawBak.arrayPoints);
        this.ref.fill(this.fillBackgroundUrl);
      } else {
        this.ref.plot(cloneDeep(drawBak.arrayPoints));
        this.ref.attr({
          stroke: drawBak?.color,
          'stroke-width': '3',
          'stroke-dasharray': '4',
          fill: drawBak?.color
        });
      }
    }

    if (drawBak?.color) {
      this.ref.attr({fill: drawBak.color});
      this.fill = drawBak.color;
      this.stroke = drawBak.color;
    }

    if (drawBak?.stroke) {
      this.ref.attr({stroke: drawBak.stroke});
    }

    this.isInEnabled = drawBak.isInEnabled;
    this.ref.attr('isInEnabled', drawBak.isInEnabled);

    this.isOutEnabled = drawBak.isOutEnabled;
    this.ref.attr('isOutEnabled', drawBak.isOutEnabled);

    const lineBbox = (this.ref as any).array().value;
    const x1 = lineBbox[0][0];
    const y1 = lineBbox[0][1];
    const x2 = lineBbox[1][0];
    const y2 = lineBbox[1][1];

    this.startCircle
      .attr({id: `${this.id}-${this.referenceName.startCircle}`, fill: drawBak.color, opacity: LabelOpacity.full})
      .center(x1, y1);
    this.endCircle
      .attr({id: `${this.id}-${this.referenceName.endCircle}`, fill: drawBak.color, opacity: LabelOpacity.full})
      .center(x2, y2);

    this.updateLinesPosition();
  }

  public saveShapeBackup() {
    return {
      arrayPoints: cloneDeep(this.ref?._array?.value || (this.ref as any).array().value),
      points: this.ref.attr('points'),
      color: this.ref.attr('fill'),
      stroke: this.ref.attr('stroke'),
      isInEnabled: this.generateBooleanFromText(this.ref.attr('isInEnabled')),
      isOutEnabled: this.generateBooleanFromText(this.ref.attr('isOutEnabled'))
    };
  }

  public hasDraw(): boolean {
    if (!this.isValidClick) {
      return;
    }

    const lineBbox = (this.ref as any).array().value;
    const hasTwoPoints = lineBbox[0][0] !== lineBbox[1][0] && lineBbox[0][1] !== lineBbox[1][1] && lineBbox[1][0] > 0;
    return hasTwoPoints;
  }

  public startDraw(opts?: Partial<{id: string; color: string; stroke: string; name: string}>) {
    this.ref.remember('hasToDraw', true);
    this.fill = opts.stroke;
    this.stroke = opts.stroke;

    this.clickOnCanvasExtraElements();

    this.ref.draw({
      closeLastPoint: false,
      circleDiameter: this.circleDiameter,
      fill: this.fill,
      skipPointConstrain: false
    });

    this.ref.attr({
      id: opts.id,
      stroke: this.stroke,
      'stroke-width': '3',
      'stroke-dasharray': '4',
      fill: this.fill,
      isInEnabled: true,
      isOutEnabled: true,
      'display-name': opts.name
    });
  }

  public editDraw() {
    this.bringToFront();
    this.startSelection();
    this.unBindOnClick();
    this.updateLinesPosition();
  }

  protected startSelection() {
    this.unbindInteractions();

    if (this.type !== ShapeTypes.line_in_out) {
      this.ref.attr('stroke-dasharray', '0');
    }

    this.updateReferences();
    this.ref
      .selectize({
        rotationPoint: false,
        deepSelect: true,
        pointFill: this.fill,
        pointSize: this.vertexDiameterPointsExtended,
        pointStroke: {width: this.vertexStrokeWidth},
        pointPadding: this.transformBoxSize,
        vertexSize: this.vertexDiameterPoints,
        vertexStroke: {width: this.vertexStrokeWidth},
        transformBoxSize: this.transformBoxSize
      })
      .resize({constraint: this.constrain})
      .draggable(this.constrain);

    this.startCircle?.attr('opacity', LabelOpacity.hidden);
    this.endCircle?.attr('opacity', LabelOpacity.hidden);

    this.bindClickEvents();

    const resizing$ = fromEvent(this.ref as any, 'resizing');
    this.resizingSub = resizing$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });

    const dragmove$ = fromEvent(this.ref as any, 'dragmove');
    this.dragmoveSub = dragmove$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });
  }

  public disableTagNameEvents() {
    if (!this.ref) {
      return;
    }

    this.group = SVG.get(`${this.id}-${this.referenceName.group}`);
    this.tagNameGroup = SVG.get(`${this.id}-${this.referenceTagName.group}`);

    this.ref.off('mouseenter');
    this.ref.off('mouseleave');
    this.group?.off('mouseenter');
    this.group?.off('mouseleave');
    this.tagNameGroup?.off('mouseenter');
    this.tagNameGroup?.off('mouseleave');
    this.tagNameGroup?.off('click');
    this.tagNameGroup?.back();
    this.group?.front();
  }

  public enableTagNameEvents(): void {
    if (!this.ref) {
      return;
    }

    this.group = SVG.get(`${this.id}-${this.referenceName.group}`);
    this.tagNameGroup = SVG.get(`${this.id}-${this.referenceTagName.group}`);

    if (!this.group || !this.tagNameGroup) {
      return;
    }

    this.group.on('mouseenter', () => this.displayZoneTag());
    this.group.on('mouseleave', () => this.hideZoneTag());
    this.tagNameGroup.on('mouseenter', () => this.displayZoneTag());
    this.tagNameGroup.on('mouseleave', () => this.hideZoneTag());
    this.tagNameGroup.on('click', () => this.ref.fire('click'));
    this.tagNameGroup.front();
    this.group.back();
  }

  public stopSelection() {
    this.updateReferences();
    this.ref.resize('stop').draggable(false).selectize(false, {deepSelect: true}).selectize(false);

    const lineBbox = (this.ref as any).array().value;
    const x1 = lineBbox[0][0];
    const y1 = lineBbox[0][1];
    const x2 = lineBbox[1][0];
    const y2 = lineBbox[1][1];

    this.startCircle
      ?.attr({id: `${this.id}-${this.referenceName.startCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x1, y1);
    this.endCircle
      ?.attr({id: `${this.id}-${this.referenceName.endCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x2, y2);
  }

  public updateColor(colorToUse: string) {
    this.updateReferences();

    const color = tinycolor(colorToUse).setAlpha(1).toString();
    this.ref.attr('fill', color);
    this.ref.attr('stroke', color);
    this.fill = color;
    this.stroke = color;
    this.ref.attr({stroke: color, fill: color});
    this.lineInTag.attr({fill: color});
    this.lineOutTag.attr({fill: color});
    this.lineInEnd.attr({fill: color});
    this.lineOutEnd.attr({fill: color});
    this.lineInClose.attr({fill: tinycolor(color).darken(10).toString()});
    this.lineOutClose.attr({fill: tinycolor(color).darken(10).toString()});
    this.lineInConnectorLine.attr({stroke: color});
    this.lineOutConnectorLine.attr({stroke: color});
    this.startCircle.attr({fill: color});
    this.endCircle.attr({fill: color});

    const vertexs = document.getElementsByClassName('svg_vertex_margin');
    for (let i = 0; i < vertexs.length; i++) {
      (vertexs[i] as HTMLElement).style.fill = color;
    }
  }

  private scaleLineInOutShape(
    shape: [number, number][],
    canvasSizeScale: {width_scale: number; height_scale: number},
    angle: number = 0
  ): number[][] {
    const newShape: any = [];

    if (shape.length > 2) {
      let polygon = shape.map(shape => ({
        x: shape[0] * canvasSizeScale.width_scale,
        y: shape[1] * canvasSizeScale.height_scale
      }));

      const middleOneX = (polygon[0].x + polygon[1].x) / 2;
      const middleOneY = (polygon[0].y + polygon[1].y) / 2;

      const middleTwoX = (polygon[2].x + polygon[3].x) / 2;
      const middleTwoY = (polygon[2].y + polygon[3].y) / 2;

      newShape.push([middleOneX, middleOneY]);
      newShape.push([middleTwoX, middleTwoY]);

      return newShape;
    }

    if (shape.length === 2) {
      for (const point of shape as number[][]) {
        const xy = [point[0] * canvasSizeScale.width_scale, point[1] * canvasSizeScale.height_scale];
        newShape.push(xy);
      }
      return newShape;
    }

    return newShape;
  }

  private scaleMatrix(
    matrix: {a: number; b: number; c: number; d: number; e: number; f: number},
    canvasSizeScale: {width_scale: number; height_scale: number}
  ): any {
    const normalizedMatrix = {...matrix};
    if (normalizedMatrix && normalizedMatrix.e) {
      normalizedMatrix.e *= canvasSizeScale.width_scale;
    }

    if (normalizedMatrix && normalizedMatrix.f) {
      normalizedMatrix.f *= canvasSizeScale.height_scale;
    }
    return normalizedMatrix;
  }

  private updateLinesPosition() {
    this.fill = this.ref.attr('fill');
    const lineBbox = (this.ref as any).array().value;
    const x1 = lineBbox[0][0];
    const y1 = lineBbox[0][1];
    const x2 = lineBbox[1][0];
    const y2 = lineBbox[1][1];

    const direction = LineInOutFeature.calculateDirection({x: x1, y: y1}, {x: x2, y: y2});

    const distanceIncrement = 30;
    let directionValue = 0;

    if (direction.y > 0 && direction.y < 1) {
      if (direction.y < 0.2) {
        directionValue = 0.2 * distanceIncrement;
      } else {
        directionValue = direction.y * distanceIncrement;
      }
    } else {
      if (direction.x == 0 && direction.y == 1) {
        directionValue = direction.y * distanceIncrement;
      } else {
        directionValue = direction.y * -distanceIncrement;
      }
    }

    const normalX = direction.x;
    const normalY = direction.y;
    const angleRad = Math.atan2(normalY, normalX);
    let angleDeg = (angleRad * 180) / Math.PI;

    if (angleDeg < 0) {
      angleDeg += 360;
    }

    let distance = this.ref.width();
    if (distance < 50) {
      distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    this.centerXLine = (x1 + x2) / 2;
    this.centerYLine = (y1 + y2) / 2;

    const intersectionPoints = this.findIntersectionPoints(
      {x: x1, y: y1},
      {x: x2, y: y2},
      {x: this.centerXLine, y: this.centerYLine},
      16,
      angleDeg
    );

    const perpendicularOne = this.getPerpendicularPoints(
      {p1: {x: x1, y: y1}, p2: {x: x2, y: y2}},
      intersectionPoints[0],
      16,
      16,
      directionValue,
      angleDeg,
      true
    );

    const perpendicularTwo = this.getPerpendicularPoints(
      {p1: {x: x1, y: y1}, p2: {x: x2, y: y2}},
      intersectionPoints[1],
      16,
      16,
      directionValue,
      angleDeg,
      false
    );

    let currentIconIn = this.upDirectionIcon(angleDeg);
    let currentIconOut = this.downDirectionIcon(angleDeg);

    // Normal position
    const extraDistance = this.extraDistanceBasedOnDegrees(angleDeg);

    this.lineInTagX = perpendicularOne.p2.x - 10;
    this.lineInTagY = perpendicularOne.p2.y;
    this.lineOutTagX = perpendicularTwo.p1.x + (angleDeg > 180 ? -extraDistance : extraDistance);
    this.lineOutTagY = perpendicularTwo.p1.y;

    if (!this.isOutEnabled) {
      this.lineOutClose.center(intersectionPoints[1].x, intersectionPoints[1].y);
      this.closeIconOut.plot(this.plusIconPath);
      this.closeIconOut.center(intersectionPoints[1].x, intersectionPoints[1].y);
      this.lineOutConnectorLine.attr({opacity: LabelOpacity.hidden});
      this.lineOutEnd.attr({opacity: LabelOpacity.hidden});
    } else {
      this.lineOutEnd
        .center(perpendicularTwo.p2.x, perpendicularTwo.p2.y)
        .attr({opacity: LabelOpacity.full, fill: this.fill});
      this.lineOutConnectorLine
        .plot([
          [perpendicularTwo.p2.x, perpendicularTwo.p2.y],
          [perpendicularTwo.p1.x, perpendicularTwo.p1.y]
        ])
        .attr({opacity: LabelOpacity.full, stroke: this.stroke});
      this.lineOutClose.attr({fill: tinycolor(this.fill).darken(10).toString()});
      this.closeIconOut.attr({fill: 'white', opacity: LabelOpacity.highlight});
    }

    if (!this.isInEnabled) {
      this.lineInClose.center(intersectionPoints[0].x, intersectionPoints[0].y);
      this.closeIconIn.plot(this.plusIconPath);
      this.closeIconIn.center(intersectionPoints[0].x, intersectionPoints[0].y);
      this.lineInConnectorLine.attr({opacity: LabelOpacity.hidden, stroke: this.stroke});
      this.lineInEnd.attr({opacity: LabelOpacity.hidden});
    } else {
      this.lineInConnectorLine
        .plot([
          [perpendicularOne.p2.x, perpendicularOne.p2.y],
          [perpendicularOne.p1.x, perpendicularOne.p1.y]
        ])
        .attr({opacity: LabelOpacity.full, stroke: this.stroke});
      this.lineInEnd
        .center(perpendicularOne.p1.x, perpendicularOne.p1.y)
        .attr({opacity: LabelOpacity.full, fill: this.fill});
      this.lineInClose.attr({fill: tinycolor(this.fill).darken(10).toString()});
      this.closeIconIn.attr({fill: 'white', opacity: LabelOpacity.highlight});
    }

    // lineIn
    if (this.isInEnabled) {
      this.lineInTag.center(this.lineInTagX, this.lineInTagY).attr({opacity: LabelOpacity.full, fill: this.fill});
      this.lineInTagCenterX = this.lineInTag.cx();
      this.lineInTagCenterY = this.lineInTag.cy();
      this.lineInCloseX = this.lineInTagCenterX + this.inWidthTag / 2;
      this.lineInCloseY = this.lineInTagCenterY - this.heightTag / 2;
      this.lineInClose.move(this.lineInCloseX, this.lineInCloseY);
      this.lineInText
        .move(this.lineInTag.cx(), this.lineInTag.cy() - this.lineInText.bbox().height / 2)
        .attr({opacity: LabelOpacity.full});
      this.closeIconIn.plot(this.xIconPath);
      this.closeIconIn.center(this.lineInClose.bbox().cx, this.lineInClose.bbox().cy);

      this.directionIconIn.plot(currentIconIn);
      this.directionIconIn.center(this.lineInTag.bbox().cx - 8, this.lineInTag.bbox().cy);
      this.directionIconIn.attr({opacity: LabelOpacity.highlight});
    } else {
      this.lineInTag.attr({opacity: LabelOpacity.hidden});
      this.lineInText.attr({opacity: LabelOpacity.hidden});
      this.directionIconIn.attr({opacity: LabelOpacity.hidden});
    }

    // lineOut
    if (this.isOutEnabled) {
      this.lineOutTag.center(this.lineOutTagX, this.lineOutTagY).attr({opacity: LabelOpacity.full, fill: this.fill});
      this.lineOutTagCenterX = this.lineOutTag.bbox().cx;
      this.lineOutTagCenterY = this.lineOutTag.bbox().cy;
      this.lineOutCloseX = this.lineOutTagCenterX + this.outWidthTag / 2;
      this.lineOutCloseY = this.lineOutTagCenterY - this.heightTag / 2;
      this.lineOutClose.move(this.lineOutCloseX, this.lineOutCloseY);
      this.lineOutText
        .move(
          this.lineOutTag.cx() - this.lineOutText.bbox().width / 4,
          this.lineOutTag.cy() - this.lineOutText.bbox().height / 2
        )
        .attr({opacity: LabelOpacity.full});

      this.closeIconOut.plot(this.xIconPath);
      this.closeIconOut.center(this.lineOutClose.bbox().cx, this.lineOutClose.bbox().cy);
      this.directionIconOut.plot(currentIconOut);
      this.directionIconOut.center(this.lineOutTag.bbox().cx - 14, this.lineOutTag.bbox().cy);
      this.directionIconOut.attr({opacity: LabelOpacity.highlight});
    } else {
      this.lineOutTag.attr({opacity: LabelOpacity.hidden});
      this.lineOutText.attr({opacity: LabelOpacity.hidden});
      this.directionIconOut.attr({opacity: LabelOpacity.hidden});
    }

    const hasToHideAuxiliarLines = distance < 50;
    if (hasToHideAuxiliarLines) {
      this.lineInConnectorLine.attr({opacity: LabelOpacity.hidden});
      this.lineOutConnectorLine.attr({opacity: LabelOpacity.hidden});
      this.lineInEnd.attr({opacity: LabelOpacity.hidden});
      this.lineOutEnd.attr({opacity: LabelOpacity.hidden});
    }

    this.closeIconIn.front();
    this.closeIconOut.front();
  }

  public findIntersectionPoints(p1: Point, p2: Point, center: Point, radius: number, angleDeg: number): Point[] {
    const dx = p2.x - p1.x;
    if (dx > -0.001 && dx < 0.001) {
      if (Math.abs(angleDeg - 90) < this.angleError) {
        return [
          {x: center.x, y: center.y - radius},
          {x: center.x, y: center.y + radius}
        ];
      }

      if (Math.abs(angleDeg - 270) < this.angleError) {
        return [
          {x: center.x, y: center.y + radius},
          {x: center.x, y: center.y - radius}
        ];
      }
    }

    const dy = p2.y - p1.y;
    if (dy > -0.001 && dy < 0.001) {
      // Zero degrees
      if (Math.abs(angleDeg) < this.angleError) {
        return [
          {x: center.x - radius, y: center.y},
          {x: center.x + radius, y: center.y}
        ];
      }

      if (Math.abs(angleDeg - 180) < this.angleError) {
        return [
          {x: center.x + radius, y: center.y},
          {x: center.x - radius, y: center.y}
        ];
      }
    }

    const m = (p2.y - p1.y) / (p2.x - p1.x);
    const b = p1.y - m * p1.x;

    const h = center.x;
    const k = center.y;

    const A = 1 + m * m;
    const B = -2 * h + 2 * m * (b - k);
    const C = h * h + k * k + b * b - 2 * k * b - radius * radius;

    const discriminant = B * B - 4 * A * C;

    if (discriminant < 0) {
      return []; // No intersection points
    } else if (discriminant === 0) {
      const x = -B / (2 * A);
      const y = m * x + b;
      return [{x, y}]; // One intersection point
    } else {
      const x1 = (-B + Math.sqrt(discriminant)) / (2 * A);
      const y1 = m * x1 + b;

      const x2 = (-B - Math.sqrt(discriminant)) / (2 * A);
      const y2 = m * x2 + b;

      const intersectionPoint1: Point = {x: x1, y: y1};
      const intersectionPoint2: Point = {x: x2, y: y2};

      // Sort the intersection points based on proximity to p1
      const distance1 = Math.sqrt(Math.pow(p1.x - x1, 2) + Math.pow(p1.y - y1, 2));
      const distance2 = Math.sqrt(Math.pow(p1.x - x2, 2) + Math.pow(p1.y - y2, 2));

      if (distance1 < distance2) {
        return [intersectionPoint1, intersectionPoint2];
      } else {
        return [intersectionPoint2, intersectionPoint1];
      }
    }
  }

  public updateReferences() {
    this.setupConstants();

    this.lineInTag = SVG.get(`${this.id}-${this.referenceName.lineInTag}`);
    this.lineOutTag = SVG.get(`${this.id}-${this.referenceName.lineOutTag}`);
    this.lineInClose = SVG.get(`${this.id}-${this.referenceName.lineInClose}`);
    this.lineOutClose = SVG.get(`${this.id}-${this.referenceName.lineOutClose}`);
    this.lineInEnd = SVG.get(`${this.id}-${this.referenceName.lineInEnd}`);
    this.lineOutEnd = SVG.get(`${this.id}-${this.referenceName.lineOutEnd}`);
    this.lineInConnectorLine = SVG.get(`${this.id}-${this.referenceName.lineInConnectorLine}`);
    this.lineOutConnectorLine = SVG.get(`${this.id}-${this.referenceName.lineOutConnectorLine}`);
    this.closeIconIn = SVG.get(`${this.id}-${this.referenceName.closeIconIn}`);
    this.closeIconOut = SVG.get(`${this.id}-${this.referenceName.closeIconOut}`);
    this.directionIconIn = SVG.get(`${this.id}-${this.referenceName.directionIconIn}`);
    this.directionIconOut = SVG.get(`${this.id}-${this.referenceName.directionIconOut}`);
    this.lineInText = SVG.get(`${this.id}-${this.referenceName.lineInText}`);
    this.lineOutText = SVG.get(`${this.id}-${this.referenceName.lineOutText}`);
    this.startCircle = SVG.get(`${this.id}-${this.referenceName.startCircle}`);
    this.endCircle = SVG.get(`${this.id}-${this.referenceName.endCircle}`);
    this.group = SVG.get(`${this.id}-${this.referenceName.group}`);
  }

  public toggleIn() {
    if (!this.isOutEnabled && this.isInEnabled) {
      return;
    }

    this.isInEnabled = this.generateBooleanFromText(this.ref.attr('isInEnabled'));
    this.isInEnabled = !this.isInEnabled;
    this.ref.attr('isInEnabled', this.isInEnabled);
    this.updateReferences();
    this.updateLinesPosition();
  }

  public toggleOut() {
    if (!this.isInEnabled && this.isOutEnabled) {
      return;
    }

    this.isOutEnabled = this.generateBooleanFromText(this.ref.attr('isOutEnabled'));
    this.isOutEnabled = !this.isOutEnabled;
    this.ref.attr('isOutEnabled', this.isOutEnabled);
    this.updateReferences();
    this.updateLinesPosition();
  }

  public bindOnInClick(inClickCallback: (data?: any) => any) {
    this.ref.on('lineInClick', () => inClickCallback());
  }

  public bindOnOutClick(outClickCallback: (data?: any) => any) {
    this.ref.on('lineOutClick', () => outClickCallback());
  }

  public bindLineInSwapClick(inClickCallback: (data?: any) => any) {
    this.ref.on('lineInSwapClick', () => inClickCallback());
  }

  public bindLineOutSwapClick(outClickCallback: (data?: any) => any) {
    this.ref.on('lineOutSwapClick', () => outClickCallback());
  }

  public bindAtLeastOneInOutPositionEnabled(atLeastOneInOutPositionEnabled: () => void) {
    this.ref.on('atLeastOneInOutPositionEnabled', () => atLeastOneInOutPositionEnabled());
  }

  public bindDrawPointClick(drawPointClickCallback: (data?: any) => any) {
    this.ref.on('drawpoint', () => drawPointClickCallback());
  }

  public swapDirections(): void {
    const reversePoints = this.ref.array().value.reverse();
    this.ref.plot(reversePoints);
    this.updateReferences();
    this.updateLinesPosition();
  }

  public removeDraw() {
    super.removeDraw();
    this.updateReferences();
    this.removeReferences();
  }

  public downDirectionIcon(angleDeg: number): string {
    let currentIconIn = this.directionIconPathDown;
    if (angleDeg > 0 && angleDeg < 45) {
      currentIconIn = this.directionIconPathDown;
    } else if (angleDeg > 45 && angleDeg < 90) {
      currentIconIn = this.directionIconPathLeft;
    } else if (angleDeg >= 90 && angleDeg < 135) {
      currentIconIn = this.directionIconPathLeft;
    } else if (angleDeg >= 135 && angleDeg < 180) {
      currentIconIn = this.directionIconPathUp;
    } else if (angleDeg >= 180 && angleDeg < 225) {
      currentIconIn = this.directionIconPathUp;
    } else if (angleDeg >= 225 && angleDeg < 270) {
      currentIconIn = this.directionIconPathRight;
    } else if (angleDeg >= 270 && angleDeg < 305) {
      currentIconIn = this.directionIconPathRight;
    }
    return currentIconIn;
  }

  public upDirectionIcon(angleDeg: number): string {
    let currentIconOut = this.directionIconPathUp;
    if (angleDeg > 0 && angleDeg < 45) {
      currentIconOut = this.directionIconPathUp;
    } else if (angleDeg > 45 && angleDeg < 90) {
      currentIconOut = this.directionIconPathRight;
    } else if (angleDeg >= 90 && angleDeg < 135) {
      currentIconOut = this.directionIconPathRight;
    } else if (angleDeg >= 135 && angleDeg < 180) {
      currentIconOut = this.directionIconPathDown;
    } else if (angleDeg >= 180 && angleDeg < 225) {
      currentIconOut = this.directionIconPathDown;
    } else if (angleDeg >= 225 && angleDeg < 270) {
      currentIconOut = this.directionIconPathLeft;
    } else if (angleDeg >= 270 && angleDeg < 305) {
      currentIconOut = this.directionIconPathLeft;
    }
    return currentIconOut;
  }

  private removeReferences() {
    this.lineInTag?.remove();
    this.lineOutTag?.remove();
    this.lineInClose?.remove();
    this.lineOutClose?.remove();
    this.lineInEnd?.remove();
    this.lineOutEnd?.remove();
    this.lineInConnectorLine?.remove();
    this.lineOutConnectorLine?.remove();
    this.closeIconIn?.remove();
    this.closeIconOut?.remove();
    this.directionIconIn?.remove();
    this.directionIconOut?.remove();
    this.lineInText?.remove();
    this.lineOutText?.remove();
    this.startCircle?.remove();
    this.endCircle?.remove();
    this.group?.remove();
  }

  private getPerpendicularPoints(
    line: Line,
    intersectionPoint: Point,
    distanceX: number,
    distanceY: number,
    directionIncrement: number,
    angleDeg: number,
    isIn: boolean
  ): {p1: Point; p2: Point} {
    const {p1: P1, p2: P2} = line;

    if (P2.y === P1.y) {
      let p1: Point = {x: intersectionPoint.x, y: P1.y - distanceY};
      let p2: Point = {x: intersectionPoint.x, y: P1.y + distanceY};

      // Zero degrees
      if (Math.abs(angleDeg) < this.angleError) {
        p1 = {x: intersectionPoint.x, y: P1.y + distanceY};
        p2 = {x: intersectionPoint.x, y: P1.y - distanceY};
      }

      if (Math.abs(angleDeg - 180) < this.angleError) {
        p1 = {x: intersectionPoint.x, y: P1.y - distanceY};
        p2 = {x: intersectionPoint.x, y: P1.y + distanceY};
      }

      return {p1, p2};
    }

    const dx = P2.x - P1.x;

    if (dx > -0.001 && dx < 0.001) {
      let p1: Point;
      let p2: Point;

      if (Math.abs(angleDeg - 90) < this.angleError) {
        if (isIn) {
          p1 = {x: intersectionPoint.x - distanceX, y: intersectionPoint.y};
          p2 = {x: intersectionPoint.x + distanceX + directionIncrement, y: intersectionPoint.y};
        } else {
          p1 = {x: intersectionPoint.x - distanceX - directionIncrement, y: intersectionPoint.y};
          p2 = {x: intersectionPoint.x + distanceX, y: intersectionPoint.y};
        }
        return {p1, p2};
      }

      if (Math.abs(angleDeg - 270) < this.angleError) {
        if (isIn) {
          p1 = {x: intersectionPoint.x + distanceX, y: intersectionPoint.y};
          p2 = {x: intersectionPoint.x - distanceX - directionIncrement, y: intersectionPoint.y};
        } else {
          p1 = {x: intersectionPoint.x + distanceX + directionIncrement, y: intersectionPoint.y};
          p2 = {x: intersectionPoint.x - distanceX, y: intersectionPoint.y};
        }
        return {p1, p2};
      }
      return {p1, p2};
    }

    const slopeL1 = (P2.y - P1.y) / (P2.x - P1.x);
    const slopeL2 = -1 / slopeL1;
    const yInterceptL2 = intersectionPoint.y - slopeL2 * intersectionPoint.x;

    let aditionalIncrement = 0;

    if (isIn) {
      aditionalIncrement = angleDeg >= 0 && angleDeg < 180 ? distanceX + directionIncrement : distanceX;
    } else {
      aditionalIncrement = angleDeg >= 0 && angleDeg < 180 ? distanceX : distanceX + directionIncrement;
    }

    const xP3 = intersectionPoint.x + aditionalIncrement * Math.sqrt(1 / (1 + Math.pow(slopeL2, 2)));
    const yP3 = slopeL2 * xP3 + yInterceptL2;

    const P3: Point = {
      x: xP3,
      y: yP3
    };

    let aditionalIncrementTwo = 0;

    if (isIn) {
      aditionalIncrementTwo = angleDeg >= 180 && angleDeg < 360 ? distanceX + directionIncrement : distanceX;
    } else {
      aditionalIncrementTwo = angleDeg >= 180 && angleDeg < 360 ? distanceX : distanceX + directionIncrement;
    }

    const xP4 = intersectionPoint.x - aditionalIncrementTwo * Math.sqrt(1 / (1 + Math.pow(slopeL2, 2)));
    const yP4 = slopeL2 * xP4 + yInterceptL2;

    const P4: Point = {
      x: xP4,
      y: yP4
    };

    let pointsSortedByY = [P3, P4].sort((a, b) => b.y - a.y);

    if (P1.x > P2.x) {
      pointsSortedByY = pointsSortedByY.reverse();
    }

    return {p1: pointsSortedByY[0], p2: pointsSortedByY[1]};
  }

  static calculateDirection(P1: Point, P2: Point): Point {
    const deltaX = P2.x - P1.x;
    const deltaY = P2.y - P1.y;
    const magnitude = Math.sqrt(deltaX ** 2 + deltaY ** 2);

    const normalizedDeltaX = deltaX / magnitude;
    const normalizedDeltaY = deltaY / magnitude;

    return {x: normalizedDeltaX, y: normalizedDeltaY};
  }

  public buildComponents() {
    this.setupConstants();

    this.lineInTag = this.canvas
      .rect(this.inWidthTag, this.heightTag)
      .attr({fill: this.fill, id: `${this.id}-${this.referenceName.lineInTag}`});
    this.lineOutTag = this.canvas
      .rect(this.outWidthTag, this.heightTag)
      .attr({fill: this.fill, id: `${this.id}-${this.referenceName.lineOutTag}`});

    this.lineInConnectorLine = this.canvas
      .polyline([0.0])
      .stroke({color: this.stroke, width: 2})
      .attr({id: `${this.id}-${this.referenceName.lineInConnectorLine}`});
    this.lineOutConnectorLine = this.canvas
      .polyline([0.0])
      .stroke({color: this.stroke, width: 2})
      .attr({id: `${this.id}-${this.referenceName.lineOutConnectorLine}`});

    this.lineInClose = this.canvas
      .rect(this.xHeightTag, this.xWidthTag)
      .attr({fill: tinycolor(this.fill).darken(10).toString(), id: `${this.id}-${this.referenceName.lineInClose}`});
    this.lineOutClose = this.canvas
      .rect(this.xHeightTag, this.xWidthTag)
      .attr({fill: tinycolor(this.fill).darken(10).toString(), id: `${this.id}-${this.referenceName.lineOutClose}`});

    this.lineInEnd = this.canvas.rect(8, 8).attr({fill: this.fill, id: `${this.id}-${this.referenceName.lineInEnd}`});
    this.lineOutEnd = this.canvas.rect(8, 8).attr({fill: this.fill, id: `${this.id}-${this.referenceName.lineOutEnd}`});

    this.xIconPath =
      'M11.0834 3.739L10.2609 2.9165L7.00008 6.17734L3.73925 2.9165L2.91675 3.739L6.17758 6.99984L2.91675 10.2607L3.73925 11.0832L7.00008 7.82234L10.2609 11.0832L11.0834 10.2607L7.82258 6.99984L11.0834 3.739Z';
    this.plusIconPath =
      'M9.08317 5.58317H5.58317V9.08317H4.4165V5.58317H0.916504V4.4165H4.4165V0.916504H5.58317V4.4165H9.08317V5.58317Z';

    this.closeIconIn = this.canvas
      .path(this.xIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.closeIconIn}`});
    this.closeIconOut = this.canvas
      .path(this.xIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.closeIconOut}`});

    this.directionIconIn = this.canvas
      .path(this.directionIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.directionIconIn}`});
    this.directionIconOut = this.canvas
      .path(this.directionIconPath)
      .attr({fill: 'white', opacity: 0.5, id: `${this.id}-${this.referenceName.directionIconOut}`});

    this.lineInText = (this.canvas as any).text(this.lineInTextContent).attr({
      fill: '#FFF',
      'font-family': 'Rubik',
      'font-weight': 500,
      style: 'user-select: none',
      'letter-spacing': '0.75px',
      'font-size': '12px',
      id: `${this.id}-${this.referenceName.lineInText}`
    });
    this.lineOutText = (this.canvas as any).text(this.lineOutTextContent).attr({
      fill: '#FFF',
      'font-family': 'Rubik',
      'font-weight': 500,
      style: 'user-select: none',
      'letter-spacing': '0.75px',
      'font-size': '12px',
      id: `${this.id}-${this.referenceName.lineOutText}`
    });

    const lineBbox = (this.ref as any).array().value;
    const x1 = lineBbox[0][0];
    const y1 = lineBbox[0][1];
    const x2 = lineBbox[1][0];
    const y2 = lineBbox[1][1];

    this.startCircle = this.canvas
      .circle(8)
      .attr({id: `${this.id}-${this.referenceName.startCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x1, y1);
    this.endCircle = this.canvas
      .circle(8)
      .attr({id: `${this.id}-${this.referenceName.endCircle}`, fill: this.fill, opacity: LabelOpacity.full})
      .center(x2, y2);

    this.group = this.canvas.group().attr({id: `${this.id}-${this.referenceName.group}`});
    this.group.add(this.ref);
    this.group.add(this.lineInConnectorLine);
    this.group.add(this.lineOutConnectorLine);
    this.group.add(this.lineInTag);
    this.group.add(this.lineOutTag);
    this.group.add(this.lineInClose);
    this.group.add(this.lineInText);
    this.group.add(this.lineOutText);
    this.group.add(this.lineOutClose);
    this.group.add(this.closeIconIn);
    this.group.add(this.closeIconOut);
    this.group.add(this.lineInEnd);
    this.group.add(this.lineOutEnd);
    this.group.add(this.directionIconIn);
    this.group.add(this.directionIconOut);
    this.group.add(this.startCircle);
    this.group.add(this.endCircle);

    this.bindClickEvents();
    this.updateLinesPosition();

    const resizing$ = fromEvent(this.ref as any, 'resizing');
    this.resizingSub = resizing$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });

    const dragmove$ = fromEvent(this.ref as any, 'dragmove');
    this.dragmoveSub = dragmove$.pipe(throttleTime(10)).subscribe(() => {
      this.updateLinesPosition();
    });
  }

  public cancelDraw() {
    this.ref.draw('cancel');
    this.stopSelection();
    this.unbindInteractions();
  }

  public stopDraw() {
    this.stopSelection();
  }

  private generateBooleanFromText(text: string): boolean {
    if (text === 'true') {
      return true;
    } else if (text === 'false') {
      return false;
    } else {
      return undefined;
    }
  }

  public unbindInteractions() {
    this.unBindOnClick();
    this.ref.off('dragmove');
    this.ref.off('resizing');
    this.ref.off('lineInClick');
    this.ref.off('lineOutClick');
    this.ref.off('lineInSwapClick');
    this.ref.off('lineOutSwapClick');
    this.ref.off('atLeastOneInOutPositionEnabled');
    if (this.dragmoveLineInEndSub) {
      this.lineInEnd?.draggable(false).off('dragmove');
      this.lineInEnd?.off('click');
      this.lineInEnd?.off('mouseup');
      this.lineInClose?.off('mouseover');
      this.dragmoveLineInEndSub.unsubscribe();
      this.dragmoveLineInEndSub = undefined;
    }
    if (this.dragmoveLineOutEndSub) {
      this.lineOutEnd?.draggable(false).off('dragmove');
      this.lineOutEnd?.off('click');
      this.lineOutEnd?.off('mouseup');
      this.lineOutClose?.off('mouseover');
      this.dragmoveLineOutEndSub.unsubscribe();
      this.dragmoveLineOutEndSub = undefined;
    }
    this.lineInClose?.click(null);
    this.lineInText?.click(null);
    this.lineOutText?.click(null);
    this.lineOutClose?.click(null);
    this.closeIconIn?.click(null);
    this.closeIconOut?.click(null);
    this.lineInTag?.click(null);
    this.lineOutTag?.click(null);
    this.directionIconIn?.click(null);
    this.directionIconOut?.click(null);
  }

  public bindOnClick(selectShapeCallback: (data?: any) => any) {
    this.updateReferences();
    const clickToSelectAction = () => {
      if (this.ref.attr('fill-opacity') !== LabelOpacity.hidden) {
        selectShapeCallback();
      }
    };

    this.ref.click(clickToSelectAction);
    this.lineInClose?.click(clickToSelectAction);
    this.lineInText?.click(clickToSelectAction);
    this.lineOutText?.click(clickToSelectAction);
    this.lineOutClose?.click(clickToSelectAction);
    this.closeIconIn?.click(clickToSelectAction);
    this.closeIconOut?.click(clickToSelectAction);
    this.lineInTag?.click(clickToSelectAction);
    this.lineOutTag?.click(clickToSelectAction);
    this.directionIconIn?.click(clickToSelectAction);
    this.directionIconOut?.click(clickToSelectAction);
  }

  public completeDraw() {
    this.ref.draw('complete');
  }

  private setupConstants(): void {
    this.xHeightTag = 18;
    this.xWidthTag = 18;
    this.heightTag = 18;
    this.inWidthTag = 35;
    this.outWidthTag = 45;
    this.verticalOffsetX = 30;
    this.lineConnectorHeight = 25;
    this.lineConnectorWidth = 2;

    this.isInEnabled = this.generateBooleanFromText(this.ref.attr('isInEnabled'));
    this.isOutEnabled = this.generateBooleanFromText(this.ref.attr('isOutEnabled'));

    this.fill = this.ref.attr('fill');
    this.stroke = this.ref.attr('stroke');
  }

  private bindClickEvents(): void {
    this.lineInClose?.click(() => {
      if (!this.isOutEnabled && this.isInEnabled) {
        this.ref.fire('atLeastOneInOutPositionEnabled');
        return;
      }

      this.isInEnabled = !this.isInEnabled;
      this.ref.attr('isInEnabled', this.isInEnabled);
      this.ref.fire('lineInClick');
      this.updateLinesPosition();
    });

    this.lineOutClose?.click(() => {
      if (!this.isInEnabled && this.isOutEnabled) {
        this.ref.fire('atLeastOneInOutPositionEnabled');
        return;
      }

      this.isOutEnabled = !this.isOutEnabled;
      this.ref.attr('isOutEnabled', this.isOutEnabled);
      this.ref.fire('lineOutClick');
      this.updateLinesPosition();
    });

    this.closeIconIn?.click(() => {
      if (!this.isOutEnabled && this.isInEnabled) {
        this.ref.fire('atLeastOneInOutPositionEnabled');
        return;
      }

      this.isInEnabled = !this.isInEnabled;
      this.ref.attr('isInEnabled', this.isInEnabled);
      this.ref.fire('lineInClick');
      this.updateLinesPosition();
    });

    this.closeIconOut?.click(() => {
      if (!this.isInEnabled && this.isOutEnabled) {
        this.ref.fire('atLeastOneInOutPositionEnabled');
        return;
      }

      this.isOutEnabled = !this.isOutEnabled;
      this.ref.attr('isOutEnabled', this.isOutEnabled);
      this.ref.fire('lineOutClick');
      this.updateLinesPosition();
    });

    this.lineInText?.click(() => {
      this.ref.fire('lineInSwapClick', {});
      this.updateLinesPosition();
    });

    this.lineOutText?.click(() => {
      this.ref.fire('lineOutSwapClick', {});
      this.updateLinesPosition();
    });

    this.lineInTag?.click(() => {
      this.ref.fire('lineInSwapClick', {});
      this.updateLinesPosition();
    });

    this.lineOutTag?.click(() => {
      this.ref.fire('lineOutSwapClick', {});
      this.updateLinesPosition();
    });

    this.directionIconIn?.click(() => {
      this.ref.fire('lineInSwapClick', {});
      this.updateLinesPosition();
    });

    this.directionIconOut?.click(() => {
      this.ref.fire('lineOutSwapClick', {});
      this.updateLinesPosition();
    });

    this.lineInClose
      ?.on('mouseover', () => {
        this.setupConstants();
        if (!this.isInEnabled) {
          this.lineInClose.fill('#fff');
          this.lineInClose.front();
          this.closeIconIn.attr({fill: this.fill, opacity: LabelOpacity.full});
          this.closeIconIn.front();
        }
      })
      .on('mouseout', () => {
        if (!this.isInEnabled) {
          this.lineInClose.fill(this.fill);
          this.lineInClose.front();
          this.closeIconIn.attr({fill: '#fff', opacity: LabelOpacity.full});
          this.closeIconIn.front();
        }
      });

    this.lineOutClose
      ?.on('mouseover', () => {
        this.setupConstants();
        if (!this.isOutEnabled) {
          this.lineOutClose.fill('#fff');
          this.lineOutClose.front();
          this.closeIconOut.attr({fill: this.fill, opacity: LabelOpacity.full});
          this.closeIconOut.front();
        }
      })
      .on('mouseout', () => {
        if (!this.isOutEnabled) {
          this.lineOutClose.fill(this.fill);
          this.lineOutClose.front();
          this.closeIconOut.attr({fill: '#fff', opacity: LabelOpacity.full});
          this.closeIconOut.front();
        }
      });

    const mouseUpInEnd$ = fromEvent(this.lineInEnd, 'mouseup');
    const mouseUpOutEnd$ = fromEvent(this.lineOutEnd, 'mouseup');
    const mouseClickInEnd$ = fromEvent(this.lineInEnd, 'click');
    const mouseClickOutEnd$ = fromEvent(this.lineOutEnd, 'click');

    this.dragmoveLineInEndSub = fromEvent(this.lineInEnd.draggable(), 'dragmove')
      .pipe(this.pairwiseWithClearOnMouseUp(mouseUpInEnd$, mouseClickInEnd$))
      .subscribe(([dx, dy]: any) => {
        const cx = this.ref.cx();
        const cy = this.ref.cy();
        (this.ref as any).center(cx + dx, cy + dy);
        this.updateLinesPosition();
      });

    this.dragmoveLineOutEndSub = fromEvent(this.lineOutEnd.draggable(), 'dragmove')
      .pipe(this.pairwiseWithClearOnMouseUp(mouseUpOutEnd$, mouseClickOutEnd$))
      .subscribe(([dx, dy]: any) => {
        const cx = this.ref.cx();
        const cy = this.ref.cy();
        (this.ref as any).center(cx + dx, cy + dy);
        this.updateLinesPosition();
      });
  }

  private extraDistanceBasedOnDegrees(angleDeg, distance = 20): number {
    const normalizedDegree = (angleDeg + 360) % 360;
    let value: number;
    if (normalizedDegree >= 0 && normalizedDegree <= 180) {
      value = ((normalizedDegree - 0) / (180 - 0)) * (-distance - distance) + distance;
    } else {
      value = ((normalizedDegree - 180) / (360 - 180)) * (-distance - distance) + distance;
    }
    return value;
  }

  private pairwiseWithClearOnMouseUp<T>(mouseUp$, mouseClick$): OperatorFunction<T, [number, number]> {
    return (source: Observable<T>) => {
      return new Observable<[number, number]>(observer => {
        let buffer: T[] = [];

        const subscription = source.pipe().subscribe(
          value => {
            (value as any).preventDefault();
            buffer.push(value);
            if (buffer.length === 2) {
              const e = buffer[0] as any;
              const eEnd = buffer[1] as any;
              const x0 = e.detail.p.x;
              const y0 = e.detail.p.y;
              const x1 = eEnd.detail.p.x;
              const y1 = eEnd.detail.p.y;
              const dx = x1 - x0;
              const dy = y1 - y0;

              if (Math.abs(dx) > 120 || Math.abs(dy) > 120) {
                return;
              }

              observer.next([dx, dy]);
              buffer = [buffer[1]];
              return;
            }

            if (buffer.length > 2) {
              buffer = [buffer[buffer.length - 1]];
            }
          },
          error => observer.error(error),
          () => observer.complete()
        );

        const clearBuffer = () => {
          buffer = [];
        };

        const mouseUpSubscription = mouseUp$.subscribe(clearBuffer);
        const mouseClickSubscription = mouseClick$.subscribe(clearBuffer);

        return () => {
          subscription.unsubscribe();
          mouseUpSubscription.unsubscribe();
          mouseClickSubscription.unsubscribe();
        };
      });
    };
  }

  findLongestDiagonal(points: Point[]): number {
    let longestDiagonal = 0;

    for (let i = 0; i < points.length; i++) {
      for (let j = i + 1; j < points.length; j++) {
        const diagonalDistance = this.calculateDistance(points[i], points[j]);
        longestDiagonal = Math.max(longestDiagonal, diagonalDistance);
      }
    }

    return longestDiagonal;
  }

  calculateDistance(p1, p2) {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }
}

interface Point {
  x: number;
  y: number;
}

interface Circle {
  center: Point;
  radius: number;
}

interface Line {
  p1: Point;
  p2: Point;
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {pageAnimation} from '@app/core/animations/router/slide-in.animation';
import {SlideshowComponent} from '@app/shared/slideshow/slideshow/slideshow.component';
import {distinctUntilChanged, map, take} from 'rxjs/operators';
import {MetatagService} from '@app/shared/services/metatag.service';
import {CustomMetatags} from '@app/shared/models/custom-metatags.enum';
import {
  ANIMATION_OPTIONS,
  AnimationOption,
  AnimationOptions,
  DEVELOPER_SLIDESHOW_ITEMS,
  NON_DEVELOPER_SLIDESHOW_ITEMS,
  SlideshowItem,
  SlideshowItems
} from './home.config';
import {HelpService} from '@app/core/services/help.service';

@UntilDestroy({checkProperties: true})
@Component({
  templateUrl: './home.html',
  styleUrls: ['./home.scss'],
  animations: [pageAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  @ViewChild('slideShow', {read: SlideshowComponent, static: true}) public slideShowRef: SlideshowComponent;

  public slideshowItems: SlideshowItem[] = [];
  public isDeveloperMode: boolean;
  private viewportSubFix: string = 'sm';
  private nonDeveloperSlideshowItems: SlideshowItems = NON_DEVELOPER_SLIDESHOW_ITEMS;
  private developerSlideshowItems: SlideshowItems = DEVELOPER_SLIDESHOW_ITEMS;
  private animationOptions: AnimationOptions = ANIMATION_OPTIONS;

  constructor(
    private observableMedia: MediaObserver,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private helpService: HelpService,
    private metatagService: MetatagService
  ) {}

  public ngOnInit(): void {
    this.isDeveloperMode = this.route.snapshot.firstChild.data.isDeveloperMode;

    this.setSlideshowItems();
    this.watchViewportChanges();
  }

  public prepareRoute(outlet: RouterOutlet): {value: string; params: AnimationOption} | null {
    if (!outlet.isActivated) {
      return;
    }

    const urlPath = outlet.activatedRoute.snapshot.routeConfig.path;
    const animationName = outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    const slideshowItems = this.isDeveloperMode ? this.developerSlideshowItems : this.nonDeveloperSlideshowItems;

    this.slideShowRef.switchIndex(slideshowItems[urlPath]?.index);

    if (!animationName) {
      return null;
    }

    return {value: animationName, params: this.animationOptions[this.viewportSubFix]};
  }

  public goToKnowledgePage(): void {
    this.helpService.goToKnowledgePage();
  }

  private setSlideshowItems(): void {
    this.metatagService
      .getMetatagValue(CustomMetatags.LOGIN_BACKGROUND)
      .pipe(take(1))
      .subscribe(customBackground => {
        if (customBackground) {
          const updateSlideshowItems = (items: SlideshowItems) =>
            Object.entries(items).reduce(
              (acc, [key, item]) => ({
                ...acc,
                [key]: {...item, backgroundImageSrc: customBackground}
              }),
              {}
            );

          if (!this.isDeveloperMode) {
            this.nonDeveloperSlideshowItems = updateSlideshowItems(this.nonDeveloperSlideshowItems);
          } else {
            this.developerSlideshowItems = updateSlideshowItems(this.developerSlideshowItems);
          }
        }
      });

    this.slideshowItems = this.isDeveloperMode
      ? Object.values(this.developerSlideshowItems)
      : Object.values(this.nonDeveloperSlideshowItems);
  }

  private watchViewportChanges(): void {
    const indexFirstPriorityMedia = 0;
    this.observableMedia
      .asObservable()
      .pipe(
        map((media: MediaChange[]) => media[indexFirstPriorityMedia].mqAlias),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((mediaAlias: string) => {
        this.viewportSubFix = mediaAlias;
        this.cd.detectChanges();
      });
  }
}

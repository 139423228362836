import {createSelector} from '@ngrx/store';
import {LiveScheduleState, selectLiveScheduleStore} from '@app/live/store/schedules/live-schedule.reducer';

export const selectSchedules = createSelector(selectLiveScheduleStore, (state: LiveScheduleState) => {
  return state.itemsIds.map(id => state.itemsById[id]);
});

export const selectScheduleById = (scheduleId: string) =>
  createSelector(selectLiveScheduleStore, (state: LiveScheduleState) => {
    return state.itemsById[scheduleId];
  });

export const selectIsLoadingSchedules = createSelector(selectLiveScheduleStore, (state: LiveScheduleState) => {
  return state.isLoadingSchedules;
});

export const selectIsExecutingAction = createSelector(selectLiveScheduleStore, (state: LiveScheduleState) => {
  return state.isExecutingAction;
});

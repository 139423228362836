import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {PermissionService} from '@app/core/services/permission.service';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {catchError, take} from 'rxjs/operators';
import {saveBlob} from '@app/core/services/blob.utils';
import {throwError, timeout} from 'rxjs';

@Directive({
  selector: '[ulExportShape]',
  standalone: true
})
export class ExportShapeDirective {
  @Input('ulExportShape') public asset: AtlasAssetModel;

  constructor(
    private atlasService: AtlasService,
    private unleashAnalytics: UnleashAnalyticsService,
    private permissionService: PermissionService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  @HostListener('click', ['$event'])
  public exportShape(): void {
    const atlasAssetModel = this.asset;

    if (!this.permissionService.canUseAtlas()) {
      console.error('Atlas plan not enabled');
      return;
    }
    this.displayMessage('atlas.control.exportToShapefilePending');
    const asset = atlasAssetModel || this.atlasService.selectedLayers[0];
    this.atlasService
      .convertToShp(asset.id, asset.name)
      .pipe(
        timeout(30000), // 30 seconds timeout
        catchError(error => {
          this.displayMessage('common.fileDownloadFailed');
          return throwError(() => new Error('ConvertToShp failed'));
        })
      )
      .subscribe({
        next: (response: any) => {
          try {
            this.displayMessage('common.fileDownload');
            const {downloadUrl} = JSON.parse(response);
            fetch(downloadUrl)
              .then(response => response.blob())
              .then(blob => {
                saveBlob(blob, asset.name);
              });
          } catch (e) {
            this.displayMessage('common.fileDownloadFailed');
            console.error(e);
          }
        },
        error: error => {
          // This will catch any errors not handled in the catchError above
          console.error('Error in convertToShp:', error);
        }
      });

    this.atlasService.clearSelectedLayers();
    this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {type: 'Export'});
  }
  private displayMessage(translation: string) {
    this.translateService
      .get(translation)
      .pipe(take(1))
      .subscribe(message => {
        this.snackBar.open(message, null, {
          duration: 5000,
          horizontalPosition: 'left',
          extraClasses: ['short-snackbar']
        } as MatSnackBarConfig);
      });
  }
}

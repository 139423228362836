<div class="dialog-container">
  <h2 mat-dialog-title>{{ 'profile.rolesManagement.addRole.title' | translate }}</h2>

  <mat-dialog-content>
    <form [formGroup]="roleForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'common.name' | translate }}</mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf="roleForm.get('name')?.hasError('required')">
          {{ 'common.validation.required' | translate }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ 'common.cancel' | translate }}
    </button>
    <button mat-flat-button color="primary" [disabled]="!roleForm.valid" (click)="onSubmit()">
      {{ 'common.save' | translate }}
    </button>
  </mat-dialog-actions>
</div>

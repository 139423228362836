import {Injectable} from '@angular/core';
import {divIcon, latLng, latLngBounds, Map, marker, point} from 'leaflet';
import Supercluster from 'supercluster';
import {GeojsonAssetLoaderService} from './geojson-asset-loader.service';
import {GeoJSONFeature} from '../model/mission-geojson.model';
import {BehaviorSubject, filter, map, Observable, ReplaySubject, take} from 'rxjs';
import {atlasConfig} from '../atlas.config';
import {UserService} from '@app/core/services/api/user.service';
import {CompanyModel} from '@app/core/models/api/company-model';

@Injectable({
  providedIn: 'root'
})
export class SuperclusterService {
  public markers: L.Marker[] = [];
  public supercluster;
  public displayedClusters = [];
  public isClusterEnabled = false;
  public isClusterButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public stickyClustersEnabled = false;
  public hiddenAssets = [];
  public updatedColorAssets: {[key: string]: string} = {};
  public updatedMarkerZIndex: {[key: string]: number} = {};
  public currentDisplayedPoints: GeoJSONFeature[] = [];
  public finishUpdate: ReplaySubject<void> = new ReplaySubject<void>(1);
  public currentDisplayedPointsObject = {};
  public myCompany$: Observable<CompanyModel> = this.userService.myCompany$;

  constructor(private geojsonAssetLoaderService: GeojsonAssetLoaderService, private userService: UserService) {
    this.myCompany$
      .pipe(
        filter(company => !!company),
        take(1),
        map(company => company.atlas?.settings?.markers)
      )
      .subscribe(settings => {
        this.geojsonAssetLoaderService.setGeojsonMarkerIconSettings(settings);
      });
  }

  public setCurrentDisplayedPoints(currentDisplayedPoints: GeoJSONFeature[]) {
    this.currentDisplayedPoints = currentDisplayedPoints;
    this.currentDisplayedPointsObject = currentDisplayedPoints.reduce((acc, item: any) => {
      const assetId = item.asset.id;
      if (!acc[assetId]) {
        acc[assetId] = {};
      }
      acc[assetId][item.index] = item;
      return acc;
    }, {});
  }

  public getPoint(assetId: string, index: number) {
    return this.currentDisplayedPointsObject?.[assetId]?.[index];
  }

  public setNewAssetColor(color: string, id: string) {
    this.updatedColorAssets[id] = color;
  }

  public setUpdatedMarkerZIndex(zIndex: number, id: string) {
    this.updatedMarkerZIndex[id] = zIndex;
  }

  public restoreHiddenAssets() {
    this.hiddenAssets = [];
  }

  public addHiddenAssets(assetId: string): void {
    this.hiddenAssets = [...new Set([...this.hiddenAssets, assetId])];
  }

  public removeHiddenAssets(assetId: string): void {
    if (this.hiddenAssets.length === 0) {
      return;
    }
    const index = this.hiddenAssets.indexOf(assetId);
    if (index !== -1) {
      this.hiddenAssets.splice(index, 1);
    }
  }

  public setIsClusterEnabled(isClusterEnabled: boolean) {
    this.isClusterEnabled = isClusterEnabled;
  }

  public updateUnclusteredMarkers(map) {
    const points = this.displayedClusters.flatMap(cluster =>
      cluster.properties.cluster ? this.supercluster.getLeaves(cluster.properties.cluster_id, Infinity) : [cluster]
    );
    points.forEach(point => {
      const [longitude, latitude] = point.geometry.coordinates;
      this.addSingleMarker(point, latitude, longitude, map);
    });
  }

  public updateClusters(map): void {
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    this.displayedClusters = this.supercluster.getClusters(
      [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
      zoom
    );
    this.markers.forEach(marker => map.removeLayer(marker));
    this.markers = [];
    if (!this.isClusterEnabled) {
      this.updateUnclusteredMarkers(map);
      return;
    }
    this.displayedClusters.forEach(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const isCluster = cluster.properties.cluster;
      if (isCluster) {
        let pointCount = cluster.properties?.point_count;
        let clusterColor = cluster.properties?.clusterColor;
        const clusterPoints = this.supercluster.getLeaves(cluster.properties.cluster_id, Infinity);
        const hasUpdatedColorAssets = Object.keys(this.updatedColorAssets).length > 0;
        const hasHiddenAssets = this.hiddenAssets.length > 0;
        if (hasUpdatedColorAssets) {
          //for changed color assets
          clusterColor = this.updateClusterColor(clusterPoints);
        }
        if (hasHiddenAssets) {
          //check if the asset is hidden
          const filteredPoints = clusterPoints.filter(
            clusterPoint => !this.hiddenAssets.includes(clusterPoint.asset.id)
          );
          if (filteredPoints.length === 0) {
            return;
          }
          if (filteredPoints.length === 1) {
            const point = filteredPoints[0];
            this.addSingleMarker(point, point.geometry.coordinates[1], point.geometry.coordinates[0], map);
            return;
          }
          if (clusterPoints.length !== filteredPoints.length) {
            pointCount = filteredPoints.length;
            clusterColor = filteredPoints.reduce((acc, obj) => {
              acc[obj.properties.featureColor] = (acc[obj.properties.featureColor] || 0) + 1;
              return acc;
            }, {});
          }
        }
        //create cluster icon
        const clusterMarker = marker([latitude, longitude], {
          icon: this.getIconCreateFunction(pointCount, clusterColor)
        });

        clusterMarker.on('click', () => {
          // zoom cluster on click
          const clusterBounds = latLngBounds(
            clusterPoints.map(point => latLng(point.geometry.coordinates[1], point.geometry.coordinates[0]))
          );
          map.fitBounds(clusterBounds);
        });

        this.markers.push(clusterMarker);
        clusterMarker.addTo(map);
      } else {
        //for markers only
        this.addSingleMarker(cluster, latitude, longitude, map);
      }
    });
    this.finishUpdate.next();
  }

  public addSingleMarker(point, latitude, longitude, map) {
    const isMarkerHidden = this.hiddenAssets.includes(point.asset.id);
    if (isMarkerHidden) {
      return;
    }
    // Individual marker no cluster
    const marker = this.geojsonAssetLoaderService.generateMarker(point, latLng(latitude, longitude), point.asset);
    marker.feature = point;
    if (point.properties.selectedIcon) {
      //for select markers
      marker.setIcon(point.properties.selectedIcon);
    }
    this.markers.push(marker);
    marker.addTo(map);
    if (this.updatedMarkerZIndex[point.asset.id]) {
      marker.setZIndexOffset(this.updatedMarkerZIndex[point.asset.id]);
    }
    this.geojsonAssetLoaderService.onEachFeature(point, marker, point.asset, map);
  }

  public updateClusterColor(clusterPoints: any[]) {
    clusterPoints.forEach(point => {
      if (this.updatedColorAssets[point.asset.id]) {
        point.properties.featureColor = this.updatedColorAssets[point.asset.id];
      }
      return point;
    });
    const clusterColor = clusterPoints.reduce((acc, obj) => {
      acc[obj.properties.featureColor] = (acc[obj.properties.featureColor] || 0) + 1;
      return acc;
    }, {});
    return clusterColor;
  }

  public loadSuperCluster(points: GeoJSONFeature[]): void {
    this.supercluster = new Supercluster({
      radius: atlasConfig.SUPERCLUSTER_RADIUS,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      map: properties => ({featureColor: [properties.featureColor]}),
      reduce: (accumulated, props) => {
        accumulated.featureColor = [...accumulated.featureColor, ...props.featureColor];
        accumulated.clusterColor = accumulated.featureColor.reduce((acc, item) => {
          acc[item] = (acc[item] || 0) + 1;
          return acc;
        }, {});
      }
    });
    this.supercluster.load(points);
  }

  public refreshCluster(points: GeoJSONFeature[], map: Map) {
    this.setCurrentDisplayedPoints(points);
    this.setIsClusterEnabled(true);
    this.loadSuperCluster(points);
    this.updateClusters(map);
  }

  public enableCluster(map: Map) {
    if (this.isClusterEnabled) {
      return;
    }
    this.setIsClusterEnabled(true);
    this.updateClusters(map);
  }

  public getClusterHtml(colorCounts: {[key: string]: number}, total: number) {
    let cumulativePercentage = 0;
    const gradientParts = [];

    for (const color in colorCounts) {
      const PERCENTAGE_FACTOR = 100;
      const percentage = (colorCounts[color] / total) * PERCENTAGE_FACTOR;
      gradientParts.push(`${color} ${cumulativePercentage}% ${cumulativePercentage + percentage}%`);
      cumulativePercentage += percentage;
    }
    const gradient = `conic-gradient(${gradientParts.join(', ')})`;
    const containerStyle = `background: ${gradient};`;

    return `<div class="custom-cluster__container" style="${containerStyle}"></div>
            <div class="custom-cluster__icon size-indefinite">${total}</div>`;
  }

  public getIconCreateFunction(childCount, groupColors) {
    return divIcon({
      html: this.getClusterHtml(groupColors, childCount),
      className: 'custom-cluster',
      iconSize: point(40, 40)
    });
  }

  public disableCluster(map: Map) {
    if (!this.isClusterEnabled) {
      return;
    }
    this.setIsClusterEnabled(false);
    this.updateClusters(map);
  }

  public toggleCluster(map: Map) {
    if (this.isClusterEnabled) {
      this.disableCluster(map);
      this.stickyClustersEnabled = false;
      return;
    }
    this.enableCluster(map);
    this.stickyClustersEnabled = true;
  }

  public listenClusterChanges(map: Map) {
    if (!this.isClusterEnabled) {
      this.enableCluster(map);
    }
    this.updateClusters(map);
    this.handleMapChange(map);
  }

  public toggleClusterItemsVisibility(isDisplaying: boolean, assetId: string) {
    isDisplaying ? this.removeHiddenAssets(assetId) : this.addHiddenAssets(assetId);
  }

  public handleMapChange(map) {
    const zoom = map.getZoom();
    console.info('CURRENT ZOOM', zoom);
    const hasToEnableCluster =
      (this.shouldEnableClusteringByMarkers() || !!this.shouldEnableClusteringByZoom(zoom)) &&
      zoom !== atlasConfig.MAX_ZOOM_LEVEL;
    this.isClusterButtonDisabled.next(hasToEnableCluster);
    if (this.stickyClustersEnabled) {
      console.debug('Sticky clusters enabled');
      return;
    }
    this.isClusterButtonDisabled.value ? this.enableCluster(map) : this.disableCluster(map);
  }

  private shouldEnableClusteringByZoom(zoom: number): boolean {
    if (zoom >= atlasConfig.DISABLE_CLUSTERING_ZOOM_LEVEL) {
      return false;
    } else if (zoom <= atlasConfig.ENABLE_CLUSTERING_ZOOM_LEVEL) {
      return true;
    }
  }

  private shouldEnableClusteringByMarkers() {
    let visiblePoints = 0;
    const threshold = atlasConfig.DISABLE_CLUSTERING_MARKERS_COUNT;
    for (const cluster of this.displayedClusters) {
      const pointsToAdd = cluster.properties.cluster ? cluster.properties.point_count : 1;
      visiblePoints += pointsToAdd;
      if (visiblePoints > threshold) {
        break;
      }
    }
    return visiblePoints >= threshold;
  }
}

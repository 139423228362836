import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {SignInForm, SsoConfig} from './sign-in.model';
import {emailRegex} from '../../regex';
import {MetatagService} from '../../../shared/services/metatag.service';
import {CustomMetatags} from '../../../shared/models/custom-metatags.enum';

@Component({
  selector: 'unleash-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInComponent implements OnInit {
  @Input() inProgress: boolean;
  @Output() signIn: EventEmitter<Partial<SignInForm>> = new EventEmitter<Partial<SignInForm>>();
  @Output() federatedSignIn: EventEmitter<string> = new EventEmitter<string>();
  @Output() forgotPassword: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ssoConfig: SsoConfig | null = null;
  public showSsoButton = false;

  form = this.fb.group({
    email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    password: ['', [Validators.required]]
  });

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder, private metatagService: MetatagService) {}

  public ngOnInit(): void {
    this.metatagService
      .getMetatagValue(CustomMetatags.SSO_PROVIDER)
      .pipe(takeUntil(this.destroy$))
      .subscribe(provider => {
        if (provider) {
          this.ssoConfig = this.getSsoConfig(provider);
          this.showSsoButton = true;
        }
      });
  }

  private getSsoConfig(provider: string): SsoConfig {
    let providerName = provider.split('-')[0];
    const logoPath = `/assets/icons/auth/logo-${providerName.toLowerCase()}.svg`;

    return {
      provider,
      providerName,
      logoPath
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  signInWithProvider() {
    if (this.ssoConfig) {
      this.federatedSignIn.emit(this.ssoConfig.provider);
    }
  }

  clickSignInButton() {
    if (this.form.valid) {
      this.signIn.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }

  clickForgotYourPasswordButton() {
    this.forgotPassword.emit(true);
  }
}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {RolesManagementState, rolesManagementFeatureKey} from './roles-management.state';

export const selectRolesManagementState = createFeatureSelector<RolesManagementState>(rolesManagementFeatureKey);

export const selectRoles = createSelector(selectRolesManagementState, state => state.roles);

export const selectPermissions = createSelector(selectRolesManagementState, state => state.permissions);

export const selectIsLoading = createSelector(selectRolesManagementState, state => state.isLoading);

export const selectError = createSelector(selectRolesManagementState, state => state.error);

export const selectUpdatedRoleIds = createSelector(selectRolesManagementState, state => state.updatedRoleIds || []);

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'unleash-static-header',
  templateUrl: './static-header.component.html',
  styleUrls: ['./static-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIconModule, CommonModule]
})
export class StaticHeaderComponent {
  @Input() public title: string;
  @Input() public items: string[];
  @Output() public goBack: EventEmitter<void> = new EventEmitter();

  public emitGoBack(): void {
    this.goBack.emit();
  }
}

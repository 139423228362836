<button
  data-test-id="zoom-in-button"
  matTooltip="{{ 'library.imageAnnotation.shortcuts.zoomIn' | translate }}"
  matTooltipPosition="right"
  mat-flat-button
  class="zoom-in square-button"
  color="basic"
  (click)="zoomIn()"
  [analyticsTrackerType]="events.ATLAS_CONTROLS"
  [analyticsProperties]="{type: 'Zoom In'}"
>
  <mat-icon>zoom_in</mat-icon>
</button>
<button
  matTooltip="{{ 'library.imageAnnotation.shortcuts.zoomOut' | translate }}"
  matTooltipPosition="right"
  mat-flat-button
  class="zoom-out square-button"
  color="basic"
  (click)="zoomOut()"
  [analyticsTrackerType]="events.ATLAS_CONTROLS"
  [analyticsProperties]="{type: 'Zoom Out'}"
>
  <mat-icon>zoom_out</mat-icon>
</button>

<div class="container">
  <button
    class="square-button"
    mat-flat-button
    color="basic"
    (click)="emitToggleMap()"
    matTooltip="{{ 'atlas.toggle.' + mapViewMapper[mapView].translation | translate }}"
    matTooltipPosition="right"
    [analyticsTrackerType]="events.ATLAS_CONTROLS"
    [analyticsProperties]="mapViewMapper[mapView].analyticsType"
  >
    <ng-container [ngSwitch]="mapView">
      <mat-icon *ngSwitchCase="MAP_VIEW.SATELLITE">satellite_alt</mat-icon>
      <mat-icon *ngSwitchCase="MAP_VIEW.HYBRID">map</mat-icon>
      <mat-icon *ngSwitchCase="MAP_VIEW.ROADMAP">terrain</mat-icon>
    </ng-container>
  </button>
  <button
    *ngIf="!isMiniMapControl"
    matTooltip="{{
      isLayersControlSideBarOpen
        ? isTaskControl
          ? ('atlas.map.hideSidebar' | translate)
          : ('atlas.map.hideLayers' | translate)
        : isTaskControl
        ? ('atlas.map.showSidebar' | translate)
        : ('atlas.map.showLayers' | translate)
    }}"
    matTooltipPosition="right"
    mat-flat-button
    (click)="emitToggleLayerControlSideBar()"
    color="white"
    class="mat-elevation-z8 square-button"
  >
    <mat-icon *ngIf="!isLayersControlSideBarOpen; else clearLayersTemplate">layers</mat-icon>
    <ng-template #clearLayersTemplate>
      <mat-icon> layers_clear</mat-icon>
    </ng-template>
  </button>
  <button
    *ngIf="hasInfoPopupControl"
    class="square-button"
    mat-flat-button
    color="basic"
    (click)="emitTogglePopups()"
    matTooltip="{{
      (isPopupEnabled ? 'atlas.toggle.disableMarkersInfo' : 'atlas.toggle.enableMarkersInfo') | translate
    }}"
    matTooltipPosition="right"
  >
    <mat-icon *ngIf="isPopupEnabled; else hasInfoPopupControlTmp">speaker_notes_off</mat-icon>
    <ng-template #hasInfoPopupControlTmp>
      <mat-icon>speaker_notes</mat-icon>
    </ng-template>
  </button>

  <button
    class="square-button"
    mat-flat-button
    color="basic"
    *ngIf="initializedWithWeatherMap"
    (click)="emitToggleWeather()"
    matTooltip="{{ (isWeatherMapDisplayed ? 'atlas.toggle.weather.hide' : 'atlas.toggle.weather.show') | translate }}"
    matTooltipPosition="right"
    [analyticsTrackerType]="events.ATLAS_CONTROLS"
    [analyticsProperties]="isWeatherMapDisplayed ? {type: 'Hide Weather Map'} : {type: 'Show Weather Map'}"
  >
    <mat-icon *ngIf="!isWeatherMapDisplayed">umbrella</mat-icon>
    <mat-icon *ngIf="isWeatherMapDisplayed">wb_sunny</mat-icon>
  </button>
  <button
    *ngIf="!isTaskControl && !isMiniMapControl"
    class="square-button"
    mat-flat-button
    color="basic"
    [disabled]="isClusterButtonDisabled"
    (click)="emitToggleClusters()"
    matTooltip="{{
      isClusterButtonDisabled ? ('atlas.toggle.clustersPlsZoom' | translate) : ('atlas.toggle.clusters' | translate)
    }}"
    matTooltipPosition="right"
    [analyticsTrackerType]="events.ATLAS_CONTROLS"
    [analyticsProperties]="{type: 'toggle clusters'}"
  >
    <mat-icon>bubble_chart</mat-icon>
  </button>
</div>

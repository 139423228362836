import {Injectable, NgZone} from '@angular/core';
import {JobsFacadeService} from './jobs-facade.service';
import {BehaviorSubject, Observable, Subject, filter, take, tap} from 'rxjs';
import {TeamRole} from '@app/profile/models/team.model';
import {MatDialog} from '@angular/material/dialog';
import {RENAME_JOBS_OPTIONS, RenameDialog} from '../components/rename-dialog/rename-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {createMapIcon} from '@app/atlas/marker-icons/custom-map-pointer';
import {GeojsonAssetLoaderService} from '@app/atlas/services/geojson-asset-loader.service';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {MissionTaskStatus} from '../models/marker-task';
import {defaultMissionRouteColor} from '@app/atlas/atlas.config';
import {Map, PointExpression} from 'leaflet';
import {v4 as uuidv4} from 'uuid';
import {MissionPointType} from '@app/atlas/model/mission.model';
import {MarkerClusterService} from '@app/atlas/services/marker-cluster.service';
import {LibraryNavigationService} from '@app/library/services/library-navigation.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class JobTasksService {
  public markerTaskList: BehaviorSubject<{geojson: any; name: string; userId: string; markers: any[]}[]> =
    new BehaviorSubject([]);
  public missionTaskList: BehaviorSubject<{name: string; userId: string; mission: Mission}[]> = new BehaviorSubject([]);
  public selectedAssetId = null;
  public isAddingTasks$: Observable<boolean> = this.jobsFacadeService.isAddingTasks$;
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> =
    this.jobsFacadeService.teamMembers$;
  public selectedTaskIndex: number = -1;
  public hasToDetectListChanges: Subject<void> = new Subject();
  public missionTaskStatus: BehaviorSubject<MissionTaskStatus> = new BehaviorSubject(MissionTaskStatus.SELECT_MISSIONS);
  public flightPathMarkers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public isDrawEventsBlocked: boolean = false;
  public drawnItems = new L.FeatureGroup();
  public miniMapItems = new L.FeatureGroup();
  public routeGroup = new L.FeatureGroup();
  public taskMiniMapItems = new L.FeatureGroup();
  public existentPathGroup = new L.FeatureGroup();
  public flightPathTasks: BehaviorSubject<
    {markers: any[]; name: string; userId: string; internalId: string; isSelected: boolean; context?: any}[]
  > = new BehaviorSubject([]);
  public selectedTaskId: BehaviorSubject<string> = new BehaviorSubject(null);
  public miniMapZoom: number = null;
  public jobAsset: BehaviorSubject<AtlasAssetModel> = new BehaviorSubject<AtlasAssetModel>(null);
  public isPopupEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private undoStack: number[] = [];
  private currentInternalId = this.generateRandomId();
  private readonly createdTaskColor = '#666666';
  private readonly greenPathColor = '#00AF55';
  private readonly createdTaskMarkerClass = 'created-flight-path-marker';
  private readonly poleCreatedTaskMarkerClass = 'pole-created-flight-path-marker';
  private readonly createdTaskPolylineType = 'line-grey';
  private readonly defaultPolylineType = 'line';
  private readonly newFlightPathMarkerClass = 'new-flight-path-marker';
  private readonly selectedMarkerIcon = 'assets/icons/atlas/selected-marker.svg';
  private readonly flightPathMarkerClass = 'flight-path-marker';
  private readonly plusMarkerClass = 'plus-marker';
  private readonly originalClusterType = 'original';
  private readonly greyTaskMarkerIconColor = '#666666';
  private readonly selectedMarkerColor = '#00AF55';
  private readonly drawedLinesWeight = 3.5;
  private readonly defaultMarkerSize = [17, 24];
  private readonly largeMiniMapPadding = [25, 25];
  private readonly smallMiniMapPadding = [50, 50];

  constructor(
    private jobsFacadeService: JobsFacadeService,
    private matDialog: MatDialog,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService,
    private atlasService: AtlasService,
    private zone: NgZone,
    private markerClusterService: MarkerClusterService,
    private libraryNavigationService: LibraryNavigationService
  ) {}

  public toggleIsPopupEnabled() {
    this.setIsPopupEnabled(!this.isPopupEnabled.value);
  }

  public setIsPopupEnabled(isPopupEnabled: boolean) {
    this.isPopupEnabled.next(isPopupEnabled);
  }

  public setJobAsset(jobAsset: AtlasAssetModel) {
    this.jobAsset.next(jobAsset);
  }

  public toggleMap(map, mapView, roadView, hybridView, satelliteView) {
    return this.atlasService.toggleMap(
      map,
      {
        roadView,
        hybridView,
        satelliteView
      },
      mapView
    );
  }

  public viewReport(libraryItemId: string): void {
    this.libraryNavigationService.goToReport(libraryItemId);
  }

  public viewMedia(libraryItemId: string): void {
    this.libraryNavigationService.goToLibraryItem(libraryItemId);
  }

  public setMiniMapZoom(zoom: number): void {
    this.miniMapZoom = zoom;
  }

  public generateNewInternalId() {
    this.currentInternalId = this.generateRandomId();
  }

  public setSelectedTaskId(selectedTaskId: string) {
    this.selectedTaskId.next(selectedTaskId);
  }

  public createFlightPathTasks(jobId: string) {
    const flightPathTasks = this.flightPathTasks.value.map(task => {
      const markers = [];
      task.markers.forEach(marker => {
        const point = {
          ...marker.getLatLng(),
          type:
            marker.options?.icon?.options?.type === this.flightPathMarkerClass
              ? MissionPointType.POLE
              : MissionPointType.POINT
        };
        if (marker?.feature?.properties?.name) {
          point.label = marker.feature.properties.name;
        }
        markers.push(point);
      });
      return {
        ...task,
        markers
      };
    });
    this.jobsFacadeService.createFlightPathTasks({jobId, flightPathTasks});
  }

  public addFlightPaths(flightPath: {markers: any[]; name: string; userId: string; context: any}) {
    this.flightPathTasks.next([
      ...this.flightPathTasks.value,
      {...flightPath, internalId: this.currentInternalId, isSelected: false}
    ]);
  }

  public addInitialFlightPaths(routes) {
    routes.forEach(route => {
      this.generateRoutePath(route, this.existentPathGroup, false);
    });
  }

  public setIsDrawEventsBlocked(isDrawEventsBlocked: boolean) {
    this.isDrawEventsBlocked = isDrawEventsBlocked;
  }

  public detectListChanges(): void {
    this.hasToDetectListChanges.next();
  }

  public addMarkerTask(markerTask: {geojson: any; name: string; userId: string; markers: any}): void {
    this.markerTaskList.next([...this.markerTaskList.value, markerTask]);
  }

  public addMissionTask(missionTaskList: {name: string; userId: string; mission: Mission}[]): void {
    this.missionTaskList.next(missionTaskList);
  }

  public clearMissionTaskList(): void {
    this.missionTaskList.next([]);
  }

  public clearMarkerTaskList(): void {
    this.markerTaskList.next([]);
  }

  public setSelectedAssetId(assetId: string): void {
    this.selectedAssetId = assetId;
  }

  public addTasks(jobId: string): void {
    const taskList = this.markerTaskList.value.map(taskList => {
      taskList.geojson.features.forEach(feature => this.atlasService.deleteFeatureCustomProperties(feature));
      return {geojson: taskList.geojson, name: taskList.name, userId: taskList.userId};
    });
    this.jobsFacadeService.addTasks(jobId, taskList, this.selectedAssetId);
  }

  public addMissionTasks(jobId: string): void {
    this.jobsFacadeService.addMissionTasks(jobId, this.missionTaskList.value);
  }

  public toggleAddUserToTask(taskIndex: number, userId: string): void {
    const taskList = [...this.markerTaskList.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.markerTaskList.next(taskList);
  }

  public deleteTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    this.clearAllFocusedMarkers();
    const taskList = [...this.markerTaskList.value];
    taskList.splice(taskIndex, 1);
    this.markerTaskList.next(taskList);
    this.updateMarkerIcon();
    this.refreshClusters();
  }

  public deleteFlightPathTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    const taskList = [...this.flightPathTasks.value];
    taskList.splice(taskIndex, 1);
    this.flightPathTasks.next(taskList);
  }

  public deleteMissionTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    const taskList = [...this.missionTaskList.value];
    taskList.splice(taskIndex, 1);
    this.missionTaskList.next(taskList);
    if (taskList.length === 0) {
      this.setMissionTaskStatus(MissionTaskStatus.SELECT_MISSIONS);
    }
  }

  public clearAllFocusedMarkers(): void {
    const allSelectedMarkers = this.markerTaskList.value.flatMap(obj => obj.markers);
    allSelectedMarkers.forEach(marker => {
      const icon = this.geojsonAssetLoaderService
        .generateMarker(marker.feature, marker.getLatLng(), this.atlasService.getAssetById(this.selectedAssetId))
        ?.getIcon();
      marker.setIcon(icon);
    });
  }

  public refreshClusters(): void {
    this.markerClusterService.refresh();
  }

  public updateMarkerIcon(): void {
    console.time('get selected markers');
    const allSelectedMarkers = this.markerTaskList.value.flatMap(obj => obj.markers);
    console.timeEnd('get selected markers');
    allSelectedMarkers.forEach(marker => {
      this.generateSelectedMarkerIcon(marker);
    });
  }

  public updateFlightPathTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: string) => !!response),
        tap(newName => {
          const updatedTaskList = this.flightPathTasks.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.flightPathTasks.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  public selectTask(internalId: string): void {
    this.drawnItems.eachLayer(layer => {
      if (!layer.options?.internalId) {
        return;
      }
      if (layer.options?.internalId === internalId) {
        this.updateSelectedTaskStatus(layer, true);
        return;
      }
      if (!internalId) {
        this.updateSelectedTaskStatus(layer, false);
        return;
      }
    });
  }

  public updateMissionTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: string) => !!response),
        tap(newName => {
          const updatedTaskList = this.missionTaskList.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.missionTaskList.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  public updateTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: any) => !!response),
        tap(newName => {
          const updatedTaskList = this.markerTaskList.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.markerTaskList.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  private openUpdateNameDialog(name: string) {
    return this.matDialog.open(RenameDialog, {
      ...STANDARD_DIALOG_CONFIG,
      width: '40vw',
      data: {
        renameOption: RENAME_JOBS_OPTIONS.TASK,
        name: name
      }
    });
  }

  public setSelectedIndex(taskIndex: number): void {
    if (taskIndex === -1) {
      const task = this.markerTaskList.value[this.selectedTaskIndex];
      task?.markers.forEach(marker => {
        this.generateSelectedMarkerIcon(marker);
      });
      this.selectedTaskIndex = taskIndex;
      return;
    }
    const task = this.markerTaskList.value[taskIndex];
    task?.markers.forEach(marker => {
      this.generateSelectedTaskIcon(marker, this.selectedMarkerIcon);
    });
    this.selectedTaskIndex = taskIndex;
    this.refreshClusters();
  }

  public generateSelectedTaskIcon(marker: any, icon: string) {
    const iconProperties = marker.options.icon.options;
    const newCustomIcon = L.icon({
      ...iconProperties,
      iconUrl: icon,
      iconSize: [25, 41]
    });
    marker.setIcon(newCustomIcon);
  }

  public generateSelectedMarkerIcon(marker: any) {
    const iconProperties = marker.options.icon.options;
    const newCustomIcon = createMapIcon({
      ...iconProperties,
      size: [25, 41],
      color: this.createdTaskColor
    });
    marker.setIcon(newCustomIcon);
  }

  public generateDefaultClusterIcon(totalMarkers: number) {
    const defaultColor = this.getDefaultClusterColor(totalMarkers);
    const color = this.atlasService.getAssetById(this.selectedAssetId)?.color;
    return color
      ? this.generateClusterIcon(color, color, false, totalMarkers)
      : this.generateClusterIcon(defaultColor.container, defaultColor.marker, true, totalMarkers);
  }

  public generateClusterIcon(
    containerColor: string,
    markerColor: string,
    hasBlackCounterColor: boolean,
    counter: number
  ) {
    return L.divIcon({
      html: `<div style="background-color:
        ${containerColor}
        " class="custom-cluster__container"></div><div style="background-color:
        ${markerColor}
        " class="custom-cluster__icon
        ${hasBlackCounterColor ? 'black' : ''} ">  ${counter}  </div>`,
      className: 'custom-cluster',
      iconSize: L.point(40, 40)
    });
  }

  public getDefaultClusterColor(markerCounter: number): {container: string; marker: string} {
    if (markerCounter < 10) {
      return {container: 'rgba(181, 226, 140, 1)', marker: 'rgba(110, 204, 57, 0.6)'};
    }
    if (markerCounter < 100) {
      return {container: 'rgba(241, 211, 87, 1)', marker: 'rgba(240, 194, 12, 0.6)'};
    }
    return {container: 'rgba(253, 156, 115, 1)', marker: 'rgba(241, 128, 23, 0.6)'};
  }

  public addUserToMission({userId, taskIndex}: {userId: string; taskIndex: number}): void {
    const taskList = [...this.missionTaskList.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.missionTaskList.next(taskList);
  }

  public addUserToFlightPathTask({userId, taskIndex}: {userId: string; taskIndex: number}): void {
    const taskList = [...this.flightPathTasks.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.flightPathTasks.next(taskList);
  }

  public setMissionTaskStatus(missionTaskStatus: MissionTaskStatus): void {
    this.missionTaskStatus.next(missionTaskStatus);
  }

  public selectMarkersByTask(map: Map, taskId: string) {
    const taskLayers = this.taskMiniMapItems.getLayers(taskId);
    if (taskLayers?.length === 0) {
      return;
    }
    taskLayers[0].eachLayer(layer => {
      if (!taskId) {
        this.updateMiniMapSelectedMarker(layer, this.greyTaskMarkerIconColor);
        layer.setZIndexOffset(0);
        return;
      }
      if (layer.feature?.properties?.taskId === taskId) {
        this.updateMiniMapSelectedMarker(layer, this.selectedMarkerColor);
        layer.setZIndexOffset(1000);
        const mapBounds = map.getBounds();
        const layerPosition = layer.getLatLng();
        if (!mapBounds.contains(layerPosition)) {
          map.setView(layerPosition);
        }
      }
    });
  }

  public addTaskMarkersToMap(tasks, map, zoom) {
    const taskMarkers = tasks
      .filter(task => task.context.mission?.route)
      .map(task => ({
        coords: task.context.mission.route[0],
        id: task.id
      }));
    if (taskMarkers.length === 0) {
      return;
    }
    const geojsonFeature = {
      type: 'FeatureCollection',
      features: taskMarkers.map(values => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [values.coords.lng, values.coords.lat]
        },
        properties: {
          taskId: values.id
        }
      }))
    };
    const bounds = L.latLngBounds(taskMarkers.map(markers => markers?.coords));
    map.fitBounds(bounds, {padding: this.smallMiniMapPadding as PointExpression, maxZoom: zoom});
    const geojsonLayer = L.geoJSON(geojsonFeature, {
      pointToLayer: (feature, latlng) => {
        return L.marker(latlng, {
          icon: createMapIcon({
            size: this.defaultMarkerSize,
            color: this.greyTaskMarkerIconColor
          })
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on('mouseover', () => {
          this.zone.run(() => {
            this.setSelectedTaskId(layer.feature?.properties?.taskId);
            this.updateMiniMapSelectedMarker(layer, this.selectedMarkerColor);
            layer.setZIndexOffset(1000);
          });
        });
        layer.on('mouseout', () => {
          this.zone.run(() => {
            this.setSelectedTaskId(null);
            this.updateMiniMapSelectedMarker(layer, this.greyTaskMarkerIconColor);
            layer.setZIndexOffset(0);
          });
        });
      }
    });
    this.taskMiniMapItems.addLayer(geojsonLayer);
    this.taskMiniMapItems.addTo(map);
  }

  public removeTask(taskId: string) {
    const taskLayers = this.taskMiniMapItems.getLayers()[0];
    taskLayers?.eachLayer(layer => {
      if (layer.feature?.properties?.taskId === taskId) {
        taskLayers.removeLayer(layer);
      }
    });
  }

  public updateMiniMapSelectedMarker(marker, color) {
    marker.setIcon(
      createMapIcon({
        size: this.defaultMarkerSize,
        color
      })
    );
  }

  public toggleMarkersTooltip() {
    const cluster = this.drawnItems.getLayers()[0];
    cluster?.eachLayer(marker => {
      if (!this.isPopupEnabled.value) {
        marker.unbindTooltip();
        marker.unbindPopup();
        return;
      }
      this.setSelectedMarkerTooltip(marker.feature?.properties?.name, marker);
    });
  }

  public addMarkersToMap({
    markers,
    map,
    hasToEnableDraw,
    zoom
  }: {
    markers: any[];
    map: Map;
    hasToEnableDraw: boolean;
    zoom?: number;
  }) {
    const taskDrawZoom = 19;
    const minimapZoom = 16;
    const markerClusterGroup = L.markerClusterGroup({
      maxClusterRadius: 50,
      disableClusteringAtZoom: hasToEnableDraw ? taskDrawZoom : minimapZoom,
      spiderfyOnMaxZoom: false,
      type: this.originalClusterType
    });
    const geojsonFeature = {
      type: 'FeatureCollection',
      features: markers
    };
    const geojsonLayer = L.geoJSON(geojsonFeature, {
      pointToLayer: (feature, latlng) =>
        this.geojsonAssetLoaderService.generateMarker(feature, latlng, null, this.defaultMarkerSize),
      onEachFeature: (feature, layer) => {
        if (hasToEnableDraw) {
          layer.on('click', () => {
            const originalMarkersCluster = this.drawnItems.getLayers()[0];
            if (
              originalMarkersCluster.hasLayer(layer) &&
              layer.options?.icon?.options?.className === this.flightPathMarkerClass
            ) {
              return;
            }
            if (this.isDrawEventsBlocked) {
              return;
            }
            this.updateSelectedTaskStatus(layer, false);
            this.handleMarkerClick({layer, map});
          });
          layer.on('mouseover', event => {
            if (!this.isPopupEnabled.value) {
              return;
            }
            this.geojsonAssetLoaderService.addMouseOverLayerEvent({
              event,
              feature,
              layer,
              assetName: this.jobAsset.value?.name || ''
            });
          });
          layer.on('mouseout', () => {
            this.geojsonAssetLoaderService.addMouseOutLayerEvent(layer);
          });
        }
      }
    });
    markerClusterGroup.addLayer(geojsonLayer);
    hasToEnableDraw ? this.drawnItems.addLayer(markerClusterGroup) : this.miniMapItems.addLayer(markerClusterGroup);
    const bounds = geojsonLayer.getBounds();
    map.fitBounds(bounds, {padding: this.smallMiniMapPadding as PointExpression, maxZoom: zoom});
    if (hasToEnableDraw) {
      this.listenDrawMarkerOnMap(map);
    }
  }

  private setSelectedMarkerTooltip(assetId, layer) {
    if (assetId) {
      layer.bindTooltip(assetId, {
        closeButton: false,
        maxWidth: 172,
        autoPan: false,
        className: 'assetId-tooltip',
        permanent: true,
        offset: [7, 0] // add extra space in x axis for path icon
      });
      layer.openTooltip();
    }
  }

  public clearMiniMapItems(): void {
    this.miniMapItems.clearLayers();
  }

  public clearTaskMiniMapItems(): void {
    this.taskMiniMapItems.clearLayers();
  }

  public clearDrawnItems(): void {
    this.drawnItems.clearLayers();
  }

  public clearExistentPathGroup(): void {
    this.existentPathGroup.clearLayers();
  }

  public clearRouteGroup(): void {
    this.routeGroup.clearLayers();
  }

  public showExistentPathGroup(map) {
    this.existentPathGroup.addTo(map);
  }

  public hideExistentPathGroup(map) {
    this.existentPathGroup.removeFrom(map);
  }

  public hideDraw(map) {
    this.drawnItems.removeFrom(map);
  }

  public showDraw(map) {
    this.drawnItems.addTo(map);
  }

  public showMiniMapMarkers(map) {
    this.miniMapItems.addTo(map);
  }

  public generateRoutePath(route, group, generateTooltip) {
    route.forEach((point, i) => {
      const marker = L.marker(point);
      if (point.type == MissionPointType.POLE) {
        this.generateSelectedFlightPathMarker({isNewMarker: false, index: i + 1, layer: marker});
        if (point?.label && generateTooltip) {
          this.setSelectedMarkerTooltip(point.label, marker);
        }
      } else {
        this.generateSelectedFlightPathMarker({isNewMarker: true, index: i + 1, layer: marker});
      }
      group.addLayer(marker);
      if (i > 0) {
        const layer = L.polyline([route[i - 1], route[i]], {
          color: defaultMissionRouteColor,
          weight: this.drawedLinesWeight
        });
        group.addLayer(layer);
      }
    });
  }

  public displayRoutePath(map, route: any[]) {
    const bounds = L.latLngBounds(route);
    map.fitBounds(bounds, {padding: this.largeMiniMapPadding});
    this.generateRoutePath(route, this.routeGroup, true);
    this.routeGroup.addTo(map);
  }

  public hideMiniMapMarkers(map) {
    this.miniMapItems.removeFrom(map);
  }

  public updateDeletedFlightPathDraws(index: number) {
    const markers = this.flightPathTasks.value[index].markers;
    this.drawnItems.eachLayer(layer => {
      if (layer.options?.type === this.createdTaskPolylineType) {
        layer.removeFrom(this.drawnItems);
      }
      if (
        layer.options?.icon?.options?.type === this.newFlightPathMarkerClass ||
        layer.options?.icon?.options?.type === this.createdTaskMarkerClass
      ) {
        layer.removeFrom(this.drawnItems);
        return;
      }
    });

    markers.forEach(marker => {
      const defaultMarker = this.geojsonAssetLoaderService.generateMarker(
        marker.feature,
        marker.getLatLng(),
        null,
        this.defaultMarkerSize
      );
      if (defaultMarker) {
        marker.setIcon(defaultMarker.options?.icon);
      }
    });
  }

  public redrawFlightPaths(params: {markersOnly: boolean}): void {
    const allTaskMarkers = this.flightPathTasks.value.map(task => task.markers);
    allTaskMarkers.forEach(group => {
      if (params.markersOnly) {
        this.generateGreyMarkers(group);
        return;
      }
      this.generateGreyMarkers(group);
      this.generatePolylines(group, this.greenPathColor);
    });
  }

  public generatePolylines(group, color): void {
    for (let i = 1; i < group.length; i++) {
      const start = this.getLayerCoords(group[i - 1]);
      const end = this.getLayerCoords(group[i]);
      const layer = L.polyline([start, end], {
        color,
        weight: this.drawedLinesWeight,
        type: this.createdTaskPolylineType,
        internalId: group[i].options.internalId
      });

      this.setPolilyneEvents(layer);
      this.drawnItems.addLayer(layer);
    }
  }

  public generateGreyMarkers(markers) {
    markers.forEach((marker, index) => {
      const markerIcon = marker.getIcon();
      const newIcon = L.divIcon({
        className:
          markerIcon.options?.className === this.newFlightPathMarkerClass ||
          markerIcon.options?.type === this.newFlightPathMarkerClass
            ? this.createdTaskMarkerClass
            : this.poleCreatedTaskMarkerClass,
        type: markerIcon.options?.type || marker.options?.icon?.options?.className,
        html: `<div style="text-align: center;">
               <span style="position: absolute; top: 44%; left: 50%;
                            transform: translate(-50%, -50%);
                            height: 14px;
                            font-family: Arial; font-size: 12px; font-weight:700; text-anchor: middle;">
                 ${index + 1}
               </span>
             </div>`,
        iconSize: [20, 20]
      });
      marker.setIcon(newIcon);
      marker.dragging?.disable();
      if (marker.options.internalId) {
        marker.off('mouseover');
        marker.off('mouseout');
      }
      if (!marker.options.internalId) {
        marker.options.internalId = this.currentInternalId;
      }
      marker.on('mouseover', () => {
        this.updateSelectedTaskStatus(marker, true);
      });
      marker.on('mouseout', () => {
        this.updateSelectedTaskStatus(marker, false);
      });
      if (!this.drawnItems.hasLayer(marker)) {
        this.drawnItems.addLayer(marker);
      }
    });
  }

  private updateSelectedTaskStatus(layer, isSelected) {
    const flightPathTasks = [...this.flightPathTasks.value];
    const selectedFlightPathIndex = flightPathTasks.findIndex(task => task.internalId === layer.options?.internalId);
    if (selectedFlightPathIndex !== -1) {
      flightPathTasks[selectedFlightPathIndex] = {...flightPathTasks[selectedFlightPathIndex], isSelected};
      this.flightPathTasks.next(flightPathTasks);
    }
  }

  public generateRandomId() {
    return uuidv4();
  }

  public updateFlightPathTaskIcons() {
    this.drawnItems.eachLayer(layer => {
      if (layer.options?.type === this.defaultPolylineType) {
        layer.setStyle({color: this.greenPathColor});
        layer.options.type = this.createdTaskPolylineType;
        if (!layer.options.internalId) {
          layer.options.internalId = this.currentInternalId;
        }
        this.setPolilyneEvents(layer);
      }
      if (layer.options?.icon?.options?.className === this.plusMarkerClass) {
        layer.removeFrom(this.drawnItems);
      }
    });
    this.generateGreyMarkers(this.flightPathMarkers.value);
  }

  public undo(map) {
    if (this.flightPathMarkers.value.length === 0) {
      return;
    }
    const drawedMarkers = [...this.flightPathMarkers.value];
    const index = this.undoStack.pop();
    const lastMarker = drawedMarkers[index];
    lastMarker.removeFrom(this.drawnItems);
    this.redrawFlightPaths({markersOnly: false});
    if (lastMarker.options?.icon?.options?.className === this.flightPathMarkerClass) {
      const defaultMarker = this.geojsonAssetLoaderService.generateMarker(
        lastMarker.feature,
        lastMarker.getLatLng(),
        null,
        this.defaultMarkerSize
      );
      lastMarker.setIcon(defaultMarker.options?.icon);
    }
    drawedMarkers.splice(index, 1);
    this.flightPathMarkers.next(drawedMarkers);
    this.redrawPath(map, true);
  }

  public serializeLatLng(latLng) {
    return `${latLng.lat},${latLng.lng}`;
  }
  public isMarkerPartOfSelectedLayers(arr1, arr2) {
    const serializedArr1 = arr1.map(this.serializeLatLng);
    const serializedArr2 = arr2.map(this.serializeLatLng);
    const serializedStr1 = serializedArr1.join('|');
    const serializedStr2 = serializedArr2.join('|');
    return serializedStr2.includes(serializedStr1);
  }

  public clearAllDrawnItems() {
    this.drawnItems.eachLayer(layer => {
      if (layer.options?.type === this.originalClusterType) {
        layer?.eachLayer(marker => {
          if (marker.options?.icon?.options?.className === this.flightPathMarkerClass) {
            const icon = this.geojsonAssetLoaderService
              .generateMarker(
                marker.feature,
                marker.getLatLng(),
                this.atlasService.getAssetById(this.selectedAssetId),
                this.defaultMarkerSize
              )
              ?.getIcon();
            marker.setIcon(icon);
          }
        });
        return;
      }
      if (
        layer.options?.icon?.options?.className === this.createdTaskMarkerClass ||
        layer.options?.type === this.createdTaskPolylineType
      ) {
        return;
      }
      this.flightPathMarkers.next([]);
      this.undoStack = [];
      layer.removeFrom(this.drawnItems);
      this.redrawFlightPaths({markersOnly: true});
    });
  }

  public clearFlightPathData(): void {
    this.flightPathTasks.next([]);
    this.flightPathMarkers.next([]);
    this.undoStack = [];
  }

  private setPolilyneEvents(layer): void {
    if (layer.options.internalId) {
      layer.off('mouseover');
      layer.off('mouseout');
    }
    layer.on('mouseover', () => {
      this.updateSelectedTaskStatus(layer, true);
    });
    layer.on('mouseout', () => {
      this.updateSelectedTaskStatus(layer, false);
    });
  }

  private listenDrawMarkerOnMap(map) {
    map.on('click', event => {
      if (this.isDrawEventsBlocked) {
        return;
      }
      const coords = [event.latlng.lat, event.latlng.lng];
      const newMarker = L.marker(coords, {draggable: true});
      this.drawnItems.addLayer(newMarker);
      newMarker.on('drag', () => {
        this.redrawPath(map, false);
      });
      newMarker.on('click', () => {
        if (
          this.drawnItems.hasLayer(newMarker) &&
          newMarker.options?.icon?.options?.className !== this.createdTaskMarkerClass
        ) {
          return;
        }
        this.updateSelectedTaskStatus(newMarker, false);
        this.handleMarkerClick({layer: newMarker, map, isNewMarker: true});
      });
      this.handleMarkerClick({layer: newMarker, map, isNewMarker: true});
    });
  }

  private generateSelectedFlightPathMarker(params: {isNewMarker: boolean; index: number; layer: any}) {
    const {isNewMarker, index, layer} = params;
    const newIcon = L.divIcon({
      className: isNewMarker ? this.newFlightPathMarkerClass : this.flightPathMarkerClass,
      html: `<div style="text-align: center;">
             <span style="position: absolute; top: 44%; left: 50%;
                          transform: translate(-50%, -50%);
                          height: 14px;
                          font-family: Arial; font-size: 12px; font-weight:700; text-anchor: middle;">
               ${index}
             </span>
           </div>`,
      iconSize: [20, 20]
    });
    layer.setIcon(newIcon);
  }

  private handleMarkerClick(params: {layer: any; map: any; isNewMarker?: boolean}) {
    this.flightPathMarkers.next([...this.flightPathMarkers.value, params.layer]);
    this.undoStack.push(this.flightPathMarkers.value.length - 1);
    const index = this.flightPathMarkers.value.length;
    this.generateSelectedFlightPathMarker({isNewMarker: params.isNewMarker, index: index, layer: params.layer});
    if (this.flightPathMarkers.value.length > 1) {
      const lastIndex = this.flightPathMarkers.value.length - 1;
      const start = this.getLayerCoords(this.flightPathMarkers.value[lastIndex - 1]);
      const end = this.getLayerCoords(this.flightPathMarkers.value[lastIndex]);
      const midpoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
      const line = L.polyline([start, end], {
        color: defaultMissionRouteColor,
        weight: this.drawedLinesWeight,
        type: this.defaultPolylineType
      });
      this.drawnItems.addLayer(line);
      this.generatePlusMarker(midpoint, params.map, lastIndex);
    }
  }

  private removePolilynes() {
    this.drawnItems.eachLayer(layer => {
      if (
        (layer instanceof L.Polyline && layer.options?.type !== this.createdTaskPolylineType) ||
        layer.options?.icon?.options?.className === this.plusMarkerClass
      ) {
        layer.removeFrom(this.drawnItems);
      }
    });
  }

  private redrawPath(map, includeMarkers: boolean) {
    this.removePolilynes();
    for (let i = 0; i < this.flightPathMarkers.value.length; i++) {
      const currentMarker = this.flightPathMarkers.value[i];
      if (i >= 1) {
        const start = this.getLayerCoords(this.flightPathMarkers.value[i - 1]);
        const end = this.getLayerCoords(currentMarker);
        const line = L.polyline([start, end], {
          color: defaultMissionRouteColor,
          weight: this.drawedLinesWeight,
          type: this.defaultPolylineType
        });
        this.drawnItems.addLayer(line);
        const midpoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
        this.generatePlusMarker(midpoint, map, i);
      }
      if (includeMarkers) {
        const markerOptions = currentMarker.options?.icon?.options;
        const generateMarkerParams = {
          isNewMarker:
            markerOptions?.className === this.newFlightPathMarkerClass ||
            markerOptions?.type === this.newFlightPathMarkerClass ||
            !markerOptions?.className,
          index: i + 1,
          layer: currentMarker
        };
        this.generateSelectedFlightPathMarker(generateMarkerParams);
      }
    }
  }

  private generatePlusMarker(midpoint, map, lastIndex) {
    const plusMarker = L.marker(midpoint, {
      icon: L.divIcon({
        className: this.plusMarkerClass,
        html: '+',
        iconSize: [11, 11],
        iconAnchor: [5, 5]
      })
    });
    this.drawnItems.addLayer(plusMarker);

    plusMarker.on('click', () => {
      plusMarker.removeFrom(map);
      const marker = L.marker(
        midpoint,
        {draggable: true},

        {
          icon: L.divIcon({
            className: this.newFlightPathMarkerClass
          })
        }
      );
      marker.on('drag', () => {
        this.redrawPath(map, false);
      });
      marker.on('click', () => {
        if (marker.options?.icon?.options?.className !== this.createdTaskMarkerClass) {
          return;
        }
        this.handleMarkerClick({layer: marker, map, isNewMarker: true});
      });
      this.drawnItems.addLayer(marker);
      const updatedFlightPathMarkers = [...this.flightPathMarkers.value];
      this.undoStack.push(lastIndex);
      updatedFlightPathMarkers.splice(lastIndex, 0, marker);
      this.flightPathMarkers.next(updatedFlightPathMarkers);
      this.redrawPath(map, true);
    });
  }

  private getLayerCoords(layer) {
    return [layer.getLatLng().lat, layer.getLatLng().lng];
  }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {WebrtcService} from '@app/core/services/api/webrtc.service';
import {Addon} from '@app/store/addon/models/addon';
import {RemoteVideoTrackStats} from 'agora-rtc-sdk-ng';
import {environment} from 'environments/environment';
import 'leaflet-rotatedmarker';
import {filter, map, mergeMap, Observable, Subscription, switchMap, take} from 'rxjs';
import {DeviceNotFoundDialogComponent} from '../device-not-found-dialog/device-not-found-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {UntilDestroy} from '@ngneat/until-destroy';
import {LiveStreamPageService} from '@app/live/pages/live-stream-page/live-stream-page.service';
import {IoTSensorsData} from '@app/flights/models/remote-cockpit.model';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'atlas-video',
  templateUrl: './atlas-video.component.html',
  styleUrls: ['./atlas-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtlasVideoComponent {
  @Input() public gpsDevices$: Observable<UserDeviceJoined[]>;
  @Output() public emitShowLayer: EventEmitter<UserDeviceJoined> = new EventEmitter();
  @Output() public deviceUpdated: EventEmitter<void> = new EventEmitter();

  public streamPlayer = StreamPlayer;
  public streamingGpsDevices$: Observable<UserDeviceJoined[]>;
  public activeStreamingDeviceIndex: number = 0;
  public activeStreamingDevice: UserDeviceJoined;
  public activeWebrtcPlayerStats: RemoteVideoTrackStats;
  public ioTSensorsData: IoTSensorsData;
  public horizontalSpeed: number;
  public agoraToken$: Observable<string>;
  public rawStream: Partial<Addon> = {
    id: 'RAW_STREAM',
    name: 'Raw stream',
    subtitle: 'No AI activated',
    description: 'Watch your raw live stream without any A.I. analysis.'
  };
  public streamAppId = environment.PWA_STREAMING_APP_ID;
  private activeStreamingDeviceSub: Subscription;
  private streamsSub: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public atlasService: AtlasService,
    public webrtcService: WebrtcService,
    private liveStreamPageService: LiveStreamPageService
  ) {
    this.streamingGpsDevices$ = this.getStreamingGpsDevices();
    this.activeStreamingDeviceChange();

    this.agoraToken$ = this.atlasService.activeStreamingDevice$.pipe(
      filter(device => !!device && device.player === this.streamPlayer.WEBRTC),
      take(1),
      switchMap(device => {
        return this.webrtcService.getWebRTCSubscriberToken(device.deviceId || device.id).pipe(
          take(1),
          map(token => token)
        );
      })
    );
  }

  public prevStreamingDevice(streamingGpsDevices: UserDeviceJoined[]) {
    if (this.activeStreamingDeviceIndex > 0) {
      this.activeStreamingDeviceIndex--;
    }
    this.atlasService.activeStreamingDevice$.next({
      ...streamingGpsDevices[this.activeStreamingDeviceIndex],
      id: null
    });
    this.updateDisplayedStream(streamingGpsDevices[this.activeStreamingDeviceIndex]);
  }

  public nextStreamingDevice(streamingGpsDevices: UserDeviceJoined[]) {
    if (this.activeStreamingDeviceIndex < streamingGpsDevices.length - 1) {
      this.activeStreamingDeviceIndex++;
    }
    this.updateDisplayedStream(streamingGpsDevices[this.activeStreamingDeviceIndex]);
  }

  public videoStats(stats: {[key: string]: RemoteVideoTrackStats | string}) {
    const values = Object.values(stats);
    const validObjectValueCount = 2;
    this.activeWebrtcPlayerStats = values.length === validObjectValueCount && (values[0] as RemoteVideoTrackStats);
  }

  private getStreamingGpsDevices(): Observable<UserDeviceJoined[]> {
    return this.liveStreamPageService.liveDevicesId$.pipe(
      mergeMap(
        (streamingDevices: string[]): Observable<UserDeviceJoined[]> =>
          this.gpsDevices$.pipe(
            map((gpsDevices: UserDeviceJoined[]): UserDeviceJoined[] => {
              const filteredDevices = gpsDevices.filter(
                (device: UserDeviceJoined): boolean => streamingDevices.indexOf(device.id) !== -1
              );
              if (filteredDevices.length && this.activeStreamingDeviceIndex >= filteredDevices.length) {
                this.activeStreamingDeviceIndex--;
              }
              this.atlasService.activeStreamingDevice$.next(filteredDevices[this.activeStreamingDeviceIndex]);
              return filteredDevices;
            })
          )
      )
    );
  }

  public toggleStream(options: {id: string; alertText?: string; verifyLiveStream?: boolean}) {
    this.streamingGpsDevices$.pipe(take(1)).subscribe(streamingGpsDevices => {
      const hasToShowDialog = options.verifyLiveStream
        ? !streamingGpsDevices.some(obj => Object.values(obj).includes(options.id))
        : false;
      if (hasToShowDialog) {
        this.dialog.open(DeviceNotFoundDialogComponent, {
          ...STANDARD_DIALOG_CONFIG,
          width: '80vw',
          maxWidth: '800px'
        });
        return;
      }
      this.activeStreamingDeviceIndex = streamingGpsDevices.findIndex(device => device.id === options.id);
      const activeStreamingDevice = streamingGpsDevices[this.activeStreamingDeviceIndex];
      this.updateDisplayedStream(activeStreamingDevice);
      if (activeStreamingDevice) {
        this.emitShowLayer.emit(activeStreamingDevice);
        if (options.alertText) {
          this.snackBar.open(`${options.alertText} ${activeStreamingDevice.name}`, null, {
            duration: 5000,
            horizontalPosition: 'left'
          });
        }
      }
    });
  }

  public iotDataChange(data: IoTSensorsData, device: UserDeviceJoined): void {
    if (this.activeStreamingDevice.id === device.id) {
      this.ioTSensorsData = data;
      this.horizontalSpeed = Math.sqrt(
        Math.abs(Math.pow(this.ioTSensorsData.velX, 2)) + Math.abs(this.ioTSensorsData.velY)
      );
    }
  }

  public emitDeviceUpdated(): void {
    this.deviceUpdated.emit();
  }

  private activeStreamingDeviceChange(): void {
    this.activeStreamingDeviceSub = this.atlasService.activeStreamingDevice$.subscribe(device => {
      if (device && device.id !== this.activeStreamingDevice?.id) {
        this.activeStreamingDevice = device;
        this.activeWebrtcPlayerStats = null;
      }
    });
  }

  private updateDisplayedStream(activeStreamingDevice: UserDeviceJoined): void {
    this.atlasService.activeStreamingDevice$.next(null);
    this.cd.detectChanges();
    this.atlasService.activeStreamingDevice$.next(activeStreamingDevice);
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomMetatags } from '../models/custom-metatags.enum';

@Injectable({
  providedIn: 'root'
})
export class MetatagService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getMetatagValue(metatag: CustomMetatags): Observable<string | null> {
    const metaElement = this.document.querySelector(`meta[name="${metatag}"]`);
    return of(metaElement?.getAttribute('content') || null);
  }
}

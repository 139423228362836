<div class="time-selector">
  <section>
    <div *ngIf="date; else recurringTemplate" [class.disabled]="isDisabled" class="time-selector__date">
      <span class="time-selector__day"> {{ date | date: 'dd MMM' }} </span>
      <span class="time-selector__date-day">{{ day }}</span>
    </div>
    <ng-template #recurringTemplate>
      <span class="time-selector__day"> {{ day }} </span>
    </ng-template>
  </section>
  <section class="timeline">
    <div class="hours">
      <div class="hours__item">0:00</div>
      <div class="hours__item">&nbsp;3:00</div>
      <div class="hours__item">&nbsp;6:00</div>
      <div class="hours__item">&nbsp;9:00</div>
      <div class="hours__item">12:00</div>
      <div class="hours__item">15:00</div>
      <div class="hours__item">18:00</div>
      <div class="hours__item">21:00</div>
    </div>
    <div
      id="timeline"
      (click)="isDisabled || !isAdmin ? '' : onTimelineClick($event)"
      [class.disabled]="isDisabled"
      class="timeline__rect"
    >
      <div class="timeline__divider"></div>
      <div
        *ngFor="let selectedSection of selectedSections; let i = index"
        class="timeline__resizable"
        [class.disabled]="!isAdmin || selectedSection?.isDisabledRange"
        [style.transform]="'translateX(' + selectedSection.x + 'px)'"
        [style.width.px]="selectedSection.width"
      >
        <div
          [class.timeline-short]="selectedSection.width < 120"
          [class.disabled]="!isAdmin || selectedSection?.isDisabledRange"
          class="timeline__content"
        >
          <ng-container *ngIf="!selectedSection?.isDisabledRange">
            <span class="timeline__hour" [class.hour-left]="selectedSection.width < 120">
              {{ selectedSection.startTime | date: 'HH:mm' }}
            </span>
            <span *ngIf="selectedSection.width > 182" class="timeline__text">
              {{ 'live.scheduler.recordingOn' | translate }}
            </span>
            <span class="timeline__hour" [class.hour-right]="selectedSection.width < 120">
              {{ selectedSection.endTime | date: 'HH:mm' }}
            </span>
          </ng-container>
        </div>
        <div
          *ngIf="isAdmin && !selectedSection?.isDisabledRange"
          class="timeline__resizer left"
          [class.disabled]="selectedSection?.invalid"
          (mousedown)="selectedSection?.invalid ? '' : setStatus($event, leftResize, i)"
        ></div>
        <div
          *ngIf="isAdmin && !selectedSection?.isDisabledRange"
          class="timeline__resizer right"
          [class.disabled]="selectedSection?.invalid"
          (mousedown)="selectedSection?.invalid ? '' : setStatus($event, rightResize, i)"
        ></div>
        <mat-icon
          *ngIf="isAdmin && !selectedSection?.isDisabledRange"
          class="timeline__close"
          (click)="deleteSelection(i); $event.preventDefault(); $event.stopPropagation()"
          svgIcon="delete-schedule"
        ></mat-icon>
      </div>
    </div>
    <mat-error class="timeline__error" *ngIf="hasError">
      ** {{ 'live.scheduler.invalidRanges' | translate: {value: date | date: 'EEEE dd MMM'} }}
    </mat-error>
  </section>
</div>

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationModel} from '@app/core/models/api/notifications.model';
import {selectNewNotification} from '@app/core/notifications/notifications.selectors';
import {UPLOAD_METHODS} from '@app/library/models/upload-types.model';
import {ApiPayloadV2} from '@app/shared/payload-transformer/payload-transformer';
import {Addon} from '@app/store/addon/models/addon';
import {select, Store} from '@ngrx/store';
import {filter, map, Observable, switchMap} from 'rxjs';
import {AddonCategoriesFilters, Severities} from '../models/addon-categories-filters';
import {LibraryItemMutateModel} from '../models/delete-items.model';
import {LibraryItem, LibraryViewMode} from '../models/folder-file.model';
import {AnnotationsSummaryChip, AnnotationsSummaryData, NextToken} from '../models/library-api-list-response.model';
import {LibraryFilter} from '../models/library-filter.model';
import {AnnotationsModel} from '../models/library-list-child-ids-response.model';
import {ModelData, ModelRequestOptions} from '../models/models.model';
import {Tag} from '../models/tag.model';
import * as downloadAnalyticsActions from '../store/download-analytics/download-analytics.actions';
import * as analyzeActions from '../store/analyze/analyze.actions';
import * as annotationsSummaryActions from '../store/annotations-summary/annotations-summary.actions';
import * as actions from '../store/library.actions';
import * as assignToAssetsActions from '../store/assign-to-assets/assign-to-assets-actions';
import * as selectors from '../store/library.selectors';
import {AclService} from '@app/core/services/acl.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {LibraryLayout} from '@app/library/models/library-layout.model';
import {LibraryState} from '@app/library/store/library.reducer';
import {MenuActions} from '@app/library/models/action-menu.model';

@Injectable({
  providedIn: 'root'
})
export class LibraryStoreFacadeService {
  public hasSelectedAllCurrentViewItems$: Observable<boolean> = this.store.select(
    selectors.selectHasSelectedAllCurrentViewItems
  );
  public currentPath$: Observable<string[]> = this.store.select(selectors.selectCurrentPath);
  public availableModels$: Observable<LibraryItem[]> = this.store.select(selectors.selectAvailableModels);
  public selectedModel$: Observable<ModelData> = this.store.select(selectors.selectSelectedModel);
  public selectedLibraryItem$: Observable<LibraryItem> = this.store.select(selectors.selectSelectedLibraryItem);
  public isLoading$: Observable<boolean> = this.store.select(selectors.selectLibraryIsLoading);
  public isLoadingAnnotationsSummary$: Observable<boolean> = this.store.select(
    selectors.selectIsLoadingAnnotationsSummary
  );
  public selectActiveAddonsAnnotationsSummaryData$: Observable<string[]> = this.store.select(
    selectors.selectActiveAddonsAnnotationsSummaryData
  );
  public isSelectionMode$: Observable<boolean> = this.store.pipe(select(selectors.selectIsSelectionMode));
  public isActionHeaderMode$: Observable<boolean> = this.store.pipe(select(selectors.selectIsActionHeaderMode));
  public selectAiVideoMenu$: Observable<Partial<LibraryItem>[]> = this.store.pipe(select(selectors.selectAiVideoMenu));
  public selectIsLoadingAiVideoMenu$: Observable<boolean> = this.store.pipe(
    select(selectors.selectIsLoadingAiVideoMenu)
  );
  public hasExportAnnotationsOption$: Observable<boolean> = this.store.select(
    selectors.selectHasExportAnnotationsOption
  );
  public hasAddToAtlasOption$: Observable<boolean> = this.store.select(selectors.selectHasAddToAtlasOption).pipe(
    switchMap(hasToAddToAtlasOption =>
      this.aclService.hasSetupPermission$.pipe(
        filter(hasSetupPermissions => !!hasSetupPermissions),
        map(() => {
          return hasToAddToAtlasOption && this.aclService.hasPermission(AclPermissions.AtlasApiGenerate);
        })
      )
    )
  );
  public tags$: Observable<Tag[]> = this.store.select(selectors.selectTags);
  public tagsObject$: Observable<{[key: string]: Tag}> = this.store.select(selectors.selectTagsObject);

  public suggestedTags$: Observable<Tag[]> = this.store.select(selectors.selectSuggestedTags);

  // eslint-disable-next-line rxjs/finnish
  public selectImagesFromLibraryItems: Observable<LibraryItem[]> = this.store.select(
    selectors.selectImagesFromLibraryItems
  );

  public activeTags$: Observable<{[key: string]: Tag[]}> = this.store.select(selectors.selectActiveTags);
  public annotationsSummaryItems$: Observable<LibraryItem[]> = this.store.select(
    selectors.selectAnnotationsSummaryItems
  );
  public annotationsSummaryData$: Observable<AnnotationsSummaryData[]> = this.store.select(
    selectors.selectAnnotationsSummaryData
  );
  public activeAnnotationsSummaryData$: Observable<AnnotationsSummaryData[]> = this.store.select(
    selectors.selectActiveAnnotationsSummaryData
  );
  public libraryItemForSidebar$: Observable<LibraryItem> = this.store.select(selectors.selectLibraryItemForSidebar);
  public activeTagsForSidebar$: Observable<Tag[]> = this.store.select(selectors.selectActiveTagsForSidebar);
  public activeTagsForSelectionBar$: Observable<Tag[]> = this.store.select(selectors.selectActiveTagsForSelectionBar);
  public tagsLoading$: Observable<boolean> = this.store.select(selectors.selectTagsLoading);

  public hasAnalyzeOption$: Observable<boolean> = this.store.pipe(select(selectors.selectHasAnalyzeOption));
  public filters$: Observable<{[key: string]: Partial<LibraryFilter>}> = this.store.pipe(
    select(selectors.selectFilters)
  );
  public totalImagesSelected$: Observable<number> = this.store.select(selectors.totalImagesSelected);
  public isOnlyItemsTypeImageSelected$: Observable<boolean> = this.store.select(selectors.isOnlyItemsTypeImageSelected);
  public selectCurrentAddonCategories$: Observable<AddonCategoriesFilters[]> = this.store.select(
    selectors.selectCurrentAddonCategories
  );
  public hasSelectionAFolder$: Observable<boolean> = this.store.select(selectors.hasSelectionAFolder);
  public hasPanoramaSelected$: Observable<boolean> = this.store.select(selectors.hasPanoramaSelected);
  public annotationsSummaryActiveTags$: Observable<Tag[]> = this.store.select(
    selectors.selectAnnotationsSummaryActiveTags
  );
  public severityHeaderButtons$: Observable<Severities> = this.store.select(selectors.selectSeverityHeaderButtons);
  public annotationsSummaryChips$: Observable<AnnotationsSummaryChip[]> = this.store.select(
    selectors.selectAnnotationsSummaryChips
  );
  public hasInitialAnnotationsSummaryData$: Observable<boolean> = this.store.select(
    selectors.selectHasInitialAnnotationsSummaryData
  );
  public annotationsSummaryHasImages$: Observable<boolean> = this.store.select(selectors.annotationsSummaryHasImages);
  public isFilterActive$: Observable<boolean> = this.store.select(selectors.isFilterActive);
  public libraryItemForContextMenu$ = this.store.select(selectors.libraryItemForContextMenu);
  public libraryState$: Observable<LibraryState> = this.store.select(selectors.selectLibraryState);
  public specialFoldersIds$: Observable<string[]> = this.store.select(selectors.selectSpecialFoldersIds);
  public hasSelectedSpecialFolders$: Observable<boolean> = this.store.select(selectors.hasSelectedSpecialFolders);
  public libraryViewMode$: Observable<LibraryViewMode> = this.store.select(selectors.selectLibraryViewMode);

  constructor(public store: Store, private aclService: AclService) {}

  public activeAnnotationSummaryTags(tagIds: string[]): Observable<Tag[]> {
    return this.store.pipe(select(selectors.activeAnnotationSummaryTags(tagIds)));
  }

  public loadLibraryItemsFromStoreCache(): void {
    this.store.dispatch(actions.loadLibraryItemsFromStoreCache());
  }

  public appendLoadLibraryItems(): void {
    this.store.dispatch(actions.appendLoadLibraryItems());
  }

  public setLibraryItems(libraryItems: {[key: string]: LibraryItem}, nextToken: NextToken | undefined): void {
    this.store.dispatch(actions.saveLibraryItems({payload: {libraryItems, nextToken}}));
  }

  // eslint-disable-next-line rxjs/finnish
  public selectListViewSorted(): Observable<LibraryItem[]> {
    return this.store.pipe(select(selectors.selectListViewSorted));
  }

  public watchUploadedLibraryItem(userId: string): void {
    return this.store.dispatch(actions.watchUploadedFilesLibraryItem({payload: {userId}}));
  }

  public watchUploadedLibraryItems(userId: string): void {
    return this.store.dispatch(actions.watchUploadedFilesLibraryItems({payload: {userId}}));
  }

  public watchDeleteLibraryItem(): void {
    return this.store.dispatch(actions.watchDeleteLibraryItem());
  }

  public watchBackStateLibraryItem(): void {
    return this.store.dispatch(actions.watchBackStateLibraryItem());
  }

  public getLibraryItemsSilently(libraryItemIds: LibraryItemMutateModel[], withAnnotations: boolean = false): void {
    return this.store.dispatch(
      actions.loadLibraryItemsSilently({
        payload: {libraryItemIds, withAnnotations}
      })
    );
  }

  public saveLibraryItemsSilently(libraryItems: {[key: string]: LibraryItem}): void {
    this.store.dispatch(actions.saveLibraryItemsSilently({payload: {libraryItems}}));
  }

  public createEmptyFolder(folderName: string, parentId: string, location?: string): void {
    this.store.dispatch(
      actions.createEmptyFolder({
        payload: {folderName, parentId, location}
      })
    );
  }

  public displayLoader(): void {
    this.store.dispatch(actions.displayLoader());
  }

  public hideLoader(): void {
    this.store.dispatch(actions.hideLoader());
  }

  public selectLibraryIsLoading(): Observable<boolean> {
    return this.store.pipe(select(selectors.selectLibraryIsLoading));
  }

  public setFolderPath(path: string[]): void {
    this.store.dispatch(actions.saveFolderPath({payload: {path}}));
  }

  public selectLibraryFolderPath(): Observable<string[]> {
    return this.store.pipe(select(selectors.selectLibraryFolderPath));
  }

  public addToBreadcrumbPath(pathId: string): void {
    this.store.dispatch(actions.addFolderPath({payload: {pathId}}));
  }

  public deleteLastBreadcrumbPathByIndex(pathIndex: number): void {
    this.store.dispatch(actions.deleteUntilFolderPathIndex({payload: {pathIndex}}));
  }

  public clearBreadcrumb(): void {
    this.store.dispatch(actions.clearFolderPath());
  }

  public selectLibraryItem(libraryItemId: string): Observable<LibraryItem> {
    return this.store.pipe(select(selectors.selectLibraryItem(libraryItemId)));
  }

  public openFolder(libraryItemId: string, openInNewTab?: boolean): void {
    this.store.dispatch(actions.openFolder({payload: {libraryItemId, openInNewTab}}));
  }

  public openFolderFromBreadcrumb(libraryItemId: string): void {
    this.store.dispatch(actions.openFolderFromBreadcrumb({payload: {libraryItemId}}));
  }

  public openUploadFilesFromGoogleDriveDialog(): void {
    this.store.dispatch(actions.openUploadFilesFromGoogleDriveDialog());
  }

  public openExportToS3Dialog(): void {
    this.store.dispatch(actions.openExportToS3Dialog());
  }

  public openUploadFilesFromSkydioDialog(): void {
    this.store.dispatch(actions.openUploadFilesFromSkydioDialog());
  }

  public openImportFromS3Dialog(): void {
    this.store.dispatch(actions.openImportFromS3Dialog());
  }

  public openUploadDialog(
    files: File[],
    parentId: string,
    uploadType: UPLOAD_METHODS,
    totalItemsDropped: number
  ): void {
    this.store.dispatch(actions.openUploadDialog({payload: {files, parentId, uploadType, totalItemsDropped}}));
  }

  public markAsSelectedLibraryItem(libraryItemId: string): void {
    return this.store.dispatch(actions.markAsSelectLibraryItem({payload: {libraryItemId}}));
  }

  public markAsUnselectedLibraryItem(libraryItemId: string): void {
    return this.store.dispatch(actions.markAsUnselectLibraryItem({payload: {libraryItemId}}));
  }

  public selectAllLibraryItemsFromCurrentView(): void {
    return this.store.dispatch(actions.selectAllLibraryItemsFromCurrentView());
  }

  public unselectAllLibraryItems(): void {
    return this.store.dispatch(actions.unselectAllLibraryItems());
  }

  public unselectAllLibraryItemsFromCurrentView(): void {
    return this.store.dispatch(actions.unselectAllLibraryItemsFromCurrentView());
  }

  public unselectAllLibraryItemsFromCurrentViewSuccess(selectedItems: LibraryItem[]): void {
    return this.store.dispatch(actions.unselectAllLibraryItemsFromCurrentViewSuccess({payload: {selectedItems}}));
  }

  public unselectAllLibraryItemsSuccess(selectedItems: LibraryItem[]): void {
    return this.store.dispatch(actions.unselectAllLibraryItemsSuccess({payload: {selectedItems}}));
  }

  public openMoveLibraryItemsDialog(): void {
    return this.store.dispatch(actions.openMoveLibraryItemsDialog());
  }

  public openDeleteItemsDialog(): void {
    return this.store.dispatch(actions.openDeleteLibraryItemsDialog());
  }

  public openRenameItemDialog(libraryItemId: string): void {
    return this.store.dispatch(actions.openRenameLibraryItemDialog({payload: {libraryItemId}}));
  }

  public renameItem(newName: string, libraryItem: LibraryItem): void {
    this.store.dispatch(actions.renameLibraryItemStart({payload: {newName, libraryItem}}));
  }

  public getSelectedLibraryItems(): Observable<LibraryItem[]> {
    return this.store.pipe(select(selectors.selectSelectedItems));
  }

  public loadBreadcrumbs(location: string): void {
    this.store.dispatch(actions.loadBreadcrumbs({payload: {location}}));
  }

  public requestFolderLocation(folderId: string): void {
    this.store.dispatch(actions.requestFolderLocation({payload: {folderId}}));
  }

  public deleteLibraryItems(selectedItems: LibraryItem[]): void {
    const deleteItems: LibraryItemMutateModel[] = [];
    for (const item of selectedItems) {
      deleteItems.push({
        id: item.id
      });
    }

    this.store.dispatch(actions.deleteLibraryItems({payload: {deleteItems}}));
  }

  public selectNextToken(): Observable<NextToken> {
    return this.store.pipe(select(selectors.selectNextToken));
  }

  public openAnnotate(libraryItem: LibraryItem, addonId: string): void {
    this.store.dispatch(actions.openAnnotate({payload: {libraryItem, addonId}}));
  }

  public imageModel(libraryItemIds: string[], options: ModelRequestOptions): void {
    this.store.dispatch(actions.imageModel({payload: {libraryItemIds, options}}));
  }

  public openVideoModelDialog(libraryItemId: string): void {
    this.store.dispatch(actions.openVideoModelDialog({payload: {libraryItemId}}));
  }

  public videoModel(libraryItemIds: string[]): void {
    this.store.dispatch(actions.videoModel({payload: {libraryItemIds}}));
  }

  public openImageModelDialog(): void {
    this.store.dispatch(actions.openImageModelDialog());
  }

  public addLibraryItem(item: LibraryItem): void {
    this.store.dispatch(actions.addLibraryItem({payload: {libraryItem: item}}));
  }

  public addLibraryItemError(): void {
    this.store.dispatch(actions.addLibraryItemError());
  }

  public noMoreLibraryItemsToLoad(): void {
    this.store.dispatch(actions.noMoreLibraryItemsToLoad());
  }

  public watchLoadLibraryItemsOnScroll(): void {
    this.store.dispatch(actions.watchLoadLibraryItemsOnScroll());
  }

  public updateAnnotationLibraryItem(libraryItemId: string, annotations: AnnotationsModel): void {
    this.store.dispatch(
      actions.updateAnnotationLibraryItem({
        payload: {libraryItemId, annotations}
      })
    );
  }

  public setFilterUrlParams(filterParams: LibraryFilter): void {
    this.store.dispatch(actions.setFilterUrlParams({payload: {filterParams}}));
  }

  public updateFilterUrlParams(filterParams: Partial<LibraryFilter>): void {
    this.store.dispatch(actions.updateFilterUrlParams({payload: {filterParams}}));
  }

  public recognise(payload: ApiPayloadV2): void {
    this.store.dispatch(actions.recognise({payload}));
  }

  public openModelPickerDialog(libraryItem: LibraryItem, addonsWithConfig: Addon[]): void {
    this.store.dispatch(actions.openModelPickerDialog({payload: {libraryItem, addonsWithConfig}}));
  }

  public createNewTag(tagName: string, libraryItem?: LibraryItem, libraryLayout?: LibraryLayout): void {
    this.store.dispatch(actions.createNewTag({tagName, libraryItem, libraryLayout}));
  }

  public createNewTagSuccess(tag: Tag): void {
    this.store.dispatch(actions.createNewTagSuccess({tag}));
  }

  public createNewTagFail(error: HttpErrorResponse): void {
    this.store.dispatch(actions.createNewTagFail(error));
  }

  public selectTags(): Observable<Tag[]> {
    return this.store.pipe(select(selectors.selectTags));
  }

  public selectTagsColors(): Observable<{[key: string]: string}> {
    return this.store.pipe(select(selectors.selectTagsColors));
  }

  public selectSuggestedTags(): Observable<Tag[]> {
    return this.store.pipe(select(selectors.selectSuggestedTags));
  }

  public selectActiveTags(): Observable<any> {
    return this.store.pipe(select(selectors.selectActiveTags));
  }

  public renameTag(tag: Tag): void {
    this.store.dispatch(actions.renameTag({payload: {tag}}));
  }

  public renameTagSuccess(tag: Tag): void {
    this.store.dispatch(actions.renameTagSuccess({payload: {tag}}));
  }

  public renameTagFail(error: HttpErrorResponse): void {
    this.store.dispatch(actions.renameTagFail({payload: {error}}));
  }

  public updateColorTag(tag: Tag): void {
    this.store.dispatch(actions.updateColorTag({payload: {tag}}));
  }

  public updateColorTagSuccess(tag: Tag): void {
    this.store.dispatch(actions.updateColorTagSuccess({payload: {tag}}));
  }

  public deleteTag(tag: Tag): void {
    this.store.dispatch(actions.deleteTag({payload: {tag}}));
  }

  public deleteTagSuccess(tag: Tag): void {
    this.store.dispatch(actions.deleteTagSuccess({payload: {tag}}));
  }

  public deleteTagFail(error: HttpErrorResponse): void {
    this.store.dispatch(actions.deleteTagFail({payload: {error}}));
  }

  public tagLoading(): void {
    this.store.dispatch(actions.tagLoading());
  }

  public download(): void {
    this.store.dispatch(actions.downloadStart());
  }

  public share(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.share({payload: {libraryItem}}));
  }

  public removeLibraryItemFromStore(deleteItems: {id: string}[]): void {
    this.store.dispatch(
      actions.removeLibraryItemFromStore({
        payload: {
          libraryItemsIds: deleteItems.map((item: {id: string}) => item.id)
        }
      })
    );
  }

  public getAvailableModels(libraryItemId: LibraryItem['id'], location?: LibraryItem['location']): void {
    this.store.dispatch(actions.getAvailableModels({libraryItemId, location}));
  }

  public clearAvailableModels(): void {
    this.store.dispatch(actions.clearAvailableModels());
  }

  public getImageMetadata(libraryItem: LibraryItem): void {
    this.store.dispatch(
      actions.downloadLibraryItemMetadata({
        payload: {libraryItem}
      })
    );
  }

  public saveImageMetadata(libraryItemId: LibraryItem['id'], metadata: LibraryItem['metadata']): void {
    this.store.dispatch(
      actions.saveLibraryItemMetadata({
        payload: {libraryItemId, metadata}
      })
    );
  }

  public updateIsLoadingMetadata(): void {
    this.store.dispatch(actions.updateIsLoadingMetadata());
  }

  public selectIsLoadingMetadata(): Observable<boolean> {
    return this.store.pipe(select(selectors.selectMetadataIsLoading));
  }

  public openSelectedModel({
    modelMimeType,
    libraryItemId
  }: {
    modelMimeType: ModelData['mimeType'];
    libraryItemId: LibraryItem['id'];
  }): void {
    this.store.dispatch(actions.openSelectedModel({modelMimeType, libraryItemId}));
  }

  public selectCurrentParentId(): Observable<string> {
    return this.store.pipe(select(selectors.selectCurrentParentId));
  }

  public getModelData(libraryItemId: LibraryItem['id']): void {
    this.store.dispatch(actions.getModelData({libraryItemId}));
  }

  public openFlightLogDialog(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.openFlightLogDialog({payload: {libraryItem}}));
  }

  public openNoImagesAvailableDialog(libraryItemId: LibraryItem['id']): void {
    this.store.dispatch(actions.openNoImagesAvailableDialog({payload: {libraryItemId}}));
  }

  public loadRequirementsForModelPickerDialog(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.loadRequirementsForModelPickerDialog({payload: {libraryItem}}));
  }

  public loadOneImageFromFolder(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.loadOneImageFromFolder({payload: {libraryItem}}));
  }

  public loadOneImageFromFolderSuccess(libraryItemId: LibraryItem['id']): void {
    this.store.dispatch(actions.loadOneImageFromFolderSuccess({payload: {libraryItemId}}));
  }

  public loadOneImageFromFolderFail(error: string): void {
    this.store.dispatch(actions.loadOneImageFromFolderFail({payload: {error}}));
  }

  public openModelPickerDialogFromImage(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.openModelPickerDialogFromImage({payload: {libraryItem}}));
  }

  public loadAnnotationsFromLibraryItem(libraryItemId: LibraryItem['id']): void {
    this.store.dispatch(actions.loadAnnotationsFromLibraryItem({payload: {libraryItemId}}));
  }

  public loadAnnotationsFromLibraryItemSuccess(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.loadAnnotationsFromLibraryItemSuccess({payload: {libraryItem}}));
  }

  public loadAnnotationsFromLibraryItemFail(error: string): void {
    this.store.dispatch(actions.loadAnnotationsFromLibraryItemFail({payload: {error}}));
  }

  public loadLibraryItemGPSMetadataSilently(libraryItemId: string): void {
    this.store.dispatch(actions.loadLibraryItemGPSMetadataSilently({payload: {libraryItemId}}));
  }

  public loadLibraryItemsGPSMetadataSilently(libraryItemIds: string[]): void {
    this.store.dispatch(actions.loadLibraryItemsGPSMetadataSilently({payload: {libraryItemIds}}));
  }

  public loadLibraryItemGPSMetadataSilentlySuccess(
    libraryItemId: string,
    metadata: {gpslat: number; gpslng: number}
  ): void {
    this.store.dispatch(
      actions.loadLibraryItemGPSMetadataSilentlySuccess({
        payload: {metadata, libraryItemId}
      })
    );
  }

  public loadLibraryItemGPSMetadataSilentlyFail(error: string): void {
    this.store.dispatch(
      actions.loadLibraryItemGPSMetadataSilentlyFail({
        payload: {error}
      })
    );
  }

  public saveCurrentParentId(parentId: string): void {
    this.store.dispatch(actions.saveCurrentParentId({payload: {currentParentId: parentId}}));
  }

  public scrollUp(): void {
    this.store.dispatch(actions.scrollUp());
  }

  public hasAnalyzeOption(): void {
    this.store.dispatch(actions.checkIfHasAnalyzeOption());
  }

  public hasAnalyzeOptionSuccess(libraryItems: LibraryItem[]): void {
    this.store.dispatch(actions.hasAnalyzeOptionSuccess({payload: {libraryItems}}));
  }

  public hasAnalyzeOptionFail(error: string): void {
    this.store.dispatch(actions.hasAnalyzeOptionFail({error}));
  }

  public exportCocoJson(libraryItem?: LibraryItem): void {
    this.store.dispatch(actions.exportCocoJson({libraryItem}));
  }

  public exportCsv(libraryItem?: LibraryItem): void {
    this.store.dispatch(actions.exportCsv({libraryItem}));
  }

  public exportCsvAnnotationsSummary(addonId: string, teamId: string): void {
    this.store.dispatch(annotationsSummaryActions.exportCsvAnnotationsSummary({addonId, teamId}));
  }

  public exportCocoJsonAnnotationsSummary(addonId: string, teamId: string): void {
    this.store.dispatch(annotationsSummaryActions.exportCocoJsonAnnotationsSummary({addonId, teamId}));
  }

  public exportToAtlasAnnotationsSummary(addonId: string, teamId: string): void {
    this.store.dispatch(annotationsSummaryActions.exportToAtlasAnnotationsSummary({addonId, teamId}));
  }

  public exportPdf(addonId: Addon['id'], path: string[], hasReportConfig: boolean, libraryItem?: LibraryItem): void {
    this.store.dispatch(annotationsSummaryActions.exportPdf({addonId, path, libraryItem, hasReportConfig}));
  }

  public requestAiVideo(libraryItem: LibraryItem): void {
    this.store.dispatch(actions.requestAiVideo({payload: {libraryItem}}));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public requestAiVideoSuccess(libraryItems: Partial<LibraryItem>[]): void {
    this.store.dispatch(actions.requestAiVideoSuccess({payload: {libraryItems}}));
  }

  public switchVideo(id: string, aiVideo: LibraryItem): void {
    this.store.dispatch(actions.switchVideo({payload: {libraryItemId: id, aiVideo}}));
  }

  public getLastNotification(): Observable<NotificationModel> {
    return this.store.pipe(select(selectNewNotification));
  }

  public addToAtlas(): void {
    this.store.dispatch(actions.addToAtlas());
  }

  public openAnalyze(): void {
    this.store.dispatch(analyzeActions.openAnalyze());
  }

  public openAnalyzeDialog(libraryItem: LibraryItem): void {
    this.store.dispatch(analyzeActions.openAnalyzeDialog({payload: {libraryItem}}));
  }

  public loadLibraryTags(): void {
    this.store.dispatch(actions.loadLibraryTags());
  }

  public updateLibraryItemTag(tag: Tag, libraryItem: LibraryItem, libraryLayout: LibraryLayout): void {
    this.store.dispatch(actions.updateLibraryItemTag({tag, libraryItem, libraryLayout}));
  }

  public showLibraryItemTags(libraryItemId: string): void {
    this.store.dispatch(actions.showLibraryItemTags({payload: {libraryItemId}}));
  }

  public clearLibraryItemTags(): void {
    this.store.dispatch(actions.clearLibraryItemTags());
  }

  public selectTagWithId(tagId: string): Observable<Tag> {
    return this.store.select(selectors.selectTagWithId(tagId));
  }

  public openAnnotationsSummary(libraryItem: LibraryItem): void {
    this.store.dispatch(annotationsSummaryActions.openAnnotationsSummary({payload: {libraryItem}}));
  }

  public openAnalyzeDialogClosed(): void {
    this.store.dispatch(actions.openAnalyzeDialogClosed());
  }

  public getAnnotationsSummaryData(libraryItemIdArr: string[]): void {
    this.store.dispatch(annotationsSummaryActions.getAnnotationsSummaryData({libraryItemIdArr}));
  }

  public setCurrentAddonCategories(addonId: Addon['id']): void {
    this.store.dispatch(annotationsSummaryActions.setCurrentAddonCategories({addonId}));
  }

  public updateCheckedAddonCategory(categoryIndex: number, hasToSelectAll?: boolean): void {
    this.store.dispatch(annotationsSummaryActions.updateCheckedAddonCategory({categoryIndex, hasToSelectAll}));
  }

  public saveCurrentAddonCategories(categories: AddonCategoriesFilters[]): void {
    this.store.dispatch(annotationsSummaryActions.saveCurrentAddonCategories({categories}));
  }

  public clearAnnotationsSummaryData(): void {
    this.store.dispatch(annotationsSummaryActions.clearAnnotationsSummaryData());
  }

  public loadAnnotationsFromLibraryItemFromViewerSuccess(libraryItem: LibraryItem): void {
    this.store.dispatch(
      actions.loadAnnotationsFromLibraryItemFromViewerSuccess({
        payload: {libraryItem}
      })
    );
  }

  public selectAnnotations(libraryItemId: string): Observable<AnnotationsModel> {
    return this.store.pipe(select(selectors.selectAnnotations(libraryItemId)));
  }

  public clearListView(): void {
    this.store.dispatch(actions.clearListView());
  }

  public startOpenAnnotationSummaryOneItem(libraryItem: LibraryItem): void {
    this.store.dispatch(
      annotationsSummaryActions.startOpenAnnotationSummaryOneItem({
        payload: {libraryItem}
      })
    );
  }

  public getAnnotationsForLibraryItems(libraryItems: LibraryItem[]): void {
    this.store.dispatch(
      annotationsSummaryActions.getAnnotationsForLibraryItems({
        payload: {libraryItems}
      })
    );
  }

  public getAnnotationsForLibraryItemSuccess(libraryItems: LibraryItem[]): void {
    this.store.dispatch(
      annotationsSummaryActions.getAnnotationsForLibraryItemSuccess({
        payload: {libraryItems}
      })
    );
  }

  public getAnnotationsForLibraryItemFail(error: string): void {
    this.store.dispatch(
      annotationsSummaryActions.getAnnotationsForLibraryItemFail({
        payload: {error}
      })
    );
  }

  public inferAddonFromLibraryItemAnnotation(libraryItem: LibraryItem): void {
    this.store.dispatch(
      annotationsSummaryActions.inferAddonFromLibraryItemAnnotation({
        payload: {libraryItem}
      })
    );
  }

  public startOpenAnnotationsSummaryMultipleItems(): void {
    this.store.dispatch(annotationsSummaryActions.startOpenAnnotationsSummaryMultipleItems());
  }

  public startOpenAnnotationSummarySelectedItems(): void {
    this.store.dispatch(annotationsSummaryActions.startOpenAnnotationSummarySelectedItems());
  }

  public listenPreviousUrl(): void {
    this.store.dispatch(actions.listenPreviousUrl());
  }

  public navigateToPreviousUrl(redirectedFrom?: string, isPositiveAction?: boolean): void {
    this.store.dispatch(actions.navigateToPreviousUrl({payload: {redirectedFrom: redirectedFrom, isPositiveAction}}));
  }

  public saveFilterParams(payload: {libraryFilters: Partial<LibraryFilter>; libraryItemId: LibraryItem['id']}): void {
    this.store.dispatch(actions.saveFilterParams({payload}));
  }

  public saveFilterParamsSuccess(payload: {libraryFilters: Partial<LibraryFilter>; libraryItemId: LibraryItem['id']}) {
    this.store.dispatch(actions.saveFilterParamsSuccess({payload}));
  }

  public clearLibraryFilters(): void {
    this.store.dispatch(actions.clearLibraryFilters());
  }

  public clearOtherLibraryFilters(): void {
    this.store.dispatch(actions.clearOtherLibraryFilters());
  }

  public getModelDataSuccess(modelData: ModelData): void {
    this.store.dispatch(actions.getModelDataSuccess({modelData}));
  }

  public compareImages(): void {
    this.store.dispatch(actions.compareImages());
  }

  public getAnnotationsSummaryDataSuccess(annotations: AnnotationsSummaryData[]): void {
    this.store.dispatch(annotationsSummaryActions.getAnnotationsSummaryDataSuccess({annotations}));
  }

  public toggleFilters(hasToSelectAll: boolean): void {
    this.store.dispatch(annotationsSummaryActions.toggleFilters({hasToSelectAll}));
  }

  public addSeverityLevel(level: number, categoryIndex: number): void {
    this.store.dispatch(annotationsSummaryActions.addSeverityLevel({level, categoryIndex}));
  }

  public removeSeverityLevel(level: number, categoryIndex: number): void {
    this.store.dispatch(annotationsSummaryActions.removeSeverityLevel({level, categoryIndex}));
  }

  public addAllSeverityLevel(level: number): void {
    this.store.dispatch(annotationsSummaryActions.addAllSeverityLevel({level}));
  }

  public removeAllSeverityLevel(level: number): void {
    this.store.dispatch(annotationsSummaryActions.removeAllSeverityLevel({level}));
  }

  public updateActiveAnnotationsSummaryData(addonId: string): void {
    this.store.dispatch(annotationsSummaryActions.updateActiveAnnotationsSummaryData({addonId}));
  }

  public saveActiveAnnotationsSummaryData(annotations: AnnotationsSummaryData[]): void {
    this.store.dispatch(annotationsSummaryActions.saveActiveAnnotationsSummaryData({annotations}));
  }

  public resetAllFilters(): void {
    this.store.dispatch(annotationsSummaryActions.resetAllFilters());
  }

  public resetCurrentAddonCategories(): void {
    this.store.dispatch(annotationsSummaryActions.resetCurrentAddonCategories());
  }

  public fileCount(selectedItems: {id: LibraryItem['id']}[]): void {
    this.store.dispatch(actions.fileCount({payload: {libraryItems: selectedItems}}));
  }

  public updateCurrentActiveTags(tag: Tag): void {
    this.store.dispatch(annotationsSummaryActions.updateCurrentActiveTags({tag}));
  }

  public updateSeverityHeaderButtons(level: number): void {
    this.store.dispatch(annotationsSummaryActions.updateSeverityHeaderButtons({level}));
  }

  public verifyActiveHeaderButtons(levels: number[], checkedCategories: AddonCategoriesFilters[]): void {
    this.store.dispatch(annotationsSummaryActions.verifyActiveHeaderButtons({levels, checkedCategories}));
  }

  public toggleSeverityHeaderButtons(level: number, isActive: boolean): void {
    this.store.dispatch(annotationsSummaryActions.toggleSeverityHeaderButtons({level, isActive}));
  }

  public enableActiveTag(tag: Tag): void {
    this.store.dispatch(annotationsSummaryActions.enableActiveTag({tag}));
  }

  public handleAnnotationAndSeverityChips(totalAnnotationLabels: number): void {
    this.store.dispatch(annotationsSummaryActions.handleAnnotationAndSeverityChips({totalAnnotationLabels}));
  }

  public handleTagsChips(tags: Tag[]): void {
    this.store.dispatch(annotationsSummaryActions.handleTagsChips({tags}));
  }

  public resetAnnotationsSummaryChips(): void {
    this.store.dispatch(annotationsSummaryActions.resetAnnotationsSummaryChips());
  }

  public enableTagsChips(tag: Tag): void {
    this.store.dispatch(annotationsSummaryActions.enableTagsChips({tag}));
  }

  public saveActiveTags(tags: Tag[]): void {
    this.store.dispatch(annotationsSummaryActions.saveActiveTags({tags}));
  }

  public disableAllHeaderSeverityButtonsState(): void {
    this.store.dispatch(annotationsSummaryActions.disableAllHeaderSeverityButtonsState());
  }

  public setHasInitialAnnotationsSummaryData(hasSummaryData: boolean): void {
    this.store.dispatch(annotationsSummaryActions.setHasInitialAnnotationsSummaryData({hasSummaryData}));
  }

  public setLibraryItemFromContextMenu(libraryItemId: string): void {
    this.store.dispatch(actions.setLibraryItemFromContextMenu({payload: {libraryItemId}}));
  }

  public clearLibraryIteamContextMenu(): void {
    this.store.dispatch(actions.clearLibraryIteamContextMenu());
  }

  public clearLibraryIteamContextMenuSuccess(): void {
    this.store.dispatch(actions.clearLibraryIteamContextMenuSuccess());
  }

  public exportModel(payload: {id: LibraryItem['id']; type: MenuActions}) {
    this.store.dispatch(actions.exportModels({payload: payload}));
  }

  public setListViewState(stateToBack: Partial<LibraryState>) {
    this.store.dispatch(actions.setListViewState({payload: stateToBack}));
  }

  public clearCurrentPositionStore(): void {
    this.store.dispatch(actions.clearCurrentPositionStore());
  }

  public loadSpecialLibraryItemsSuccess(payload: {[x: string]: {}}) {
    return this.store.dispatch(actions.loadSpecialLibraryItemsSuccess({payload}));
  }

  public saveLibraryItems(payload: {
    libraryItems: {};
    nextToken: NextToken;
    specialLibraryItems?: LibraryItem[];
  }): void {
    return this.store.dispatch(actions.saveLibraryItems({payload}));
  }

  public assingToAssets(libraryItem: LibraryItem) {
    this.store.dispatch(assignToAssetsActions.openAssingToAssetsDialog({payload: {libraryItem}}));
  }

  public downloadAnalyticsMenuItems(libraryItem: LibraryItem): void {
    this.store.dispatch(downloadAnalyticsActions.downloadAnalyticsMenuItems({payload: {libraryItem}}));
  }

  public exportAnalytics(path: string) {
    this.store.dispatch(downloadAnalyticsActions.exportAnalytics({payload: {path}}));
  }

  public changeViewMode(viewMode: LibraryViewMode) {
    this.store.dispatch(actions.changeViewMode({payload: {viewMode}}));
  }

  public downloadSingleLibraryItem(libraryItem: LibraryItem, messages: {downloadStart: string; downloadError: string}) {
    this.store.dispatch(actions.downloadSingleLibraryItem({payload: {libraryItem, messages}}));
  }
}

<section class="header" role="banner">
  <div class="header__go-back">
    <mat-icon (click)="emitGoBack()">arrow_back</mat-icon>
  </div>
  <div class="header__content" role="heading">
    <div class="header__title">{{ title }}</div>
    <div class="header__info">
      <ng-container *ngFor="let item of items; let i = index">
        {{ item }}
        <div *ngIf="i < items.length - 1" class="header__item-divider" role="separator"></div>
      </ng-container>
    </div>
  </div>
</section>

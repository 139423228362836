import {Injectable} from '@angular/core';
import {ApiGateway} from '@app/core/services/api/api-gateway.service';
import {Job, Task, TaskContext, TaskType} from '../models/jobs.models';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import {ExportTaskPayload} from '@app/jobs/models/export-task.model';

@Injectable({
  providedIn: 'root'
})
export class JobsApiService {
  constructor(private apiGateway: ApiGateway, private http: HttpClient) {}
  // eslint-disable-next-line rxjs/finnish
  public getJobs(nextToken: string): Observable<{items: Job[]; nextToken: string}> {
    return this.apiGateway.get(`job/team`, {nextToken, limit: 20});
  }

  public createJob(payload) {
    return this.apiGateway.post(`job`, null, payload);
  }

  // eslint-disable-next-line rxjs/finnish
  public getJob(jobId: string): Observable<any> {
    return this.apiGateway.get(`job/${jobId}`, {});
  }

  // eslint-disable-next-line rxjs/finnish
  public getTask(jobId: string): Observable<any> {
    return this.apiGateway.get(`task/job/${jobId}`, {});
  }

  // eslint-disable-next-line rxjs/finnish
  public getTeamUsers(): Observable<any> {
    return this.apiGateway.get(`organization/team/users`, {});
  }

  // eslint-disable-next-line rxjs/finnish
  public assignAllTasksToMe(jobId: string, assignedId: string): Observable<any> {
    return this.apiGateway.patch(`/job/${jobId}/task`, null, {assignedId});
  }

  // eslint-disable-next-line rxjs/finnish
  public updateJob(jobId: string, payload: Partial<Job>): Observable<any> {
    return this.apiGateway.patch(`job/${jobId}`, null, {...payload});
  }

  // eslint-disable-next-line rxjs/finnish
  public updateTask(taskId: string, payload: Partial<Task>): Observable<any> {
    return this.apiGateway.patch(`task/${taskId}`, null, {...payload});
  }

  // eslint-disable-next-line rxjs/finnish
  public getJobTasks(nextToken: string, jobId: string): Observable<any> {
    return this.apiGateway.get(`task/job/${jobId}`, {nextToken, limit: 20});
  }

  public createJobTask(payload: {
    type: TaskType;
    title: string;
    description: string;
    context: TaskContext;
    jobId: string;
    assignedId: string;
  }) {
    return this.apiGateway.post(`task`, null, payload);
  }

  // eslint-disable-next-line rxjs/finnish
  public deleteJob(jobId: string): Observable<any> {
    return this.apiGateway.delete(`job/${jobId}`, {});
  }

  // eslint-disable-next-line rxjs/finnish
  public deleteTask(taskId: string): Observable<any> {
    return this.apiGateway.delete(`task/${taskId}`, {});
  }

  public createFlightPathTask(payload: {
    context: any;
    name: string;
    route: {lat: number; lng: number}[];
    jobId: string;
    assignedId: string;
    type: TaskType;
  }) {
    return this.apiGateway.post(`task/mission`, null, payload);
  }

  // eslint-disable-next-line rxjs/finnish
  public getS3File(key: string): Observable<any> {
    return this.http.get([environment.jobCDNDomain, key].join('/'));
  }

  exportTask(payload: ExportTaskPayload) {
    return this.apiGateway.post(`tasks/export`, null, payload);
  }
}

export interface Team {
  name: string;
  createdAt: number;
  updatedAt: number;
  companyId: string;
  slug: string;
  sk: string;
  id: string;
  pk: string;
  ownerId: string;
  isPublicWatchPageEnabled: boolean;
  members: number;
  role?: TeamRole;
  dashboards?: any;
  companyName?: string;
}

export enum TeamRole {
  contributor = 'default:contributor',
  admin = 'default:admin',
  viewer = 'default:viewer'
}

export interface TeamTable {
  teamId: string;
  name: string;
  members: number;
  lastActive: number;
  createdAt?: number;
}

export interface TeamInvitation {
  teamId: Team;
  role: TeamRole;
}

export enum TeamActions {
  delete = 'delete',
  edit = 'edit',
  deleteUserFromTeam = 'delete-from-team'
}

export interface TeamAction {
  action: TeamActions;
  teamId: string;
}

export interface TeamTableActions {
  name: string;
  type: TeamActions;
}

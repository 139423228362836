import {Directive, HostListener, Input} from '@angular/core';
import {MissionManagerService} from '../services/mission-manager.service';
import {Mission} from '../services/mission-settings.service';
import {MissionExportType} from '@app/atlas/model/mission-geojson.model';

@Directive({
  selector: '[ulExportMission]'
})
export class ExportMissionDirective {
  @Input('ulExportMission') public mission: Mission;
  @Input() public exportType: MissionExportType;
  @Input() public customName: string;

  @HostListener('click', ['$event'])
  public onExport(event: Event) {
    switch (this.exportType) {
      case MissionExportType.JSON:
        this.missionManagerService.exportMissionJSON(this.mission);
        break;
      case MissionExportType.GEOJSON:
        this.missionManagerService.exportMissionGeoJSON(this.mission, this.customName);
        break;
      default:
        console.warn(`Unknown export type: ${this.exportType}`);
    }
  }
  constructor(private missionManagerService: MissionManagerService) {}
}

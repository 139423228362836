import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {EVENTS} from '@app/core/services/unleash-analytics.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, Subscription} from 'rxjs';
import {LocalStorageLayer} from './layers-control-item.models';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {taskLayers} from '@app/atlas/atlas.config';
import {AtlasHeaderStatus} from '@app/atlas/services/atlas-header.service';
import {Map} from 'leaflet';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-layers-control-item',
  templateUrl: './layers-control-item.component.html',
  styleUrls: ['./layers-control-item.component.scss'],
  providers: [{provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: 'noop'}],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayersControlItemComponent implements OnInit {
  public events = EVENTS;
  // eslint-disable-next-line rxjs/finnish
  public asset$ = new BehaviorSubject<AtlasAssetModel>(null);
  public layerSavedInLocalStorage: LocalStorageLayer[] = [];
  public assetType = AssetType;
  public hasToRemoveAssetsHiglight = this.atlasService.hasToRemoveAssetsHiglight;

  @Input('asset')
  public set setupAsset(asset: AtlasAssetModel) {
    if (asset) {
      if (asset.groupName === taskLayers) {
        asset.isDisplaying = false;
      }
      this.asset$.next(asset);
    }
  }
  @Input('hasToDetectChanges')
  public set hasToDetectChanges(hasToDetectChanges: boolean) {
    if (hasToDetectChanges !== undefined) {
      this.cd.detectChanges();
    }
  }
  @Input('hasToDetectChangesSidebar')
  public set hasToDetectChangesSidebar(hasToDetectChanges: boolean) {
    if (hasToDetectChanges !== null) {
      this.cd.detectChanges();
    }
  }
  @Input() public hasToBlockMenu: boolean = false;
  @Input() public isGpsDevice: boolean = false;
  @Input() public isListOnly: boolean = false;
  @Input() public map: Map;
  @Input() public headerStatus: AtlasHeaderStatus;

  @Output() public refreshView: EventEmitter<void> = new EventEmitter();
  @Output() public openLayerControlItemMenu: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public openSearchResults: EventEmitter<any> = new EventEmitter();

  public aclPermissions = AclPermissions;
  public atlasHeaderStatus = AtlasHeaderStatus;

  private hasLayerGroupChangedSub: Subscription;

  constructor(public atlasService: AtlasService, private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.hasLayerGroupChangedSub = this.atlasService.hasLayerGroupChanged$.subscribe(() => {
      this.cd.detectChanges();
    });
  }

  public toggleSelect(asset: AtlasAssetModel) {
    asset.isSelected = !asset.isSelected;

    if (asset.isSelected) {
      this.atlasService.addSelectedLayer(asset);
      return;
    }

    this.atlasService.removeSelectedLayer(asset.id);
  }

  public emitOpenLayerControlItemMenu(event: MouseEvent): void {
    this.openLayerControlItemMenu.emit(event);
  }

  public emitOpenSearchResults(asset: any): void {
    this.openSearchResults.emit(asset);
  }

  public hasToHideLayer(asset): boolean {
    return !(
      asset.isDisplaying ||
      this.isGpsDevice ||
      this.headerStatus === this.atlasHeaderStatus.SELECT_MARKERS ||
      this.headerStatus === this.atlasHeaderStatus.EDIT_JOB_SHAPE
    );
  }
}

import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {CompanyModel} from '@app/core/models/api/company-model';
import {StreamPlayer, UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {UserModel} from '@app/core/models/api/user-model';
import {DeviceService} from '@app/core/services/api/device.service';
import {UserService} from '@app/core/services/api/user.service';
import {WebrtcService} from '@app/core/services/api/webrtc.service';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {LibraryApiService} from '@app/library/services/library-api.service';

import {environment} from 'environments/environment';
import {EMPTY, Observable, interval, combineLatest, of, zip} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, pairwise, switchMap, take, tap} from 'rxjs/operators';
import {StreamingErrors} from '../models/streaming-errors.models';
import {AgoraService} from '../services/agora.service';
import {LiveFacadeService} from '../services/live-facade.service';
import {Addon} from '@app/store/addon/models/addon';
import {
  NotificationModel,
  NotificationSource,
  NotificationSourceType,
  NotificationState
} from '@app/core/models/api/notifications.model';
import {StatusService} from '@app/core/services/api/status.service';
import {Stream} from '@app/core/models/api/stream.model';
import {ContinueWatchingComponent} from '../components/continue-watching/continue-watching.component';
import {actionNewNotification, actionUpdateNotification} from '@app/core/notifications/notifications.actions';
import * as actions from '../store/live.actions';
import {PayloadAction} from '@app/shared/models/payload-action';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {PlansService} from '@app/plans/services/plans.service';
import {LiveStreamPageService} from '../pages/live-stream-page/live-stream-page.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DeviceDialog} from '@app/profile/components/device-dialog/device-dialog.component';
import {AdditionalDeviceDialogComponent} from '@app/profile/components/device/additional-device-dialog/additional-device-dialog.component';

@Injectable()
export class LiveEffects {
  private selectedModel: Addon = {} as Addon;
  private modelId: string = null;
  private deviceId: string = null;
  private rawStream: Addon = {
    id: 'RAW_STREAM',
    name: 'Raw stream',
    subtitle: 'No AI activated',
    description: 'Watch your raw live stream without any A.I. analysis.',
    developer: '',
    price: 0,
    logo: '',
    owner: '',
    type: null,
    screenshots: [],
    enabled: true
  };

  private listenStreamsNotificaitons$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actionUpdateNotification, actionNewNotification),
        filter(({payload}: {payload: NotificationModel}) => {
          return (
            (!!payload &&
              payload?.source === NotificationSource.stream &&
              (payload?.sourceType === NotificationSourceType.webrtcraw ||
                payload?.sourceType === NotificationSourceType.raw ||
                payload?.sourceType === NotificationSourceType.ai)) ||
            payload.title === 'Live AI processing failed'
          );
        }),
        tap(({payload}: {payload: NotificationModel}) => {
          if (payload.title === 'Live AI processing failed') {
            // this will help to stop analysis spinner, the source is from cortex
            this.userStoreFacadeService
              .getDevice(payload.deviceId)
              .pipe(take(1))
              .subscribe((device: UserDeviceJoined) => {
                const waitingModels = device.waitingModels.filter(modelId => modelId !== payload.data.modelId);
                this.userStoreFacadeService.updateDeviceCache({...device, waitingModels});
              });
            return;
          }

          //TODO Verify notification model id response
          switch (payload.state) {
            case NotificationState.RUNNING: {
              /// IMPORTANT TODO REVIEW
              const newStream = {
                deviceId: payload.deviceId,
                player: payload?.sourceType === NotificationSourceType.raw ? StreamPlayer.DEFAULT : StreamPlayer.WEBRTC,
                models: [],
                type: 'RAW'
              } as Stream;
              this.updateAfterStop(payload.deviceId);
              this.statusService.addStream(newStream);
              if (payload.sourceType === NotificationSourceType.ai) {
                this.userStoreFacadeService.setCurrentModel(
                  payload.deviceId,
                  payload.data.modelId || this.rawStream.id
                );
                return;
              }
              break;
            }
            case NotificationState.CANCEL:
            case NotificationState.ERROR:
            case NotificationState.FINISH: {
              break;
            }
          }
        })
      ),
    {dispatch: false}
  );

  private showUrlCopied$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] translate copy url event'),
        switchMap(() => this.translateService.get('profile.streaming.urlCopied')),
        tap((res: string) => {
          this.snackBar.open(res, 'OK', {duration: 5000} as MatSnackBarConfig);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private enablePublicLivePage$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] enable public live page'),
        tap(() => this.liveFacadeService.isEnablingLivePage()),
        tap((params: {companyId: string; shared: Partial<CompanyModel>}) =>
          this.userService.updateCompany(params.companyId, params.shared)
        ),
        tap(() => this.liveFacadeService.isEnablingLivePage()),
        switchMap((params: {companyId: string; shared: Partial<CompanyModel>}) =>
          this.translateService.get(`profile.streaming.shareBroadcastToast.${params.shared ? '0' : '1'}`)
        ),
        tap((res: string) => this.snackBar.open(res, 'OK', {duration: 5000} as MatSnackBarConfig)),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private generateCompanySlug$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] generate company slug'),
        tap((params: {companyId: string; companySlug: string}) => {
          this.liveFacadeService.isLoadingSlug();
          const slug = this.userService.generateCompanySlug(params.companySlug);
          this.userService.updateCompany(params.companyId, {slug: slug});
        }),
        tap(() => this.liveFacadeService.isLoadingSlug()),
        switchMap(() => this.translateService.get(`profile.streaming.urlGenerated`)),
        tap((res: string) => this.snackBar.open(res, 'OK', {duration: 5000} as MatSnackBarConfig)),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private supportIOSErrorMessage$: Observable<{[key: string]: string}> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] supporting IOS error'),
        switchMap(() => this.translateService.get('stream-webrtc')),
        tap((res: {[key: string]: string}) => {
          const {featureNotSupportedSystemRequirementsMessage} = res;
          this.liveFacadeService.updateErrorMessage(featureNotSupportedSystemRequirementsMessage);
          this.liveFacadeService.updateErrorCode(StreamingErrors.IOS_ERROR);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private verifyDevicesAndJoinChannel$: Observable<[string, UserDeviceJoined]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] verify devices and join channel'),
        switchMap((params: {deviceId: string}) =>
          zip(
            of(params.deviceId),
            this.userService.userDevices$.pipe(
              take(1),
              map(devices => devices.find(device => device.id === params.deviceId))
            )
          )
        ),
        tap(([deviceId, device]: [string, UserDeviceJoined]) => {
          this.liveFacadeService.updateDevice(device);
          this.agoraService.waitForUserAndJoinChannel(deviceId);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private manuallyPermissionErrorMessage$: Observable<{[key: string]: string}> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] manually permission error message'),
        switchMap(() => this.translateService.get('stream-webrtc')),
        tap((res: {[key: string]: string}) => {
          const {cameraIsOffMessage} = res;
          this.liveFacadeService.updateErrorMessage(cameraIsOffMessage);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private getStreamKey$: Observable<UserModel> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] get user stream key'),
        switchMap(() => this.userService.user$),
        tap((user: UserModel) => {
          this.agoraService.streamKey = user.id;
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private getAllDevices$: Observable<UserDeviceJoined[]> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] get all user devices'),
        switchMap(() => this.userService.userDevices$),
        tap((devices: UserDeviceJoined[]) => {
          this.agoraService.devices = devices;
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private updateDevice$: Observable<{device: UserDeviceJoined}> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] update device params'),
        tap((deviceData: {device: UserDeviceJoined}) => {
          this.agoraService.streamingDevice$.next(deviceData.device);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private getAgoraToken$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] get agora token'),
        switchMap((params: {deviceId: string}) => this.webrtcService.getWebRTCSubscriberToken(params.deviceId)),
        tap((token: string) => {
          this.agoraService.agoraToken$.next(token);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private startLiveAi$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] start live ai'),
        tap((params: {device: UserDeviceJoined; model: Addon; streamKey: string}) => {
          this.selectedModel = params.model;
        }),
        switchMap((params: {device: UserDeviceJoined; model: Addon; streamKey: string}) =>
          this.libraryApiService.startLiveAi(params.device, params.model.id, params.streamKey)
        ),
        tap(() => {
          this.agoraService.addRunningModel(this.selectedModel);
          this.agoraService.removeWaitingModel(this.selectedModel.id);
          this.snackBar.open(
            'Analyzing live with ' + this.selectedModel.name + ' This will take about 1 minute to begin...',
            '',
            {
              duration: 6000,
              verticalPosition: 'bottom',
              horizontalPosition: 'start',
              panelClass: 'streaming-snackbar'
            } as MatSnackBarConfig
          );
          this.unleashAnalytics.logEvent(EVENTS.STREAM_PLAYER_AI, {model: this.selectedModel});
        }),
        catchError((err: {error: {message: string}}) => {
          console.error('Error while requesting AI model', err);
          if (!!err && !!err?.error && !!err?.error.message) {
            this.snackBar.open(err.error.message, null, {
              duration: 2000
            } as MatSnackBarConfig);
          }
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  private stopLiveAi$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] stop live ai'),
        tap((params: {deviceId: string; modelId: string}) => {
          this.modelId = params.modelId;
        }),
        switchMap((params: {deviceId: string; modelId: string}) =>
          this.deviceService.stopAi(params.deviceId, params.modelId)
        ),
        tap(() => {
          this.snackBar.open('AI Stopped', null, {
            duration: 3000,
            verticalPosition: 'bottom'
          } as MatSnackBarConfig);
          if (this.agoraService.streamingDevice$.value.runningModels.length > 0) {
            this.agoraService.removeRunningModel(this.modelId);
          }
          this.agoraService.setCurrentModel(this.agoraService.streamingDevice$.value, {...this.rawStream});
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private getCompanyInfo$: Observable<CompanyModel> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] get company info'),
        switchMap(() => this.userService.myCompany$),
        tap((company: CompanyModel) => {
          this.agoraService.isShared$.next(company.isPublicWatchPageEnabled);
          this.agoraService.companyId$.next(company.id);
          this.agoraService.companySlug$.next(company.slug);
          this.agoraService.publicWatchPageURL$.next(this.agoraService.getWatchPageLink(company.slug));
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private waitForUserAndJoinChannel$: Observable<UserModel> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] wait for user and channel to join channel'),
        tap((params: {deviceId: string}) => {
          this.deviceId = params.deviceId;
        }),
        switchMap(() => this.userService.user$),
        tap((user: UserModel) => {
          if (!user) {
            console.error('Empty user object');
            throw new Error('User not found. Please try again.');
          }
          this.agoraService.streamUrl = this.agoraService.getStreamUrl(this.deviceId, user.streamKey);
          this.agoraService.joinChannel();
          this.agoraService.isLoadingCompanyInfo$.next(false);
        }),
        catchError((err: string | {[key: string]: string}) => {
          console.error('Could not get user ' + err);
          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  private askPermissionErrorMessage$: Observable<{[key: string]: string}> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] ask permission error message'),
        switchMap(() => this.translateService.get('stream-webrtc')),
        tap((res: {[key: string]: string}) => {
          const {cameraPermissionMessage} = res;
          this.liveFacadeService.updateErrorMessage(cameraPermissionMessage);
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private joinChannel$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] join channel'),
        switchMap(() => this.webrtcService.getWebRTCPublisherToken(this.deviceId)),
        tap(async (token: string) => {
          this.agoraService.client.on('is-using-cloud-proxy', (isUsingCloudProxy: boolean) => {
            console.info('Is using cloud proxy', isUsingCloudProxy);
          });
          this.agoraService.client.on('token-privilege-did-expire', () => {
            this.liveFacadeService.renewToken();
          });
          this.agoraService.client.on('token-privilege-will-expire', () => {
            this.liveFacadeService.renewToken();
          });
          //TODO review options to avoid multiple streams with the same device id
          // this.agoraService.client.on('user-published', () => {
          //   this.agoraService.stopStream();
          //   this.liveFacadeService.updateErrorMessage('This device is already streaming');
          // });
          this.agoraService.client.on('user-unpublished', () => {
            this.liveFacadeService.updateErrorMessage(null);
          });
          try {
            this.agoraService.uid = await this.agoraService.client.join(
              environment.PWA_STREAMING_APP_ID,
              this.deviceId,
              token,
              null
            );
            this.agoraService.isConnected$.next(true);
          } catch (e) {
            console.error('join failed', e);
            this.liveFacadeService.updateErrorMessage(e);
            this.liveFacadeService.updateErrorCode(StreamingErrors.JOIN_FAILED);
          }
        }),
        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  private renewToken$: Observable<string> = createEffect(
    () =>
      this.actions$.pipe(
        ofType('[Streams] renew token'),
        switchMap(() => this.webrtcService.getWebRTCPublisherToken(this.deviceId)),
        tap((newToken: string) => {
          this.agoraService.client.renewToken(newToken);
        }),

        catchError(() => EMPTY)
      ),
    {dispatch: false}
  );

  public watchForStreamingDevicesChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchForStreamingDevicesChange),
        switchMap(() => {
          this.liveFacadeService.setIsInitialized(true);
          return this.statusService.liveDeviceIdArr$.pipe(
            pairwise(),
            switchMap(([previousLiveDeviceIdArr, currentLiveDeviceIdArr]) => {
              return zip(
                of(currentLiveDeviceIdArr),
                of(previousLiveDeviceIdArr),
                this.userService.currentUserDevicesObject$.pipe(filter(devices => Object.keys(devices)?.length > 0))
              );
            })
          );
        }),
        tap(([currentLiveDeviceIdArr, previousLiveDeviceIdArr, devices]) => {
          this.getDiff(currentLiveDeviceIdArr, previousLiveDeviceIdArr).forEach(deviceId => {
            const device = {...devices[deviceId]};
            if (Object.keys(device)?.length === 0) {
              console.warn('could not find streaming device ' + deviceId);
              return;
            }
            if (currentLiveDeviceIdArr.indexOf(deviceId) < 0) {
              this.liveFacadeService.removeLiveDevice(device);
            } else {
              // TODO: refactor to use websockets
              // append player information - todo migrate to websockets to avoid nasty mapping and change detection!
              const stream = this.statusService.getStreamById(device.id);
              if (stream) {
                device.player = stream.player;
                this.liveFacadeService.addLiveDevice(device);
              }
            }
          });
        })
      ),
    {dispatch: false}
  );

  public removeLiveDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.removeLiveDevice),
        switchMap(({payload}: PayloadAction<{device: UserDeviceJoined}>) =>
          zip(
            of(payload.device),
            this.userService.liveDevices$.pipe(
              take(1),
              map(devices => devices.length > 0)
            )
          )
        ),
        tap(([devicePayload, isShowingStreams]) => {
          const device = {...devicePayload};

          this.showLimitReachedDialog(false);
          device.isLive = false;
          device.runningModels = [];
          device.waitingModels = [];
          device.lastSeen = new Date().getTime();
          device.isDisplayed = false;
          (device.isRestreamingFrom = false),
            (device.statsRestreamingFrom = ''),
            (device.isRestreamingTo = false),
            (device.statsRestreamingTo = '');
          this.userService.updateDeviceCache(device);

          if (isShowingStreams) {
            // eslint-disable-next-line
            this.translateService.get('live.live-stream-page.service.removeLiveDevice').subscribe(i18removeLiveDevice =>
              this.snackBar.open(`${device.name} ${i18removeLiveDevice.message}`, i18removeLiveDevice.action, {
                duration: 5000
              } as MatSnackBarConfig)
            );
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public addLiveDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.addLiveDevice),
        switchMap(({payload: {device}}: PayloadAction<{device: UserDeviceJoined}>) =>
          zip(
            of(device),
            this.userService.liveDevices$.pipe(
              take(1),
              map(devices => devices.length > 0)
            )
          )
        ),
        tap(([device, isShowingStreams]) => {
          this.userStoreFacadeService.addLiveDevice({deviceId: device.id, player: device.player});

          if (isShowingStreams) {
            // eslint-disable-next-line
            this.translateService.get('live.live-stream-page.service.addLiveDevice').subscribe(i18addLiveDevice =>
              this.snackBar.open(`${device.name} ${i18addLiveDevice.message}`, i18addLiveDevice.action, {
                duration: 5000
              } as MatSnackBarConfig)
            );
          }
        })
      ),
    {dispatch: false}
  );

  public watchForStreamSourceChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchForStreamSourceChanges),
        switchMap(() =>
          combineLatest([
            this.statusService.streams.pipe(
              distinctUntilChanged((prev, curr) => {
                return (
                  prev.map(s => s.deviceId + s.models.join('-') + s.player).toString() ===
                  curr.map(s => s.deviceId + s.models.join('-') + s.player).toString()
                );
              })
            ),
            this.addonStoreFacadeService
              .getAllActiveAddons()
              .pipe(
                distinctUntilChanged((prev, curr) => prev.map(a => a.id).toString() === curr.map(a => a.id).toString())
              )
          ])
        ),
        tap(([streams, streamAddons]) => {
          this.userStoreFacadeService.currentUserDevicesObject$
            .pipe(
              filter(devices => Object.keys(devices).length > 0),
              take(1)
            )
            .subscribe(devices => {
              streams.forEach((stream: any) => {
                let streamingDevice = devices[stream.deviceId];

                if (!streamingDevice) {
                  console.warn('No Streaming device found in user devices ' + stream.deviceId);
                  return;
                }
                // update running models
                const runningModels = streamAddons.filter(
                  addon => !!stream.models && stream.models.indexOf(addon.id) !== -1
                );

                this.userStoreFacadeService.setRunningModelsByDeviceId(
                  stream.deviceId,
                  runningModels.map(addon => addon.id)
                );
                streamingDevice = {...streamingDevice, runningModels: runningModels.map(addon => addon.id)};
                // update waiting models and switch current model
                if (this.currentlySelectedAIStreamWentOff(streamingDevice, stream.models)) {
                  this.showLimitReachedDialog(true);
                  this.userStoreFacadeService.setCurrentModel(streamingDevice.id, this.rawStream.id);
                }
                this.userStoreFacadeService.removeAddonFromWaitingModels(streamingDevice.id);
              });
            });
        })
      ),
    {dispatch: false}
  );

  public watchForStreamDestinationChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchForRestreamChanges),
        switchMap(() =>
          combineLatest([this.statusService.restreams, this.translateService.get('live.device-table.stats')])
        ),
        tap(([restreams, translations]) => {
          let deviceToUpdate: {
            [key: string]: {
              isRestreamingFrom?: boolean;
              statsRestreamingFrom?: string;
              isRestreamingTo?: boolean;
              statsRestreamingTo?: string;
              isLive: boolean;
            };
          } = {};

          for (let index = 0; index < restreams.length; index++) {
            const restream = restreams[index];
            deviceToUpdate[restream.deviceId] = {
              isRestreamingFrom: false,
              statsRestreamingFrom: '',
              isRestreamingTo: false,
              statsRestreamingTo: '',
              isLive: false
            };

            if (restream.type === 'FROM') {
              deviceToUpdate[restream.deviceId].isRestreamingFrom = true;
              deviceToUpdate[restream.deviceId].statsRestreamingFrom = this.emitStats(restream, translations);
              deviceToUpdate[restream.deviceId].isLive = true;
            } else if (restream.type === 'TO') {
              deviceToUpdate[restream.deviceId].isRestreamingTo = true;
              deviceToUpdate[restream.deviceId].statsRestreamingTo = this.emitStats(restream, translations);
              deviceToUpdate[restream.deviceId].isLive = true;
            }
          }

          this.userStoreFacadeService.updateRestreamsStats(deviceToUpdate);
        })
      ),
    {dispatch: false}
  );

  private START_SUBSCRIPTION_INTERVAL = 0;
  private STREAM_POLLING_INTERVAL = 5000;

  public startPolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.startPolling),
        switchMap(() => this.userService.user$),
        tap((user: UserModel) => {
          this.statusService.startPolling({
            user,
            startInterval: this.START_SUBSCRIPTION_INTERVAL,
            endInterval: this.STREAM_POLLING_INTERVAL
          });
        })
      ),
    {dispatch: false}
  );

  public addDevice$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.addDevice),
        switchMap(() => combineLatest([this.plansService.userPlan$, this.userService.userDevices$]).pipe(take(1))),
        tap(([userPlan, devices]) => {
          const deviceLimit = userPlan?.maxDevices ?? 0;
          const isReachedDeviceLimit = devices.length >= deviceLimit;

          if (!isReachedDeviceLimit) {
            this.showDeviceDialog();
            return;
          }

          this.showAdditionalDeviceDialog()
            .afterClosed()
            .pipe(take(1))
            .subscribe(result => {
              if (result) {
                this.showDeviceDialog();
              }
            });
        })
      ),
    {dispatch: false}
  );

  public watchSelectModelWhenDeviceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.watchSelectModelWhenDeviceChange),
        switchMap(() =>
          this.liveStreamPageService.selectedDeviceId$.pipe(
            switchMap(selectedDeviceId =>
              this.userService.currentUserDevicesObject$.pipe(map(devices => devices[selectedDeviceId]))
            )
          )
        ),
        tap((device: UserDeviceJoined) => {
          const isSelectedDeviceRunningModel =
            device &&
            ((device.waitingModels && device.waitingModels.length > 0) ||
              (device.runningModels && device.runningModels.length > 0));

          this.liveFacadeService.setIsSelectedDeviceRunningModel(isSelectedDeviceRunningModel);
        })
      ),
    {dispatch: false}
  );

  private showAdditionalDeviceDialog(): MatDialogRef<AdditionalDeviceDialogComponent> {
    return this.dialog.open(AdditionalDeviceDialogComponent, {
      width: '80vw',
      maxWidth: '800px',
      data: {},
      panelClass: 'profile-device-dialog',
      restoreFocus: false
    });
  }

  private showDeviceDialog(): MatDialogRef<DeviceDialog> {
    return this.dialog.open(DeviceDialog, {
      width: '80vw',
      maxWidth: '800px',
      data: {},
      panelClass: 'profile-device-dialog',
      restoreFocus: false
    });
  }

  private getDiff(newItems: string[], cachedItems: string[]): string[] {
    const cachedMinusNew = cachedItems.filter(x => newItems.indexOf(x) === -1);
    const newMinusCached = newItems.filter(x => cachedItems.indexOf(x) === -1);
    return cachedMinusNew.concat(newMinusCached);
  }

  // TODO: implement this.
  private showLimitReachedDialog(ai: boolean): void {
    // const dialogRef = this.dialog.open(StreamLimitDialog, <MatDialogConfig>{
    //   width: '320px',
    //   data: {ai: ai}
    // });
  }

  private currentlySelectedAIStreamWentOff(streamingDevice: UserDeviceJoined | undefined, streamingModels: any): any {
    let aiStreamOff;
    try {
      aiStreamOff =
        streamingDevice.selectedModel &&
        streamingDevice.selectedModel !== 'RAW_STREAM' &&
        (!streamingDevice.waitingModels ||
          // eslint-disable-next-line
          !streamingDevice.waitingModels.find(wMId => wMId === streamingDevice.selectedModel)) &&
        (!streamingModels || streamingModels.indexOf(streamingDevice.selectedModel) === -1);
    } catch (e) {
      console.warn('Could not infer stream status', streamingDevice, streamingModels);
      aiStreamOff = true;
    }
    return aiStreamOff;
  }

  private emitStats(restream: Stream, translations): string {
    // eslint-disable-next-line
    const {bitrate, fps, sent, streaming, time} = translations;

    const stats = `${streaming} ${restream.type}
    ${bitrate}: ${restream.bitrate || 'N/A'}
    ${fps}: ${restream.fps || 'N/A'}
    ${time}: ${(!!restream.out_time && restream.out_time.slice(0, 8)) || 'N/A'}
    ${sent}: ${!!restream.total_size ? (Number(restream.total_size) / 1024 / 1024).toFixed(2) + 'MB' : 'N/A'}
    `;

    return stats;
  }

  constructor(
    private userService: UserService,
    private actions$: Actions,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private liveFacadeService: LiveFacadeService,
    private userStoreFacadeService: UserStoreFacadeService,
    private agoraService: AgoraService,
    private route: ActivatedRoute,
    private webrtcService: WebrtcService,
    private libraryApiService: LibraryApiService,
    private unleashAnalytics: UnleashAnalyticsService,
    private deviceService: DeviceService,
    private statusService: StatusService,
    private addonStoreFacadeService: AddonStoreFacadeService,
    private plansService: PlansService,
    private liveStreamPageService: LiveStreamPageService,
    private dialog: MatDialog
  ) {}

  public updateAfterStop(deviceId: string): void {
    this.deviceService
      .getDevice(deviceId)
      .pipe(take(1))
      .subscribe(device => {
        this.userService.updateDeviceCache(device);
        this.listenStopAfterChanges(device);
      });
  }

  public listenStopAfterChanges(device: UserDeviceJoined): void {
    const stopAfter = device.automation?.batteryPowered?.stopAfter;
    const batteryDurationTime = (stopAfter || 0) - Date.now();
    if (batteryDurationTime > 0) {
      interval(batteryDurationTime)
        .pipe(
          take(1),
          tap(() => {
            this.snackBar
              .openFromComponent(ContinueWatchingComponent, {
                duration: 5000,
                panelClass: 'continue-watching',
                data: {deviceName: device.name}
              })
              .afterDismissed()
              .pipe(take(1))
              .subscribe(({dismissedByAction}: {dismissedByAction: boolean}) => {
                if (dismissedByAction) {
                  this.userService.updateAutomationConfig(device.id).subscribe(e => {
                    const updatedDevice = {
                      ...device,
                      automation: {
                        batteryPowered: {
                          // eslint-disable-next-line no-magic-numbers
                          stopAfter: new Date(stopAfter + 5 * 60000).getTime()
                        }
                      }
                    };
                    this.userService.updateDeviceCache(updatedDevice);
                    this.listenStopAfterChanges(updatedDevice);
                  });
                  return;
                }
                this.userService.stopStream(device);
              });
          })
        )
        .subscribe();
    }
  }
}

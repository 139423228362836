import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {MatDialog} from '@angular/material/dialog';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {filter, take, tap} from 'rxjs';

import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {ConvertToTaskComponent} from '../components/convert-to-task-dialog/convert-to-task-dialog.component';
import {JobsApiService} from '@app/jobs/services/jobs-api.service';

@Directive({
  selector: '[ulConvertToTaskAsset]',
  standalone: true
})
export class ConvertToTaskAssetDirective {
  @Input('ulConvertToTaskAsset') public asset: AtlasAssetModel;

  constructor(
    private dialog: MatDialog,

    private snackBar: MatSnackBar,
    private router: Router,
    private jobsApiService: JobsApiService
  ) {}

  @HostListener('click', ['$event'])
  public convertToTask(): void {
    const asset = this.asset;
    this.dialog
      .open(ConvertToTaskComponent, {
        ...STANDARD_DIALOG_CONFIG,
        data: asset,
        width: '80vw',
        maxWidth: '800px'
      })
      .afterClosed()
      .pipe(
        filter(result => !!result?.taskResult)
      )
      .subscribe(({taskResult}: any): void => {
        this.snackBar
          .open(
            'Task created',
            'Show task',
            {duration: 5000, panelClass: 'center'}
          )
          .onAction()
          .pipe(take(1))
          .subscribe(() => {
            this.router.navigate(['/secure/jobs', taskResult.jobId]);
          });
      });
  }
}

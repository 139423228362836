<ng-container *ngIf="mission?.type === missionType.MAPPING_2D; else defaultMissionControl">
  <unleash-survey-mission-info [mission]="mission"></unleash-survey-mission-info>
</ng-container>
<ng-template #defaultMissionControl>
  <div class="mission-viewer" [class.settings]="hasToShowSettings | async">
    <ng-container *ngIf="hasToShowSettings | async; else waypointsTemplate">
      <unleash-edit-mission-settings
        [defaultSettings]="defaultSettings"
        [isSmartInspect]="mission.isSmartInspect"
        (updatedMissionSetting)="updatedMissionSetting($event)"
        (goBack)="hideMissionSettings()"
        (smartInspect)="updateSmartInspect($event)"
      ></unleash-edit-mission-settings>
    </ng-container>
    <ng-template #waypointsTemplate>
      <ng-container *ngIf="mission?.description">
        <div class="mission-viewer__header">
          <div class="mission-viewer__waypoints">
            <mat-icon class="mission-viewer__header-icon">info</mat-icon>
            <span class="mission-viewer__header-title">{{ 'atlas.mission.summary' | translate }}</span>
          </div>
        </div>
        <div #summaryContainer class="mission-viewer__summary">
          {{ mission.description }}
          <div *ngIf="hasToShowEllipsis" class="mission-viewer__summary-show">
            <span
              class="mission-viewer__summary-action"
              [ngClass]="hasToShowCompleteSummary ? 'show-less' : 'show-more'"
              (click)="hasToShowCompleteSummary ? showLess() : showMore()"
            >
              <span class="ellipsis" *ngIf="!hasToShowCompleteSummary">...</span>

              {{ hasToShowCompleteSummary ? ('atlas.mission.less' | translate) : ('atlas.mission.more' | translate) }}
            </span>
          </div>
        </div>
      </ng-container>
      <div class="mission-viewer__header">
        <div class="mission-viewer__waypoints">
          <mat-icon class="mission-viewer__header-icon">location_on</mat-icon>
          <span class="mission-viewer__header-title"
            >{{ 'atlas.mission.waypoints' | translate }} ({{ mission?.route?.length }})</span
          >
        </div>
        <div *ngIf="hasToShowSettingsButton | async" class="mission-viewer__settings">
          <button mat-button color="primary" (click)="setShowSettings(true)">
            <mat-icon class="mission-viewer__header-icon">settings</mat-icon>
            <span>{{ 'common.settings' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="mission-viewer__routes">
        <ng-container *ngIf="mission?.route?.length === 0; else waypointsListTemplate">
          <div class="mission-viewer__no-waypoints">
            <mat-icon class="mission-viewer__no-waypoints-icon" svgIcon="no-waypoints"></mat-icon>
            <span class="mission-viewer__no-waypoints-title">{{ 'atlas.mission.noWaypoints' | translate }}</span>
            <span
              class="mission-viewer__no-waypoints-text"
              [innerHTML]="'atlas.mission.noWaypointsText' | translate"
            ></span>
          </div>
        </ng-container>
        <ng-template #waypointsListTemplate>
          <ng-container *ngFor="let route of mission?.route; let i = index">
            <button
              [class.mission-viewer__route-selected]="selectedIndex === i && !isMissionDrawerClosed"
              mat-menu-item
              class="mission-viewer__route"
              (click)="isNewRoute ? '' : emitSelectedWaypoint(route, i)"
            >
              <section>
                <div
                  [ngClass]="
                    i === 0 || i === mission?.route?.length - 1
                      ? 'mission-viewer__circle-limits'
                      : 'mission-viewer__circle-waypoint'
                  "
                >
                  {{ i === 0 ? 'S' : i === mission?.route?.length - 1 ? 'E' : i + 1 }}
                </div>
                <div class="mission-viewer__points" *ngIf="i !== mission?.route?.length - 1">
                  <div class="mission-viewer__point"></div>
                  <div class="mission-viewer__point"></div>
                  <div class="mission-viewer__point"></div>
                  <div class="mission-viewer__point"></div>
                </div>
              </section>
              <section class="mission-viewer__route-data">
                <span class="mission-viewer__route-name">
                  {{ i === 0 ? 'Start' : i === mission?.route?.length - 1 ? 'End' : 'Waypoint' + ' ' + (i + 1) }}
                </span>
                <span class="mission-viewer__route-info">
                  <div class="mission-viewer__tooltip">
                    <span class="mission-viewer__tooltiptext">{{ 'atlas.mission.height' | translate }}</span>
                    <mat-icon class="mission-viewer__waypoint-icon" svgIcon="altitude-waypoint"></mat-icon
                    >{{ route.altitude | number: '0.0-0' }}m
                  </div>
                  <div class="mission-viewer__tooltip">
                    <span class="mission-viewer__tooltiptext">{{ 'atlas.mission.heading' | translate }}</span>
                    <mat-icon class="mission-viewer__waypoint-icon" svgIcon="heading-waypoint"></mat-icon
                    >{{ route.heading | number: '0.0-0' }}&deg;
                  </div>
                  <div class="mission-viewer__tooltip">
                    <span class="mission-viewer__tooltiptext">{{ 'atlas.mission.speed' | translate }}</span>
                    <mat-icon class="mission-viewer__waypoint-icon" svgIcon="speed-waypoint"></mat-icon>
                    {{ route.speed | number: '1.0-3' }}m/s
                  </div>
                  <div *ngIf="route.actions?.length > 0">
                    <ng-template #actionTooltip>
                      <div class="tooltip-list">
                        <span class="tooltip-list__title"> {{ 'atlas.mission.actions' | translate }} </span>
                        <div class="tooltip-list__content" *ngFor="let action of route.actions">
                          {{ action.action | translateAction }}
                        </div>
                      </div>
                    </ng-template>
                    <mat-icon
                      [mtxTooltip]="actionTooltip"
                      mtxTooltipPosition="after"
                      class="mission-viewer__waypoint-icon"
                      svgIcon="mission_actions"
                    ></mat-icon>
                  </div>
                </span>
              </section>
            </button>
          </ng-container>
        </ng-template>
      </div>
    </ng-template>
  </div>
</ng-template>

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Map} from 'leaflet';
import {LayersVisibilityService} from '../services/layers-visibility.service';

@Directive({
  selector: '[ulSaveLayerVisibility]',
  standalone: true
})
export class SaveLayerVisibilityDirective {
  @Input('ulSaveLayerVisibility') public asset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(private layersVisibilityService: LayersVisibilityService) {}

  @HostListener('click', ['$event'])
  public saveLayerVisibility($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.layersVisibilityService.toggleLayerVisibility(this.asset, this.map);
  }
}

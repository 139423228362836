<div *ngIf="hasToShowTooltip && (hasLibraryItem | async) === false && !isLoadingLibraryItem" class="tooltip">
  {{ 'atlas.markerMenu.noMediaDrive' | translate }}
</div>
<button
  *uaHasPermission="aclPermissions.LibraryApiRead"
  (mouseleave)="setHasToShowTooltip(false)"
  (mouseover)="setHasToShowTooltip(true)"
  [disabled]="(hasLibraryItem | async) === false"
  class="menu-button"
  (click)="viewInMediaDrive()"
  mat-menu-item
>
  <unleash-spinner *ngIf="isLoadingLibraryItem; else iconTemplate" diameter="20" [color]="'#ccc'"></unleash-spinner>
  <ng-template #iconTemplate
    ><mat-icon class="icon" [style.color]="(hasLibraryItem | async) === false ? '#ccc' : '#999'"
      >image</mat-icon
    ></ng-template
  >
  {{ 'atlas.markerMenu.viewInMediaDrive' | translate }}
</button>
<mat-divider></mat-divider>
<button
  class="menu-button submenu-trigger"
  *ngIf="feature?.properties.state"
  (click)="detectChanges()"
  mat-menu-item
  [matMenuTriggerFor]="status"
  (menuClosed)="detectChanges()"
>
  <mat-icon svgIcon="in-progress-marker" class="custom-icon"></mat-icon>
  <div class="marker-state">
    {{ 'atlas.markerMenu.changeStatus' | translate }}
    <mat-icon class="menu-icon">arrow_right</mat-icon>
  </div>
</button>
<mat-menu #status="matMenu">
  <button class="menu-button" (click)="setMarkerState(geojsonFeatureState.IN_PROGRESS)" mat-menu-item>
    <mat-icon svgIcon="in-progress-marker" class="custom-icon"></mat-icon
    >{{ 'atlas.markerMenu.inProgress' | translate }}
    <mat-icon *ngIf="feature?.properties.state === geojsonFeatureState.IN_PROGRESS" class="check-icon">check</mat-icon>
  </button>
  <button class="menu-button" (click)="setMarkerState(geojsonFeatureState.COMPLETED)" mat-menu-item>
    <mat-icon svgIcon="complete-marker" class="custom-icon"></mat-icon>{{ 'atlas.markerMenu.complete' | translate
    }}<mat-icon *ngIf="feature?.properties.state === geojsonFeatureState.COMPLETED" class="check-icon">check</mat-icon>
  </button>
  <button class="menu-button" (click)="setMarkerState(geojsonFeatureState.NOT_COMPLETED)" mat-menu-item>
    <mat-icon svgIcon="incomplete-marker" class="custom-icon"></mat-icon>{{ 'atlas.markerMenu.incomplete' | translate
    }}<mat-icon *ngIf="feature?.properties.state === geojsonFeatureState.NOT_COMPLETED" class="check-icon"
      >check</mat-icon
    >
  </button>
</mat-menu>

<button class="menu-button" (click)="copyToClipboard()" mat-menu-item>
  <mat-icon class="icon">gps_fixed</mat-icon>{{ 'atlas.markerMenu.copyLocation' | translate }}
</button>
<ng-container *uaHasPermission="aclPermissions.AtlasApiUpdate">
  <mat-divider></mat-divider>
  <button [map]="map" [ulSelectMarkers]="asset" class="menu-button" mat-menu-item>
    <mat-icon svgIcon="select-markers" class="custom-icon"></mat-icon>{{ 'atlas.markerMenu.selectMarkers' | translate }}
  </button>
</ng-container>

import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Role, Permission} from '../models/roles-management.model';
import * as RolesManagementActions from '../store/roles-management/roles-management.actions';
import * as RolesManagementSelectors from '../store/roles-management/roles-management.selectors';
import {take} from 'rxjs/operators';
import {RolesManagementState} from '@app/profile/store/roles-management/roles-management.state';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementStoreFacadeService {
  roles$: Observable<Role[]> = this.store.select(RolesManagementSelectors.selectRoles);
  permissions$: Observable<Permission[]> = this.store.select(RolesManagementSelectors.selectPermissions);
  isLoading$: Observable<boolean> = this.store.select(RolesManagementSelectors.selectIsLoading);
  error$: Observable<string> = this.store.select(RolesManagementSelectors.selectError);
  updatedRoleIds$: Observable<string[]> = this.store.select(RolesManagementSelectors.selectUpdatedRoleIds);

  constructor(private store: Store) {}

  loadRoles(): void {
    this.store.dispatch(RolesManagementActions.loadRoles());
  }

  loadPermissions(): void {
    this.store.dispatch(RolesManagementActions.loadPermissions());
  }

  addRole(role: {name: string}): void {
    this.store.dispatch(RolesManagementActions.addRole({role}));
  }

  updateLocalRolePermissions(roleId: string, permissionKey: string, isAdd: boolean): void {
    this.store.dispatch(RolesManagementActions.updateLocalRolePermissions({roleId, permissionKey, isAdd}));
  }

  deleteLocalRole(roleId: string): void {
    this.store.dispatch(RolesManagementActions.deleteLocalRole({roleId}));
  }

  deleteRole(roleId: string): void {
    this.store.dispatch(RolesManagementActions.deleteRole({roleId}));
  }

  saveChanges(): void {
    // Get current state snapshot
    let state: RolesManagementState;
    this.store
      .select(RolesManagementSelectors.selectRolesManagementState)
      .pipe(take(1))
      .subscribe(s => (state = s));

    // Process each type of change
    state.roles
      .filter(role => state.updatedRoleIds.includes(role.id))
      .forEach(role => {
        if (!role.pk && !role.sk) {
          // New role that needs to be created
          this.store.dispatch(
            RolesManagementActions.createRole({
              role: {
                id: role.id, // Pass the temporary ID
                name: role.name,
                acl: role.acl || []
              }
            })
          );
        } else {
          // Existing role that was modified
          this.store.dispatch(
            RolesManagementActions.updateRole({
              roleId: role.id,
              role: {acl: role.acl}
            })
          );
        }
      });

    // Process deleted roles
    state.deletedRoleIds.forEach(roleId => {
      this.store.dispatch(RolesManagementActions.deleteRole({roleId}));
    });
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectMarkersOption} from '../atlas-map/atlas-map.component';
import { AssetType } from '@app/core/models/api/atlas.model';

@Component({
  selector: 'unleash-select-markers',
  templateUrl: './select-markers.component.html',
  styleUrls: ['./select-markers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMarkersHeaderComponent {
  @Input() public totalSelectedMarkers: number = 0;
  @Input() public activeSelectedOption: SelectMarkersOption;
  @Input() public xPosition: number = 0;
  @Input() public isEditJobMode: boolean = false;
  @Input() public layerType: string;

  @Output() public selectByBox: EventEmitter<void> = new EventEmitter();
  @Output() public selectByPolygon: EventEmitter<void> = new EventEmitter();
  @Output() public selectByClick: EventEmitter<void> = new EventEmitter();

  public AssetType = AssetType;
  public selectMarkersOption: typeof SelectMarkersOption = SelectMarkersOption;

  public emitSelectByBox(): void {
    this.selectByBox.emit();
  }

  public emitSelectByPolygon(): void {
    this.selectByPolygon.emit();
  }

  public emitSelectByClick(): void {
    this.selectByClick.emit();
  }
}

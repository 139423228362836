<h1 mat-dialog-title>{{ 'atlas.convertToTaskDialog.title' | translate }}</h1>
<mat-stepper color="accent" linear>
  <mat-step color="accent" [stepControl]="form">
    <ng-template color="accent" matStepLabel>{{ 'atlas.convertToTaskDialog.title' | translate }}</ng-template>
    <div mat-dialog-content [formGroup]="form" class="form-container">
      <div class="form-section">
        <section class="form-layer">
          <mat-label class="label">{{ 'atlas.convertToTaskDialog.layer' | translate }}</mat-label>
          <span>{{ data.name }}</span>
        </section>
        <mat-label class="label">{{ 'common.settings' | translate }}</mat-label>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'atlas.convertToTaskDialog.missionName' | translate }}</mat-label>
          <input matInput type="text" formControlName="missionName" data-test-id="missionName" />
        </mat-form-field>
        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'atlas.convertToTaskDialog.smartInspectProfile' | translate }}</mat-label>
          <mat-select formControlName="smartInspectProfile" data-test-id="smartInspectProfile">
            <mat-option *ngFor="let profile of smartInspectProfiles" [value]="profile.key">
              {{ profile.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'atlas.convertToTaskDialog.operationalAltitude' | translate }}</mat-label>
          <input
            matInput
            type="number"
            formControlName="operationalAltitude"
            data-test-id="operationalAltitude"
            min="0"
          />
          <span class="unit" matSuffix>{{ 'atlas.convertToTaskDialog.meters' | translate }}</span>
        </mat-form-field>

        <mat-form-field class="form-field" appearance="fill">
          <mat-label>{{ 'atlas.convertToTaskDialog.speed' | translate }}</mat-label>
          <input matInput type="number" formControlName="speed" data-test-id="speed" min="0" step="0.1" />
          <span class="unit" matSuffix>{{ 'atlas.convertToTaskDialog.metersSeconds' | translate }}</span>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-button color="primary" (click)="cancel()">{{ 'common.cancel' | translate }}</button>
      <button mat-raised-button color="primary" matStepperNext>
        {{ 'atlas.convertToTaskDialog.next' | translate }}
      </button>
    </div>
  </mat-step>
  <mat-step color="accent" [stepControl]="jobForm">
    <ng-template color="accent" matStepLabel>{{
      'atlas.convertToTaskDialog.createJobAndTask' | translate
    }}</ng-template>
    <div mat-dialog-content [formGroup]="jobForm" class="form-container">
      <mat-radio-group class="form-radio-group" formControlName="jobType">
        <mat-radio-button class="form-radio" [value]="jobSelectionType.CREATE_NEW"
          >{{ 'atlas.convertToTaskDialog.createNewJobForTask' | translate }}
        </mat-radio-button>
        <mat-form-field
          *ngIf="jobForm.controls.jobType.value === jobSelectionType.CREATE_NEW"
          class="form-field"
          appearance="fill"
        >
          <mat-label>{{ 'atlas.convertToTaskDialog.jobName' | translate }}</mat-label>
          <input matInput type="text" formControlName="jobName" data-test-id="missionName" />
        </mat-form-field>
        <mat-radio-button class="form-radio" [value]="jobSelectionType.ADD_TO_EXISTING">{{
          'atlas.convertToTaskDialog.addTaskToExistingJob' | translate
        }}</mat-radio-button>
        <mat-form-field
          *ngIf="jobForm.controls.jobType.value === jobSelectionType.ADD_TO_EXISTING"
          class="form-field"
          appearance="fill"
        >
          <mat-label>{{ 'atlas.convertToTaskDialog.selectJob' | translate }}</mat-label>
          <mat-select formControlName="jobId" data-test-id="jobSelect">
            <mat-option [value]="">--</mat-option>
            <mat-option *ngFor="let job of jobs$ | async" [value]="job.id">
              {{ job.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-radio-group>
    </div>
    <div mat-dialog-actions>
      <button mat-button color="primary" matStepperPrevious>{{ 'common.back' | translate }}</button>
      <button
        [appLadda]="isLoading$ | async"
        [disabled]="!jobForm.valid || (isLoading$ | async)"
        (click)="convertAssetToTask()"
        data-test-id="convert-asset-to-task-button"
        mat-raised-button
        color="primary"
        matStepperNext
      >
        {{
          jobForm.controls.jobType.value === jobSelectionType.ADD_TO_EXISTING
            ? ('atlas.convertToTaskDialog.addTaskToJob' | translate)
            : ('atlas.convertToTaskDialog.addTaskToNewJob' | translate)
        }}
      </button>
    </div>
  </mat-step>
</mat-stepper>

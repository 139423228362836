import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {ForgotPassword} from '@app/auth/components/forgot-password/forgot-password.model';
import {selectForgotPasswordFormInProgress} from '@app/auth/components/forgot-password/forgot-password.selectors';
import {Observable} from 'rxjs';
import {AuthService} from '@app/auth/services/auth.service';

@Component({
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss']
})
export class ForgotPasswordPage implements OnInit {
  isInProgress$: Observable<boolean>;
  public savedEmail$: Observable<string> = this.authService.savedEmail$;
  public isDeveloperMode = this.route.snapshot.data.isDeveloperMode;

  constructor(
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isInProgress$ = this.store.pipe(select(selectForgotPasswordFormInProgress));
  }

  backLastLocation() {
    const backTo = history.state.backTo
      ? history.state.backTo
      : this.isDeveloperMode
      ? '/auth/developer-sign-in'
      : '/auth/sign-in';
    this.router.navigate([backTo]);
  }

  public continue(event: ForgotPassword) {
    this.authService.triggerForgotPassword(event.email, this.isDeveloperMode);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MissionType, MissionTypeDisplay } from '@app/atlas/model/mission.model';
import {Mission} from '@app/atlas/services/mission-settings.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import { ExportType } from '@app/profile/models/export-data.model';
import { MissionExportType } from '@app/atlas/model/mission-geojson.model';

@Component({
  selector: 'unleash-missions-header',
  templateUrl: './missions-header.component.html',
  styleUrls: ['./missions-header.component.scss']
})
export class MissionsHeaderComponent {
  @Input() public aclPermissions: typeof AclPermissions;
  @Input() public selectedMission: Mission;
  @Input() public selectedWaypointIndex: number;

  @Output() public closeMissionViewer: EventEmitter<void> = new EventEmitter();
  @Output() public missionCopiedToClipboard: EventEmitter<void> = new EventEmitter();
  @Output() public updatedMission: EventEmitter<Mission> = new EventEmitter();

  public isNewMission: boolean = false;
  public isEditMode: boolean = false;
  public isUpdatingMission: boolean = false;
  public isCreatingMission: boolean = false;
  public missionType: typeof MissionType = MissionType;
  public missionTypeDisplay: typeof MissionTypeDisplay = MissionTypeDisplay;

  public emitCloseMissionViewer(): void {
    this.closeMissionViewer.emit();
    this.isNewMission = false;
  }

  public showCopyToClipboard(): void {
    this.missionCopiedToClipboard.emit();
  }

  public emitUpdatedMission(mission: Mission): void {
    this.updatedMission.emit(mission);
  }

  public emitNewMission(): void {
    this.isNewMission = true;
    const mission = {
      name: 'Untitled',
      route: [],
      distance: 0,
      time: 0,
      isSmartInspect: false,
      type: 'waypoint',
      isNewMission: true
    } as unknown as Mission;
    this.updatedMission.emit(mission);
  }

  public setNewMission(isNewMission: boolean): void {
    this.isNewMission = isNewMission;
  }

  public clearRoutes(): void {
    this.updatedMission.emit({...this.selectedMission, route: []});
  }

  public setEditMode(isEditing: boolean): void {
    this.isEditMode = isEditing;
  }

  public setIsUpdatingMission(isUpdating: boolean): void {
    this.isUpdatingMission = isUpdating;
  }

  public setIsCreatingMission(isCreatingMission: boolean): void {
    this.isCreatingMission = isCreatingMission;
  }

  protected readonly MissionExportType = MissionExportType;
}

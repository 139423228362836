import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ForgotPasswordStepTwo} from './forgot-password-step-two.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {passwordRegex} from '@app/auth/regex';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'unleash-forgot-password-step-two',
  templateUrl: './forgot-password-step-two.component.html',
  styleUrls: ['./forgot-password-step-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordStepTwoComponent implements OnInit {
  @Input() public forgotPasswordForm: ForgotPasswordStepTwo;
  @Input() public inProgress: boolean;
  @Input() public isUserInvitation: boolean;
  @Input('isCodeExpired') public set setisCodeExpired(isCodeExpired: boolean) {
    this.isCodeExpired = isCodeExpired;
    if (this.form && isCodeExpired) {
      this.form.controls.code.setErrors({expiredCode: true});
    }
  }
  @Input() public codeGeneratedSuccessfully: boolean;

  @Output() public submitForm: EventEmitter<Partial<ForgotPasswordStepTwo>> = new EventEmitter<
    Partial<ForgotPasswordStepTwo>
  >();
  @Output() public resendCode: EventEmitter<void> = new EventEmitter<void>();

  public isCodeExpired: boolean;
  public form: FormGroup;
  public hidePassword: boolean = true;
  public passwordValidations = [
    {translationKey: 'validationOne', isValid: false},
    {translationKey: 'validationTwo', isValid: false},
    {translationKey: 'validationThree', isValid: false},
    {translationKey: 'validationFour', isValid: false}
  ];
  public isPasswordValid: boolean = false;
  public newCodeRequired: boolean = false;

  private changePasswordSub: Subscription;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.initializeForm();
    this.listenPasswordInput();
  }

  public listenPasswordInput() {
    this.changePasswordSub = this.form.controls.password.valueChanges.subscribe(password => {
      this.passwordValidations[0].isValid = /\d/.test(password);
      this.passwordValidations[1].isValid = /[A-Z]/.test(password);
      this.passwordValidations[2].isValid = /[a-z]/.test(password);
      this.passwordValidations[3].isValid = password.length >= 8;
      this.isPasswordValid = this.passwordValidations.every(obj => obj.isValid === true);
    });
  }

  public initializeForm(): void {
    this.form = this.fb.group({
      code: [
        this.forgotPasswordForm.code,
        [Validators.required, this.customValidator.bind(this), Validators.minLength(6), Validators.maxLength(6)]
      ],
      password: ['', [Validators.required, this.passwordValidator]],
      terms: [false, this.isUserInvitation ? Validators.requiredTrue : '']
    });
  }

  public clickForgotPasswordButton() {
    if (this.form.valid) {
      this.submitForm.emit(this.form.value);
    } else {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
    }
  }

  public emitResendCode() {
    this.newCodeRequired = true;
    this.resendCode.emit();
  }

  public cleanTermsAndConditionError(): void {
    this.form.controls.terms.markAsUntouched();
  }

  private passwordValidator(formControl: FormControl) {
    if (!passwordRegex.test(formControl?.value)) {
      return {passwordError: true};
    }
    return null;
  }

  private customValidator(): ValidationErrors | null {
    if (this.isCodeExpired && !this.codeGeneratedSuccessfully) {
      return {expiredCode: true};
    }
    return null;
  }
}

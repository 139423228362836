import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {zip} from 'rxjs';
import {take} from 'rxjs/operators';
import {ALLOWED_FILE_TYPES} from '@app/config/file-types';

export enum UploadModuleType {
  LIBRARY = 'LIBRARY',
  ATLAS = 'ATLAS',
  DEVICE = 'DEVICE'
}

@Injectable({
  providedIn: 'root'
})
export class FileTypeService {
  constructor(private translateService: TranslateService, private snackBar: MatSnackBar) {}

  filterValidFiles(files: File[], moduleType: UploadModuleType): File[] {
    const validFiles = files.filter(file => this.isFileTypeAccepted(file, moduleType));
    const invalidFilesCount = files.length - validFiles.length;

    if (invalidFilesCount > 0) {
      this.displayUnsupportedFilesMessage(invalidFilesCount, moduleType);
    }
    return validFiles;
  }

  private isFileTypeAccepted(file: File, moduleType: UploadModuleType): boolean {
    const allowedTypes = ALLOWED_FILE_TYPES[moduleType];
    // 1. check mime type
    const mimeTypeMatches = allowedTypes.MIME_TYPES.some(acceptedType => {
      if (acceptedType.endsWith('/*')) {
        const [category] = acceptedType.split('/');
        return file.type?.startsWith(category + '/');
      }
      return file.type === acceptedType;
    });

    if (mimeTypeMatches) {
      return true;
    }

    // 2. check extension
    const fileName = file.name.toLowerCase();
    return allowedTypes.EXTENSIONS.some(format => fileName.endsWith(format));
  }

  private displayUnsupportedFilesMessage(invalidFilesCount: number, moduleType: UploadModuleType): void {
    const translationKey = `${moduleType.toLowerCase()}.dialogs.uploadDevice`;

    zip(
      this.translateService.get(`${translationKey}.unsupportedFiles`, {totalFiles: invalidFilesCount}),
      this.translateService.get(`${translationKey}.unsupportedFile`)
    )
      .pipe(take(1))
      .subscribe(([unsupportedFiles, unsupportedFile]) => {
        this.snackBar.open(invalidFilesCount === 1 ? unsupportedFile : unsupportedFiles, null, {
          duration: 3000,
          panelClass: 'center'
        });
      });
  }
}

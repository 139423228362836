import {Injectable} from '@angular/core';
import {Mission} from './mission-settings.service';
import {MissionType} from '../model/mission.model';
import {MissionImportService} from '@app/flights/services/mission-import.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadMissionsService {
  public missionsToUpload: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public kmzMissionsToUpload: BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(private missionImportService: MissionImportService) {}

  public importMission(files) {
    for (let i = 0; i < files.length; i++) {
      const kmzRegex = /\.kmz$/;
      const isKmz = files[i].type === 'application/vnd.google-earth.kmz' || kmzRegex.test(files[i].name);
      if (isKmz) {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          this.setKmzMissionsToUpload([
            ...this.kmzMissionsToUpload.value,
            {result: fileReader.result, name: files[i].name, size: (files[i].size / 1000).toFixed(2) + 'kB'}
          ]);
        };
        fileReader.readAsArrayBuffer(files[i]);
        continue;
      }
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const mission: Mission = await this.missionImportService.importFile(files[i], fileReader.result as string);
        if (mission) {
          this.filterActions(mission);
          this.setMissionsToUpload([
            ...this.missionsToUpload.value,
            {result: mission, name: files[i].name, size: (files[i].size / 1000).toFixed(2) + 'kB'}
          ]);
        }
        if (mission?.type === MissionType.CORRIDOR) {
          console.info('Created Corridor mission', mission);
        }
      };
      fileReader.readAsText(files[i]);
    }
  }

  public arrayBufferToHexString(arrayBuffer) {
    const byteArray = new Uint8Array(arrayBuffer);
    const hexArray = [];
    for (let i = 0; i < byteArray.length; i++) {
      hexArray.push(byteArray[i].toString(16).padStart(2, '0'));
    }

    return hexArray.join('');
  }

  public setMissionsToUpload(missions: any[]) {
    this.missionsToUpload.next(missions);
  }

  public setKmzMissionsToUpload(kmzMissions: any[]) {
    console.info('kmzMissions', kmzMissions);
    this.kmzMissionsToUpload.next(kmzMissions);
  }

  public resetMissionsToUpload() {
    this.missionsToUpload.next([]);
    this.kmzMissionsToUpload.next([]);
  }

  public filterActions(mission: Mission) {
    mission?.route.forEach(route => {
      if (route.actions?.length > 0) {
        route.actions = route.actions.filter(action => action?.action);
      }
    });
  }
}

import {Injectable} from '@angular/core';
import {SuperclusterService} from './supercluster.service';
import {AtlasService} from './atlas.service';
import {AssetType, AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {Map} from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class LayersVisibilityService {
  constructor(private atlasService: AtlasService, private superclusterService: SuperclusterService) {}

  public displaySelectMarkersView(selectedAsset: AtlasAssetModel, map: Map) {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = true;
      asset.isHighlighted = false;
      asset.type === AssetType.GEOJSON
        ? this.atlasService.toggleAssetLayer({
            isDisplaying: true,
            layer: asset.leafletLayer,
            map
          })
        : this.atlasService.toggleAssetLayer({
            isDisplaying: selectedAsset.id === asset.id,
            layer: asset.leafletLayer,
            map
          });

      this.superclusterService.toggleClusterItemsVisibility(selectedAsset.id === asset.id, asset.id);
    });
    this.superclusterService.updateClusters(map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public showOnlyThisLayer(selectedAsset: AtlasAssetModel, map: Map): void {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = selectedAsset.id === asset.id;
      asset.isHighlighted = false;
      this.updateLayerVisibility(asset, map);
    });
    this.superclusterService.updateClusters(map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public showDefaultView(map: Map, hasToDetectChanges: boolean): void {
    this.atlasService.assets
      .filter(asset => this.atlasService.defaultAssetsView[asset.id])
      .forEach(asset => {
        asset.isDisplaying = this.atlasService.defaultAssetsView[asset.id]?.isDisplaying;
        this.updateLayerVisibility(asset, map);
      });
    this.superclusterService.updateClusters(map);
    if (hasToDetectChanges) {
      this.atlasService.toggleHasToDetectChanges();
    }
  }

  public restoreLayersVisibility(map: Map): void {
    this.atlasService.displayAssets(map);
    this.superclusterService.restoreHiddenAssets();
    this.superclusterService.refreshCluster(this.atlasService.getAllPointFeatures(), map);
  }

  public displayLayersToBeSelectedOnly(assetId: string, polygonAssetId: string, map: Map): void {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = true;
      asset.isHighlighted = false;
      asset.type === AssetType.GEOJSON
        ? this.atlasService.toggleAssetLayer({
            isDisplaying: true,
            layer: asset.leafletLayer,
            map
          })
        : this.atlasService.toggleAssetLayer({
            isDisplaying: asset.id === assetId || polygonAssetId === asset.id,
            layer: asset.leafletLayer,
            map
          });
      this.superclusterService.toggleClusterItemsVisibility(
        asset.id === assetId || polygonAssetId === asset.id,
        asset.id
      );
    });
    this.superclusterService.updateClusters(map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public toggleLayerVisibility(asset: AtlasAssetModel, map: Map): void {
    asset.isDisplaying = !asset.isDisplaying;
    this.setLayerVisibility(asset, map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public setLayerVisibility(asset: AtlasAssetModel, map: Map): void {
    this.updateLayerVisibility(asset, map);
    this.superclusterService.updateClusters(map);
  }

  public showOnlyThisGroup(groupName: string, map: Map) {
    this.atlasService.assets.forEach(asset => {
      asset.isDisplaying = groupName === asset?.groupName;
      this.updateLayerVisibility(asset, map);
    });
    this.superclusterService.updateClusters(map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public toggleAssetsGroups(assets: AtlasAssetModel[], isDisplaying: boolean, map: Map): void {
    assets.forEach(asset => {
      asset.isDisplaying = isDisplaying;
      this.updateLayerVisibility(asset, map);
    });
    this.superclusterService.updateClusters(map);
  }

  public showDisplayedAssets(map: Map) {
    this.atlasService.assets.forEach(asset => {
      this.updateLayerVisibility(asset, map);
    });
    this.superclusterService.updateClusters(map);
    this.atlasService.toggleHasToDetectChanges();
  }

  public updateLayerVisibility(asset: AtlasAssetModel, map): void {
    this.superclusterService.toggleClusterItemsVisibility(asset.isDisplaying, asset.id);
    this.atlasService.toggleAssetLayer({
      isDisplaying: asset.isDisplaying,
      layer: asset.leafletLayer,
      map
    });
  }

  public updateMarkerStatusClusterColor(assetId: string, index: number, map: Map): void {
    const point = this.superclusterService.getPoint(assetId, index);
    if (!point) {
      return;
    }
    point.properties.featureColor = this.atlasService.getFeatureColorAttribution(point);
    this.superclusterService.updateClusters(map);
  }

  public updateClusterColor(asset: AtlasAssetModel, colorIndex: number, map: Map) {
    asset.color = this.atlasService.availableColors[colorIndex];
    asset.customColorIndex = colorIndex;
    if (asset.type !== AssetType.GEOJSON) {
      return;
    }
    (asset.geojson as any).features?.forEach(feature => {
      const point = this.superclusterService.getPoint(asset.id, feature.index);
      if (!point) {
        return;
      }
      point.asset.color = asset.color;
      point.asset.customColorIndex = asset.customColorIndex;
    });

    this.superclusterService.setNewAssetColor(asset.color, asset.id);
    this.superclusterService.updateClusters(map);
  }
}

<form [formGroup]="form" (submit)="clickContinue()" class="form">
  <unleash-radio-card
    formControlName="reason"
    [options]="options"
    [selectedCard]="form.value.reason"
    (valueSelected)="emitReasonSelected()"
    data-test-id="unleashRadioCard"
  ></unleash-radio-card>
  <div class="buttons">
    <button
      class="form__item continue-button"
      [disabled]="!form.valid || !isAuthInitialized"
      mat-raised-button
      data-test-id="continueButton"
      color="primary"
    >
      <ng-container *ngIf="isInProgress || !isAuthInitialized; else displayButtonTemplate">
        <unleash-spinner></unleash-spinner>
      </ng-container>
      <ng-template #displayButtonTemplate>
        {{ 'common.continue' | translate }}
      </ng-template>
    </button>
  </div>
</form>

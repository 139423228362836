<div class="container">
  <section class="schedule" [formGroup]="scheduleForm">
    <section class="schedule__device">
      <div class="schedule__device-header">
        <div class="schedule__device-title">
          <mat-icon class="schedule__device-icon" svgIcon="devices"></mat-icon>
          <span> {{ 'live.scheduler.device' | translate }} </span>
        </div>
        <div class="schedule__device-total" [class.capitalize]="scheduleForm.controls.devices.value?.length === 0">
          {{
            scheduleForm.controls.devices.value?.length > 0
              ? scheduleForm.controls.devices.value?.length === 1
                ? scheduleForm.controls.devices.value?.length + ' ' + ('live.scheduler.device' | translate)
                : scheduleForm.controls.devices.value?.length + ' ' + ('live.scheduler.devices' | translate)
              : ('live.scheduler.none' | translate)
          }}
        </div>
      </div>
      <ng-container *ngIf="isAdmin; else deviceListTemplate">
        <div class="schedule__device-searcher mat-elevation-z3">
          <mat-form-field class="schedule__input" appearance="fill">
            <span matPrefix><mat-icon class="schedule__search-icon">search</mat-icon></span>
            <input
              formControlName="search"
              matInput
              type="text"
              [placeholder]="'live.scheduler.searchDevices' | translate"
            />
          </mat-form-field>
          <ng-container *ngIf="scheduleForm.controls.search.value?.length === 0">
            <span
              *ngIf="!(scheduleForm.controls.devices?.value?.length === devicesList?.length); else deselectTemplate"
              class="schedule__device-select"
              (click)="selectAllDevices()"
              >{{ 'live.scheduler.selectAll' | translate }}</span
            >
            <ng-template #deselectTemplate>
              <span class="schedule__device-select" (click)="deSelectAllDevices()">{{
                'live.scheduler.deSelectAll' | translate
              }}</span>
            </ng-template>
          </ng-container>
          <ng-container *ngFor="let device of filteredDevices | async">
            <mat-checkbox
              [checked]="scheduleForm.controls.devices.value?.includes(device?.id)"
              (change)="addDevice(device?.id)"
              class="schedule__device-item"
            >
              <div class="schedule__device-container">
                <span class="schedule__device-name">
                  {{ device?.name }}
                </span>
                <span class="schedule__device-description">{{ device?.description }}</span>
              </div>
            </mat-checkbox>
            <mat-divider class="schedule__device-divider"></mat-divider>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #deviceListTemplate>
        <div class="schedule__device-searcher mat-elevation-z3">
          <mat-form-field class="schedule__input" appearance="fill">
            <span matPrefix><mat-icon class="schedule__search-icon">search</mat-icon></span>
            <input
              formControlName="search"
              matInput
              type="text"
              [placeholder]="'live.scheduler.searchDevices' | translate"
            />
          </mat-form-field>
          <div class="schedule__device-container" *ngFor="let device of filteredDevices | async">
            <span class="schedule__device-name">{{ device?.name }}</span>
            <span class="schedule__device-description">{{ device?.description }}</span>
            <mat-divider></mat-divider>
          </div>
        </div>
      </ng-template>
    </section>
    <section class="schedule__settings">
      <div class="schedule__settings-header">
        <div class="schedule__settings-title">
          <mat-icon class="schedule__schedule-icon" svgIcon="schedule"></mat-icon>
          <span>
            {{ 'live.scheduler.schedule' | translate }}
          </span>
        </div>
        <span class="schedule__settings-total">
          {{
            totalDays === 0
              ? ('live.scheduler.none' | translate)
              : totalDays === 1
              ? ('live.scheduler.activeDay' | translate)
              : ('live.scheduler.activeDays' | translate: {value: totalDays})
          }}
        </span>
      </div>
      <div class="schedule__settings-container mat-elevation-z3">
        <div class="schedule__settings-metadata">
          <span class="schedule__settings-card-title"> {{ 'common.settings' | translate }} </span>
          <mat-form-field class="schedule__input" appearance="fill">
            <input formControlName="name" matInput type="text" />
          </mat-form-field>
          <mat-form-field class="schedule__input" appearance="fill">
            <mat-select formControlName="scheduleExpressionTimezone">
              <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.name">
                {{ timeZone.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            class="schedule__input schedule__input--date"
            *ngIf="scheduleForm.controls.type.value === ScheduleType.ONEOFF"
            appearance="fill"
          >
            <mat-label>{{ 'live.scheduler.startDate' | translate }} </mat-label>
            <input
              formControlName="oneOffStartDate"
              [matDatepickerFilter]="dateFilter"
              matInput
              [min]="minDate"
              [max]="maxDate"
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div class="schedule__settings-item">
            <span class="schedule__settings-card-title"> {{ 'live.scheduler.type' | translate }} </span>
            <mat-radio-group class="schedule__settings-radio" formControlName="type">
              <mat-radio-button (change)="typeChanged($event)" [value]="type.value" *ngFor="let type of types">
                {{ type.name }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="schedule__settings-item">
            <span class="schedule__settings-card-title"> {{ 'live.scheduler.mode' | translate }} </span>
            <mat-radio-group
              (change)="changeMode($event.value)"
              class="schedule__settings-radio"
              formControlName="mode"
            >
              <mat-radio-button [value]="mode.value" [disabled]="mode?.disabled" *ngFor="let mode of modes">
                {{ mode.title }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="schedule__settings-item" *ngIf="scheduleForm.controls.mode.value === scheduleMode.SNAPSHOT">
            <span class="schedule__settings-card-title">{{ 'live.scheduler.frequency' | translate }}</span>
            <mat-form-field class="schedule__frecuency-input" appearance="fill">
              <span matPrefix>
                <span>{{ 'live.scheduler.every' | translate }}</span>
                <input
                  class="schedule__frecuency-value"
                  formControlName="imageSnapshotFrecuency"
                  [includeZero]="true"
                  numbersOnly
                  matInput
                  type="number"
                />
              </span>
              <mat-select formControlName="imageSnapshotFrecuencyUnits">
                <mat-option *ngFor="let imageSnapshot of imageSnapshotFrecuencyUnits" [value]="imageSnapshot.value">
                  {{ imageSnapshot.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="schedule__settings-time-container">
          <span class="schedule__settings-card-title"> {{ 'live.scheduler.schedule' | translate }} </span>
          <section formGroupName="schedules" class="schedule__settings-time">
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Monday"
              [day]="'common.monday' | translate"
              [date]="oneOffDays['monday']"
              [isDisabled]="!!oneOffDays['monday'] && oneOffDays['monday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Monday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Monday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Tuesday"
              [day]="'common.tuesday' | translate"
              [date]="oneOffDays['tuesday']"
              [isDisabled]="!!oneOffDays['tuesday'] && oneOffDays['tuesday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Tuesday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Tuesday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Wednesday"
              [day]="'common.wednesday' | translate"
              [date]="oneOffDays['wednesday']"
              [isDisabled]="!!oneOffDays['wednesday'] && oneOffDays['wednesday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Wednesday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Wednesday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Thursday"
              [day]="'common.thursday' | translate"
              [date]="oneOffDays['thursday']"
              [isDisabled]="!!oneOffDays['thursday'] && oneOffDays['thursday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Thursday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Thursday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Friday"
              [day]="'common.friday' | translate"
              [date]="oneOffDays['friday']"
              [isDisabled]="!!oneOffDays['friday'] && oneOffDays['friday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Friday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Friday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Saturday"
              [day]="'common.saturday' | translate"
              [date]="oneOffDays['saturday']"
              [isDisabled]="!!oneOffDays['saturday'] && oneOffDays['saturday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Saturday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Saturday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
            <unleash-scheduler-time-selector
              [isAdmin]="isAdmin"
              [formControlName]="days.Sunday"
              [day]="'common.sunday' | translate"
              [date]="oneOffDays['sunday']"
              [isDisabled]="!!oneOffDays['sunday'] && oneOffDays['sunday'] < currentDate"
              [existentSelections]="incomingSchedules[days.Sunday]"
              [scheduleExpressionTimezone]="scheduleForm.controls.scheduleExpressionTimezone.value"
              [hasError]="scheduleForm.controls.schedules.get(days.Sunday + '')?.errors?.invalidDate && isAdmin"
              [type]="scheduleForm.controls.type.value"
            ></unleash-scheduler-time-selector>
          </section>
        </div>
      </div>
      <div class="schedule__settings-header no-space">
        <div class="schedule__settings-title">
          <mat-icon class="schedule__schedule-icon" svgIcon="analysis"></mat-icon>
          <span>
            {{ 'live.scheduler.aiApps' | translate }}
          </span>
        </div>
        <span class="schedule__settings-total">
          {{
            scheduleForm.controls.addons.value?.length === 0
              ? ('live.scheduler.none' | translate)
              : scheduleForm.controls.addons.value?.length + ' ' + ('live.scheduler.active' | translate)
          }}
        </span>
      </div>
      <div class="schedule__settings-container mat-elevation-z3">
        <unleash-scheduler-ai-apps
          formControlName="addons"
          [isAdmin]="isAdmin"
          [addons]="addons"
        ></unleash-scheduler-ai-apps>
      </div>
    </section>
  </section>
</div>
<div *ngIf="isAdmin" class="schedule__footer mat-elevation-z1">
  <button [disabled]="isExecutingAction" mat-button color="primary" (click)="emitGoToPrincipalPage()">
    {{ 'common.cancel' | translate }}
  </button>
  <button
    *ngIf="isEditMode; else createScheduleTemplate"
    [disabled]="isExecutingAction"
    [mtxTooltip]="scheduleForm.invalid ? tooltipTpl : ''"
    mtxTooltipClass="schedule-tooltip"
    class="schedule__footer-button"
    mat-button
    (click)="emitEditSchedule()"
  >
    {{ 'live.scheduler.saveSchedule' | translate }}
    <unleash-spinner *ngIf="isExecutingAction" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
  </button>
  <ng-template #createScheduleTemplate>
    <button
      [mtxTooltip]="scheduleForm.invalid ? tooltipTpl : ''"
      mtxTooltipClass="schedule-tooltip"
      [disabled]="isExecutingAction"
      class="schedule__footer-button"
      mat-button
      (click)="emitCreateSchedule()"
    >
      {{ 'live.scheduler.createSchedule' | translate }}
      <unleash-spinner *ngIf="isExecutingAction" diameter="20" borderWidth="2.1" color="#111"></unleash-spinner>
    </button>
  </ng-template>
</div>
<ng-template #tooltipTpl>
  <div class="tooltip">
    <span>{{ 'live.scheduler.tooltip' | translate }}</span>
    <ul class="tooltip__list">
      <li *ngIf="scheduleForm.controls.devices.hasError('required')">{{ 'live.scheduler.devices' | translate }}</li>
      <li *ngIf="scheduleForm.controls.schedules?.errors?.error">{{ 'live.scheduler.schedule' | translate }}</li>
      <li
        *ngIf="
          scheduleForm.controls.schedules.get(days.Monday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Tuesday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Wednesday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Thursday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Friday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Saturday + '')?.errors?.invalidDate ||
          scheduleForm.controls.schedules.get(days.Sunday + '')?.errors?.invalidDate
        "
      >
        {{ 'live.scheduler.invalidTimeRanges' | translate }}
      </li>
    </ul>
  </div>
</ng-template>

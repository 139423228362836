export type ACL = string[];

export interface Role {
  id?: string;
  pk?: string;
  sk?: string;
  companyId?: string;
  ownerId?: string;
  name: string;
  acl?: ACL;
  createdAt?: string;
  updatedAt?: string;
}

export interface Permission {
  id: string;
  name: string;
  apiName: string;
  action: string;
  description?: string;
  groupName: string;
  createdAt: string;
  updatedAt: string;
}

export enum DefaultRoleId {
  UL_ADMIN = 'UlAdmin',
  ADMIN = 'admin',
  CONTRIBUTOR = 'contributor',
  VIEWER = 'viewer'
}

export enum StaticRoleCompanyId {
  DEFAULT = 'default'
}

export interface RolePkSk {
  pk: string;
  sk: string;
}

export interface RoleRequestParams {
  name: string;
  acl?: ACL;
}

export interface RoleModel extends RoleRequestParams {
  ownerId: string;
}

import {createReducer, on} from '@ngrx/store';
import * as RolesManagementActions from './roles-management.actions';
import {initialRolesManagementState} from './roles-management.state';
import {v4 as uuidv4} from 'uuid';

export const rolesManagementReducer = createReducer(
  initialRolesManagementState,

  on(RolesManagementActions.loadRoles, state => ({
    ...state,
    isLoading: true,
    error: null
  })),

  on(RolesManagementActions.loadRolesSuccess, (state, {roles}) => ({
    ...state,
    roles,
    updatedRoleIds: [],
    isLoading: false
  })),

  on(RolesManagementActions.loadRolesFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(RolesManagementActions.loadPermissions, state => ({
    ...state,
    isLoading: true,
    error: null
  })),

  on(RolesManagementActions.loadPermissionsSuccess, (state, {permissions}) => ({
    ...state,
    permissions,
    isLoading: false
  })),

  on(RolesManagementActions.loadPermissionsFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(RolesManagementActions.createRole, state => ({
    ...state,
    isLoading: true,
    error: null
  })),

  on(RolesManagementActions.createRoleSuccess, (state, {role, tempRoleId}) => ({
    ...state,
    roles: [...state.roles.filter(r => r.id !== tempRoleId), role],
    updatedRoleIds: state.updatedRoleIds.filter(id => id !== tempRoleId),
    isLoading: false
  })),

  on(RolesManagementActions.createRoleFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(RolesManagementActions.addRole, (state, {role}) => {
    const newRoleId = uuidv4();
    return {
      ...state,
      roles: [
        ...state.roles,
        {
          id: newRoleId,
          name: role.name,
          acl: []
        }
      ],
      updatedRoleIds: [...state.updatedRoleIds, newRoleId]
    };
  }),

  on(RolesManagementActions.updateRole, state => ({
    ...state,
    isLoading: true,
    error: null
  })),

  on(RolesManagementActions.updateRoleSuccess, (state, {roleId, role}) => ({
    ...state,
    roles: state.roles.map(r => (r.id === roleId ? {...r, ...role} : r)),
    updatedRoleIds: state.updatedRoleIds.filter(id => id !== roleId),
    isLoading: false
  })),

  on(RolesManagementActions.updateRoleFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(RolesManagementActions.updateLocalRolePermissions, (state, {roleId, permissionKey, isAdd}) => {
    const role = state.roles.find(r => r.id === roleId);
    if (!role) return state;

    const currentAcl = role.acl || [];
    const newAcl = isAdd ? [...currentAcl, permissionKey] : currentAcl.filter(p => p !== permissionKey);

    // Only mark as updated if the ACL actually changed
    const aclChanged = isAdd ? !currentAcl.includes(permissionKey) : currentAcl.includes(permissionKey);

    return {
      ...state,
      roles: state.roles.map(r =>
        r.id === roleId
          ? {
              ...r,
              acl: newAcl
            }
          : r
      ),
      updatedRoleIds: aclChanged
        ? state.updatedRoleIds.includes(roleId)
          ? state.updatedRoleIds
          : [...state.updatedRoleIds, roleId]
        : state.updatedRoleIds
    };
  }),

  on(RolesManagementActions.deleteRole, (state, {roleId}) => ({
    ...state,
    deletedRoleIds: [...state.deletedRoleIds, roleId],
    isLoading: true,
    error: null
  })),

  on(RolesManagementActions.deleteRoleSuccess, (state, {roleId}) => ({
    ...state,
    roles: state.roles.filter(role => role.id !== roleId),
    deletedRoleIds: state.deletedRoleIds.filter(id => id !== roleId),
    updatedRoleIds: state.updatedRoleIds.filter(id => id !== roleId),
    isLoading: false
  })),

  on(RolesManagementActions.deleteRoleFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  })),

  on(RolesManagementActions.deleteLocalRole, (state, {roleId}) => {
    const role = state.roles.find(r => r.id === roleId);
    return {
      ...state,
      roles: state.roles.filter(r => r.id !== roleId),
      updatedRoleIds: state.updatedRoleIds.filter(id => id !== roleId),
      // If the role had pk/sk, it was saved before, so track it for deletion
      deletedRoleIds: role?.pk && role?.sk ? [...state.deletedRoleIds, roleId] : state.deletedRoleIds
    };
  }),

  on(RolesManagementActions.saveChanges, state => ({
    ...state,
    isLoading: true
  })),

  on(RolesManagementActions.saveChangesSuccess, state => ({
    ...state,
    updatedRoleIds: [],
    isLoading: false
  })),

  on(RolesManagementActions.saveChangesFailure, (state, {error}) => ({
    ...state,
    error,
    isLoading: false
  }))
);

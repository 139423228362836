<div
  class="notification-card"
  [ngClass]="{
    'notification-card--is-read': notification.isRead,
    'normal-card': layout !== notificationLayout.grow
  }"
  (click)="emitReadNotification()"
>
  <div class="notification-content-divider">
    <ng-container *ngTemplateOutlet="icon"></ng-container>
    <div class="notification-wrapper">
      <ng-container *ngIf="layout !== notificationLayout.inProgress; else inProgressTemplate">
        <ng-container *ngTemplateOutlet="normalTemplate"></ng-container>
      </ng-container>
    </div>
    <div class="notification-card__action" *ngIf="!hasCloseButton && layout !== notificationLayout.inProgress">
      <mat-icon
        (click)="emitDeleteNotification(); $event.preventDefault(); $event.stopPropagation()"
        class="notification-card__close hover-active"
        [matTooltip]="'common.delete' | translate"
        >close</mat-icon
      >
    </div>
  </div>
</div>

<ng-template #normalTemplate>
  <div class="notification-header">
    <div class="notification-title-container">
      <div data-test-id="notification-title-text" class="notification-title__text">
        {{ notification.title }}
      </div>
      <div *ngIf="hasCloseButton" class="notification-title__close">
        <button class="notification-card__close-button" mat-icon-button (click)="emitClose()">
          <mat-icon class="notification-card__close">close</mat-icon>
        </button>
      </div>
    </div>

    <div
      class="notification-title__time"
      *ngIf="layout !== notificationLayout.grow"
      [title]="notification.createdAt | date: 'h:mm a, d/MM/yyyy'"
    >
      <ng-container
        *ngIf="
          isOnHoverCard && notification.updatedAt && notification.createdAt !== notification.updatedAt;
          else timestampCardTemplate
        "
      >
        Task duration:
        {{ notification.updatedAt | uDuration: 'completeFormat':notification.createdAt }}
      </ng-container>
      <ng-template #timestampCardTemplate>
        {{ notification.updatedAt || notification.createdAt | uTimeAgo | async }}
      </ng-template>
    </div>
  </div>
  <div class="notification-body">
    <div
      *ngIf="notification.message"
      class="notification-body__content"
      [ngClass]="{grow: layout === notificationLayout.grow}"
    >
      {{ notification.message }}
    </div>
  </div>

  <div *ngIf="notification.actionUrl && notification.action" class="notification-footer notification-footer--end">
    <ng-container
      [ngTemplateOutlet]="notificationButtons"
      [ngTemplateOutletContext]="{action: notification.action, actionUrl: notification.actionUrl}"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #inProgressTemplate>
  <ng-container *ngIf="layout !== notificationLayout.grow">
    <div class="notification-header">
      <div class="notification-title-container">
        <div data-test-id="notification-title-text" class="notification-title__text">
          {{ notification.title }}
        </div>
      </div>
      <div class="notification-title__time" [title]="notification.createdAt | date: 'h:mm a, dd.MM.YYYY' | lowercase">
        {{ notification.createdAt | date: 'h:mm a, dd.MM.YYYY' | lowercase }}
      </div>
    </div>
  </ng-container>
  <div class="notification-body">
    <div *ngIf="notification.message" class="notification-body" [ngClass]="{grow: layout === notificationLayout.grow}">
      {{ notification.message }}
    </div>
  </div>
  <div class="notification-footer notification-footer--end" [ngClass]="'notification-footer--column-start'">
    <ng-container *ngIf="notification.progress > 0; else isIndeterminateTemplate">
      <mat-progress-bar class="progress-bar" mode="determinate" [value]="notification.progress"></mat-progress-bar>
      <div class="progress-bar__description">{{ notification.progress | number: '1.2-2' }}%</div>
    </ng-container>
    <ng-template #isIndeterminateTemplate>
      <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
      <div class="progress-bar__description">Started {{ notification.createdAt | uTimeAgo | async }}</div>
    </ng-template>
    <div *ngIf="notification.actionUrl && notification.action" class="notification-buttons">
      <ng-container
        [ngTemplateOutlet]="notificationButtons"
        [ngTemplateOutletContext]="{action: notification.action, actionUrl: notification.actionUrl}"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #icon>
  <mat-icon
    class="notification-subtitle__icon"
    [ngClass]="{
      'mat-primary': notification.level === notificationLevel.info,
      'mat-error': notification.level === notificationLevel.error,
      'mat-warn': notification.level === notificationLevel.warning,
      'mat-success': notification.level === notificationLevel.success,
      'notification-card__icon--big': layout === notificationLayout.grow,
      'notification-card__icon--small': layout !== notificationLayout.grow
    }"
  >
    {{ notification.icon }}
  </mat-icon>
</ng-template>

<ng-template #notificationButtons let-actionUrl="actionUrl" let-action="action">
  <ng-container *ngFor="let url of actionUrl; let i = index">
    <ng-container *ngIf="url">
      <ng-container *ngIf="url.startsWith('beacon://')">
        <a (click)="emitReadNotification(); closeSidebar(action[i]); goToContactUs()" mat-button color="primary">
          {{ action[i] }}
        </a>
      </ng-container>
      <ng-container *ngIf="url.startsWith('/')">
        <ng-container [ngSwitch]="action[i]">
          <ng-container *ngSwitchCase="notificationActions.ViewFiles">
            <ng-container *uaHasPermission="aclPermissions.LibraryApiRead">
              <ng-container
                [ngTemplateOutlet]="notificationButton"
                [ngTemplateOutletContext]="{url: url, action: action[i]}"
              ></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="notificationActions.ViewInAtlas">
            <ng-container *uaHasPermission="aclPermissions.AtlasApiRead">
              <ng-container
                [ngTemplateOutlet]="notificationButton"
                [ngTemplateOutletContext]="{url: url, action: action[i]}"
              ></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="notificationActions.ViewStream">
            <ng-container *uaHasPermission="aclPermissions.LivestreamApiStreamRead">
              <ng-container
                [ngTemplateOutlet]="notificationButton"
                [ngTemplateOutletContext]="{url: url, action: action[i]}"
              ></ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container
              [ngTemplateOutlet]="notificationButton"
              [ngTemplateOutletContext]="{url: url, action: action[i]}"
            ></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #notificationButton let-url="url" let-action="action">
          <a
            [routerLink]="url | uRouterLinkUrl"
            [queryParams]="url | uRouterLinkQueryParams"
            (click)="emitReadNotification(); closeSidebar(action)"
            mat-button
            color="primary"
          >
            {{ action }}
          </a>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="url.startsWith('http')">
        <a
          [href]="url"
          download
          target="_blank"
          (click)="emitReadNotification(); closeSidebar(action[i])"
          mat-button
          color="primary"
        >
          {{ action[i] }}
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<form [formGroup]="form" (submit)="clickSignInButton()" class="form">
  <mat-form-field appearance="fill" class="email">
    <mat-label>{{ 'common.email' | translate }}</mat-label>
    <input matInput formControlName="email" autofocus="true" autocomplete="email" data-test-id="emailInput" />
    <mat-error *ngIf="(form.controls.email.invalid && form.controls.email.touched) || form.controls.email.dirty"
      >{{ 'common.email' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>

  <mat-form-field appearance="fill" class="password">
    <mat-label>{{ 'common.password' | translate }}</mat-label>
    <input autocomplete="current-password" matInput type="password" formControlName="password" data-test-id="passwordInput" />
    <mat-error
      *ngIf="form.controls.password.invalid && (form.controls.password.touched || form.controls.password.dirty)"
      >{{ 'common.password' | translate }} {{ 'common.errors.isRequired' | translate }}</mat-error
    >
  </mat-form-field>

  <a class="forgot-password" data-test-id="forgotPasswordLink" (click)="clickForgotYourPasswordButton()">{{
    'auth.signIn.forgot' | translate
  }}</a>

  <button
    class="form__item sign-in-button"
    mat-raised-button
    data-test-id="signInButton"
    color="primary"
    [appLadda]="inProgress"
    [disabled]="inProgress"
  >
    {{ 'common.signIn' | translate }}
  </button>

  <unleash-divider class="form__item">OR</unleash-divider>

  <div class="form__item social-buttons">
    <unleash-social-button
      class="social-buttons__item"
      imageSrc="/assets/icons/auth/logo-entra.svg"
      textContent="SIGN IN WITH ENTRA ID"
      (buttonClick)="signInWithEntra()"
    ></unleash-social-button>
    <unleash-social-button
      class="social-buttons__item"
      imageSrc="/assets/icons/auth/logo-okta.svg"
      textContent="SIGN IN WITH OKTA"
      (buttonClick)="signInWithOkta()"
    ></unleash-social-button>
  </div>
</form>

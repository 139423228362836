import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {ConfirmDeleteDialog} from '@app/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import * as FileSaver from 'file-saver';
import {Map} from 'leaflet';
import {BehaviorSubject, combineLatest, map, Observable, of, Subject, Subscription} from 'rxjs';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {PermissionService} from '../../../../core/services/permission.service';
import {MissionImportService} from '../../../../flights/services/mission-import.service';
import {MissionApiService} from '../../../services/mission-api.service';
import {
  Mission,
  MissionRoutePoint,
  MissionSettingsService,
  SELECTED_WAYPOINT
} from '../../../services/mission-settings.service';
import {RouteCreator} from '../route-creator';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {MissionType} from '@app/atlas/model/mission.model';
import {MissionManagerService} from '@app/atlas/services/mission-manager.service';
import {MatDrawer, MatDrawerContainer} from '@angular/material/sidenav';
import {TranslateService} from '@ngx-translate/core';

declare const L; // leaflet global

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'ua-option-sidemenu',
  templateUrl: './option-sidemenu.component.html',
  styleUrls: ['./option-sidemenu.component.scss']
})
export class OptionSidemenuComponent implements AfterViewInit, OnDestroy {
  @Input() public map: Map;
  @Input() public isLayersControlSideBarOpen: boolean = true;
  @Input('selectedWaypoint') public set setSelectedWaypoint(selectedWaypoint: {
    waypoint: MissionRoutePoint;
    index: number;
    isLastIndex: boolean;
  }) {
    if (selectedWaypoint) {
      this.selectedWaypoint = selectedWaypoint.waypoint;
      this.selectedWaypointIndex = selectedWaypoint.index;
      this.isLastIndex = selectedWaypoint.isLastIndex;
      this.missionSettingsService.selectRoutePoint(this.selectedWaypointIndex);
      this.drawer.open();
      return;
    }
    this.selectedWaypoint = null;
    this.selectedWaypointIndex = 0;
    this.isLastIndex = false;
    this.drawer?.close();
  }
  @Output() public missionDrawerClosed: EventEmitter<void> = new EventEmitter();
  @Output() public selectedPointIndex: EventEmitter<number> = new EventEmitter();
  @ViewChild('drawerContainer', {read: MatDrawerContainer, static: false})
  public drawer: MatDrawer;
  public selectedWaypoint: MissionRoutePoint = null;
  public selectedWaypointIndex: number = SELECTED_WAYPOINT.DEFAULT_WAYPOINT;
  public isLastIndex: boolean = false;
  // eslint-disable-next-line rxjs/finnish
  public missionName: BehaviorSubject<string> = this.missionManagerService.missionName;
  public isImporting$: Subject<boolean> = new Subject();
  // eslint-disable-next-line rxjs/finnish
  public importError$: Subject<string> = new Subject();

  public route: MissionRoutePoint[];
  public saving: boolean = false;
  public userMissions: Mission[] = [];
  public isSmartInspect: boolean = false;

  public totalDistance$: Observable<number>;
  public totalTime$: Observable<number>;
  private currentMission: Mission;
  private routeSub: Subscription;
  private missionSub: Subscription;
  private listMissionsSub: Subscription;
  private routeCreator: RouteCreator;
  private selectedMarkerSub: Subscription;
  public aclPermissions = AclPermissions;

  constructor(
    public missionSettingsService: MissionSettingsService,
    public missionApiService: MissionApiService,
    public dialog: MatDialog,
    private permissions: PermissionService,
    private missionImportService: MissionImportService,
    private snackBar: MatSnackBar,
    private missionManagerService: MissionManagerService,
    private translateService: TranslateService
  ) {
    this.routeSub = this.missionSettingsService.route.subscribe(route => {
      this.route = route;
    });
    this.missionSub = this.missionSettingsService.currentMission$
      .pipe(
        tap(mission => (this.currentMission = mission)),
        filter(Boolean)
      )
      .subscribe(mission => {
        this.route = mission.route;
        this.isSmartInspect = mission.isSmartInspect;
      });

    this.totalTime$ = combineLatest([
      this.missionSettingsService.settings$,
      this.missionSettingsService.totalDistance$
    ]).pipe(
      map(([settings, distance]) => {
        // eslint-disable-next-line no-magic-numbers
        const goingUpAndDownTime = (settings.altitude / 2) * 2;
        const takeoffAndLandingTime = 15;
        const flyingPathTime = distance / settings.speed;
        return Math.round(flyingPathTime + goingUpAndDownTime + takeoffAndLandingTime);
      })
    );
    this.totalDistance$ = this.missionSettingsService.totalDistance$;

    //listen selected markers to select in view mode
    this.selectedMarkerSub = this.missionSettingsService.selectedMarker.subscribe(selectedMarker => {
      if (selectedMarker) {
        this.selectedPointIndex.emit((selectedMarker.options as any).routeIndex);
      }
    });
  }

  public ngAfterViewInit() {
    this.listMissionsSub = this.missionManagerService.missions$
      .pipe(
        take(1),
        switchMap(missions => {
          if (missions.length > 0) {
            this.userMissions = missions;
            return of(missions);
          }
          return this.missionApiService.listMissions().pipe(
            tap(missions => {
              this.missionManagerService.setMissions(missions);
              this.userMissions = missions;
            })
          );
        })
      )
      .subscribe();

    this.initializeSidebar();

    this.importError$.pipe(filter(Boolean)).subscribe(msg => {
      this.snackBar.open(msg, null, {
        duration: 5000
      } as MatSnackBarConfig);
      return;
    });
  }

  public ngOnDestroy(): void {
    this.missionSettingsService.editSave();
  }

  private initializeSidebar() {
    this.routeCreator = new RouteCreator(this.map, this.missionSettingsService, this.snackBar, this.translateService);
    this.missionSettingsService.addRouteCreator(this.routeCreator);
  }

  public loadMission(mission: Mission) {
    this.missionSettingsService.setMission(mission);
    this.missionManagerService.setMissionName(mission.name);
  }

  public remove(mission: Mission) {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    const message = `${mission.name}`;
    const dialogRef = this.dialog.open(ConfirmDeleteDialog, {
      data: {items: [message]},
      width: '80vw',
      maxWidth: '800px'
    } as MatDialogConfig);
    dialogRef.afterClosed().subscribe((yesRemove: boolean) => {
      if (yesRemove) {
        return this.missionApiService.removeMission(mission.id).subscribe(() => {
          const ind = this.userMissions.findIndex(m => m.id === mission.id);
          this.missionManagerService.removeMission(mission);
          this.userMissions.splice(ind, 1);
          const isCurrentlyLoaded = !!this.currentMission && this.currentMission.id === mission.id;
          if (isCurrentlyLoaded) {
            this.missionSettingsService.setMission(undefined);
            this.missionSettingsService.clearRoute();
          }
        }, console.error);
      }
    });
  }

  public saveToCloud() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    if (!this.missionName.value) {
      return;
    }
    this.saving = true;
    const isCreatingNewMission =
      !this.currentMission || (this.currentMission?.isImported && !this.currentMission?.createdAt);

    if (!this.currentMission) {
      // drawn mission
      this.currentMission = new Mission(
        this.missionName.value,
        this.route,
        !!this.currentMission?.isImported,
        this.isSmartInspect
      );
    }

    if (this.isSmartInspect) {
      this.currentMission.type = MissionType.SMART_INSPECT;
      this.currentMission.isSmartInspect = this.isSmartInspect;
    }

    if (isCreatingNewMission) {
      this.missionApiService.createMission({...this.currentMission, name: this.missionName.value}).subscribe(
        mission => {
          this.missionSettingsService.setMission(mission);
          this.userMissions.unshift(mission);
          const missionWithTimeAndDistance = this.missionManagerService.calculateTimeAndDistance(mission);
          this.missionManagerService.addNewMission(missionWithTimeAndDistance);
        },
        console.error,
        () => {
          this.saving = false;
        }
      );
    } else {
      this.missionApiService
        .updateMission(this.currentMission.id, {
          name: this.missionName.value,
          isSmartInspect: this.isSmartInspect,
          route: this.route
        })
        .subscribe(
          mission => {
            // update route in the current mission
            this.currentMission.route = this.route;
            this.missionSettingsService.setMission(this.currentMission);
          },
          console.error,
          () => {
            this.saving = false;
          }
        );
    }
  }

  public saveToFile() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    const file = new Blob([JSON.stringify(this.route)], {type: 'application/json'});
    const name = `${this.missionName.value || 'waypoints'}-${new Date().getTime()}.json`;
    FileSaver.saveAs(file, name);
  }

  public saveSettings(settings: MissionRoutePoint) {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.saveSettings(settings);
  }

  public savePointSettings(settings: MissionRoutePoint) {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.saveSelectedMarkerSettings(settings);
  }

  public newRoute() {
    // if (!!this.route && this.route.length > 0) return;
    this.missionSettingsService.newRoute();
  }

  public clearRoute() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    // if (!!this.route && this.route.length > 0) return;
    this.missionSettingsService.clearRoute();
  }

  public editRoute() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.editRoute();
  }

  public confirmEdit() {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.missionSettingsService.editSave();
  }

  public importMission(event) {
    if (!this.permissions.canUseMissionPlanner()) {
      return;
    }
    this.importError$.next(null);
    const files = event.target.files;
    const file = files[0];
    this.isImporting$.next(true);

    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const mission: Mission = await this.missionImportService.importFile(file, fileReader.result as string);
        if (mission.type === MissionType.CORRIDOR) {
          console.info('Created Corridor mission', mission);
          this.userMissions.unshift(mission);
        }
        this.missionSettingsService.setMission(mission);
        this.missionManagerService.setMissionName(mission.name);
      } catch (e) {
        this.isImporting$.next(false);
        const reason = e.message;
        const errMsg = `Import error: ${reason}`;
        this.importError$.next(errMsg);
      }
      this.isImporting$.next(false);
    };
    fileReader.readAsText(file);
  }

  public closeDrawer(isFromEdit: boolean = false): void {
    this.drawer.close();
    isFromEdit
      ? this.missionSettingsService.deselectRouteEditPoint(this.selectedWaypointIndex)
      : this.missionSettingsService.deselectRoutePoint(this.selectedWaypointIndex);
    this.missionSettingsService.deselectMarker();
    this.missionSettingsService.selectedWaypointIndex = SELECTED_WAYPOINT.NO_WAYPOINT_SELECTED;
    this.missionDrawerClosed.emit();
  }
}

import {Injectable} from '@angular/core';
import {JobsFacadeService} from './jobs-facade.service';
import {BehaviorSubject, Observable, filter, map, shareReplay} from 'rxjs';
import {Job} from '../models/jobs.models';
import {TeamRole} from '@app/profile/models/team.model';
import {UserStoreFacadeService} from '@app/core/services/user-store-facade.service';

@Injectable({
  providedIn: 'root'
})
export class JobsPageService {
  public inProgressJobs$: Observable<Job[]> = this.jobsFacadeService.inProgressJobs$;
  public completeJobs$: Observable<Job[]> = this.jobsFacadeService.completeJobs$;
  public failedJobs$: Observable<Job[]> = this.jobsFacadeService.failedJobs$;
  public draftJobs$: Observable<Job[]> = this.jobsFacadeService.draftJobs$;
  public publishedJobs$: Observable<Job[]> = this.jobsFacadeService.publishedJobs$;
  public isLoadingJobs$: Observable<boolean> = this.jobsFacadeService.isLoadingJobs$;
  public hasJobs$: Observable<boolean> = this.jobsFacadeService.hasJobs$;
  public currentTab: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> =
    this.jobsFacadeService.teamMembers$;
  public role$: Observable<TeamRole> = this.userStoreFacadeService.currentRole$.pipe(shareReplay(1));
  public isAdmin$: Observable<boolean> = this.userStoreFacadeService.currentRole$.pipe(
    filter(role => !!role),
    map(role => role === TeamRole.admin),
    shareReplay(1)
  );
  public isContributor$: Observable<boolean> = this.userStoreFacadeService.currentRole$.pipe(
    filter(role => !!role),
    map(role => role === TeamRole.contributor),
    shareReplay(1)
  );
  public currentUserId$: Observable<string> = this.userStoreFacadeService.currentUser$.pipe(
    map(user => user?.id),
    shareReplay(1)
  );

  constructor(private jobsFacadeService: JobsFacadeService, private userStoreFacadeService: UserStoreFacadeService) {}

  public setCurrentTab(currentTab: number): void {
    this.currentTab.next(currentTab);
  }

  public listJobs(): void {
    this.jobsFacadeService.listJobs();
  }

  public createNewJob(): void {
    this.jobsFacadeService.createNewJob();
  }
}

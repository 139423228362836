import {LatLngBoundsExpression} from 'leaflet';
import {AnnotationsModel} from './library-list-child-ids-response.model';
import {LibraryMimeType, LIBRARY_ITEM_TYPE_MODEL} from './mime-type.model';

export interface LibraryItem {
  teamId: string;
  location: string;
  createdAt: number;
  id: string;
  metadata?: LibraryMetadata;
  name: string;
  ownerId: string;
  parentId: string;
  s3Path: string;
  updatedAt: number;
  childItemsNumber: number;
  type: LIBRARY_ITEM_TYPE_MODEL;
  mimeType: LibraryMimeType;
  isSelected?: boolean;
  deviceId?: string;
  isArchive?: boolean;
  annotations?: AnnotationsModel;
  thumb_path?: string;
  tags?: string;
  isVisibleInSidebar?: boolean;
  uploadedAt?: number;
}
export interface LibrarySubfolderItem {
  id: string;
  name: string;
  parentId: string;
  location: string;
}

export interface SaveFolder {
  id: string;
  mimeType: string;
  name: string;
  parentId: string;
  createdAt?: number;
}

export interface AggregationFolders {
  archiveId: string;
  resultsId: string;
}

export interface LibraryMetadata {
  size?: number;
  bounds?: LatLngBoundsExpression;
  gpslat?: number;
  gpslng?: number;
  gpslatref?: 'N' | 'S';
  gpslngref?: 'E' | 'W';
  make?: string;
  model?: string;
  camfocallength?: number;
  camaperturevalue?: number;
  addonId?: string;
  height?: number;
  width?: number;
  isPanoramic?: boolean;
  childItemsNumber?: number;
  aggregationFolders?: AggregationFolders;
  deviceId?: string;
  deviceName?: string;
  droneSerialNumber?: string;
  pilotId?: string;
  pilotName?: string;
  teamId?: string;
  teamName?: string;
  gimbalyawdegree?: number;
  gimbalrolldegree?: number;
  gpsDatum?: string;
  gpsStatus?: string;
  lrfTargetLat?: number;
  lrfTargetLon?: number;
  lrfTargetAlt?: number;
  lrfTargetDist?: number;
  camShutterSpeed?: any;
  camExposureBiasValue?: any;
  digitalZoom?: string;
  createdate?: number;
  camfnumber?: string;
  gpsalt?: number;
  camera?: string;
  exifimagewidth?: number;
  exifimageheight?: number;
  type?: string;
  camfocallengthin35mmformat?: number;
  gimbalpitchdegree?: number;
  lastmodified?: number;
  gpsaltref?: number;

  // Special folders - Added on frontend
  specialFolderResults?: boolean;
  specialFolderArchive?: boolean;
}

export interface LibraryItemExportRequest {
  teamId: string;
  addonId: string;
  exportQuery: LibraryItemExportQuery;
}
export interface LibraryItemExportQuery {
  libraryLocation?: string;
  filterByIds?: string[];
  filterByLabelIds?: string[];
  filter?: ExportFilter;
}

export interface LibraryItemExportPdfRequest {
  addonId: string;
  teamId: string;
  exportQuery: LibraryItemExportPdfQuery;
}

export interface LibraryItemExportPdfQuery {
  filter?: ExportFilter;
  reportName: string;
  hasToIncludeTableOfContents: boolean;
  hasToIncludeStats: boolean;
  reportSummary: string;
  libraryLocation?: string;
  filterByIds: string[];
  template: string;
}

export type ExportFilter = {
  categories: ExportFilterCategories[];
  tags: string[];
};

export type ExportFilterCategories = {
  categoryId: string;
  severities?: number[];
};

export enum LibraryViewMode {
  list = 'list',
  grid = 'grid'
}

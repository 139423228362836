import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UPLOAD, UPLOAD_METHODS} from '@app/library/models/upload-types.model';
import {ALLOWED_FILE_TYPES} from '@app/config/file-types';
import {FileTypeService, UploadModuleType} from '@app/shared/services/upload/file-type.service';

@Component({
  selector: 'unleash-upload-files-menu-item',
  templateUrl: './upload-files-menu-item.component.html',
  styleUrls: ['./upload-files-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadFilesMenuItemComponent {
  @Input() public disabled: boolean = false;
  @Input() public entryType: string = UPLOAD_METHODS.FILE;
  @Input() public isUploadInProgress: boolean;

  @Output() public files: EventEmitter<UPLOAD> = new EventEmitter();

  constructor(private fileTypeService: FileTypeService) {}

  public uploadMethods: typeof UPLOAD_METHODS = UPLOAD_METHODS;
  public acceptedFormatsString: string = ALLOWED_FILE_TYPES.LIBRARY.EXTENSIONS.join(',');

  public emitFile(files: FileList, uploadType: UPLOAD_METHODS): void {
    const validFiles = this.fileTypeService.filterValidFiles(Array.from(files), UploadModuleType.LIBRARY);
    if (validFiles.length > 0) {
      const newFileList = new DataTransfer();
      validFiles.forEach(file => newFileList.items.add(file));
      this.files.emit({files: newFileList.files, uploadType});
    }
  }
}

import {MenuItemTypeEnum} from '@app/core/models/gui/menu-item-type.enum';
import {MenuItem} from './menu-item';
import {AclPermissions} from '../api/acl.model';

/** Opens a link in a new tab */
export class NewTabMenuItem extends MenuItem {
  public href: string;
  public type: MenuItemTypeEnum = MenuItemTypeEnum.newTab;

  constructor(menuParams: {
    label: string;
    imagePath: string;
    url: string;
    aclPermission?: AclPermissions;
    fragment?: string;
    queryParams?: {};
    styleClass?: string;
  }) {
    super(
      menuParams.label,
      menuParams.imagePath,
      menuParams.styleClass,
      menuParams.fragment,
      menuParams.queryParams,
      menuParams.aclPermission
    );
    this.href = menuParams.url;
  }
}

import {Addon} from '@app/store/addon/models/addon';
import {ScheduleModel} from '@app/core/models/api/schedule.model';

export interface Schedule {
  id: string;
  name: string;
  devices: any[]; // TODO verify if it is Devices array insted of ids array
  type: ScheduleType;
  scheduleDays: number[];
  aiApps: Addon[];
  isCompleted: boolean;
}

export enum ScheduleType {
  RECURRING = 'RECURRING',
  ONEOFF = 'ONEOFF'
}

export enum ScheduleMode {
  STREAM = 'STREAM',
  SNAPSHOT = 'SNAPSHOT'
}

export enum Days {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export interface ListScheduleResponse {
  items: ScheduleModel[];
  nextToken?: string;
}

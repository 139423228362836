import {gridLayer, tileLayer} from 'leaflet';
import 'leaflet.gridlayer.googlemutant';

export const atlasConfig = {
  MAX_ZOOM_LEVEL: 21,
  MIN_ZOOM_LEVEL: 3,
  MAX_NATIVE_ZOOM_LEVEL: 21,
  INITIAL_ZOOM_LEVEL: 17,
  INITIAL_MAP_CENTER: [-33.8688, 151.2093], // Sydney CBD
  FIT_BOUNDS_OPTIONS: {
    animate: false,
    maxZoom: 17 // same as INITIAL_ZOOM_LEVEL
  },
  MAX_BOUNDS: [
    [-100, 260],
    [100, -180]
  ],
  MAX_BOUNDS_FULL_SCREEN: [
    [-100, 180],
    [100, -180]
  ],
  defaultMarkerColor: '#146FF1',
  DISABLE_CLUSTERING_ZOOM_LEVEL: 17, // at this zoom level and above, clustering is always disabled
  ENABLE_CLUSTERING_ZOOM_LEVEL: 12, // at this zoom level and below, clustering is always enabled
  DISABLE_CLUSTERING_MARKERS_COUNT: 2000,
  SUPERCLUSTER_RADIUS: 80
};

export const getHybridViewLayer = () =>
  (gridLayer as any).googleMutant({
    type: 'hybrid',
    zIndex: 0,
    styles: [
      {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: 'transit',
        stylers: [{visibility: 'off'}]
      }
    ]
  });

export const getSatelliteViewLayer = () =>
  (gridLayer as any).googleMutant({
    type: 'satellite',
    zIndex: 0
  });

export const getMapViewLayer = () =>
  (gridLayer as any).googleMutant({
    type: 'roadmap',
    zIndex: 0,
    styles: [{featureType: 'poi', stylers: [{visibility: 'off'}]}]
  });

export const getMapWeatherLayer = () =>
  tileLayer(
    'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=4be2bdf5dde908ff8767bfe6ebae0c8f',
    {
      noWrap: true,
      maxNativeZoom: atlasConfig.MAX_NATIVE_ZOOM_LEVEL,
      maxZoom: atlasConfig.MAX_ZOOM_LEVEL,
      zIndex: 0
    }
  );

export const defaultMissionRouteColor = '#FFA500';

export const taskLayers = 'Task layers';

export const defaultMarkerSize = [25, 41];

export const defaultCircleIconProps = {
  color: atlasConfig.defaultMarkerColor,
  borderColor: '#fff',
  borderSize: 2,
  radius: 8
};

export enum StatusMarkerColors {
  IN_PROGRESS = '#F6B100',
  COMPLETED = '#5CB85C',
  NOT_COMPLETED = '#CE1C00'
}

export const defaultHouseMarkerColor = '#c0c0c0';

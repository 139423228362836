/**
 * Service to generate redirect URLs for authentication
 */
export class RedirectUrlService {
  /**
   * Generates a sign-in redirect URL based on the current domain
   * @returns URL with the current domain and /auth/sign-in suffix
   */
  public static getRedirectSignInUrl(): string {
    const currentDomain = this.getCurrentDomain();
    return `${currentDomain}/auth/sign-in`;
  }

  /**
   * Generates a sign-out redirect URL based on the current domain
   * @returns URL with the current domain and /auth/logout suffix
   */
  public static getRedirectSignOutUrl(): string {
    const currentDomain = this.getCurrentDomain();
    return `${currentDomain}/auth/logout`;
  }

  /**
   * Gets the current domain, safely handling environments where window is not defined
   * @returns The current domain or a default value for non-browser environments
   */
  private static getCurrentDomain(): string {
    // Check if window is defined (browser environment)
    if (typeof window !== 'undefined' && window.location) {
      return window.location.origin;
    }

    // For Cypress or other Node.js environments, return a default value
    // This could be configured from environment variables or other sources if needed
    return 'https://local.unleashlive.com:8080';
  }
}

import {GeoJSON} from 'geojson';
import {AssetProperty} from './user-model';
import {LatLngBounds, LatLngBoundsExpression} from 'leaflet';
import {UserDeviceJoined} from './user-device.model';

export interface AtlasAssetPresentable {
  isDisplaying?: boolean;
  isSelected?: boolean;
  leafletLayer?: any;
  leafletBounds: LatLngBounds;

  filename?: string;
  properties?: AssetProperty[];
  sessionId?: string;
  hidden?: boolean;
  disabled?: boolean;
  fillColor?: string;
  strokeColor?: string;
  weight?: string;
  iconSize?: number | [number, number];
  iconName: string;
  deviceIcon?: string;
}

export enum AssetType {
  FLIGHT_LOG = 'flightlog',
  GEOJSON = 'geojson',
  KML = 'kml',
  HEATMAP = 'csv-heat',
  ORTHOPHOTOMAP = 'ortho',
  DEMO = 'demo',
  DEVICE = 'device'
}

export enum ASSET_PROPERTY_NAME {
  LGAS = 'LGAs'
}

export enum ASSET_GEOMETRY_TYPE {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
  MULTI_LINE_STRING = 'MultiLineString',
  MULTI_POLYGON = 'MultiPolygon',
  GEOMETRY_COLLECTION = 'GeometryCollection'
}

export interface AtlasAssetModel extends AtlasAssetPresentable {
  id?: string;
  owner: string;
  name: string;
  type: AssetType;
  key: string;
  order?: number;
  groupName?: string;
  locationName?: string;
  locationLatLng?: [number, number];
  isPinned?: boolean;
  createdAt?: number;
  updatedAt?: number;
  bounds: LatLngBoundsExpression;
  modelId?: string;
  parentId?: string;
  dashboard?: string;
  device?: UserDeviceJoined;
  color?: string;
  customColorIndex?: number;
  isHighlighted?: boolean;
  hasState: boolean;
  geojson?: GeoJSON;
  searchResult?: any;
}

// Not needed interface
export interface AtlasGeojsonAssetModel extends AtlasAssetModel {
  geojson?: GeoJSON;
}

/* eslint-disable no-magic-numbers */
import {AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Days, ScheduleType} from '@app/live/models/schedule.model';

@Component({
  selector: 'unleash-scheduler-time-selector',
  templateUrl: './scheduler-time-selector.component.html',
  styleUrls: ['./scheduler-time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchedulerTimeSelectorComponent),
      multi: true
    }
  ]
})
export class SchedulerTimeSelectorComponent implements ControlValueAccessor, AfterViewInit {
  @Input() public day: string = null;
  @Input('date') public set setDate(date: Date) {
    this.date = date;
    this.selectedSections = this.selectedSections
      .filter(selection => !selection?.isDisabledRange)
      .map(selection => ({
        ...selection,
        startTime: this.date ? this.updateDate(selection.startTime) : selection.startTime,
        endTime: this.date ? this.updateDate(selection.endTime) : selection.endTime,
        date: this.date
      }));
    this.setFormValue();
    this.addInvalidRange(this.scheduleExpressionTimezone);
  }
  @Input('existentSelections') public set setExistSelections(selections) {
    if (selections && selections.length > 0) {
      selections.forEach(selection => {
        this.rect = document.getElementById('timeline').getBoundingClientRect();
        const clickPosition = this.getPixelOffsetForTimestamp(selection.startTime, this.rect.width);
        let endPosition = this.getPixelOffsetForTimestamp(selection.endTime, this.rect.width);
        if (selection.endTime.getHours() === 0) {
          //Adjust end position when end time is 00h00
          endPosition = this.rect.width;
        }
        const selectedSection = {
          startTime: selection.startTime,
          startCronId: selection.startCronId,
          endTime: selection.endTime,
          endCronId: selection.endCronId,
          width:
            Math.round(endPosition - clickPosition) < this.minTimeSlotWidthInPx
              ? this.minTimeSlotWidthInPx
              : endPosition - clickPosition,
          x: clickPosition,
          px: 0,
          isDraggingCorner: false,
          invalid: this.isDisabled
        };
        this.selectedSections.push(selectedSection);
      });
      this.setFormValue();
    }
  }
  @Input() public isAdmin = false;
  @Input('scheduleExpressionTimezone') public set setScheduleExpressionTimezone(scheduleExpressionTimezone: string) {
    this.scheduleExpressionTimezone = scheduleExpressionTimezone;
    this.checkInvalidDay(this.type);
    this.removeCurrentDisabledSection();
    this.addInvalidRange(this.scheduleExpressionTimezone);
  }
  @Input() public hasError = false;
  @Input('isDisabled') public set setIsDisabled(isDisabled: boolean) {
    this.setDisabledDaysAndRanges(isDisabled);
  }

  @Input('type') public set setType(type: ScheduleType) {
    this.type = type;
    this.checkInvalidDay(this.type);
  }

  public type: ScheduleType = null;
  public isDisabled = false;
  public scheduleExpressionTimezone = null;
  public overlapSectionIndex: number = -1;
  public date: Date = null;
  public resizer: (offsetX: number, endTimePosition: number, currentSection: Section) => void;
  public rect: DOMRect = null;
  public selectedSections: Section[] = [];
  public selectedIndex = -1;
  public minTimeSlotWidthInPx: number;
  public minTimeSlotHours: number;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onChange: (_: any) => void = (_: any) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onTouched: () => void = () => {};

  constructor() {
    this.setMinTimeSlot(5);
  }

  public ngAfterViewInit() {
    this.setFormValue();
  }

  public checkInvalidDay(type: ScheduleType) {
    if (type === ScheduleType.ONEOFF) {
      let currentDate = new Date();
      currentDate = new Date(currentDate.toLocaleString('en-US', {timeZone: this.scheduleExpressionTimezone}));
      this.date < currentDate && this.date.getDate() !== currentDate.getDate()
        ? this.setDisabledDaysAndRanges(true)
        : this.setDisabledDaysAndRanges(false);

      return;
    }
    this.setDisabledDaysAndRanges(false);
  }

  private setMinTimeSlot(timeInMinutes: number) {
    const oneHourInPixel = 28;
    const totalMinutesInOneHour = 60;
    this.minTimeSlotWidthInPx = (timeInMinutes * oneHourInPixel) / totalMinutesInOneHour;
    this.minTimeSlotHours = timeInMinutes / totalMinutesInOneHour;
  }

  private setDisabledDaysAndRanges(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.selectedSections = this.selectedSections.map(section => ({...section, invalid: isDisabled}));
    this.setFormValue();
  }

  public updateDate(date: Date) {
    date.setMonth(this.date.getMonth());
    date.setFullYear(this.date.getFullYear());
    date.setDate(this.date.getDate());
    return date;
  }

  public setToSpecificDay(dayOfWeek, date) {
    const currentDay = date.getDay();
    const dayDifference = (dayOfWeek - currentDay + 7) % 7;
    const targetDate = new Date(date);
    targetDate.setDate(date.getDate() + dayDifference);
    return targetDate;
  }

  public onTimelineClick(event: MouseEvent) {
    if (this.overlapSectionIndex !== -1) {
      this.overlapSectionIndex = -1;
      return;
    }
    this.rect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    let clickPosition = event.clientX - this.rect.left;
    if (this.getIndexOverlap(clickPosition, {rightLimit: 42, leftLimit: 14}) !== -1) {
      return;
    }
    if (this.rect.width - clickPosition <= this.minTimeSlotWidthInPx) {
      clickPosition = this.rect.width - this.minTimeSlotWidthInPx;
    }

    let clickPercentage = (clickPosition / this.rect.width) * 100;
    const formattedTime = this.getTimeInFiveMinutesInterval(clickPercentage);
    const clickedTime = this.setToSpecificDay(Days[this.day], this.transformHourIntoDate(formattedTime));
    const minHourSelection = 23;
    //Condition to set one hour timeslot when start hour exceeds 11pm
    if (clickedTime.getHours() >= minHourSelection) {
      clickedTime.setHours(minHourSelection, 0, 0, 0);
      clickPercentage = (minHourSelection / 24) * 100;
      clickPosition = (this.rect.width * clickPercentage) / 100;
    }
    const endPercentage = clickPercentage + (60 / 1440) * 100;
    const endTime = new Date(clickedTime.getTime() + 60 * 60000);
    const selectedSection = {
      startTime: clickedTime,
      endTime: endTime,
      width: (this.rect.width * (endPercentage - clickPercentage)) / 100,
      x: clickPosition,
      px: 0,
      isDraggingCorner: false
    };
    this.selectedSections.push(selectedSection);
    this.setFormValue();
  }

  public leftResize(offsetX: number, endTimePosition: number, currentSection: Section) {
    this.overlapSectionIndex = this.getIndexOverlap(
      currentSection.x,
      {rightLimit: 14, leftLimit: 14},
      this.selectedIndex
    );
    //restricts movement when overlap another created section
    if (this.overlapSectionIndex !== -1 && offsetX <= 0) {
      const overlapedSection = this.selectedSections[this.overlapSectionIndex];
      //set x position limit
      currentSection.x = overlapedSection.x + overlapedSection.width + 14;
      //set min width limit
      currentSection.width = endTimePosition - currentSection.x;
      this.getLeftSectionTime(currentSection);
      return;
    }
    // restricts movement when it reaches the left container border
    if (currentSection.x < 1 && offsetX <= 0) {
      currentSection.x = 0;
      currentSection.width = endTimePosition;
      this.getLeftSectionTime(currentSection);
      return;
    }
    // restricts movement when it reaches the minimum width
    const timeDifference = this.timeDifferenceInHours(currentSection);
    if (timeDifference <= this.minTimeSlotHours && offsetX >= 0) {
      let newDatePosition = new Date(currentSection.endTime);
      newDatePosition = new Date(newDatePosition.getTime() - this.minTimeSlotHours * 3600 * 1000);
      const initialPosition = this.getTimeInPixels(newDatePosition);
      currentSection.x = initialPosition;
      currentSection.width = this.minTimeSlotWidthInPx;
      this.getLeftSectionTime(currentSection);
      return;
    }

    currentSection.x += offsetX;
    currentSection.width -= offsetX;
    this.getLeftSectionTime(currentSection);
  }

  public rightResize(offsetX: number, endTimePosition: number, currentSection: Section) {
    this.overlapSectionIndex = this.getIndexOverlap(
      currentSection.x + currentSection.width,
      {rightLimit: 14, leftLimit: 14},
      this.selectedIndex
    );
    //restricts movement when overlap another created section
    if (this.overlapSectionIndex !== -1 && offsetX >= 0) {
      currentSection.width = this.selectedSections[this.overlapSectionIndex].x - 14 - currentSection.x;
      this.getRightSectionTime(currentSection);
      return;
    }
    // restricts movement when it reaches the left container border
    if (currentSection.x + currentSection.width >= this.rect.width && offsetX >= 0) {
      currentSection.width = this.rect.width - currentSection.x;
      this.getRightSectionTime(currentSection);
      return;
    }
    // restricts movement when it reaches the min width
    const timeDifference = this.timeDifferenceInHours(currentSection);
    if (timeDifference <= this.minTimeSlotHours + 0.1 && offsetX <= 0) {
      currentSection.width = this.minTimeSlotWidthInPx;
      this.getRightSectionTime(currentSection);
      return;
    }

    currentSection.width += offsetX;
    this.getRightSectionTime(currentSection);
  }

  @HostListener('document:mousemove', ['$event'])
  public onCornerMove(event: MouseEvent) {
    const currentSection = this.selectedSections[this.selectedIndex];
    if (!currentSection?.isDraggingCorner) {
      return;
    }

    const offsetX = event.clientX - currentSection.px;
    const endTimePosition = this.getTimeInPixels(currentSection.endTime);
    this.resizer(offsetX, endTimePosition, currentSection);

    currentSection.px = event.clientX;
  }

  @HostListener('document:mouseup', ['$event'])
  public onCornerRelease() {
    const currentSection = this.selectedSections[this.selectedIndex];
    if (!currentSection) {
      return;
    }
    currentSection.isDraggingCorner = false;
    this.setFormValue();
  }

  public setStatus(event: MouseEvent, func: () => void, index: number) {
    this.selectedIndex = index;
    const currentSection = this.selectedSections[index];
    currentSection.isDraggingCorner = true;
    currentSection.px = event.clientX;
    this.resizer = func;
    event.preventDefault();
    event.stopPropagation();
  }

  public deleteSelection(index: number): void {
    this.selectedSections.splice(index, 1);
    this.setFormValue();
  }

  public writeValue(obj: any): void {
    //Not needed
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public removeCurrentDisabledSection(): void {
    this.selectedSections = this.selectedSections.filter(section => !section.isDisabledRange);
    this.setFormValue();
  }

  public addInvalidRange(scheduleExpressionTimezone: string) {
    if (!this.date || !scheduleExpressionTimezone) {
      return;
    }
    let currentDate = new Date();
    currentDate = new Date(currentDate.toLocaleString('en-US', {timeZone: scheduleExpressionTimezone}));
    if (
      this.date.getMonth() === currentDate.getMonth() &&
      this.date.getDate() === currentDate.getDate() &&
      this.date.getFullYear() === currentDate.getFullYear()
    ) {
      this.rect = document.getElementById('timeline').getBoundingClientRect();
      const clickPosition = this.getPixelOffsetForTimestamp(this.date, this.rect.width);
      const endPosition = this.getPixelOffsetForTimestamp(currentDate, this.rect.width);
      const selectedSection = {
        startTime: this.date,
        endTime: currentDate,
        width: endPosition - clickPosition,
        x: clickPosition,
        px: 0,
        isDraggingCorner: false,
        isDisabledRange: true
      };
      this.checkInvalidRanges(selectedSection);
      this.selectedSections.push(selectedSection);
      this.setFormValue();
    }
  }

  private getTimeInPixels(time: Date): number {
    const timeInMinutes = time.getHours() * 60 + time.getMinutes();
    return timeInMinutes > 0 ? (timeInMinutes / (24 * 60)) * this.rect.width : this.rect.width;
  }

  private setFormValue(): void {
    this.onChange(
      this.selectedSections
        .filter(selection => !selection?.isDisabledRange)
        .map(selection => ({
          startTime: selection.startTime,
          startCronId: selection.startCronId,
          endTime: selection.endTime,
          endCronId: selection.endCronId,
          date: this.date,
          invalid: selection?.invalid
        }))
    );
  }

  private timeDifferenceInHours(selectedSection: Section): number {
    const timeDifference = selectedSection.endTime.getTime() - selectedSection.startTime.getTime();
    return timeDifference / (1000 * 60 * 60);
  }

  private getIndexOverlap(clickPosition, bounds: {rightLimit: number; leftLimit: number}, excludedIndex?: number) {
    return this.selectedSections.findIndex((section, index) => {
      if (index === excludedIndex) {
        return;
      }
      return (
        clickPosition >= section.x - bounds.rightLimit && clickPosition <= section.x + section.width + bounds.leftLimit
      );
    });
  }

  private getLeftSectionTime(selectedSection: Section) {
    const clickPercentage = (selectedSection.x / this.rect.width) * 100;
    const formattedTime = this.getTimeInFiveMinutesInterval(clickPercentage);

    selectedSection.startTime = this.transformHourIntoDate(formattedTime);
  }

  private getRightSectionTime(selectedSection: Section) {
    const xPosition = selectedSection.x + selectedSection.width;
    const clickPercentage = (xPosition / this.rect.width) * 100;
    const formattedTime = this.getTimeInFiveMinutesInterval(clickPercentage);
    selectedSection.endTime = this.transformHourIntoDate(formattedTime);
  }

  private getTimeInFiveMinutesInterval(clickPercentage: number): string {
    const totalMinutes = Math.round((clickPercentage / 100) * 24 * 60);
    const hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    minutes = Math.round(minutes / 5) * 5;
    return `${hours}:${minutes}`;
  }

  private transformHourIntoDate(formattedTime: string) {
    const today = this.date ? new Date(this.date) : this.setToSpecificDay(Days[this.day], new Date());
    const timeString = formattedTime;
    const [hours, minutes] = timeString.split(':').map(Number);
    today.setHours(hours);
    today.setMinutes(minutes);
    return today;
  }

  private getPixelOffsetForTimestamp(timestamp, dayDivWidth) {
    const totalDayDuration = 24 * 60 * 60 * 1000;
    const startOfDay = new Date(timestamp);
    startOfDay.setHours(0, 0, 0, 0);
    const millisecondsSinceStartOfDay = timestamp - startOfDay.getTime();
    const pixelOffset = (millisecondsSinceStartOfDay / totalDayDuration) * dayDivWidth;
    return pixelOffset;
  }

  public checkInvalidRanges(invalidRange) {
    this.selectedSections.forEach(section => {
      section.invalid = invalidRange.endTime >= section.startTime;
    });
  }
}

export interface Section {
  startTime: Date;
  startCronId?: string;
  endTime: Date;
  endCronId?: string;
  width: number;
  x: number;
  px: number;
  isDraggingCorner: boolean;
  isDisabledRange?: boolean;
  invalid?: boolean;
}

import {ScheduleMode} from '@app/live/models/schedule.model';
import {Addon} from '@app/store/addon/models/addon';

export interface ScheduleCronExpression {
  startCronExpression: string;
  startCronId?: string;
  endCronExpression?: string;
  endCronId?: string;
}

export interface ScheduleModel {
  scheduleDays: number[];
  id: string;
  title: string;
  description: string;
  expressions: ScheduleCronExpression[];
  eventExecutionType: ScheduleChainType;
  events: ScheduleEvent[];
  deviceIds: string[];
  scheduleExpressionTimezone?: string;
  addons?: Partial<Addon>[];
  isRecurring?: boolean;
  isSnapshot?: boolean;
  oneOffStartDate?: Date;
  schedules: ScheduleCronExpression[];
  snapshotConfig?: LiveStreamScheduleSnapshot;
}

export enum ScheduleChainType {
  sync = 'SYNC',
  async = 'ASYNC'
}

export interface AssignAssetsRequestPayload {
  gimbalPitch: number;
  folderId: string;
}

export interface AssignAssetsByAtlasRequestPayload {
  atlasId: string;
  folderId: string;
  groupNameKey?: string;
}

export interface MediaExportRequestPayload {
  folders: string[];
}

export interface LiveStreamPayload {
  streamKey: string;
  deviceIds: string[];
  addons?: Partial<Addon>[];
}

export interface LiveStreamSchedulePayload {
  streamKey: string;
  deviceIds: string[];
  addons?: Partial<Addon>[];
}

export interface LiveStreamScheduleSnapshot {
  frequency: number;
  unit: string;
  snapshotCronId?: string;
}

export interface ScheduleEvent {
  type: ScheduleEventType;
  data: AssignAssetsRequestPayload | AssignAssetsByAtlasRequestPayload | MediaExportRequestPayload | LiveStreamPayload;
}

export interface SchedulePayload {
  title: string;
  description: string;
  expressions: ScheduleCronExpression[];
  eventExecutionType: ScheduleChainType;
  events: ScheduleEvent[];
  scheduleExpressionTimezone?: string;
  deviceIds: string[];
  addons: Partial<Addon>[];
  oneOffStartDate?: number;
  mode?: ScheduleMode;
  snapshotConfig?: LiveStreamScheduleSnapshot;
}

export interface BatchCreateSchedule {
  deviceIds: string[];
  streamKey: string;
  schedules: ScheduleModel[];
  name: string;
  scheduleExpressionTimezone?: string;
  addons?: Partial<Addon>[];
  recurring?: boolean;
  oneOffStartDate?: Date;
  mode?: ScheduleMode;
  imageSnapshotFrecuencyUnits?: string;
  imageSnapshotFrecuency?: number;
}

export interface BatchUpdateSchedule {
  deviceIds: string[];
  schedules?: ScheduleModel[];
  streamKey: string;
  name?: string;
  scheduleExpressionTimezone?: string;
  addons?: Partial<Addon>[];
  recurring?: boolean;
  mode?: ScheduleMode;
  imageSnapshotFrecuencyUnits?: string;
  imageSnapshotFrecuency?: number;
}

export interface BatchDeleteSchedulePayload {
  scheduleId: string;
  scheduleName: string;
}

export enum ScheduleEventType {
  startLiveStream = 'START_LIVE_STREAM',
  liveAiStream = 'LIVE_AI_STREAM',
  assignAssets = 'ASSIGN_ASSETS',
  assignAssetsByLayer = 'ASSIGN_ASSETS_BY_LAYER',
  exportToAzure = 'EXPORT_TO_AZURE',
  exportToS3 = 'EXPORT_TO_S3',
  stream = 'EXPORT_TO_S3',
  streamSnapshot = 'STREAM_SNAPSHOT'
}

import {Injectable} from '@angular/core';
import {LocalStorageService} from '@app/core/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AtlasLocalStorageService {
  constructor(private localStorageService: LocalStorageService) {}
  public setItem(itemName: string, value: string): void {
    this.localStorageService.setItem(itemName, value);
  }

  public getItem(itemName: string): string {
    return this.localStorageService.getItem(itemName);
  }

  public removeItem(itemName: string): void {
    this.localStorageService.removeItem(itemName);
  }

  public transformLocalStorageToJSON(itemName: string) {
    let transformResult: {[key: string]: {id: string; isDisplaying: boolean}} = {};
    const storedLayers: string = this.localStorageService.getItem(itemName);
    if (storedLayers) {
      const splittedLayers: string[] = storedLayers.split(';');
      splittedLayers.forEach((layer: string) => {
        const jsonLayer: string = layer.replace(
          /(\w+:)|(\w+ :)/g,
          matchedLayer => '"' + matchedLayer.substring(0, matchedLayer.length - 1) + '":'
        );
        if (jsonLayer !== '{}') {
          const layer = JSON.parse(jsonLayer);
          transformResult = {...transformResult, [layer.id]: layer};
          return;
        }
      });
    }
    return transformResult;
  }
}

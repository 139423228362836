import {Injectable} from '@angular/core';
import {PhotoBrowserMetadata} from '@app/library/models/photo-browser-metadata.model';
import Pica from 'pica';
import {BehaviorSubject} from 'rxjs';
import {JpegFilter} from './filter_jpeg';

const PromisePool = require('es6-promise-pool');

export interface ResizeSettings {
  max_width?: number;
  max_height?: number;
  width: number;
  height: number;
  jpeg_quality: number;
}

// TODO Metadata should be handled in central place
export interface FileWithMetadata {
  file: File;
  metadata?: {[key: string]: string | number | boolean} | PhotoBrowserMetadata;
}

@Injectable({providedIn: 'root'})
export class ImageResizeService {
  public isResizing$ = new BehaviorSubject<boolean>(false);
  private settings: ResizeSettings;
  private filesResized: FileWithMetadata[] = [];
  private RESIZE_FILE_EXTENSIONS = ['bmp', 'jpg', 'jpeg', 'png'];

  private MAX_WIDTH_2K = 2704;
  private MAX_WIDTH_4K = 3840;
  private pica: Pica.Pica = new Pica();

  constructor() {
    const defaultSettings = {
      // max_width: 2700,     // crop, if scaled size is higher than this
      // max_height: null,    // crop, if scaled size is higher than this
      width: this.MAX_WIDTH_4K,
      height: null, // scale to fit width
      jpeg_quality: 100
    };
    this.settings = {...defaultSettings};
  }

  public saveDebugInfo(fileDebugArr, file, resizedImage, start) {
    const resizedInfo = [
      file.name, // fileName
      (file.size / 1024 / 1024).toFixed(2) + 'MB', // origWeight
      (resizedImage.size / 1024 / 1024).toFixed(2) + 'MB', // resWeight
      Math.round((file.size / resizedImage.size) * 100) + '%', // gain
      (new Date().getTime() - start) / 1000 + ' sec' // timeElapsed
    ];
    // console.log('Resized:', resizedInfo);
    fileDebugArr.push(resizedInfo);
  }

  /** Returns a list of filtered by extension, resized files */
  public resizeImages(files: File[]): PromiseLike<FileWithMetadata[]> {
    this.isResizing$.next(true);
    this.filesResized = [];
    // console.log('START RESIZING FILES');
    const fileDebugArr = [];
    const debug = true;
    let i = 0;
    // use tool to limit concurrency https://github.com/timdp/es6-promise-pool
    const enqueueResizeJob = () => {
      if (i < files.length) {
        const file = files[i];
        i++;
        const start = new Date().getTime();
        return this.resizeImage(file).then(imageWithMetadata => {
          if (debug) {
            this.saveDebugInfo(fileDebugArr, file, imageWithMetadata.file, start);
          }
          this.filesResized.push(imageWithMetadata);
          return Promise.resolve();
        });
      }
      return null;
    };

    return new PromisePool(enqueueResizeJob, 1).start().then(
      () => {
        if (debug) {
          this.showDebugMsg(fileDebugArr);
        }
        this.isResizing$.next(false);
        return Promise.resolve(this.filesResized);
      },
      err => {
        console.error('Resize failed', err);
        this.isResizing$.next(false);
        return Promise.resolve(this.filesResized);
      }
    );
  }

  public resizeImage(file: File): Promise<{file: File; metadata?: PhotoBrowserMetadata}> {
    return new Promise((resolve, reject) => {
      // Next tick
      setTimeout(() => {
        const slice = file.slice || (file as any).webkitSlice || (file as any).mozSlice;
        const ext = file.name.split('.').pop().toLowerCase();
        // Check if file can be resized before upload
        if (this.RESIZE_FILE_EXTENSIONS.indexOf(ext) === -1) {
          resolve({file}); // Skip resize
          return;
        }
        let jpegHeader;
        const img = new Image();
        img.onload = () => {
          if (img.width <= this.settings.width) {
            console.log(`Skipping resize (${img.width} < ${this.settings.width})`);
            resolve({file});
            return;
          }

          const {scaledHeight, scaledWidth} = this.scaleImage(img);

          /*eslint-disable no-undefined*/
          const quality = ext === 'jpeg' || ext === 'jpg' ? this.settings.jpeg_quality : undefined;

          const width = Math.min((img.height * scaledWidth) / scaledHeight, img.width);
          const cropX = (width - img.width) / 2;

          const alpha = ext === 'png';

          const source: any = document.createElement('canvas');
          const resizedImage = document.createElement('canvas') as any;

          source.width = width;
          source.height = img.height;

          resizedImage.width = scaledWidth;
          resizedImage.height = scaledHeight;

          source.getContext('2d').drawImage(img, cropX, 0, width, img.height);
          this.pica
            // TODO: https://github.com/nodeca/pica/issues/233
            .resize(source, resizedImage)
            .then(() => this.pica.toBlob(resizedImage, file.type, quality))
            .then(fileBlob => resolve({file: new File([fileBlob], file.name)}));
        };

        img.onerror = () => {
          this.handleError(reject);
        };

        const reader = new FileReader();

        reader.onloadend = () => {
          const fileData = new Uint8Array(reader.result as ArrayBuffer);

          if (fileData[0] === 0xff && fileData[1] === 0xd8) {
            // only keep comments in header
            const filter = new JpegFilter({
              removeImage: true,
              filter: true,
              removeICC: true
            }) as any;

            try {
              filter.push(fileData);
              filter.end();
            } catch (err) {
              this.handleError(reject);
              return;
            }

            const tmp = this.arrayConcat(filter.output);

            // cut off last 2 bytes (EOI, 0xFFD9),
            // they are always added by filter_jpeg on end
            jpegHeader = tmp.subarray(0, tmp.length - 2);
          }

          img.src = window.URL.createObjectURL(file);
        };

        reader.readAsArrayBuffer(file);
      }, 0);
    });
  }

  // Concatenate multiple Uint8Arrays
  //
  public arrayConcat(list) {
    let size = 0;
    let pos = 0;

    for (let i = 0; i < list.length; i++) {
      size += list[i].length;
    }

    const result = new Uint8Array(size);

    for (let i = 0; i < list.length; i++) {
      result.set(list[i], pos);
      pos += list[i].length;
    }

    return result;
  }

  private showDebugMsg(results: string[][]) {
    console.table([
      ['name', 'original size', 'resized size', 'compressed', 'time'],
      ...results // [fileName, origWeight, resWeight, gain, timeElapsed]
    ]);
  }

  private scaleImage(img: HTMLImageElement) {
    // To scale image we calculate new width and height, resize image by height and crop by width
    let scaledHeight, scaledWidth;
    const resizeConfig = this.settings;
    if (resizeConfig.height && !resizeConfig.width) {
      // If only height defined - scale to fit height,
      // and crop by max_width
      scaledHeight = resizeConfig.height;

      const proportionalWidth = Math.floor((img.width * scaledHeight) / img.height);

      scaledWidth =
        !resizeConfig.max_width || resizeConfig.max_width > proportionalWidth
          ? proportionalWidth
          : resizeConfig.max_width;
    } else if (!resizeConfig.height && resizeConfig.width) {
      // If only width defined - scale to fit width,
      // and crop by max_height
      scaledWidth = resizeConfig.width;

      const proportionalHeight = Math.floor((img.height * scaledWidth) / img.width);

      scaledHeight =
        !resizeConfig.max_height || resizeConfig.max_height > proportionalHeight
          ? proportionalHeight
          : resizeConfig.max_height;
    } else {
      // If determine both width and height
      scaledWidth = resizeConfig.width;
      scaledHeight = resizeConfig.height;
    }
    return {scaledHeight, scaledWidth};
  }

  private handleError(reject) {
    const message = 'error while resizing image!';
    console.log(message);
    reject(new Error(message));
  }
}

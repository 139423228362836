import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CreateEffectMetadata} from '@ngrx/effects/src/models';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {EMPTY, of, zip} from 'rxjs';
import {
  batchCreateSchedule,
  batchDeleteSchedule,
  batchUpdateSchedule,
  loadSchedules,
  loadSchedulesFail,
  loadSchedulesSuccess,
  loadSingleSchedule
} from '@app/live/store/schedules/live-schedule.actions';
import {ScheduleService} from '@app/core/services/api/schedule.service';
import {ListScheduleResponse, ScheduleMode} from '@app/live/models/schedule.model';
import {StreamScheduleFacadeService} from '@app/live/services/stream-schedule-facade.service';
import {SCHEDULE_PAGE_STATE, StreamScheduleService} from '@app/live/services/stream-schedule.service';
import {
  ScheduleChainType,
  ScheduleEvent,
  ScheduleEventType,
  SchedulePayload
} from '@app/core/models/api/schedule.model';
import {MatDialog} from '@angular/material/dialog';
import {DeleteScheduleDialogComponent} from '@app/live/components/delete-schedule-dialog/delete-schedule-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Injectable()
export class LiveScheduleEffects {
  public loadLiveSchedule$: CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSchedules),
      switchMap(() => {
        this.streamScheduleFacadeService.updateIsLoadingSchedules(true);
        return this.scheduleApiService.listTeamSchedules();
      }),
      map((schedules: ListScheduleResponse) => loadSchedulesSuccess(schedules)),
      catchError(error => of(loadSchedulesFail(error)))
    )
  );
  public loadSingleSchedule$: CreateEffectMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSingleSchedule),
      switchMap(action => zip(of(action), this.streamScheduleFacadeService.selectScheduleById(action.scheduleId))),
      switchMap(([action, schedule]) => {
        if (schedule) {
          return EMPTY;
        }
        return this.scheduleApiService.getSingleSchedule(action.scheduleId);
      }),
      map(schedule => {
        return loadSchedulesSuccess({items: [schedule], nextToken: undefined});
      })
    )
  );

  public batchCreateLiveSchedule$: CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchCreateSchedule),
        switchMap(action => {
          const startLiveStreamEvent: ScheduleEvent = {
            type: ScheduleEventType.startLiveStream,
            data: {
              streamKey: action.payload.streamKey,
              deviceIds: action.payload.deviceIds,
              addons: action.payload.addons
            }
          };
          const schedulePayload: SchedulePayload = {
            title: action.payload.name,
            description: '',
            eventExecutionType: ScheduleChainType.sync,
            events: [],
            expressions: this.streamScheduleService.dateToCron(
              action.payload.schedules,
              action.payload.scheduleExpressionTimezone,
              action.payload.recurring
            ),
            scheduleExpressionTimezone: action.payload.scheduleExpressionTimezone,
            deviceIds: action.payload.deviceIds,
            addons: action.payload.addons
          };
          if (action.payload.mode === ScheduleMode.SNAPSHOT) {
            schedulePayload.snapshotConfig = {
              unit: action.payload.imageSnapshotFrecuencyUnits,
              frequency: this.parseFrecuency(
                action.payload.imageSnapshotFrecuency,
                action.payload.imageSnapshotFrecuencyUnits
              )
            };
          }
          schedulePayload.events.push(startLiveStreamEvent);
          return this.scheduleApiService.create(schedulePayload);
        }),
        tap(schedule => {
          this.streamScheduleFacadeService.createScheduleSuccess(schedule);
          this.streamScheduleService.setSchedulePageState(SCHEDULE_PAGE_STATE.LIST);
        })
      ),
    {dispatch: false}
  );

  public batchUpdateLiveSchedule$: CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchUpdateSchedule),
        switchMap(action => {
          this.streamScheduleFacadeService.updateIsExecutingAction(true);
          const updatedEvents = {
            type: ScheduleEventType.startLiveStream,
            data: {
              streamKey: action.payload.streamKey,
              deviceIds: action.payload.deviceIds,
              addons: action.payload.addons
            }
          };
          const schedulePayload: SchedulePayload = {
            title: action.payload.name,
            description: '',
            eventExecutionType: ScheduleChainType.sync,
            events: [],
            scheduleExpressionTimezone: action.payload.scheduleExpressionTimezone,
            expressions: this.streamScheduleService.dateToCron(
              action.payload.schedules,
              action.payload.scheduleExpressionTimezone,
              action.payload.recurring
            ),
            deviceIds: action.payload.deviceIds,
            addons: action.payload.addons
          };

          if (action.payload?.name) {
            schedulePayload.title = action.payload.name;
          }
          if (action.payload.mode === ScheduleMode.SNAPSHOT) {
            schedulePayload.snapshotConfig = {
              unit: action.payload.imageSnapshotFrecuencyUnits,
              frequency: this.parseFrecuency(
                action.payload.imageSnapshotFrecuency,
                action.payload.imageSnapshotFrecuencyUnits
              )
            };
          }
          schedulePayload.events.push(updatedEvents);
          return this.scheduleApiService.update(action.scheduleId, schedulePayload);
        }),
        tap(schedule => {
          this.streamScheduleFacadeService.updateScheduleSuccess(schedule);
          this.streamScheduleFacadeService.updateIsExecutingAction(false);
          this.router.navigate(['secure/live/scheduler']);
        })
      ),
    {dispatch: false}
  );

  public deleteLiveSchedule$: CreateEffectMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(batchDeleteSchedule),
        switchMap(action => {
          const dialog = this.dialog.open(DeleteScheduleDialogComponent, {
            data: {
              title: action.scheduleName,
              scheduleId: action.scheduleId
            },
            width: '80vw',
            maxWidth: '800px',
            disableClose: true
          });
          return dialog.afterClosed().pipe(
            take(1),
            filter(data => !!data),
            switchMap(schedule =>
              zip(
                of(schedule),
                this.translateService.get(['live.scheduler.actionError', 'live.scheduler.scheduleDeleted'])
              )
            ),
            map(([schedule, translations]) => {
              if (schedule.error) {
                this.displaySnackBar(translations['live.scheduler.actionError']);
                return loadSchedulesFail(schedule.error);
              }
              this.displaySnackBar(translations['live.scheduler.scheduleDeleted']);
              this.router.navigate(['secure/live/scheduler']);
              return this.streamScheduleFacadeService.deleteSchedulesSuccess(schedule);
            })
          );
        })
      ),
    {dispatch: false}
  );

  constructor(
    private actions$: Actions,
    private scheduleApiService: ScheduleService,
    private streamScheduleService: StreamScheduleService,
    private streamScheduleFacadeService: StreamScheduleFacadeService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router
  ) {}

  private displaySnackBar(message: string) {
    this.matSnackBar.open(message, null, {
      duration: 6000,
      panelClass: 'center'
    });
  }

  private parseFrecuency(value: number, unit: string): number {
    switch (unit) {
      case 'seconds':
        return value;
      case 'minutes':
        return value * 60;
      case 'hours':
        return value * 3600;
      default:
        return value;
    }
  }
}

import {Injectable, NgZone} from '@angular/core';
import {JobsFacadeService} from './jobs-facade.service';
import {BehaviorSubject, Observable, Subject, Subscription, debounceTime, filter, fromEvent, take, tap} from 'rxjs';
import {TeamRole} from '@app/profile/models/team.model';
import {MatDialog} from '@angular/material/dialog';
import {RENAME_JOBS_OPTIONS, RenameDialog} from '../components/rename-dialog/rename-dialog.component';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {createMapIcon} from '@app/atlas/marker-icons/custom-map-pointer';
import {GeojsonAssetLoaderService} from '@app/atlas/services/geojson-asset-loader.service';
import {AtlasService} from '@app/atlas/services/atlas.service';
import {Mission, MissionRoutePoint} from '@app/atlas/services/mission-settings.service';
import {MissionTaskStatus} from '../models/marker-task';
import {atlasConfig, defaultMissionRouteColor} from '@app/atlas/atlas.config';
import {latLng, latLngBounds, Map, Marker, marker, PointExpression} from 'leaflet';
import {v4 as uuidv4} from 'uuid';
import {MissionPointType} from '@app/atlas/model/mission.model';
import {LibraryNavigationService} from '@app/library/services/library-navigation.service';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {SharedSuperclusterService} from '@app/shared/services/supercluster/shared-supercluster.service';
import {TaskTabs} from '../models/task-tabs.model';
import {ACTION_TASK_MODE} from '../models/action-task-mode';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
declare const L; // leaflet global

@Injectable({
  providedIn: 'root'
})
export class JobTasksService extends SharedSuperclusterService {
  public markerTaskList: BehaviorSubject<{geojson: any; name: string; userId: string; markers: any[]}[]> =
    new BehaviorSubject([]);
  public missionTaskList: BehaviorSubject<{name: string; userId: string; mission: Mission}[]> = new BehaviorSubject([]);
  public selectedAssetId = null;
  public isAddingTasks$: Observable<boolean> = this.jobsFacadeService.isAddingTasks$;
  public teamMembers$: Observable<{[key: string]: {id: string; name: string; role: TeamRole}}> =
    this.jobsFacadeService.teamMembers$;
  public selectedTaskIndex: number = -1;
  public hasToDetectListChanges: Subject<void> = new Subject();
  public missionTaskStatus: BehaviorSubject<MissionTaskStatus> = new BehaviorSubject(MissionTaskStatus.SELECT_MISSIONS);
  public flightPathMarkers: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public isDrawEventsBlocked: boolean = false;
  public drawnItems = new L.FeatureGroup();
  public miniMapItems = new L.FeatureGroup();
  public routeGroup = new L.FeatureGroup();
  public taskMiniMapItems = new L.FeatureGroup();
  public flightPathTasks: BehaviorSubject<
    {markers: any[]; name: string; userId: string; internalId: string; isSelected: boolean; context?: any}[]
  > = new BehaviorSubject([]);
  public selectedTaskId: BehaviorSubject<string> = new BehaviorSubject(null);
  public miniMapZoom: number = null;
  public jobAsset: BehaviorSubject<AtlasAssetModel> = new BehaviorSubject<AtlasAssetModel>(null);
  public isPopupEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRoutePopupEnabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public displayedClusters = [];
  public clusterMarkers: L.Marker[] = [];
  public hasToEnableDraw: boolean = false;
  public blockUpdateClusters: boolean = false;
  public moveMapSub: Subscription;
  public currentTabIndex: BehaviorSubject<TaskTabs> = new BehaviorSubject(0);
  public actionTaskMode: ACTION_TASK_MODE = ACTION_TASK_MODE.ADD;
  public undoStack: BehaviorSubject<{index: number; action: UNDO_ACTION; marker?: Marker}[]> = new BehaviorSubject([]);
  public originalTaskMarkers: Marker[] = [];

  private currentInternalId = this.generateRandomId();
  private readonly createdTaskColor = '#666666';
  private readonly greenPathColor = '#00AF55';
  private readonly createdTaskMarkerClass = 'created-flight-path-marker';
  private readonly poleCreatedTaskMarkerClass = 'pole-created-flight-path-marker';
  private readonly createdTaskPolylineType = 'line-grey';
  private readonly defaultPolylineType = 'line';
  private readonly newFlightPathMarkerClass = 'new-flight-path-marker';
  private readonly activeFlightPathMarkerClass = 'active-flight-path-marker';
  private readonly selectedMarkerIcon = 'assets/icons/atlas/selected-marker.svg';
  public readonly flightPathMarkerClass = 'existent-flight-path-marker';
  private readonly plusMarkerClass = 'plus-marker';
  private readonly greyTaskMarkerIconColor = '#666666';
  private readonly selectedMarkerColor = '#00AF55';
  public readonly drawedLinesWeight = 3.5;
  private readonly defaultMarkerSize = [17, 24];
  private readonly largeMiniMapPadding = [25, 25];
  private readonly smallMiniMapPadding = [50, 50];
  private activeIndex: number = 0;
  private editPolePoints = [];

  constructor(
    private jobsFacadeService: JobsFacadeService,
    private matDialog: MatDialog,
    private geojsonAssetLoaderService: GeojsonAssetLoaderService,
    private atlasService: AtlasService,
    private zone: NgZone,
    private libraryNavigationService: LibraryNavigationService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    super();
    this.setClusterThreshold(atlasConfig.DISABLE_CLUSTERING_MARKERS_COUNT_TASKS_MODE);
  }

  public setActionTaskMode(mode: ACTION_TASK_MODE) {
    this.actionTaskMode = mode;
  }

  public setCurrentTabIndex(index: TaskTabs) {
    this.currentTabIndex.next(index);
  }

  public setHasToEnableDraw(hasToEnableDraw: boolean) {
    this.hasToEnableDraw = hasToEnableDraw;
  }

  public toggleIsPopupEnabled() {
    this.setIsPopupEnabled(!this.isPopupEnabled.value);
  }

  public toggleIsRoutePopupEnabled() {
    this.setIsRoutePopupEnabled(!this.isRoutePopupEnabled.value);
  }

  public setIsPopupEnabled(isPopupEnabled: boolean) {
    this.isPopupEnabled.next(isPopupEnabled);
  }

  public setIsRoutePopupEnabled(isPopupEnabled: boolean) {
    this.isRoutePopupEnabled.next(isPopupEnabled);
  }

  public setJobAsset(jobAsset: AtlasAssetModel) {
    this.jobAsset.next(jobAsset);
  }

  public toggleMap(map, mapView, roadView, hybridView, satelliteView) {
    return this.atlasService.toggleMap(
      map,
      {
        roadView,
        hybridView,
        satelliteView
      },
      mapView
    );
  }

  public viewReport(libraryItemId: string): void {
    this.libraryNavigationService.goToReport(libraryItemId);
  }

  public viewMedia(libraryItemId: string): void {
    this.libraryNavigationService.goToLibraryItem(libraryItemId);
  }

  public setMiniMapZoom(zoom: number): void {
    this.miniMapZoom = zoom;
  }

  public generateNewInternalId() {
    this.currentInternalId = this.generateRandomId();
  }

  public setSelectedTaskId(selectedTaskId: string) {
    this.selectedTaskId.next(selectedTaskId);
  }

  public createFlightPathTasks(jobId: string) {
    const flightPathTasks = this.flightPathTasks.value.map(task => {
      return {
        ...task,
        markers: this.formatFlightPathMarkers(task.markers)
      };
    });
    this.jobsFacadeService.createFlightPathTasks({jobId, flightPathTasks});
  }

  public formatFlightPathMarkers(markers: Marker[]) {
    const formattedMarkers = [];
    markers.forEach(marker => {
      const point = {
        ...marker.getLatLng(),
        type:
          (marker.options?.icon?.options as any)?.type === this.flightPathMarkerClass
            ? MissionPointType.POLE
            : MissionPointType.POINT
      };
      if (marker?.feature?.properties?.name) {
        (point as any).label = marker.feature.properties.name;
      }
      formattedMarkers.push(point);
    });
    return formattedMarkers;
  }

  public addFlightPaths(flightPath: {markers: any[]; name: string; userId: string; context: any}) {
    this.flightPathTasks.next([
      ...this.flightPathTasks.value,
      {...flightPath, internalId: this.currentInternalId, isSelected: false}
    ]);
  }

  public setIsDrawEventsBlocked(isDrawEventsBlocked: boolean) {
    this.isDrawEventsBlocked = isDrawEventsBlocked;
  }

  public detectListChanges(): void {
    this.hasToDetectListChanges.next();
  }

  public addMarkerTask(markerTask: {geojson: any; name: string; userId: string; markers: any}): void {
    this.markerTaskList.next([...this.markerTaskList.value, markerTask]);
  }

  public addMissionTask(missionTaskList: {name: string; userId: string; mission: Mission}[]): void {
    this.missionTaskList.next(missionTaskList);
  }

  public clearMissionTaskList(): void {
    this.missionTaskList.next([]);
  }

  public clearMarkerTaskList(): void {
    this.markerTaskList.next([]);
  }

  public setSelectedAssetId(assetId: string): void {
    this.selectedAssetId = assetId;
  }

  public addTasks(jobId: string): void {
    const taskList = this.markerTaskList.value.map(taskList => {
      taskList.geojson.features.forEach(feature => this.atlasService.deleteFeatureCustomProperties(feature));
      return {geojson: taskList.geojson, name: taskList.name, userId: taskList.userId};
    });
    this.jobsFacadeService.addTasks(jobId, taskList, this.selectedAssetId);
  }

  public addMissionTasks(jobId: string): void {
    this.jobsFacadeService.addMissionTasks(jobId, this.missionTaskList.value);
  }

  public toggleAddUserToTask(taskIndex: number, userId: string): void {
    const taskList = [...this.markerTaskList.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.markerTaskList.next(taskList);
  }

  public deleteTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    this.clearAllFocusedMarkers();
    const taskList = [...this.markerTaskList.value];
    taskList.splice(taskIndex, 1);
    this.markerTaskList.next(taskList);
    this.updateMarkerIcon();
  }

  public deleteFlightPathTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    const taskList = [...this.flightPathTasks.value];
    taskList.splice(taskIndex, 1);
    this.flightPathTasks.next(taskList);
  }

  public deleteMissionTask(taskIndex: number): void {
    if (taskIndex === -1) {
      return;
    }
    const taskList = [...this.missionTaskList.value];
    taskList.splice(taskIndex, 1);
    this.missionTaskList.next(taskList);
    if (taskList.length === 0) {
      this.setMissionTaskStatus(MissionTaskStatus.SELECT_MISSIONS);
    }
  }

  public clearAllFocusedMarkers(): void {
    const allSelectedMarkers = this.markerTaskList.value.flatMap(obj => obj.markers);
    allSelectedMarkers.forEach(marker => {
      const icon = this.geojsonAssetLoaderService
        .generateMarker(marker.feature, marker.getLatLng(), this.atlasService.getAssetById(this.selectedAssetId))
        ?.getIcon();
      marker.setIcon(icon);
    });
  }

  public updateMarkerIcon(): void {
    console.time('get selected markers');
    const allSelectedMarkers = this.markerTaskList.value.flatMap(obj => obj.markers);
    console.timeEnd('get selected markers');
    allSelectedMarkers.forEach(marker => {
      this.generateSelectedMarkerIcon(marker);
    });
  }

  public updateFlightPathTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: string) => !!response),
        tap(newName => {
          const updatedTaskList = this.flightPathTasks.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.flightPathTasks.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  public selectTask(internalId: string): void {
    this.drawnItems.eachLayer(layer => {
      if (!layer.options?.internalId) {
        return;
      }
      if (layer.options?.internalId === internalId) {
        this.updateSelectedTaskStatus(layer, true);
        return;
      }
      if (!internalId) {
        this.updateSelectedTaskStatus(layer, false);
        return;
      }
    });
  }

  public updateMissionTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: string) => !!response),
        tap(newName => {
          const updatedTaskList = this.missionTaskList.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.missionTaskList.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  public updateTaskName(taskIndex: number, name: string): void {
    const dialog = this.openUpdateNameDialog(name);
    dialog
      .afterClosed()
      .pipe(
        take(1),
        filter((response: any) => !!response),
        tap(newName => {
          const updatedTaskList = this.markerTaskList.value.map((task, index) =>
            index === taskIndex ? {...task, name: newName} : task
          );
          this.markerTaskList.next(updatedTaskList);
        })
      )
      .subscribe();
  }

  private openUpdateNameDialog(name: string) {
    return this.matDialog.open(RenameDialog, {
      ...STANDARD_DIALOG_CONFIG,
      width: '40vw',
      data: {
        renameOption: RENAME_JOBS_OPTIONS.TASK,
        name: name
      }
    });
  }

  public setSelectedIndex(taskIndex: number): void {
    if (taskIndex === -1) {
      const task = this.markerTaskList.value[this.selectedTaskIndex];
      task?.markers.forEach(marker => {
        this.generateSelectedMarkerIcon(marker);
      });
      this.selectedTaskIndex = taskIndex;
      return;
    }
    const task = this.markerTaskList.value[taskIndex];
    task?.markers.forEach(marker => {
      this.generateSelectedTaskIcon(marker, this.selectedMarkerIcon);
    });
    this.selectedTaskIndex = taskIndex;
  }

  public generateSelectedTaskIcon(marker: any, icon: string) {
    const iconProperties = marker.options.icon.options;
    const newCustomIcon = L.icon({
      ...iconProperties,
      iconUrl: icon,
      iconSize: [25, 41]
    });
    marker.setIcon(newCustomIcon);
  }

  public generateSelectedMarkerIcon(marker: Marker) {
    const iconProperties = marker.options.icon.options;
    const newCustomIcon = createMapIcon({
      ...iconProperties,
      size: [25, 41],
      color: this.createdTaskColor
    });
    marker.setIcon(newCustomIcon);
  }

  public generateDefaultClusterIcon(totalMarkers: number) {
    const defaultColor = this.getDefaultClusterColor(totalMarkers);
    const color = this.atlasService.getAssetById(this.selectedAssetId)?.color;
    return color
      ? this.generateClusterIcon(color, color, false, totalMarkers)
      : this.generateClusterIcon(defaultColor.container, defaultColor.marker, true, totalMarkers);
  }

  public generateClusterIcon(
    containerColor: string,
    markerColor: string,
    hasBlackCounterColor: boolean,
    counter: number
  ) {
    return L.divIcon({
      html: `<div style="background-color:
        ${containerColor}
        " class="custom-cluster__container"></div><div style="background-color:
        ${markerColor}
        " class="custom-cluster__icon
        ${hasBlackCounterColor ? 'black' : ''} ">  ${counter}  </div>`,
      className: 'custom-cluster',
      iconSize: L.point(40, 40)
    });
  }

  public getDefaultClusterColor(markerCounter: number): {container: string; marker: string} {
    if (markerCounter < 10) {
      return {container: 'rgba(181, 226, 140, 1)', marker: 'rgba(110, 204, 57, 0.6)'};
    }
    if (markerCounter < 100) {
      return {container: 'rgba(241, 211, 87, 1)', marker: 'rgba(240, 194, 12, 0.6)'};
    }
    return {container: 'rgba(253, 156, 115, 1)', marker: 'rgba(241, 128, 23, 0.6)'};
  }

  public addUserToMission({userId, taskIndex}: {userId: string; taskIndex: number}): void {
    const taskList = [...this.missionTaskList.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.missionTaskList.next(taskList);
  }

  public addUserToFlightPathTask({userId, taskIndex}: {userId: string; taskIndex: number}): void {
    const taskList = [...this.flightPathTasks.value];
    taskList[taskIndex].userId = taskList[taskIndex].userId === userId ? null : userId;
    this.flightPathTasks.next(taskList);
  }

  public setMissionTaskStatus(missionTaskStatus: MissionTaskStatus): void {
    this.missionTaskStatus.next(missionTaskStatus);
  }

  public selectMarkersByTask(map: Map, taskId: string) {
    const taskLayers = this.taskMiniMapItems.getLayers(taskId);
    if (taskLayers?.length === 0) {
      return;
    }
    taskLayers[0].eachLayer(layer => {
      if (!taskId) {
        this.updateMiniMapSelectedMarker(layer, this.greyTaskMarkerIconColor);
        layer.setZIndexOffset(0);
        return;
      }
      if (layer.feature?.properties?.taskId === taskId) {
        this.updateMiniMapSelectedMarker(layer, this.selectedMarkerColor);
        layer.setZIndexOffset(1000);
        const mapBounds = map.getBounds();
        const layerPosition = layer.getLatLng();
        if (!mapBounds.contains(layerPosition)) {
          map.setView(layerPosition);
        }
      }
    });
  }

  public addTaskMarkersToMap(tasks, map, zoom) {
    const taskMarkers = tasks
      .filter(task => task.context.mission?.route)
      .map(task => ({
        coords: task.context.mission.route[0],
        id: task.id
      }));
    if (taskMarkers.length === 0) {
      return;
    }
    const geojsonFeature = {
      type: 'FeatureCollection',
      features: taskMarkers
        .filter(values => values.coords)
        .map(values => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [values.coords.lng, values.coords.lat]
          },
          properties: {
            taskId: values.id
          }
        }))
    };
    const bounds = L.latLngBounds(taskMarkers.map(markers => markers?.coords));
    map.fitBounds(bounds, {padding: this.smallMiniMapPadding as PointExpression, maxZoom: zoom});
    const geojsonLayer = L.geoJSON(geojsonFeature, {
      pointToLayer: (feature, latlng) => {
        return L.marker(latlng, {
          icon: createMapIcon({
            size: this.defaultMarkerSize,
            color: this.greyTaskMarkerIconColor
          })
        });
      },
      onEachFeature: (feature, layer) => {
        layer.on('mouseover', () => {
          this.zone.run(() => {
            this.setSelectedTaskId(layer.feature?.properties?.taskId);
            this.updateMiniMapSelectedMarker(layer, this.selectedMarkerColor);
            layer.setZIndexOffset(1000);
          });
        });
        layer.on('mouseout', () => {
          this.zone.run(() => {
            this.setSelectedTaskId(null);
            this.updateMiniMapSelectedMarker(layer, this.greyTaskMarkerIconColor);
            layer.setZIndexOffset(0);
          });
        });
      }
    });
    this.taskMiniMapItems.addLayer(geojsonLayer);
    this.taskMiniMapItems.addTo(map);
  }

  public removeTask(taskId: string) {
    const taskLayers = this.taskMiniMapItems.getLayers()[0];
    taskLayers?.eachLayer(layer => {
      if (layer.feature?.properties?.taskId === taskId) {
        taskLayers.removeLayer(layer);
      }
    });
  }

  public updateMiniMapSelectedMarker(marker, color) {
    marker.setIcon(
      createMapIcon({
        size: this.defaultMarkerSize,
        color
      })
    );
  }

  public toggleMarkersTooltip(map: Map) {
    const cluster = this.drawnItems;
    cluster?.eachLayer(marker => {
      if (!this.isPopupEnabled.value) {
        marker.unbindTooltip();
        marker.unbindPopup();
        return;
      }
      this.setSelectedMarkerTooltip(marker.feature?.properties?.name, marker);
    });
    this.updateClusters(map);
  }

  public addMarkersToMap({markers, map, zoom, routes}: {markers: any[]; map: Map; zoom?: number; routes: any[]}) {
    const geojson = {
      type: 'FeatureCollection',
      features: markers
    };
    const geoJsonLayer = L.geoJSON(geojson);
    const bounds = geoJsonLayer.getBounds();
    map.fitBounds(bounds, {padding: this.smallMiniMapPadding as PointExpression, maxZoom: zoom});

    const drawedPoints = new Set(routes?.flat().map(({lng, lat}) => `${lng},${lat}`));
    const clusterPoints = markers
      .filter(({geometry: {coordinates}}) => {
        const [lng, lat] = coordinates;
        return !drawedPoints.has(`${lng},${lat}`);
      })
      .map(marker => {
        const point = {...marker, properties: {...marker.properties}};
        this.atlasService.addFeatureProperties(point, this.jobAsset.value);
        return point;
      });
    this.initClusters(clusterPoints, map);
    if (this.hasToEnableDraw) {
      this.listenDrawMarkerOnMap(map);
    }
  }

  public setSelectedMarkerTooltip(assetId, layer) {
    if (assetId) {
      layer.bindTooltip(assetId, {
        closeButton: false,
        maxWidth: 172,
        autoPan: false,
        className: 'assetId-tooltip',
        direction: 'left',
        permanent: true,
        offset: [-10, 0] // add extra space in x axis for path icon
      });
      layer.openTooltip();
    }
  }

  public clearMiniMapItems(): void {
    this.miniMapItems.clearLayers();
  }

  public clearTaskMiniMapItems(): void {
    this.taskMiniMapItems.clearLayers();
  }

  public clearDrawnItems(): void {
    this.drawnItems.clearLayers();
  }

  public clearRouteGroup(): void {
    this.routeGroup.clearLayers();
  }

  public hideDraw(map: Map) {
    this.drawnItems.removeFrom(map);
    this.clusterMarkers.forEach(marker => {
      map.removeLayer(marker);
    });
    this.blockUpdateClusters = true;
  }

  public showDraw(map: Map) {
    this.drawnItems.addTo(map);
    this.blockUpdateClusters = false;
    this.updateClusters(map);
  }

  public async hideCurrentFlightPath(map: Map) {
    this.drawnItems.removeFrom(map);
  }

  public showCurrentFlightPath(map: Map) {
    this.drawnItems.addTo(map);
  }

  public showMiniMapMarkers(map) {
    this.miniMapItems.addTo(map);
  }

  public generateRoutePath(route, group, generateTooltip) {
    route.forEach((point, i) => {
      const marker = L.marker(point);
      if (point.type == MissionPointType.POLE) {
        this.generateSelectedFlightPathMarker({isNewMarker: false, index: i + 1, layer: marker});
        if (point?.label && generateTooltip) {
          marker['label'] = point.label;
          this.setSelectedMarkerTooltip(point.label, marker);
        }
      } else {
        this.generateSelectedFlightPathMarker({isNewMarker: true, index: i + 1, layer: marker});
      }
      group.addLayer(marker);
      if (i > 0) {
        const layer = L.polyline([route[i - 1], route[i]], {
          color: defaultMissionRouteColor,
          weight: this.drawedLinesWeight
        });
        group.addLayer(layer);
      }
    });
  }

  public toggleRouteTooltip() {
    const cluster = this.routeGroup;
    cluster?.eachLayer(marker => {
      if (!this.isRoutePopupEnabled.value) {
        marker.unbindTooltip();
        marker.unbindPopup();
        return;
      }
      if (marker.label) {
        this.setSelectedMarkerTooltip(marker.label, marker);
      }
    });
  }

  public displayRoutePath(map, route: any[]) {
    const bounds = L.latLngBounds(route);
    map.fitBounds(bounds, {padding: this.largeMiniMapPadding});
    this.generateRoutePath(route, this.routeGroup, true);
    this.routeGroup.addTo(map);
  }

  public hideMiniMapMarkers(map) {
    this.miniMapItems.removeFrom(map);
  }

  public updateDeletedFlightPathDraws(index: number) {
    const internalId = this.flightPathTasks.value[index].internalId;
    this.drawnItems.eachLayer(layer => {
      if (internalId === layer.options?.internalId || internalId === layer.feature?.internalId) {
        delete layer.feature?.flightPathIcon;
        layer.removeFrom(this.drawnItems);
      }
    });
  }

  public redrawFlightPaths(params: {markersOnly: boolean}): void {
    const allTaskMarkers = this.flightPathTasks.value.map(task => task.markers);
    allTaskMarkers.forEach(group => {
      if (params.markersOnly) {
        this.generateGreyMarkers(group);
        return;
      }
      this.generateGreyMarkers(group);
      this.generatePolylines(group, this.greenPathColor);
    });
  }

  public generatePolylines(group, color): void {
    for (let i = 1; i < group.length; i++) {
      const start = this.getLayerCoords(group[i - 1]);
      const end = this.getLayerCoords(group[i]);
      const layer = L.polyline([start, end], {
        color,
        weight: this.drawedLinesWeight,
        type: this.createdTaskPolylineType,
        internalId: group[i].options.internalId
      });

      this.setPolilyneEvents(layer);
      this.drawnItems.addLayer(layer);
    }
  }

  public generateGreyMarkers(markers) {
    markers.forEach((marker, index) => {
      const markerIcon = marker.getIcon();
      const newIcon = L.divIcon({
        className:
          markerIcon.options?.className === this.newFlightPathMarkerClass ||
          markerIcon.options?.type === this.newFlightPathMarkerClass
            ? this.createdTaskMarkerClass
            : this.poleCreatedTaskMarkerClass,
        type: markerIcon.options?.type || marker.options?.icon?.options?.className,
        html: `<div style="text-align: center;">
               <span style="position: absolute; top: 44%; left: 50%;
                            transform: translate(-50%, -50%);
                            height: 14px;
                            font-family: Arial; font-size: 12px; font-weight:700; text-anchor: middle;">
                 ${markerIcon.options?.createdIndex || index + 1}
               </span>
             </div>`,
        iconSize: [20, 20],
        createdIndex: index + 1
      });
      marker.setIcon(newIcon);
      if (marker.feature) {
        marker.feature.flightPathIcon = newIcon;
        marker.feature.internalId = this.currentInternalId;
        return;
      }
      marker.dragging?.disable();
      if (marker.options.internalId) {
        marker.off('mouseover');
        marker.off('mouseout');
      }
      if (!marker.options.internalId) {
        marker.options.internalId = this.currentInternalId;
      }
      marker.on('mouseover', () => {
        this.updateSelectedTaskStatus(marker, true);
      });
      marker.on('mouseout', () => {
        this.updateSelectedTaskStatus(marker, false);
      });
    });
  }

  private updateSelectedTaskStatus(layer, isSelected) {
    const flightPathTasks = [...this.flightPathTasks.value];
    const selectedFlightPathIndex = flightPathTasks.findIndex(
      task => task.internalId === layer.options?.internalId || task.internalId === layer.feature?.internalId
    );
    if (selectedFlightPathIndex !== -1) {
      flightPathTasks[selectedFlightPathIndex] = {...flightPathTasks[selectedFlightPathIndex], isSelected};
      this.flightPathTasks.next(flightPathTasks);
    }
  }

  public generateRandomId() {
    return uuidv4();
  }

  public updateFlightPathTaskIcons() {
    this.drawnItems.eachLayer(layer => {
      if (layer.options?.type === this.defaultPolylineType) {
        layer.setStyle({color: this.greenPathColor});
        layer.options.type = this.createdTaskPolylineType;
        if (!layer.options.internalId) {
          layer.options.internalId = this.currentInternalId;
        }
        this.setPolilyneEvents(layer);
      }
      if (layer.options?.icon?.options?.className === this.plusMarkerClass) {
        layer.removeFrom(this.drawnItems);
      }
    });
    this.generateGreyMarkers(this.flightPathMarkers.value);
  }

  public undo(map: Map) {
    if (this.flightPathMarkers.value.length === 0 && !this.undoStack.value.length) {
      return;
    }
    const drawedMarkers = [...this.flightPathMarkers.value];
    const undoStack = this.undoStack.value;
    const lastUndoItem = undoStack.pop();
    this.undoStack.next(undoStack);
    if (!lastUndoItem) {
      return;
    }
    const undoIndex = lastUndoItem.index - 1;
    if (lastUndoItem.action === UNDO_ACTION.REMOVE) {
      this.restoreDeletedPoint(undoIndex, lastUndoItem.marker, map, drawedMarkers);
      if (!this.originalTaskMarkers.find(marker => marker.getLatLng() === lastUndoItem.marker.getLatLng())) {
        this.undoStack.next([...this.undoStack.value, {index: undoIndex + 1, action: UNDO_ACTION.ADD}]);
      }
      return;
    }

    const lastMarker = drawedMarkers[undoIndex];
    if (!lastMarker) {
      return;
    }
    const markerOptions = lastMarker.options?.icon?.options;
    const hasToRestore =
      this.editPolePoints.length > 0 && markerOptions?.className.includes(this.flightPathMarkerClass);
    if (hasToRestore) {
      this.restoreEditPolePoint(lastMarker);
    }
    if (!markerOptions?.isCreatedPoint) {
      lastMarker?.removeFrom(this.drawnItems);
      delete lastMarker.feature?.flightPathIcon;
    } else {
      this.generateGreyMarkers([lastMarker]);
    }
    this.removeWaypointAttachedLines(undoIndex, drawedMarkers);
    this.deleteFlightPathMarker(drawedMarkers, undoIndex);
    if (this.flightPathMarkers.value[undoIndex]) {
      // restore line
      this.drawLine(undoIndex, map);
    }
    if (undoIndex + 1 !== this.activeIndex) {
      this.updatePreviousActiveMarkerIcon();
    }
    this.activeIndex = this.getUndoIndex();
    if (undoIndex > 0) {
      const updateOriginIndex = undoIndex - 1;
      this.updateMarkersFromCustomIndex(updateOriginIndex);
    }
    this.updateClusters(map);
  }

  private restoreDeletedPoint(index: number, marker: Marker, map: Map, drawedMarkers: Marker[]) {
    this.activeIndex++;
    this.removeLine(index - 1, index);
    this.removeEditPolePoint(marker);
    drawedMarkers.splice(index, 0, marker);
    this.flightPathMarkers.next(drawedMarkers);
    this.generateSelectedMarkerIcon(marker);
    if (index > 0) {
      this.drawLine(index, map);
    }
    if (index + 1 < this.flightPathMarkers.value.length) {
      this.drawLine(index + 1, map);
    }
    this.updateMarkersFromCustomIndex(index - 1);
    this.drawnItems.addLayer(marker);
    this.updateClusters(map);
  }

  private restoreEditPolePoint(marker: Marker) {
    const point = this.getSelectedPolePoint(marker);
    if (point) {
      const pointIndexes = new Set(this.currentDisplayedPoints.map(point => (point as any).index));
      if (!pointIndexes.has(point.index)) {
        const pointWithFeatures = {...point, properties: {...point.properties}};
        this.atlasService.addFeatureProperties(pointWithFeatures, this.jobAsset.value);
        const points = [...this.currentDisplayedPoints, {...pointWithFeatures}];
        this.setCurrentDisplayedPoints(points);
        this.loadSuperCluster(points);
      }
    }
  }

  private removeEditPolePoint(marker: Marker) {
    const point = this.getSelectedPolePoint(marker);
    if (point) {
      const pointIndex = this.currentDisplayedPoints.findIndex(cdp => (cdp as any).index === point.index);
      if (pointIndex !== -1) {
        this.currentDisplayedPoints.splice(pointIndex, 1);
        this.setCurrentDisplayedPoints(this.currentDisplayedPoints);
        this.loadSuperCluster(this.currentDisplayedPoints);
      }
    }
  }

  private getSelectedPolePoint(marker: Marker) {
    return this.editPolePoints.find(point => {
      const coords = `${point.geometry.coordinates[1]},${point.geometry.coordinates[0]}`;
      const markerCoords = `${marker.getLatLng().lat},${marker.getLatLng().lng}`;
      return coords === markerCoords;
    });
  }

  private getUndoIndex() {
    const undoStack = this.undoStack.value;
    while (undoStack.length > 0 && undoStack[undoStack.length - 1]?.index > this.flightPathMarkers.value.length) {
      undoStack.pop();
    }
    this.undoStack.next(undoStack);
    return (
      this.undoStack.value.findLast(
        item => item.action === UNDO_ACTION.ADD && item.index <= this.flightPathMarkers.value.length
      )?.index || this.activeIndex - 1
    );
  }

  private deleteFlightPathMarker(drawedMarkers: Marker[], index: number) {
    drawedMarkers.splice(index, 1);
    this.flightPathMarkers.next(drawedMarkers);
  }

  private removeWaypointAttachedLines(originIndex: number, drawedMarkers: Marker[]) {
    if (originIndex !== 0) {
      this.removeLine(originIndex - 1, originIndex);
      if (originIndex + 1 < drawedMarkers.length) {
        this.removeLine(originIndex, originIndex + 1);
      }
    }
  }

  public serializeLatLng(latLng) {
    return `${latLng.lat},${latLng.lng}`;
  }
  public isMarkerPartOfSelectedLayers(arr1, arr2) {
    const serializedArr1 = arr1.map(this.serializeLatLng);
    const serializedArr2 = arr2.map(this.serializeLatLng);
    const serializedStr1 = serializedArr1.join('|');
    const serializedStr2 = serializedArr2.join('|');
    return serializedStr2.includes(serializedStr1);
  }

  public clearAllDrawnItems() {
    switch (this.actionTaskMode) {
      case ACTION_TASK_MODE.ADD:
        this.redrawFlightPaths({markersOnly: true});
        break;
      case ACTION_TASK_MODE.EDIT:
        this.flightPathTasks.value?.[0]?.markers
          ?.filter(marker => marker.options?.icon?.options?.className.includes(this.flightPathMarkerClass))
          .forEach(marker => {
            this.restoreEditPolePoint(marker);
          });
        break;
    }
    this.drawnItems.eachLayer(layer => {
      const iconOptions = layer.options?.icon?.options;
      if (
        iconOptions?.className === this.createdTaskMarkerClass ||
        iconOptions?.className === this.poleCreatedTaskMarkerClass ||
        layer.options?.type === this.createdTaskPolylineType
      ) {
        return;
      }
      if (iconOptions?.isCreatedPoint) {
        this.generateGreyMarkers([layer]);
        return;
      }
      layer.removeFrom(this.drawnItems);
    });
    this.flightPathMarkers.value.forEach(marker => {
      if (marker.feature?.flightPathIcon?.options?.className?.includes(this.flightPathMarkerClass)) {
        delete marker.feature?.flightPathIcon;
      }
    });
    this.restoreTaskValues();
  }

  public restoreTaskValues(): void {
    this.flightPathMarkers.next([]);
    this.undoStack.next([]);
    this.activeIndex = 0;
  }

  public clearFlightPathData(): void {
    this.restoreTaskValues();
    this.flightPathTasks.next([]);
    this.editPolePoints = [];
  }

  private setPolilyneEvents(layer): void {
    if (layer.options.internalId) {
      layer.off('mouseover');
      layer.off('mouseout');
    }
    layer.on('mouseover', () => {
      this.updateSelectedTaskStatus(layer, true);
    });
    layer.on('mouseout', () => {
      this.updateSelectedTaskStatus(layer, false);
    });
  }

  private listenDrawMarkerOnMap(map) {
    map.on('contextmenu', () => {
      if (
        this.actionTaskMode !== ACTION_TASK_MODE.EDIT &&
        (this.currentTabIndex.value !== TaskTabs.MARKERS || this.isDrawEventsBlocked)
      ) {
        return;
      }
      this.undo(map);
    });
    map.on('click', event => {
      if (this.isDrawEventsBlocked) {
        return;
      }
      const coords = [event.latlng.lat, event.latlng.lng];
      const newMarker = L.marker(coords, {draggable: true});
      this.drawnItems.addLayer(newMarker);
      newMarker.on('drag', () => {
        this.redrawPath(map, false);
      });
      newMarker.on('click', () => {
        this.updateSelectedTaskStatus(newMarker, false);
        this.handleMarkerClick({layer: newMarker, map, isNewMarker: true});
      });
      newMarker.on('contextmenu', () => {
        this.removeFlightPathMarker(newMarker, map);
      });
      this.handleMarkerClick({layer: newMarker, map, isNewMarker: true});
    });
  }

  private removeFlightPathMarker(marker: Marker | any, map: Map) {
    if (!marker || !map) {
      return;
    }
    const markerOptions = marker.options?.icon?.options;
    const index = markerOptions?.index - 1;
    if (
      markerOptions?.className === this.createdTaskMarkerClass ||
      markerOptions?.className === this.poleCreatedTaskMarkerClass ||
      index < 0
    ) {
      return;
    }
    this.drawnItems.removeLayer(marker);
    const hasToRestore =
      this.editPolePoints.length > 0 && markerOptions?.className.includes(this.flightPathMarkerClass);
    if (hasToRestore) {
      this.restoreEditPolePoint(marker);
    }
    const flightPathCopy = [...this.flightPathMarkers.value];
    const isFirstWaypoint = index === 0;
    delete marker.feature?.flightPathIcon;
    isFirstWaypoint ? this.removeLine(index, index + 1) : this.removeWaypointAttachedLines(index, flightPathCopy);
    this.deleteFlightPathMarker(flightPathCopy, index);
    if (this.flightPathMarkers.value[index] && index !== 0) {
      // restore line
      this.drawLine(index, map);
    }
    if (index + 1 <= this.activeIndex) {
      const undoStack = this.undoStack.value;
      undoStack.pop();
      this.undoStack.next(undoStack);
      this.activeIndex = this.getUndoIndex();
    }
    this.updateMarkersFromCustomIndex(isFirstWaypoint ? index : index - 1);
    this.updateClusters(map);
    this.undoStack.next([...this.undoStack.value, {index: markerOptions?.index, action: UNDO_ACTION.REMOVE, marker}]);
  }

  public generateSelectedFlightPathMarker(params: {
    isNewMarker: boolean;
    index: number;
    layer: any;
    isActive?: boolean;
  }) {
    const {isNewMarker, index, layer, isActive} = params;
    const type = isNewMarker ? this.newFlightPathMarkerClass : this.flightPathMarkerClass;
    const className = isActive ? type + ' ' + this.activeFlightPathMarkerClass : type;
    const iconOptions = layer.options?.icon?.options;
    const createdIndex = iconOptions?.createdIndex;
    const isCreatedPoint =
      iconOptions?.isCreatedPoint ||
      iconOptions?.className === this.createdTaskMarkerClass ||
      iconOptions?.className === this.poleCreatedTaskMarkerClass;
    const newIcon = L.divIcon({
      className,
      type,
      html: `<div style="text-align: center;">
      <span style="position: absolute; top: 44%; left: 50%;
      transform: translate(-50%, -50%);
      height: 14px;
      font-family: Arial; font-size: 12px; font-weight:700; text-anchor: middle;">
      ${index}
      </span>
      </div>`,
      iconSize: [20, 20],
      isActive,
      index,
      isCreatedPoint,
      createdIndex
    });
    layer.setIcon(newIcon);
    if (layer.feature) {
      layer.feature.flightPathIcon = newIcon;
    }
  }

  private handleMarkerClick(params: {layer: any; map: any; isNewMarker?: boolean; activeIndex?: number}) {
    const iconOptions = params.layer.options?.icon?.options;
    const layerClassName = iconOptions?.className;
    if (layerClassName === this.createdTaskMarkerClass || layerClassName === this.poleCreatedTaskMarkerClass) {
      return;
    }
    if (layerClassName?.includes(this.activeFlightPathMarkerClass)) {
      return;
    }
    if (layerClassName === this.newFlightPathMarkerClass || layerClassName === this.flightPathMarkerClass) {
      const flightPathCopy = [...this.flightPathMarkers.value];
      flightPathCopy.splice(iconOptions?.index - 1, 1, params.layer);
      this.flightPathMarkers.next(flightPathCopy);
      this.setActiveMarker(params.layer, params.isNewMarker);
      this.updateClusters(params.map);
      return;
    }
    this.activeIndex++;
    this.setActiveMarker(params.layer, params.isNewMarker);
    this.undoStack.next([...this.undoStack.value, {index: this.activeIndex, action: UNDO_ACTION.ADD}]);
    const flightPathCopy = [...this.flightPathMarkers.value];
    flightPathCopy.splice(this.activeIndex - 1, 0, params.layer);
    this.flightPathMarkers.next(flightPathCopy);
    if (this.flightPathMarkers.value.length > 1) {
      this.drawLine(this.activeIndex - 1, params.map);
      if (this.activeIndex < this.flightPathMarkers.value.length) {
        //when active index is in the middle of the path
        this.drawLine(this.activeIndex, params.map);
        this.removeLine(this.activeIndex - 2, this.activeIndex);
        this.updateMarkersFromCustomIndex(this.activeIndex - 1);
      }
    }
    this.updateClusters(params.map);
  }

  private removeLine(originIndex: number, endIndex: number) {
    let midPointLat = 0;
    let midPointLng = 0;
    const markerOrigin = this.flightPathMarkers.value[originIndex]?.getLatLng();
    const markerEnd = this.flightPathMarkers.value[endIndex]?.getLatLng();
    const drawnLayers = this.drawnItems.getLayers();
    const lineLayer = drawnLayers.find(layer => {
      if (layer.getLatLngs) {
        const linePosition = layer.getLatLngs();
        const start = linePosition[0];
        const end = linePosition[1];
        if (
          JSON.stringify(start) === JSON.stringify({lat: markerOrigin.lat, lng: markerOrigin.lng}) &&
          JSON.stringify(end) === JSON.stringify({lat: markerEnd.lat, lng: markerEnd.lng})
        ) {
          midPointLat = (start.lat + end.lat) / 2;
          midPointLng = (start.lng + end.lng) / 2;
          return layer;
        }
      }
    });
    if (lineLayer) {
      const plusMarker = drawnLayers.find(layer => {
        if (layer.getLatLng) {
          const coordinates = layer.getLatLng();
          const lat = coordinates.lat;
          const lng = coordinates.lng;
          if (lat === midPointLat && lng === midPointLng) {
            return layer;
          }
        }
      });
      lineLayer.removeFrom(this.drawnItems);
      plusMarker?.removeFrom(this.drawnItems);
    }
  }

  private updateMarkersFromCustomIndex(index: number) {
    for (let i = index; i < this.flightPathMarkers.value.length; i++) {
      const marker = this.flightPathMarkers.value[i];
      if (!marker) {
        continue;
      }
      const markerOptions = marker.options?.icon?.options;
      const generateMarkerParams = {
        isNewMarker:
          markerOptions?.className.includes(this.newFlightPathMarkerClass) ||
          markerOptions?.type === this.newFlightPathMarkerClass ||
          !markerOptions?.className,
        index: i + 1,
        layer: marker,
        isActive: i + 1 === this.activeIndex
      };
      this.generateSelectedFlightPathMarker(generateMarkerParams);
    }
  }

  private drawLine(lastMarkerIndex: number, map: Map) {
    const start = this.getLayerCoords(this.flightPathMarkers.value[lastMarkerIndex - 1]);
    const end = this.getLayerCoords(this.flightPathMarkers.value[lastMarkerIndex]);
    const midpoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
    const line = L.polyline([start, end], {
      color: defaultMissionRouteColor,
      weight: this.drawedLinesWeight,
      type: this.defaultPolylineType
    });
    this.drawnItems.addLayer(line);
    this.generatePlusMarker(midpoint, map, start);
  }

  private updatePreviousActiveMarkerIcon() {
    const previousActiveLayer = this.flightPathMarkers.value.find(layer => layer.options?.icon?.options?.isActive);
    if (!previousActiveLayer) {
      return;
    }
    const layerIconOptions = previousActiveLayer.options?.icon?.options;
    this.generateSelectedFlightPathMarker({
      isNewMarker: layerIconOptions?.type === this.newFlightPathMarkerClass,
      index: layerIconOptions?.index,
      layer: previousActiveLayer,
      isActive: false
    });
  }

  private removePolylines() {
    this.drawnItems.eachLayer(layer => {
      if (
        (layer instanceof L.Polyline && layer.options?.type !== this.createdTaskPolylineType) ||
        layer.options?.icon?.options?.className === this.plusMarkerClass
      ) {
        layer.removeFrom(this.drawnItems);
      }
    });
  }

  private redrawPath(map: Map, includeMarkers: boolean) {
    this.removePolylines();
    for (let i = 0; i < this.flightPathMarkers.value.length; i++) {
      const currentMarker = this.flightPathMarkers.value[i];
      if (i >= 1) {
        const start = this.getLayerCoords(this.flightPathMarkers.value[i - 1]);
        const end = this.getLayerCoords(currentMarker);
        const line = L.polyline([start, end], {
          color: defaultMissionRouteColor,
          weight: this.drawedLinesWeight,
          type: this.defaultPolylineType
        });
        this.drawnItems.addLayer(line);
        const midpoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
        this.generatePlusMarker(midpoint, map, start);
      }
      if (includeMarkers) {
        const markerOptions = currentMarker.options?.icon?.options;
        const generateMarkerParams = {
          isNewMarker:
            markerOptions?.className === this.newFlightPathMarkerClass ||
            markerOptions?.type === this.newFlightPathMarkerClass ||
            !markerOptions?.className,
          index: i + 1,
          layer: currentMarker
        };
        this.generateSelectedFlightPathMarker(generateMarkerParams);
        this.updateClusters(map);
      }
    }
  }

  private generatePlusMarker(midpoint, map, originRefrenceCoords) {
    const plusMarker = L.marker(midpoint, {
      icon: L.divIcon({
        className: this.plusMarkerClass,
        html: '+',
        iconSize: [11, 11],
        iconAnchor: [5, 5]
      })
    });
    this.drawnItems.addLayer(plusMarker);
    plusMarker.on('click', () => {
      const marker = L.marker(
        midpoint,
        {draggable: true},

        {
          icon: L.divIcon({
            className: this.newFlightPathMarkerClass
          })
        }
      );
      marker.on('drag', () => {
        this.redrawPath(map, false);
      });
      marker.on('click', () => {
        this.handleMarkerClick({layer: marker, map, isNewMarker: true});
      });
      marker.on('contextmenu', () => {
        this.removeFlightPathMarker(marker, map);
      });
      this.drawNewVertex(originRefrenceCoords, map, marker);
      this.handleMarkerClick({layer: marker, map, isNewMarker: true});
    });
  }

  private drawNewVertex(originRefrenceCoords: number[], map: Map, marker: Marker) {
    const flightPathMarkers = [...this.flightPathMarkers.value];
    const originReferenceMarker = flightPathMarkers.find(layer => {
      if (layer.getLatLng) {
        if (JSON.stringify([layer.getLatLng().lat, layer.getLatLng().lng]) === JSON.stringify(originRefrenceCoords)) {
          return layer;
        }
      }
    });
    if (originReferenceMarker) {
      const originIndex = originReferenceMarker.options?.icon?.options?.index;
      if (typeof originIndex !== 'number' || originIndex < 1) {
        console.warn('Invalid origin index:', originIndex);
        return;
      }
      this.undoStack.next([...this.undoStack.value, {index: originIndex + 1, action: UNDO_ACTION.ADD}]);
      flightPathMarkers.splice(originIndex, 0, marker);
      this.flightPathMarkers.next(flightPathMarkers);
      this.generateSelectedFlightPathMarker({
        isNewMarker: true,
        index: originIndex + 1,
        layer: marker,
        isActive: false
      });
      this.removeLine(originIndex - 1, originIndex + 1);
      this.drawLine(originIndex, map);
      this.drawLine(originIndex + 1, map);
      marker.addTo(this.drawnItems);
      this.activeIndex = originIndex + 1;
      this.updateMarkersFromCustomIndex(originIndex + 1);
      this.updateClusters(map);
    }
  }

  private getLayerCoords(layer) {
    return [layer.getLatLng().lat, layer.getLatLng().lng];
  }

  public initClusters(clusterPoints: any[], map: Map) {
    this.refreshCluster(clusterPoints, map);
    this.handleMapChange(map);
    this.moveMapSub?.unsubscribe();
    this.moveMapSub = fromEvent(map, 'move')
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.listenClusterChanges(map);
      });
  }

  public updateClusters(map: Map): void {
    if (this.blockUpdateClusters || !this.supercluster || !map) {
      return;
    }
    try {
      const zoom = map.getZoom();
      const bounds = map.getBounds();
      this.displayedClusters = this.supercluster.getClusters(
        [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()],
        zoom
      );
      this.clusterMarkers.forEach(marker => {
        if (this.drawnItems.hasLayer(marker)) {
          this.drawnItems.removeLayer(marker);
        }
        map.removeLayer(marker);
      });
      this.clusterMarkers = [];
      if (!this.isClusterEnabled) {
        this.updateUnclusteredMarkers(map);
        return;
      }
      this.displayedClusters.forEach(cluster => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const isCluster = cluster.properties.cluster;
        if (isCluster) {
          const pointCount = cluster.properties?.point_count;
          const clusterColor = cluster.properties?.clusterColor;
          const clusterPoints = this.supercluster.getLeaves(cluster.properties.cluster_id, Infinity);
          //create cluster icon
          const clusterMarker = marker([latitude, longitude], {
            icon: this.getIconCreateFunction(pointCount, clusterColor)
          });
          clusterMarker.on('click', () => {
            // zoom cluster on click
            const clusterBounds = latLngBounds(
              clusterPoints.map(point => latLng(point.geometry.coordinates[1], point.geometry.coordinates[0]))
            );
            map.fitBounds(clusterBounds);
          });
          this.clusterMarkers.push(clusterMarker);
          clusterMarker.addTo(map);
        } else {
          //for markers only
          this.addSingleMarker(cluster, latitude, longitude, map);
        }
      });
    } catch (error) {
      console.warn(error.message);
    }
  }

  public addSingleMarker(point, latitude, longitude, map) {
    const marker = this.geojsonAssetLoaderService.generateMarker(
      point,
      latLng(latitude, longitude),
      this.jobAsset.value,
      this.defaultMarkerSize
    );
    marker.feature = point;
    this.clusterMarkers.push(marker);
    this.onEachFeature(point, marker, map);
    if (point.flightPathIcon) {
      marker.setIcon(point.flightPathIcon);
    }
    if ((marker.feature as any).flightPathIcon) {
      //When the cluster is updated the drawed markers need to be added again
      this.drawnItems.addLayer(marker);
    }
    if (this.isDrawEventsBlocked && point.flightPathIcon) {
      return;
    }
    marker.addTo(map);
  }

  public onEachFeature(feature, layer, map) {
    if (this.isPopupEnabled.value) {
      this.setSelectedMarkerTooltip(feature.properties?.name, layer);
    }
    if (this.hasToEnableDraw) {
      layer.on('contextmenu', () => {
        const layerIconClass = layer.options?.icon?.options?.className;
        if (layerIconClass?.includes(this.flightPathMarkerClass)) {
          this.removeFlightPathMarker(layer, map);
        }
      });
      layer.on('click', () => {
        if (this.isDrawEventsBlocked) {
          return;
        }
        this.drawnItems.addLayer(layer);
        this.updateSelectedTaskStatus(layer, false);
        this.handleMarkerClick({layer, map});
      });
      layer.on('mouseover', event => {
        if (layer.feature?.internalId) {
          this.updateSelectedTaskStatus(layer, true);
        }
        if (!this.isPopupEnabled.value) {
          return;
        }
        this.geojsonAssetLoaderService.addMouseOverLayerEvent({
          event,
          feature,
          layer,
          assetName: this.jobAsset.value?.name || ''
        });
      });
      layer.on('mouseout', () => {
        if (layer.feature?.internalId) {
          this.updateSelectedTaskStatus(layer, false);
        }
        if (!this.isPopupEnabled.value) {
          return;
        }
        this.geojsonAssetLoaderService.addMouseOutLayerEvent(layer);
      });
    }
  }

  public setActiveMarker(marker: Marker, isNewMarker?: boolean) {
    const options = marker.options?.icon?.options as any;
    const markerIndex = options?.index;
    this.activeIndex = markerIndex || this.activeIndex;
    if (typeof this.activeIndex !== 'number' && this.activeIndex < 1) {
      return;
    }
    this.updatePreviousActiveMarkerIcon();
    this.generateSelectedFlightPathMarker({
      isNewMarker: isNewMarker || options?.type === this.newFlightPathMarkerClass,
      index: this.activeIndex,
      layer: marker,
      isActive: true
    });
  }

  public drawEditWaypoints(selectedTaskRoutes: MissionRoutePoint[], map: Map, jobMarkers: any[]) {
    const drawedPoints = new Set(selectedTaskRoutes?.map(({lng, lat}) => `${lng},${lat}`));
    this.editPolePoints = jobMarkers.filter(({geometry: {coordinates}}) => {
      const [lng, lat] = coordinates;
      return drawedPoints.has(`${lng},${lat}`);
    });
    const addMarker = (marker: Marker, isNewMarker: boolean) => {
      this.handleMarkerClick({layer: marker, map, isNewMarker});
      marker.on('click', () => {
        this.handleMarkerClick({layer: marker, map, isNewMarker});
      });
      marker.on('contextmenu', () => {
        this.removeFlightPathMarker(marker, map);
      });
    };
    selectedTaskRoutes.forEach(point => {
      const marker = L.marker(point);
      addMarker(marker, point.type !== MissionPointType.POLE);
      this.drawnItems.addLayer(marker);
      this.originalTaskMarkers.push(marker);
    });
    this.undoStack.next([]);
  }

  public createTask(map: Map): void {
    if (this.isDrawEventsBlocked) {
      return;
    }
    const name = `Flight Path ${this.flightPathTasks.value.length + 1}`;
    this.addFlightPathToList(null, name);
    this.updateFlightPathTaskIcons();
    this.updateClusters(map);
    this.restoreTaskValues();
    this.generateNewInternalId();
    this.translateService
      .get('jobs.tasks.flightPathAdded')
      .pipe(take(1))
      .subscribe(translation => {
        this.snackBar.open(translation, null, {
          panelClass: 'center',
          duration: 3000
        });
      });
  }

  public addFlightPathToList(userId: string, name: string) {
    const flightPath = this.getNewFlightPath(name);
    this.addFlightPaths({
      ...flightPath,
      userId
    });
  }

  public getNewFlightPath(name: string) {
    const markers = this.flightPathMarkers.value;
    const context = {};
    if (markers.length === 1) {
      context['properties'] = markers[0]?.feature?.properties;
      const nameProperty = Object.keys(context['properties']).find(key => key.toLowerCase() === 'name');
      if (context['properties'][nameProperty]) {
        name = context['properties'][nameProperty];
      }
    }
    return {
      markers,
      name,
      context
    };
  }

  public getJobAsset(jobId: string): void {
    this.jobsFacadeService
      .selectJob(jobId)
      .pipe(
        filter(job => !!job),
        take(1),
        tap(async job => {
          const assetId = job.context?.asset?.id;
          if (assetId) {
            const asset = await this.atlasService.loadLayerById(assetId);
            this.setJobAsset(asset);
          }
        })
      )
      .subscribe();
  }

  public showNothingToEditMsg(): void {
    this.translateService
      .get('jobs.tasks.nothingToEdit')
      .pipe(take(1))
      .subscribe(translation => {
        this.snackBar.open(translation, null, {
          panelClass: 'center',
          duration: 3000
        });
      });
  }

  public showNoPathMsg(): void {
    this.translateService
      .get('jobs.tasks.noPathMessage')
      .pipe(take(1))
      .subscribe(translation => {
        this.snackBar.open(translation, null, {
          panelClass: 'center',
          duration: 3000
        });
      });
  }
}

enum UNDO_ACTION {
  ADD,
  REMOVE
}

/* eslint-disable no-magic-numbers */
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserDeviceJoined} from '@app/core/models/api/user-device.model';
import {StatusService} from '@app/core/services/api/status.service';
import {FourGridLayoutService} from '@app/live/components/four-grid-layout/four-grid-layout.service';

import {BehaviorSubject, combineLatest, distinctUntilChanged, map, shareReplay, take} from 'rxjs';
import {UnleashAgoraService} from 'unleash-components/dist/unleash-components';
import {AddonStoreFacadeService} from '@app/core/services/addon-store-facade.service';
import {Addon} from '@app/store/addon/models/addon';
import {THUMBLER_AVAILABLE_CONFIGS} from '@app/shared/pipes/models/thumbler.model';
import {LiveStreamPageService} from './live-stream-page.service';
import {Router} from '@angular/router';
import {RouterFacadeStoreService} from '@app/core/services/api/router-store-facade.service';
import {AclPermissions} from '@app/core/models/api/acl.model';
import {ManagerZonesStoreFacadeService} from '@app/shared/manage-zones-dialog/services/manager-zones-store-facade.service';
import {LiveFacadeService} from '@app/live/services/live-facade.service';
import {GridLayout} from '@app/live/models/grid-state.model';
import {UserService} from '@app/core/services/api/user.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-live-stream-page',
  templateUrl: './live-stream-page.component.html',
  styleUrls: ['./live-stream-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveStreamPage implements OnInit, OnDestroy {
  public isShowDevices: boolean = false;

  public streamKey$ = this.userService.user$.pipe(
    map(user => user.streamKey),
    shareReplay(1)
  );
  public isInitialized$ = this.liveStreamPageService.isInitialized$.pipe(shareReplay(1));
  public layoutState$ = this.liveStreamPageService.layoutState$.pipe(shareReplay(1));
  public isShowingStreams$ = this.userService.liveDevices$.pipe(
    distinctUntilChanged((prev, curr) => {
      if (prev.length !== curr.length) return false;
      const prevIds = new Set(prev.map(device => device.id));
      return curr.every(device => prevIds.has(device.id));
    }),
    map(devices => devices.length > 0)
  );
  public liveDevicesId$ = this.liveStreamPageService.liveDevicesId$.pipe(
    distinctUntilChanged((prev, curr) => {
      return prev.length === curr.length && prev.every((value, index) => value === curr[index]);
    }),
    shareReplay(1)
  );
  public firstDeviceId$ = this.liveFacadeService.firstDeviceId$.pipe(shareReplay(1));
  public selectedDevice$ = this.liveStreamPageService.selectedDevice$.pipe(
    distinctUntilChanged(
      (prev, curr) =>
        `${prev.deviceId || prev.id}-${prev.runningModels?.join('-')}-${prev.waitingModels?.join('-')}-${
          prev.destUrlSet
        }-${prev.isRestreamingTo}` ===
        `${curr.deviceId || curr.id}-${curr.runningModels?.join('-')}-${curr.waitingModels?.join('-')}-${
          curr.destUrlSet
        }-${curr.isRestreamingTo}`
    ),
    shareReplay(1)
  );
  public modelSelectorOptions$ = this.addonStoreFacadeService.getAllActiveAddons();
  public THUMBLER_AVAILABLE_CONFIGS = THUMBLER_AVAILABLE_CONFIGS;

  public toggleModelSelector = new BehaviorSubject(false);
  public hasToDisplayModelSelector$ = combineLatest([
    this.liveFacadeService.isSelectedDeviceRunningModel$,
    this.toggleModelSelector.asObservable()
  ]).pipe(
    map(
      ([isSelectedDeviceRunningModel, hasToDisplayModelSelector]) =>
        hasToDisplayModelSelector || isSelectedDeviceRunningModel
    )
  );
  public aclPermissions = AclPermissions;

  constructor(
    private statusService: StatusService,
    private fourGridLayoutService: FourGridLayoutService,
    private userService: UserService,
    public liveStreamPageService: LiveStreamPageService,
    private unleashAgoraService: UnleashAgoraService,
    public addonStoreFacadeService: AddonStoreFacadeService,
    private router: Router,
    private routerFacadeStoreService: RouterFacadeStoreService,
    private managerZonesStoreFacadeService: ManagerZonesStoreFacadeService,
    private liveFacadeService: LiveFacadeService
  ) {}

  public ngOnInit(): void {
    this.liveFacadeService.startPolling();
    this.liveFacadeService.watchSelectModelWhenDeviceChange();
    this.liveFacadeService.watchForStreamingDevicesChange();
    this.liveFacadeService.watchForStreamSourceChanges();
    this.liveFacadeService.watchForRestreamChanges();
  }

  public ngOnDestroy(): void {
    this.statusService.stopPolling();
  }

  // TODO: implement this.
  public showLimitReachedDialog(_ai: boolean): void {
    // const dialogRef = this.dialog.open(StreamLimitDialog, <MatDialogConfig>{
    //   width: '320px',
    //   data: {ai: ai}
    // });
  }

  public setCurrentModel(device: UserDeviceJoined, modelId: Addon['id']): void {
    this.liveStreamPageService.setCurrentModel(device, modelId);
  }

  public setDeviceOnCinema(device: UserDeviceJoined): void {
    this.fourGridLayoutService.setDeviceOnCinema(device);
  }

  public toggleShowDevice(): void {
    this.isShowDevices = !this.isShowDevices;
  }

  public setLayoutState(state: GridLayout): void {
    this.liveFacadeService.setLayoutState(state);
  }

  public onAIModelToggleSet($event: MatSlideToggleChange): void {
    this.toggleModelSelector.next($event.checked);
  }

  public openZoneDialog(device: UserDeviceJoined, model: Addon): void {
    this.routerFacadeStoreService.url$.pipe(take(1)).subscribe((_url: string) => {
      this.router.navigate([`/secure/live/analyze`], {
        queryParams: {
          deviceId: device.id,
          addonId: model.id
        }
      });

      this.managerZonesStoreFacadeService.triggerSnapshot(device.id);
      this.unleashAgoraService.triggerSnapshot(device.id);
    });
  }

  public stopModelOfSelectedDeviceOptimisticUpdate(modelToStop: {device: UserDeviceJoined; modelId: string}): void {
    this.liveStreamPageService.stopModelOfSelectedDeviceOptimisticUpdate(modelToStop);
  }

  public addDevice(): void {
    this.liveFacadeService.addDevice();
  }

  public changeDestUrlSetOnSelectedDevice(event: MatSlideToggleChange, deviceId: string): void {
    this.userService.changeDestUrlSetOnSelectedDevice(event.checked, deviceId);
  }

  public errorStartModel(deviceId: string, modelId: string): void {
    this.userService.removeAddonFromWaitingModelsDueError(deviceId, modelId);
  }
}

import * as actions from '@app/live/store/schedules/live-schedule.actions';
import {createFeatureSelector, createReducer, on} from '@ngrx/store';
import {ListScheduleResponse} from '@app/live/models/schedule.model';
import {ScheduleEvent, ScheduleEventType, ScheduleModel} from '@app/core/models/api/schedule.model';

export const scheduleFeatureKey = 'live-schedule';
export const selectLiveScheduleStore = createFeatureSelector(scheduleFeatureKey);

export interface LiveScheduleState {
  itemsIds: string[];
  itemsById: {[id: string]: ScheduleModel};
  isLoadingSchedules: boolean;
  isExecutingAction: boolean;
}

export const initialState: LiveScheduleState = {
  itemsIds: [],
  itemsById: {},
  isLoadingSchedules: false,
  isExecutingAction: false
};

export const reducer = createReducer(
  initialState,
  on(actions.loadSchedulesSuccess, (state: LiveScheduleState, action: ListScheduleResponse) => {
    const itemsById = {};
    const itemsIds: string[] = [];
    action.items?.forEach(item => {
      const scheduleDays = new Set<number>();
      item.expressions?.forEach(expression => {
        const cronParts = expression.startCronExpression.replace('cron(', '').replace(')', '').split(' ');
        scheduleDays.add(getScheduleDay(cronParts));
      });
      const copyItem: ScheduleModel = {
        ...item,
        scheduleDays: Array.from(scheduleDays),
        isRecurring: isRecurring(item.expressions),
        isSnapshot: !!item?.snapshotConfig?.frequency
      };
      if (item.oneOffStartDate) {
        copyItem.oneOffStartDate = new Date(item.oneOffStartDate);
      }
      itemsIds.push(copyItem.id);
      itemsById[copyItem.id] = copyItem;
    });

    return {
      ...state,
      itemsIds: [...new Set([...state.itemsIds, ...itemsIds])],
      itemsById: {...state.itemsById, ...itemsById},
      nextToken: action.nextToken,
      isLoadingSchedules: action.nextToken !== undefined
    };
  }),
  on(actions.updateIsLoadingSchedules, (state: LiveScheduleState, action: {isLoadingSchedules: boolean}) => {
    return {
      ...state,
      isLoadingSchedules: action.isLoadingSchedules
    };
  }),
  on(actions.batchUpdateScheduleSuccess, (state: LiveScheduleState, schedule: ScheduleModel) => {
    return {
      ...state,
      itemsById: {...state.itemsById, [schedule.id]: schedule},
      isLoadingSchedules: false
    };
  }),
  on(actions.batchCreateScheduleSuccess, (state: LiveScheduleState, schedule: ScheduleModel) => {
    const newSchedule = {...schedule};
    const scheduleDays = [];
    newSchedule?.expressions.forEach(expression => {
      const cronParts = expression.startCronExpression.replace('cron(', '').replace(')', '').split(' ');
      scheduleDays.push(getScheduleDay(cronParts));
    });
    newSchedule.scheduleDays = scheduleDays;
    newSchedule.isRecurring = isRecurring(schedule.expressions);
    newSchedule.isSnapshot = !!schedule?.snapshotConfig?.frequency;
    return {
      ...state,
      itemsIds: [...state.itemsIds, schedule.id],
      itemsById: {...state.itemsById, [schedule.id]: newSchedule},
      isLoadingSchedules: false
    };
  }),
  on(actions.batchDeleteScheduleSuccess, (state: LiveScheduleState, schedule: ScheduleModel) => {
    const itemsById = {...state.itemsById};
    delete itemsById[schedule.id];
    return {
      ...state,
      itemsIds: state.itemsIds.filter(scheduleId => scheduleId !== schedule.id),
      itemsById,
      isLoadingSchedules: false
    };
  }),
  on(actions.updateIsExecutingAction, (state: LiveScheduleState, action: {isExecutingAction: boolean}) => {
    return {
      ...state,
      isExecutingAction: action.isExecutingAction
    };
  })
);

function getScheduleDay(cronParts) {
  const dayNumberIndex = 4;
  if (cronParts[dayNumberIndex] === '?') {
    const startCron = new Date(
      parseInt(cronParts[5]),
      parseInt(cronParts[3]) - 1, // https://stackoverflow.com/questions/12254333/javascript-is-creating-date-wrong-month
      parseInt(cronParts[2]),
      parseInt(cronParts[1]),
      parseInt(cronParts[0])
    );
    return startCron.getDay() + 1;
  }
  return parseInt(cronParts[dayNumberIndex]);
}

function isRecurring(schedules) {
  if (schedules?.length === 0) {
    return true;
  }
  if (schedules && schedules.length > 0) {
    const cronParts = schedules[0].startCronExpression.split(' ');
    if (cronParts[4] !== '?') {
      return true;
    }
  }
  return false;
}

import {Directive, HostListener, Input} from '@angular/core';
import {AtlasAssetModel} from '@app/core/models/api/atlas.model';
import {AtlasService} from '../services/atlas.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {STANDARD_DIALOG_CONFIG} from '@app/theme/dialogs.config';
import {filter} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EVENTS, UnleashAnalyticsService} from '@app/core/services/unleash-analytics.service';
import {ConfirmDeleteDialog} from '@app/shared/confirm-delete-dialog/confirm-delete-dialog.component';
import {SuperclusterService} from '../services/supercluster.service';
import {Map} from 'leaflet';

@Directive({
  selector: '[ulRemoveLayer]',
  standalone: true
})
export class RemoveLayertDirective {
  @Input('ulRemoveLayer') public asset: AtlasAssetModel;
  @Input() public map: Map;

  constructor(
    private atlasService: AtlasService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private unleashAnalytics: UnleashAnalyticsService,
    private superclusterService: SuperclusterService
  ) {}

  @HostListener('click', ['$event'])
  public removeLayer(): void {
    const asset = this.asset;
    this.dialog
      .open(ConfirmDeleteDialog, {
        ...STANDARD_DIALOG_CONFIG,
        width: '500px',
        data: {items: [asset.name]}
      } as MatDialogConfig)
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((): void => {
        const removeAssetParams = {
          assetId: asset.id,
          avoidUpdateCache: false
        };
        this.atlasService.removeAsset(removeAssetParams).subscribe((): void => {
          this.translateService.get('atlas.control.removeSuccess', {value: asset.name}).subscribe(message => {
            this.snackBar.open(message, null, {duration: 5000});
          });
          asset.leafletLayer?.removeFrom(this.map);
          this.superclusterService.refreshCluster(this.atlasService.getAllPointFeatures(), this.map);
          this.unleashAnalytics.logEvent(EVENTS.ATLAS_LAYER_ACTION, {
            type: 'Remove'
          });
        });
      });
  }
}
